import { Button } from '@mui/material'
import React from 'react'
import { installationDoneByElectrician } from './UpgradeDownGrade/ApplincesDifferenceApi'
import { subHistory } from '../Function/SubscriptionHistory'
import { useSelector } from 'react-redux'
import { notify, notifyFailed } from '../Common/Notification'
import { electicianLocationLog, typeOfLocaionLogs } from './api'

function B2BSubscriptionSiteDone({sub,setReload,reload}) {
  let loginName = useSelector(state=>state.sar.data.user.first_name)
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)

  const handleInstallationDone = async()=>{
    let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.leave,sub)
    if(!res.success){
      notifyFailed("First Click on Day Start Button")
      return
    }
     await installationDoneByElectrician(sub,true,false)
    subHistory("B2B Installation Done by Electrician",sub,"B2B Installation Done by Electrician","B2B Installation Done by Electrician")
    setReload(!reload)
  }
  return (
    <>
      <Button 
        varient="contained"
        color="primary"
        onClick={()=>handleInstallationDone()}
      >B2B Installation Done</Button>
    </>
  )
}

export default B2BSubscriptionSiteDone