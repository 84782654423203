import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { serverUrl } from '../ApiUrl'
import moment from 'moment'
import { GoogleMap, LoadScript, OverlayView,Marker } from '@react-google-maps/api'
const mapContainerStyle = {
  width: "100%",
  height: "75vh",
};
function ElectricianWiseMap() {
    const [electrician,setElectrician] = useState([])
    const [elctricianId,setElectricianId]  = useState("")
    const [locationlog,setLocationlog] = useState([])
    const [reload,setReload] = useState(false)
    const notify = (text)=>toast(text)
    const [activeMarker, setActiveMarker] = useState(null);

    const handleMouseOver = (marker) => {
      setActiveMarker(marker);
    };
  
    const handleMouseOut = () => {
      setActiveMarker(null);
    };
    useEffect(()=>{
       axios.get(`${serverUrl.u}/api/tele/getAllElectrician`).then(function (res) {
          console.log(res);
          if(res.data.success===true){
             console.log(res.data.data,"dd");
             setElectrician(res.data.data)
             setElectricianId(res.data.data[0].employeeId)
          }
       }).catch(function (err) {
           console.log(err);
       })
    },[reload])
    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = new FormData(e.currentTarget);
    
        let date = {
          startDate: data.get('startDate'),
          endDate: data.get('endDate'),
        }
     
        return await axios
        .post(`${serverUrl.u}/api/electrician/fetchLog`, {
          employeeId: elctricianId,
          firstDate: date.startDate,
          lastDate: new Date(date.endDate).setDate(new Date().getDate() + 1),
        })
        .then((res) => {
         
          if (res.data.success) {
            if (res.data.data.length !== 0) {
             setLocationlog(res.data.data)
            }else{
              setLocationlog([])
            }
           
          } else {
            setLocationlog([])
          }
        })
        .catch((err) => {
          let message = err?.response?.data?.message
            ? err?.response?.data?.message
            : err.message;
            setLocationlog([])
        
        });
      }
      const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        locationlog.forEach((position) => bounds.extend({lat:position.location.latitude,lng:position.location.longitude}));
        
        map.fitBounds(bounds);
      };
  return (
    <div style={{ width: "100%", padding: "0 4%", marginTop: "5vh" }}>
        <form onSubmit={handleSubmit} >
        <div className='row'>
            <div className='col-2'>
            <label>Electrician</label>
                <select
                  className='form-control'
                  name='elctrician'
                  value={elctricianId}
                  onChange={(e)=>setElectricianId(e.target.value)}
                >
                 {
                    electrician.map((e,i)=>{
                       return(
                        <option value={e.employeeId} key={e._id}>{e.firstName} {e.lastName}</option>
                       )
                    })
                 }
                </select>
            </div>
            <div className='col-2 '>
            <label>Start Date</label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                name="startDate"
                defaultValue={new Date(new Date().setDate(new Date().getDate() - 10)).toISOString().substr(0, 10)}
                autoComplete='current-startDate'
              />
            </div>
          </div>
          <div className='col-2 '>
            <label>End Date</label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                name="endDate"
                defaultValue={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10)}
                autoComplete='current-endDate'
              />
            </div>
          </div>
          <div className='col-2'>
            <label></label>
            <div className='input-group mt-2 '>
            <button className='btn btn-primary' style={{ marginLeft: "2%",marginTop:"2%",marginBottom:"5%" }} type='submit'>Get Data</button>
            </div>
          </div>
        </div>
        </form>
{
  locationlog.length>0 && (
<LoadScript
        googleMapsApiKey="AIzaSyBqO5gAoCNGU4GU_BoWui5KZyZ8ULZrEuA"
        loadingElement="Loading..."
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={locationlog.length !== 0 && locationlog[0]}
          onLoad={handleOnLoad}
        >
          <>
          {locationlog.map((position) => (
            <div key={position.employeeId}>
            <Marker
              key={position._id}
              position={{lat:position.location.latitude,lng:position.location.longitude}}
              // onClick={() => handleActiveMarker(location.employeeId)}
              onMouseOver={() => handleMouseOver(position._id)}
              onMouseOut={handleMouseOut}
            >
            
           { activeMarker === position._id &&  <OverlayView
                 position={{lat:position.location.latitude,lng:position.location.longitude}}
                 mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
               >
                   <div className="custom-info">
                    {moment(position.createdAt).format('DD-MM-YYYY ,LT')}  -  {position.distanceFromLastLog}Kms
                  </div>
                </OverlayView>
}
            </Marker>
            </div>
          ))}
          
        </>
        </GoogleMap>
      </LoadScript>
  )
}
        
        <div className='table-container1' style={{ marginTop: "10px", marginBottom: "30px", height: "fit-content" }} key={"imp"}>
              <table className='table table-sm table-bordered'>
                <thead>
                  <tr >
                    <th>Sr. No.</th>
                    <th>Date</th>
                    <th>Type of Location</th>
                    <th scope="col">Distance fron Last Location</th>
                    <th>Reson</th>
                  </tr>
                </thead>
                <tbody>
                  {

                     locationlog.map((e, i) => {
                      return (
                        <tr key={i} >
                          <td>{(i + 1)}</td>
                          <td>{moment(e.createdAt).format('DD-MM-YYYY ,LT')}</td>
                          <td>{e.type}</td>
                          <td>{e.distanceFromLastLog} Kms</td>
                          <td>{e?.reson}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
    </div>
  )
}

export default ElectricianWiseMap