import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './PaymentHistory.css'
import moment from 'moment';
import Cards from './Card';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({data}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let actualTotalAmount = 0
  data.map((id)=> actualTotalAmount +=id.actualTotalAmount)

  data = data.sort(
    (p1, p2) => 
    (p1.createdAt > p2.createdAt) ? 1 : (p1.createdAt < p2.createdAt) ? -1 : 0);

 let createdAt = data[0].createdAt;
  return (
 
    <Card sx={{ maxWidth: "100%" }}>
      <CardContent className='labelCardContent'>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Name</label>
        <label className='labelFormating'>{data[0].name}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Phone</label>
        <label className='labelFormating'>{data[0].phone}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Tellecaller Name</label>
        <label className='labelFormating'>{data[0].teleCallerId}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Registration Date</label>
        <label className='labelFormating'>{moment(createdAt).format('DD-MM-YYYY')}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Total Amount</label>
        <label className='labelFormating'>{actualTotalAmount}</label>
        </div>

      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{height:0,outline:'none',border:'none'}}
        >
          <ExpandMoreIcon sx={{outline:'none',border:'none'}}/>
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph></Typography>
          {data.map((record,id)=>{
            console.log(record,"rec");
            return <Cards data={record} id={id}/>
            })}
        </CardContent>
      </Collapse>
    </Card>
  
  
  );
}
