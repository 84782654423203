import React,{useState} from 'react'
import ReactWhatsapp from 'react-whatsapp'
import { WhatsApp } from '@mui/icons-material'
import { finalSelectDeviceUpdate } from '../../Data'
import { applianceDifferenceWithDeviceId, applianceDifferenceWithHouseId } from '../../Common/applianceDifference'
import { electicianLocationLog, typeOfLocaionLogs } from '../../SpecificElectricianUpGradeDownGrade.js/api'
import { notifyFailed } from '../../Common/Notification'
import { useSelector } from 'react-redux'

function PaymentCollect({sub,setReload, reload}) {
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)

    const [pop, setPop] = React.useState(false)
    const [housePop, setHousePop] = React.useState(false)
  const [text, setText] = React.useState("")
  const [paymentLink, setPaymentLink] = React.useState("")
  let [differenceAppliancesText, setDifferenceAppliancesText] = useState("")
  let [whatappNumber, setWhatappNumber] = useState();
  let [btn, setBtnText] = useState("Collect Payment")

  const handleSubmit = (event)=>{
    setBtnText("Processing...")
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let deviceData = {
        deviceType: data.get('deviceType'),
        deviceId: data.get('deviceId'),
      }
    // applianceDifferenceWithDeviceId(sub,deviceData,setDifferenceAppliancesText,setPaymentLink,setWhatappNumber,setBtnText,setReload,reload)
  }
console.log(btn);
  return (
    <>
    <button 
     className='btn1 btn-primary'
     disabled={btn==="loading..."?true:false}
     onClick={async()=>{
        let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.leave,sub)
        if(!res.success){
          notifyFailed("First Click on Day Start Button")
          return
        }
      if(sub.houseId===""){
         notifyFailed('First Create House')
      }else{
        applianceDifferenceWithHouseId(sub,{},setDifferenceAppliancesText,setPaymentLink,setWhatappNumber,setBtnText,setReload,reload)
        setHousePop(true)
      }
      }}>{btn}</button>
    {
        pop === true ?
          <div className="reson-form">
            <span className="reson-cross" onClick={() => {
              setPop(!pop)
              setText("")
              setPaymentLink("")
              setDifferenceAppliancesText('')
            }}>x</span>
            <form onSubmit={handleSubmit}>
              <label className="specific-label">Select Device Type</label>
              <div class="input-group ">
                <select class="custom-select height-select mb-2"
                  name="deviceType"
                  autoComplete='deviceType'
                  defaultValue={finalSelectDeviceUpdate[0].value}
                >
                  {finalSelectDeviceUpdate.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}

                </select>
              </div>
              <label className="specific-label">Enter one Device Id</label>
              <div className="input-group">
                <input type="text"
                  name="deviceId"
                  autoComplete='current-deviceId'
                  className="form-control"
                  required
                  aria-label="referenceId"
                  aria-describedby="basic-addon1" />
              </div>
          
              <div><button type='submit' className="btn2 btn-primary mt-2" style={{ pointerEvents: btn !== "Processing..." ? "" : "none" }} >{btn}</button></div>
              <div>{differenceAppliancesText}</div>
             
              {text !== "" ? (
                <>

                  <div>
                    <button
                      type='button'
                      className="btn2 btn-primary mt-2"
                      onClick={() => navigator.clipboard.writeText(paymentLink)}
                    >
                      Copy
                    </button>

                  </div>
                  <div>
                    <ReactWhatsapp
                      number={whatappNumber}
                      message={text}
                      type='button'
                      className="btn2 btn-primary mt-2"
                      style={{
                        backgroundColor: "#25D366",
                        border: "none",
                        borderRadius: "12px",
                        padding: "5px",

                      }}
                    >
                      <WhatsApp
                        sx={{
                          color: "white",
                          verticalAlign: "middle",
                          fontSize: "xx-large",
                        }}
                      />
                    </ReactWhatsapp>
                  </div>
                </>
              ) : null}
            </form>
          </div> : null
      }
 {(text !== "" && housePop===true) ? (
                <>
                <div className="reson-form">
            <span className="reson-cross" onClick={() => {
              setHousePop(!housePop)
              setText("")
              setPaymentLink("")
              setDifferenceAppliancesText('')
            }}>x</span>
             <div>{differenceAppliancesText}</div>
                  <div>
                    <button
                      type='button'
                      className="btn2 btn-primary mt-2"
                      onClick={() => navigator.clipboard.writeText(paymentLink)}
                    >
                      Copy
                    </button>

                  </div>
                  <div>
                    <ReactWhatsapp
                      number={whatappNumber}
                      message={text}
                      type='button'
                      className="btn2 btn-primary mt-2"
                      style={{
                        backgroundColor: "#25D366",
                        border: "none",
                        borderRadius: "12px",
                        padding: "5px",

                      }}
                    >
                      <WhatsApp
                        sx={{
                          color: "white",
                          verticalAlign: "middle",
                          fontSize: "xx-large",
                        }}
                      />
                    </ReactWhatsapp>
                  </div>
                  </div>
                </>
              ) : null}
      </>
  )
}

export default PaymentCollect