import { Label } from '@mui/icons-material'
import { Box, Button, Grid, Modal, Stack } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { addLeave } from './Api'
import { getAllDates } from '../../Common/applianceDifference'
import axios from 'axios'
import { serverUrl } from '../../ApiUrl'
import { notify, notifyFailed } from '../../Common/Notification'
import { useEffect } from 'react'
import moment from 'moment'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    borderRadius: "12px",
    overflowY:"scroll",
    maxHeight:"85vh",
}
const labelStyle = {
    fontSize:"10px",
    margin:0
}

function AddLeave({leave, setLeave}) {
    const [btnDisable, setBtnDisable] = useState(false)
    return (
        <Grid container spacing={1}>
            <Grid item md={2} sm={2} xs={5}>
                <label style={labelStyle}>Start Date</label>
                <div class="input-group mb-1">
                    <input
                        type="date"
                        className="form-control w-100"
                        id="date"
                        name="date"
                        disabled={btnDisable}
                        onChange={(event) => {
                            setLeave({ ...leave, startDate: event.target.value })

                        }}
                    />
                </div>
            </Grid>
            <Grid item md={2} sm={2} xs={5}>
                <label style={labelStyle}>End Date</label>
                <div class="input-group mb-1">
                    <input
                        type="date"
                        className="form-control w-100"
                        id="date"
                        name="end_date"
                        disabled={btnDisable}
                        onChange={(event) => {
                            setLeave({ ...leave, endDate: event.target.value })

                        }}
                    />
                </div>
            </Grid>
            <Grid item md={2} sm={2} xs={5}>
                <label style={labelStyle}>Reson</label>
                <div class="input-group mb-1 ">
                    <input
                        type="text"
                        className="form-control w-100"
                        id="description"
                        name="description"
                        disabled={btnDisable}
                        onChange={(event) => {
                            setLeave({ ...leave, description: event.target.value })
                        }}
                    />
                </div>
            </Grid>

           
        </Grid>

    )
}
function Leave() {
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    const [open, setOpen] = React.useState(false);
    let [numberLeaveAction, setLeaveAction] = useState(1)
    const [leave, setLeave] = useState({})
    const [leaveCount, setLeaveCount] = useState([])
    const [approveTotalLeave,setApproveTotalLeave] = useState({})
    const handleClose = ()=>{
        setLeaveAction(1)
        setOpen(false)
        setLeaveCount([])
    }
    const getApproveLeave = () =>{
        axios.post(`${serverUrl.u}/app/elec/electricianApprovedLeave`,{
            employeeId:employeeId 
        }).then(function (res) {
            if(res.data.success===true){
                setApproveTotalLeave(res.data.data.electLeave)
                
            }else{
                notifyFailed(res.data.message)
            }
        }).catch(function (err) {
            notifyFailed("Leave Not Found")
        })
    }
    useEffect(()=>{
        getApproveLeave()
    },[])
    const deleteLeave = (date)=>{
        axios.post(`${serverUrl.u}/app/elec/editLeaveByElectrician`,{
            "employeeId":employeeId,
            "removeLeaveArray":[date]
        }).then((res)=>{
            if(res.data.success){
                notify(res.data.message)
            getApproveLeave()
            }else{
                notifyFailed(res.data.message)
            }
        }).catch(err=>{

            notifyFailed("Something went wrong")
        })
    }
    return (
        <div style={{ padding: "0 1%", marginTop: "4vh" }}>

            <Stack>
                <Button
                 variant='contained'
                 color="primary"
                 onClick={()=>setOpen(true)}
                >Add Leaves</Button>
            </Stack>
            <div className='mt-4'>Approved Leaves</div>
            {
                (Object.keys(approveTotalLeave).length !== 0 && approveTotalLeave.approvedLeave.length!==0) && (
<div className='table-container1 mt-1'>
            
            <table className="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th scope="col" >Leave Reson</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        approveTotalLeave?.approvedLeave.map((leave, i) => {
                                return (

                                    <tr key={i}>
                                        <td style={{ fontWeight: "bold" }}>{leave.description}</td>
                                        <td>{moment(leave.date).format("DD-MMM-YYYY")}</td>
                                    </tr>
                                );
                            })
                            
                    }
                </tbody>
            </table>
        </div>
                )
            }
            
            <div className='mt-4'>Pending Leaves for Approval</div>
            {
              ( Object.keys(approveTotalLeave).length !== 0 && approveTotalLeave.leaveForApproval.length!==0) &&  (
                <div className='table-container1 mt-1'>
            
                <table className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" >Leave Reson</th>
                            <th scope="col">Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                                approveTotalLeave?.leaveForApproval.map((leave, i) => {
                                    return (

                                        <tr key={i}>
                                            <td style={{ fontWeight: "bold" }}>{leave.description}</td>
                                            <td>{moment(leave.date).format("DD-MMM-YYYY")}</td>
                                            <td><Button
                                               variant='contained'
                                               onClick={()=>deleteLeave(leave.date)}
                                            >Delete</Button></td>
                                        </tr>
                                    );
                                })
                            
                        }
                    </tbody>
                </table>
            </div>
              )

            }
        
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="ModelBox">
                    {
                        Array.from({ length: numberLeaveAction }).map(e => (
                            <AddLeave leave={leave} setLeave={setLeave} />

                        ))
                    }
                    <Stack direction={"row"} spacing={2} mt={2}>
                  
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      onClick={
                        async()=>{
                    
                      await addLeave(employeeId,leave)
                       handleClose()
                    }}
                    >Submit</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    )
}

export default Leave