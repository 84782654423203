import React, { useEffect, useRef, useState } from 'react';
import { apiRequest } from '../../utils';
import { pages, serverUrl } from '../ApiUrl';
import { notify, notifyFailed } from '../Common/Notification';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import AlreadyDroped from '../OnCallGrivance/AlreadyDroped';
import LastGrivanceElectrician from '../OnCallGrivance/LastGrivanceElectrician';
import GreivanceResolveDep from '../OnCallGrivance/GreivanceResolveDep';
import GrivanceHistory from '../OnCallGrivance/GrivanceHistory';
import { set } from 'react-hook-form';

export default function DroppedClient() {
  const [loading, setLoading] = useState(false);
  const [droppedClients, setDroppedClients] = useState([]);
  const [enhancedClients, setEnhancedClients] = useState([]);
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState('dropDate');

  function handleFilterChange(e) {
    console.log('[handleFilterChange- ', e);
    setFilter(e.target.value);
  }
  let firstDate = useRef(new Date());
  let lastDate = useRef(new Date());
  const enhanceClientData = async (clients) => {
    const updatedClients = await Promise.all(
      clients.map(async (client) => {
        // Call the API for each row (client)
        const response = await apiRequest({
          url: `${serverUrl.u}/site/status`, // Your additional API endpoint
          data: {
            generatedHex: client.generatedHex,
            dropDate: client.dropDate, // Send the client ID or relevant identifier,
          },
          method: 'POST',
        });

        // Assuming the API response contains some extra data in `response.data.extraField`
        if (response.success) {
          return {
            ...client, // Keep the original client data
            page: response.data.data.status ? 'Electrician Page' : 'Other Page', // Add the new field from the API
          };
        }
        return client; // Return the original client if API fails
      })
    );
    setEnhancedClients(updatedClients);
  };

  const fetchDroppedClients = async () => {
    const response = await apiRequest({
      url: `${serverUrl.u}/client/drop`,
      data: {
        firstDate: firstDate.current.value,
        lastDate: lastDate.current.value,
        filterOn: filter,
      },
      method: 'POST',
    });

    if (!response.success) {
      notifyFailed(response.message);
      return;
    }

    const clients = response.data?.data?.subscription || [];
    setDroppedClients(clients);
    notify(response.message);
    await enhanceClientData(clients);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('SUBMIT pressed');

    fetchDroppedClients();
  };
  useEffect(() => {
    if (firstDate.current.value === null || firstDate.current.value.length < 2)
      return;
    console.log(firstDate.current.value, 'date');
    fetchDroppedClients();
  }, [reload]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
    },
    {
      field: 'phone',
      headerName: 'PhoneNumber',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'totalAppliances',
      headerName: 'Total Appliances',
    },
    {
      field: 'dropped',
      headerName: 'Dropped',
    },
    {
      field: 'dropDate',
      headerName: 'Dropped Date',
      width: 200,
    },
    {
      field: 'reasonForDrop',
      headerName: 'Reason',
      width: 300,
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 300,
    },

    {
      field: 'generatedHex',
      headerName: 'Hex',
    },
    {
      field: 'teleCallerId',
      headerName: 'Telecaller',
    },

    {
      field: 'secondarySalesPerson',
      headerName: 'Second Sales Person',
    },
    {
      field: 'invoiceStatus',
      headerName: 'Invoice Status',
    },

    {
      field: 'overdueAmount',
      headerName: 'OverDue',
    },
    {
      field: 'page', // This is the new field
      headerName: 'Page',
      width: 200,
    },
    {
      field: 'subscriptionStage',
      headerName: 'Stage',
      width: 200,
    },
    {
      field: 'actions', // Field for actions like buttons
      headerName: 'Actions',
      width: 350,
      renderCell: (params) => {
        console.log('====================================');
        console.log(params.row);
        console.log('====================================');
        return (
          <Stack
            direction={'row'}
            spacing={2}
            className='align-items-center h-100 px-2'
          >
            <AlreadyDroped
              e={params.row._id}
              data={params.row}
              setReload={setReload}
              reloadW={reload}
              notify={notify}
              page={pages.grivianceDeartment}
            />

            <GreivanceResolveDep
              dataObject={params.row}
              reloadW={reload}
              setReload={setReload}
              notify={notify}
            />
            <GrivanceHistory data={params.row} notify={notify} />
          </Stack>
        );
      },
    },
  ];

  return (
    <div style={{ padding: '0 20px', position: 'relative' }}>
      <h2 className='mt-4'>Dropped Clients</h2>
      <Paper elevation={1} sx={{ p: 4, marginBottom: '20px' }}>
        <form onSubmit={handleSubmit}>
          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>First Date:</label>
              <input type='date' ref={firstDate} required />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>Last Date:</label>
              <input type='date' ref={lastDate} required />
            </div>

            <Button variant='contained' size='small' type='submit'>
              Fetch Clients
            </Button>
          </Stack>
          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'flex-end' }}>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <RadioGroup row value={filter} onChange={handleFilterChange}>
                <FormControlLabel
                  value='dropDate'
                  control={<Radio />}
                  label='Dropped Date'
                />
                <FormControlLabel
                  value='uninstallationDate'
                  control={<Radio />}
                  label='Uninstallation Date'
                />
              </RadioGroup>
            </FormGroup>
          </Stack>
        </form>
      </Paper>

      {enhancedClients.length > 0 && (
        <DataGrid
          columns={columns}
          rows={enhancedClients.map((dd, index) => ({
            ...dd,
            dropDate: moment(new Date(dd.dropDate)).format('DD/MM/YYYY'),
            id: index,
          }))}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              csvOptions: {
                fileName: `DroppedClients-${moment(
                  firstDate.current.value
                ).format('DD-MM-YYYY')}-${moment(lastDate.current.value).format(
                  'DD-MM-YYYY'
                )}`,
              },
            },
          }}
          pageSizeOptions={[10, 20, 30, 40]}
          sx={{
            height: '500px',
            width: '87vw',
            zIndex: 1,
            position: 'absolute',
          }}
        />
      )}
    </div>
  );
}
