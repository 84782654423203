import axios from 'axios'
import React from 'react'
import { useSelector } from 'react-redux'
import { serverUrl } from '../ApiUrl'

export default function GrivanceResolved({grivance,notify,reload, setReload}) {
    let loginName = useSelector((state) => state.sar.data.user.first_name)

    const resolvedUpdate = ()=>{
        axios.post(`${serverUrl.u}/api/grv/resolveGrievance`,{
            sId:grivance._id,
            resolvedBy:loginName
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                notify("Successfully Done")
                setReload(!reload)
            }else{
                notify('Failed')
            }
        }).catch(function (error) {
            console.log(error);
            notify("Failed")
        })
     }
  return (
    <>
      <button className='btn btn-primary' onClick={()=>resolvedUpdate()}>Resolve</button>
    </>
  )
}
