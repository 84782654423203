import { Box, Button, Modal,Stack } from '@mui/material'
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { allLeave, approveLeave } from './Api';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "max-content",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    overflowY:"scroll",
    maxHeight:"85vh",
}
const dateLeave = {
    width:"170px"
}
function ApproveLeave({leave,setLeave}) {
    const [open, setOpen] = useState(false);
    const [approve,setapprove] = useState([])
    const handleClose = ()=>{
        setapprove([])
        setOpen(false)
    }
    const selectAllBox = (checked)=>{
    
        let checkBox = document.getElementsByName('chk')
        for(var i=0; i<checkBox.length; i++){  
          if(checkBox[i].type=='checkbox')  
          checkBox[i].checked=checked;  
        }
        if (checked===true) {
             setapprove(leave.leaveForApproval)
        }else{
          setapprove([])
        }
        
    }
    useEffect(()=>{
        console.log(approve,"approve");
    },[approve])
    const handleChange = (event,leav)=>{
    
        if(event.target.checked){
          setapprove([...approve,leav])
        }else{
         let filterLeave = approve.filter(e=>{
             if(e.date!==leav.date ){
                 return e
             }
         })
         setapprove(filterLeave)
        }
     }
  return (
    <div>
    <Button
      variant='contained'
      size='small'
      disabled={leave.leaveForApproval.length===0?true:false}
      onClick={()=>setOpen(true)}
    >
        Approve Leave
    </Button>
   <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="ModelBox">
                   
                <Stack>
                        <Stack direction={"row"} spacing={1}>
                                            <item style={dateLeave}>Date</item>
                                            <item>Reason</item>
                                            <item>
                                            <item> <input
                                                 type='checkBox'
                                                 name={"All"}
                                                 onChange={(event)=>selectAllBox(event.target.checked)}
                                                /></item>
                                            </item>
                                        </Stack>
                                       
                            {
                                leave.leaveForApproval.map(le=>{
                                    return(
                                        <Stack direction={"row"} spacing={1} >
                                            <item style={dateLeave}>{moment(le.date).format("DD-MM-YYYY")}</item>
                                            <item>{le.description}</item>
                                            <item>
                                                <input
                                                 type='checkBox'
                                                 name={"chk"}
                                                 onChange={(event)=>handleChange(event,le)}
                                                />
                                            </item>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                        <Button
                          variant='contained'
                          color='primary'
                          sx={{mt:2}}
                          disabled={approve.length===0?true:false}
                          onClick={async()=>{
                           await approveLeave(leave.employeeId,approve)
                           await allLeave(setLeave)
                           handleClose()
                        }}
                        >
                            Submit
                        </Button>
                </Box>
            </Modal>
    </div>
  )
}

export default ApproveLeave