import React from 'react'
import './Quotation.css'
import { planDuration, plan, dummy, SeurityInstallion, planCost } from '../Data'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { serverUrl } from '../ApiUrl'
import { useSelector } from "react-redux";

const Quotation = ({ userInfoData }) => {
    let token = useSelector((state) => state.sar.data.token)
    let [userData, setUserData] = useState({ name: '', lastName: '' })
    let [planD, setPlanD] = useState("Monthly");
    let [planNa, setPlanNa] = useState("1 Room");
    let [planN, setPlanN] = useState(1);
    let [totalOfHeavyAppliances, setTotalOfHeavyAppliances] = useState(0);
    let [install, setInsall] = useState({
        security: 400,
        Installion: 1000,
        quantity: 4
    })
    let [countOfHeavyAppliances, setCountOfHeavyAppliances] = useState(0)

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
        console.log('count', userData)

    }
    const handlePlanDuration = (event) => {
        setPlanD(event.target.value);
    };

    const handlePlan = (event) => {
        setPlanNa(event.target.value);
        if (event.target.value === "Custom") {
            setPlanN(0)
        } else if (event.target.value === "1 Room") {
            setPlanN(1)
        } else if (event.target.value === "1 BHK") {
            setPlanN(2)
        } else if (event.target.value === "2 BHK") {
            setPlanN(3)
        } else if (event.target.value === "3 BHK") {
            setPlanN(4)
        } else if (event.target.value === "4 BHK") {
            setPlanN(5)
        }
        else if (event.target.value === "5 BHK") {
            setPlanN(6)
        } else if (event.target.value === "6 BHK") {
            setPlanN(7)
        }
        console.log(event.target.value);
    };

    const handleChangeCustom = (event) => {
        let quantitys = event.target.value
        let sequrity = quantitys * 100;
        let Installion;
        if (quantitys > 10) {
            let cal = quantitys - 10;
            Installion = 1000 + (cal * 50);
        } else {
            Installion = 1000;
        }
        setInsall({
            planName: "Custom",
            security: sequrity,
            Installion: Installion,
            quantity: quantitys
        })
    }

    const handleHeavyCount = (e) => {
        setCountOfHeavyAppliances( e.target.value );
        console.log('count', countOfHeavyAppliances)
    }

    useEffect(() => {
        if (planN === 0) return;
        setInsall(SeurityInstallion[planN - 1])
    }, [planN])

    const handleData = () => {
        let userObj = {
            first_name: userInfoData ? userInfoData.first_name : userData.name,
            last_name: userInfoData ? userInfoData.last_name : userData.lastName,
            phone: userInfoData ? userInfoData.phone : '0123456789',
            city: userInfoData ? userInfoData.city : '',
            address: userInfoData ? userInfoData.address : '',
            planFrequency: userInfoData ? userInfoData.planDurations : planD,
            plan: userInfoData ? userInfoData.planId : planN,
            origin: userInfoData ? userInfoData.origin : '',
            leadQuality: 1,
            email: userInfoData ? userInfoData.email : 'demo@gmail.com',
            zip: userInfoData ? userInfoData : '',
            comments: userInfoData ? userInfoData.comments : '',
            security: userInfoData ? userInfoData.security : install.security,
            installion: userInfoData ? userInfoData.installation : install.Installion,
            teleCallerId: userInfoData ? userInfoData.teleCallerId : '00',
            refferal: userInfoData ? userInfoData.refferal : [],
            secondarySalesPerson: userInfoData ? userInfoData.secondarySalesPerson : '',
            totalAppliances: userInfoData ? userInfoData.totalAppliances : install.quantity,
            partialAmount: 0
        }

        let planDurations = userObj.planFrequency;
        let qu = userObj.totalAppliances;
        let fee = 0;
        let addon = 0;
        console.log('countOfHeavyAppliancesss',countOfHeavyAppliances)
        if (planDurations === "Monthly") {
            fee = (qu) * 30
          } else if (planDurations === "Quarterly") {
            fee = (qu*planCost.Quarterly) * 90
          } else if (planDurations === "Semi Annualy") {
            fee = (qu*planCost.SemiAnnualy) * 180
          } else if (planDurations === "Annualy") {
            fee = (qu*planCost.Annualy) * 365
          } else if (planDurations === "Test (Weekly)") {
            fee = (qu*planCost.TestWeekly) * 7
          } else if (planDurations === "Two Years") {
            fee = (qu*planCost.TwoYears) * (365*2);
          } else if (planDurations === "Three Years") {
            fee = (qu*planCost.ThreeYears) * (365*3);
          }

        let total = ((fee + userData.installation+addon) * 1.18 + userData.security ).toFixed(2)
        let tax = ((fee + userData.installation+addon) * 0.18).toFixed(2)
        setTotalOfHeavyAppliances(addon);
        console.log('totalOfHeavyAppliances',totalOfHeavyAppliances);
        console.log('fee',fee,'tax',tax,'planduration',planD,'addon',addon);
        userObj = { ...userObj, orignalTotalAmount: total, totalAmount: total, orignalRecursiveAmount: fee, recursiveAmount: fee, tax: tax }
        paymentLinkGen(userObj,addon);
    }

    const paymentLinkGen = (userData,addon) => {
        let data1 = async () => {
            try {
                const resp = await axios.post(
                    `${serverUrl.u}/api/zoho/registerLeadAndLink`,
                    {
                        planId: planN,
                        planFrequency: userData.planFrequency,
                        refferal: userData.refferal,
                        email: userData.email,
                        first_name: userData.first_name,
                        last_name: userData.last_name,
                        phone: userData.phone,
                        city: "Any for Now",
                        zip: "000000",
                        address: "no",
                        teleCallerId: userData.teleCallerId,
                        leadQuality: "0",
                        origin: "",
                        layout: dummy[planN],
                        security: userData.security,
                        comments: "",
                        installation: userData.installion,
                        partialPayment: false,
                        secondarySalesPerson: "empty",
                        discount: 0,
                        orignalRecursiveAmount: userData.orignalRecursiveAmount,
                        orignalTotalAmount: Number(userData.orignalTotalAmount),
                        recursiveAmount: userData.recursiveAmount,
                        totalAmount: Number(userData.totalAmount),
                        addOnAmount: 0,
                        totalAppliances: userData.totalAppliances,
                        partialAmount: 0
                    }
                );
                if (resp.data.success === true) {
                    console.log(resp);

                    await quotationApi(resp.data.data.quotationNumber, userData,addon);
                    //  await createAndDownloadPdf(data)

                }
                if (resp.data.success === false) {
                    console.log(resp.data.message);

                }
            } catch (error) {
                console.log(error);

            }
        };
        if (planN === 0) {
            dummy[planN][0].switchboards[0].appliances.fans = Number(install.quantity);
            data1();
        } else {
            data1();
        }
    }

    const downloadFile = (blob, fileName) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        // setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };

    // const quotationApi = async (quotationNumber, userData) => {
    //     await axios.post(`${serverUrl.u}/misc/getQuotation`,
    //         {
    //             name: userData.first_name,
    //             quoteNo: quotationNumber,
    //             planName:planNa,
    //             planFrequency:userData.planFrequency,
    //             totalAppliances: userData.totalAppliances,
    //             totalOfAppliances: userData.recursiveAmount,
    //             security: userData.security,
    //             isfreeDays: false,
    //             freeDays: 0,
    //             installation: userData.installation,
    //             numberOfHeavyAppliances: countOfHeavyAppliances,
    //             totalOfHeavyAppliances: totalOfHeavyAppliances,
    //             finalTotal: userData.totalAmount,
    //         }, {
    //         responseType: 'blob',
    //         headers: {
    //             AccessToken: token
    //         }
    //     }).then(function (response) {
    //         console.log("quto", response);
    //         downloadFile(new Blob([response.data]), `Quote - ${userData.first_name}.pdf`)
    //     }).catch(function (error) {
    //         console.log("quto   ", error);
    //     })
    // }
    const quotationApi = async (data, userData,addon) => {
        let name = `${userData.first_name} ${userData.last_name}`
        let seq = Number(userData.security);
        console.log('seq',seq);
        let ins = Number(userData.installion);
        let qu = Number(userData.totalAppliances);
        console.log('quuu',qu);
        let planNamess = planNa
        console.log("planNamess",planNamess);
        let planDurations = planD;
        console.log('planDuration',planDurations)
        let fee = userData.recursiveAmount;
        let total = fee + seq + ins + addon
        let tax = (fee + ins + addon) * 0.18;
        console.log("Tax   ", tax);
        let totalTax = total + tax ;
        console.log("bill");
    
        await axios.post(`${serverUrl.t}/api/tele/getQuotation`,
          {
            quoteTo: name,
            quoteNo: data,
            itemsQuantity: { appliances: qu },
            packagePlanDetails: {
              packageName: planNamess,
              planName: planDurations,
              security: seq,
              installation: ins,
              fee: fee,
              total: total
            },
            amounts: {
              subtotal: total,
              tax: tax,
              total: totalTax
            },
            heavyAppliance:{
                count :countOfHeavyAppliances,
                total :addon,
            }
          }, {
          responseType: 'blob',
          headers: {
            AccessToken: token
          }
        }).then(function (response) {
          console.log("quto", response);
          downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`)
        }).catch(function (error) {
          console.log("quto   ", error);
        })
    
      }
    return (
        <div>
            <div className='parentDiv'>
                <div className='childDiv'>

                    <div class="input-group mb-3  textInputs">
                        <label >Name</label>
                        <input type="text" class="form-control singleTextInput" placeholder="Enter Client Name" aria-label="name" name='name' aria-describedby="basic-addon1" onChange={handleChange} />
                    </div>
                    <div class="input-group mb-3  textInputs">
                        <label >LastName</label>
                        <input type="text" class="form-control singleTextInput" placeholder="Enter Client Last Name" aria-label="lastName" name='lastName' aria-describedby="basic-addon1" onChange={handleChange} />
                    </div>

                </div>
                <div className='childDiv'>

                    <div class="input-group mb-3 textInputs">
                        <label >Plan Name</label>
                        <select class="custom-select singleTextInput" required id="inputGroupSelect01" onChange={handlePlan} defaultValue="choose one"
                            name="plan"
                            label="plan"
                            autoComplete="current-plan" value={planNa}>
                            {plan.map((option) => (
                                <option value={option.text}>{option.text}</option>
                            ))}
                        </select>
                    </div>

                    <div class="input-group mb-3 textInputs">
                        <label >Plan Duration</label>
                        <select class="custom-select singleTextInput" required id="inputGroupSelect01" onChange={handlePlanDuration} defaultValue="choose one"
                            name="planDuration"
                            label="planDuration"
                            autoComplete="current-planDuration" value={planD}>

                            {planDuration.map((option) => (
                                <option value={option.value}>{option.value}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {
                    planN === 0 ?
                        <div className='childDiv'>
                            <div class="input-group mb-3  textInputs">
                                <label >Total Appliances</label>
                                <input type="text" class="form-control singleTextInput" placeholder="Enter Total Appliances" aria-label="Appliances" name='appliances' aria-describedby="basic-addon1" onChange={(e) => { handleChangeCustom(e) }} />
                            </div>
                        </div> : null
                }
                <div className='childDiv'>
                    <div class="input-group mb-3  textInputs">
                        <label >Security</label>
                        <input type="text" class="form-control singleTextInput" value={install.security} placeholder="Enter Security Amount" aria-label="Security" name='security' aria-describedby="basic-addon1" />
                    </div>
                    <div class="input-group mb-3  textInputs">
                        <label >Installation</label>
                        <input type="text" class="form-control singleTextInput" value={install.Installion} onChange={(e)=>setInsall({...install,"Installion":Number(e.target.value)})} placeholder="Enter Installation Number" aria-label="Installation" name='installation' aria-describedby="basic-addon1" />
                    </div>
                </div>

                <div className='childDiv'>
                    <div class="input-group mb-3  textInputs" style={{width:"50%"}}>
                        <label >Count Of Heavy Appliances</label>
                        <input type="text" class="form-control singleTextInput" placeholder="Enter Count Of Heavy Appliances" aria-label="Appliances" name='HeavyAppliances' aria-describedby="basic-addon1" onChange={handleHeavyCount} />
                    </div>
                </div>
                <button type="button" class="btn btn-primary btn-download" onClick={handleData}>Download</button>

            </div>
        </div>
    )
}

export default Quotation