import { Box, Button, Modal,Stack } from '@mui/material'
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { allLeave, approveLeave, disapproveLeave } from './Api';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "max-content",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    overflowY:"scroll",
    maxHeight:"85vh",
}
const dateLeave = {
    width:"170px"
}
function DisapproveLeave({leave,setLeave}) {
    const [open, setOpen] = useState(false);
    const [approve,setapprove] = useState([])
    const handleClose = ()=>{
        setapprove([])
        setOpen(false)
    }
    const handleChange = (event,leav)=>{
        if(event.target.checked){
          setapprove([...approve,leav])
        }else{
         let filterLeave = approve.filter(e=>{
             if(e.date!==leave.date && e.description!==leav.description){
                 return e
             }
         })
         setapprove(filterLeave)
        }
     }
  return (
    <div>
    <Button
      variant='contained'
      size='small'
      disabled={leave.currentLeave.length===0?true:false}
      onClick={()=>setOpen(true)}
    >
        DisApprove Leave
    </Button>
   <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="ModelBox">
                   
                <Stack>
                        <Stack direction={"row"} spacing={1}>
                                            <item style={dateLeave}>Date</item>
                                            <item>Reason</item>
                                            <item>Select</item>
                                        </Stack>
                            {
                                leave.currentLeave.map(le=>{
                                    return(
                                        <Stack direction={"row"} spacing={1} >
                                            <item style={dateLeave}>{moment(le.date).format("DD-MM-YYYY")}</item>
                                            <item>{le.description}</item>
                                            <item>
                                                <input
                                                 type='checkBox'
                                                 name={leave.employeeId}
                                                 onChange={(event)=>handleChange(event,le)}
                                                />
                                            </item>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                        <Button
                          variant='contained'
                          color='primary'
                          sx={{mt:2}}
                          disabled={approve.length===0?true:false}
                          onClick={async()=>{
                           await disapproveLeave(leave.employeeId,approve)
                           await allLeave(setLeave)
                           handleClose()
                        }}
                        >
                            Submit
                        </Button>
                </Box>
            </Modal>
    </div>
  )
}

export default DisapproveLeave