import React, { useState } from 'react';
import { Button } from '@mui/material';
import ListPerforma from './ListPerforma';
import CreatePerforma from './CreatePerforma';
import PiWIthoutInvoice from './PiWIthoutInvoice';

export default function PerformaInvoice() {
  // STATES
  const [tab, setTab] = useState('performa');
  const [performaData, setPerformaData] = useState({});
  return (
    <div>
      <div
        className='mt-3'
        style={{
          display: 'flex',
          gap: '20px',
          padding: '10px',
          height: '100%',
        }}
      >
        <Button
          color={tab === 'performa' ? 'primary' : 'info'}
          variant={tab === 'performa' ? 'contained' : 'outlined'}
          onClick={() => setTab('performa')}
        >
          User Proforma
        </Button>
        <Button
          color={tab === 'performa' ? 'primary' : 'info'}
          variant={tab === 'createPerforma' ? 'contained' : 'outlined'}
          onClick={() => setTab('createPerforma')}
        >
          Create Proforma
        </Button>
        <Button
          color={tab === 'piWithoutInvoice' ? 'primary' : 'info'}
          variant={tab === 'piWithoutInvoice' ? 'contained' : 'outlined'}
          onClick={() => setTab('piWithoutInvoice')}
        >
          PI Without Invoice
        </Button>
      </div>

      <div style={{ margin: '30px', height: '100%' }}>
        {tab === 'performa' ? (
          <ListPerforma setPerformaData={setPerformaData} setTab={setTab} />
        ) : null}
        {tab === 'createPerforma' ? (
          <CreatePerforma
            setTab={setTab}
            currentTab={tab}
            data={performaData}
          />
        ) : null}
        {tab === 'piWithoutInvoice' ? <PiWIthoutInvoice /> : null}
      </div>
    </div>
  );
}
