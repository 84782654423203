import { AppBar, Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import ListInvoices from "./components/ListInvoices";

export default function Invoices() {
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    console.log("HRERE");
    return (
        <div className="invoice">
            <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <AppBar
                        position="static"
                        color="transparent"
                        sx={{ bgcolor: "#f1f0f0", color: "white" }}
                    >
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            <Tab label="Invoices" />
                        </Tabs>
                    </AppBar>

                    <Box sx={{ p: 4 }}>
                        {tab === 0 ? <ListInvoices /> : null}
                    </Box>
                </Box>
            </div>
        </div>
    );
}
