import axios from "axios"
import { addressLayout } from "../actionIndex"
import { serverUrl } from "../../ApiUrl"
import store from '../index'
import { user_roles } from "../../Data"

const fetchAddressLayout =(data)=>{
    console.log(data,"ddd");
    return{
        type:addressLayout.FETCH_DATA,
        payload:data
    }
}
export const updateAddressLayout = (data)=>{
    console.log(data,"update");
    return{
        type:addressLayout.UPDATE_DATA,
        payload:data
    }
}
export const removeAddressLayout = (data)=>{
    return{
        type:addressLayout.REMOVE_DATA,
        payload:data
    }
}

export const fetchAddressLayoutApi = (userDetail,dispatch)=>{
    let data = async () => {
        await axios.post(`${serverUrl.u}/api/tele/getAllAddressAndLayoutSubscriptionParticular/?p=${0}`, {
          teleCallerId:store.getState().sar.data?.user?.roles.includes(user_roles.Isp)?store.getState().sar.data?.user?.employeeId :userDetail?.first_name
        }).then(function (res) {
            console.log(res.data.alisteSubscription);
            dispatch(fetchAddressLayout(res.data.alisteSubscription))
        }).catch(function (err) {
            
        })
    }
    let dataAll = async () => {
      
        await axios.get(`${serverUrl.u}/api/tele/getAllAddressAndLayoutSubscription/?p=${0}`).then(function (res) {
            console.log(res.data.alisteSubscription,"HHHHH");
            dispatch(fetchAddressLayout(res.data.alisteSubscription))

        }).catch(function (err) {
            
        })
    }  
    userDetail.roles.includes("Super Admin")?dataAll():data()
}