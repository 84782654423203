import { Box } from '@mui/material'
import Button from "@mui/material/Button";
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getFilledDetail } from '../../Function/AxiosControl'
import SelectCityInput from '../../city/SelectCityInput'
import { ispPlanDuration } from '../../Data'
import { notify, notifyFailed } from '../../Common/Notification'
import { registerLeadAndLink } from './function'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertSuccessModel from '../../Common/AlertSuccessModel';

export default function ClientRegister() {
    let demoAppliance = 4
    let [open,setOpen] = useState(false)
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    let token = useSelector((state) => state.sar.data?.token)
    const [alreadyRegister, setAlreadyRegister] = useState(false)
    const [alreadyRegisterUser, setAlreadyRegisterUser] = useState({})
    const [city, setCity] = useState("")
    let [reloadLink, setReloadLink] = useState(false)
    let [okBtn, setOkBtn] = useState(false)
    let [qutoBtnText, setQutoBtnText] = useState("Download")
    let [ok, setok] = useState(false)
    let [paymentLink, setPaymentLink] = useState("")
    let [errorsApi, setErrorApi] = useState(false);
    

    const callFilled = async (number) => {
        if (number.length < 10) return;
        let res = await getFilledDetail(number);
        if (res[1] === "Client Already Register") {
            setAlreadyRegister(true)
            // notify("Client Already Register With this Number")
        } else if (res[1] === "New Client" && res[0].data.success === true) {
            setAlreadyRegisterUser(res[0].data.data.user)
        }else{
            setAlreadyRegister(false)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("planDuration") === "") {
            notifyFailed("Select Plan Frequency")
            return
        }
        let planDetail = JSON.parse(data.get("planDuration"))
        let userData = {
            first_name: data.get("fname"),
            last_name: data.get("lname"),
            phone: data.get("phoneNumber"),
            city: data.get("city"),
            address: data.get("address"),
            planType: planDetail.value,
            plan: planDetail.id,
            email: data.get("email"),
            zip: data.get("zipcode"),
            security: 0,
            installion: 0,
            teleCallerId: employeeId,
            partialPayment: true,
            teleCallerSecond: "empty",
            totalAppliances: Number(data.get('appliance')),
            partialAmount: 0,

        }

        userData = {
            ...userData,
            orignalTotalAmount: (planDetail.rate*userData.totalAppliances) * 1.18,
            totalAmount: (planDetail.rate*userData.totalAppliances) * 1.18,
            orignalRecursiveAmount: planDetail.rate*userData.totalAppliances,
            recursiveAmount: planDetail.rate*userData.totalAppliances,
            tax: (planDetail.rate*userData.totalAppliances) * 0.18,
            refferal: "",
            city: city,
            planId: 0,
            zip: "",
            comments: "",
            leadQuality: 1,
            origin: "Isp",
            paymentMode: "",
            reference_id: "",
            incentiveZone: city,
            ispSubscription: true
        }
        registerLeadAndLink(userData, setQutoBtnText, setReloadLink, setErrorApi, setok, setOkBtn,token,setOpen)
    }

    return (
        <div style={{ padding: "0 4%", marginTop: "4vh" }}>
              <AlertSuccessModel open={open} setOpen={setOpen}/>
            <div>
                <Box component="form" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
                    <div className="make_quto">
                        <div className="row">
                            <div className="col-6">
                                <label className="create-label1">Phone Number <span className="required_star">*</span></label>
                                <div class="input-group mb-1 ">
                                    <input type="text"
                                        class="form-control"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        autoComplete="current-phoneNumber"
                                        required
                                        onChange={(ele) => callFilled(ele.target.value)}
                                        placeholder="Phone Number"
                                        aria-label="phoneNumber"
                                        aria-describedby="basic-addon1" />
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="create-label1">Address <span className="required_star">*</span></label>
                                <div class="input-group mb-1 ">
                                    <input type="text"
                                        class="form-control"
                                        id="address"
                                        name="address"
                                        autoComplete="current-address"
                                        required
                                    
                                        placeholder="address"
                                        aria-label="address"
                                        aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div>
                    </div>
                
                                <div className="make_quto">
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="create-label1">First Name <span className="required_star">*</span></label>
                                            <div class="input-group mb-1">
                                                <input type="text"
                                                    class="form-control"
                                                    id="fname"
                                                    name="fname"
                                                    autoComplete="fname"
                                                    defaultValue={alreadyRegisterUser?.name}
                                                    required
                                                    placeholder="First Name"
                                                    aria-label="fname"
                                                    aria-describedby="basic-addon1"
                                                    onFocus={(e) => { console.log(e.target.value) }} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label className="create-label1">Last Name</label>
                                            <div class="input-group mb-1">
                                                <input type="text"
                                                    class="form-control"
                                                    id="lname"
                                                    name="lname"
                                                    autoComplete="current-lname"
                                                    placeholder="Last Name"
                                                    aria-label="lname"
                                                    aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="create-label1">Email <span className="required_star">*</span></label>
                                            <div class="input-group ">
                                                <input type="email" class="form-control" required defaultValue={alreadyRegister?.email} id="email" name="email" autoComplete="current-email" placeholder="Email" aria-label="email" aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label className="create-label1">City <span className="required_star">*</span></label>
                                            <div class="input-group ">
                                                <SelectCityInput value={city} setValue={setCity} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="make_quto">
                                    <div className="row">
                                    <div className="col-6">
                                    <label className="create-label1">Total Appliances</label>
                                    <div class="input-group ">
                                        <input type="text" defaultValue={4} class="form-control" id="appliance" name="appliance" autoComplete="current-appliance" placeholder="Total appliances" aria-label="appliance" aria-describedby="basic-addon1"/>
                                    </div>
                                    </div>
                                        <div className="col-6">
                                            <label className="create-label1">Plan Duration <span className="required_star">*</span></label>
                                            <div className="input-group ">
                                                <select className="custom-select height-select" required id="inputGroupSelect01"
                                                    name="planDuration"
                                                    label="planDuration"
                                                    autoComplete="current-planDuration"
                                                >
                                                    <option value="">Select Plan Duration</option>
                                                    {ispPlanDuration.map((option) => (
                                                        <option value={JSON.stringify(option)}>{option.value}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div style={{ marginLeft: "1%" }}>
                                    <div className="btn-create-container">
                                        <div className="btn-create">
                                            {
                                                (city !== undefined && city !== "") ?
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        style={{
                                                            pointerEvents: `${qutoBtnText === "Processing..." ? "none" : ""}`,
                                                            backgroundColor: `${qutoBtnText === "Processing..." ? "#cccccc" : "#2552B2"}`
                                                        }}
                                                        onClick={() => {
                                                            setReloadLink(false)
                                                        }}>
                                                        {qutoBtnText}
                                                    </Button> : null
                                            }
                                        </div>

                                        
                                    </div>
                                </div>
                            

                </Box>


                
            </div>
        </div>
    )
}
