import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "../../ConvertedLead.css";
import "../../DispatchData.css";
import "../../PaymentLinkGen.css";
import "../../PartialPayment.css";
import moment from "moment/moment";
import Qutoes from "../../Qutoes";
import { serverUrl } from "../../ApiUrl";
import { Grid, Stack } from "@mui/material";
import { useSelector } from "react-redux";

import { state, user_roles } from "../../Data";
import AddressUpdate from "../../Function/AddressUpdate";
import SearchDataWithStage from "../../Function/SearchDataWithStage";
import { addGrevianceClient } from "../../ApiCalls/AddGreviance";
import CommentUdate from "../../Function/CommentUdate";
import { notify } from "../../Common/Notification";
import IspClientSearch from "./IspClientSearch";
import ManualSwitchBlock from "../../SearchClient/ManualSwitchBlock";
import PartialUpgradeDownGrade from "../../SearchClient/PartialUpgradeDownGrade";
import HouseDeviceActivateDeactivate from "../../SmallComponent/HouseDeviceActivateDeactivate";
export default function PaidClients() {
  let [details, setDetails] = useState([]);
  let [displayData, setDisplayData] = useState([]);
  let [GerivanceType, setGrivanceType] = useState("Misc");
  let [filterOption, setFilterOption] = useState(false);
  let [update, setUpdateData] = useState({});
  let [q, setq] = useState("");
  let grivance = useRef(null);
  let grivanceDate = useRef(null);
  let grivanceComment = useRef(null);
  let gstName = useRef(null);
  let gstNumber = useRef(null);
  let [reloadW, setReload] = useState(false);
  const [searchParam] = useState(["phone", "name"]);

  let [grivanceDisplay, setGrivanceDisplay] = useState(false);
  let [addOnDisplay, setAddOnDisplay] = useState(false);
  let [deviceObject, setDeviceObject] = useState({});
  let [gstContainer, setGstContainer] = useState(false);
  let [page, setPage] = useState(1);
  let [totalPage, setTotalPage] = useState(1);

  let userRoles = useSelector((state) => state.sar.data.user.roles);
  let teleCallers = useSelector((state) => state.sar.data.user.employeeId);
  let firstName = useSelector((state) => state.sar.data.user.first_name);
  let data = async () => {
    await axios
      .post(
        `${serverUrl.u}/api/tele/getAllSubscriptionsParticularss/?p=${
          page - 1
        }`,
        {
          teleCallerId: userRoles.includes(user_roles.Isp)
            ? teleCallers
            : firstName,
        }
      )
      .then(function (response) {
        let detail = response.data.data.filter(
          (e) => e.partialPayment === false
        );
        setTotalPage(response.data.totalPage);
        console.log(detail);
        setDetails(detail);
        setDisplayData(detail);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    data();
  }, [reloadW, page]);
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return item[newItem].indexOf(q.toLowerCase()) > -1;
      });
    });
  }

  const changeGstName = async (name, number, e) => {
    await axios
      .post(`${serverUrl.u}/api/tele/changeGSTName`, {
        generatedHex: e.generatedHex,
        boole: name,
      })
      .then(function (res) {
        console.log(res);
        changeGstNumber(number, e);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const changeGstNumber = async (number, e) => {
    await axios
      .post(`${serverUrl.u}/api/tele/changeGSTNumber`, {
        generatedHex: e.generatedHex,
        boole: number,
      })
      .then(function (res) {
        console.log(res);
        setGstContainer(false);
        setReload(!reloadW);
      })
      .catch(function (error) {
        console.log(error);
      });
    setReload(!reloadW);
  };
  const updateGstDetails = async (name, number, e) => {
    await axios
      .post(`${serverUrl.u}/api/tele/changeGSTStatus`, {
        generatedHex: e.generatedHex,
        boole: true,
      })
      .then(function (res) {
        console.log(res);
        setReload(!reloadW);
        changeGstName(name, number, e);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const colorDecide = (text) => {
    if (text === "dispatch") {
      return "rgb(251, 37, 118)";
    }
    if (text === "ready to install") {
      return "green";
    }
    if (text === "installed") {
      return "rgb(51, 47, 208)";
    }
  };

  const filterOnCity = (name) => {
    if (name === "all") {
      setDisplayData(details);
    } else {
      let data = details.filter((e) => e.city == name);
      setDisplayData(data);
    }
  };
  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="input-field">
        <IspClientSearch
          setDisplayData={setDisplayData}
          stage={"All"}
          partialClient={false}
          employeeId={teleCallers}
        />
      </div>
      {filterOption === true ? (
        <div className="row">
          <div className="col-2">
            <label className="create-label1">City</label>
            <div class="input-group ">
              <select
                class="custom-select height-select"
                onChange={(e) => filterOnCity(e.target.value)}
                defaultValue="all"
              >
                <option value="all">All</option>
                <option value="Any for Now">Any for Now</option>
                {state?.map((option) => (
                  <option value={option.value}>{option.value}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      ) : null}
      <nav aria-label="..." className="pagination-container">
        <ul class="pagination">
          {[...Array(totalPage)].map((e, i) => {
            return (
              <li
                className={page === i + 1 ? "page-item active" : "page-item"}
                onClick={() => setPage(i + 1)}
              >
                <a className="page-link" href="#">
                  {i + 1}{" "}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="table-container1" style={{ marginTop: 0 }}>
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th scope="col" style={{ fontWeight: "bold" }}>
                Name
              </th>
              <th>Client Status</th>
              <th>Hex Code</th>
              <th>
                Address<span style={{ color: "red" }}>*</span>
              </th>
              <th scope="col">City</th>
              <th scope="col" style={{ width: "110px" }}>
                Phone
              </th>
              <th scope="col">Comments</th>
              <th scope="col">Selected Plan</th>
              <th scope="col">Appliances</th>
              <th>Payment Status</th>
              <th style={{ color: "#FB2576" }}>Security</th>
              <th style={{ color: "#FB2576" }}>Installation</th>
              <th style={{ color: "#FB2576" }}>RecursiveAmount</th>
              <th style={{ color: "#0002A1" }}>Total Amount</th>
              <th>
                Gst Detail<span style={{ color: "red" }}>*</span>
              </th>
              <th>Gst Name</th>
              <th>Gst Number</th>
              <th>Created At</th>
              <th>Mandate Date</th>
              <th>Installation Date</th>
              <th style={{ width: "130px" }}>subscriptionStage</th>
              <th>App Status</th>
              <th style={{ width: "310px" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {displayData !== 0
              ? search(displayData).map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1 + (page - 1) * 30}</td>
                      <td
                        className="pr-15 pl-55"
                        style={{
                          color: `${e.installed === true ? "green" : "black"}`,
                          fontWeight: "bold",
                        }}
                      >
                        {e.name}
                      </td>
                      <td
                        style={{
                          color: e.dropped === false ? "#008000" : "#FF0000",
                        }}
                      >
                        {e.dropped === true ? "Dropped" : "Registered"}
                      </td>
                      <td
                        style={{
                          color: `${
                            e.partialPayment === true ? "orange" : "black"
                          }`,
                        }}
                      >
                        {e.generatedHex}
                      </td>
                      <AddressUpdate
                        e={e}
                        setReload={setReload}
                        reloadW={reloadW}
                        page={"paidOrder"}
                      />
                      <td>{e.city}</td>
                      <td>
                        <a href={`tel:${e.phone}`}>{e.phone}</a>
                      </td>
                      <CommentUdate
                        e={e}
                        setReload={setReload}
                        reloadW={reloadW}
                        page={"paidOrder"}
                      />
                      <td>{e.planFrequency}</td>
                      <td>{e.totalAppliances}</td>
                      <td
                        style={{
                          color: `${
                            e.partialPayment === false
                              ? "green"
                              : e.partialAmount === 0
                              ? "red"
                              : "orange"
                          }`,
                        }}
                      >
                        {e.partialPayment === false
                          ? "Paid"
                          : e.partialAmount === 0
                          ? "Zero"
                          : `Partial`}
                      </td>
                      <td style={{ color: "#FB2576" }}>{e.security}</td>
                      <td style={{ color: "#FB2576" }}>{e.installation}</td>
                      <td style={{ color: "#FB2576" }}>{e.recursiveAmount}</td>
                      <td style={{ color: "#0002A1" }}>
                        {e.totalAmount.toFixed(2)}
                      </td>
                      <td
                        onClick={() => {
                          setUpdateData(e);
                          setGstContainer(true);
                        }}
                      >
                        {e.isGstClient === true
                          ? `${e.gstName}  ${e.gstNumber}`
                          : "No"}
                      </td>
                      <td>{e.gstName}</td>
                      <td>{e.gstNumber}</td>
                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <td>{moment(e.mandateDate).format("MMM Do")}</td>
                      <td>
                        {moment(e.InstallationDate).format("MMM Do") !==
                        "Invalid date"
                          ? moment(e.InstallationDate).format("MMM Do")
                          : ""}
                      </td>
                      <td style={{ color: colorDecide(e.subscriptionStage) }}>
                        {e.subscriptionStage}
                        {e.grivience === true ? "  &  Grivience" : ""}
                      </td>
                      <td>{e.active?"Active":"De-Active"}</td>
                      <td>
                        <Stack spacing={2} direction="row">
                          <HouseDeviceActivateDeactivate
                            e={e}
                            reload={reloadW}
                            SetReload={setReload}
                            notify={notify}
                          />
                        </Stack>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>

      {gstContainer === true ? (
        <div className="update-form">
          <span className="cross1" onClick={() => setGstContainer(false)}>
            X
          </span>
          <label className="create-label1">Gst Name</label>
          <div class="input-group ">
            <input
              type="text"
              class="form-control"
              ref={gstName}
              required
              aria-label="referenceId"
              aria-describedby="basic-addon1"
            />
          </div>

          <label className="create-label1">Gst Number</label>
          <div class="input-group ">
            <input
              type="text"
              class="form-control"
              ref={gstNumber}
              required
              aria-label="referenceId"
              aria-describedby="basic-addon1"
            />
          </div>

          <button
            className="btn btn-primary"
            style={{ marginTop: "15px" }}
            onClick={() =>
              updateGstDetails(
                gstName.current.value,
                gstNumber.current.value,
                update
              )
            }
          >
            Update
          </button>
        </div>
      ) : null}
      <Qutoes />
    </div>
  );
}
