import React from 'react'
import axios from 'axios'
import { pages, serverUrl } from '../ApiUrl'
import { useDispatch } from 'react-redux'
import { updateInstallationData } from '../Redux/installationActions'
import { updateAddressLayout } from '../Redux/Action/adderess&layout'
import moment from 'moment'

export default function CommentUdate({e,SetWebsiteReload,websiteReload,page}) {
    const dispatch = useDispatch()
    const Update = async(comment,data)=>{
        await axios.post(`${serverUrl.u}/api/tele/updateCommentsHex`,{
          generatedHex :data.generatedHex,
          comments:`${comment} - ${moment(new Date()).format("DD-MM-YYYY")}`
        }).then(function (res) {
          console.log(res);
          if(page===pages.installation){
            dispatch(updateInstallationData(res.data.data.cart))
          }else if(page===pages.addressLayout){
            dispatch(updateAddressLayout(res.data.data.cart))
          }
          else{
            SetWebsiteReload(!websiteReload);
          }
        }).catch(function (error) {
          console.log(error);
        })
      }
  return (
    <td><div className="hover-wide" contenteditable="true" suppressContentEditableWarning={true} onBlur={(ele)=>Update(ele.target.innerText,e)} >{e.comments}</div></td>
  )
}
