import React from 'react'
import { notifyFailed } from './Notification';
import { pages } from '../ApiUrl';
import { useEffect } from 'react';
import { useState } from 'react';
import { Box, Button, Modal, Stack, Typography, setRef } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
};

function ModelCommon({ agree, cancel="", text, btnName, page, input = false,setComment,label="" }) {
    const [refesh, setRefresh] = useState(false)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)

    };
    


    return (
        <div>
            <Button
                variant='contained'
                className={page === pages.elctrician ? "btn1 btn-primary" : "btn btn-primary"}
                style={{ width: page.elctrician ? "100% !important" : "" }}

                onClick={() => {

                    setOpen(true)
                }}
            >
                {btnName}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h4">
                        Confirmation
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {text}
                        {
                            input && (
                                <>
                                    <label className="specific-label">{label===""?"Enter Comment":label} </label>
                                    <div className="input-group ">
                                        <input type="text"
                                            className="form-control"
                                            required
                                            aria-label="referenceId"
                                            name="comment"
                                            onChange={(event)=>setComment(event.target.value)}
                                            autoComplete="current-comment"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                </>
                            )
                        }
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={"flex-end"} mt={5}>
                        <Button variant="contained" sx={{ backgroundColor: "#9FA6B2" }} onClick={() => {
                            if(cancel!==""){
                                cancel()
                            }
                            setOpen(false)
                            }}>No</Button>

                        <Button variant="contained" color='primary'
                            onClick={() => {
                                agree()
                                handleClose()
                            }}
                        >
                            Yes</Button>

                    </Stack>
                </Box>
            </Modal>


        </div>
    )
}

export default ModelCommon