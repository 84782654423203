import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { daDK } from "@mui/x-data-grid";
import { notify, notifyFailed } from "../Common/Notification";
import store from "../Redux";
import { locationLog } from "../Redux/Action/locationLog";

export const typeOfLocaionLogs = {
  start: "day start",
  reach: "site reached",
  leave: "site leave",
  end: "end day",
  other: "other",
};
const logOneTime = ["site reached","site leave","end day"]


function errors(err) {
  notifyFailed(err.message);
  return { success: false, message: err.message, data: {} };
}

function success(position) {
  return {
    success: true,
    message: "Location fetched successfully",
    data: position,
  };
}

var options = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 0,
};

function getCurrentPosition(options) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

export const getLocationAccess = async () => {
  if (navigator.geolocation) {
    return await navigator.permissions
      .query({ name: "geolocation" })
      .then(async function (result) {
      
        if (result.state === "granted") {
          // If granted then you can directly call your function here
          try {
            let position = await getCurrentPosition(options);
            return success(position);
          } catch (err) {
            return errors(err);
          }
        } else if (result.state === "prompt") {
          // If prompt then the user will be asked to give permission
          try {
            let position = await getCurrentPosition(options);
            return success(position);
          } catch (err) {
            return errors(err);
          }
        } else if (result.state === "denied") {
          notifyFailed("Allow Location Permissions");
          return { success: false, message: "Allow Location Permissions" };
        }
      });
  } else {
    notifyFailed("Geolocation is not supported by this browser.");
    return {
      success: false,
      message: "Geolocation is not supported by this browser.",
    };
  }
};

export const distancelog = async (employeeId) => {
  let currentDate = new Date().setHours(0, 0, 0, 0);
  let nextDate = new Date();
  nextDate.setDate(new Date().getDate() + 1);
  return await axios
    .post(`${serverUrl.u}/api/electrician/fetchLog`, {
      employeeId: employeeId,
      firstDate: currentDate,
      lastDate: nextDate,
    })
    .then((res) => {
     
      if (res.data.success) {
        if (res.data.data.length !== 0) {
          let firstLog = res.data.data[0];
     
          if (firstLog.type === typeOfLocaionLogs.start) {
            store.dispatch(locationLog(currentDate));
          }
        }
        return {
          success: true,
          data: res.data.data,
          message: res.data.message,
        };
      } else {
        return { success: false, message: res.data.message };
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message
        ? err?.response?.data?.message
        : err.message;

      return { success: false, message: message };
    });
};

export const electicianLocationLog = async (
  employeeId,
  type,
  sub,
  reson = ""
) => {
  return { success: true }
  let res = await getLocationAccess();
  if (!res.success) {
    notifyFailed(res.message);
    return { success: false };
  }

  if (type !== typeOfLocaionLogs.start) {
    // check there is any start log of current date
    let log = await distancelog(employeeId);
    if (log.success) {
      
      if (log.data.length === 0) {
        return { success: false };
      } else {
        let firstLog = log.data[0];
        if (firstLog.type !== typeOfLocaionLogs.start) {
          return { success: false };
        }
      }
    }
  }
  if(logOneTime.includes(type)){
    let distancelogRes = await distancelog(employeeId)
   
    let findd = []
    if(distancelogRes.success===true && distancelogRes.data.length>0){
      findd = distancelogRes.data.filter(e=>{
        if(e.siteDetails!==undefined && e.type===type && e.siteDetails.generatedHex===sub.generatedHex){
          return e
        }
      })
  
      if(findd!==undefined && findd.length>0){
        return { success: true };
      }
    }
    
  }
  return axios
    .post(`${serverUrl.u}/api/electrician/log`, {
      employeeId: employeeId,
      location: {
        latitude: res.data.coords.latitude,
        longitude: res.data.coords.longitude,
      },
      timestamp: res.data.timestamp,
      type: type,
      reson: reson,
      siteDetails: {
        generatedHex: sub.generatedHex,
        clientName: sub.name,
        siteType: `${
          sub.toBePickedUp === true
            ? "UnInstallation Site"
            : sub.grivience === true
            ? "Grivience Site"
            : sub.grivience === false && sub.finalInstalled === false
            ? "Fresh Site"
            : "  "
        }${sub.ispSubscription === true ? " ISP Client" : ""}`,
      },
    })
    .then((res) => {
   
      if (res.data.success) {
        notify(res.data.message);
        if (type === typeOfLocaionLogs.start) {
          store.dispatch(locationLog(new Date().setHours(0, 0, 0, 0)));
        }
        return { success: true };
      } else {
        notifyFailed(res.data.message);
        return { success: true, locationSuccess: false };
      }
    })
    .catch((err) => {
  
      let message = err?.response?.data?.message
        ? err?.response?.data?.message
        : err.message;
      notifyFailed(message);
      return { success: true, locationSuccess: false, message: message };
    });
};
