import React from 'react'
import { useState } from 'react'
import { state } from '../Data';
import { useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { useEffect } from 'react';

export default function ZoneRemove({e,reload,setReload,notify}) {
    let [pop,setPop] = useState(false)
    let [zone,setZone] = useState([])
    let Select = useRef(null)
    const handleSubmit = (event)=>{
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data.get('city'));
        console.log(Select.current.state.selectedValues);
        let city = Select.current.state.selectedValues.map((e)=>{
               return e.value
        })
        console.log(city,"k");
        axios.post(`${serverUrl.u}/api/tele/removeZoneOfElectrician`,{
            "employeeId":e.employeeId,
            "newZone":city
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
            setReload(!reload)
            notify(res.data.message)
            setPop(false)
            }else{
                notify(res.data.message)
            }
        }).catch(function (err) {
            console.log(err);
            notify("Failed")
        })
    }
    const makeZone=()=>{
        let arr=[]
        for(let i of e.zone){
            arr.push({value:i})
        }
        console.log(arr,"arr");
        setZone(arr)
    }
    useEffect(()=>{
     makeZone()
    },[])
    
  return (
    <>
       <td><button className='btn btn-primary' onClick={()=>setPop(true)}>Remove Zone</button></td>
       {
        pop===true?
        <div style={{position:"fixed",top:0,left:0,height:"100vh",width:"100vw",overflowX:"scroll",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div style={{width:"300px",backgroundColor:"whitesmoke",padding:"25px",borderRadius:"12px",position:"relative"}}>
                <div style={{width:"100%",textAlign:"right",cursor:"pointer"}} onClick={()=>setPop(false)}>X</div>
                <form onSubmit={handleSubmit}>
                <button className='btn btn-primary' type='submit'>Submit</button>

                <div className="row">
             <div className="col-12">
              <label>City</label>
              <div class="input-group mb-1" >
                 {
                    zone.length!==0?
                    <Multiselect
                    displayValue="value"
                    options={zone}
                     ref={Select}
                    style={{
                        width: "100px !important",
                        multiselectContainer: {
                            marginTop: "1px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />:null
                 }
                
             </div> 
            </div>  
          </div>
                </form>
            </div>

        </div>:null
       }
    </>
  )
}
