import React from 'react'
import { useState } from 'react'
import './CreateHome.css'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { subHistory } from '../Function/SubscriptionHistory'
import { useSelector } from 'react-redux'
import { notifyFailed } from '../Common/Notification'

export default function CreateHome({ dataObject, notify }) {
  let [pop, setPop] = useState(false)
  let [btn, setBtn] = useState("Create Home")
  let [type, setType] = useState('number')
  let [locationCoor,setLocationCoor] = useState({})
  let [loginId,setLoginId] = useState("")
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  function success(pos) {
    let crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    handleSubmit(crd)
}
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    notifyFailed(err.message)
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocationAccess = async(event) => {
    event.preventDefault();
    const Formdata = new FormData(event.currentTarget);
    if (navigator.geolocation) {
     await navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
           navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
           navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            notifyFailed("Allow Location Permissions")
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
      notifyFailed("Geolocation is not supported by this browser.")
   
    }
  }

  const handleSubmit = async (locationDetails) => {
    setBtn('Processing...')
   
    let number = loginId
    if (number.length === 0) {
      notifyFailed("Enter Id")
      setBtn("Create Home")
      return
    }

    const cleanNumber = (num) => {
      try {
        let cn = String(num).replace(/\D/g, ''); // replace non digits
        if (cn.startsWith('0')) {
          // the number is starting with 0, remove it
          cn = cn.slice(1, cn.length);
        }
        if (cn.length > 10) {
          cn = cn.slice(cn.length - 10, cn.length);
        }
        return `+91${cn}`;
      } catch (_) {
        return num;
      }
    };
    if (type === "number") {

      number = await cleanNumber(number);
    }
    
   
    
    axios.post(`${serverUrl.u}/api/sub/createHouse`, {
      masters: [number],
      createdBy: number,
      generatedHex: dataObject.generatedHex,
      latitude:locationDetails.latitude,
      longitude:locationDetails.longitude
    }).then(function (res) {
      console.log(res);
      if (res.data.success === true) {
        notify(res.data.message)
        subHistory(`Create Home by ${loginName}`, dataObject, "Create Home", number)
        window.location.reload()
      } else {
        notify(res.data.message)
      }
      setBtn("Create Home")
    }).catch(function (err) {
      console.log(err);
      notify("Failed")
      setBtn("Create Home")
    })
  }
  return (
    <>
      <button className={btn === "Create Home" ? 'btn1 btn-primary' : "btn1 btn-secondary"} onClick={() => setPop(true)} style={{ pointerEvents: btn === "Create Home" ? "" : "none" }}>{btn}</button>

      {
        pop === true ?
          <div className='homeContainer'>
            <div style={{ position: "relative", width: "100%", zIndex: 344433 }}><span style={{ position: "absolute", right: "5px" }} onClick={() => setPop(false)}>X</span></div>
            <form onSubmit={(event)=>getLocationAccess(event)}>
              <label style={{ width: '90%' }}>Enter Electrician Number/Email Register With Aliste App?</label>
              <div className="input-group mb-3">
                <select
                  onChange={(val) => setType(val.target.value)}
                  className="form-control"
                  value={type}
                  required
                >
                  <option value={'number'}>Phone</option>
                  <option value={'email'}>Email</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <input
                  type={type}
                  name="number"
                  className="form-control"
                  autoComplete='current-number'
                  onChange={(e)=>{setLoginId(e.target.value)}}
                  required
                />
              </div>
              <button type='submit' className='btn btn-primary' style={{ pointerEvents: btn === "Create Home" ? "" : "none" }}>{btn}</button>
            </form>
          </div> : null
      }
    </>
  )
}
