import axios from "axios"
import { serverUrl } from "../ApiUrl"

export const siteVistToOther = async(type,sub,detail)=>{
  return await axios.post(`${serverUrl.u}/app/tele/turnSiteVisitToSubscription`,{
    "orderType":type,
    "generatedHex":sub.generatedHex,
    "details":detail
   }).then(function (res) {
      return {res:res}
   }).catch(function (err) {
      return {err:err}
   })
}