import React, { useRef, useState } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import './ChangeOtherProblem.css'

export default function OtherProblemChange({e,notify,reload,setReload}) {
    let [btnText,setBtnText] =useState("Other Problem")
    let reson = useRef("No");
  const changeOtherProblem = (text)=>{
     setBtnText("Processing...")
      axios.post(`${serverUrl.u}/api/tele/changeOtherProblem`,{
        generatedHex:e.generatedHex,
        OtherProblemDesc:text,
      }).then(function (res) {
         console.log(res);
         if(res.status===200){
            notify(res.data.message)
            setBtnText("Other Problem")
            setReload(!reload)
         }else{
            notify(res.data.message)
            setBtnText("Failed")
         }
      }).catch(function (err) {
         console.log(err);
         notify(err.message)
         setBtnText("Failed")
      })
  }

  return (
    <>
        
    <button className='btn btn-primary' onClick={()=>setBtnText("Processing...")}>{btnText}</button>
    {
        btnText==="Processing..."?
        <div className='otherProblemContainer'>

            <div onClick={()=>setBtnText("Other Problem")} style={{float:"right",cursor:"pointer",fontSize:"18px"}}>X</div>
            <div style={{marginBottom:"5px",}}></div>
             <label className="create-label1 ">Description Of Other Problem</label>
              <div class="input-group mb-2 ">
              <input type="text" 
                       class="form-control" 
                       ref={reson}
                       placeholder="Problem Description" 
                       aria-describedby="basic-addon1" />
              </div>
              <button className='btn btn-primary' onClick={()=>changeOtherProblem(reson.current.value)}>Submit</button>
        </div>:null
    }
    </>
  )
}
