import axios from "axios";
import { useState } from "react";
import Spinner from "../Function/Spinner";

export default function InstallationReport() {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [city, setCity] = useState("All");
  const [teleCallerId, setTeleCallerId] = useState("All");

  const [loading, setLoading] = useState(false);

  const [response, setResponse] = useState([]);

  // const [allStatus, setAllStatus] = useState([]);

  function calculateTotal(status) {
    let total = 0;

    for (let i = 0; i < response.length; i++) {
      total += response[i][telecallerNames[i]][status]?.count;
    }

    return total;
  }

  async function getData() {
    if (firstDate === "" && lastDate === "") {
      return;
    }

    setLoading(true);
    await axios
      .post(
        `https://subscriptioncloud.alistetechnologies.com/api/number/getInstallationRecord`,
        {
          city,
          teleCallerId,
          dayStart: firstDate,
          dayEnd: lastDate,
        }
      )
      .then((res) => {
        setLoading(false);
        setResponse(res.data.finalData);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const telecallerNames = [];

  for (const tc of response) {
    if (tc) {
      telecallerNames.push(Object.keys(tc)[0]);
    }
  }

  // const allStatus = response && response[0][telecallerNames[0]]

  //   console.log("RESPONSE", Object.values(response[0]))

  //   console.log("RESPONSE", Object.keys(Object.values(response[0])[0]))
  const allStatus =
    response?.length && Object.keys(Object.values(response[0])[0]);

  // const allStatus = Object.keys(response[0]);
  // console.log("ALLSTATUS", Object.keys(response[0][telecallerNames[0]]))
  // for (const tc of telecallerNames) {
  //     console.log("TCC", tc);
  //     for (const s of Object.keys(response[tc])) {
  //         console.log("STATUS", s);
  //         if (!allStatus.includes(s)) {
  //             allStatus.push(s);
  //         }
  //     }
  // }

  // console.log("ALLSTATUS ", allStatus)

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="row">
        <div className="col-2">
          <label className="create-label1">Start Date</label>
          <div class="input-group ">
            <input type="date" onChange={(e) => setFirstDate(e.target.value)} />
          </div>
        </div>
        <div className="col-2">
          <label className="create-label1">End Date</label>
          <div class="input-group ">
            <input
              type="date"
              onChange={(e) => {
                setLastDate(e.target.value);
              }}
            />
          </div>
        </div>
        {/* <div className="col-2">
            <label className="create-label1">Till Date</label>
            <div class="input-group ">
              <input
                type="date"
                onChange={(e) => {
                  setTillDate(e.target.value);
                }}
              />
            </div>
          </div> */}
        <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
              getData();
            }}
          >
            Get Data
          </button>
        </div>
        {/* <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
              //   generateCsvData();
            }}
          >
            Generate CSV
          </button>
        </div> */}
      </div>
      <div className="table-container1 table-fixed">
        {loading && <Spinner />}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Status</th>
              {telecallerNames.map((tc) => (
                <th key={tc}>{tc}</th>
              ))}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {allStatus &&
              allStatus.map((status) => (
                <tr>
                  <td>{status}</td>
                  {response &&
                    response.map((res, i) => {
                      // <td>{res[status]?.count}</td>
                      // console.log(res[status])
                      // console.log(res)
                      // <td>{res[telecallerNames[i]][status]?.count?res[telecallerNames[i]][status]?.count: res[telecallerNames[i]][status]}</td>
                      let varObj = res[telecallerNames[i]][status];
                      return (
                        <td>
                           {res[telecallerNames[i]][status].count}
                        </td>
                      );
                    })}
                  {<td>{calculateTotal(status)}</td>}
                </tr>
              ))}
            {response &&
              response.map((tc, i) => {
                // <tr>{tc[</tr>
                Object.keys(response[i]).map((status) => (
                  <tr>
                    <td>{status}</td>
                  </tr>
                ));
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
