import { Button, Divider, Popover, Stack } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { getHisab, handeleDownGrade, handleUpgrade } from './Api'
import { WhatsApp } from '@mui/icons-material'
import ReactWhatsapp from 'react-whatsapp'
import { useEffect } from 'react'
import './SubscriptionHisab.css'

function SubscriptionHisab({ detail, sub, setDrop, setPop, setDetail, setReload, reload }) {
    const [btnName, setBtnName] = useState("Plan Cost")
    const [anchorEl, setAnchorEl] = React.useState(null);
    let [btnText, setBtnText] = useState("DownGrade/UpGrade")
    let [paymentLink, setPaymentLink] = useState("")
    let [hisab, setHisab] = useState({})
    const [text, setText] = useState("");
    console.log(detail, "detail");
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleHisab = async () => {
        setPaymentLink("")
        setText('')
        setBtnName('Processing...')
        let res = await getHisab(detail.totalAppliances, detail.installations, detail.oldDrop, sub, detail.planFrequency)
        if (Object.keys(res).length === 0) {
            setBtnName("Failed Plan Cost")
        } else {
            setBtnName("Plan Cost")
            setHisab(res)
        }
    }

    const handelePaymentLink = async () => {
        setBtnText("Processing...")
        let res = detail.type === "upgradeSubscriptions" ? await handleUpgrade(detail, sub) : await handeleDownGrade(detail, sub)
        if (Object.keys(res).length !== 0) {
            if (res.url !== "") {
                setPaymentLink(res.url);

                setText(`Hey ${sub.name},
                 Book your Aliste Smart Home in a few clicks by carrying out a payment on the following link :  ${res.url}`);
            }
            setBtnText("DownGrade/UpGrade")
            setReload(!reload)
            setDrop(false)
        } else {
            setBtnText("Failed DownGrade/UpGrade")
        }
    }

    useEffect(() => {
        if (Object.keys(detail).length === 0) return
        handleHisab()
    }, [detail])

    return (
        <Stack direction='column' spacing={2} pl={5}>
            <Stack direction='row' spacing={2}>
                <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={() => {
                        setPop(false)
                        setPaymentLink("")
                        setText('')
                        setHisab({})
                        setDetail({})
                    }}
                >
                    Close
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={btnName === "Processing..." ? true : false}
                    type="submit"
                >{btnName}</Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={btnText === "Processing..." ? true : false}
                    onClick={() => handelePaymentLink()}
                >{btnText}</Button>
                {
                    text !== "" && (
                        <>


                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => navigator.clipboard.writeText(paymentLink)}
                            >
                                Copy
                            </Button>


                            <div>
                                <ReactWhatsapp
                                    number={sub.phone}
                                    message={text}
                                    style={{
                                        backgroundColor: "#25D366",
                                        border: "none",
                                        borderRadius: "12px",
                                        padding: "5px",

                                    }}
                                >
                                    <WhatsApp
                                        sx={{
                                            color: "white",
                                            verticalAlign: "middle",
                                            fontSize: "xx-large",
                                        }}
                                    />
                                </ReactWhatsapp>
                            </div>

                        </>
                    )
                }
            </Stack>

            {
                Object.keys(hisab).length !== 0 && (
                    <Stack direction={"column"} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <item>Particulars</item>
                            <item>Appliances</item>
                            <item>Rate</item>
                            <item>Total</item>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <item>New Recursive Amount</item>
                            <item>{detail.totalAppliances}</item>
                            <item>{Number(hisab.currentRecursive) / Number(detail.totalAppliances)}/{detail.planFrequency}</item>
                            <item>₹ {hisab.currentRecursive}</item>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <item>New Security Amount</item>
                            <item>{detail.totalAppliances}</item>
                            <item>{Number(hisab.currentSecurity)}</item>
                            <item>₹ {Number(hisab.currentSecurity)}</item>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <item>New Installation Amount</item>
                            <item>{detail.totalAppliances}</item>
                            <item>{Number(hisab.currentInstallation)}</item>
                            <item>₹ {Number(hisab.currentInstallation)}</item>
                        </Stack>
                        <Divider></Divider>
                        <Stack direction={'row'} spacing={2}>
                            <item>Sub Total(A)</item>
                            <item></item>
                            <item></item>
                            <item>₹ {Number(hisab.currentTotal).toFixed(2)}</item>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <item>Overdue (B)</item>
                            <item></item>
                            <item></item>
                            <item>+₹ {Number(hisab.overdueAmount).toFixed(2)}</item>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <item>Credit Note (C)  <Button aria-describedby={id} onClick={handleClick}>info</Button></item>
                            <item></item>
                            <item></item>
                            <item>-₹ {Number(hisab.creditNoteActualAmount).toFixed(2)}</item>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <item>Advance Amount (D)  </item>
                            <item></item>
                            <item></item>
                            <item>-₹ {Number(hisab.partialAmount)}</item>
                        </Stack>
                        <Divider></Divider>
                        <Stack direction={'row'} spacing={2}>
                            <item>Total (A+B-C-D)</item>
                            <item></item>
                            <item></item>
                            <item>₹ {Number(hisab.paymentToTake).toFixed(2)}</item>
                        </Stack>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}

                        >
                            {
                                hisab.creditNoteDetails.length !== 0 && (
                                    hisab.creditNoteDetails.map((e, i) => {
                                        return (
                                            <Stack direction={"column"} spacing={4} sx={{
                                                padding: "10px"
                                            }}>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Credit Note {i + 1}</item>
                                                </Stack>
                                               
                                                <Stack direction={'row'} spacing={2}>
                                                    <item>Plan Duration</item>
                                                    <item>{e.planDuration}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Un-Used Days</item>
                                                    <item>{e.unusedDays}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={2}>
                                                    <item>Used Days</item>
                                                    <item>{e.usedDays}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Paid Amount</item>
                                                    <item>{e.paidAmount}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Used Amount</item>
                                                    <item>{e.amountUtilized.toFixed(2)}</item>
                                                </Stack>
                                               
                                               
                                                <Stack direction={'row'} spacing={2}>
                                                    <item>Balance Amount</item>
                                                    <item>{e.creditAmount}</item>
                                                </Stack>
                                            </Stack>
                                        )
                                    })

                                )
                            }
                        </Popover>
                    </Stack>
                )
            }

        </Stack>
    )
}

export default SubscriptionHisab