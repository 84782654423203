import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../ConvertedLead.css";
import "../../DispatchData.css";
import moment from "moment/moment";
import '../../Installation.css'
import Qutoes from "../../Qutoes";
import { grevianceElectrician, serverUrl } from '../../ApiUrl'
import { useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../SpecificElectrician.css'
import { Button } from "@mui/material";
import { subHistory } from "../../Function/SubscriptionHistory";
import CommonConfirmationModel from "../../Model/CommonConfirmationModel";
export default function PickSiteByElectrician() {
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)
  let employeeName = useSelector((state) => state.sar.data.user.first_name)
  let [q, setq] = useState("");
  let [websiteReload, SetWebsiteReload] = useState(false);
  let [message, setMessage] = useState("")
  const [selectData,setSelectData] = useState({})
  const notify = (text) => toast(text);
  const err = (text) => toast.error(text);
  let [differntTable, setDiffTable] = useState({})
  let [keys, setKeys] = useState([])
  const [confirmationModel,setConfirmationModel] = useState(false)
  let datas = {}
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  useEffect(() => {
    setMessage('')
    let data = async () => {
      let electrician = []
      await axios.get(`${serverUrl.u}/api/tele/getAllElectrician`).then(function (res) {
        console.log(res);
        if (res.data.success === true) {
          console.log(res.data.data, "dd");
          electrician = res.data.data.filter((e) => {
            if (e.employeeId === employeeId) {
              console.log(e, "yyyy");
              return e
            }
          })
        }
      }).catch(function (err) {
        console.log(err);
      })
      await axios
        .get(
          `${serverUrl.u}/api/tele/getInstallationSiteOfCurrentDate`).then(async function (response) {
            console.log(response);
            let detail = response.data.data;
            if (electrician[0].grievanceElectrician === false) {
              detail = detail.filter((e) => {
             
                  if ((e.grivience === false ) ) {
                    return e
                  }
                
              })
            } else {
              detail = detail.filter((e) => {
                  return e
              })
            }


          

            console.log(detail, "data");
            if (detail.length === 0) {
              setMessage("Sites Not Found")
            }
            detail.sort(function (a, b) {
              return new Date(a.InstallationDate) - new Date(b.InstallationDate);
            });
            await detail.map((d) => {
              let ddate = moment.utc(d.InstallationDate).format("dddd -  Do MMMM YYYY");
              if (datas[ddate] === undefined) {
                datas[ddate] = [];
              }
              datas[ddate].push(d);
            })

            setDiffTable(datas)
          })
        .catch(function (error) {
          console.log(error);
        });
    };
    data();
    console.log("call");
  }, [websiteReload]);

  useEffect(() => {
    let keya = Object.keys(differntTable)
    setKeys(keya)
  }, [differntTable])

  const assignedElectritian = (e) => {
    axios.post(`${serverUrl.u}/api/dispatch/assignElectrician`, {
      "generatedHex": e.generatedHex,
      "electrician": [{ "name": employeeName, "Id": employeeId }]
    }).then(function (res) {
      console.log(res);
      if (res.data.success === true) {
        notify(res.data.message)
        SetWebsiteReload(!websiteReload)
        setConfirmationModel(false)
        subHistory(`Site is pickUp by ${employeeName}`, e, "PickUp Site By Electrician",  `${employeeName}`)
        setKeys([])
      } else {
        err(res.data.message)
      }
    }).catch(function (error) {
      console.log(error);
      err(error.response.data.message)
    })
  }

  const search = (data) => {
    if (data === undefined) return
    let newData = data.filter((e) => e.name.toUpperCase().includes(q.toUpperCase()))
    return newData;
  }
  return (
    <div >
      <div className="input-field">
        <input
          type="text"
          value={q}
          placeholder="Search here...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
      {
        message !== "" ?
          <div style={{
            width: "-webkit-fill-available",
            textAlign: "center",
            fontSize: "27px",
            fontWeight: "700",
            color: "#ff0000ba",
          }}>{message}</div> : null
      }
      {
        keys.length !== 0 ?
          keys.map((d, index) => {
            differntTable[d]?.sort((a, b) => (b.priorityNumber - a.priorityNumber))
            return (<div className='' style={{ height: "fit-content", marginLeft: "2%" }} key={index}>
              <div className="table-date-container">  <div className="table-date" >{d}</div>
              </div>
              <div className="table-container" style={{ height: "fit-content", marginTop: 0, marginLeft: 0, width: "100%" }}>
                {
                  search(differntTable[d])?.sort((a, b) => (b.priorityNumber - a.priorityNumber))?.map((e, i) => {
                    return (
                      <div key={i} 
                      style={{ backgroundColor: e.toBePickedUp === true ? "rgba(255, 255, 0, 0.48)" : (e.grivience === true ? "rgb(235 85 82 / 30%)" : (e.halfInstallation === true ? "lightgreen" : ((e.toBePickedUp === true) ? "#ffff007a" : ""))) }}
                      >
                        <table className=" table-bordered " style={{ width: "100%" }}>
                          <tbody>
                            <tr >

                              <td className="specific-title">{`Client Name :`}</td>
                              <th className="specific-data">{e.name}</th>
                            </tr>
                            <tr>
                              <td className="specific-title">Phone no :</td>
                              <th className="specific-data"><a href={`tel:${e.phone}`}>{e.phone}</a></th>
                            </tr>
                            <tr>
                              <td className="specific-title">Time :</td>
                              <th className="specific-data">{moment.utc(e.InstallationDate).format('LT')!=="12:00 AM"?moment.utc(e.InstallationDate).calendar():"Time Not Given"}</th>
                            </tr>
                            <tr>
                              <td className="specific-title">{`City :`}</td>
                              <th className="specific-data">{e.city}</th>
                            </tr>
                            <tr>
                              <td className="specific-title">{`Address :`}</td>
                              <th className="specific-data"><a href={e.addressHyperlink}>{e.address}</a></th>
                            </tr>
                            <tr>
                              <td className="specific-title">Sales Agent :</td>
                              <th className="specific-data">{e.teleCallerId}</th>
                            </tr>
                            <tr>
                              <td className="specific-title">Second Sales Agent :</td>
                              <th className="specific-data">{e.secondarySalesPerson}</th>
                            </tr>
                            <tr>
                              <td className="specific-title">Order Type :</td>
                              <th className="specific-data">{e.orderType}</th>
                            </tr>
                            {e.siteVisiteByElectrician && (
                              <tr>
                              <td className="specific-title">Site Visit :</td>
                              <th className="specific-data">{"Yes"}</th>
                            </tr>
                            )}
                            <tr>
                              <td className="specific-title">Appliances :</td>
                              <th className="specific-data">{e.totalAppliances}</th>
                            </tr>
                            <tr>
                              <td className="specific-title">TeleCaller Comments :</td>
                              <th className="specific-data">{e.comments}</th>
                            </tr>
                            <tr>
                              <td className="specific-title">Gerivance Department Comments :</td>
                              <th className="specific-data">{e.commentByGrivienceDepartment}</th>
                            </tr>

                          </tbody>
                        </table>
                        <Button variant="contained" className="w-100" onClick={() => {
                          setSelectData(e)
                          setConfirmationModel(true)
                        }}>PickUp Site</Button>
                      </div>

                    )
                  })
                }



              </div>
            </div>)
          }) : null
      }
      <ToastContainer />
      <CommonConfirmationModel setOpen={setConfirmationModel} open={confirmationModel} text={"Are you sure you want to pick this site"} agree={()=>assignedElectritian(selectData)}/>



      <Qutoes />

    </div>
  );
}
