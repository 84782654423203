import { serverUrl } from "../ApiUrl"
import axios from "axios"
export const changeInstallationDate = async(date,e,loginName,setReload,reload,notify)=>{
    await axios.post(`${serverUrl.u}/api/tele/changeInstallationDate`,{
        generatedHex:e.generatedHex,
        InstallationDate:date,
        loggingPerson:loginName,
      }).then(function (response) {
        if(response.data.success===true){
      setReload(!reload)
      notify(response.data.message)
        }else{
          notify(response.data.message)
        }
    }).catch(function (error) {
      console.log(error);
      notify(error.response.data.message)
    })
}