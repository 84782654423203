import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './ConvertedLead.css'
import './DispatchData.css'
import './PaymentLinkGen.css'
import moment from 'moment/moment'
import Qutoes from './Qutoes'
import CreateLead from './CreateLead'
import { serverUrl } from './ApiUrl'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip';
import { salesUserData } from './Function/AxiosControl'
import { planCost, state } from './Data'
import TempDataCsv from './Function/TempDataCsv'
import { DragDropContext,Droppable,Draggable } from "react-beautiful-dnd";

export default function NotPaid({wholeReload}) {
  let [details, setDetails] = useState([]);
  let [filterOption,setFilterOption] = useState(false)
  let [displayData,setDisplayData] = useState([])
  let [q, setq] = useState("");
  let [reloadW, setReload] = useState(false)
  let [display, setDisplay] = useState(false)
  let [deletedData, setDeletedData] = useState()
  let [data, setData] = useState()
  let [confirmationDis, setConfirmationDis] = useState(false)
  let [salesUserDatas,setSalesUserDatas] = useState()
  let userRoles = useSelector((state) => state.sar.data.user)
  let token = useSelector((state) => state.sar.data.token)
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    let salesUser;
    let data = async () => {
      await axios.post(`${serverUrl.u}/api/tele/getAllTemporarySubscriptionsParticular`, {
        teleCallerId: userRoles?.first_name
      })
        .then(function (response) {
          let detail = response.data.data;
          setDetails(detail)
          setDisplayData(detail)
        })
        .catch(function (error) {
          console.log(error);
        })
    }
    let dataAll = async () => {
      setFilterOption(true)
      await axios.post(`${serverUrl.u}/api/tele/getAllTemporarySubscriptions`).then(function (response) {
        let detail = response.data.data;
        setDetails(detail)
        setDisplayData(detail)
        console.log(detail);
      })
        .catch(function (error) {
          console.log(error);
        })
    }
    userRoles?.roles.includes("Super Admin") ? dataAll() : data()
   const sale = async()=>{
    salesUser =await salesUserData(token);
    setSalesUserDatas(salesUser);
    console.log(salesUser);
    }
    sale();
    
    
  }, [reloadW,wholeReload])
useEffect(()=>{
  console.log("sale  ",salesUserDatas);
},[salesUserDatas])
  const handleDelete = async (data) => {
    console.log(data._id);
    await axios.post(`${serverUrl.u}/api/tele/deleteTemporarySubscription`,
      {
        subId: data._id
      }
    ).then(function (response) {
      console.log(response);
      setReload(!reloadW)
      setConfirmationDis(false)
    }).catch(function (error) {
      console.log(error);
    })
  }
  const handleEdit = (data1) => {
    setDisplay(!display)
    setData(data1)
  }
  const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
  };

  const quotationApi = async (data) => {
    let name = data.name;
    console.log(data, 'quote num');
    let seq = Number(data.security);
    let ins = Number(data.installation);
    let qu = Number(data.totalAppliances);
    let planNamess = data.houseStructure[0].roomName;
    let planDurations = data.planFrequency;
    let fee;
    if (planDurations === "Monthly") {
      fee = (qu*planCost.Monthly) * 30
    } else if (planDurations === "Quarterly") {
      fee = (qu*planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
      fee = (qu*planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
      fee = (qu*planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
      fee = (qu*planCost.TwoYears) * (365*2);
    } else if (planDurations === "Three Years") {
      fee = (qu*planCost.ThreeYears) * (365*3);
    }
    let total = fee + seq + ins
    let tax = (fee + ins) * 0.18;
    let totalTax = total + tax;
    await axios.post(`${serverUrl.s}/misc/getQuotation`,
      {
        quoteTo: name,
        quoteNo: data.quotationNumber,
        itemsQuantity: { appliances: qu },
        packagePlanDetails: {
          packageName: planNamess,
          planName: planDurations,
          security: seq,
          installation: ins,
          fee: fee,
          total: total,
        },
        amounts: {
          subtotal: total,
          tax: tax,
          total: totalTax
        }
      }, {
      responseType: 'blob',
      headers: {
        AccessToken: token
      }
    }).then(function (response) {
      downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`)
    }).catch(function (error) {
      console.log("quto   ", error);
    })

  }
  const handleSarch = async (name) => {
    setq(name)
    await axios.post(`${serverUrl.u}/api/tele/getAllTemporarySubscriptionsSearch`, {
      name: name,
    }).then(function (response) {
      setDetails(response.data.data)
    }).catch(function (error) {
      console.log(error);
    })
  }
  const filterOnTelecaller = (name)=>{
    if(name==="all"){
      setDisplayData(details)
    }else{
      let data = details.filter((e)=>e.teleCallerId==name)
      setDisplayData(data);
    }
  }
  const filterOnCity = (name)=>{
    if(name==="all"){
      setDisplayData(details)
    }else{
      let data = details.filter((e)=>e.city==name)
      setDisplayData(data);
    }
  }

  const handleDragEnd = (results) =>{
    console.log(results)
    if(!results.destination) return
    let temData = [...displayData]
    let [SelectedRow] = temData.splice(results.source.index,1)
    temData.splice(results.destination.index,0,SelectedRow)
    setDisplayData(temData)
  }
  const search = (data)=>{
    if(data===undefined) return
    let newData = data.filter((e)=>e.name.toUpperCase().includes(q.toUpperCase()))
    return newData
}
  return (
    <div  style={{padding:"0 4%",marginTop:"4vh"}}>

      {
        display === false ?
          <>

            <div className='input-field'><input type="text" value={q} placeholder="Search here...." onChange={(e) => setq(e.target.value)} /></div>
            {
              filterOption===true?
            <div className='row' style={{marginLeft:"0.7vw"}}>
                  <div className="col-2">
                      <label className="create-label1">TeleCaller</label>
                      <div class="input-group ">
                          <select class="custom-select height-select" 
                                 onChange={(e)=>filterOnTelecaller(e.target.value)}
                                 defaultValue="all"
                                 >  
                                 <option value="all">All</option>
                                     {salesUserDatas?.map((option) => (
                                         <option value={option.first_name}>{option.first_name}</option>
                                      ))}
                                       <input type="text"/>
                          </select>
                        </div>
                  </div>
                  <div className="col-2">
                      <label className="create-label1">City</label>
                      <div class="input-group ">
                          <select class="custom-select height-select" 
                                 onChange={(e)=>filterOnCity(e.target.value)}
                                 defaultValue="all"
                                 >  
                                   <option value="all">All</option>
                                 <option value="Any for Now">Any for Now</option>
                                     {state?.map((option) => (
                                         <option value={option.value}>{option.value}</option>
                                      ))}
                                       <input type="text"/>
                          </select>
                        </div>
                  </div>
                  <div className="col-2">
                  <label className="create-label1">Temp Data Start Date</label>
                      <div class="input-group ">
                      <TempDataCsv/>
                        </div>
                  </div>
            </div>:null
      }
           
            <div className='table-container1'>
            <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name<span style={{ color: "red" }}>*</span></th>
                    <th>TeleCaller Name</th>
                    <th>Address</th>
                    <th>City</th>
                    <th style={{width:"110px"}}>Phone</th>

                    <th>Created At</th>
                    <th>Appliances</th>
                    <th>Selected Plan</th>
                    <th>Partial Payment</th>
                    <th>Comments</th>
                    <th  style={{width:"200px"}}>Action</th>
                  </tr>
                </thead>
                <Droppable droppableId="droppable-1">
  {(provided, snapshot) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {

displayData !== 0 ?

search(displayData).map((e, i) => {
                        return (
                          <Draggable draggableId={e._id} index={i} key={e._id}>
                          {(provided, snapshot) => (
                          <tr key={i} ref={provided.innerRef}
                          {...provided.draggableProps}
                          >
                            <td style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                            <td {...provided.dragHandleProps}>{e.teleCallerId}</td>
                            <td>{e.address}</td>
                            <td>{e.city}</td>
                            <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                            <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                            <td>{e.totalAppliances}</td>
                            <td>{e.planFrequency}</td>
                            <td style={{ color: `${e.partialPayment === false ? "green" : "red"}` }}>{e.partialPayment === false ? "No" : "Yes"}</td>
                            <td>{e.comments}</td>
                            {/* <td  ></td> */}
                            <td><div style={{display:"flex",alignItems:"center",justifyContent:"space-evenly"}}><img className='edit-delete-icon' data-tip="Edit" onClick={() => { handleEdit(e) }} src={process.env.PUBLIC_URL + "assets/icon/Edit.png"} />
                            <img data-tip="Delete" onClick={() => {
                              setDeletedData(e)
                              setConfirmationDis(!confirmationDis)
                            }} className='edit-delete-icon' src={process.env.PUBLIC_URL + "assets/icon/Delete.png"} />
                            <img data-tip="Quote" onClick={() => { quotationApi(e) }} className='edit-delete-icon' src={process.env.PUBLIC_URL + "assets/icon/coin-stacks.png"} />
                            <img data-tip="Payment Link" onClick={() => navigator.clipboard.writeText(e.paymentLink?(serverUrl.pay + e.generatedHex):(e.partialPaymentDetails?.short_url))} className='edit-delete-icon' src={process.env.PUBLIC_URL + "assets/icon/bank.png"} />
                            </div></td>
                           
                            {/* {
                              e.paymentLink ?
                                <td>
                                  <div style={{display:"flex",justifyContent:"space-evenly"}}>
                                  <button style={{width:"80px !important"}} className='btn btn-primary'  onClick={() => { quotationApi(e) }}>Quote</button>
                                  <button className='btn btn-primary' onClick={() => navigator.clipboard.writeText(serverUrl.pay + e.generatedHex)}>Payment Link</button>
                                  </div>
                                  </td> : null
                            }
                            {e.partialPaymentDetails?.short_url ?
                              <td style={{width:"150px"}}>
                                  <div style={{display:"flex",justifyContent:"space-evenly"}}>
                                  <button style={{width:"80px !important"}} className='btn btn-primary'  onClick={() => { quotationApi(e) }}>Quote</button>
                                <button className='btn btn-primary' onClick={() => { navigator.clipboard.writeText(e.partialPaymentDetails?.short_url) }}>Partial Link</button>
                                </div>
                                </td> : null
                            } */}
                          </tr>
                          )}
                          </Draggable>
                        )
                      }) : null
                  }
                  {provided.placeholder}
                </tbody>
               )
                }
                </Droppable>
              </table>
            </DragDropContext>
            </div>
            {confirmationDis === true ?
              <div className='confirmation-popup'>
                <div className='confirmation-popup-heading'>Confirmation to Delete {deletedData.firstName}</div>
                <div className='confirmation-popup-Subheading'>Are your sure you want to delete</div>
                <button className='btn btn-danger' onClick={() => { setConfirmationDis(false) }}>No</button>
                <button className='btn btn-primary ml-5' onClick={() => { handleDelete(deletedData) }}>Yes</button>
              </div> : null
            }
            <Qutoes /> </> : <CreateLead dataEdit={data} />
      }
 {/* <ReactTooltip effect="solid" offset={{ top: 15 }} /> */}
    </div>
  )
}
