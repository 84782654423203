import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Qutoes() {
  let [qutoes, setQutoes] = useState();
  let [singleQutoes, setSingleQutoes] = useState();
  useEffect(() => {
    axios
      .get("https://type.fit/api/quotes")
      .then(function (res) {
        setQutoes(res.data);
      })
      .catch(() => {});
  }, []);
  var rand;
  useEffect(() => {
    if (qutoes === undefined) return;
    var min = 1;
    var max = qutoes?.length;
    rand = Math.floor(min + Math.random() * (max - min));

    setSingleQutoes(qutoes[rand].text);
  }, [qutoes]);
  return (
    <div style={{ margin: "16px", textAlign: "center", fontSize: "18px" }}>
      {singleQutoes !== undefined ? <div>{singleQutoes}</div> : null}
    </div>
  );
}
