import React from 'react'
import { ci } from './Cities'
import { useState } from 'react'
import { useEffect } from 'react'

export default function SelectCityInput({value,setValue}) {
     useEffect(()=>{
       setSearchText(value)
     },[value])
    let [pop,setPop] = useState(false)
    let [serchText,setSearchText] = useState(value)
    let [searchValue,setSearchValue] = useState(ci)
    const callFilterCity =(text)=>{
        let newCi = ci.filter(e=>{
            if(e.value?.toUpperCase().includes(text.toUpperCase())){
                return e
            }
        })

        setSearchValue(newCi)
    }
  return (
    <div style={{position:"relative"}} className='input-group mb-1'>
        <input name="city"  className="form-control" value={serchText} onChange={(e)=>{
            setSearchText(e.target.value)
            callFilterCity(e.target.value)}} onClick={()=>setPop(true)}/>
        {
            pop===true?
            <div style={{position:"absolute",top:"30px",maxHeight:"200px",overflowY:"scroll",backgroundColor:"white",zIndex:4}}>
            {
                searchValue.length!==0?
                 searchValue.map(ele=>(
                    <div onClick={()=>{
                        setValue(ele.value)
                        setSearchText(ele.value)
                        setPop(false)
                    }}
                     style={{cursor:"pointer",padding:"5px 10px"}}
                    >{ele.value}</div>
                )):<div value>City Not Found</div>
            }
        </div>:null
        }
       
    </div>
  )
}
