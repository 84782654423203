import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { orderTyepes } from "../Data";
export const updateZohoLink = async (userData, fee, totalAmount, update, qu = 0) => {
console.log(userData.overdueCollect,"ffff");
  return await axios
    .post(`${serverUrl.u}/api/partialSubscription/paymentLink`, {

      generatedHex: update.generatedHex,
      totalAppliances: qu !== 0 ? qu : userData.quantityAppliances,
      security:update.reatachMandate===false?Number(userData.security):0,
      planFrequency: userData.planType,
      planId: update.planId,
      installation: update.reatachMandate===false?Number(userData.installion):0,
      collectOverdue: userData.overdueCollect === "true" ? true : false,
      applyCreditNotes: userData.creditNote === "true" ? true : false,
      postPayment:userData.postPayment,
      invoiceArray:userData.invoiceArray,
      upsellCapex: (orderTyepes[0].value === update.orderType || orderTyepes[4].value === update.orderType) ? false : true
    })
    .then(function (response) {
      return ["then", response]
    })
    .catch(function (error) {
      console.log(error);
      return ["catch", error]
    });
};

export const updateRazorpayLink = async (userData, fee, total, update, qu = 0) => {
 
  return await axios
    .post(`${serverUrl.u}/api/tele/createRazorFullPayment`, {
      generatedHex: update.generatedHex,
      totalAppliances: qu !== 0 ? qu : userData.quantityAppliances,
      planFrequency: userData.planType,
      planId: update.planId,
      security: userData.zohoSecurity,
      installation: userData.installion,
      collectOverdue: userData.overdueCollect === "true" ? true : false,
      applyCreditNotes: userData.creditNote === "true" ? true : false,
      postPayment:userData.postPayment,
      invoiceArray:userData.invoiceArray,
      upsellCapex: (orderTyepes[0].value === update.orderType || orderTyepes[4].value === update.orderType) ? false : true

    })
    .then(function (response) {
      console.log(response);
      return ["then", response]

    })
    .catch(function (error) {
      console.log(error);
      return ["catch", error]

    });
};