import axios from "axios"
import { serverUrl } from "../ApiUrl"
import { notify, notifyFailed } from "../Common/Notification"

export const custmorDontFeedback = (setFeedBack)=>{
    axios.get(`${serverUrl.u}/app/feedback/getSubscriptionWithoutFeedback`).then(
        res =>{
            if(res.data.success === true){
                notify(res.data.message)
                console.log(res.data.data.subscription);
                setFeedBack(res.data.data.finalData)
            }else{
                notifyFailed(res.data.message)
            }
        }
    ).catch(err=>{
        notifyFailed("Failed")
    })
}
export const custmorHaveFeedback = (setFeedBack)=>{
    axios.post(`${serverUrl.u}/app/feedback/getAllFeedback`).then(
        res =>{
            if(res.data.success === true){
                notify(res.data.message)
                console.log(res.data.data.finalData);
                setFeedBack(res.data.data.finalData)
            }else{
                notifyFailed(res.data.message)
            }
        }
    ).catch(err=>{
        notifyFailed("Failed")
    })
}
export const enterFeedback = async(detail)=>{
     await axios.post(`${serverUrl.u}/app/feedback/enterfeedback`,detail).then(
        res =>{
            if(res.data.success===true){
                console.log(res.data.data);
                notify(res.data.message)
            }else{
                notifyFailed(res.data.message)
            }
        }
     ).catch(err => {
        console.log(err);
        notifyFailed("FeedBack Fill Failed")
     })
}