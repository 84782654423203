import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './ConvertedLead.css'
import './DispatchData.css'
import './PaymentLinkGen.css'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import Qutoes from './Qutoes'
import { serverUrl } from './ApiUrl'
import { useSelector } from 'react-redux'
import PlanUpdate from './Function/PlanUpdate'
import CommentUdate from './Function/CommentUdate'
import ChangeSecondTeleCaller from './Function/ChangeSecondTeleCaller'
import { salesUserData } from './Function/AxiosControl'
import { planCost, state } from './Data'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddressUpdate from './Function/AddressUpdate'
import AlternateNumberUpdate from './Function/AlternateNumberUpdate'

export default function WebsiteLead() {
  let [details, setDetails] = useState([]);
  let [displayData,setDisplayData] = useState([])
  let [salesUserDatas,setSalesUserDatas] = useState()
  let [update, setUpdateData] = useState({})
  let [updateDetail,setUpdateDetail] = useState(false)
  let [dateChange, setDateChange] = useState(true)
  let [meetingPop,setMeetingPop] = useState(false)
    let [meetingData,setMeetingData] = useState({})

  let [q, setq] = useState("");
  let [display, setDisplay] = useState("");
  let [reloadW, setReload] = useState(false);
  let [userName, setUserName] = useState()

  const [searchParam] = useState(["phone"]);
  let installationDates = useRef(null) 
  const notify = (text) => toast(text);
  let meetDate=useRef(null)
  
  let meetingComment = useRef(null)
  let meetingStatu = useRef(null)
  let dDate =update.InstallationDate?(moment(update.InstallationDate).format('DD-MM-YYYY')):null
  let userDetail = useSelector((state)=>state.sar.data.user);
  let token = useSelector((state)=>state.sar.data.token)
  useEffect(()=>{
    dDate =update.InstallationDate?(moment(update.InstallationDate).format('DD-MM-YYYY')):null
  },[update])
  useEffect(() => {
    let salesUser;
    let dataAll = async () => {

      await axios.post(`${serverUrl.u}/tele/api/getWebsiteLead`).then(function (response) {
        let detail = response.data.data;
        setDetails(detail)
        console.log(detail);
        setDisplayData(detail)
      })
        .catch(function (error) {
          console.log(error);
        })
    }
   dataAll();
    const sale = async()=>{
      salesUser =await salesUserData(token);
      setSalesUserDatas(salesUser);
      console.log(salesUser);
      }
      sale();
  }, [reloadW, userDetail])
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
  
  let updateStatus = async (data) => {
    console.log(data.InstallationDate);
    await axios.post(`${serverUrl.u}/api/tele/updateDispatchStatus`, {
      subId: data._id,
      status: "ready to dispatch"
    }).then(function (response) {
      let detail = response;
      console.log(data._id);
      console.log(detail);
     
      if(response.data.success===true){
        notify("Dispatched Successfully")
        setDetails([])
        setUpdateData([])
        setDisplayData([])
        setReload(!reloadW);
      }else{
        notify(response.data.message)
      
      }
    })
      .catch(function (error) {
        console.log(error);
        notify(error.data.message)
      })

  }
 
  const downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
  };
  const quotationApi = async (data) => {
    let name = data.name;
    let seq = Number(data.zohoSecurity);
    let ins = Number(data.zohoInstallation);
    let qu = Number(data.totalAppliances);
    let planNamess = data.houseStructure[0].roomName;
    let planDurations = data.planFrequency;
    let fee;
    if (planDurations === "Monthly") {
      fee = (qu*planCost.Monthly) * 30
    } else if (planDurations === "Quarterly") {
      fee = (qu*planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
      fee = (qu*planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
      fee = (qu*planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
      fee = (qu*planCost.TwoYears) * (365*2);
    } else if (planDurations === "Three Years") {
      fee = (qu*planCost.ThreeYears) * (365*3);
    }
    let total = fee + seq + ins;
    let tax = (fee + ins) * 0.18;
    let totalTax = total + tax;
    await axios
      .post(
        `${serverUrl.s}/misc/getQuotation`,
        {
          quoteTo: name,
          quoteNo: data.quotationNumber,
          itemsQuantity: { appliances: qu },
          packagePlanDetails: {
            packageName: planNamess,
            planName: planDurations,
            security: seq,
            installation: ins,
            fee: fee,
            total: total,
          },
          amounts: {
            subtotal: total,
            tax: tax,
            total: totalTax,
          },
        },
        {
          responseType: "blob",
          headers: {
            AccessToken: token,
          },
        }
      )
      .then(function (response) {
        downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`);
      })
      .catch(function (error) {
        console.log("quto   ", error);
        notify(error.data.message)
      });
  };

  const updatePriority = async(data)=>{
    await axios.post(`${serverUrl.u}/api/tele/updatePriority`,{
      generatedHex :data.generatedHex,
      priority: !data.priority 
    }).then(function (res) {
      console.log(res);
      setReload(!reloadW);
    }).catch(function (error) {
      console.log(error);
    })
}

const hyperLinkUpdate = async(link,data)=>{
  await axios.post(`${serverUrl.u}/api/subscription/updateHyperLink`,{
    subId: data._id,
    link:link
  }).then(function (res) {
    console.log(res);
    setReload(!reloadW)
  }).catch(function (error) {
    console.log(error);
  })
}
useEffect(() => {
  
  axios.get("https://int.alistetechnologies.com/users/salesUsers", {
    headers: {
      AccessToken: token
    }
  }).then(function (response) {
    console.log("user list  ",response.data);
    
    if(response.data.success===false){
      console.log("false");
     notify(response.data.message)
      setUserName()
    }else{
      console.log("true");
      notify(response.data.message)
      setUserName(response.data.users)
    }
  }).then(function (error) {
    console.log("list error   ",error);
  })
}, [])



const meetingSchedule = (dateTime,comment,status)=>{
  axios.post(`${serverUrl.u}/api/tele/updateMeetingSchedule`,{
   generatedHex:meetingData.generatedHex, 
   fieldVisite:true,
   fieldVisiteDateTime:dateTime,
   meetingComment:comment,
   meetingStatus:status,
   name:meetingData.name,teleCallerId:meetingData.teleCallerId,planFrequency:meetingData.planFrequency,totalAppliances:meetingData.totalAppliances
  }).then(function (res) {
    if(res.status===200){
      notify("Meeting Set Sucessfully")
      setMeetingPop(false)
      setReload(!reloadW)
    }else{
      notify("error occured")
    }
    console.log(res);
  }).catch(function (err) {
     console.log(err);
     notify("error occured")
  })
}


  return (
    <div  style={{padding:"0 4%",marginTop:"4vh"}}>

      <div className='input-field mb-5'>
        <input type="text" value={q} placeholder="Search here phone number...." onChange={(e) => setq(e.target.value)} /></div>
       
    
      <div className='table-container1' style={{marginTop:0}}>
      
        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
              <th scope="col">Telecaller Name</th>
              <th>Client Status</th>
              <th scope="col">Second Telecaller Name</th>
              <th scope="col">City</th>
              <th>Address<span style={{color:"red"}}>*</span></th>
              <th>Hyper Link<span style={{color:"red"}}>*</span></th>
              <th scope="col">Phone</th>
              <th>Alternate Phone<span style={{color:"red"}}>*</span></th>
              <th>Payment</th>
              <th scope='col'>Priority<span style={{color:"red"}}>*</span></th>
              <th scope="col">Created At</th>
              <th scope="col">Appliances</th>
              <th scope="col">Selected Plan</th>
              <th scope="col">Comments <span style={{color:"red"}}>*</span></th>
              {/* <th style={{width:"620px"}}>Action</th>
              <th style={{width:"270px"}}>Update</th> */}
            </tr>
          </thead>
          <tbody>
            {
              displayData !== 0 ?
                search(displayData).sort(function(a,b){
                  return new Date(b.createdAt) - new Date(a.createdAt);
                }).map((e, i) => {
                  return (
                    <tr key={i}>
                      <td className="pr-15 pl-55" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                      <td>{e.teleCallerId}</td>
                      <td style={{color:e.dropped === false ?"#008000":"#FF0000"}}>{e.dropped === true ? "Dropped" : "Active"}</td>
                      <ChangeSecondTeleCaller e={e} SetWebsiteReload={setReload} websiteReload={reloadW} userName={salesUserDatas} filter={"website"}/>
                      <td>{e.city}</td>
                      <AddressUpdate e={e} setReload={setReload} reloadW={reloadW} page={"websiteLead"}/>
                      <td label="Hyper Link" contenteditable="true" onBlur={(ele)=>hyperLinkUpdate(ele.target.innerText,e)}>{e.addressHyperlink}</td>
                      <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                      <AlternateNumberUpdate e={e} notify={notify} reload={reloadW} setReload={setReload}/>
                      <td style={{ color: `${e.partialPayment === false ? "green" :(e.partialAmount===0)?"red":"orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount===0)?"Zero":`Partial`}  </td>
                      <td style={{ color: `${e.priority === false ? "black" : "red"}` }} onClick={()=>updatePriority(e)}>{e.priority === false ? "No" : "Yes"}</td>
                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <td>{e.totalAppliances}</td>
                      <td>{e.planFrequency}</td>
                      <CommentUdate e={e} websiteReload={reloadW} SetWebsiteReload={setReload} page={"websiteLead"}/>
                      {/* <td>

                      <div style={{display:"flex",justifyContent:"space-evenly"}}>
                      
                      <button className='btn btn-primary' onClick={()=>updateStatus(e)}>Dispatch <br/><span style={{fontSize: "10px"}}>(Update Alternate Number before click)</span></button>
<Link to="/new" state={{ from: e ,path:"Address&Layout"}}><button className='btn btn-primary' type="button" style={{
                        backgroundColor: "#1565c0",
                        color: "white",
                      }}>SwitchBoard</button></Link>

<button
                          className="btn btn-primary"
                          onClick={() => {
                            quotationApi(e);
                          }}
                        >
                          Quote
                        </button>

                        <button className="btn btn-primary info" onClick={()=>{
                     setMeetingPop(true)
                     setMeetingData(e)
                   }}>Meeting</button>
                        </div>

                      </td>
                     <td> <PlanUpdate e={e}/></td> */}
                      
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
<ToastContainer/>
     
      
      {
    meetingPop===true?
    <div className="greivance-container" style={{top:"21% !important"}}>
        <span className="cross" onClick={() => setMeetingPop(!meetingPop)}>x</span>
        <label className='create-label1'>Meeting Date and Time</label>
        <div class="input-group mb-1">
        <input type="datetime-local" ref={meetDate}/>        
        </div>
        <label className='create-label1'>Meeting Comments</label>
        <div class="input-group mb-1">
          <input type="text" ref={meetingComment} defaultValue="no" />
        </div>
        <label className='create-label1'>Meeting Status</label>
        <div class="input-group mb-1">
          <input type="text" ref={meetingStatu} defaultValue=""/>
        </div>
        <button className='btn btn-primary' onClick={()=>meetingSchedule(meetDate.current.value,meetingComment.current.value,meetingStatu.current.value)}>Meeting Schedule</button>
    </div>:null
  } 
      <Qutoes />
    </div>
  )
}
