import axios from "axios";
import { serverUrl } from "../ApiUrl";
import moment from "moment";
import { subHistory } from "../Function/SubscriptionHistory";
export const addGrevianceClient = async(hex,deviceObject,comment,GerivanceType,loginName,gerivanceDate,notify,SetWebsiteReload,websiteReload,setGrivanceDisplay,data,grievancePriority="false",sendTo="Vikas") => {
  console.log("data",data);
   await axios.post(`${serverUrl.u}/api/dispatch/markAsGrivienceTwo`, {
      generatedHex: hex,
      date: moment(gerivanceDate).format("l"),
      devicesadded: deviceObject.decision==="no"?false:true,
      devices: deviceObject,
      comment:`${comment} - ${moment(new Date()).format("DD-MM-YYYY")}`,
      type:GerivanceType,
      enteringPerson:loginName,
      grievancePriority:grievancePriority==="false"?false:true,
      sendTo:sendTo
    }).then(function (res) {
      console.log(res);
      if(res.data.success===true){
      setGrivanceDisplay(false)
      notify("Gerivance Added")
      SetWebsiteReload(!websiteReload);
      subHistory(comment,data,"Greivance",GerivanceType,gerivanceDate)
      }else{
        notify("Error Occur")
      }
    }).catch(function (error) {
      console.log();
      notify(error.data.message)
    })
  }