import React,{useState} from 'react'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { Grid } from '@mui/material';
import { PaymentModes } from '../Data';
import { useSelector } from 'react-redux';

export default function RefundDestination({refundRecords,notify,SetReload,reload}) {
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let [forms,setForm] = useState(false)
    let [paymentMode,setPaymentMode] = useState("BankTransfer")

      const updateRefundDetails = async (userData) => {
        console.log(userData,"refund form");
        await axios.post(`${serverUrl.u}/app/fnd/updatePaymentMode`, {
            sId:refundRecords._id,
            refundMethod:userData.refundMethod,
            refundUpiId:userData.refundUpiId,
            bankTransferDetails:{
                Name : userData.bankNameClient,
                Account_Number : userData.accountNumber,
                IFSC : userData.ifsc
        
            }
          })
          .then(function (response) {
            console.log(response);
            if(response.data.success===true){
                notify(response.data.message)
                setForm(false)
                SetReload(!reload)
            }else{
                notify(response.data.message)
            }
          })
          .catch(function (error) {
            console.log(error);
            notify("error occur")
          })
      };
     
      const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let userData = {
          refundMethod: data.get("refundMode"),
          refundUpiId: data.get("refundUpi"),
          bankNameClient:data.get('bankNameClient'),
          accountNumber:data.get("accountNumber"),
          ifsc:data.get('ifsc'),
        };
              updateRefundDetails(userData);
      };
    return (
        <>
        <button
          className="btn btn-primary"
          onClick={() => setForm(true)}
        >
          Refund Details
        </button>
      
      
    
         {forms=== true ? (
            <div className="updatePlan">
              <form onSubmit={handleSubmit}>
                <div className="cross1" onClick={() => setForm(false)}>
                  x
                </div>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <label>Name <span style={{color:"red"}}>*</span></label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="refundTo"
                        required
                        name="refundTo"
                        autoComplete="refundTo"
                        placeholder="refund To"
                        aria-label="refundTo"
                        aria-describedby="basic-addon1"
                        value={refundRecords.refunndTo}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label>Refund Method <span style={{color:"red"}}>*</span></label>
                    <div class="input-group mb-3">
                    <select class="custom-select height-select" 
                                        required 
                                        id="inputGroupSelect01" 
                                        defaultValue="choose one"
                                        name="refundMode"
                                        label="City"
                                        onChange={(e)=>setPaymentMode(e.target.value)}
                                        autoComplete="current-refundMode" 
                                        >
                                          {PaymentModes.map((option) => (
                                          <option value={option.value}>{option.value}</option>
                                          ))}
                                </select>
                    </div>
                  </Grid>
                </Grid>
              
          
                
                
                  
                  
                  {
                    paymentMode==="upi"?
                    <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                    <label>Refund Upi Id<span style={{color:"red"}}>*</span></label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="refundUpi"
                        name="refundUpi"
                        autoComplete="current-refundUpi"
                        placeholder=""
                        required
                        aria-label="refundUpi"
                        aria-describedby="basic-addon1"
                        
                      />
                    </div>
                    </Grid>
                  </Grid>:
                  <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                  <label>Client Name in Bank<span style={{color:"red"}}>*</span></label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="bankNameClient"
                      name="bankNameClient"
                      autoComplete="current-bankNameClient"
                      placeholder=""
                      required
                      aria-label="bankNameClient"
                      aria-describedby="basic-addon1"
                      
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Account Number <span style={{color:"red"}}>*</span></label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="accountNumber"
                        name="accountNumber"
                        autoComplete="current-accountNumber"
                        placeholder=""
                        required
                        aria-label="accountNumber"
                        aria-describedby="basic-addon1"
                        
                      />    
                    </div>
                  </Grid>
                </Grid>
                  }
                  
            
                {
                    paymentMode!=="upi"?
                <Grid container spacing={4}>
                  
                  
                    <Grid item xs={12} md={6}>
                    <label>IFSC Code<span style={{color:"red"}}>*</span></label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="ifsc"
                        name="ifsc"
                        autoComplete="current-ifsc"
                        placeholder=""
                        required
                        aria-label="ifsc"
                        aria-describedby="basic-addon1"
                        
                      />
                    </div>
                  </Grid>
                  </Grid>:null
                }
                <Grid container spacing={4}>
                  <Grid item xs={12} md={2}>
                    <button className="btn btn-primary" type="submit">
                      Update Refund Details
                    </button>
                  </Grid>
                  
                </Grid>
              </form>
                 </div>
          ) : null}
          </>
      )
}
