import { Button } from '@mui/material'
import React, { useState } from 'react'
import ModelCommon from '../Common/ModelCommon'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { notify, notifyFailed } from '../Common/Notification'

function ChangeLeadType({leads, selectedLeadType, selectedCitys, selectedDate, selectedContent, selectedSources,setReload,reload}) {
    const [leadType,setLeadType] = useState("") 
    const [btn,setBtn] = useState('Lead Type Change')
    const leadTypeChange = () => {
        if (selectedLeadType.type === "default") {
            notifyFailed("Plese Select Lead Type")
            setBtn("Assign Lead")
            return
        }
        setBtn("Processing...")
      
       
        if (selectedLeadType.total===0) {
            notifyFailed("Leads Not Selected")
            setBtn("Lead Type Change")
            return
        }
        let cityTotal = 0
        for (let le of selectedCitys) {
            cityTotal += le.value
        }
        // if (cityTotal < total) {
        //     notifyFailed("Total lead you want to assign is more than available leads in Selected city")
        //     setBtn("Assign Lead")
        //     return
        // }
        let cit = []
        for (let i of selectedCitys) {
            if (i.value === "Empty_City") {
                cit.push('')
            } else {
                cit.push(i.value)
            }
        }
        let createdDays = []
        for (let i of selectedDate) {
            createdDays.push(i.originalDate)
        }
        let content = []
        for(let o of selectedContent.map(e=>e.value)){
            if(o==="Empty_Content"){
                for( let s of selectedSources){
                    content.push(s.value)
                }
            }else{
                content.push(o)
            }
        }
        console.log({"leadType": selectedLeadType.type,
        "cities": cit,
        "sources": content,
        "createdDays": createdDays,
        "changeTo": leadType},"ffff");
    
        console.log(selectedLeadType,"type");
        axios.post(`${serverUrl.amazon}/leads/updateLeadType`,{
            "leadType": selectedLeadType.type,
            "cities": cit,
            "sources": content,
            "createdDays": createdDays,
            "changeTo": leadType
        }).then(function (res) {
            console.log(res);
            if (res.data.success === true) {
                notify(res.data.message)
                setReload(!reload)
                setBtn("Lead Type Change")
            } else {
                notifyFailed(res.data.message)
                setBtn("Lead Type Change")
            }
        }).catch(function (err) {
            console.log(err);

        })
    }
  return (
    <>
    {/* <Button
      variant='contained'
      size='small'
    >ChangeLeadType</Button> */}
    <ModelCommon
      agree={leadTypeChange}
      label={"Enter New Lead Type"} 
      btnName={btn}
      page={"Lead Type Change"} 
      input={true}
      setComment={setLeadType}
    />
    </>
  )
}

export default ChangeLeadType