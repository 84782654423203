import React, { useEffect, useState } from "react";
import "../ConvertedLead.css";
import "../DispatchData.css";
import moment from "moment/moment";
import '../Installation.css'
import Qutoes from "../Qutoes";
import { useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';
import '../SpecificElectrician.css'
import { InstallationUser } from '../Function/AxiosControl'
import Installationtablewise from "./Installationtablewise";
import { InstallationDataApi, availableElectrician } from "../Redux/installationActions";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { user_roles } from "../Data";
import { notify, notifyFailed } from "../Common/Notification";
import InstallationSlot from "./InstallationSlot";
import  Spinner from '../../component/Function/Spinner'
export default function Installation() {
  let salesUserData = useSelector(state => state.salesUser);
  let [details, setDetails] = useState([]);
  let [q, setq] = useState("");
  let [csvBtnText, setCsvBtnText] = useState(false)
  const [filterDataofDates, setFilterDataOfDates] = useState([])
  let [csv, setCsv] = useState([])
  let [userName, setUserName] = useState()
  let [websiteReload, SetWebsiteReload] = useState(false);
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)
  let userRoles = useSelector((state) => state.sar.data.user.roles)
  let [cityKey, setCityKey] = useState([]);
  let dispatch = useDispatch()
  let AllInstallationData = useSelector((state) => state.installationData)
  let avilableElectrician = useSelector(state=>state.electrician)
  const [dates, setDates] = useState([])
  useEffect(() => {
    ReactTooltip.rebuild();
    InstallationDataApi(dispatch)    
    availableElectrician()
  }, [])
  useEffect(() => {
    let id = setInterval(() => { InstallationDataApi(dispatch) }, 10000);
    return () => {
      if (id) {
        clearInterval(id)
      }
    }
  }, [])



  useEffect(() => {
    setCityKey([])
    let datas = []

    let data = async () => {

      let detail = AllInstallationData;
      // detail.sort(function (a, b) {
      //   return new Date(a.InstallationDate) - new Date(b.InstallationDate);
      // });
      if (userRoles[0] === (user_roles.Isp)) {
        console.log("comeISp");
        detail = await detail.filter((e) => {
          if (userRoles.includes(user_roles.Isp) === false ? (e.teleCallerId == loginName || e.secondarySalesPerson == loginName) : (e.teleCallerId === employeeId)) {
            return e
          }
        })

      }
      if (userRoles.includes(user_roles.ExternalTeleCaller)) {
        console.log("comeISp");
        detail = await detail.filter((e) => {
          if (userRoles.includes(user_roles.ExternalTeleCaller) === false ? (e.teleCallerId == loginName || e.secondarySalesPerson == loginName) : (e.teleCallerId === loginName)) {
            return e
          }
        })

      }
      await detail.map((d) => {
        let ddate = moment.utc(d.InstallationDate).format("ddd -  Do MMM");
        if (datas[ddate] === undefined) {
          datas[ddate] = [];
        }
        datas[ddate].push(d);
      })
      // setDetails(detail);
      let keya = Object.keys(datas)
      setCityKey(keya)
  
    };
    let dataAll = async () => {
      let detail = AllInstallationData
      let datess = []
      for (let i = 0; i < 7; i++) {
        let data = new Date(new Date().setDate(new Date().getDate() + i))
        datess.push(data)
      }

      setDates(datess)
      // detail.sort(function (a, b) {
      //   return new Date(a.InstallationDate) - new Date(b.InstallationDate);
      // });
      if (datess.length !== 0 && employeeId !== "A03") {
        let startDate = moment.utc(new Date())
        let endDate = moment.utc(new Date(datess[6]))

        const filteredData = detail.filter(item => {
          const date = moment.utc(new Date(item.InstallationDate));

          // return new Date(date).getTime() >= new Date().getTime() && new Date(date).getTime() <= new Date(endDate).getTime();
          // return startDate<=date<=endDate
          return date.isBetween(startDate, endDate)
        });

        setFilterDataOfDates(filteredData)

      }
      let filterData = detail;
      await filterData.map((d) => {
        let ddate = d.InstallationDate;

        if (datas[ddate] === undefined) {
          datas[ddate] = [];
        }
        datas[ddate].push(d);
      })
      // setDetails(detail);
      let keya = Object.keys(datas)
      setCityKey(keya)

    };
    (userRoles.includes(user_roles.Isp) || userRoles.includes(user_roles.ExternalTeleCaller)) ? data() : dataAll();
  }, [AllInstallationData]);

  const colorDecide = (text) => {
    if (text === "dispatch") {
      return "rgb(251, 37, 118)"
    }
    if (text === "ready to install") {
      return "green"
    }
    if (text === "installed") {
      return "rgb(51, 47, 208)"
    }
  }
  let dataCsvss = async () => {
    let dataCsv = []
    for (const e of details) {
      dataCsv.push({
        "Client Name": e.name,
        "Address": e.address,
        "City": e.city,
        "TeleCaller": e.teleCallerId,
        "Second Sales Person": e.secondarySalesPerson,
        "Hex Code": e.generatedHex,
        "Payment Status": e.partialPayment === false ? ("Paid") : ((e.partialAmount === 0) ? "Zero" : "Partial"),
        "Electrician": e.AssignedElectritian,
        "Installation Date": moment(e.InstallationDate).format("MMM Do LT YYYY"),
        "Greviance": e.grivience === true ? "Yes" : "No",
        "Comments": e.comments,
        "Electrician Comment": e.installerComments,
        "Greviance Commment": e.commentByGrivienceDepartment
      })
    }
    setCsv(dataCsv);
    setCsvBtnText(false)
  };
  const search = (data) => {
    if (data === undefined) return
    let newData = data.filter((e) => e.name.toUpperCase().includes(q.toUpperCase()))
    return newData
  }

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="input-field">
        <input
          type="text"
          value={q}
          placeholder="Search here...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
      <InstallationSlot dates={dates} setDates={setDates} filterDataofDates={filterDataofDates} />

      {
        !userRoles.includes(user_roles.Isp) ?
          <>
            <div style={{ display: "flex", marginLeft: "20px", marginTop: "20px", alignItems: "center" }}>
              <div style={{ backgroundColor: "lightgreen", height: "17px", width: "17px", marginRight: "10px" }}></div>
              <div>This color means site is Half Installed</div>
            </div>
            <div style={{ display: "flex", marginLeft: "20px", marginTop: "20px", alignItems: "center" }}>
              <div style={{ backgroundColor: "#ffff007a", height: "17px", width: "17px", marginRight: "10px" }}></div>
              <div>This color means uninstallation is set</div>
            </div>
            <div style={{ display: "flex", marginLeft: "20px", marginTop: "20px", alignItems: "center", marginBottom: "10px" }}>
              <div style={{ backgroundColor: "rgb(235 85 82 / 30%)", height: "17px", width: "17px", marginRight: "10px" }}></div>
              <div>This color means client has Grievance</div>
            </div>
          </> : null
      }

      {
        !userRoles.includes(user_roles.Isp) ?
          <>
            {
              csv.length !== 0 ?
                <div>
                  <label className="create-label1">CSV Sheet</label>
                  <div class="input-group ">
                    <CSVLink className='btn btn-primary' filename={`Installation.csv`} style={{ marginLeft: "2%" }} data={csv}>Download CSV</CSVLink>
                  </div>
                </div>
                : <div>
                  <label className="create-label1">Create CSV Sheet</label>
                  <div class="input-group ">
                    {
                      csvBtnText === false ?
                        <button className="btn btn-primary" onClick={() => {
                          dataCsvss()
                          setCsvBtnText(true)
                        }}>Create Csv</button>
                        : <button className="btn btn-secondary" >Proccessing...</button>
                    }
                  </div>
                </div>
            }
          </> : null
      }

      {
        cityKey.length !== 0 ?
          cityKey.map((d, index) => {
            return (
              <Installationtablewise
                array={d}
                details={AllInstallationData}
                SetWebsiteReload={SetWebsiteReload}
                websiteReload={websiteReload}
                userName={userName}
                colorDecide={colorDecide}
                notify={notify}
                salesUserDatas={salesUserData}
                index={index}
                search={search}
                avilableElectrician={avilableElectrician}
              />
            )
          }) : <Spinner/>
      }
      <Qutoes />


    </div>

  );
}
