import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../utils';
import { serverUrl } from '../../component/ApiUrl';
import { notifyFailed } from '../../component/Common/Notification';
import { RepeatOneSharp } from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Spinner from '../../component/Function/Spinner';

export default function PiWIthoutInvoice() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await apiRequest({
        url: `${serverUrl.sub}/v3/performa/PIWithoutInvoice`,
        method: 'GET',
      });
      console.log(response.data);
      if (!response.data.success) {
        notifyFailed(response.data.message);
        return;
      }

      setData(response.data.data.PIs);
      setLoading(false);
    }

    getData();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Customer Name',
      width: 250,
    },
    {
      field: 'piNumber',
      headerName: 'Proforma Number',
      width: 150,
    },
    {
      field: 'item',
      headerName: 'Number of Products',
    },
    {
      field: 'items',
      headerName: 'Product Names',
      width: 250,
    },
    {
      field: 'amount',
      headerName: 'Total Amount',
      width: 250,
    },
    {
      field: 'billTo',
      headerName: 'Billing Address',
      width: 250,
    },
    {
      field: 'shipTo',
      headerName: 'Shipping Address',
      width: 250,
    },
  ];

  const rows = data.map((pi, i) => {
    return {
      ...pi,

      id: i, // Using index as unique ID
      items:
        pi.items.length > 0
          ? pi.items.map((p) => p.name).join(', ')
          : pi.product.map((p) => p.name).join(', '),
      billTo: pi.billTo
        ? `${pi.billTo.address} ${pi.billTo.city}, ${pi.billTo.country}`
        : undefined,
      shipTo: pi.shipTo
        ? `${pi.shipTo.address} ${pi.shipTo.city}, ${pi.shipTo.country}`
        : undefined,
      item: pi.items.length > 0 ? pi.items.length : pi.product.length,
      rawData: pi,
    };
  });

  return (
    <div>
      <h2>Proforma Invoices without Invoice</h2>

      {loading ? (
        <Spinner />
      ) : (
        <div className='mt-4'>
          {/* Table */}
          <div
            className='table-container'
            style={{
              border: '1px solid #c6d0f0',
              borderRadius: '10px',
            }}
          >
            <DataGrid
              sx={{ minHeight: '70vh' }}
              columns={columns}
              rows={rows}
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: {
                    fileName: `pi_without_invoice_${new Date().toLocaleDateString()}`,
                  },
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>
      )}
    </div>
  );
}
