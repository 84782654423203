
import React, { useState,useEffect } from "react";
import "./RoomDetails.css";
import SwitchBoard from "./SwitchBoard";
import { useDispatch, useSelector } from "react-redux";
import { addSwitchBoardInRoom } from "../Redux/action";

export default function RoomDetails(props) {
  let {  name,changeData,layoutDecision,appliancesLeft } = props;
  

  let dataState = useSelector((state) => {
    return state.switchBoardsSave;
  });
let index;
  if(name==="Edit"){
     index=changeData;
  }else{
   index = dataState.length-1;
  }
console.log(dataState[index]);

 
  let dispatch = useDispatch();
 
  function handleSwitch() {
    let roomNamme = dataState[index]

    if (roomNamme) {
      roomNamme.switchboards = [
        ...roomNamme.switchboards,
        {
          switchboardNumber: roomNamme.switchboards.length + 1,
          appliances: {
            fans: 0,
            light: 0,
            ac: 0,
            geyser: 0,
            tv: 0,
            socket: 0,
            other: 0,
            ir:0
          },
        },
      ];
      console.log("switch add ",roomNamme);
      dispatch(addSwitchBoardInRoom(roomNamme))
    }

  }

  let [roomName, setRoomName] = useState([
    {
      name: "Living Room",
    },
    {
      name: "Dining Room",
    },
    {
      name: "Master Bed Room",
    },
    {
      name: "Guest Room",
    },
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };
  const handleDelete = async (i) => {
    if (i === 0) {
      return;
    }
    const newData = dataState[index];
    newData.switchboards = newData.switchboards
      .filter((e, index) => index !== i)
      .map((e, index) => {
        return { ...e, switchboardNumber: index + 1 };
      });

    dispatch(addSwitchBoardInRoom(newData))
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  
    console.log("102",dataState[index])
  
  return (
    <div>
      <div
      
        className="SwitchBoard-container"
        style={{
         
        }}
      >
        {/* <div className="room-name-cont">
          {roomName.length !== 0
            ? roomName.map((e, i) => {
              return (
                <div>
                  <button
                    onClick={() => {
                      let btn = document.querySelectorAll(".room-name-btn");

                      btn.forEach((ele) => {
                        ele.classList.remove("background-blue");
                      });
                      btn[i].classList.add("background-blue");
                      dataState[index].roomName = e.name
                      dispatch(addSwitchBoardInRoom(dataState))

                    }}
                    className={`room-name-btn ${e.name === "Living Room"
                        ? "background-blue"
                        : ".border-colors"
                      }`}
                    key={i}
                  >
                    <span className="room-name-btn-txt">{e.name}</span>
                  </button>
                </div>
              );
            })
            : null}
          <div>
            <button
              className="room-name-btn"
              aria-describedby={id}
              onClick={handleClick}
            >
              Custom
            </button>
          </div>
          <Popover
            sx={{
              borderRadius: 25,
              width: "35vw",
            }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <input
              className="input-roomName"
              type="text"
              placeholder="Room name"
              onBlur={(e) => {
                const Rname = e.target.value;
                setRoomName([...roomName, { name: Rname }]);
              }}
            />
          </Popover>
        </div> */}
        <div className="Detail-switchBoard-roomName">
           <input defaultValue={dataState[index].roomName } onBlur={(ele)=>{
              const newData = {...dataState[index]};
              newData.roomName= ele.target.value;
              dispatch(addSwitchBoardInRoom(newData))
           }}></input>
        </div>
        <div className="swichBoard-data">
          {console.log("log",dataState[index].switchboards)}
          {
            dataState[index].switchboards.length !== 0
              ? dataState[index].switchboards.map((e, i) => {
                return (
                  <>
                    <div className="SwitchBoard-title-container" key={i}>
                      SwitchBoard {i + 1}
                      <span
                        className="roomDetails-SwitchBoard-delete-btn"
                        ids={i + 1}
                        onClick={() => {
                          handleDelete(i);
                        }}
                      >
                        x
                      </span>
                    </div>

                    <SwitchBoard
                      Switchboard={i}
                      data={dataState[index]}
                      appliancesLeft={appliancesLeft}
                    />
                  </>
                );
              })
              : null}
        </div>

        <div style={{ width: "100%" }}>
          <div
            onClick={() => {
              handleSwitch();
            }}
            className="room-appliance-count"
            style={{ width: "90%", left: "5%", top: 0,cursor:"pointer" }}
          >
            <div className="IMG">
              <img
                style={{
                  height: "14.98px",
                  width: "14.06px",
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "centre",
                }}
                src={process.env.PUBLIC_URL + "/assets/icon/Frame.png"}
                alt="plus"
              ></img>
            </div>
            <div className="SwitchBoard-appliance-name-text">
              Add SwitchBoard
            </div>
          </div>
        </div>
      </div>


    


        <div
          className="addroom-bottom-container"
          onClick={(e) => {
            e.preventDefault();
            props.onClosee();
          }}
        >
          <span className="add-room-detailes">Done</span>
        </div>
      
    </div>
  );
}
