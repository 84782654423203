import axios from 'axios'
import React, { useState } from 'react'
import { pages, serverUrl } from '../ApiUrl'
import './AlternateNumber.css'
import { useDispatch } from 'react-redux'
import { updateAddressLayout } from '../Redux/Action/adderess&layout'

export default function  ({e,notify,reload,setReload,page=""}) {
    const dispatch = useDispatch()
    let [alternateNumberPop,setAlternatePop] = useState(false)
    let [number,setNumber] = useState(0)
    const alternateNumber=()=>{
        axios.post(`${serverUrl.u}/api/tele/updateAlternateNumber`,{
            generatedHex:e.generatedHex, 
            alternatePhone:number
        }).then(function (res) {
            console.log(res,"kk");
            if(res.status===200){
                notify("Number Updated")
                if(page===pages.addressLayout){
                dispatch(updateAddressLayout(res.data.alisteSubscription))
                }else{
                  setReload(!reload)
                }
                setAlternatePop(false)
            }else(
                notify(res.data.message)
            )
        }).catch(function (err) {
            notify(err.data.message)
        })
    }
  return (
    <>
  <td  onClick={()=>{setAlternatePop(true)}}>{e.alternatePhone}</td>
  {
    alternateNumberPop===true?
    <div className='Alternate'>
        <div style={{float:"right",marginBottom:"5px",cursor:"pointer"}} onClick={()=>setAlternatePop(false)}>X</div>
          <div className="row">
           
           <label className="create-label1">Alternate Phone Number <span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text" 
                       class="form-control" 
                       id="phoneNumber" 
                       name="phoneNumber" 
                       value={number}
                       onChange={(ele)=>setNumber(ele.target.value)}
                       required 
                       placeholder="Phone Number" 
                       aria-label="phoneNumber" 
                       aria-describedby="basic-addon1" />
              </div>
           </div>
            <button className='btn btn-primary' onClick={()=>alternateNumber()}>Update Number</button>
    </div>:null
  }
  </>
  )
}
