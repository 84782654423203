export const refundRecordReducer = (state={},action)=>{
    
        switch (action.type) {
          case "refundRecord":
             return action.payload;
         
          default:
            return state;
          
        }
  }

  export const refundPendingRecordReducer = (state={},action)=>{
    
        switch (action.type) {
          case "refundPendingRecord":
             return action.payload;
         
          default:
            return state;
          
        }
  }

  export const refundCompleteRecordReducer = (state={},action)=>{
    
    switch (action.type) {
      case "refundCompleteRecord":
         return action.payload;
     
      default:
        return state;
      
    }
}