import axios from 'axios';
import React from 'react'
import { useRef } from 'react';
import { useState } from 'react'
import { serverUrl } from '../ApiUrl';
import { notify } from '../Common/Notification';
import { useSelector } from 'react-redux';
import { subHistory } from '../Function/SubscriptionHistory';

export default function SubscrionStageChange({ data }) {
  let [subscriptionStagePop, setSubscriptionStagePop] = useState(false)
  let subStage = useRef("address&layout");
  let loginName = useSelector((state) => state.sar.data.user.first_name);


  const changeSubscriptionStage = (status) => {
    axios
      .post(`${serverUrl.u}/api/tele/changeSubscriptionStage`, {
        generatedHex: data.generatedHex,
        subscriptionStage: status,
      })
      .then(function (res) {
        if (res.status === 200) {
          notify(res.data.message);
          setSubscriptionStagePop(false);
          if(status==="ready to install"){
            subHistory("Send to Installation Page",data,"Subscription Stage Change",data.InstallationDate)
          }else{
            subHistory(`Send to ${status}`,data,"Subscription Stage Change",status)
          }
        } else {
          notify(res.data.message);
        }
        console.log(res);
      })
  }
  return (
    <>
      <button className='btn btn-primary' onClick={() => setSubscriptionStagePop(true)}>Subscription Stage Change</button>
      {subscriptionStagePop === true ? (
        <div
          className="greivance-container"
          style={{ position: "fixed", top: "35% !important" }}
        >
          <span
            style={{ position: "absolute", right: "15px", fontSize: "25px" }}
            onClick={() => setSubscriptionStagePop(!subscriptionStagePop)}
          >
            x
          </span>

          <label style={{ marginTop: "25px" }}>Select Stage</label>
          <div class="input-group mb-3">
            <select
              defaultValue="address&layout"
              ref={subStage}
              style={{
                boxShadow:
                  "0px -25px 20px -20px rgba(0, 0, 0, 0.45), 25px 0 20px -20px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45)",
                width: "100%",
                borderRadius: "12px",
                fontSize: "18px",
              }}
            >

              <option value="address&layout">address&layout</option>
              <option value="hardware">hardware</option>
              <option value="ready to install">ready to install</option>
            
              {loginName === "Udit" || loginName === "BHAVYA" || loginName === "Akhil" ? (
                <option value="installed">installed</option>
              ) : null}
              {loginName === "Udit" || loginName === "BHAVYA" || loginName === "Akhil" || loginName === "Tanu" ? (
                <>
                  <option value="installed & complete">
                    installed & complete
                  </option>

                  <option value={"uninstallation"}>Uninstallation</option>
                </>) : null}
            </select>
          </div>

          <button
            className="btn btn-primary"
            onClick={() => changeSubscriptionStage(subStage.current.value)}
          >
            Change Stage
          </button>
        </div>
      ) : null}
    </>
  )
}
