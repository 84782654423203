import React, { useEffect, useState } from "react";
import Qutoes from "../Qutoes";
import { planDuration, plan, dummy, SeurityInstallion, planCost } from '../Data'
import { useSelector } from "react-redux";
import './MakeBill.css'
import { newClientRegister } from "./Api";
import { recursiveAmountCalculate } from "../Common/applianceDifference";

export default function CreateLead() {
  let userRole = useSelector((state) => state.sar.data?.user?.roles)
  let stateN = "Any For Now"
  let [paymentLink, setPaymentLink] = useState("")
  const [text, setText] = useState("");
  let [planD, setPlanD] = useState("");
  let [qutoBtnText, setQutoBtnText] = useState("Download")
  let [numberWhatapp, setNumberWhatapp] = useState("");
  let [planN, setPlanN] = useState(1);
  let [planNa, setPlanNa] = useState("1 Room");
  let teleCallers = useSelector((state) => state.sar.data.user.first_name)
  let [check, setCheck] = useState(false);
  let [install, setInsall] = useState({
    security: 400,
    Installion: 1000,
    quantity: 4
  })

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userData2 = {
      first_name: data.get("fname"),
      last_name: data.get("lname"),
      phone: "1234567890",
      city: "Any for Now",
      address: "no",
      planType: data.get("planDuration"),
      plan: data.get("plan"),
      origin: data.get("source"),
      leadQuality: 0,
      email: "demo@gmail.com",
      zip: "000000",
      comments: data.get("comments"),
      security: data.get("security"),
      installion: data.get("install"),
      teleCallerId: data.get("teleCaller"),
      refferal: "empty",
      teleCallerSecond: "empty",
      quantityAppliances: data.get("appliance"),
      partialAmount: 100
    }

    let seq = Number(userData2.security);
    let ins = Number(userData2.installion);
    let qu = Number(userData2.quantityAppliances)
    let planDurations = userData2.planType;
    if (planN === 0) {
      qu = Number(userData2.quantityAppliances)
    } else {
      qu = install.quantity;
    }
    let fee = await recursiveAmountCalculate(planDurations, qu);
    let total = ((fee + ins) * 1.18 + seq).toFixed(2)
    setCheck(!check);
    let tax = ((fee + ins) * 0.18).toFixed(2)
    userData2 = { ...userData2, totalAppliances: qu, orignalTotalAmount: total, totalAmount: total, orignalRecursiveAmount: fee, recursiveAmount: fee, tax: tax }
    newClientRegister(userData2, setQutoBtnText, planN, stateN, userRole, teleCallers, dummy, setText, setPaymentLink, install)
  };

  useEffect(() => {
    if (planN === 0) return;
    setInsall(SeurityInstallion[planN - 1])
  }, [planN])

  const handlePlanDuration = (event) => {
    setPlanD(event.target.value);
  };
  const handlePlan = (event) => {
    setPlanNa(event.target.value);
    if (event.target.value === "Custom") {
      setPlanN(0)
    } else if (event.target.value === "1 Room") {
      setPlanN(1)
    } else if (event.target.value === "1 BHK") {
      setPlanN(2)
    } else if (event.target.value === "2 BHK") {
      setPlanN(3)
    } else if (event.target.value === "3 BHK") {
      setPlanN(4)
    } else if (event.target.value === "4 BHK") {
      setPlanN(5)
    }
    else if (event.target.value === "5 BHK") {
      setPlanN(6)
    } else if (event.target.value === "6 BHK") {
      setPlanN(7)
    }
    console.log(event.target.value);
  };

  const handleChangeCustom = (event) => {
    let quantitys = event.target.value
    let sequrity = quantitys * 100;
    let Installion;
    if (quantitys > 10) {
      let cal = quantitys - 10;
      Installion = 1000 + (cal * 50);
    } else {
      Installion = 1000;
    }
    setInsall({
      planName: "Custom",
      security: sequrity,
      Installion: Installion,
      quantity: quantitys
    })
  }


  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <form className="make_quto" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
        <div className="row">
          <div className="col-6">
            <label>First Name <span className="required_star">*</span></label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="fname" name="fname" autoComplete="fname"  required placeholder="First Name" aria-label="fname" aria-describedby="basic-addon1" />
            </div>
          </div>
          <div className="col-6">
            <label>Last Name</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="lname" name="lname" autoComplete="current-lname" placeholder="Last Name" aria-label="lname" aria-describedby="basic-addon1" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <label>Plan Name <span className="required_star">*</span></label>
            <div class="input-group mb-3">
              <select class="custom-select" required id="inputGroupSelect01" onChange={handlePlan} defaultValue="choose one"
                name="plan"
                label="plan"
                autoComplete="current-plan" value={planNa}>
                {plan.map((option) => (
                  <option value={option.text}>{option.text}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-6">
            <label>Plan Duration <span className="required_star">*</span></label>
            <div class="input-group mb-3">
              <select class="custom-select" required id="inputGroupSelect01" onChange={handlePlanDuration} defaultValue="choose one"
                name="planDuration"
                label="planDuration"
                autoComplete="current-planDuration" value={planD}>

                {planDuration.map((option) => (
                  <option value={option.value}>{option.value}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {
          planN === 0 ?
            <div className="row">
              <div className="col-6">
                <label>Total Appliances <span className="required_star">*</span></label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="appliance" name="appliance" autoComplete="current-appliance" placeholder="Total appliances" aria-label="appliance" aria-describedby="basic-addon1" onChange={(e) => { handleChangeCustom(e) }} />
                </div>
              </div>
            </div> : null
        }

        <div className="row">
          <div className="col-6">
            <label>Security <span className="required_star">*</span></label>
            <div class="input-group mb-3">
              <input type="number" class="form-control" value={install.security} id="security" name="security" autoComplete="current-security" onChange={(e) => { setInsall({ ...install, security: e.target.value }) }} placeholder="Security" aria-label="security" aria-describedby="basic-addon1" onWheel={event => event.currentTarget.blur()} />
            </div>
          </div>
          <div className="col-6">
            <label>Installation <span className="required_star">*</span></label>
            <div class="input-group mb-3">
              <input type="number" class="form-control" value={install.Installion} id="install" name="install" autoComplete="current-install" onChange={(e) => { setInsall({ ...install, Installion: e.target.value }) }} placeholder="Installation" aria-label="install" aria-describedby="basic-addon1" onWheel={event => event.currentTarget.blur()} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <button
              className="btn btn-primary"
              type="submit"
              variant="contained"
              sx={{ pointerEvents: `${qutoBtnText === "Processing..." ? "none" : ""}`, backgroundColor: `${qutoBtnText === "Processing..." ? "#cccccc" : "#2552B2"}` }}
              onClick={() => {

                setQutoBtnText("Processing...")
              }}
            >
              {qutoBtnText}
            </button>
          </div>

        </div>
      </form>
      <div className="make_quto">
        <div className="row">
          <div className="col-4">
            <div class="input-group">
              <input type="text" style={{ height: "41px !important" }} class="form-control" id="phoneNumbers" name="phoneNumbers" required placeholder="Phone Number" aria-label="phoneNumbers" aria-describedby="basic-addon1" onChange={(e) => {
                setNumberWhatapp(e.target.value)
              }} />
            </div>
          </div>
          <div className="col-2">
            <a href={`http://wa.me/${numberWhatapp}?text=Hey there, 
This is ${teleCallers} from Aliste Home Automation. 
As discussed, I am sharing our brochure, pricing and videos below. 
Would request you to go through them and let me know if you have any queries in regards to the same. 
For further details please feel free to reach out to me or visit alistetechnologies.com`} target="_blank">
              <img src={process.env.PUBLIC_URL + '/assets/icon/WhatsApp_logo.svg'} style={{ height: "43px" }} />
            </a>
          </div>

        </div>
      </div>
<Qutoes/>
    </div>
  );
}
