import axios from "axios";
const config = {
  sub: "https://subscription.alistetechnologies.com/v3/referral",
  test: "https://test.alistetechnologies.com:8443/v3/referral"
};

export const getAllReferrals = async () => {
  try {
    const response = await axios.post(`${config.url}/getAll`);
    return {
      success: true,
      data: response.data.data.referral,
    };
  } catch (error) {
    return {
      success: false,
      data: {},
      err: { error },
    };
  }
};


export const markPaid = async (referredTo) => {
    try {
    const response = await axios.post(`${config.url}/markPaid`, {referredTo});
        console.log('Respnose - ', response);
    if (!response.data.success) {
        return {
            success: false,
            data: response.data
        }
    }

    return {
        success: true,
        data: response.data.data.referral
    }
    } catch (error) {
      return {
        success: false,
        data: {},
        err: { error },
      };  
    }
}

export const getPaginatedResponse = async (payload) => {
  try {
    const {page, limit=30, sortBy="updatedAt"} = payload;

    const response = await axios.get(`${config.sub}/getReferrals?page=${page}&limit=${limit}&sortBy=${sortBy}`);

    const { success, message, data} = response.data;

    return {
      success,
      message,
      data,
    }

  } catch (error) {
    return {
      success: false,
      data: { error }
    }
  }
}

export const getAllReferralsByEmployee = async (payload) => {
  try {
    const response = await axios.post(`${config.sub}/getReferralsByEmployee`, payload);

    const { success, message, data } = response.data;

    return {
      success,
      message,
      data
    }

  } catch (error) {
    return {
      success: false,
      error: error
    } 
  }
}


export const getAllReferralsByUser = async (payload) => {
  try {
    const response = await axios.post(`${config.sub}/getReferralsByUser`, payload);

    const { success, message, data } = response.data;

    return {
      success,
      message,
      data
    }

  } catch (error) {
    return {
      success: false,
      data: { error }
    }
  }
}