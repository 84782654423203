import axios from "axios"
import { serverUrl } from "../ApiUrl"
import { notify, notifyFailed } from "../Common/Notification"

export const pendingLeave = async(setLeave)=>{
   await axios.get(`${serverUrl.u}/app/electrician/showAllPendingLeave`).then(
        res =>{
            if(res.data.success===true){
                notify(res.data.message)
                setLeave(res.data.data.pendingLeave)
            }else{
                notifyFailed(res.data.message)
            }
        }
    ).catch(err =>{
        notifyFailed("Failed")
    })
}
export const allLeave = async(setLeave)=>{
    await axios.get(`${serverUrl.u}/app/electrician/getAllLeaveRecord`).then(
         res =>{
             if(res.data.success===true){
                 notify(res.data.message)
                 setLeave(res.data.data.electricianLeave)
             }else{
                 notifyFailed(res.data.message)
             }
         }
     ).catch(err =>{
         notifyFailed("Failed")
     })
 }
export const approveLeave=async(employeeId,leaveArray)=>{
   await axios.post(`${serverUrl.u}/app/electrician/approveDisapproveLeave`,{
    employeeId,
    leaveArray,
   }).then(res=>{
    if (res.data.success===true) {
        notify("Leave Approved")
    }else{
        notifyFailed("Leave Not Approved")
    }
   }).catch(err=>{
      notifyFailed("Failed")
   })
}
export const disapproveLeave=async(employeeId,leaveArray)=>{
    await axios.post(`${serverUrl.u}/app/electrician/disapproveLeave`,{
     employeeId,
     leaveArray,
    }).then(res=>{
     if (res.data.success===true) {
         notify("Leave DisApproved")
     }else{
         notifyFailed("Leave Not DisApproved")
     }
    }).catch(err=>{
       notifyFailed("Failed")
    })
 }

export const electricianOutOfCity =(employeeId,setLeave)=>{
      axios.post(`${serverUrl.u}/app/electrician/toogleOutOfCity`,{
        employeeId
      }).then(function (res) {
        console.log(res);
        if(res.data.success===true){
            notify(res.data.message)
            allLeave(setLeave)
        }else{
            notifyFailed(res.data.message)
        }
      }).catch(function (err) {
        notifyFailed("Out Of City Failed")
      })
 }