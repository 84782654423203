export const NumberChart = {
   AllDays :{
   //  dailyMetrix:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=656861f6-7cf7-460a-840f-a5d837dc2bd1&maxDataAge=3600&theme=light&autoRefresh=true",
   //  allInstallationDone:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=6569e9db-ffd8-4f13-88fb-ca6a71ca9647&maxDataAge=3600&theme=light&autoRefresh=true",
   //  booking:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=6569edc6-9190-4e23-86d2-0fcad6a5db80&maxDataAge=3600&theme=light&autoRefresh=true",
   //  revenue:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=cdc4c869-38cb-41a8-a272-c86d36016347&maxDataAge=3600&theme=light&autoRefresh=true",
   //  glance:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=59bf52b7-dc8e-4cff-b0c0-a6f80ea0f36a&maxDataAge=3600&theme=light&autoRefresh=true",
   //  revenuebifurcate:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=62acb4e1-56b6-4efb-bb75-c9f5054b241f&maxDataAge=3600&theme=light&autoRefresh=true",
    subscriptionD2C:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=7e852a02-6dbc-41be-8e97-17c94622905f&maxDataAge=3600&theme=light&autoRefresh=true',
    recursiveRevenueD2C:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=1840b671-2dd4-479c-ad8d-acd6299fc3ff&maxDataAge=3600&theme=light&autoRefresh=true',
    automatedD2C:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=6569e9db-ffd8-4f13-88fb-ca6a71ca9647&maxDataAge=3600&theme=light&autoRefresh=true',
    subscriptionB2B:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=48edf8f4-3593-4b41-a2d7-07379b07422d&maxDataAge=3600&theme=light&autoRefresh=true',
    recursiveRevenueB2B:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=5d517552-4e4c-4fb5-a471-3dab6716d8df&maxDataAge=3600&theme=light&autoRefresh=true',
    automatedB2B:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=4d840469-2e18-40e1-b475-e68e4619e505&maxDataAge=3600&theme=light&autoRefresh=true',
    revenue:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=cdc4c869-38cb-41a8-a272-c86d36016347&maxDataAge=3600&theme=light&autoRefresh=true',
   },
   CurrentMonth:{
   //  dailyMetrix:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=cbeb35f8-90a7-45b0-a8a3-454b81e948f6&maxDataAge=3600&theme=light&autoRefresh=true",
   //  allInstallationDone:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=6fe80f33-f0ae-4b94-9ad2-caeca31602a5&maxDataAge=3600&theme=light&autoRefresh=true",
   //  booking:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=fff587af-6e11-4fdf-a64f-26a0f7dcc6d1&maxDataAge=3600&theme=light&autoRefresh=true",
   //  revenue:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=6569f0b9-59d2-44fe-80ef-7c642af8f798&maxDataAge=3600&theme=light&autoRefresh=true",
   //  glance:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=98c7640b-11f0-4d86-9aa5-8918363d1df8&maxDataAge=3600&theme=light&autoRefresh=true",
   //  revenuebifurcate:"https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=73c02c5b-b679-4dbc-b578-44996610e099&maxDataAge=3600&theme=light&autoRefresh=true",
    subscriptionD2C:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=f57a5511-62b6-4506-9197-c666fe4012bf&maxDataAge=3600&theme=light&autoRefresh=true',
    recursiveRevenueD2C:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=4c25cd48-c0d3-4de7-9b57-92a57ad404c5&maxDataAge=3600&theme=light&autoRefresh=true',
    automatedD2C:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=c24138db-f2dd-4878-a088-2ccc261e45eb&maxDataAge=3600&theme=light&autoRefresh=true',
    subscriptionB2B:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=f3449316-54bb-43b0-9fe2-b643bc9fa80b&maxDataAge=3600&theme=light&autoRefresh=true',
    recursiveRevenueB2B:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=8764c3e2-8ab1-4266-bc23-6d5e095b0fb2&maxDataAge=3600&theme=light&autoRefresh=true',
    automatedB2B:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=2636c5a5-bd86-4e82-afb3-8559f3431f09&maxDataAge=3600&theme=light&autoRefresh=true',
    revenue:'https://charts.mongodb.com/charts-aliste-technologies-nfpjc/embed/charts?id=6569f0b9-59d2-44fe-80ef-7c642af8f798&maxDataAge=3600&theme=light&autoRefresh=true', 
   }
}