import axios from "axios";
import React, { useState } from "react";
import { useAsyncError } from "react-router-dom";
import Spinner from "../Function/Spinner";

const salesExecutiveNames = {
  9650887596: "Ambika (9650887596)",
  9667599492: "Heena (9667599492)",
  6901441547: "Prity (6901441547)",
  9205773467: "Tanu (9205773467)",
  9311533284: "Menna (9311533284)",
  9667169022: "Havisha (9667169022)",
  8826119402: "Geetika (8826119402)",
  9667183235: "Vishakha (9667183235)",
  9289331675: "Aastha (9289331675)",
  9599759430: "afreen (9599759430)",
  7028664846: "Jyoti (7028664846)",
  8130259803: "Mahima (8130259803)",
};

  // 9667758104: "Ambika",
  // 9327479686: "Heena",
  // 6901441547: "Prity",
  // 9205773467: "Tanu",
  // 9311533284: "Menna",

export default function LeadsConsumed() {
  const [date, setDate] = useState("");
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  async function getData() {
    if (date === "") return;

    setLoading(true);
    await axios
      .post(
        "https://8fza561rd2.execute-api.ap-south-1.amazonaws.com/default/leads/getLeadsConsumed",
        {
          date: new Date(date).setHours(0, 0, 0, 0),
        }
      )
      .then((res) => {
        setLoading(false);
        setResponse(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  let allSalesExecutive = [];
  let allStatus = [];

  for (const s of Object.keys(response)) {
    allSalesExecutive.push(s);
    for (const status of Object.keys(response[s])) {
      if (!allStatus.includes(status)) {
        allStatus.push(status);
      }
    }
  }

  const calculateTotal = (status) => {
    let total = 0;

    for (const s of Object.keys(response)) {
      total += response[s][status] ? response[s][status] : 0;
    }
    return total;
  };

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="row">
        <div className="col-2">
          <label className="create-label1">Start Date</label>
          <div class="input-group ">
            <input type="date" onChange={(e) => setDate(e.target.value)} />
          </div>
        </div>

        <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
              getData();
            }}
          >
            Get Data
          </button>
        </div>
        {/* <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
              //   generateCsvData();
            }}
          >
            Generate CSV
          </button>
        </div> */}
      </div>

      {loading && <Spinner />}
      <div className="table-container1 table-fixed">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th
                style={{ width: "200px", left: 0 }}
                className="sticky-col second-col stickyHeader"
              >
                Sales Executive
              </th>
              {allStatus.map((status) => (
                <th>{status}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* {allStatus &&
               allStatus.map((status) => (
                 <tr style={{ backgroundColor: getColors[status] }}>
                   <td>{status}</td>
                   <td>{totalValues[status]}</td>
                 </tr>
               ))} */}
            {allSalesExecutive.map((se) => (
              <>
                <tr>
                  <td
                    key={se}
                    className="sticky-col second-col stickyHeader"
                    style={{ left: 0, zIndex: "1 !important" }}
                  >
                    {salesExecutiveNames[Number(se)]}
                  </td>
                  {allStatus.map((status) => {
                    return (
                      <td>{response[se][status] ? response[se][status] : 0}</td>
                    );
                  })}
                </tr>
              </>
            ))}
            <tr>
              <td className="sticky-col second-col stickyHeader" style={{left: 0}}>Total</td>
              {allStatus.map((status) => (
                <td key={status}>{calculateTotal(status)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
