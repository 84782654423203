import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'

export default function AssignGrivence({data, setReload,reload}) {
    const assignGrivence = ()=>{
        axios.post(`${serverUrl.u}/app/elec/toggleGrievanceElectrician`,{
            employeeId:data.employeeId,
            grievanceElectrician:!data.grievanceElectrician
        }).then(function (res) {
             console.log(res);
             setReload(!reload)
        }).catch(function (err) {
            console.log(err);
        })
    }
  return (
    <button className='btn btn-primary' onClick={()=>assignGrivence()}>{data.grievanceElectrician===true?"Un-Assign Grivance":"Assign Grivence"}</button>
  )
}
