import { locationTechniciansAction } from "../actionIndex"



export const locationLog = (data)=>{
    console.log(data,"date");
    return{
        type:locationTechniciansAction.LOAD,
        payload:data
    }
}