import axios from 'axios';
import React from 'react'
import { serverUrl } from '../ApiUrl';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Spinner from '../Function/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { subHistory } from '../Function/SubscriptionHistory';
export default function ManualSwitchBlock({data,decision}) {
  let userDetail = useSelector((state) => state.sar.data?.user)
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let [spinners,setSpinner] = useState(false)
  let successMessage = (text)=>toast.success(text)
  let errorMessage = (text)=>toast.error(text)
  console.log(userDetail);
  let devicesBlock_Not = decision;
  const deviceId = async()=>{
    let deviceIds = []
    setSpinner(true)
    await axios.post(`${serverUrl.u}/api/div/getAllDevices`,
    {
        "houseId":data.houseId,
    }).then(function (res){
             console.log(res);
            for(let device of Object.keys(res.data.data)){
              //  console.log(res.data.data[device]);
              for(let value of Object.keys(res.data.data[device])){
                console.log(value,res.data.data[device][value]);
                deviceIds=[...deviceIds,...res.data.data[device][value]]
              }
            }
            
    }).catch(function (err){
     console.log(err);
    })
    
    for(let id of deviceIds){
      console.log(id,"d");
      console.log(id.substring(1,3));
      let d= id.split(" ")
      let event="";
      if(decision===1){
        if(id.substring(1,3)=="01"){
          event="1"
       }else if(id.substring(1,3)=="02"){
         event="11"
       }else if(id.substring(1,3)=="03"){
         event="1111"
       }else if(id.substring(1,3)=="04"){
         event="11111"
       }else if(id.substring(1,3)=="05"){
         event="11111"
       }else if(id.substring(1,3)=="06"){
         event="11"
       }else if(id.substring(1,3)=="12"){
         event="111"
       }else if(id.substring(1,3)=="13"){
         event="1111"
       }else{
        continue;
       }
      }else if(decision===0){
        if(id.substring(1,3)=="01"){
          event="0"
       }else if(id.substring(1,3)=="02"){
         event="00"
       }else if(id.substring(1,3)=="03"){
         event="0000"
       }else if(id.substring(1,3)=="04"){
         event="00000"
       }else if(id.substring(1,3)=="05"){
         event="00000"
       }else if(id.substring(1,3)=="06"){
         event="00"
       }else if(id.substring(1,3)=="12"){
         event="000"
       }else if(id.substring(1,3)=="13"){
         event="0000"
       }else{
        continue;
       }
      }
      
      axios.post(`https://a3.alistetechnologies.com/v3/device/updateSwitchStates?user=${loginName}`,{
        deviceId: d[0],
        states: event
      }).then(function (res) {
          console.log(res);
          if(res.data.success===true){
            successMessage(`${d} Device ${decision===0?"Blocked":"UnBlocked"} Sucessfully`)
          }else{ 
            errorMessage(`Failed for this ${d} `)
          }
      }).catch(function (error) {
        console.log(error);
        errorMessage(`Failed for this ${d} `)
      })
     
    }
    if(decision===1){
      const ActivateHouse = async()=>{
        await axios.post(`${serverUrl.u}/v2/house/activate`,{
          houseId:data.houseId,
        }).then(function (res) {
          console.log(res);
          
      subHistory("House Activated With Mannual Switch",data,"House Active/Deactive","House Activated With Mannual Switch")
          successMessage(res.data.message)
        }).catch(function (err) {
          console.log(err);
          errorMessage("Error")
        })
     }
     ActivateHouse();
    }else if(decision===0){
      const DectivateHouse = async()=>{
        await axios.post(`${serverUrl.u}/v2/house/deactivate`,{
          houseId:data.houseId,
        }).then(function (res) {
          console.log(res);
      subHistory("House Deactivated With Mannual Switch",data,"House Active/Deactive","House Deactivated With Mannual Switch")
          successMessage(res.data.message)
        }).catch(function (err) {
          console.log(err);
          errorMessage("Error")
        })
      }
      DectivateHouse();
    }
    
    subHistory(`${decision===1?"Manual Switch UnBlock":"Manual Switch Block"}`,data,"Manual Switch Block/UnBlock",`${decision===1?"Manual Switch UnBlock":"Manual Switch Block"}`)
    setSpinner(false)
 }
  return (
    <>
          <button className='btn btn-primary' style={{backgroundColor:decision===1?"#28a745":"#ec0909c7"}} onClick={()=>deviceId()}>{decision===0?"Manual Switch Block":"Manual Switch UnBlock"}</button>
          {
            spinners===true?
            <div>
              <Spinner/>
              <ToastContainer/>
            </div>:null
          }
    </>
  )
}
