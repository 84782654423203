import React, { useEffect, useState } from "react";
import { apiRequest } from "../../../utils";
import { serverUrl } from "../../../component/ApiUrl";
import { notify, notifyFailed } from "../../../component/Common/Notification";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";

export default function ListCustomers({setCustomerData, setTab}) {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const response = await apiRequest({
        url: `${serverUrl.u}/v3/customer/fetch`,
        method: "GET",
      });

      if (!response.success) {
        notifyFailed(response.message);
        setLoading(false);
        return;
      }

      setCustomers(response.data.data.customer);

      notify(response.message);
      setLoading(false);
    };
    fetchData();
  }, []);

  // Data Grid Data Manupulations

  // const columns = Object.keys(customers[0] || {})
  //   .filter((header) => !["createdAt", "updatedAt"].includes(header))
  //   .map((header) => {
  //     if (header === "_id") {
  //       return { field: "_id", headerName: "Mongo ID" };
  //     } else {
  //       return {
  //         field: header,
  //         headerName: header.charAt(0).toUpperCase() + header.slice(1),
  //       };
  //     }
  //   });


  function handleEdit (data) {
    console.log("DATA - ", data);

    setCustomerData(data.data.rawData);
    setTab(1);
  }

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
    },
    {
      field: "last_name",
      headerName: "Last Name",
    },
    {
      field: "legalName",
      headerName: "Legal Name",
    },
    {
      field: "phoneNumbers",
      headerName: "PhoneNumbers",
      width: 200,
    },
    {
      field: "gstNumber",
      headerName: "GST Number",
    },
    {
      field: "emails",
      headerName: "Emails",
    },
    {
      field: "type",
      headerName: "Type",
    },
    {
      field: "addresses",
      headerName: "Address",
      width: 250,
    },
  ];

//   Add action buttons
  columns.push({
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
          <div style={{ display: "flex", gap: "10px" }}>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                      handleEdit({ type: "UPDATE", data: params.row })
                  }
              >
                  <Edit />
              </Button>
              {/* <Button
                  variant="outlined"
                  style={{ color: "red", border: "1px solid red" }}
                  onClick={() => handleDelete(params.row)}
              >
                  <Delete />
              </Button> */}
          </div>
      ),
  });

  const rows = customers.map((cus, i) => ({
    ...cus,
    rawData: cus,
    id: i + 1,
    phoneNumbers: cus.phoneNumbers.map((p) => p).join(", "),
    addresses: `${cus.addresses[0].address} ${cus.addresses[0].city}, ${cus.addresses[0].country} ${cus.addresses[0].pinCode}`,
  }));

  return (
    <Box sx={{ width: "100%", height: 600, p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Customers
      </Typography>

      {loading ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="pulse"
          />
        </Box>
      ) : (
        <DataGrid
          columns={columns}
          rows={rows}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 20, 30, 40]}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              csvOptions: {
                fileName: `Customers_list`,
              },
            },
          }}
        />
      )}
    </Box>
  );
}
