import axios from "axios";
import { serverUrl } from "../../component/ApiUrl";

export const getUserOtpApi = async (data) => {
  try {
    console.log(data, " - data");
    const response = await axios.post(
      `${serverUrl.user_service}/admin/v1/getUserOtp`,
      data
    );

    console.log(response.data, "GET USER OTP response");
    return {
      success: true,
      data: response.data.data,
    };

    // if (response.status ===)
  } catch (error) {
    console.log("ERROR - ");
    if (error.response) {
      // The request was made and server responded with a status code
      // that falls out of the range of 2xx
      console.log("1");
      return {
        success: false,
        error: `Error: ${error.response.status} - ${
          error.response?.data?.message || error.response.statusText
        }`,
      };
    } else if (error.request) {
      // The request was made but no response was received
      console.log("2");

      return {
        success: false,
        error: `Error: No response received from the server`,
      };
    } else {
      console.log("3");

      return {
        success: false,
        error: `Error: ${error.message || "Something went wrong"}`,
      };
    }
  }
};
