import axios from "axios"
import { serverUrl } from "../../ApiUrl"
import { notify } from "../../Common/Notification";
import store from '../../../component/Redux/index'
export const registerIsp = async (details, setBtn) => {
    console.log(details,"details");
    await axios.post(`${serverUrl.u}/app/isp/addProfile`, details).then(
        (res) => {
            console.log(res);
            if (res.data.success === true) {
                notify(res.data.message)
                setBtn("Registered");
            }else{
                setBtn("Failed")
            }
        }
    ).catch((err) => {
        console.log(err);
        setBtn("Failed");
    })
}

export const editIsp = async (details, setBtn) => {
    await axios.post(`${serverUrl.u}/app/isp/editIspProfile`, details).then(
        (res) => {
            console.log(res);
            if (res.data.success === true) {
                notify(res.data.message)
                setBtn("Done");
            }else{
                setBtn("Failed")
            }
        }
    ).catch((err) => {
        console.log(err);
        setBtn("Failed");
    })
}

export const getIsp = async (phone, setIsp) => {
    await axios.post(`${serverUrl.u}/app/isp/getISPProfile`, {
        "phone": phone
    }).then(
        (res) => {
            console.log(res);
            if (res.data.success === true) {
                notify(res.data.message)
                setIsp(res.data.data);
            } else {
                setIsp({})
            }
        }
    ).catch((err) => {
        console.log(err);
        setIsp({});
    })
}