import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SubCard from './SubCard';
import RefundCard from './RefundCard';
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({data},{id}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let totalInvoice = data.invoiceArray.length;
  let invoiceArray = data.invoiceArray;
  let events = data.events
  let refund = data.refund
  return (
    <Card sx={{ maxWidth: 1200 }}>
      
      <CardContent className='labelCardContent'>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Subscription Status</label>
        <label className='labelFormating'>{data.dropped?'Dropped':'Active'}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Generated Hex</label>
        <label className='labelFormating'>{data.generatedHex}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Invoice Count</label>
        <label className='labelFormating'>{totalInvoice}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='labelFormating' >Total Invoice Amount</label>
        <label className='labelFormating'>{data.actualTotalAmount}</label>
        </div>
        {
          data.dropped === true?
          <><div className='labelDivFormating'>
          <label className='labelFormating' >Reason Of Drop</label>
          <label className='labelFormating'>{data.reasonForDrop}</label>
          </div>
          <div className='labelDivFormating'>
          <label className='labelFormating' >New Hex</label>
          <label className='labelFormating'>{data.newHex}</label>
          </div></>:null

        }
       
      </CardContent>

      <CardActions disableSpacing>
        
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{height:0,outline:'none',border:'none'}}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        {
          invoiceArray.length > 0? invoiceArray.map((inv,id)=>{return <SubCard data={inv} id={id} type={"inv"}/>}):null
        }
        {
            events && events.map((inv,id)=>{return <SubCard data={inv} id={id} type={"event"}/>})
        }
         {
            refund && refund.map((inv,id)=>{return <RefundCard data={inv} id={id} />})
        }
        </CardContent>
      </Collapse>
    </Card>
  );
}
