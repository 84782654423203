import { Box, Button, Grid, Modal, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { apiRequest } from '../../../utils';
import { serverUrl } from '../../../component/ApiUrl';
import { notify, notifyFailed } from '../../../component/Common/Notification';

const METHOD_TYPE = [
  { label: 'razorpay', value: 'razorpay' },
  { label: 'cash', value: 'cash' },
  { label: 'payu', value: 'payu' },
  { label: 'other', value: 'other' },
  { label: 'banktransfer', value: 'banktransfer' },
  { label: 'payout', value: 'payout' },
];

const STATUS_TYPE = [
  { label: 'created', value: 'created' },
  { label: 'received', value: 'received' },
];

export default function MapInvoiceWithPayment({ data }) {
  const user = useSelector((state) => state.sar.data.user);

  const [open, setOpen] = useState(false);

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      invoiceIds: [''],
      amount: 0,
      method: '',
      mode: '',
      referenceId: '',
      details: {},
      verified: false,
      verificationDetail: {},
      comments: [
        {
          userId: '',
          userName: '',
          message: '',
          timestamp: '',
        },
      ],
      status: { label: 'received', value: 'received' },

      customerId: '',
    },
  });

  useEffect(() => {
    // Set values
    setValue('invoiceIds.0', data.InvoiceId);
    setValue('comments.0.userId', user._id);
    setValue('comments.0.userName', user.first_name + user.last_name);
    setValue('customerId', data.ClientId);
  }, [data]);

  async function onSubmit(data) {
    const finalData = data;

    finalData.method = data.method.value;
    finalData.status = data.status.value;
    finalData.comments[0].timestamp = new Date();
    const response = await apiRequest({
      url: `${serverUrl.test}/v3/payments/add`,
      data: finalData,
      method: 'POST',
    });

    console.log('RESPONSE - ', response.data);
    if (!response.data?.success) {
      notifyFailed(response.data.message);
    }

    notify('Successfully mapped.');
    setOpen(false);
  }
  return (
    <>
      <Button variant='contained' onClick={() => setOpen(true)}>
        Map Payment
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h4>Map Payments</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name='amount'
                  control={control}
                  rules={{
                    required: 'Amount is required!!',
                  }}
                  render={({ field }) => (
                    <TextField
                      label='Amount'
                      type='number'
                      fullWidth
                      error={errors?.amount}
                      helperText={errors?.amount?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name='mode'
                  control={control}
                  rules={{
                    required: 'Mode is required!!',
                  }}
                  render={({ field }) => (
                    <TextField
                      label='Mode'
                      fullWidth
                      error={errors?.mode}
                      helperText={errors?.mode?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              {/* Reference Id */}
              <Grid item xs={6}>
                <Controller
                  name='referenceId'
                  control={control}
                  rules={{
                    required: 'ReferenceId is required!!',
                  }}
                  render={({ field }) => (
                    <TextField
                      label='ReferenceId'
                      fullWidth
                      error={errors?.referenceId}
                      helperText={errors?.referenceId?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              {/* Message */}
              <Grid item xs={6}>
                <Controller
                  name='comments.0.message'
                  control={control}
                  rules={{
                    required: 'Message is required!!',
                  }}
                  render={({ field }) => (
                    <TextField
                      label='Message'
                      name='comments.0.message'
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>

              {/* Status */}
              <Grid item xs={6}>
                <Controller
                  name='status'
                  control={control}
                  rules={{
                    required: 'Please Select any method',
                  }}
                  render={({ field }) => (
                    <ReactSelect
                      options={STATUS_TYPE}
                      placeholder='Select Status'
                      defaultValue={STATUS_TYPE[1]}
                      {...field}
                    />
                  )}
                />
              </Grid>

              {/* Method */}
              <Grid item xs={6}>
                <Controller
                  name='method'
                  control={control}
                  rules={{
                    required: 'Please Select any method',
                  }}
                  render={({ field }) => (
                    <ReactSelect
                      options={METHOD_TYPE}
                      {...field}
                      placeholder='Select Payment Method'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Button type='submit' variant='contained'>
                  Map Payment
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
