import { Cases } from "@mui/icons-material";
import { Installation } from "./actionIndex";
import { ci } from "../city/Cities";
import { electricianList } from "./installationActions";

export const installationReducer = (state = [], action) => {
    switch (action.type) {
      case Installation.Installation_DATA:
        return action.payload;
      case Installation.UpdateInstallion_DATA:
        let data = state.map((element)=>{
            if(element._id===action.payload._id){
                 element={...action.payload}
              return element
            }else{
              return element
            }
         })  
        return data;
      case Installation.Remove_DATA :
        let removedata = state.filter((element)=>{
          if(element._id!==action.payload._id){
            return element
          }
       })  
      return removedata;
      default:
        return state;
    }
  };

export const electricianListReducer = (state=[],action)=>{
   const {type, payload} =  action
   switch (type) {
    case Installation.AVAILABLE_ELECTRICIAN:
      return payload
    default:
      return state
   }
}