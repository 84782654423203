export const dashboardLink = [
       {
        id:1,
        link:"https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/dashboards?id=6448c3e9-faa0-4e30-82e6-bc6ee54e571d&theme=light&autoRefresh=true&maxDataAge=1800&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed",
        name:"Yashi",
       },
       {
        id:2,
        link:"https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/dashboards?id=6448c3d7-2ff0-4941-8218-17320be6ae76&theme=light&autoRefresh=true&maxDataAge=1800&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed",
        name:"Kanika",
       },
       {
        id:3,
        link:"https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/dashboards?id=6448c3ca-97c2-48d9-81d6-624c72f76440&theme=light&autoRefresh=true&maxDataAge=1800&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed",
        name:"Jyoti",
       },
       {
        id:4,
        link:"https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/dashboards?id=6447df98-b3e2-431f-8e94-4464f2cbb841&theme=light&autoRefresh=true&maxDataAge=1800&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed",
        name:"Ambika",
       },
       {
        id:5,
        link:"https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/dashboards?id=6447c4fd-faa0-4130-89ca-bc6ee5533f94&theme=light&autoRefresh=true&maxDataAge=1800&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed",
        name:"Heena",
       },
       {
        id:6,
        link:"https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/dashboards?id=ca46e216-16e7-4da1-a82a-1d2c7b6139eb&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed",
        name:"AASTHA",
       },
]
