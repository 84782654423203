import React,{useState,useEffect} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import RoomDetails from "./RoomDetails";
import "./RoomHome.css";
import { useSelector } from "react-redux";
export default function RoomHome(props) {
  let {open,handleClose,name,changeData,layoutDecision} = props;
  let [quantitys,setQuantity] = useState();
  console.log("change Data   =   ",changeData);
  let dataState = useSelector((state) => {
    return state.switchBoardsSave; 
  });   
  
  useEffect(()=>{
    const countAppliances=async(items)=>{
      var quantity = 0 
  
      console.log("inner ",items);
      for (var roomLevel in items) {
        var roomName = items[roomLevel]
        console.log("RoomName  ",roomName);
        var switchLevel = roomName.switchboards
        for (var switchh in switchLevel) {
          var switchboard = switchLevel[switchh]
          console.log("Switchboard     =  ",switchboard);
          var { fans, light, ac, socket, geyser, tv , other , ir} = switchboard.appliances;
          quantity = quantity + fans + light + ac + socket + geyser + tv + other + ir
        }
      }
      console.log(quantity);
      setQuantity(quantity)
     
    }
    countAppliances(dataState)
    console.log("final Data  = ",dataState);
  },[dataState])

  return (
    <div className="addRoom-d-c">
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          scrollbar: "auto",
          scrollbarWidth: "0",
          outline: "none",
        }}
      >
        
        <Fade in={open}>
          <Box
          className="popupSwitch"
            sx={{
              
              top: "10vh",
             
            }}
          >
            <div className="add-room-title-container">{name} Room Details</div>
            <div className="add-room-subtitle-conainer">
              {name} the rooms, and the switchboards in it & the appliances they
              control
            </div>
            <div className="roomDetail-appliaces">Appliances Left =    {layoutDecision.totalAppliances-quantitys}</div>
            <div className="room-name">
              <RoomDetails
                onClosee={handleClose}
                name={name}
                changeData={changeData}
                layoutDecision={layoutDecision}
                appliancesLeft={layoutDecision.totalAppliances-quantitys}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
          </div>
  );
}
