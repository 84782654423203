import React from 'react'
import jsPDF from 'jspdf'
import "./Pdf2.css"
import { useNavigate,useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import domToPdf from 'dom-to-pdf'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
export default function Pdf2() {
    const location = useLocation()
    const navigate = useNavigate();
    let [path,setPath] = useState();
    let [layout,setLayout] = useState();
    useEffect(()=>{
        const { from,path} = location.state
    const call =async(from)=>{
           await axios.post(`${serverUrl.u}/api/tele/getSubscription`,{
                generatedHex:from.generatedHex,
            }).then(function (res) {
                console.log(res.data.data);
                setLayout(res.data.data)
                setPath(path)
            }).catch(function (err) {
                console.log(err);
            })
    }
    call(from);   
      },[])
   const generatePdf = ()=>{
     var doc  = new jsPDF("p","px",[ 595.28,  841.89]);
     doc.html(document.querySelector("#content"),{
        callback: function(pdf){
            pdf.save(`${layout?.name}.pdf`);
        }
     }) 
   }
   const generatePdfDom = () => {
    const element = document.querySelector('#content');
    const options = {
      filename: `${layout?.name}.pdf`,
      scale :1.2
    };
    return domToPdf(element, options, () => {
      });
    } 

  return (
    <>
    <button className='btn btn-primary' onClick={generatePdfDom}>Generate Pdf Dom</button>
  <button className='btn btn-primary' style={{marginLeft:"5%"}} onClick={generatePdf}>Generate Pdf</button>
    <button className='btn btn-primary' style={{marginLeft:"5%"}} onClick={()=>navigate(`/${path}`)}>Back</button>
    <div id="content" style={{margin:"1%"}}>
        <div className='pdf-container' >
                <div className='top-container-pdf'>
                    <div className='client-name'>{`Client Name :-  ${layout?.name}  `} </div>
                    <div className='client-name'>{`TeleCaller Name :-  ${layout?.teleCallerId}`}</div>
                    <div className='client-name' style={{fontSize:"18px"}}>{`Payment Status :-${layout?.partialPayment?"Partial Payment":"Fully Paid"}`}</div>
                    { 
                        layout?.devicesAlgo?.roomData.map((e) => {
                            return (
                            <>
                            <div style={{fontSize:"20px",marginTop: "15px",}}>{e.roomName}</div>
                            
                            {
                            e.switchboards.map((ele,index) => {
                                return (<div key={index+2}>
                                <div style={{fontSize:"18px",height: "30px"}}>SwitchBoard Number :- {ele.switchboardNumber}</div>
                    
                                <div className='table-container-pdf'>
                                    
                                <table className='table-layout  table-bordered'>
                                    <thead className='table-layout-devices'>
                                        <tr key={index+4}>
                                            <th  colSpan="2">Devices</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-layout-devices-algo'>
                                        {ele.switchBoardConfig[1]!==0?
                                        <tr>
                                            <td>One Switch</td>
                                            <td >{ele.switchBoardConfig[1]}</td>
                                        </tr>:null
                            }
                            {ele.switchBoardConfig[2]!==0?
                                        <tr>
                                            <td>Two Switch</td>
                                            <td >{ele.switchBoardConfig[2]}</td>
                                        </tr>:null
                            }
                            {ele.switchBoardConfig[3]!==0?
                                        <tr>
                                            <td>Three Switch</td>
                                            <td>{ele.switchBoardConfig[3]}</td>
                                        </tr>:null
                            }
                            {ele.switchBoardConfig[4]!==0?
                                        <tr>
                                             {/* {totalCount++} */}
                                            <td>Four Plus One Switch</td>
                                            <td>{ele.switchBoardConfig[4]}</td>
                                        </tr>:null
                            }
                            {ele.switchBoardConfig[5]!==0?
                                        <tr>
                                             {/* {totalCount++} */}
                                            <td>Five Switch</td>
                                            <td>{ele.switchBoardConfig[5]}</td>
                                        </tr>:null
                            }
                            {ele.switchBoardConfig.f!==0?
                                        <tr>
                                            {/* {totalCount++} */}
                                            <td>Fan Dimmer</td>
                                            <td>{ele.switchBoardConfig.f}</td>
                                        </tr>:null
                            }
                            {(ele.switchBoardConfig.ir!==0 && ele.switchBoardConfig.ir!==undefined)?
                            
                                        <tr>
                                             {/* {totalCount++} */}
                                            <td>IR Device</td>
                                            <td>{ele.switchBoardConfig.ir}</td>
                                        </tr>:null
                            }
                                    </tbody>
                                    
                                    {/* {console.log(totalCount)} */}
                                </table>
        
                                <table className='table-layout  table-bordered'>
                                    <thead className='table-layout-devices'>
                                        <tr key={index+3}>
                                            <th colSpan="2">Appliances</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-layout-devices-algo'>
                                        {ele.appliances.fans!==0?
                                    <tr>
                                            <td>Fan</td>
                                            <td>{ele.appliances.fans}</td>
                                        </tr>:null
                            }
                             {ele.appliances.light!==0?
                                        <tr>
                                            <td>Light</td>
                                            <td>{ele.appliances.light}</td>
                                        </tr>:null
                            }
                             {ele.appliances.ac!==0?
                                        <tr>
                                            <td>A.C.</td>
                                            <td>{ele.appliances.ac}</td>
                                        </tr>:null
                            }
                             {ele.appliances.geyser!==0?
                                        <tr>
                                            <td>Geyser</td>
                                            <td>{ele.appliances.geyser}</td>
                                        </tr>:null
                            }
                             {ele.appliances.tv!==0?
                                        <tr>
                                            <td>T.V.</td>
                                            <td>{ele.appliances.tv}</td>
                                        </tr>:null
                            }
                             {ele.appliances.socket!==0?
                                        <tr>
                                            <td>Socket</td>
                                            <td>{ele.appliances.socket}</td>
                                        </tr>:null
                            }
                             {ele.appliances.ir!==0?
                                        <tr>
                                            <td>IR</td>
                                            <td>{ele.appliances.ir}</td>
                                        </tr>:null
                            }
                             {ele.appliances.other!==0?
                                        <tr>
                                            <td>Other</td>
                                            <td>{ele.appliances.other}</td>
                                        </tr>:null
                            }
                                    </tbody>
                                </table>
        
                            </div>
                            </div>)
                            })
                        }
                            </>
                        )})
                    }
                   
                </div>
            </div>

    </div>
    
    </>
  )
}
