import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { addGrevianceClient } from '../ApiCalls/AddGreviance';
import { Button } from '@mui/material';
import { smartMeterOrderTypes } from '../Function/data';

export default function RegiterGrivance({
  data,
  reload,
  setReload,
  notify,
  orderType,
}) {
  let [btn, setBtn] = useState('Grievance');
  let [grievancePop, setGrievancePop] = useState(false);
  let [deviceObject, setDeviceObject] = useState({ decision: 'no' });
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let handleSumit = async (event) => {
    event.preventDefault();
    // setBtn('Loading...');
    const datas = new FormData(event.currentTarget);

    let greivanceData = {
      hex: datas.get('hex'),
      date: datas.get('date'),
      comment: datas.get('comment'),
      priority: datas.get('priority'),
      problemType: datas.get('problemType'),
      sendTo: smartMeterOrderTypes.includes(orderType)
        ? 'Vikas'
        : datas.get('sendTo'),
    };

    console.log('[GreivanceData]', greivanceData);

    if (greivanceData.problemType === '') {
      notify('Select Problem Type');
      setBtn('Grievance');
      return;
    }
    let dateOfToday = smartMeterOrderTypes.includes(orderType)
      ? datas.get('date')
      : new Date();
    await addGrevianceClient(
      greivanceData.hex,
      deviceObject,
      greivanceData.comment,
      greivanceData.problemType,
      loginName,
      dateOfToday,
      notify,
      setReload,
      reload,
      setGrievancePop,
      data,
      greivanceData.priority,
      greivanceData.sendTo
    );
    setBtn('Grievance');
  };
  return (
    <>
      <button
        className='btn btn-primary'
        onClick={() => setGrievancePop(true)}
        style={{
          display: data.subscriptionStage === 'grivience' ? 'none' : '',
        }}
      >
        {btn}
      </button>

      {grievancePop === true ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            overflowX: 'scroll',
            zIndex: 4,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'whitesmoke',
              width: '25%',
              padding: '10px',
              borderRadius: '12px',
              position: 'relative',
              top: '100px',
              marginBottom: '30px',
              height: 'fit-content',
            }}
          >
            <div
              style={{
                width: '100%',
                position: 'absolute',
                top: 0,
                right: -20,
              }}
            >
              <span
                className='cross'
                onClick={() => setGrievancePop(false)}
                style={{
                  fontSize: '30px',
                  fontWeight: '500',
                }}
              >
                x
              </span>
            </div>
            <h4>Raise a Grievance</h4>
            <form
              onSubmit={handleSumit}
              style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div className='input-group mb-1'>
                <label>Generated Hex</label>
                <input
                  style={{ width: '100%' }}
                  type='text'
                  //   ref={grivance}
                  name='hex'
                  autoComplete='hex'
                  placeholder='Enter Hex Code here...'
                  defaultValue={data.generatedHex}
                  // disabled
                  readOnly
                  className='form-control'
                />
              </div>
              {smartMeterOrderTypes.includes(orderType) && (
                <div className='input-group mb-1'>
                  <label>Date for grievance</label>
                  <input
                    type='date'
                    style={{ width: '100%' }}
                    //    ref={grivanceDate}
                    name='date'
                    required
                    className='form-control'
                    autoComplete='current-date'
                  />
                </div>
              )}

              <div className='input-group mb-1'>
                <label>Priority</label>
                <select
                  style={{ width: '100%' }}
                  defaultValue={'false'}
                  name='priority'
                  autoComplete='current-priority'
                  className='form-control'

                  //   onChange={(e)=>setGrivancePriority(e.target.value)}
                >
                  <option value='false'>No</option>
                  <option value='true'>Yes</option>
                </select>
              </div>

              {/* <div className='input-group mb-1'>
              <label>Assign To</label>
                <select
                  style={{ width: '100%' }}
                  defaultValue={'false'}
                  name='sendTo'
                  autoComplete='current-sendTo'
                  className='form-control'

                  //   onChange={(e)=>setGrivancePriority(e.target.value)}
                >
                  <option value='Vikas'>Vikas</option>
                  <option value='Heena'>Heena</option>
                  <option value='Sidharth'>Sidharth</option>
                </select>
              </div> */}
              <div className='input-group mb-1'>
                <label>Problem Type</label>
                <select
                  style={{ width: '100%' }}
                  //   onChange={(e) => setGrivanceType(e.target.value)}
                  name='problemType'
                  autoComplete='current-problemType'
                  className='form-control'
                  //   defaultValue={GerivanceType}
                >
                  <option value={''}>Select</option>
                  <option value='WiFi'>WiFi</option>
                  <option value='Wiring'>Wiring</option>
                  <option value='Offline'>Offline</option>
                  <option value='Wrong'>Wrong</option>
                  <option value='No readings'>No readings</option>
                  <option value='No Output'>No Output</option>

                  <option value='Hanging'>Hanging</option>
                  <option value='Power'>Power</option>
                  <option value='Switch'>Switch</option>
                  <option value='Misc'>Misc</option>
                </select>
              </div>

              <div className='input-group mb-1'>
                <label>Comment</label>

                <textarea
                  required
                  name='comment'
                  autoComplete='comment'
                  placeholder='Enter Comment here...'
                  rows='3'
                  cols='50'
                ></textarea>
              </div>
              {/* <div className="input-group mb-2">
            <label>If you want to send devices select Yes</label>
            <select
              onChange={(e) =>
                setDeviceObject({ ...deviceObject, decision: e.target.value })
              }
              defaultValue="no"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
          {deviceObject.decision == "yes" ? (
            <div className="DeviceObjectDetails">
              <div className="DeviceObjectDetails-specific">
                <label>One Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, one: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Two Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, two: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Three Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, three: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>4 + 1 Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, four: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Five Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, five: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>IR</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, ir: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Fan</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, fan: e.target.value })
                  }
                />
              </div>
            </div>
          ) : null} */}
              <Button
                variant='contained'
                sx={{ w: 100 }}
                type='submit'
                style={{ pointerEvents: btn === 'Loading...' ? 'none' : '' }}
              >
                {btn}
              </Button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}
