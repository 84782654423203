import React,{useState} from 'react'
import { state } from '../Data'
import axios from 'axios';
import { pages, serverUrl } from '../ApiUrl';
import { useDispatch } from 'react-redux';
import { updateInstallationData } from '../Redux/installationActions';
import { updateAddressLayout } from '../Redux/Action/adderess&layout';
import { ci } from '../city/Cities';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SelectCityInput from '../city/SelectCityInput';

export default function CityUpdate({e,SetWebsiteReload,websiteReload,page}) {
    let [displayDropDown,setDisplayDropDown] = useState(false) 
    let [confirmationDis, setConfirmationDis] = useState(false)   
    let [city,setCity] = useState(e.city)
    let dispatch = useDispatch(e.city)
      const cityChange = async()=>{
        await axios.post(`${serverUrl.u}/api/tele/updateCity`,{
          generatedHex:e.generatedHex,
           city:city.toUpperCase()
        }).then(function (response) {
        let detail=response;
        console.log(detail);
        setDisplayDropDown(false)
        if(page===pages.installation){
          dispatch(updateInstallationData(response.data.subscirption))
        }else if(page===pages.addressLayout){
            dispatch(updateAddressLayout(response.data.subscirption))
        }
        else{
          SetWebsiteReload(!websiteReload);
        }
      }).catch(function (error) {
        console.log(error);
      })}
     
  return (
    <>
    {confirmationDis === true ?
     <div className='confirmation-popup'>
       <div className='confirmation-popup-heading'>Confirmation to change {e.name}</div>
       <div className='confirmation-popup-Subheading'>Are your sure you want to Ready</div>
       <button className='btn btn-danger' onClick={() => { setConfirmationDis(false) }}>No</button>
       <button className='btn btn-primary ml-5' onClick={() => {
       
         setConfirmationDis(false)
         setDisplayDropDown(true)
       }}>Yes</button>
     </div> : null
   }
 
         {
             displayDropDown === false ? <td onClick={() => setConfirmationDis(true)} label="Assigned Electritian *">{e.city}</td> : 
             <div class="input-group mb-3" >
                 {/* <select class="custom-select" id="inputGroupSelect01"
                     onChange={(ele) => handleCity(ele, e.generatedHex)}
                     defaultValue="empty"
                 >
                     <option value="empty">choose city</option>
                     {ci.map((option) => (
                         <option value={option.value}>{option.value}</option>
                     ))}
                 </select> */}
                   {/* <Autocomplete
                      id="country-select-demo"
                      size={"small"}
                      sx={{ width: "100%" }}
                      options={ci}
                      onChange={(ele)=>handleCity(ele.target.innerText,e.generatedHex)}
                      // autoHighlight
                      getOptionLabel={(option) => option.value}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                        
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'city',
                          }}
                        />
                      )}
                    /> */}
                    <SelectCityInput value={city} setValue={setCity}/>
                    <button className='btn btn-primary' onClick={()=>cityChange()}>Done</button>
             </div>
         }
     
     </>
  )
}
