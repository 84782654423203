import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import '../AssignElectrician.css'
import { updateInstallationData } from '../Redux/installationActions';
import { useDispatch } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import Button from '@mui/material/Button';
import { subHistory } from '../Function/SubscriptionHistory';
import { Stack } from '@mui/material';

export default function AssignElectrician({e,SetWebsiteReload,websiteReload,userName,notify}) {
    let userRole = useSelector((state) => state.sar.data?.user?.roles)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    
    let [displayDropDown,setDisplayDropDown] = useState(false) 
    let [confirmationDis, setConfirmationDis] = useState(false) 
    let [selectedValue,setSelectedValue] = useState([])
  
    let dispatch = useDispatch()  
      const assign = async()=>{
        let electrician = []
        for(let i of selectedValue){
             electrician.push({"name":i.first_name,Id:i.employeeId})
        }
        await axios.post(`${serverUrl.u}/api/dispatch/assignElectricianOnInstallationPage`,{
          generatedHex:e.generatedHex,
          electrician:electrician 
        }).then(function (response) {
        let detail=response;
      
        if(response.data.success===true){
          setDisplayDropDown(false)
          notify(response.data.message)
          dispatch(updateInstallationData(response.data.sub))
          subHistory("Electrician Assign From Installation Page",e,"Electrician Assign",JSON.stringify(electrician))
        }else{
          notify("Error Occured")
        }
        
      }).catch(function (error) {
        console.log(error);
        notify(error.response.data.message)
      })
    }
     
    return (
      <>
       {/* {confirmationDis === true ?
        <div className='confirmation-popup'>
          <div className='confirmation-popup-heading'>Confirmation to change {e.name}</div>
          <div className='confirmation-popup-Subheading'>Are your sure you want to Ready</div>
          <button className='btn btn-danger wid' onClick={() => { setConfirmationDis(false) }}>No</button>
          <button className='btn btn-primary wid ml-5' onClick={() => {
          
            setConfirmationDis(false)
            setDisplayDropDown(true)
          }}>Yes</button>
        </div> : null
      } */}
    
            {
                displayDropDown === false ? <td onClick={() => {if(!userRole.includes("Telecaller") && !userRole.includes("Sales"))setDisplayDropDown(true)}} label="Assigned Electritian *">
                  {Array.isArray(e.AssignedElectritian)?
                  
                  <ul>
                       {
                        e.AssignedElectritian && e.AssignedElectritian.map((name)=>{
                          console.log(name,"j");
                          return(
                          <li>{(typeof name === 'object' && name !== null)?name.electricianName:name}</li>
                        )})
                       }
                  </ul>: e.AssignedElectritian}</td> : 
                <div className="input-group mb-3 assign"
                 
                >
                    
                    <Multiselect
                    displayValue="first_name"
                    onRemove={setSelectedValue}
                    onSelect={setSelectedValue}
                    options={userName}
                    style={{
                        width: "70px !important",
                        multiselectContainer: {
                            marginTop: "3px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />
                <Stack direction="row" gap={2}>
                <Button onClick={()=>assign()} sx={{mt:2}} variant="contained">Done</Button>
                <Button onClick={()=>setDisplayDropDown(false)} sx={{mt:2}} variant="contained">Close</Button>
                </Stack>
                </div>
            }
        
        </>
    )
}
