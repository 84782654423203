import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFirstandLastDate } from "../../Common/applianceDifference";
import { notifyFailed } from "../../Common/Notification";
import { serverUrl } from "../../ApiUrl";
import { kmReportDaily } from "../../MacId/Api";
import Spinner from "../../Function/Spinner";

export default function ElectricianKms() {
  let employeeId = useSelector((state) => state.sar.data.user.employeeId);
  let startDate = useRef(new Date());
  let [Loading, setLoading] = useState(false);

  let [data, setData] = useState([]);

  const getIncentive = () => {
    setLoading(true);
    let gg = new Date(startDate.current.value);
    let data = getFirstandLastDate(startDate.current.value);
    setData([]);
    let date = {
      startDate: gg,
      endDate: data.lastDay,
    };
    kmReportDaily(date).then((res) => {
      let filterData = res.filter((e) => e.id === employeeId);
      console.log(filterData, "gggg");
      setData(filterData);
      setLoading(false);
    });
  };
  useEffect(() => {
    getIncentive();
  }, []);
  return (
    <div style={{ padding: "2% 4%", marginTop: "4vh" }}>
      <ToastContainer />
      {Loading && <Spinner />}
      <div className="row align-items-end">
        <div className="col-md-2 col-sm-5">
          <label className="create-label1">Month</label>
          <div class="input-group ">
            <input
              type="month"
              ref={startDate}
              defaultValue={moment(new Date()).format("YYYY-MM")}
              style={{ width: "100%" }}
              onChange={() => {
                getIncentive();
              }}
            />
          </div>
        </div>
      </div>

      <div className="table-container1" style={{ marginTop: "20px" }}>
        <table className="table table-sm table-bordered ">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th scope="col">Date</th>
              <th>Total Kms</th>
            </tr>
          </thead>
          <tbody>
            <>
              {data.length !== 0
                ? data.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{e.Date}</td>
                        <td>{e.totalKm.toFixed(2)}Kms</td>
                      </tr>
                    );
                  })
                : null}
              <tr>
                <td>{""}</td>
                <td>Total</td>
                <td>{data.reduce((p,c)=>{
                    return p+=Number(c.totalKm.toFixed(2))
                },0)} Kms</td>
              </tr>
            </>
          </tbody>
        </table>
      </div>
      <div style={{ marginBottom: "60px", height: "60px" }}></div>
    </div>
  );
}
