import { WhatsApp } from '@mui/icons-material'
import { Box, Button, Modal, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ReactWhatsapp from 'react-whatsapp'
import { handleCash, installationDoneByElectrician, razorpayLink, removeElectricianFromSite } from './UpgradeDownGrade/ApplincesDifferenceApi'
import { electicianLocationLog, typeOfLocaionLogs } from './api'
import { notifyFailed } from '../Common/Notification'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
  };

export default function UpSellDone({sub,setReload,reload}) {
    const [text,setText] = useState('')
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)

    const [cashTransaction, setCashTransaction] = useState(false)
    const [whatappNumber,setWhatappNumber] = useState(sub.phone)
    const [paymentLink,setPaymentLink] =useState("")
    const [paymentLinkBtn,setPaymentLinkBtn] = useState('Payment Link')
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
        
      };
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let appliance =0 
    let security=0
    let installation=0
    let newDate = new Date()
    let total=0
    let planFrequency="monthly"
    let arrayInvoice=[] 
  return (
    <>
      <Button 
        onClick={async()=>{
            if(sub.partialPayment){
                let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.leave,sub)
                if(!res.success){
                  notifyFailed("First Click on Day Start Button")
                  return
                }
                setOpen(true)
            }else{
                let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.leave,sub)
                if(!res.success){
                  notifyFailed("First Click on Day Start Button")
                  return
                }
               await installationDoneByElectrician(sub,true,true)
               await removeElectricianFromSite(sub)
               window.location.reload();
            }
            }}
        variant="contained"
        color='primary'
        size='small'
      >Up-Sell Done</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
          
         

        <Stack direction="row" spacing={2} mt={2}>
                            
                                    <>
                                        <Button
                                            variant="contained"
                                            color='primary'
                                            disabled={paymentLinkBtn === "Processing..." ? true : false}
                                            onClick={() => {
                                                razorpayLink(sub, setText, setWhatappNumber, setPaymentLink, appliance, security, installation, total, newDate, setPaymentLinkBtn, planFrequency, arrayInvoice, loginName)
                                                installationDoneByElectrician(sub,false,true)
                                                setPaymentLink("")
                                                setText('')
                                                setWhatappNumber('')
                                            }}>{paymentLinkBtn}</Button>
                                        
                                            
                                                <Button
                                                    variant="contained"
                                                    color='primary'
                                                    onClick={() => {
                                                        setCashTransaction(true)

                                                    }}>{"Payment in Cash"}</Button>
                                            
                                    

                                    </>
                                
                            
                            {
                                paymentLink !== "" && (
                                    <>
                                        <ReactWhatsapp
                                            number={sub.phone}
                                            message={text}
                                            style={{
                                                backgroundColor: "#25D366",
                                                border: "none",
                                                borderRadius: "12px",
                                                padding: "5px",

                                            }}
                                         
                                        >
                                            <WhatsApp
                                                sx={{
                                                    color: "white",
                                                    verticalAlign: "middle",
                                                    fontSize: "xx-large",
                                                }}
                                                onClick={()=>{
                                                    handleClose()
                                                    setReload(!reload)

                                                }}
                                            />
                                        </ReactWhatsapp>


                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={async () => {
                                                navigator['clipboard'].writeText(paymentLink)
                                                handleClose()
                                                setReload(!reload)
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </>
                                )
                            }
                        </Stack>
                    
                    {
                        cashTransaction && (
                            <Stack>
                                <form onSubmit={(e) => handleCash(e, sub)}>
                                    <label className="specific-label">Select Payment Type</label>
                                    <div class="input-group mb-3">
                                        <select class="custom-select height-select"
                                            name="paymentMode"
                                            autoComplete='current-paymentMode'
                                            defaultValue={"cash"}
                                        >

                                            <option value={"cash"}>Cash</option>
                                            <option value={"cheque"}>Cheque</option>
                                            <option value={"Bank Transfer"}>Bank Transfer</option>

                                        </select>
                                    </div>
                                    <label className="specific-label">Transaction Id</label>
                                    <div className="input-group">
                                        <input type="text"
                                            name="transactionId"
                                            autoComplete='current-transactionId'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                        />
                                    </div>
                                    <label className="specific-label">Transaction Amount</label>
                                    <div className="input-group">
                                        <input type="number"
                                            name="transactionAmount"
                                            autoComplete='current-transactionAmount'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                        />
                                    </div>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </Stack>
                        )
                    }
        </Box>
      </Modal>
    </>
  )
}
