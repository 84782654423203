import axios from 'axios'
import React from 'react'

export default function PartialTest() {
    const partialPaid = ()=>{
        axios.post("https://test.alistetechnologies.com:8443/api/rzp/paymentLink/updates",{
            "account_id": "acc_IexdIlVpqofgvC",
            "contains": [
              "payment_link",
              "order",
              "payment"
            ],
            "created_at": 1693216364,
            "entity": "event",
            "event": "payment_link.paid",
            "payload": {
              "order": {
                "entity": {
                  "amount": 100,
                  "amount_due": 100,
                  "amount_paid": 0,
                  "attempts": 1,
                  "created_at": 1693216757,
                  "currency": "INR",
                  "entity": "order",
                  "id": "order_MVRnld1rEExo3p",
                  "notes": {
                    "generatedHex": "fcfe7e",
                    "type": "0"
                  },
                  "offer_id": null,
                  "receipt": "3f2dca",
                  "status": "attempted"
                }
              },
              "payment": {
                "entity": {
                  "acquirer_data": {},
                  "amount": 100,
                  "amount_refunded": 0,
                  "amount_transferred": 0,
                  "bank": null,
                  "base_amount": 100,
                  "captured": true,
                  "card": null,
                  "card_id": null,
                  "contact": "+919999999999",
                  "created_at": 1693216773,
                  "currency": "INR",
                  "description": "#MVRnc8r1fSjPVl",
                  "email": "void@razorpay.com",
                  "entity": "payment",
                  "error_code": null,
                  "error_description": null,
                  "error_reason": null,
                  "error_source": null,
                  "error_step": null,
                  "fee": 2,
                  "fee_bearer": "platform",
                  "id": "pay_MVRo2hJeZIy2Qo",
                  "international": false,
                  "invoice_id": null,
                  "method": "upi",
                  "notes": {
                    "generatedHex": "d42d48",
                    "type": "0"
                  },
                  "order_id": "order_MVRnld1rEExo3p",
                  "refund_status": null,
                  "status": "captured",
                  "tax": 0,
                  "upi": {},
                  "pa": "digvijayrana123456@okaxis",
                  "wallet": null
                }
              },
              "payment_link": {
                "entity": {
                  "accept_partial": false,
                  "amount": 100,
                  "amount_paid": 100,
                  "callback_method": "get",
                  "callback_url": "https://subscription.alistetechnologies.com/confirm/3f2dca",
                  "cancelled_at": 0,
                  "created_at": 1693216749,
                  "currency": "INR",
                  "customer": {},
                  "description": "Token for booking your smart home with Aliste :)",
                  "expire_by": 0,
                  "expired_at": 0,
                  "first_min_partial_amount": 0,
                  "id": "plink_MVRnc8r1fSjPVl",
                  "notes": {
                    "generatedHex": "fcfe7e",
                    "type": "0"
                  },
                  "notify": {},
                  "order_id": "order_MVRnld1rEExo3p",
                  "reference_id": "3f2dca",
                  "reminder_enable": false,
                  "reminders": {},
                  "short_url": "https://rzp.io/i/KWLMNjLAi",
                  "status": "paid",
                  "updated_at": 1693216774,
                  "upi_link": false,
                  "user_id": ""
                }
              }
            }
        }).then(function (res) {
            console.log(res);
        }).catch(function (err) {
            console.log(err);
        })
    }
  return (
    <button onClick={()=>partialPaid()}>Partial Upgrade/DownGrade Amount</button>
  )
}
