import React, { useEffect, useState } from "react";
import "./ConvertedLead.css";
import "./DispatchData.css";
import moment from "moment/moment";
import './Installation.css'
import Qutoes from "./Qutoes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { refundRecords,refundPendingRecords, refundCompleteRecords } from "./Redux/action";
import { useDispatch } from "react-redux";
import RefundReference from "./SmallComponent/RefundReference";
import RefundDeleteBtn from "./Refund/RefundDeleteBtn";
import RefundDestination from "./Refund/RefundDestination";
import axios from "axios";
import { serverUrl } from "./ApiUrl";

export default function RefundRecord() {
  let [displayData,setDisplayData] = useState([])
  let [tab,setTab] = useState("All_Record");
  let [page,setPage] = useState(1)
  let [pendingPage,setPendingPage] = useState(1)
  let [completePage,setCompletePage] = useState(1) 
  let [searchRefundData,setSearchRefundData] = useState([])
  let [totalPage,setTotalPage] = useState(1)
  let [pendingTotalPage,setPendingTotalPage] = useState(1)
  let [completeTotalPage,setCompleteTotalPage] = useState(1)
  let [q, setq] = useState("");
  let [reload,SetReload] = useState(false);
  let userRole =useSelector((state)=>state.sar.data.user.roles)
  let RefundDetail = useSelector((state)=>state.refudRecord)
  let refundPendingRecord = useSelector((state)=>state.refundPendingRecord)
  let refundCompleteRecord = useSelector((state)=>state.refundCompleteRecord)
  const [searchParam] = useState(["name"]);
  const notify = (text) => toast(text);
  let dispatch = useDispatch();
  useEffect(()=>{
    refundRecords(page,notify,dispatch);
    refundPendingRecords(pendingPage,notify,dispatch)
    refundCompleteRecords(completePage,notify,dispatch)
  },[reload])

  useEffect(()=>{
       setTotalPage(RefundDetail.totalPage)
       setPendingTotalPage(refundPendingRecord.totalPage)
       setCompleteTotalPage(refundCompleteRecord.totalPage)
  },[RefundDetail,refundPendingRecord,refundCompleteRecord])
  
  const searchRefund = (event)=>{
   
  axios.post(`${serverUrl.u}/app/fnd/searchRefundRecord`,{
    value:event.target.value
  }).then(function (res) {
    console.log(res);
    if(event.target.value===""){
      setSearchRefundData([])
    }else{
      setSearchRefundData(res.data.refundRecord)
    }
  }).catch(function (err) {
    console.log(err);
  })
  }
  return (
    <div style={{padding:"0 4%",marginTop:"4vh"}}>
      
     
      <div  className='input-field' style={{display:"flex"}}>
      <div >
        
      </div>
        <input style={{width:"100% !important"}} type='text' placeholder='search by name...' onChange={searchRefund}/>
      </div>
      

      {searchRefundData.length!==0 && <div>Search Record</div>}
      {
        searchRefundData.length!==0 && <div className='table-container1' style={{marginTop:"10px",maxHeight:"300px"}}>
        <table className="table table-sm table-bordered ">
          <thead>
            <tr>
            <th>Sr. No.</th>
              <th scope="col">Hex Code<span style={{ color: "red" }}>*</span></th>
             <th>TeleCaller</th>
              <th>Refund Amount</th>
              <th>Refund Status</th>
              <th style={{width:"180px"}}>Reference Id</th>
              <th scope="col">Client Name</th>
              <th scope="col">Refund By</th>
              <th>Refund Method</th>
              <th scope="col" style={{width:"120px"}}>Refund Created Date</th>
              <th scope="col" style={{width:"180px"}}>Refund Payment Upi Id/Bank</th>
              <th>Refund Reson</th>
              <th style={{width:"200px"}}>Action</th>
              <th style={{width:"200px"}}>Upi/Bank Transfer</th>
              <th style={{width:"150px"}}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {
               searchRefundData.map((e, i) => {
                  return (
                    <tr key={i} style={{marginBottom:5}}>
                      <td>{(i+1)+((page-1)*50)}</td>
                      <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.generatedHex}</td>
                      <td>{e.telecallerId}</td>
                      <td>{e.refundAmount}</td>
                      <td style={{color:e.status==="pending"?"red":""}}>{e.status}</td>
                      <td>{e.referenceId}</td>
                      <td label="refunndTo"><div className="hover-wide">{e.refunndTo}</div></td>
                      <td label="City">{e.refundBy}</td>
                      <td label="Phone">{e.refundMethod}</td>
                      <td label="Date">{moment(e.refundDate).format("DD-MM-YYYY")}</td>
                      <td>{e.refundUpiId!==null?(e.refundUpiId):(Object.keys(e.bankTransferDetails).map((key, index) => {
                                        return (
                                                <li>
                                                 {key}: {e.bankTransferDetails[key]}
                                               </li>
                                           )
                                          }))}</td>
                      <td label="Date">{e.reasonOfRefund}</td>
                      <td><RefundReference e={e} notify={notify} SetReload={SetReload} reload={reload}/></td>
                      <td><RefundDestination refundRecords={e} notify={notify} SetReload={SetReload} reload={reload}/></td>
                      <td><RefundDeleteBtn data={e} notify={notify}  SetReload={SetReload} reload={reload}/></td>
                    </tr>
                  );
                })
              }
          </tbody>
        </table>
        </div>
      }

        <ToastContainer />
        <div style={{marginBottom: "20px"}}>
            <button className={tab==="All_Record"?"btn btn-primary mt-2":'btn btn-light mt-2'} onClick={()=>{setTab("All_Record")
                                                                                                         refundRecords(page,notify,dispatch)}}>All Record</button>
            
            <button className={tab==="Pending_Record"?"btn btn-primary ml-2 mt-2":'btn btn-light ml-2 mt-2'} onClick={()=>{setTab("Pending_Record")
                                                                                                 refundPendingRecords(pendingPage,notify,dispatch)}}>Pending Record</button>

<button className={tab==="Complete_Record"?"btn btn-primary ml-2 mt-2":'btn btn-light ml-2 mt-2'} onClick={()=>{setTab("Complete_Record")
                                                                                                 refundCompleteRecords(completePage,notify,dispatch)}}>Complete Record</button>
        </div>
        { tab==="All_Record"?
        <>
        <nav aria-label="..." className='pagination-container'>
      <ul class="pagination">
        {
          [...Array(totalPage)].map((e,i)=>{
              return(
                
                <li className={page===i+1?"page-item active":"page-item"} onClick={()=>{setPage(i+1)
                  refundRecords(i+1,notify,dispatch)}}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
        </nav>

      <div className='table-container1' style={{marginTop:0}}>
      <table className="table table-sm table-bordered ">
        <thead>
          <tr>
          <th>Sr. No.</th>
            <th scope="col">Hex Code<span style={{ color: "red" }}>*</span></th>
           <th>TeleCaller</th>
            <th>Refund Amount</th>
            <th>Refund Status</th>
            <th style={{width:"180px"}}>Reference Id</th>
            <th scope="col">Client Name</th>
            <th scope="col">Refund By</th>
            <th>Refund Method</th>
            <th scope="col" style={{width:"120px"}}>Refund Created Date</th>
            <th scope="col" style={{width:"180px"}}>Refund Payment Upi Id/Bank</th>
            <th>Refund Reson</th>
            <th style={{width:"200px"}}>Action</th>
            <th style={{width:"200px"}}>Upi/Bank Transfer</th>
            <th style={{width:"150px"}}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {RefundDetail.hasOwnProperty('refundRecord')
            ? RefundDetail.refundRecord.map((e, i) => {
                return (
                  <tr key={i} style={{marginBottom:5}}>
                    <td>{(i+1)+((page-1)*50)}</td>
                    <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.generatedHex}</td>
                    <td>{e.telecallerId}</td>
                    <td>{e.refundAmount}</td>
                    <td style={{color:e.status==="pending"?"red":""}}>{e.status}</td>
                    <td>{e.referenceId}</td>
                    <td label="refunndTo"><div className="hover-wide">{e.refunndTo}</div></td>
                    <td label="City">{e.refundBy}</td>
                    <td label="Phone">{e.refundMethod}</td>
                    <td label="Date">{moment(e.refundDate).format("DD-MM-YYYY")}</td>
                    <td>{e.refundUpiId!==null?(e.refundUpiId):(Object.keys(e.bankTransferDetails).map((key, index) => {
                                      return (
                                              <li>
                                               {key}: {e.bankTransferDetails[key]}
                                             </li>
                                         )
                                        }))}</td>
                    <td label="Date">{e.reasonOfRefund}</td>
                    <td><RefundReference e={e} notify={notify} SetReload={SetReload} reload={reload}/></td>
                    <td><RefundDestination refundRecords={e} notify={notify} SetReload={SetReload} reload={reload}/></td>
                    <td><RefundDeleteBtn data={e} notify={notify}  SetReload={SetReload} reload={reload}/></td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
      </>:(tab==="Pending_Record"?
        <>
        <nav aria-label="..." className='pagination-container'>
         <ul class="pagination">
           {
             [...Array(pendingTotalPage)].map((e,i)=>{
                 return(
                   
                   <li className={pendingPage===i+1?"page-item active":"page-item"} onClick={()=>{setPendingPage(i+1)
                                                                    refundPendingRecords(i+1,notify,dispatch)}}>
                   <a className="page-link" href="#" >{i+1} </a>
                 </li>
                 )
             })
           }
         </ul>
        </nav>
   
        <div className='table-container1' style={{marginTop:0}}>
         <table className="table table-sm table-bordered ">
           <thead>
             <tr>
             <th>Sr. No. Pending</th>
               <th scope="col">Hex Code<span style={{ color: "red" }}>*</span></th>
               <th>TeleCaller</th>
               <th>Refund Amount</th>
               <th>Refund Status</th>
               <th style={{width:"180px"}}>Reference Id</th>
               <th scope="col">Client Name</th>
               <th scope="col">Refund By</th>
               <th>Refund Method</th>
               <th scope="col" style={{width:"120px"}}>Refund Created Date</th>
               <th scope="col" style={{width:"180px"}}>Refund Payment Upi Id/Bank</th>
               <th>Refund Reson</th>
               <th style={{width:"200px"}}>Action</th>
             </tr>
           </thead>
           <tbody>
             {refundPendingRecord.hasOwnProperty('refundPandingRecord')
               ? refundPendingRecord.refundPandingRecord.map((e, i) => {
                   return (
                     <tr key={i} style={{marginBottom:5}}>
                       <td>{(i+1)+((page-1)*50)}</td>
                       <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.generatedHex}</td>
                       <td>{e.telecallerId}</td>
                       <td>{e.refundAmount}</td>
                       <td style={{color:e.status==="pending"?"red":""}}>{e.status}</td>
                       <td>{e.referenceId}</td>
                       <td label="refunndTo"><div className="hover-wide">{e.refunndTo}</div></td>
                       <td label="City">{e.refundBy}</td>
                       <td label="Phone">{e.refundMethod}</td>
                       <td label="Date">{moment(e.refundDate).format("DD-MM-YYYY")}</td>
                       <td>{e.refundUpiId!==null?(e.refundUpiId):(Object.keys(e.bankTransferDetails).map((key, index) => {
                                         return (
                                                 <li>
                                                  {key}: {e.bankTransferDetails[key]}
                                                </li>
                                            )
                                           }))}</td>
                       <td label="Date">{e.reasonOfRefund}</td>
                       <td><RefundReference e={e} notify={notify} SetReload={SetReload} reload={reload}/></td>
                     </tr>
                   );
                 })
               : null}
           </tbody>
         </table>
         </div>
         </>:
         <>
         <nav aria-label="..." className='pagination-container'>
          <ul class="pagination">
            {
              [...Array(completeTotalPage)].map((e,i)=>{
                  return(
                    
                    <li className={completePage===i+1?"page-item active":"page-item"} onClick={()=>{setCompletePage(i+1)
                                                                     refundCompleteRecord(i+1,notify,dispatch)}}>
                    <a className="page-link" href="#" >{i+1} </a>
                  </li>
                  )
              })
            }
          </ul>
         </nav>
    
         <div className='table-container1' style={{marginTop:0}}>
          <table className="table table-sm table-bordered ">
            <thead>
              <tr>
              <th>Sr. No. Complete</th>
                <th scope="col">Hex Code<span style={{ color: "red" }}>*</span></th>
                <th>TeleCaller</th>
                <th>Refund Amount</th>
                <th>Refund Status</th>
                <th style={{width:"180px"}}>Reference Id</th>
                <th scope="col">Client Name</th>
                <th scope="col">Refund By</th>
                <th>Refund Method</th>
                <th scope="col" style={{width:"120px"}}>Refund Created Date</th>
                <th scope="col" style={{width:"180px"}}>Refund Payment Upi Id/Bank</th>
                <th>Refund Reson</th>
                {/* <th style={{width:"200px"}}>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {refundCompleteRecord.hasOwnProperty('refundCompleteRecord')
                ? refundCompleteRecord.refundCompleteRecord.map((e, i) => {
                    return (
                      <tr key={i} style={{marginBottom:5}}>
                        <td>{(i+1)+((page-1)*50)}</td>
                        <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.generatedHex}</td>
                        <td>{e.telecallerId}</td>
                        <td>{e.refundAmount}</td>
                        <td style={{color:e.status==="pending"?"red":""}}>{e.status}</td>
                        <td>{e.referenceId}</td>
                        <td label="refunndTo"><div className="hover-wide">{e.refunndTo}</div></td>
                        <td label="City">{e.refundBy}</td>
                        <td label="Phone">{e.refundMethod}</td>
                        <td label="Date">{moment(e.refundDate).format("DD-MM-YYYY")}</td>
                        <td>{e.refundUpiId!==null?(e.refundUpiId):(Object.keys(e.bankTransferDetails).map((key, index) => {
                                          return (
                                                  <li>
                                                   {key}: {e.bankTransferDetails[key]}
                                                 </li>
                                             )
                                            }))}</td>
                        <td label="Date">{e.reasonOfRefund}</td>
                        {/* <td><RefundReference e={e} notify={notify} SetReload={SetReload} reload={reload}/></td> */}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          </div>
          </>
      )
      
      }
    <Qutoes/>
    </div>
  );
}
