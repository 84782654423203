import React from 'react'
import ReactLoading from 'react-loading';

export default function Spinner() {
  return (
    <div style={{height:"100vh",width:"100vw",backdropFilter: "blur(2px)",position:"fixed",top:0,left:0}}>
    <div style={{position:"fixed",top:"50%",left:"50%"}}>
                  <ReactLoading type={"spin"} color={"blue"} height={60} width={60} />
                  </div>
    </div>
  )
}
