import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Qutoes from "../Qutoes";
import { serverUrl } from '../ApiUrl'
import { ElectricianIncentiveZone, PaymentModes, planDuration } from '../Data'
import { useSelector } from "react-redux";
import { B2BClientRegister, registerHouseWithOutSubscription } from "./Api";
import { recursiveAmountCalculate, recursiveAmountCalculateCustom } from "../Common/applianceDifference";
import { notifyFailed } from "../Common/Notification";

export default function BusinessOfflineRegistration() {
  let userName = useSelector(state => state.salesUser)

  let teleCallers = useSelector((state) => state.sar.data.user?.first_name)
  let [qutoBtnText, setQutoBtnText] = useState("Submit")
  let [propertyIds, setPropertyIds] = useState([])
  let [companys,setCompaneys] = useState([])
  let [companyDetail, setCompanyDetail] = useState([])
  let [selectedCompany, setSelectedCompany] = useState({})
  let [selectedProperties, setSelectedProperties] = useState({})
  let [costs,setCosts] = useState({
    costperAppliance:1,
    totalAppliance:1,
    costperApplianceSequirty:100,
    security:100,
    costperApplianceInstallation:1000,
    installation:1000
  })



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
   if(data.get('companyID')===""){
    notifyFailed("Select Company")
    return
   }
    let userData = {
      masters:data.get('masters'),
      houseName:data.get('houseName'),
      companyCode:companyDetail.companyCode,
      companyName:companyDetail.companyName,
      phone:companyDetail.phone,
      email:companyDetail.email,
      propertyName:data.get('propertyName'),
      city:data.get('city'),
      propertyAddress:data.get('propertyAddress'),
      billing_address:data.get('billing_address'),
      gstName:data.get('gstName'),
      gstNumber:data.get('gstNumber'),
      planFrequency:data.get('planFrequency'),
      planCode:data.get('planCode'),
      totalAppliances:Number(data.get('totalAppliances')),
      currentSecurity:Number(data.get('currentSecurity')),
      currentInstallation:Number(data.get('currentInstallation')),
      zone:data.get('zone'),
      teleCallerId:data.get('teleCallerId')
    }
    if(userData.planFrequency===""){
      notifyFailed("Select Plan Duration")
      return
    }
    if(userData.zone===""){
      notifyFailed("Select Zone")
      return
    }
   let fee = await recursiveAmountCalculateCustom(costs.costperAppliance,userData.planFrequency,userData.totalAppliances)
   let total = Number(((fee + userData.currentInstallation)*1.18 + userData.currentSecurity).toFixed(2))
    
     userData={...userData,"currentRecursive":Number(fee),"currentTotal":total}
     console.log(userData,"userData");
     setQutoBtnText("Processing...")
     registerHouseWithOutSubscription(userData,setQutoBtnText)
  };

  



  useEffect(() => {
    axios.get(`${serverUrl.u}/api/cmp/getCompany`).then(function (res) {
      setCompaneys(res.data.company)
    }).catch(function (err) {
      console.log(err);
    })
  }, [])

  

  const getAllProperties = async(code) => {

    if (code == "Select Company") return
    let obj = JSON.parse(code)
    let properties = []
    setSelectedCompany(obj)
   await axios.post(`${serverUrl.u}/api/cmp/getProperty`, {
      companyCode: obj.companyCode
    }).then(function (res) {
      console.log(res);
      console.log(res.data.property);
      if (res.status === 200) {
        setPropertyIds(res.data.property)
        properties = res.data.property
      }
    }).catch(function (err) {
      console.log(err);
    })
    console.log(properties[0], "pro");
    let data = {
      "companyName": obj.companyName,
      "companyCode": obj.companyCode,
      "shippingAddress": "Housr Service Private Limited",
      "gstName": obj.companyName,
      "gstNumber": obj.gst,
      "email": obj.email,
      "phone": obj.phone,
      "planCode": properties[0].planCode
    }
    setCompanyDetail(data)
    console.log(obj, "obj");
  }

  const handleSecurityCostChange = (cost) => {
      let newSequirty = cost*costs.totalAppliance
      setCosts({...costs,security:newSequirty,costperApplianceSequirty:cost})
  }
  const handleInstallationCostChange = (cost) => {
    let newInstallation = cost*costs.totalAppliance
    setCosts({...costs,installation:newInstallation,costperApplianceInstallation:cost})
  }
  const handleApplianceChange = (app) => {
    let newSequirty = costs.costperApplianceSequirty*app
    let newInstallation = costs.costperApplianceInstallation*app
    setCosts({...costs,totalAppliance:app,security:newSequirty,installation:newInstallation})
  }

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  return (

    <div className="container-fluid">

      <Box component="form" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">House Owner<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="masters"
                  name="masters"
                  autoComplete="current-masters"
                  required
                  placeholder="Enter phone number with +91"
                  aria-label="masters"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">House Name<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="houseName"
                  name="houseName"
                  autoComplete="current-houseName"
                  required
                  placeholder="House Name"
                  aria-label="houseName"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
        </div>
        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Company Name<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <select className="custom-select height-select"
                  required id="inputGroupSelect01"
                  name="companyID"
                  label="companyID"
                  autoComplete="current-companyID"
                  onChange={(ele) => getAllProperties(ele.target.value)}
                  >
                  <option value={""}>Select Company</option>
                  {companys.map((option) => (
                    <option value={JSON.stringify(option)} >{option.companyName}</option>
                  ))}

                </select>
              </div>
            </div>
            <div className="col-3">
              <label className="create-label1">Property Name<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="propertyName"
                  name="propertyName"
                  autoComplete="current-propertyName"
                  required
                  placeholder="Property Name"
                  aria-label="propertyName"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
            <div className="col-3">
              <label className="create-label1">Property City<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="city"
                  name="city"
                  autoComplete="current-city"
                  required
                  placeholder="Property City"
                  aria-label="city"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Property Address<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="propertyAddress"
                  name="propertyAddress"
                  autoComplete="current-propertyAddress"
                  required
                  placeholder="propertyAddress"
                  aria-label="propertyAddress"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Billing Address<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="billing_address"
                  name="billing_address"
                  autoComplete="current-billing_address"
                  required
                  placeholder="Billing address"
                  aria-label="billing_address"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-6">
              <label className="create-label1">Gst Name<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="gstName"
                  name="gstName"
                  autoComplete="current-gstName"
                  required
                  defaultValue={companyDetail?.gstName}
                  placeholder="GST Name"
                  aria-label="gstName"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Gst Number<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="gstNumber"
                  name="gstNumber"
                  autoComplete="current-gstNumber"
                  required
                  placeholder="Gst Number"
                  aria-label="gstNumber"
                  defaultValue={companyDetail?.gstNumber}
                  aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Plan Duration <span className="required_star">*</span></label>
              <div className="input-group ">
                <select
                  className="custom-select height-select"
                  required id="inputGroupSelect01"
                  name="planFrequency"
                  label="planFrequency"
                  autoComplete="current-planFrequency"
                >
                  <option value={""}>Select Duration</option>
                  {planDuration.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Plan Code<span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="planCode"
                  name="planCode"
                  autoComplete="current-planCode"
                  required
                  defaultValue={companyDetail?.planCode}
                  placeholder="Plan Code"
                  aria-label="planCode"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
        </div>


        <div className="make_quto">
        <div className="row">
        <div className="col-6">
              <label className="create-label1">Cost Per Appliances</label>
              <div class="input-group ">
                <input
                  type="number"
                  value={costs.costperAppliance}
                  class="form-control"
                  id="costPerAppliance"
                  name="costPerAppliance"
                  autoComplete="current-costPerAppliance"
                  aria-label="costPerAppliance"
                  aria-describedby="basic-addon1"
                  onChange={(e)=>setCosts({...costs,costperAppliance:Number(e.target.value)})}
                  />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Total Appliances</label>
              <div class="input-group ">
                <input
                  type="number"
                  className="form-control"
                  id="totalAppliances"
                  value={costs.totalAppliance}
                  name="totalAppliances"
                  autoComplete="current-totalAppliances"
                  placeholder="Total appliances"
                  aria-label="totalAppliances"
                  aria-describedby="basic-addon1"
                  onChange={(e)=>handleApplianceChange(Number(e.target.value))}
                  />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Security Cost Per Appliance</label>
              <div class="input-group mb-1">
                <input
                  type="number"
                  class="form-control"
                  id="security"
                  name="securityCost"
                  value={costs.costperApplianceSequirty}
                  autoComplete="current-securityCost"
                  placeholder="Security"
                  aria-label="security"
                  aria-describedby="basic-addon1"
                  onChange={(e)=>handleSecurityCostChange(Number(e.target.value))}
                />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Installation Cost Per Appliance</label>
              <div class="input-group mb-1">
                <input
                  type="number"
                  class="form-control"
                  id="install"
                  name="installCost"
                  autoComplete="current-installCost"
                  placeholder="Installation"
                  aria-label="install"
                  value={costs.costperApplianceInstallation}
                  aria-describedby="basic-addon1"
                  onChange={(e)=>handleInstallationCostChange(e.target.value)}
                />
              </div>
            </div>
          </div>
         
        </div>
        <div className="make_quto">

          <div className="row">
            <div className="col-6">
              <label className="create-label1">Security</label>
              <div class="input-group mb-1">
                <input
                  type="number"
                  class="form-control"
                  id="currentSecurity"
                  name="currentSecurity"
                  autoComplete="current-currentSecurity"
                  placeholder="Security"
                  aria-label="security"
                  value={costs.security}
                  onChange={(e)=>setCosts({...costs,security:Number(e.target.value)})}
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Installation</label>
              <div class="input-group mb-1">
                <input
                  type="number"
                  class="form-control"
                  id="currentInstallation"
                  name="currentInstallation"
                  autoComplete="current-currentInstallation"
                  placeholder="Installation"
                  aria-label="currentInstallation"
                  value={costs.installation}
                  onChange={(e)=>setCosts({...costs,installation:Number(e.target.value)})}

                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>


          <div className="row">
          
            
          </div>
        </div>

        <div className="make_quto">
          <div className="row">
          <div className="col-6">
              <label className="create-label1">Zone</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  required
                  id="inputGroupSelect01"
                  name="zone"
                  label="zone"
                  autoComplete="current-zone"
                  >
                  <option value={''}>Select Zone</option>
                  {ElectricianIncentiveZone.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">First Telecaller Name</label>
              <div class="input-group">
                <select className="custom-select height-select" 
                  id="inputGroupSelect01"
                  name="teleCallerId"
                  label="teleCallerId"
                  autoComplete="current-teleCallerId"
                  defaultValue={teleCallers}>
                  {userName?.map((option) => (
                    <option value={option.first_name}>{option.first_name}</option>
                  ))}
                </select>
              </div>
            </div>
           
          </div>
        </div>
        <div style={{ marginLeft: "1%" }}>
          <div className="btn-create-container">
            <div className="btn-create">

              <Button
                type="submit"
                variant="contained"
                style={{
                  pointerEvents: `${qutoBtnText === "Processing..." ? "none" : ""}`,
                  backgroundColor: `${qutoBtnText === "Processing..." ? "#cccccc" : "#2552B2"}`
                }}
              >
                {qutoBtnText}
              </Button>
            </div>
          </div>
        </div>


      </Box>
      {
        userName == undefined ?

          <Alert variant="filled" severity="error"
            sx={{ fontSize: "15px", marginTop: "5%", }}>

            LogOut your account from all devices
          </Alert> : null
      }

      <Qutoes />
    </div>
  );
}

