import React from 'react'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';

import './Deleteanddrop.css'

export default function InvoiceDelete({e,setReload,reloadW,notify}) {
    const invoiceDelete = async()=>{
        await axios.post(`${serverUrl.u}/api/tele/deleteInvoice`,{
            invoiceNumber :e.invoiceNumber,
        }).then(function (res) {
          console.log(res);
          if(res.status===200){
            notify(`Invoice Deleted ${e.invoiceNumber}`)
           
          }else{
            notify("Failed")
          }
        }).catch(function (er) {
          console.log(er);
          notify("Failed")
        })
      }
  return (
    <>
   
          <button className="btn btn-primary width" onClick={()=>{invoiceDelete()}} >
          Invoice Delete
          </button>
     
    
    </>
  )
}
