import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { orderTyepes } from "../Data";
import Spinner from "../Function/Spinner";
import { notifyFailed } from "../Common/Notification";


export default function LeadsAnalysis() {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [InstallationDate, setInstallationDate] = useState("");
  const [lastInstallationDate, setLastInstallationDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [response, setResponse] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState("All");

  async function getData() {
    if (firstDate === "" || (lastDate === "" && InstallationDate === ""))
      return;

    setLoading(true);
    await axios
      .post(
        `https://subscriptioncloud.alistetechnologies.com/api/script/getScriptData`,
        {
          firstDate,
          lastDate,
          firstInstallationDate: InstallationDate,
          lastInstallationDate: lastInstallationDate,
          fillterApply: true,
          orderType: subscriptionType,
        }
      )
      .then((res) => {
        setLoading(false);
        setResponse(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed(err?.response?.data?.message)
      });
  }

  let allStatus = [
    "Total Subscrtiption Booked",
    "Total Installed Appliances",
    "Total Subscription Installed",
  ];

  
    const telecallerNames = ["overallData"].concat(Object.keys(response));


    // remove overall data
    telecallerNames.pop();

    allStatus = telecallerNames.length > 1 ? allStatus.concat(Object.keys(response[telecallerNames[1]])) : [];


  const tcD2C = ["overallData", "Heena", "Prity", "meena", "Havisha", "Ambika"];

  const tcFree = ["Tanu", "Vishakha"];

  const tele = tcD2C.concat(tcFree);
  
  const finalTc = () => {
    for (const t of telecallerNames) {
      if (!tele.includes(t)) {
          tele.push(t);
      }
    }
  }

  if (Object.keys(response).length > 1) finalTc();

    const statusToDisplay = {
      "Total Subscrtiption Booked": "totalBooked",
      "Paid Subscription Booked": "paidBooked",
      "Free Subscription Booked": "freeBooked",
      "Total Installed Appliances": "calculate",
      "Paid Appliance Installed": "totalPaidAppliance",
      "Free Appliance Installed": "totalFreeAppliance",
      "Total Subscription Installed": "calculate",
      "Paid Subscription Installed": "totalPaidInstalled",
      "Free Subscription Installed": "totalFreeInstalled",
      "Dropped Clients": "dropped",
      Uninstalled: "unInstalled",
    };

  const colors = {
    "Total Subscrtiption Booked": "#4ea8de",
    "Paid Subscription Booked": "#56cfe1",
    "Free Subscription Booked": "#56cfe1",
    "Total Installed Appliances": "#ff9f1c",
    "Paid Appliance Installed": "#ffbf69",
    "Free Appliance Installed": "#ffbf69",
    "Total Subscription Installed": "#E02E2E",
    "Paid Subscription Installed": "#f27059",
    "Free Subscription Installed": "#f27059",
    "Dropped Clients": "",
    Uninstalled: "",
  };

  const calculateData = (status, tc) => {
    if (status === "Total Installed Appliances") {
      return (
        response[tc]["totalPaidAppliance"] + response[tc]["totalFreeAppliance"]
      );
    }
    
    if (status === "Total Subscription Appliances") {
      return (
        response[tc]["totalFreeInstalled"] + response[tc]["totalPaidInstalled"]
      );
    }
    return 0;
  }

  allStatus = allStatus.filter((r) => r !== "booked");

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="row">
        <div className="col-2">
          <label className="create-label1">Select Subscription Type</label>
          <select
            defaultValue={subscriptionType}
            onChange={(e) => setSubscriptionType(e.target.value)}
          >
            {orderTyepes.map((type) => (
              <option>{type?.value}</option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label className="create-label1">Start Date</label>
          <div class="input-group ">
            <input type="date" onChange={(e) => setFirstDate(e.target.value)} />
          </div>
        </div>
        <div className="col-2">
          <label className="create-label1">End Date</label>
          <div class="input-group ">
            <input
              type="date"
              onChange={(e) => {
                setLastDate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-2">
          <label className="create-label1">First Installation Date</label>
          <div class="input-group ">
            <input
              type="date"
              onChange={(e) => {
                setInstallationDate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-2">
          <label className="create-label1">Last Installation Date</label>
          <div class="input-group ">
            <input
              type="date"
              onChange={(e) => {
                setLastInstallationDate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
              getData();
            }}
          >
            Get Data
          </button>
        </div>
        {/* <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
              //   generateCsvData();
            }}
          >
            Generate CSV
          </button>
        </div> */}
      </div>
      
      {
        loading && <Spinner />
      }
      <div className="table-container1 table-fixed">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Status</th>
              {tele.map(tc => (
                <th key={tc}>{tc}</th>
              ))}
            </tr>
          </thead>
          <tbody>

            {
              Object.keys(response).length > 0 &&  Object.keys(statusToDisplay).map(status => {
               return (
                 <tr style={{ backgroundColor: colors[status] }}>
                   <td>{status}</td>
                   {tele.map((tc) => {

                     return (
                      
                       <td>
                         {statusToDisplay[status] === "calculate"
                           ? calculateData(status, tc)
                           : response[tc][statusToDisplay[status]]}
                       </td>
                     );
                   })}
                 </tr>
               );
              })
            }
            {/* {allStatus &&
              allStatus.map((status) => (
                // <tr style={{ backgroundColor: getColors[status] }}>
                <tr>
                  <td>{status}</td>
                  {
                    
                    Object.keys(statusToDisplay).map(status => (
                      <td>{status}</td>
                    ))
                    // response && 
                    // response.map((res, i) => {
                    //   return (
                    //   <td>{res[telecallerNames[i]][status]}</td>
                    //   )
                    // })
                  }
                </tr>
              ))} */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
