import axios from 'axios'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'

function DeleteProduct({data,setApiCall, apiCall}) {
    const [btn,setBtn] = useState("Delete")

    const handleDelete = () => {
        setBtn("Deleting...")
        
        axios.post(`${serverUrl.u}/v3/affiliateProducts/delete`,{
            productId:data._id
        }).then(res => {
           console.log(res);
           setApiCall(!apiCall)
           setBtn("Deleted")
        }).catch(err =>{
            console.log(err);
            setBtn("Error")
        })
    }

  return (
    <button className='btn btn-primary' disabled={btn==="Deleting..."?true:false} onClick={handleDelete} style={{marginLeft:"10px"}}>{btn}</button>
  )
}

export default DeleteProduct