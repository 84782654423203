import { Button, Grid } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { leadCreate } from './Api';
import { useState } from 'react';
import { notifyFailed } from '../Common/Notification';

function LeadCreate() {
    let userDetail = useSelector((state) => state.sar.data.user)
    const salesUser = useSelector(state => state.salesUser)

    let [btn,setBtn] = useState("Submit")
    
    const handleSubmit = async(event)=>{
        setBtn("Processing...")
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(data.get("leadType")===""){
           notifyFailed("Select Lead Type")
           setBtn("Submit")
           return
        }
        
        console.log(userDetail);
        const detail = {
            name:data.get('name'),
            phone:data.get('phone'),
            email:data.get('email'),
            city:data.get('city'),
            source:data.get('source'),
            user:{
              name:userDetail.first_name,
              employeeId:userDetail.employeeId,
              _id:userDetail._id
            },
            leadType:data.get("leadType"),
            assignTo:data.get('assignTo')===""?undefined:data.get('assignTo')
        }
        
         if(detail.assignTo!==undefined){
            let number = detail.assignTo.split(',')[0]
            if (number.includes("+")) {
                number = number.substring(3)
                console.log(number.substring(0, 10), "2");
              }

              number = number.substring(0, 10)
              notifyFailed(number)
              detail.assignTo=number
            }
         
         await leadCreate(detail)
       setBtn("Submit")
    }
    return (
        <div style={{ padding: "0 4%", marginTop: "4vh" }}>
            <form className="make_quto" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <label>Lead Name</label>
                        <div class="input-group">
                            <input 
                              type="text" 
                              className="form-control" 
                              id="name" 
                              name="name" 
                              autoComplete="current-name" 
                              placeholder="Name" 
                              aria-label="name" 
                              aria-describedby="basic-addon1"
                              required
                            />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <label>Phone Number</label>
                        <div class="input-group">
                            <input 
                              type="number" 
                              className="form-control" 
                              id="phone" 
                              name="phone" 
                              autoComplete="current-phone" 
                              placeholder="Phone Number" 
                              aria-label="phone" 
                              aria-describedby="basic-addon1"  
                              required
                            />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <label>Email</label>
                        <div class="input-group">
                            <input 
                              type="email" 
                              className="form-control" 
                              id="email" 
                              name="email" 
                              autoComplete="current-email" 
                              placeholder="E - Mail" 
                              aria-label="email" 
                              aria-describedby="basic-addon1"
                              required  
                            />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <label>City</label>
                        <div class="input-group">
                            <input 
                              type="text" 
                              className="form-control" 
                              id="city" 
                              name="city" 
                              autoComplete="current-city" 
                              placeholder="City" 
                              aria-label="city" 
                              aria-describedby="basic-addon1"  
                              required
                            />
                        </div>
                    </Grid>
                    <Grid item md={6} >
                        <label>Lead Source</label>
                        <div class="input-group">
                            <input 
                              type="text" 
                              className="form-control" 
                              id="source" 
                              name="source" 
                              autoComplete="current-source" 
                              placeholder="source" 
                              aria-label="source" 
                              aria-describedby="basic-addon1" 
                              required 
                            />
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <label>Lead Type</label>
                        <div class="input-group" style={{width:"100%"}}>
                            <select 
                              type="text" 
                              className="form-control" 
                              id="leadType" 
                              name="leadType" 
                              autoComplete="current-leadType" 
                              placeholder="leadType" 
                              aria-label="leadType" 
                              aria-describedby="basic-addon1" 
                              required
                            >
                                <option value="">Select Type</option>
                                <option value="D2C">D2C</option>
                                <option value='BB Calling'>BB Calling</option>
                                <option value="B2B">B2B</option>
                                <option value="Grievance">Grievance</option>
                                <option value="Distributor">Distributor</option>
                                <option value="Test">Test</option>
                            </select>
                        </div>
                    </Grid>
                    {/* <Grid item md={6} >
                    <label>Assign to</label>
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control" 
                              id="assignTo" 
                              name="assignTo" 
                              autoComplete="current-assignTo" 
                              placeholder="TeleCaller Number Without +91" 
                              aria-label="assignTo" 
                              aria-describedby="basic-addon1"  
                            />
                        </div>
                    </Grid> */}
                    <Grid item md={6} xs={12}>
                        <label>Assign to</label>
                        <div class="input-group" style={{width:"100%"}}>
                            <select 
                              type="text" 
                              className="form-control" 
                              id="assignTo" 
                              name="assignTo" 
                              autoComplete="current-assignTo" 
                              placeholder="assignTo" 
                              aria-label="assignTo" 
                              defaultValue={""}
                              aria-describedby="basic-addon1" 
                              onChange={(e)=>console.log(e.target.value,"num")}
                              
                            >
                                <option value={""}>Select TeleCaller</option>
                              {
                                    salesUser.map((sale)=>{
                                        if(sale.roles !== undefined && !sale.roles.includes("Super Admin")){
                                            return (
                                                <option value={sale.phoneNumbers}>{sale.first_name}</option>
                                            )
                                        }
                                    })
                                }
                            </select>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={1} mt={2}>
                    <Grid item md={3}>
                     <Button
                       variant='contained'
                       color='primary'
                       size='small'
                       type='submit'
                       disabled={btn==="processing..."?true:false}
                     >{btn}</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default LeadCreate