import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../ApiUrl';
import { Button } from '@mui/material';
import Installationtablewise from './Installationtablewise';
import { notify } from '../Common/Notification';
import { useSelector } from 'react-redux';
import { colorDecide } from './fin';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { InstallationDataApi, availableElectrician } from '../Redux/installationActions';
import { user_roles } from '../Data';
import  Spinner from '../../component/Function/Spinner'


function PageNatedInstallationPage() {
  let teleCallersIsp = useSelector((state)=>state.sar.data.user.username)
  
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
    let AllInstallationData = useSelector((state) => state.installationData.filter(e=>{
      if(userRole?.includes(user_roles.Isp)){
        if(e.teleCallerId===teleCallersIsp){
          return e
        }
      }else{
        return e
      }
    }))
    let avilableElectrician = useSelector(state=>state.electrician)
    let [dates, setDates] = useState([])
    const dispatch = useDispatch()
    let salesUserData = useSelector(state => state.salesUser);
    // let [AllInstallationData,setAllInstalltionData] = useState([])
    let [websiteReload, SetWebsiteReload] = useState(false);
    let [userName, setUserName] = useState()
    let [q, setq] = useState("");
    const dateFormated= (date)=>{
        return moment.utc(date).format("ddd -  Do MMM")
    }
    useEffect(() => {
        
        InstallationDataApi(dispatch)   

        availableElectrician() 
      }, [])
      useEffect(() => {
        let id = setInterval(() => { InstallationDataApi(dispatch) }, 10000);
        return () => {
          if (id) {
            clearInterval(id)
          }
        }
      }, [])
    // const InstallationDataApi = async () => {

    //     await axios.post(
    //         `${serverUrl.u}/api/tele/getAllNotInstalled`
    //     )
    //         .then(async function (response) {
    //             let detail = response.data.data.sort(function (a, b) {
    //                 return new Date(a.InstallationDate) - new Date(b.InstallationDate);
    //             });
            
    //             setAllInstalltionData(detail)
    //             if (dates.length === 0) {
    //                 alert("call")
    //                 let dateData = []
    //                 let j = -3
    //                 while (j < 0) {
    //                     dateData.push(new Date(new Date().setDate(new Date().getDate() + j)))
    //                     j++
    //                 }
    //                 let i = 0
    //                 while (i <= 3) {
    //                     dateData.push(new Date(new Date().setDate(new Date().getDate() + i)))
    //                     i++
    //                 }
    //                 console.log('====================================');
    //                 console.log(dateData);
    //                 console.log('====================================');
    //                 setDates(dateData)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };
    useEffect(()=>{
        if (dates.length === 0) {
                            
                            let dateData = []
                            let j = -3
                            while (j < 0) {
                                dateData.push(new Date(new Date().setDate(new Date().getDate() + j)))
                                j++
                            }
                            let i = 0
                            while (i <= 3) {
                                dateData.push(new Date(new Date().setDate(new Date().getDate() + i)))
                                i++
                            }
                          
                            setDates(dateData)
                        }
    },[])
  

    const previousCall = ()=>{
        const startDate = new Date(dates[0])
       
        let dateData = []
        let i=-7
         while(i<=-1){
     
            dateData.push(new Date(new Date(startDate).setDate(startDate.getDate() + i)))
            i++

         }
         setDates(dateData)
     
    }
    const nextCall = ()=>{
        const startDate = new Date(dates[dates.length-1])
        
        let dateData = []
        let i=1
         while(i<=7){
            dateData.push(new Date(new Date(startDate).setDate(startDate.getDate() + i)))
            i++

         }
     
        setDates(dateData)
   
    }
    const search = (data) => {
        if (data === undefined) return
        let newData = data.filter((e) => e.name.toUpperCase().includes(q.toUpperCase()))
        return newData
      }
    return (
        <div  style={{ padding: "0 4%", marginTop: "4vh" }}>
            <div style={{display:'flex',gap:"10px"}}>
                <Button
                   variant='contained'
                   size='small'
                   onClick={()=>previousCall()}
                >Previous</Button>
                <Button
                    variant='contained'
                   size='small'
                   onClick={()=>nextCall()}
                >Next</Button>
            </div>
            {
        dates.length !== 0 ?
          dates.map((d, index) => {
          
            return (
              <Installationtablewise
                array={d}
                details={AllInstallationData}
                SetWebsiteReload={SetWebsiteReload}
                websiteReload={websiteReload}
                userName={userName}
                colorDecide={colorDecide}
                notify={notify}
                salesUserDatas={salesUserData}
                index={index}
                search={search}
                avilableElectrician={avilableElectrician}
              />
            )
          }) : (AllInstallationData.length!==0 && <Spinner/>)
      }
        </div>
    )
}

export default PageNatedInstallationPage