import axios from "axios";
import React from "react";
import { serverUrl } from "../ApiUrl";
import { useState, useEffect } from "react";


export const DeviceStatus = ({ deviceId }) => {
  const [status, setStatus] = useState('Loading...');
  const deviceDetails = async (id) => {
    await axios.post("https://keiozfbox5.execute-api.ap-south-1.amazonaws.com/default/connectionStatus", { deviceId: id }).then((res)=>{
     if (res.data.success) {
       console.log(res.data.data.connected,"api");
        setStatus(res.data.data.connected);
     } else {
       setStatus(false)
     }
    }).catch(err=>{
      setStatus(false)
    })
     
 };
  useEffect(() => {
    let isMounted = true;

    deviceDetails(deviceId)

    return () => {
      isMounted = false;
    };
  }, [deviceId]);

  return <>{status ? <span style={{ color: '#00C48B' }}>On-Line</span> : <span style={{ color: '#FF0000' }}>Off-Line</span>}</>;
};



export default function ShowDevices({ data }) {
  let [devices, setDevice] = useState([]);
  const deviceId = async () => {
    let url = "http://localhost:3001";
    await axios
      .post(`${serverUrl.u}/api/div/getAllDevicesInfo`, {
        houseId: data.houseId,
      })
      .then(function (res) {
        console.log(res, "devices");
        setDevice(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
 
  return (
    <>
      <button
        className="btn btn-primary"
        style={{ backgroundColor: "#28a745" }}
        onClick={() => deviceId()}
      >
        Get Device Id
      </button>

      {devices.length !== 0 ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 4,
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              backgroundColor: "whitesmoke",
              padding: "15px",
              borderReadius: "12px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              width: "60%",
              maxHeight: "60vh",
              overflow: "scroll",
              borderRadius: "12px",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "15px",
                cursor: "pointer",
              }}
            >
              <span>House Id :- {data.houseId}</span>
              <span
                onClick={() => setDevice([])}
                style={{
                  fontWeight: 600,
                  color: "black",
                  width: "22px",
                  float: "right",
                }}
              >
                X
              </span>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                overflowX: "scroll",
                flexDirection: "column",
              }}
            >
          
              {devices.devices.length > 0 && (
                <div className="table-container1" style={{ marginTop: 0 }}>
                  <span style={{ marginLeft: "10px" }}>Sync Devices</span>
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Room Name</th>
                        <th scope="col">Appliance Name</th>
                        <th>Device Id</th>
                        <th scope="col">Switch Id</th>
                        <th scope="col">Mannual Switch State</th>
                        <th>Appliance State</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devices.devices.length>0 && devices.devices.map((e, i) => {
                         
                        return (
                          <tr key={i}>
                            <td className="pr-15 pl-55">{e.roomName}</td>
                            <td>{e.applianceName}</td>
                            <td>{e.deviceId}</td>
                            <td>{e.switchId}</td>
                            <td>{e.mannualSwitchState}</td>
                            <td>{<DeviceStatus deviceId={e.deviceId}/>}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              {/* EnergySync */}

              {devices.energySync.length > 0 && (
                <div className="table-container1" style={{ marginTop: 0 }}>
                  <span style={{ marginLeft: "10px" }}>EnergySync Devices</span>
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Room Name</th>
                        <th>Device Name</th>
                        <th>Device State</th>
                        <th>Load Type</th>
                        <th>Control Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devices.energySync.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td className="pr-15 pl-55">{e.roomName}</td>
                            <td>{e.deviceId}</td>
                            <td>
                              {e.applianceState !== "NA"
                                ? e.applianceState === 0
                                  ? "Off"
                                  : "On"
                                : e.applianceState}
                            </td>
                            <td>{e.loadType}</td>
                            <td>{e.controlType}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Nova */}
              {devices.nova.length > 0 && (
                <div className="table-container1" style={{ marginTop: 0 }}>
                  <span style={{ marginLeft: "10px" }}>Nova Devices</span>
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Room Name</th>
                        <th scope="col">Appliance Name</th>
                        <th>Device Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devices.nova.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td className="pr-15 pl-55">{e.roomName}</td>
                            <td>{e.applianceName}</td>
                            <td>{e.deviceId}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Curtains */}
              {devices.curtains.length > 0 && (
                <div className="table-container1" style={{ marginTop: 0 }}>
                  <span style={{ marginLeft: "10px" }}>Curtains Devices</span>
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Room Name</th>
                        <th>Device Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devices.curtains.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td className="pr-15 pl-55">{e.roomName}</td>
                            <td>{e.deviceId}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
