import axios from "axios"
import { serverUrl } from "../../ApiUrl"
import { notify, notifyFailed } from "../../Common/Notification"

export const fetchWalletBalance = async (phone, setWalletBalance,setBtn) => {
    await axios.post(`${serverUrl.u}/app/isp/fetchWalletBalance`, {
        phone: phone
    }).then(res => {
        console.log(res,"wallet");
        if (res.data.success === true) {
            
            setWalletBalance(res.data.data)
            notify(res.data.message)
            setBtn("Done")
        } else {
            notifyFailed(res.data.message)
            setWalletBalance({})
            setBtn("Failed")
        }
    }).catch(err => {
        setBtn("Failed")
        setWalletBalance({})
        console.log(err);
        notifyFailed(err.response.data.message)
    })
}