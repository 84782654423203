export const UP_cities = [
    "KANPUR", "LUCKNOW", "GHAZIABAD", "AGRA", "MEERUT", "VARANASI", "PRAYAGRAJ", 
    "BAREILLY", "ALIGARH", "MORADABAD", "SAHARANPUR", "GORAKHPUR", "NOIDA", 
    "FIROZABAD", "JHANSI", "MUZAFFARNAGAR", "MATHURA-VRINDAVAN", "BUDAUN", 
    "RAMPUR", "SHAHJAHANPUR", "FARRUKHABAD-FATEHGARH", "AYODHYA", "MAUNATH BHANJAN", 
    "HAPUR", "ETAWAH", "MIRZAPUR-VINDHYACHAL", "BULANDSHAHR", "SAMBHAL", "AMROHA", 
    "HARDOI", "FATEHPUR", "RAEBARELI", "ORAI", "SITAPUR", "BAHRAICH", "MODINAGAR", 
    "UNNAO", "JAUNPUR", "LAKHIMPUR", "HATHRAS", "BANDA", "PILIBHIT", "BARABANKI", 
    "KHURJA", "GONDA", "MAINPURI", "LALITPUR", "ETAH", "DEORIA", "BADAUN", "GHAZIPUR", 
    "SULTANPUR", "AZAMGARH", "BIJNOR", "SAHASWAN", "BASTI", "CHANDAUSI", "AKBARPUR", 
    "BALLIA", "TANDA", "GREATER NOIDA", "SHIKOHABAD", "SHAMLI", "AWAGARH", "KASGANJ"
]
