import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { state, user_roles } from '../Data';
import SelectCityInput from '../city/SelectCityInput';
import { Button } from '@mui/material';
import { installationChart } from './Api';
import { notInitialized } from 'react-redux/es/utils/useSyncExternalStore';
import { notifyFailed } from '../Common/Notification';
import SalesChart from './SalesChart';

function Numbers() {
  const salesUser = useSelector((state) => state.salesUser);
  const [btn, setBtn] = useState('Submit');
  const [city, setCity] = useState('All');
  const [chartData, setChartData] = useState([]);
  const [telecallersCountKeys, setTelecallersCountKeys] = useState({});

  console.log(salesUser, 'sales');
  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataForm = new FormData(event.currentTarget);

    let data = {
      teleCallerId: dataForm.get('telecallerId'),
      city: dataForm.get('city'),
      date: dataForm.get('startDate'),
    };
    setBtn('loading...');
    let res = await installationChart(data);
    if (res.res !== undefined) {
      if (res.res.data.success) {
        console.log(res.res.data.data);
        if (res.res.data.data.success) {
          setChartData(res.res.data.data.finalData);
          let test = res.res.data.data.finalData[0];
          console.log(test[0], 'first_user');
          for (let i of Object.keys(test)) {
            // console.log('users', typeof test[i]);
            setTelecallersCountKeys(test[i]);
          }
        }
      }
      setBtn('Submit');
    } else if (res.err !== undefined) {
      notifyFailed(res.err.response.data.message);
      setBtn('Submit');
    }
  };
  return (
    <div className="container mt-2">
      <h5>Installation Data</h5>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4">
            <label>Date Of Data</label>
            <input
              className="form-control"
              type="date"
              name="startDate"
              autoComplete="current-startDate"
            />
          </div>
          <div className="col-4">
            <label>TeleCaller</label>
            <select
              className="form-control"
              defaultValue={'All'}
              name="telecallerId"
              autoComplete="current-telecallerId"
            >
              <option value={'All'}>All</option>
              {salesUser &&
                salesUser.map((sales) => {
                  if (
                    sales.roles !== undefined &&
                    (sales.roles.includes(user_roles.SuperAdmin) ||
                      sales.roles.includes(user_roles.Telecaller))
                  ) {
                    return (
                      <option value={sales.first_name} key={sales._id}>
                        {sales.first_name}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          <div className="col-4">
            <label>
              City<span className="required_star">*</span>
            </label>
            <div class="input-group ">
              <select
               className='form-control'
               name='city'
               defaultValue={"All"}
               autoComplete='current-city'
              >
                <option value={'All'}>All</option>
                <option value={'Delhi'}>DELHI</option>
                <option value={'BENGALURU'}>BENGALURU</option>

              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <Button
              type="submit"
              variant="contained"
              disabled={btn !== 'Submit' ? true : false}
            >
              {btn}
            </Button>
          </div>
        </div>
      </form>
      <div className="table-container1" style={{ marginTop: '2%' }}>
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Type</th>
              {setTelecallersCountKeys &&
                Object.keys(telecallersCountKeys).map((key) => <th style={{width:"250px"}}>{key}</th>)}
            </tr>
          </thead>
          <tbody>
            {chartData.length !== 0
              ? chartData.map((e, i) => {
                  let key = Object.keys(e);
                    console.log(e,key,e[key[0]], 'eeee');
                  return (
                    <tr key={i}>
                      <td>{key[0]}</td>
                      {
                        Object.keys(e[key[0]]).map(
                          (keyItem) => {
                            console.log(e[key[0]][keyItem],"item");
                         return <td>{e[key[0]][keyItem].count}</td>
                        }
                        )}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <SalesChart/>
    </div>
  );
}

export default Numbers;
