import axios from "axios"
import { serverUrl } from "../ApiUrl"
import { getAllDevices } from "../Common/applianceDifference"



export const clientRetention =async(time,sub)=>{
    let deviceIds = await getAllDevices(sub.houseId)
    console.log(deviceIds);
    let device=[]
    for(let id of deviceIds){
        if(id===null) continue
        console.log(id,"d");
        console.log(id.substring(1,3));
        let d= id.split(" ")
        let event=0;
        console.log(d,"device id");
        if(id.substring(1,3)=="01"){
            event=1
            device.push(`${d[0]}$0`)
         }else if(id.substring(1,3)=="02"){
           event=2
           for(let i=0;i<2;i++){
              device.push(`${d[0]}$${i}`)
           }
         }else if(id.substring(1,3)=="03"){
           event=4
           for(let i=0;i<4;i++){
            device.push(`${d[0]}$${i}`)
         }
         }else if(id.substring(1,3)=="04"){
           event=5
           for(let i=0;i<5;i++){
            device.push(`${d[0]}$${i}`)
         }
         }else if(id.substring(1,3)=="05"){
           event=5
           for(let i=0;i<5;i++){
            device.push(`${d[0]}$${i}`)
         }
         }else if(id.substring(1,3)=="06"){
           event=2
           for(let i=0;i<2;i++){
            device.push(`${d[0]}$${i}`)
         }
         }else if(id.substring(1,3)=="12"){
           event=3
           for(let i=0;i<3;i++){
            device.push(`${d[0]}$${i}`)
         }
         }else if(id.substring(1,3)=="13"){
           event=4
           for(let i=0;i<4;i++){
            device.push(`${d[0]}$${i}`)
         }
         }
        }
        console.log(device,"hhh");
   return await axios.post(`${serverUrl.analystic}/analytic/v2/caculateRetentionSpeceficHouse`,{
        firstDate:time.startDate,
        lastDate:time.endDate,
        dSIds:device
    }).then(function (res) {
        console.log(res);
        let totalOnlineCommand = 0
        if(res.data.result){
            
            let houseKey = Object.keys(res.data.data)
            for(let key of houseKey){
                // console.log(res.data.data[key].totalOnline,"find");
                totalOnlineCommand+=res.data.data[key].totalOnline
            }
            return totalOnlineCommand
        }else{
           return totalOnlineCommand
        }
    }).catch(function (err) {
        console.log(err);
        return 0
    })
}