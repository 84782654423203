import axios from "axios";
import React, { useState } from "react";
import { pages, serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";
import moment from "moment";
import { subHistory } from "../Function/SubscriptionHistory";
import { useSelector } from "react-redux";
import { Box, Button, Modal, Stack, Typography, setRef } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateInstallationData } from "../Redux/installationActions";
import { updateAddressLayout } from "../Redux/Action/adderess&layout";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

function AdminComment({ sub, page }) {
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  const [commentText, setCommentText] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const adminCommentUpdate = () => {
    axios
      .post(`${serverUrl.u}/api/tele/updateHeadComments`, {
        generatedHex: sub.generatedHex,
        comments: {
          commentText,
          createdAt: new Date(),
          createdBy: loginName,
        },
      })
      .then((res) => {
        if (res.data.success) {
          notify(res.data.message);
          if (page === pages.installation) {
            dispatch(updateInstallationData(res.data.subscription));
          } else if (page === pages.addressLayout) {
            dispatch(updateAddressLayout(res.data.subscription));
          }
          subHistory(
            `Comment Added By ${loginName}`,
            { ...sub, adminCommentPayload: commentText },
            "Admin Comment Added",
            commentText
          );
        } else {
          notifyFailed(res.data.message);
        }
      })
      .catch((err) => {
        notifyFailed(err.response.data.message);
      });
  };
  return (
    <>
      <div
        className="hover-wide"
        style={{ width: "180px" }}
        onClick={handleOpen}
      >
        {Array.isArray(sub.headComments) ? (
          <div
            style={{ padding: "5px", width: "160px" }}
            className="hover-wide"
          >
            {sub.headComments.map((c) => {
              if (typeof c === "object") {
                return (
                  <div style={{ padding: 0 }}>
                    {c.commentText} -{" "}
                    {moment.utc(c.createdAt).format("DD-MMM-YYYY")} -{" "}
                    {c.createdBy}
                  </div>
                );
              } else {
                return <div>{c}</div>;
              }
            })}
          </div>
        ) : (
          sub.headComments
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
            Comment
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <label className="specific-label">{"Enter Comment"} </label>
            <div className="input-group ">
              <input
                type="text"
                className="form-control"
                required
                aria-label="referenceId"
                name="comment"
                onChange={(event) => setCommentText(event.target.value)}
                autoComplete="current-comment"
                aria-describedby="basic-addon1"
              />
            </div>
          </Typography>
          <Stack
            direction={"row"}
            spacing={3}
            justifyContent={"flex-end"}
            mt={5}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#9FA6B2" }}
              onClick={() => {
                handleClose();
              }}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                adminCommentUpdate();
                handleClose();
              }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default AdminComment;
