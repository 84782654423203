import { ci } from "../../city/Cities";
import { addressLayout } from "../actionIndex";

export const adderessLayoutRed = (state=[],action)=>{
     
    switch (action.type) {
        case addressLayout.FETCH_DATA:
             return action.payload;
        case addressLayout.UPDATE_DATA:
            let data = state.map((element)=>{
                if(element._id===action.payload._id){
                     element={...action.payload}
                  return element
                }else{
                  return element
                }
             })  
            return data;
        case addressLayout.REMOVE_DATA:
            let removedata = state.filter((element)=>{
                if(element._id!==action.payload._id){
                  return element
                }
             })  
             console.log(removedata,"data");
            return removedata;
        default:
            return state
    }
}