import React, { useState } from 'react'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { subHistory } from '../Function/SubscriptionHistory';
import '../Function/Deleteanddrop.css'
import { useSelector } from 'react-redux';
import { UpdateInvoiceComment } from '../Function/InvoiceCommentUpdate';

export default function MaterAuditCancel({ e, setReload, reloadW, notify }) {
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  const [forms, setForm] = useState(false)
  const masterCancel = async (status) => {
    await axios.put(`${serverUrl.u}/api/tele/updateMasterCancel`, {
      invoiceNumber: e.invoiceNumber,
      cancelStatus: status == "true" ? true : false
    }).then(function (res) {
      console.log(res);
      if (res.status === 200) {
        notify("Master Cancel Successfull")
        subHistory("Master Cancel", e, "Master Cancel", "")
        setReload(!reloadW)
        setForm(false)
      } else {
        notify("Failed")
      }
    }).catch(function (er) {
      console.log(er);
      notify("Failed")
    })
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const master_status = data.get("master")
    
    masterCancel(master_status);
    UpdateInvoiceComment(data.get("comment"),e)

  }
  return (
    <>

      <button className="btn btn-primary width" onClick={() => setForm(true)} >
        Master Cancel
      </button>
      {
        forms === true ?
          <div style={{ position: "fixed", borderRadius: "12px", left: "45%", top: "35%", boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px", zIndex: 3, backgroundColor: "lightgray", width: "20%", padding: "16px" }}>
            <span onClick={() => setForm(false)} style={{ float: "right", cursor: "pointer" }}>X</span>
            <form onSubmit={handleSubmit}>
              <label className="create-label1">Master Cancel Status <span className="required_star">*</span></label>
              <div class="input-group mb-3">
                <select class="custom-select height-select"
                  required
                  id="inputGroupSelect01"

                  defaultValue="choose one"
                  name="master"
                  label="master"
                  autoComplete="current-master"
                >
                  <option value={true}>{"Yes"}</option>
                  <option value={false}>{"No"}</option>
                </select>
              </div>
              <label className="create-label1">Comment<span className="required_star">*</span></label>
              <div class="input-group mb-3">
                <input
                  className='form-control'
                  name='comment'
                  autoComplete='current-comment'
                />
              </div>
              <button className='btn btn-primary' type='submit'>
                Submit
              </button>
            </form>
          </div> : null
      }


    </>
  )
}
