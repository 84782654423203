import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'
import { subHistory } from '../Function/SubscriptionHistory';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { notify, notifyFailed } from '../Common/Notification';

export default function ReAttatchMandate({data,reload,setReload}) {
    let loginName = useSelector((state)=>state.sar.data?.user?.first_name)   
    const reAttachMandate = async()=>{  
        axios.post(`${serverUrl.u}/api/zoho/reatachMandate`,{
            generatedHex:data.generatedHex,
        }).then(function (res) {
            console.log(res);
            if(res.status===200){
                notify("Success")          
                let newDate = new Date();
                subHistory("ReAttatch Mandate",data,"ReAttatch Mandate","Mandate Date")
                setReload(!reload)
            }else{
                notifyFailed("Failed")
            }
        }).catch(function (error) {
            console.log(error);
            notifyFailed("Error Occur")
        })
    }
  return (
    <>
       <Button variant='contained'  className='btn btn-primary' onClick={()=>reAttachMandate()}>ReAttatch Mandate</Button>
           
    </>
  )
}
