import React from 'react'
import { ElectricianIncentiveZone, state } from '../Data'
import { useState } from 'react'
import { serverUrl } from '../ApiUrl';
import axios from 'axios';
import { subHistory } from '../Function/SubscriptionHistory';

import SelectCityInput from '../city/SelectCityInput';

export default function ClientDetailsUpdate({update,notify,loginName}) {
    let [pop,setPop] = useState(false)
    let [city,setCity] = useState(update.city)

    const handleSubmit = async(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let Data = {
            client_name: data.get("client_name"),
            lastName:data.get("client_last_name"),
            email:data.get("client_email"),
            address: data.get("address"),
            alternateNumber: data.get("alternateNumber"),
            addressHyperlink:data.get("addressHyperlink"),
            phone:data.get("phoneNumber"),
            gstName:data.get("gstName"),
            gstNumber:data.get("gstNumber"),
            incentiveZone:data.get('incentiveZone')
          }
        //  let cityee= state.filter(e=>e.value.toUpperCase()===Data.city.toUpperCase())

      if(city.length===0){
        notify("Plz Select City Bofore Update")
        return
      }
        console.log("data",Data);
        updateDetails(Data)
          
      };
      let updateDetails = async (data) => {
        await axios.post(`${serverUrl.u}/app/tele/updateClientDetails`, {
          generatedHex:update.generatedHex,
          name:data.client_name,
          lastName:data.lastName,
          phone:data.phone,
          email:data.email,
          address:data.address,
          addressHyperlink:data.addressHyperlink,
          alternatePhone:data.alternateNumber,
          city:city,
          gstName:data.gstName,
          gstNumber:data.gstNumber,
          zone:data.incentiveZone
        }).then(function (response) {
            if(response.data.success===true){
              notify("Details Updated Successfully")
              subHistory("Client Details Updated",{...update,payload:data},"Client Details Update",new Date())
              console.log(response,"update");
              setPop(false)  
            }else{
              notify(response.data.message)
            }
        })
          .catch(function (error) {
            console.log(error);
            notify(error.data.message)
          })
      }
  return (
    <>
    <button className='btn btn-primary' onClick={()=>setPop(true)}>Update Client Details</button>
    
    {
        pop===true ?
        <div style={{position:"fixed",top:0,left:0,overflowX:"scroll",zIndex:5,height:"100vh",width:"100vw"}}>
        <div className='update-form' style={{
          position: "relative",
          top:"10%",
         
        }}>
        <div >
            <span className='cross' style={{position:"absolute",top: "-5px",
    fontSize: "28px"}} onClick={()=>setPop(false)}>x</span>
            
            <form  validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
          
          <div className="row">
             <div className="col-12">
             <label>Client Name </label>
                 <div class="input-group mb-1">
                    <input type="text" class="form-control" defaultValue={update.name} id="client_name" name="client_name" autoComplete="client_name"  placeholder="client_name" aria-label="client_name" aria-describedby="basic-addon1" />
                 </div>
              </div>
            </div> 
            <div className="row">
             <div className="col-12">
             <label>Client Last Name </label>
                 <div class="input-group mb-1">
                    <input type="text" class="form-control" defaultValue={update.lastName} id="client_last_name" name="client_last_name" autoComplete="current-client_last_name"  placeholder="client_last_name" aria-label="client_last_name" aria-describedby="basic-addon1" />
                 </div>
              </div>
            </div> 
            <div className="row">
             <div className="col-12">
             <label>Client Email</label>
                 <div class="input-group mb-1">
                    <input type="text" class="form-control" defaultValue={update.email} id="client_email" name="client_email" autoComplete="current-client_email"  placeholder="client_email" aria-label="client_email" aria-describedby="basic-addon1" />
                 </div>
              </div>
            </div> 
            <div className="row">
             <div className="col-12">
              <label>Address</label>
                <div class="input-group mb-1">
                   <input type="text" 
                    class="form-control" id="address" defaultValue={update.address} name="address" autoComplete="current-address" placeholder="Address" aria-label="address" aria-describedby="basic-addon1" />
                </div> 
              </div>
          </div>  
          <div className="row">
             <div className="col-12">
              <label>Address Link</label>
                <div class="input-group mb-1">
                   <input type="text" 
                    class="form-control" id="address" defaultValue={update.addressHyperlink} name="addressHyperlink" autoComplete="current-addressHyperlink" placeholder="link" aria-label="addressHyperlink" aria-describedby="basic-addon1" />
                </div> 
              </div>
          </div>
          <div className="row">
             <div className="col-12">
                <label>Phone Number</label>
                <div class="input-group mb-1">
                   <input type="text"   class="form-control" id="alternateNumber"  defaultValue={update.phone} name="phoneNumber" autoComplete="current-phoneNumber" placeholder="phoneNumber"  aria-label="phoneNumber" aria-describedby="basic-addon1" />
                </div>
             </div>
          </div>  
          <div className="row">
             <div className="col-12">
                <label>Alternate Number</label>
                <div class="input-group mb-1">
                   <input type="text" class="form-control" id="alternateNumber"  defaultValue={update.alternatePhone} name="alternateNumber" autoComplete="current-alternateNumber" placeholder="alternateNumber"  aria-label="alternateNumber" aria-describedby="basic-addon1" />
                </div>
             </div>
          </div>   
          <div className='row'>
          <div className="col-12">
                    <label className="create-label1">Subscription Zone <span className="required_star">*</span></label>
                    <div class="input-group mb-1">
                      <select class="custom-select height-select" required id="inputGroupSelect01"
                        name="incentiveZone"
                        label="incentiveZone"
                        autoComplete="current-incentiveZone"
                        defaultValue={update.zone===""?"Select Zone":update.zone}
                        >
                          <option value={"Select Zone"}>Select Zone</option>
                        {ElectricianIncentiveZone.map((option) => (
                          <option value={option.value}>{option.value}</option>
                        ))}
                      </select>
                    </div>
                  </div>
          </div>
          <div className="row">
             <div className="col-12">
              <label>City {city}</label>
              <div class="input-group mb-1" >
                 <SelectCityInput value={city} setValue={setCity}/>
             </div> 
            </div>  
            
          </div>
          
          <div className="row">
             <div className="col-12">
                    <label>Gst Name</label>
                    <div class="input-group mb-1">
                         <input type="text" class="form-control" defaultValue={update.gstName} id="gstName" name="gstName"  autoComplete="current-gstName" aria-describedby="basic-addon1" />
                    </div>
                </div>
          </div>      
          <div className="row">
             <div className="col-12">
                    <label>Gst Number</label>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control"  defaultValue={update.gstNumber} id="gstNumber" name="gstNumber" autoComplete="current-gstNumber"   aria-label="gstNumber" aria-describedby="basic-addon1" />
                    </div>
                </div>
             </div>  
              
             <div className="row">
             <div className="col-12">
                    <button type="submit" className='btn btn-primary'>Update</button>
                 </div>
             </div>                       
        </form> 
          </div>
          </div> 
          </div>: null
      }
    </>
  )
}
