import React, { useState } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'

export default function ChangeMultipleProblem({e,notify,reload,setReload}) {
    let [btnText,setBtnText] =useState("Change Multiple Problem")
  const changeMultipleProblem = ()=>{
     setBtnText("Processing...")
     setReload(!reload)
      axios.post(`${serverUrl.u}/api/tele/changeMultipleProblem`,{
        generatedHex:e.generatedHex
      }).then(function (res) {
         console.log(res);
         if(res.status===200){
            notify(res.data.message)
            setBtnText("Change Multiple Problem")
            setReload(!reload)
         }else{
            notify(res.data.message)
            setBtnText("Failed")
         }
      }).catch(function (err) {
         console.log(err);
         notify(err.message)
         setBtnText("Failed")
      })
  }

  return (
    <div>
        {
            e.MultipleProblem===true?
            <button className='btn btn-primary' onClick={()=>changeMultipleProblem()}>{btnText}    {e.MultipleProblemcount}</button>:
            <span>No Problem</span>
        }
      
    </div>
  )
}
