import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ConvertedLead.css";
import "./DispatchData.css";
import moment from "moment/moment";
import './Installation.css'
import Qutoes from "./Qutoes";
import CreateLead from "./CreateLead";
import {serverUrl} from './ApiUrl'
export default function DeadLead({wholeReload}) {
  let [details, setDetails] = useState([]);
  let [q, setq] = useState("");
  const [searchParam] = useState(["name"]);
  let [display,setDisplay] = useState(false)
  let [data,setData] = useState()
  let [reloadW,setReload] = useState(false)
  let [deletedData,setDeletedData] = useState()
  let [confirmationDis,setConfirmationDis] = useState(false)

  useEffect(() => {
    let data = async () => {
      await axios
        .post(
          `${serverUrl.u}/api/ecom/getAllTemporaryCart`
        )
        .then(function (response) {
          let detail = response.data.cart;
          console.log(detail);
          setDetails(response.data.cart);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    data();
  }, [reloadW,wholeReload]);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
 const handleEdit=(data1)=>{
  setDisplay(!display)
  setData(data1)
 }
 const handleDelete = async(data)=>{
  console.log(data);
    await axios.post(`${serverUrl.u}/api/ecom/deleteTemporaryCart`,
    {
      subId:data._id
    }
    ).then(function (response) {
      console.log(response);
      console.log(data._id);
      setConfirmationDis(false)
      setReload(!reloadW)
    }).catch(function (error) {
      console.log(error);
    })
 }
  console.log(details);
  return (
    <div  style={{padding:"0 4%",marginTop:"4vh"}}>
      {
        display===false?
      <>
      
      <div className="input-field">
        <input
          type="text"
          value={q}
          placeholder="Search here...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
      <div className='table-container1'>
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th scope="col" >Name</th>
            <th scope="col" style={{width:"150px"}}>Email</th>
            <th scope="col">Plan</th>
            <th scope="col">Phone</th>
            <th scope="col">Plan Duration</th>
            <th scope="col">City</th>
            <th scope="col">Page</th>
            <th scope="col">Created At</th>
            <th colSpan="2">Action</th>
        
          </tr>
        </thead>
        <tbody>
          {details !== 0
            ? search(details).map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{fontWeight:"bold"}}>{e.name}</td>
                    <td>{e.email}</td>
                    <td>{e.plan}</td>
                    <td>{e.phone}</td>
                    <td>{e.frequency}</td>
                    <td>{e.city}</td>
                    <td>{e.page}</td>
                    <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                    <td><button className="btn " onClick={()=>{handleEdit(e)}}><img className='edit-delete-icon' src={process.env.PUBLIC_URL+"assets/icon/Edit.png"}/></button></td>
                    <td><button className="btn " onClick={()=>{ setDeletedData(e)
                      setConfirmationDis(!confirmationDis)}}><img className='edit-delete-icon' src={process.env.PUBLIC_URL+"assets/icon/Delete.png"}/></button></td>
                </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
      { confirmationDis===true?
  <div className='confirmation-popup'>
    <div className='confirmation-popup-heading'>Confirmation to Delete {deletedData.name}</div>
    <div className='confirmation-popup-Subheading'>Are your sure you want to delete</div>
    <button className='btn btn-danger' onClick={()=>{setConfirmationDis(false)}}>No</button>
    <button className='btn btn-primary ml-5'onClick={()=>{handleDelete(deletedData)}}>Yes</button>
  </div>:null
}
    <Qutoes/>
    </>:<CreateLead dataEdit={data}/>
            }
    </div>
  );
}
