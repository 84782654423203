import React, { useState,useRef } from 'react'
import moment from 'moment'
import axios from 'axios'
import { pages, serverUrl } from '../ApiUrl'
import { useSelector } from 'react-redux'
import { updateInstallationData,InstallationDataApi } from '../Redux/installationActions'
import { useDispatch } from 'react-redux'
import { updateAddressLayout } from '../Redux/Action/adderess&layout'
import { subHistory } from './SubscriptionHistory'
export default function ChangeInstallationDate({e,setReload,reload,notify,decision,page="any"}) {
  
  let loginName = useSelector((state) => state.sar.data.user.first_name)
    let [updateInstallation,setUpdateInstallation] = useState(false)
    const [timeHave,setTimeHave] = useState(true)
    let dispatch = useDispatch()
    let dates =useRef(new Date())
    const changeDate = async(data)=>{
        await axios.post(`${serverUrl.u}/api/tele/changeInstallationDate`,{
            generatedHex:e.generatedHex,
            InstallationDate:data,
            loggingPerson:loginName,
          }).then(function (response) {
            if(response.data.success===true){
          let detail=response;
          console.log(detail);
           subHistory(`Installation Date Change on this page ${page}`,e,"Change installation date",data)
          if(page===pages.installation){
            dispatch(updateInstallationData(response.data.data))
            InstallationDataApi(dispatch)
          }else if(page===pages.addressLayout){
            dispatch(updateAddressLayout(response.data.data))
          }
          else{
            setReload(!reload)
          }
          setUpdateInstallation(false)
          notify(response.data.message)
            }else{
              notify(response.data.message)
            }
        }).catch(function (error) {
          console.log(error);
          notify(error.response.data.message)
        })
    }
    
  return (
    <>
    {
      decision=="btn"?
      <button className='btn btn-primary' onClick={()=>setUpdateInstallation(true)}>Installation Date</button>:
      <td onClick={()=>setUpdateInstallation(true)}>{moment.utc(e.InstallationDate).format('LT')!=="12:00 AM"?moment.utc(e.InstallationDate).format("llll"):moment.utc(e.InstallationDate).format("DD-MMM-yyyy")}</td>
    }
    

     {
        updateInstallation === true ?
          <div className="chnageDate">
            <span className="cross" onClick={() => setUpdateInstallation(!updateInstallation)}>x</span>
            <label className="create-label1">Installation Time? <span className="required_star">*</span></label>
                      <div class="input-group mb-1">
                        <select class="custom-select height-select" required id="inputGroupSelect01"
                          name="time"
                          label="time"
                          autoComplete="current-time"
                          defaultValue={"true"}
                          onChange={(e)=>setTimeHave(e.target.value==="false"?false:true)}
                        >
                            <option value={"true"}>{"Yes"}</option>
                            <option value={"false"}>{"No"}</option>
                        </select>
                      </div>
                      {
                        timeHave ?
                        <div className="input-group mb-3 ">
                        <input type="datetime-local" class="form-control" ref={dates} id="outlined" label="date"  aria-describedby="basic-addon1" placeholder='Date'  />
                      </div>:
                        <div className="input-group mb-3 ">
                        <input type="date" class="form-control" ref={dates} id="outlined" label="date"  aria-describedby="basic-addon1" placeholder='Date'  />
                      </div>
                      }
            
           
                <button className="btn btn-primary" onClick={() => changeDate(dates.current.value)}>Update Date</button>
           
          </div> : null
      }
    </>
  )
}
