const title = ["Mr.","Mrs.","Ms.","Dr."]

 const fixedAppliances = {
    room1: 4,
    bhk1: 8,
    bhk2: 12,
    bhk3: 18,
    bhk4: 22,
    bhk5: 26
  };

   const planName = {
    4: "1 Room" ,
    8: "1 BHK",
    12: "2 BHK",
    18: "3 BHK",
    22: "4 BHK",
    26: "5 BHK",
    30: "6 BHK",
    0:"Automation System"
   };

   const typeOfCharges = [
    "Installation","Freight"
   ]
   const termCondition = {
     onTime:"1. The quote encompasses access for an unlimited number of users.\n2. Payments are to be made payable to Aliste Technologies, and please be aware that all charges are non-refundable.\n3. Every device from Aliste includes a two-year warranty\n",
    subscription:"1. The Security Deposit charged is 100% refundable on termination of the smart home subscription\n2. The quote is inclusive of unlimited number of user access.\n3. A single smart home package can not be split among multiple homes / properties.\n4. All payments must be made in favour of Aliste Technologies, the subscription fees & installation charges are non-refundable\n5. All Aliste devices come with life-time warranty\n",
   }
   const activeColor = "#4765be"
  
export const clientData ={
    title,
    fixedAppliances,
    planName,
    activeColor,
    typeOfCharges,
    termCondition
}
