import { Button } from "@mui/material";
import React from "react";
import ModelCommon from "../Common/ModelCommon";
import axios from "axios";
import { pages, serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";
import { useDispatch } from "react-redux";
import { updateAddressLayout } from "../Redux/Action/adderess&layout";
import { updateInstallationData } from "../Redux/installationActions";
import { subHistory } from "../Function/SubscriptionHistory";
export const siteVistcreateds = async (sub) => {
  return await axios
    .post(`${serverUrl.u}/app/elec/updateSiteVisite`, {
      generatedHex: sub.generatedHex,
    })
    .then((res) => {
      return { res: res };
    })
    .catch((err) => {
      return { err: err };
    });
};
function SiteVisitCreated({ sub, page, btnName, text }) {
  const dispatch = useDispatch();
  const siteVistcreated = async () => {
    let res = await siteVistcreateds(sub);
    console.log("SITE vistit created res - ", res);
    if (res.res !== undefined) {
      if (res.res.data.success === true) {
        notify(res.res.data.message);
        if (page === pages.addressLayout) {
          dispatch(updateAddressLayout(res.res.data.data));
          subHistory(
            `${
              sub.siteVisiteByElectrician
                ? "Site visite revoke"
                : "Site visite set"
            }`,
            sub,
            "Site visite",
            sub.InstallationDate
          );
        } else if (page === pages.installation) {
          dispatch(updateInstallationData(res.res.data.data));
          subHistory(
            `${
              sub.siteVisiteByElectrician
                ? "Site visite revoke"
                : "Site visite set"
            }`,
            sub,
            "Site visite",
            sub.InstallationDate
          );
        } else if (page === pages.elctrician) {
          subHistory(
            "Site visite to installation",
            sub,
            "Site visite",
            sub.InstallationDate
          );
          window.location.reload();
        }
      } else {
        notifyFailed(res.res.data.message);
      }
    } else if (res.err !== undefined) {
      notifyFailed(res.err.response.data.message);
    }
  };

  return (
    <>
      <ModelCommon
        agree={siteVistcreated}
        btnName={btnName}
        text={text}
        page={page}
      />
    </>
  );
}

export default SiteVisitCreated;
