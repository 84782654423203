import { dummy, planCost } from "../../Data"
import { subHistory } from "../../Function/SubscriptionHistory"
import { notify } from "../../Common/Notification"
import { serverUrl } from "../../ApiUrl"
import store from '../../../component/Redux/index'
import axios from "axios"


export const handleChangeAppliances = (appliance) => {
    let installation = 0
    let security = appliance * 100
    if (appliance <= 10) {
        installation = 1000
    } else {
        let app = Number(appliance) - 10
        installation = (app * 50) + 1000
    }
    return { installation, security }
}

export const handleCalculateFee = (planDurations, qu, security, installation) => {
    let fee = 0
    if (planDurations === "Monthly") {
        fee = (qu * planCost.Monthly) * 30
    } else if (planDurations === "Quarterly") {
        fee = (qu * planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
        fee = (qu * planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
        fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
        fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
        fee = (qu * planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
        fee = (qu * planCost.TwoYears) * (365 * 2);
    } else if (planDurations === "Three Years") {
        fee = (qu * planCost.ThreeYears) * (365 * 3);
    }
    let totalAmount = (fee + installation) * 1.18 + security
    let tax = (fee + installation) * 0.18
    return { fee, totalAmount, tax }

}
const downloadFile = (blob, fileName,setQutoBtnText) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    setQutoBtnText("Download")
    // in case the Blob uses a lot of memory
    // setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };
const quotationApi = async (data, userData,setQutoBtnText,token) => {
    let name = `${userData.first_name} ${userData.last_name}`
    console.log(data, 'quote num');
    let seq = 0;
    let ins = 0;
    let qu = userData.totalAppliances;
    let planNamess = "1 Room"
    let planDurations = userData.planType;
    let fee = userData.recursiveAmount;
   
    let total = userData.recursiveAmount
    let tax = Number(userData.totalAmount)*0.18
    let totalTax = total + tax;
    let to = totalTax;
    await axios.post(`${serverUrl.s}/misc/getQuotation`,
      {
        quoteTo: name,
        quoteNo: data,
        itemsQuantity: { appliances: qu },
        packagePlanDetails: {
          packageName: planNamess === undefined ? "1 Room" : planNamess,
          planName: planDurations,
          security: seq,
          installation: ins,
          fee: fee,
          total: total,

        },
        amounts: {
          subtotal: total,
          tax: tax,
          total: totalTax
        },
        heavyAppliance: {
          count: 0,
          total: 0,
        }
      }, {
      responseType: 'blob',
      headers: {
        AccessToken: token
      }
    }).then(function (response) {
      downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`,setQutoBtnText)
    }).catch(function (error) {
      console.log("quto   ", error);
    })

  }
export const registerLeadAndLink = (userData,setQutoBtnText,setReloadLink,setErrorApi,setok,setOkBtn,token,setOpen) => {
    setQutoBtnText("Processing...")
   
    let data1 = async () => {
        await axios.post(
            `${serverUrl.u}/api/zoho/registerLeadAndLink`,
            {
                planId: userData.planId,
                planFrequency: userData.planType,
                refferal: userData.refferal !== "" ? userData.refferal : "empty",
                email: userData.email,
                first_name: userData.first_name,
                last_name: userData.last_name,
                phone: userData.phone,
                city: userData.city,
                zip: userData.zip,
                address: userData.address,
                teleCallerId: userData.teleCallerId,
                comments: userData.comments,
                leadQuality: userData.leadQuality,
                origin: userData.origin,
                layout: dummy[1],
                security: userData.security,
                installation: userData.installion,
                partialPayment: true,
                secondarySalesPerson: userData.teleCallerSecond,
                discount: 0,
                orignalRecursiveAmount: userData.orignalRecursiveAmount,
                orignalTotalAmount: Number(userData.orignalTotalAmount),
                recursiveAmount: userData.recursiveAmount,
                totalAmount: Number(userData.orignalTotalAmount),
                addOnAmount: 0,
                totalAppliances: userData.totalAppliances,
                addOnSubscriptionTo: "empty",
                partialAmount: Number(userData.partialAmount),
                paymentMode: userData.paymentMode,
                reference_id: userData.reference_id,
                zone: userData.incentiveZone,
                ispSubscription:userData.ispSubscription
            }).then(async function (resp) {
                if (resp.data.success === true) {
                    subHistory("Client Register", resp.data.data, "Client Register", "New Client")
                    // await quotationApi(resp.data.data.quotationNumber, userData,setQutoBtnText,token);
                    setOpen(true)
                    setOkBtn(true)
                    setok(true)
                    // setWhatappNumber(userData.phone)
                    setReloadLink(true)
                    setQutoBtnText("Done")
                } else {
                    setQutoBtnText("Done")
                    setReloadLink(true)
                    setErrorApi(true)
                }
            }).catch(function (error) {
                console.log(error);
                setErrorApi(true)
                setQutoBtnText("Failed")
            })
    };
    if (userData.planId === 0) {
        dummy[userData.planId][0].switchboards[0].appliances.fans = Number(userData.totalAppliances);
        data1();
    } else {
        data1();
    }
}

export const countApplianceInHouse = async  (sub) =>{
  return await axios.post(`${serverUrl.u}/api/tele/countApplianceInHouse`, {
        houseId: sub.houseId,
        generatedHex: sub.generatedHex
      }).then(async function (res) {
        return {res,type:1}
    }).catch(async(error)=>{
        return {res:error,type:0}
    })
}