import { locationTechniciansAction } from "../actionIndex";



export const locationLogElectrician = (state={},action) =>{
    const {type,payload} = action
    switch (type) {
        case locationTechniciansAction.LOAD:
           return {...state,[payload]:true}
    
        default:
            return state
    }
}