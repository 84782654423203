import axios from 'axios'
import React from 'react'

export default function ZohoWebHook() {
    const hex = "8852bb"
    const callWbhook = ()=>{
        axios.post("https://test.alistetechnologies.com:8443/api/chargebee/webhook",{
            "created_time": "2023-08-28T08:34:10+0530",
            "event_id": "3457590000002783188",
            "event_type": "subscription_created",
            "data": {
             "subscription": {
              "next_shipment_day": "",
              "updated_time": "2023-08-28T08:34:10+0530",
              "zcrm_potential_id": "",
              "trial_extended_count": 0,
              "place_of_supply": "UP",
              "addons": [],
              "next_billing_at": "2023-09-20",
              "taxes": [
               {
                "tax_amount": 43.2,
                "tax_name": "CGST9 (9%)",
                "tax_id": "3457590000000081101"
               },
               {
                "tax_amount": 43.2,
                "tax_name": "SGST9 (9%)",
                "tax_id": "3457590000000081103"
               }
              ],
              "discount": 0,
              "subscription_id": "34575900000014365735",
              "gst_no": "",
              "end_of_term": false,
              "product_id": "3457590000000075213",
              "plan": {
               "setup_fee_tax_exemption_code": "",
               "discount_amount": 0,
               "setup_fee_tax_percentage": "",
               "plan_code": "a1",
               "description": "Charges for the mentioned duration ",
               "discount": 0,
               "setup_fee_tax_id": "",
               "total": 480,
               "internal_name": "",
               "setup_fee_tax_name": "",
               "discounts": [],
               "price": 30,
               "hsn_or_sac": "85365090",
               "setup_fee_tax_type": "",
               "pricing_scheme": "unit",
               "setup_fee": 0,
               "quantity": 16,
               "tax_name": "GST18",
               "setup_fee_tax_exemption_id": "",
               "item_custom_fields": [],
               "tax_id": "3457590000000081139",
               "unit": "pcs",
               "product_type": "",
               "tax_type": "tax_group",
               "name": "Subscription Fee (Monthly)",
               "tax_percentage": 18,
               "discount_formatted": "₹0.00",
               "plan_id": "3457590000000079005"
              },
              "pricebook_id": "",
              "tax_specification": "intra",
              "shipping_interval_unit": "months",
              "exchange_rate": 1,
              "orders_remaining": 0,
              "auto_apply_credits": [],
              "apply_changes_on": "",
              "bcy_amount": 566.4,
              "is_metered_billing": false,
              "interval": 1,
              "discount_percent": 0,
              "crm_owner_id": "",
              "status": "live",
              "items_associated": [],
              "discount_total": 0,
              "created_at": "2023-04-20",
              "last_billing_at": "2023-08-20",
              "currency_code": "INR",
              "can_prorate": true,
              "expires_at": "",
              "interval_unit": "months",
              "end_of_term_scheduled_date": "",
              "is_advance_invoice_present": false,
              "tax_rounding": "entity_level",
              "start_date": "2023-04-20",
              "subscription_number": "SUB-00988",
              "currency_symbol": "₹",
              "reactivate_date": "",
              "current_term_ends_at": "2023-09-20",
              "total_orders": 0,
              "discount_type": "item_level",
              "child_invoice_id": "34575900000027101798",
              "auto_collect": true,
              "paytm": {
               "payment_gateway": "paytm",
               "gateway_reference_id": "100616085871",
               "payment_method": "upi"
              },
              "customer_id": "34575900000014365745",
              "is_taxable": true,
              "pause_date": "",
              "is_reverse_charge_applied": false,
              "payment_terms_label": "Due On Receipt",
              "invoice_notes": "Thanks for your business.",
              "notes": [],
              "reference_id": hex,
              "coupon_duration": "",
              "last_modified_by_id": "3457590000000075001",
              "created_time": "2023-04-20T16:09:53+0530",
              "gst_treatment": "consumer",
              "is_inclusive_tax": false,
              "custom_fields": [],
              "last_shipment_at": "",
              "product_name": "Aliste Technologies",
              "can_invoice_prorated_amount": true,
              "activated_at": "2023-04-20",
              "name": "Aliste Technologies-Subscription Fee (Monthly)",
              "tax_treatment": "consumer",
              "zcrm_potential_name": "",
              "created_by_id": "",
              "resume_date": "",
              "billing_mode": "prepaid",
              "is_discount_before_tax": true,
              "channel_reference_id": "",
              "shipping_interval": 1,
              "payment_terms": 0,
              "scheduled_cancellation_date": "",
              "can_add_bank_account": false,
              "custom_field_hash": {},
              "last_shipment_day": "",
              "next_shipment_at": "",
              "payment_gateways": [
               {
                "payment_gateway": "paytm",
                "gateway_name": "paytm"
               }
              ],
              "bcy_discount_total": 0,
              "amount": 566.4,
              "remaining_billing_cycles": -1,
              "discount_applied_on_amount": 0,
              "current_term_starts_at": "2023-08-20",
              "salesperson_name": "AASTHA",
              "pending_addons": [],
              "salesperson_id": "3457590000000109570",
              "contactpersons": [
               {
                "zcrm_contact_id": "",
                "phone": "",
                "mobile": "",
                "last_name": "",
                "first_name": "Test ",
                "email": "Test@everonnvalue.com",
                "contactperson_id": "3457590000001436576"
               }
              ],
              "channel_source": "app",
              "sub_total": 480,
              "created_date": "2023-04-20",
              "allow_partial_payments": false,
              "is_scheduled_update": false,
              "orders_created": 0,
              "customer": {
               "channel_reference_id": "",
               "billing_address": {
                "zip": "",
                "country": "India",
                "city": "",
                "phone": "",
                "street": "",
                "attention": "",
                "street2": "",
                "state": "Uttar Pradesh",
                "fax": hex
               },
               "payment_terms": 0,
               "zcrm_contact_id": "",
               "gst_no": "",
               "custom_field_hash": {},
               "tax_reg_no": "",
               "shipping_address": {
                "zip": "",
                "country": "India",
                "city": "",
                "phone": "",
                "street": "",
                "attention": "",
                "street2": "",
                "state": "Uttar Pradesh",
                "fax": hex
               },
               "first_name": "Test ",
               "email": "Test@everonnvalue.com",
               "tax_specification": "intra",
               "gst_treatment": "consumer",
               "website": "",
               "zcrm_account_id": "",
               "custom_fields": [],
               "tax_name": "",
               "last_name": "",
               "vat_reg_no": "",
               "ip_address": "",
               "display_name": "Test  ",
               "channel_customer_id": "",
               "tax_id": "",
               "country_code": "UP",
               "company_name": "",
               "tax_percentage": "",
               "tax_treatment": "consumer",
               "salutation": "",
               "customer_id": "34575900000014365745",
               "is_taxable": true,
               "payment_terms_label": ""
              }
             }
            },
            "event_time_formatted": "28/08/2023",
            "event_source": "api",
            "event_time": "2023-08-28"
        }).then(function (res) {
             console.log(res);
        }).catch(function (err) {
            console.log(err);
        })
    }
  return (
    <button onClick={()=>callWbhook()}>ZohoWebHook</button>
  )
}
