import React from 'react'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';

import './Deleteanddrop.css'
import { notify, notifyFailed } from '../Common/Notification';

export default function SubscriptionPause({e,setReload,reloadW}) {
    const subscriptionPause = async()=>{
        await axios.post(`${serverUrl.u}/api/tele/setInvoicePause`,{
            generatedHex :e.generatedHex,
        }).then(function (res) {
          console.log(res);
          if(res.status===200){
            notify("Subscription Paused")
           setReload(!reloadW)
          }else{
            notifyFailed("Failed")
          }
        }).catch(function (er) {
          console.log(er);
          notifyFailed("Failed")
        })
      }
  return (
    <>
   
          <button className="btn btn-primary width" onClick={()=>{subscriptionPause()}} >
                Subscription Pause
          </button>
     
    
    </>
  )
}
