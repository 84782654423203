import axios from 'axios'
import moment from 'moment'
import React, { useRef } from 'react'
import { serverUrl } from '../ApiUrl'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { getFirstandLastDate } from '../Common/applianceDifference'

export default function ElectricianIncentive() {
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)
  let startDate = useRef(new Date())
  let endDate = useRef(new Date())
  let [data, setData] = useState({})

  const getIncentive = () => {
    let gg = new Date(startDate.current.value)
    let data = getFirstandLastDate(startDate.current.value)
    setData({})

    axios.post(`${serverUrl.u}/app/elec/getIncentiveOfElectrician`, {
      "employeeId": employeeId,
      "firstDate": gg,
      "lastDate": data.lastDay
    }).then(function (res) {
      console.log(res);
      if (res.data.success === true) {
        if (res.data.data.success === true) {
          setData(res.data.data.data.incentiveObject)
        }
      }
    }).catch(function (err) {
      console.log(err);
    })
  }
  return (
    <div style={{ padding: "0 1%", marginTop: "4vh" }}>
      <div className='row'>

        <div className="col-md-2 col-sm-5">
          <label className="create-label1">Month</label>
          <div class="input-group ">
            <input type="month" ref={startDate} style={{ width: "100%" }} />
          </div>
        </div>

        <div className="col-md-1 col-sm-5">
          <button className='btn btn-primary mt-3' onClick={() => getIncentive()}>Get Data</button>
        </div>


      </div>
      <div style={{ marginTop: "20px" }}>


        <div style={{ marginBottom: "10px" }}>
          <table className=" table-bordered " style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td className="specific-title" >Total Appliances</td>
                <th className="specific-data">{data?.countofappliances}</th>
              </tr>
              <tr>
                <td className="specific-title">Appliance Incentive</td>
                <th className="specific-data">{data?.applianceIncentive}</th>
              </tr>
              <tr>
                <td className="specific-title">Upgrade Appliances</td>
                <th className="specific-data">{data.countUpgradeAppliances}</th>
              </tr>
              <tr>
                <td className="specific-title">Upgrade Appliance Incentive</td>
                <th className="specific-data">{data.upgradeApplianceIncentive}</th>
              </tr>
              <tr>
                <td className="specific-title">Grievance Site</td>
                <th className="specific-data">{data.countOfGrievanceSite}</th>
              </tr>
              <tr>
                <td className="specific-title">Grievance Incentive</td>
                <th className="specific-data">{data.grievanceIncentive}</th>
              </tr>  <tr>
                <td className="specific-title">B2B Appliances</td>
                <th className="specific-data">{data.countOfB2BAppliances}</th>
              </tr>  <tr>
                <td className="specific-title">B2B Incentive</td>
                <th className="specific-data">{data.countOfB2BIncentive}</th>
              </tr>  <tr>
                <td className="specific-title">Total Incentive</td>
                <th className="specific-data">{data.totalIncentive}</th>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div style={{ marginBottom: "60px", height: "60px" }}></div>

    </div>
  )
}
