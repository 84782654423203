import React, { useState, useEffect, Suspense } from 'react';
import './Main.css';
import CreateLead from './CreateLead';
import PaymentLinkGen from './Address&Layout/PaymentLinkGen';
import ReadyDispatch from './ReadyDispatch';

import Installation from './Installation/Installation';
import ElectricianZone from './Zone/ElectricianZone';
import NotPaid from './NotPaid';
import Installed from './Installation/Installed';
import DeadLead from './DeadLead';
import MakeBill from './Client registation/MakeBill';
import MakeQuto from './Client registation/MakeQuto';
import PartialPayment from './PartialPayment';
import PaidOrderStatus from './PaidOrderStatus';
import TopHeader from './TopHeader';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import Quotation from './Quotation/Quotation';
import ReactTooltip from 'react-tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import Estimate from './Estimate';
import { useSelector } from 'react-redux';
import CrmQuto from './CrmQuto';
import InvoiceDownload from './InvoiceGenerated';
import MandatePaidOrderStatus from './MandatePaidOrderStatus';
import SpecificElectrician from './SpecificElectricianUpGradeDownGrade.js/SpecificElectrician';
import PickSiteByElectrician from './SpecificElectricianUpGradeDownGrade.js/PickSite/PickSiteByElectrician';
import SearchClient from './SearchClient';
import IncentiveCalculator from './IncentiveCalculator';
import NewTeleCallerIncentive from './NewTeleCallerIncentive';
import ProductCreation from './ProductCreation';
import ProductSelling from './ProductSelling';
import ProductSell from './Product/ProductSelling';
import YesterDaySales from './YesterDaySales';
import {
  getAllDepartmentRequestsParticular,
  countOverDue,
} from './Function/AxiosControl';
import ClientRegister from './Client registation/ClientRegister';
import Lead from './Lead';
import AssignLead from './Lead/AssignLead';
import LeadCreate from './Lead/LeadCreate';
import DepartmentRequests from './DepartmentRequests';
import MulipleSubscription from './MulipleSubscription';
import WebsiteLead from './WebSiteLead';
import ActiveOverDueInvoice from './Invoice/ActiveOverDueInvoice';
import RefundRecord from './RefundRecord';
import IspClientRegister from './Isp/ClientRegister/ClientRegister';
import DropClients from './DropClients';
import Feedback from './FeedBack/Feedback';
import ElectricianLeave from './ElectricianLeave/ElectricianLeave';

import SingleClientSubscription from './SingleClientSubscription';
import Dasboard from './DashBord/Dasboard';
import Grivance from './OnCallGrivance/Grivance';
import Suggestion from './Suggestion/Suggestion';
import GrivanceDepartment from './OnCallGrivance/GrivanceDepartment';
import ClientUpgrade from './ClientUpgrageRequest/ClientUpgrade';
import UnInstallationDepartment from './UnInsallationDepartment.js/UnInstallationDepartment';
import ElectricianIncentive from './SpecificElectricianUpGradeDownGrade.js/ElectricianIncentive';
import Movement from './SpecificElectricianUpGradeDownGrade.js/Movement/Movement';
import AllElectricianIncentive from './AllElectricianIncentive';
import Leave from './SpecificElectricianUpGradeDownGrade.js/Leave/Leave';
import AllElectricianSiteStatus from './SpecificElectricianUpGradeDownGrade.js/SiteStatus/AllElectricianSiteStatus';
import ElectricianUpsell from './SpecificElectricianUpGradeDownGrade.js/UpSell/ElectricianUpsell';

import { user_roles } from './Data';
import RegisterIsp from './Isp/RegisterIsp/RegisterIsp';
import EditIsp from './Isp/EditIsp/EditIsp';
import WalletBalance from './Isp/WalletBalance/WalletBalance';
import IspPaidClient from './Isp/Clients/PaidClients';
import IspPartialClient from './Isp/Clients/NotPaidClients';
import IspInvoice from './Isp/Invoice/Invoice';
import Retention from './Retention/Retention';
import NumbersDashboard from './Numbers/NumbersDashboard';
import MarkingAddStatus from './Marketing/MarkingAddStatus';
import TeleCallerIncentive from './Address&Layout/TeleCallerIncentive';
import AssignLeadV2 from './Lead/AssignLeadV2';
import Numbers from './Numbers/Numbers';
import NewClientRegister from './Client registation/NewClientRegister';
import FreeClient from './FreeClients/FreeClient';
import LeadsAnalysis from './Marketing/LeadsAnalysis';
import InstallationReport from './Marketing/InstallationReport';
import LeadsConsumed from './Marketing/LeadsConsumed';
import PageNatedInstallationPage from './Installation/PageNatedInstallationPage';
import NewLeadAnalysis from './Marketing/NewLeadsAnalysis';
import Notification from './Notification/notification';
import MacId from './MacId/MacId';
import Referral from './Referral/Referral';
import FinancialInvoice from './FinancialInvoice';
import Map from './MacId/Map';
import ElectricianWiseMap from './MacId/ElectricianWiseMap';
import ElectricianKms from './SpecificElectricianUpGradeDownGrade.js/ElectricianKms/ElectricianKms';
import UpsellItem from './UpsellItem/UpsellItem';
import SecurityReceipt from './Invoice/SecurityReceipt';
import CreditNote from './Invoice/CreditNote';
import Users from '../pages/users/Users';
import PerformaInvoice from '../pages/performa/PerformaInvoice';
import {
  Description,
  MapsHomeWork,
  People,
  Person,
  PersonOff,
} from '@mui/icons-material';
import Customers from '../pages/Customer/Customers';
import AllPaidClients from './AllPaidClients';
import Invoices from '../pages/invoice/Invoices';
import DroppedClient from './droppedClient/DroppedClient';
import House from '../pages/house/House';
import PayTransaction from './Invoice/PayTransaction';
// const Installation=React.lazy(()=>import('./Installation/Installation'))
export default function Main() {
  let [allRequestCount, setAllRequestCount] = useState(0);
  let [overDueCount, setOverDueCount] = useState(0);

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  useEffect(() => {
    let d = 0;
    let count = async () => {
      d = await countOverDue();
      setOverDueCount(d);
    };
    // count();
    let data = [];
    let fun1 = async () => {
      data = await getAllDepartmentRequestsParticular('All', 1);
      setAllRequestCount(data.requestLen);
    };
    fun1();
  }, []);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);

  const navigate = useNavigate();
  useEffect(() => {
    if (userRole === undefined || userRole.length === 0) {
      navigate('/');
    }
  }, []);
  let { roots } = useParams(null);
  let [wholeReload, setWholeReload] = useState(false);
  let [tab, selectTap] = useState(roots);
  let [sideMenu, setSideMenu] = useState(true);
  let [url_img, setUrl_img] = useState('');
  let [tabText, setTabText] = useState(
    roots === 'Subscriptions' || roots === 'Ready'
      ? roots === 'Subscriptions'
        ? 'Address&Layout'
        : 'Dispatch'
      : 'Genrate Payment Link'
  );
  let [displayTab, setDisplayTab] = useState();
  let loginName = useSelector((state) => state.sar.data?.user?.first_name);
  let employeeId = useSelector((state) => state.sar.data?.user?.employeeId);
  useEffect(() => {
    if (userRole?.includes('Super Admin')) {
      setDisplayTab('Super Admin');
      selectTap('NumbersDashboard');
      navigate('/NumbersDashboard');
      setTabText('Dashboard');
      setUrl_img('assets/icon/search _black.png');
    } else if (
      userRole?.includes('Dispatch') &&
      userRole?.includes('Installation')
    ) {
      setDisplayTab('Dispatch & Installation');
      setTabText('Installation');
      selectTap('Installation');
      navigate('/Installation');
      setUrl_img('assets/icon/Installation Black.png');
    } else if (userRole?.includes(user_roles.Isp)) {
      setDisplayTab('Client Register');
      selectTap('Client_Register_isp');
      navigate('/Client_Register_isp');
      setTabText('Client Register');
      setUrl_img('assets/icon/search _black.png');
    } else if (userRole.includes(user_roles.ExternalTeleCaller)) {
      setDisplayTab('Client Register');
      selectTap('Client_Register_External');
      navigate('/Client_Register_External');
      setTabText('Client Register');
      setUrl_img('assets/icon/search _black.png');
    } else {
      if (userRole?.includes('Dispatch')) {
        setDisplayTab('Dispatch');
        selectTap('Installation');
        navigate('/Installation');
        setTabText('Installation');
        setUrl_img('assets/icon/Installation Black.png');
      } else if (userRole?.includes('Installation')) {
        setDisplayTab('Electrician');
        setTabText('Electrician');
        selectTap('Electrician');
        setUrl_img('assets/icon/Installation Black.png');
      } else if (userRole?.includes('Telecaller')) {
        setDisplayTab('Telecaller');
        selectTap('Search_Client');
        setTabText('Search Client');
        setUrl_img('assets/icon/search _black.png');
      } else if (userRole?.includes('External Telecaller')) {
        setDisplayTab('External Telecaller');
        selectTap('Genrate_Payment_Link_Et');
        setTabText('Genrate Payment Link');
        setUrl_img('assets/icon/Generate Payment Link Black.png');
      }
    }
    if (roots) {
      selectTap(roots);
      navigate(`/${roots}`);
      setTabText(roots);
    }
  }, [userRole]);
  const Leadpage = () => {
    selectTap('Lead_Register');
    navigate('/Lead_Register');
    setTabText('Lead Register');
    setUrl_img('assets/icon/Generate Payment Link Black.png');
  };
  const InstallationPage = () => {
    selectTap('Installation');
    navigate('/Installation');
    setTabText('Installation');
    setUrl_img('assets/icon/Installation Black.png');
  };
  const ElectricanUpSellPage = () => {
    selectTap('Electrician');
    navigate('/Electrician');
    setTabText('Electrician');
    setUrl_img('assets/icon/Installation Black.png');
  };
  return (
    <>
      <TopHeader
        Page_name={tabText}
        url_img={url_img}
        selectTap={selectTap}
        setSideMenu={setSideMenu}
        sideMenu={sideMenu}
        setDisplayTab={setDisplayTab}
        displayTab={displayTab}
        wholeReload={wholeReload}
        setWholeReload={setWholeReload}
      />
      <div className='top-container'>
        {sideMenu === true ? (
          <div className='left-section'>
            <div style={{ marginTop: '4vh' }} className='tab-name'>
              {['1015', '1016'].includes(employeeId) && (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Search_Client');
                      navigate('/Search_Client');
                      setTabText('Search Client');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Search Client'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Search Client</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Installation');
                      navigate('/Installation');
                      setTabText('Installation');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Installation'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Installation</span>
                  </div>
                </>
              )}
              {(userRole?.includes('Super Admin') ||
                userRole?.includes('Telecaller') ||
                userRole?.includes('Dispatch')) &&
                !userRole.includes(user_roles.Installation) && (
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Search_Client');
                      navigate('/Search_Client');
                      setTabText('Search Client');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Search Client'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Search Client</span>
                  </div>
                )}
              {(userRole?.includes('Super Admin') ||
                employeeId === '1015' ||
                employeeId === '1002' ||
                userRole?.includes('Telecaller')) && (
                <>
                  {/* <div
                    className='main-text'
                    onClick={() => {
                      selectTap('ElectricianLocation');
                      navigate('/ElectricianLocation');
                      setTabText('Electrician Location');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Electrician Location'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Electrician Location</span>
                  </div> */}
                  {/* <div
                    className='main-text'
                    onClick={() => {
                      selectTap('ElectricianMap');
                      navigate('/ElectricianMap');
                      setTabText('Electrician Map');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Electrician Map'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Electrician Map</span>
                  </div> */}
                </>
              )}
              {(userRole?.includes('Super Admin') ||
                userRole?.includes('Telecaller')) &&
                !userRole.includes(user_roles.Installation) && (
                  <>
                    <div className='btn-group dropright main-text main-pos'>
                      <div
                        type='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        className='drop-btn'
                      >
                        <div className='icons-custom' data-tip='Search Client'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='paid'
                          />
                        </div>
                        <div className='NavBar-title  dropdown-toggle'>
                          Automation
                        </div>
                      </div>
                      <div
                        className='dropdown-menu'
                        aria-labelledby='dropdownMenu2'
                      >
                        <div
                          className='Sub-menu dropdown-item'
                          onClick={() => {
                            selectTap('Quote');
                            navigate('/Quote');
                            setTabText('Make Quote');
                            setUrl_img('assets/icon/Make Bill Black.png');
                          }}
                        >
                          <span className='icons-custom' data-tip='Make Quote'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                'assets/icon/Make Bill Black.png'
                              }
                              alt='logo'
                            />
                          </span>
                          <span className='NavBar-title'>Make Quote</span>
                        </div>

                        <div
                          className='Sub-menu dropdown-item'
                          onClick={() => {
                            selectTap('Estimate');
                            navigate('/Estimate');
                            setTabText('Estimate');
                            setUrl_img('assets/icon/budgeting_black.png');
                          }}
                        >
                          <span className='icons-custom' data-tip='Estimate'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                'assets/icon/budgeting_black.png'
                              }
                              alt='logo'
                            />
                          </span>
                          <span className='NavBar-title'>Estimate</span>
                        </div>
                        <div
                          className='Sub-menu dropdown-item'
                          onClick={() => {
                            selectTap('Lead_Register');
                            navigate('/Lead_Register');
                            setTabText('Lead Register');
                            setUrl_img(
                              'assets/icon/Generate Payment Link Black.png'
                            );
                          }}
                        >
                          <span
                            className='icons-custom'
                            data-tip='Lead Register'
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                'assets/icon/Generate Payment Link Black.png'
                              }
                              alt='logo'
                            />
                          </span>
                          <span className='NavBar-title'>Lead Register</span>
                        </div>

                        <div
                          className='Sub-menu dropdown-item'
                          onClick={() => {
                            selectTap('FreeClient');
                            navigate('/FreeClient');
                            setTabText('Free Client');
                            setUrl_img('assets/icon/address&layout_black.png');
                          }}
                        >
                          <span className='icons-custom' data-tip='FreeClient'>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                'assets/icon/address&layout_black.png'
                              }
                              alt='paid'
                            />
                          </span>
                          <span className='NavBar-title'>Free Client</span>
                        </div>

                        <div
                          className='Sub-menu dropdown-item'
                          onClick={() => {
                            selectTap('Paid_Clients');
                            navigate('/Paid_Clients');
                            setTabText('Paid Clients');
                            setUrl_img('assets/icon/order paid black.png');
                          }}
                        >
                          <span
                            className='icons-custom'
                            data-tip='Paid Clients'
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                'assets/icon/order paid black.png'
                              }
                              alt='paid'
                            />
                          </span>
                          <span className='NavBar-title'>Paid Clients</span>
                        </div>
                        <div
                          className='Sub-menu dropdown-item'
                          onClick={() => {
                            selectTap('Paid_Clientsv2');
                            navigate('/Paid_Clientsv2');
                            setTabText('Paid Clientsv2');
                            setUrl_img('assets/icon/order paid black.png');
                          }}
                        >
                          <span
                            className='icons-custom'
                            data-tip='Paid Clients v2'
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                'assets/icon/order paid black.png'
                              }
                              alt='paid'
                            />
                          </span>
                          <span className='NavBar-title'>Paid Clients v2</span>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className='main-text'
                      onClick={() => {
                        selectTap('Estimate');
                        navigate('/Estimate');
                        setTabText('Estimate');
                        setUrl_img('assets/icon/budgeting_black.png');
                      }}
                    >
                      <span className='icons-custom' data-tip='Estimate'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/budgeting_black.png'
                          }
                          alt='logo'
                        />
                      </span>
                      <span className='NavBar-title'>Estimate</span>
                    </div> */}

                    {/* <div className='main-text'
                        onClick={() => {
                          selectTap("Quote");
                          navigate('/Quote')
                          setTabText("Make Quote")
                          setUrl_img("assets/icon/Make Bill Black.png")
                        }}><span className="icons-custom" data-tip="Make Bill">
                          <img src={process.env.PUBLIC_URL + "assets/icon/Make Bill Black.png"} alt="logo" />
                        </span>
                        <span className='NavBar-title'>Make Bill</span>
                      </div> */}

                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('Client_Register');
                        navigate('/Client_Register');
                        setTabText('Client Register');
                        setUrl_img(
                          'assets/icon/Generate Payment Link Black.png'
                        );
                      }}
                    >
                      <span className='icons-custom' data-tip='Client Register'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/Generate Payment Link Black.png'
                          }
                          alt='logo'
                        />
                      </span>
                      <span className='NavBar-title'>Client Register</span>
                    </div>

                    {/* <div className="btn-group dropright main-text main-pos">
                      <div type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className='drop-btn'>
                        <div className="icons-custom" data-tip="UpSell">
                          <img src={process.env.PUBLIC_URL + "assets/icon/Upsell_white.png"} alt="paid" />
                        </div>
                        <div className="NavBar-title  dropdown-toggle" >
                          UpSell
                        </div>
                      </div>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                       

                        <div className='Sub-menu dropdown-item'
                          onClick={() => {
                            selectTap("ProductSell");
                            navigate('/ProductSell')
                            setTabText("Product Sell")
                            setUrl_img("assets/icon/product_selling.png")
                          }}><span className="icons-custom" data-tip="Product Selling">
                            <img src={process.env.PUBLIC_URL + "assets/icon/product_selling.png"} alt="logo" />
                          </span>
                          <span className='NavBar-title'>Product Sell</span>
                        </div>
                       
                      </div>
                    </div> */}
                    {employeeId === '1024' && (
                      <div
                        className='main-text'
                        onClick={() => {
                          selectTap('WebsiteLead');
                          navigate('/WebsiteLead');
                          setTabText('Website Lead');
                          setUrl_img('assets/icon/Paid Clients Black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Website Lead'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Paid Clients Black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Website Lead</span>
                      </div>
                    )}
                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('Address&Layout');
                        navigate('/Address&Layout');
                        setTabText('Address&Layout');
                        setUrl_img('assets/icon/address&layout_black.png');
                      }}
                    >
                      <span className='icons-custom' data-tip='Address&Layout'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/address&layout_black.png'
                          }
                          alt='paid'
                        />
                      </span>
                      <span className='NavBar-title'>Address&Layout</span>
                    </div>

                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('performaInvoice');
                        navigate('/performaInvoice');
                        setTabText('Proforma Invoice');
                        setUrl_img('assets/icon/Installation Black.png');
                      }}
                    >
                      <span
                        className='icons-custom'
                        data-tip='Proforma Invoice'
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/Installation Black.png'
                          }
                          alt='logo'
                        />
                      </span>
                      <span className='NavBar-title'>Proforma Invoice</span>
                    </div>

                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('customers');
                        navigate('/customers');
                        setTabText('Customers');
                        setUrl_img('assets/icon/Installation Black.png');
                      }}
                    >
                      <span className='icons-custom' data-tip='Customers'>
                        <People />
                      </span>
                      <span className='NavBar-title'>Customers</span>
                    </div>

                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('invoice');
                        navigate('/invoice');
                        setTabText('Invoices');
                        setUrl_img('assets/icon/Installation Black.png');
                      }}
                    >
                      <span className='icons-custom' data-tip='Invoices'>
                        <Description />
                      </span>
                      <span className='NavBar-title'>Invoices</span>
                    </div>
                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('houses');
                        navigate('/houses');
                        setTabText('Houses');
                        setUrl_img('assets/icon/Installation Black.png');
                      }}
                    >
                      <span className='icons-custom' data-tip='Houses'>
                        <MapsHomeWork />
                      </span>
                      <span className='NavBar-title'>Houses</span>
                    </div>

                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('upsellItems');
                        navigate('/upsellItems');
                        setTabText('Upsell Items');
                        setUrl_img('assets/icon/Installation Black.png');
                      }}
                    >
                      <span className='icons-custom' data-tip='Upsell Items'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/Installation Black.png'
                          }
                          alt='logo'
                        />
                      </span>
                      <span className='NavBar-title'>Upsell Items</span>
                    </div>

                    {(userRole.includes(user_roles.SalesAdmin) ||
                      userRole.includes(user_roles.SuperAdmin)) && (
                      <>
                        {/*  */}
                        <div
                          className='main-text'
                          onClick={() => {
                            selectTap('droppedClients');
                            navigate('/droppedClients');
                            setTabText('Dropped Clients');
                            setUrl_img('assets/icon/order paid black.png');
                          }}
                        >
                          <span
                            className='icons-custom'
                            data-tip='Dropped Clients'
                          >
                            <PersonOff />
                          </span>
                          <span className='NavBar-title'>Dropped Clients</span>
                        </div>
                      </>
                    )}
                  </>
                )}
              {((displayTab === 'Telecaller' ||
                displayTab === 'Dispatch & Installation' ||
                displayTab === 'Dispatch' ||
                displayTab === 'Super Admin' ||
                userRole.includes(user_roles.Isp)) &&
                !userRole.includes(user_roles.Installation)) ||
              userRole.includes(user_roles.ExternalTeleCaller) ? (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Installation');
                      navigate('/Installation');
                      setTabText('Installation');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Installation'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Installation</span>
                  </div>

                  {/* <div
                    className="main-text"
                    onClick={() => {
                      selectTap("PageInstallation");
                      navigate("/PageInstallation");
                      setTabText("Installation");
                      setUrl_img("assets/icon/Installation Black.png");
                    }}
                  >
                    <span className="icons-custom" data-tip="Installation">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/icon/Installation Black.png"
                        }
                        alt="logo"
                      />
                    </span>
                    <span className="NavBar-title">Old Installation</span>
                  </div>  */}
                </>
              ) : null}
              {(userRole?.includes(user_roles.SuperAdmin) ||
                userRole?.includes(user_roles.Grievance)) && (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Greivance_Department');
                      navigate('/Greivance_Department');
                      setTabText('Greivance Department');
                      setUrl_img('assets/icon/meeting_black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Greivance Department'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/meeting_white.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Greivance Department</span>
                  </div>
                  {employeeId === '1024' && (
                    <div
                      className='main-text'
                      onClick={() => {
                        selectTap('UnAssignLead');
                        navigate('/UnAssignLead');
                        setTabText('Assign Lead');
                        setUrl_img('assets/icon/Installation Black.png');
                      }}
                    >
                      <span className='icons-custom' data-tip='Assign Lead'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/Installation Black.png'
                          }
                          alt='logo'
                        />
                      </span>
                      <span className='NavBar-title'>Assign Lead</span>
                    </div>
                  )}

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('MacIds');
                      navigate('/MacIds');
                      setTabText('Mac Ids');
                      setUrl_img('assets/icon/meeting_black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Greivance Department'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/meeting_white.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Mac Ids</span>
                  </div>
                </>
              )}
              {userRole?.includes('Super Admin') ||
              userRole.includes(user_roles.Dispatch) ? (
                <div
                  className='main-text'
                  onClick={() => {
                    selectTap('UnInstallation');
                    navigate('/UnInstallation');
                    setTabText('UnInstallation');
                    setUrl_img('assets/icon/meeting_black.png');
                  }}
                >
                  <span className='icons-custom' data-tip='UnInstallation'>
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/icon/meeting_white.png'
                      }
                      alt='paid'
                    />
                  </span>
                  <span className='NavBar-title'>UnInstallation</span>
                </div>
              ) : null}
              {(userRole?.includes('Super Admin') ||
                employeeId === '1024' ||
                (userRole?.includes('Telecaller') &&
                  !userRole.includes(user_roles.Installation))) && (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('OverDueInvoice');
                      navigate('/OverDueInvoice');
                      setTabText('OverDue Invoice');
                      setUrl_img('assets/icon/address&layout_black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='OverDue Invoice'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/address&layout_black.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>OverDue Invoice</span>
                  </div>

                  <div className='btn-group dropright main-text main-pos'>
                    <div
                      type='button'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      className='drop-btn'
                    >
                      <div className='icons-custom' data-tip='Search Client'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/request_white.png'
                          }
                          alt='paid'
                        />
                      </div>
                      <div className='NavBar-title  dropdown-toggle'>
                        Ticket
                      </div>
                    </div>
                    <div
                      className='dropdown-menu'
                      aria-labelledby='dropdownMenu2'
                    >
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('All_Request');
                          navigate('/All_Request');
                          setTabText('All Request');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='All Request'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>
                          All Ticket{' '}
                          <span
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            ({allRequestCount})
                          </span>
                        </span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Tech_Request');
                          navigate('/Tech_Request');
                          setTabText('Tech Request');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Tech Ticket'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Tech Ticket</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Drop_Request');
                          navigate('/Drop_Request');
                          setTabText('Drop Request');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Drop Ticket'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Drop Ticket</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('General_Request');
                          navigate('/General_Request');
                          setTabText('General Request');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='General Ticket'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>General Ticket</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Sales_Request');
                          navigate('/Sales_Request');
                          setTabText('Sales Request');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Sales Ticket'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Sales Ticket</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Dispatch_Request');
                          navigate('/Dispatch_Request');
                          setTabText('Dispatch Request');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Dispatch Ticket'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Dispatch Ticket</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Upgrade_Record');
                          navigate('/Upgrade_Record');
                          setTabText('Upgrade Record');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Upgrade Record'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='Refund_Record'
                          />
                        </span>
                        <span className='NavBar-title'>Upgrade Record</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Refund_Record');
                          navigate('/Refund_Record');
                          setTabText('Refund Record');
                          setUrl_img('assets/icon/request_black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Refund Record'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/request_white.png'
                            }
                            alt='Refund_Record'
                          />
                        </span>
                        <span className='NavBar-title'>Refund Record</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {userRole.includes(user_roles.Isp) && (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Client_Register_isp');
                      navigate('/Client_Register_isp');
                      setTabText('Client Register');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Client Register'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='isp'
                      />
                    </span>
                    <span className='NavBar-title'>Client Register</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Address&Layout');
                      navigate('/Address&Layout');
                      setTabText('Address&Layout');
                      setUrl_img('assets/icon/address&layout_black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Address&Layout'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/address&layout_black.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Address&Layout</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Wallet');
                      navigate('/Wallet');
                      setTabText('Wallet');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Wallet'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='isp'
                      />
                    </span>
                    <span className='NavBar-title'>Wallet</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Paid_Client_isp');
                      navigate('/Paid_Client_isp');
                      setTabText('Paid Client');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Paid Client'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='isp'
                      />
                    </span>
                    <span className='NavBar-title'>Paid Client</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Partial_Client_isp');
                      navigate('/Partial_Client_isp');
                      setTabText('Partial Client');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Partial Client'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='isp'
                      />
                    </span>
                    <span className='NavBar-title'>Partial Client</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('InvoiceDownload_isp');
                      navigate('/InvoiceDownload_isp');
                      setTabText('Invoice Download');
                      setUrl_img('assets/icon/tax (2).png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Invoice Download'>
                      <img
                        src={process.env.PUBLIC_URL + 'assets/icon/tax (2).png'}
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Invoice Download</span>
                  </div>
                </>
              )}
              {userRole.includes(user_roles.ExternalTeleCaller) && (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Client_Register_External');
                      navigate('/Client_Register_External');
                      setTabText('Client Register');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Client Register'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='isp'
                      />
                    </span>
                    <span className='NavBar-title'>Client Register</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Address&Layout');
                      navigate('/Address&Layout');
                      setTabText('Address&Layout');
                      setUrl_img('assets/icon/address&layout_black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Address&Layout'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/address&layout_black.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Address&Layout</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Paid_Client_isp');
                      navigate('/Paid_Client_isp');
                      setTabText('Paid Client');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Paid Client'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='isp'
                      />
                    </span>
                    <span className='NavBar-title'>Paid Client</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Partial_Client_isp');
                      navigate('/Partial_Client_isp');
                      setTabText('Partial Client');
                      setUrl_img('assets/icon/search _black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Partial Client'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/search _black.png'
                        }
                        alt='isp'
                      />
                    </span>
                    <span className='NavBar-title'>Partial Client</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('InvoiceDownload_isp');
                      navigate('/InvoiceDownload_isp');
                      setTabText('Invoice Download');
                      setUrl_img('assets/icon/tax (2).png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Invoice Download'>
                      <img
                        src={process.env.PUBLIC_URL + 'assets/icon/tax (2).png'}
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Invoice Download</span>
                  </div>
                </>
              )}

              {userRole.includes('External Telecaller') ? (
                <div
                  className='main-text'
                  onClick={() => {
                    selectTap('Genrate_Payment_Link_Et');
                    navigate('/Genrate_Payment_Link_Et');
                    setTabText('Genrate Payment Link');
                    setUrl_img('assets/icon/Generate Payment Link Black.png');
                  }}
                >
                  <span
                    className='icons-custom'
                    data-tip='Genrate Payment Link'
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        'assets/icon/Generate Payment Link Black.png'
                      }
                      alt='logo'
                    />
                  </span>
                  <span className='NavBar-title'>Payment Link</span>
                </div>
              ) : null}
              {userRole?.includes(user_roles.Telecaller) &&
              !userRole.includes(user_roles.Installation) ? (
                <div
                  className='main-text'
                  onClick={() => {
                    selectTap('DashBoard');
                    navigate('/DashBoard');
                    setTabText('DashBoard');
                    setUrl_img('assets/icon/meeting_black.png');
                  }}
                >
                  <span className='icons-custom' data-tip='DashBoard'>
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/icon/meeting_white.png'
                      }
                      alt='paid'
                    />
                  </span>
                  <span className='NavBar-title'>DashBoard</span>
                </div>
              ) : null}

              {userRole.includes('Marketing') ? (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Lead');
                      navigate('/Lead');
                      setTabText('Lead');
                      setUrl_img('assets/icon/lead_black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Lead'>
                      <img
                        src={
                          process.env.PUBLIC_URL + 'assets/icon/lead_white.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Lead</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('MarkingAddStatus');
                      navigate('/MarkingAddStatus');
                      setTabText('Marketing');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Marketing'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Marketing</span>
                  </div>
                </>
              ) : null}

              {displayTab === 'Super Admin' ? (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Notification');
                      navigate('/Notification');
                      setTabText('Notification');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Notification'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Notification</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('NewLeadAnalysis');
                      navigate('/NewLeadAnalysis');
                      setTabText('New Lead Analysis');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='New Lead Analysis'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>New Lead Analysis</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('LeadAnalysis');
                      navigate('/LeadAnalysis');
                      setTabText('Lead Analysis');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Lead Analysis'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Lead Analysis</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('InstallationReport');
                      navigate('/InstallationReport');
                      setTabText('Installation Report');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Installation Report'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Installation Report</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('leadsConsumed');
                      navigate('/leadsConsumed');
                      setTabText('Leads Consumed');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Leads Consumed'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Leads Consumed</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('ElectricianZone');
                      navigate('/ElectricianZone');
                      setTabText('Electrician Zone');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Electrician Zone'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Electrician Zone</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('AllElectricianIncentive');
                      navigate('/AllElectricianIncentive');
                      setTabText('Electrician Incentive');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Electrician Incentive'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Electrician Incentive</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('UnAssignLead');
                      navigate('/UnAssignLead');
                      setTabText('Assign Lead');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Assign Lead'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Assign Lead</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('PendingLeaveElectrician');
                      navigate('/PendingLeaveElectrician');
                      setTabText('Electrician Leave');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Electrician Leave'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Electrician Leave</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('ElectricianSiteStatus');
                      navigate('/ElectricianSiteStatus');
                      setTabText('Electrician Site Status');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Electrician Site Status'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>
                      Electrician Site Status
                    </span>
                  </div>
                </>
              ) : null}
              {userRole?.includes('Telecaller') ||
              userRole?.includes(user_roles.SmartMeter) ||
              userRole?.includes('Super Admin') ? (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('RetentionDashBord');
                      navigate('/RetentionDashBord');
                      setTabText('Retention DashBord');
                      setUrl_img('assets/icon/address&layout_black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Retention DashBord'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/address&layout_black.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Retention DashBord</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('customerFeedBack');
                      navigate('/customerFeedBack');
                      setTabText('Customer FeedBack');
                      setUrl_img('assets/icon/address&layout_black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Customer FeedBack'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/address&layout_black.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Customer FeedBack</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Drop_Clients');
                      navigate('/Drop_Clients');
                      setTabText('Drop Clients');
                      setUrl_img('assets/icon/Not Paid Clients Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Drop Clients'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Not Paid Clients Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Drop Clients</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('InvoiceDownload');
                      navigate('/InvoiceDownload');
                      setTabText('Invoice Download');
                      setUrl_img('assets/icon/tax (2).png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Invoice Download'>
                      <img
                        src={process.env.PUBLIC_URL + 'assets/icon/tax (2).png'}
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Invoice Download</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('PayUTransaction');
                      navigate('/PayUTransaction');
                      setTabText('PayU Transaction');
                      setUrl_img('assets/icon/tax (2).png');
                    }}
                  >
                    <span className='icons-custom' data-tip='PayU Transaction'>
                      <img
                        src={process.env.PUBLIC_URL + 'assets/icon/tax (2).png'}
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>PayU Transaction</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('SecurityReceipt');
                      navigate('/SecurityReceipt');
                      setTabText('Security Receipt');
                      setUrl_img('assets/icon/tax (2).png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Security Receipt'>
                      <img
                        src={process.env.PUBLIC_URL + 'assets/icon/tax (2).png'}
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Security Receipt</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('CreditNote');
                      navigate('/CreditNote');
                      setTabText('Credit Note');
                      setUrl_img('assets/icon/tax (2).png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Credit Note'>
                      <img
                        src={process.env.PUBLIC_URL + 'assets/icon/tax (2).png'}
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Credit Note</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('YearInvoiceDownload');
                      navigate('/YearInvoiceDownload');
                      setTabText('Invoice Download');
                      setUrl_img('assets/icon/tax (2).png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Invoice Download'>
                      <img
                        src={process.env.PUBLIC_URL + 'assets/icon/tax (2).png'}
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Year Invoice Download</span>
                  </div>
                </>
              ) : null}

              {loginName === 'Akhil' || displayTab === 'Electrician' ? (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Electrician');
                      navigate('/Electrician');
                      setTabText('Electrician');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Electrician'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Electrician</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('MacIds');
                      navigate('/MacIds');
                      setTabText('Mac Ids');
                      setUrl_img('assets/icon/meeting_black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='Greivance Department'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/meeting_white.png'
                        }
                        alt='paid'
                      />
                    </span>
                    <span className='NavBar-title'>Mac Ids</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Devices');
                      navigate('/Devices');
                      setTabText('Devices');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Devices'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Devices</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('SitePick');
                      navigate('/SitePick');
                      setTabText('Site Pick');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Site Pick'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Site Pick</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('KmsReport');
                      navigate('/KmsReport');
                      setTabText('Kms Report');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Kms Report'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Kms Report</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('ElectricianIncentive');
                      navigate('/ElectricianIncentive');
                      setTabText('Electrician Incentive');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Site Pick'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Electrician Incentive</span>
                  </div>

                  {/* <div
                    className="main-text"
                    onClick={() => {
                      selectTap("ProductSellElectrician");
                      navigate("/ProductSellElectrician");
                      setTabText("Product Sell");
                      setUrl_img("assets/icon/product_selling.png");
                    }}
                  >
                    <span className="icons-custom" data-tip="Product Selling">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/icon/product_selling.png"
                        }
                        alt="logo"
                      />
                    </span>
                    <span className="NavBar-title">Product Sell</span>
                  </div> */}
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('LeaveElectrician');
                      navigate('/LeaveElectrician');
                      setTabText('Leave');
                      setUrl_img('assets/icon/product_selling.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Product Selling'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/product_selling.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Leave</span>
                  </div>
                </>
              ) : null}

              {userRole.includes('Super Admin') ? (
                <>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('MarkingAddStatus');
                      navigate('/MarkingAddStatus');
                      setTabText('Marketing');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Marketing'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Marketing</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('TeleCallerIncentive');
                      navigate('/TeleCallerIncentive');
                      setTabText('TeleCallerIncentive');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span
                      className='icons-custom'
                      data-tip='TeleCaller Incentive'
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>TeleCaller Incentive</span>
                  </div>
                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('Referral');
                      navigate('/Referral');
                      setTabText('Referral');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Referral'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Referral</span>
                  </div>

                  <div
                    className='main-text'
                    onClick={() => {
                      selectTap('users');
                      navigate('/users');
                      setTabText('Users');
                      setUrl_img('assets/icon/Installation Black.png');
                    }}
                  >
                    <span className='icons-custom' data-tip='Users'>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          'assets/icon/Installation Black.png'
                        }
                        alt='logo'
                      />
                    </span>
                    <span className='NavBar-title'>Users</span>
                  </div>

                  <div className='btn-group dropright main-text main-pos'>
                    <div
                      type='button'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      className='drop-btn'
                    >
                      <div className='icons-custom' data-tip='Search Client'>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            'assets/icon/request_white.png'
                          }
                          alt='paid'
                        />
                      </div>
                      <div className='NavBar-title  dropdown-toggle'>
                        Not Used
                      </div>
                    </div>
                    <div
                      className='dropdown-menu '
                      aria-labelledby='dropdownMenu2'
                    >
                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('NumbersDashboard');
                          navigate('/NumbersDashboard');
                          setTabText('Dashboard');
                          setUrl_img('assets/icon/new-product.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Numbers Dashboard'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/new-product.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Dashboard</span>
                      </div>
                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('Numbers');
                          navigate('/Numbers');
                          setTabText('Dashboard');
                          setUrl_img('assets/icon/new-product.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Numbers'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/new-product.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Numbers</span>
                      </div>
                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('ProductCreated');
                          navigate('/ProductCreated');
                          setTabText('Product Created');
                          setUrl_img('assets/icon/new-product.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Product Created'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/new-product.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Product Created</span>
                      </div>

                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('WebsiteLead');
                          navigate('/WebsiteLead');
                          setTabText('Website Lead');
                          setUrl_img('assets/icon/Paid Clients Black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Website Lead'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Paid Clients Black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Website Lead</span>
                      </div>
                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('Not_Paid_Clients');
                          navigate('/Not_Paid_Clients');
                          setTabText('Not Paid Clients');
                          setUrl_img('assets/icon/Not Paid Clients Black.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Not Paid Clients'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Not Paid Clients Black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Not Paid</span>
                      </div>
                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('Register_ISP');
                          navigate('/Register_ISP');
                          setTabText('Register ISP');
                          setUrl_img('assets/icon/search _black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Register ISP'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/search _black.png'
                            }
                            alt='isp'
                          />
                        </span>
                        <span className='NavBar-title'>Register ISP</span>
                      </div>
                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('Edit_ISP');
                          navigate('/Edit_ISP');
                          setTabText('Edit ISP');
                          setUrl_img('assets/icon/search _black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Edit ISP'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/search _black.png'
                            }
                            alt='isp'
                          />
                        </span>
                        <span className='NavBar-title'>Edit ISP</span>
                      </div>
                      <div
                        className='dropdown-item'
                        onClick={() => {
                          selectTap('Mandate_Paid_Order_Status');
                          navigate('/Mandate_Paid_Order_Status');
                          setTabText('Mandate Paid Order Status');
                          setUrl_img('assets/icon/order paid black.png');
                        }}
                      >
                        <span
                          class='icons-custom'
                          data-tip='Mandate Paid Order Status'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/order paid black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>
                          Mandate Paid Order Status
                        </span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Incentive');
                          navigate('/Incentive');
                          setTabText('Old Incentive');
                          setUrl_img('assets/icon/estimate black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Incentive'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/estimate black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Old Incentive</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('NewIncentive');
                          navigate('/NewIncentive');
                          setTabText('New Incentive');
                          setUrl_img('assets/icon/estimate black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Incentive'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/estimate black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>New Incentive</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Multiple_Subscription');
                          navigate('/Multiple_Subscription');
                          setTabText('Multiple Subscription');
                          setUrl_img('assets/icon/order paid black.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Multiple Subscription'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/order paid black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>
                          Multiple Subscription
                        </span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('ProductList');
                          navigate('/ProductList');
                          setTabText('Product List');
                          setUrl_img('assets/icon/product_selling.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Product List'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/product_selling.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Product List</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Gerivance');
                          navigate('/Gerivance');
                          setTabText('Gerivance');
                          setUrl_img('assets/icon/Paid Clients Black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Gerivance'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Paid Clients Black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Gerivance</span>
                      </div>

                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Suggestion');
                          navigate('/Suggestion');
                          setTabText('Suggestion');
                          setUrl_img('assets/icon/Paid Clients Black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Suggestion'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Paid Clients Black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Suggestion</span>
                      </div>

                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Partial_Payments');
                          navigate('/Partial_Payments');
                          setTabText('Partial Payments');
                          setUrl_img('assets/icon/Partial black.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Partial Payments'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Partial black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Partial Payments</span>
                      </div>

                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Installed');
                          navigate('/Installed');
                          setTabText('Installed');
                          setUrl_img('assets/icon/Installed Black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Installed'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Installed Black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Installed</span>
                      </div>

                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Dispatch');
                          navigate('/Dispatch');
                          setTabText('Dispatch');
                          setUrl_img('assets/icon/Dispatched Black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Dispatch'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Dispatched Black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Dispatch</span>
                      </div>

                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Meeting');
                          navigate('/Meeting');
                          setTabText('Meeting');
                          setUrl_img('assets/icon/meeting_black.png');
                        }}
                      >
                        <span className='icons-custom' data-tip='Meeting'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/meeting_white.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>Meeting</span>
                      </div>

                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('OneMonthSales');
                          navigate('/OneMonthSales');
                          setTabText('OneMonthSales');
                          setUrl_img('assets/icon/Paid Clients Black.png');
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='One Month Sales'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Paid Clients Black.png'
                            }
                            alt='paid'
                          />
                        </span>
                        <span className='NavBar-title'>One Month Sales</span>
                      </div>

                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Single_Client_Subscription');
                          navigate('/Single_Client_Subscription');
                          setTabText('Single Client Subscription');
                          setUrl_img(
                            'assets/icon/Generate Payment Link Black.png'
                          );
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Client Register'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Generate Payment Link Black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>
                          Client DownGrade/UPGrade
                        </span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Payment_History');
                          navigate('/Payment_History');
                          setTabText('Payment History');
                          setUrl_img(
                            'assets/icon/Generate Payment Link Black.png'
                          );
                        }}
                      >
                        <span
                          className='icons-custom'
                          data-tip='Client Register'
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Generate Payment Link Black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Payment History</span>
                      </div>
                      <div
                        className='Sub-menu dropdown-item'
                        onClick={() => {
                          selectTap('Quotation');
                          navigate('/Quotation');
                          setTabText('Quotation');
                          setUrl_img(
                            'assets/icon/Generate Payment Link Black.png'
                          );
                        }}
                      >
                        <span className='icons-custom' data-tip='Quotation'>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/icon/Generate Payment Link Black.png'
                            }
                            alt='logo'
                          />
                        </span>
                        <span className='NavBar-title'>Quotation</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className='right-container'>
          {tab === 'NumbersDashboard' ? <NumbersDashboard /> : null}
          {tab === 'Numbers' ? <Numbers /> : null}
          {tab === 'Greivance_Department' ? <GrivanceDepartment /> : null}
          {tab === 'UnAssignLead' ? <AssignLeadV2 /> : null}
          {tab === 'Client_Register_isp' ? <IspClientRegister /> : null}
          {tab === 'Paid_Client_isp' ? <IspPaidClient /> : null}
          {tab === 'Partial_Client_isp' ? <IspPartialClient /> : null}
          {tab === 'TeleCallerIncentive' ? <TeleCallerIncentive /> : null}
          {tab === 'Register_ISP' ? <RegisterIsp /> : null}
          {tab === 'Wallet' ? <WalletBalance /> : null}
          {tab === 'InvoiceDownload_isp' ? <IspInvoice /> : null}
          {tab === 'Edit_ISP' ? <EditIsp /> : null}
          {tab === 'CRM_Qutotation' ? (
            <CrmQuto wholeReload={wholeReload} />
          ) : null}
          {tab === 'Genrate_Payment_Link_Et' ? (
            <CreateLead wholeReload={wholeReload} />
          ) : null}
          {tab === 'Client_Register' ? (
            <ClientRegister
              Leadpage={Leadpage}
              InstallationPage={InstallationPage}
            />
          ) : null}
          {tab === 'Paid_Clientsv2' && <AllPaidClients />}
          {tab === 'Installation' && <PageNatedInstallationPage />}
          {tab === 'customerFeedBack' ? <Feedback /> : null}
          {tab === 'RetentionDashBord' ? <Retention /> : null}
          {tab === 'Single_Client_Subscription' ? (
            <SingleClientSubscription />
          ) : null}
          {tab === 'PendingLeaveElectrician' ? <ElectricianLeave /> : null}
          {tab === 'ElectricianSiteStatus' ? (
            <AllElectricianSiteStatus />
          ) : null}
          {tab === 'MacIds' && <MacId />}
          {tab === 'MarkingAddStatus' ? <MarkingAddStatus /> : null}
          {tab === 'Lead_Register' ? <LeadCreate /> : null}
          {tab === 'FreeClient' && <FreeClient />}
          {tab === 'UnInstallation' ? <UnInstallationDepartment /> : null}
          {tab === 'Upgrade_Record' ? <ClientUpgrade /> : null}
          {tab === 'Client_Register_External' ? (
            <NewClientRegister
              Leadpage={Leadpage}
              styleE={user_roles.ExternalTeleCaller}
            />
          ) : null}

          {tab === 'Address&Layout' ? (
            <PaymentLinkGen wholeReload={wholeReload} />
          ) : null}
          {tab === 'OverDueInvoice' ? <ActiveOverDueInvoice /> : null}
          {tab === 'Notification' ? <Notification /> : null}
          {tab === 'WebsiteLead' ? <WebsiteLead /> : null}
          {tab === 'Gerivance' ? <Grivance /> : null}
          {tab === 'AllElectricianIncentive' ? (
            <AllElectricianIncentive />
          ) : null}
          {tab === 'Suggestion' ? <Suggestion /> : null}
          {tab === 'Search_Client' ? <SearchClient /> : null}
          {tab === 'DashBoard' ? <Dasboard /> : null}

          {tab === 'All_Request' ? <DepartmentRequests dep={'All'} /> : null}
          {tab === 'Tech_Request' ? <DepartmentRequests dep={'Tech'} /> : null}
          {tab === 'Drop_Request' ? <DepartmentRequests dep={'Drop'} /> : null}
          {tab === 'Sales_Request' ? (
            <DepartmentRequests dep={'Sales'} />
          ) : null}
          {tab === 'General_Request' ? (
            <DepartmentRequests dep={'General'} />
          ) : null}
          {tab === 'Dispatch_Request' ? (
            <DepartmentRequests dep={'Dispatch'} />
          ) : null}
          {tab === 'Refund_Request' ? (
            <DepartmentRequests dep={'Refund'} />
          ) : null}
          {tab === 'Refund_Record' ? <RefundRecord /> : null}
          {tab === 'ProductCreated' ? <ProductCreation /> : null}
          {tab === 'ProductList' ? <ProductSelling /> : null}
          {tab === 'ProductSell' ? <ProductSell /> : null}
          {tab === 'ProductSellElectrician' ? (
            <ElectricianUpsell LoadPage={ElectricanUpSellPage} />
          ) : null}
          {tab === 'Incentive' ? <IncentiveCalculator /> : null}
          {tab === 'NewIncentive' ? <NewTeleCallerIncentive /> : null}
          {tab === 'Estimate' ? <Estimate wholeReload={wholeReload} /> : null}
          {tab === 'Partial_Payments' ? (
            <PartialPayment wholeReload={wholeReload} />
          ) : null}

          {tab === 'InvoiceDownload' ? (
            <InvoiceDownload wholeReload={wholeReload} />
          ) : null}
          {tab === 'PayUTransaction' && <PayTransaction />}
          {tab === 'SecurityReceipt' && <SecurityReceipt />}
          {tab === 'CreditNote' && <CreditNote />}
          {tab === 'YearInvoiceDownload' && (
            <FinancialInvoice wholeReload={wholeReload} />
          )}

          {tab === 'Dispatch' ? (
            <ReadyDispatch wholeReload={wholeReload} />
          ) : null}
          {tab === 'PageInstallation' ? (
            <Installation wholeReload={wholeReload} />
          ) : null}
          {tab === 'ElectricianZone' ? <ElectricianZone /> : null}
          {tab === 'ElectricianLocation' ? <Map /> : null}
          {tab === 'ElectricianMap' ? <ElectricianWiseMap /> : null}
          {tab === 'LeaveElectrician' ? <Leave /> : null}
          {tab === 'OneMonthSales' ? <YesterDaySales /> : null}
          {tab === 'Partial Payments' ? (
            <PartialPayment wholeReload={wholeReload} />
          ) : null}
          {tab === 'Lead' ? <Lead /> : null}
          {tab === 'Paid_Clients' ? (
            <PaidOrderStatus wholeReload={wholeReload} />
          ) : null}
          {tab === 'Mandate_Paid_Order_Status' ? (
            <MandatePaidOrderStatus wholeReload={wholeReload} />
          ) : null}
          {tab === 'Multiple_Subscription' ? (
            <MulipleSubscription wholeReload={wholeReload} />
          ) : null}
          {tab === 'Not_Paid_Clients' ? (
            <NotPaid wholeReload={wholeReload} />
          ) : null}
          {tab === 'Payment_History' ? <PaymentHistory /> : null}
          {tab === 'Quotation' ? <Quotation /> : null}
          {tab === 'Drop_Clients' ? <DropClients /> : null}
          {tab === 'bill' ? <MakeBill wholeReload={wholeReload} /> : null}
          {tab === 'Quote' ? <MakeQuto Leadpage={Leadpage} /> : null}
          {tab === 'Electrician' ? <SpecificElectrician /> : null}
          {tab === 'Devices' ? <Movement /> : null}
          {tab === 'SitePick' ? <PickSiteByElectrician /> : null}
          {tab === 'KmsReport' && <ElectricianKms />}
          {tab === 'ElectricianIncentive' ? <ElectricianIncentive /> : null}
          {tab === 'Installed' ? <Installed wholeReload={wholeReload} /> : null}
          {tab === 'Website_Leads' ? (
            <DeadLead wholeReload={wholeReload} />
          ) : null}
          {tab === 'LeadAnalysis' ? <LeadsAnalysis /> : null}
          {tab === 'InstallationReport' ? <InstallationReport /> : null}
          {tab === 'leadsConsumed' ? <LeadsConsumed /> : null}
          {tab === 'NewLeadAnalysis' ? <NewLeadAnalysis /> : null}
          {tab === 'Referral' ? <Referral /> : null}
          {tab === 'upsellItems' ? <UpsellItem /> : null}
          {tab === 'users' ? <Users /> : null}
          {tab === 'performaInvoice' ? <PerformaInvoice /> : null}
          {tab === 'customers' ? <Customers /> : null}
          {tab === 'invoice' ? <Invoices /> : null}
          {tab === 'droppedClients' ? <DroppedClient /> : null}
          {tab === 'houses' ? <House /> : null}
        </div>
      </div>
      <ReactTooltip effect='solid' offset={{ right: 15 }} />
    </>
  );
}
