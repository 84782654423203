import FileSaver from "file-saver";
import { Button, Paper, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { ContentCopy, Download } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";

import { apiRequest } from "../../utils";
import { serverUrl } from "../../component/ApiUrl";
import { notify, notifyFailed } from "../../component/Common/Notification";
import { searchClient } from "../../component/Client registation/Api";

import "./performa.scss";

export default function ListPerforma({ setTab, setPerformaData }) {
    let user = useSelector((state) => state.sar.data.user);

    let salesUser = useSelector((state) => state.salesUser);

    const [customerId, setCustomerId] = useState("");
    const [createdBy, setCreatedBy] = useState(user._id);
    const [searchQuery, setSearchQuery] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [performas, setPerformas] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (searchQuery.length < 3) return;

        const fetchData = async () => {
            await fetchCustomerData(searchQuery);
        };

        fetchData();
    }, [searchQuery]);

    async function fetchCustomerData(query) {
        setLoading(true);
        const response = await searchClient(query);

        setLoading(false);
        if (!response.success) {
            notifyFailed(
                `Search Customer failed ${
                    response?.message && response?.message
                }}`
            );
            return;
        }

        setCustomerData(response?.data?.customers);
        setIsDropdownVisible(true);
    }

    useEffect(() => {
        fetchInvoices();
    }, []);

    async function fetchInvoices() {
        setLoading(true);
        const response = await apiRequest({
            url: `${serverUrl.u}/v3/performa/filteredInvoice`,
            data: { createdBy, customerId },
            method: "POST",
        });

        setLoading(false);
        if (!response.success) {
            notifyFailed(response.message);
            return;
        }
        setPerformas(response.data?.data?.performas);
    }

    // HANDLE DOWNLOAD
    async function handleDownload(data) {

        notify("Regenerating your PI, please hold on...");

        const createPdf = await apiRequest({
            url: `${serverUrl.u}/api/pi/getPdf`,
            method: "POST",
            data: {
                piNumber: data.piNumber,
            },
        });

        if (!createPdf.success) {
            notifyFailed("Failed to create PDF");
            notifyFailed(createPdf.message);
        }

        notify("Please wait 2 seconds to generate proforma");
        setTimeout(() => {
            FileSaver.saveAs(
                `${serverUrl.u + createPdf.data.urlObj[0].substring(6)}`,
                `${data.name}.pdf`
            );
        }, 2000);
    }

    const searchCustomerDropdownRef = useRef(null);

    // HANDLE DUPLICATION  OF PI
    function handleDuplicate(data) {
        setPerformaData(data);
        setTab("createPerforma");
    }

    function handleCustomerSelect(cus) {
        setCustomerId(cus._id);
        setCustomerData([]);
    }
    const columns = [
        {
            field: "name",
            headerName: "Customer Name",
            width: 250,
        },
        {
            field: "piNumber",
            headerName: "Proforma Number",
            width: 150,
        },
        {
            field: "item",
            headerName: "Number of Products",
        },
        {
            field: "items",
            headerName: "Product Names",
            width: 250,
        },
        {
            field: "amount",
            headerName: "Total Amount",
            width: 250,
        },
        {
            field: "billTo",
            headerName: "Billing Address",
            width: 250,
        },
        {
            field: "shipTo",
            headerName: "Shipping Address",
            width: 250,
        },
    ];

    function onUserChange(e) {
        setCreatedBy(e.target.value);
    }
    // Add action buttons
    columns.push({
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
            <div style={{ display: "flex", gap: "10px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleDownload(params.row)}
                >
                    <Download />
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    style={{ color: "red", border: "1px solid red" }}
                    onClick={() => handleDuplicate(params.row)}
                    startIcon={<ContentCopy />}
                >
                    Duplicate
                </Button>
            </div>
        ),
    });
    const rows = performas.map((pi, i) => {
            
        return {
            ...pi,

            id: i, // Using index as unique ID
            items: pi.items.length > 0 ? pi.items.map((p) => p.name).join(", ") : pi.product.map((p) => p.name).join(", "),
            billTo: pi.billTo
                ? `${pi.billTo.address} ${pi.billTo.city}, ${pi.billTo.country}`
                : undefined,
            shipTo: pi.shipTo
                ? `${pi.shipTo.address} ${pi.shipTo.city}, ${pi.shipTo.country}`
                : undefined,
            item: pi.items.length > 0 ? pi.items.length : pi.product.length,
            rawData: pi,
        };
    });
    return (
        <div className="performa">
            <h2>User Proforma</h2>
            <Paper sx={{ p: 2 }}>
                <p>Created By:</p>
                <select
                    class="custom-select height-select"
                    onChange={onUserChange}
                    value={createdBy}
                >
                    <option value="">All</option>
                    {salesUser?.map((option) => (
                        <option value={option._id}>{option.first_name}</option>
                    ))}
                </select>

                <div
                    className="search-customer-container"
                    style={{ marginTop: "20px" }}
                >
                    <input
                        type="text"
                        value={searchQuery}
                        placeholder="Search Customer"
                        className="form-control"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                    {customerData.length > 0 && isDropdownVisible && (
                        <div className="search-customer-result">
                            {customerData.length > 0 &&
                                customerData.map((cus) => {
                                    return (
                                        <div
                                            key={`${cus.first_name}${cus.last_name}`}
                                            onClick={() =>
                                                handleCustomerSelect(cus)
                                            }
                                            className="customer-container"
                                            ref={searchCustomerDropdownRef}
                                        >
                                            <p>
                                                {cus.first_name} {cus.last_name}
                                            </p>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
                {loading ? (
                    <Button variant="contained" sx={{ width: "150px" }}>
                        <CircularProgress
                            sx={{ color: "white" }}
                            size={"25px"}
                        />
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={fetchInvoices}
                        className="mt-4"
                    >
                        Fetch Invoices
                    </Button>
                )}
            </Paper>
            <div className="mt-4">
                {/* Table */}
                <div
                    className="table-container"
                    style={{
                        border: "1px solid #c6d0f0",
                        borderRadius: "10px",
                    }}
                >
                    <DataGrid
                        sx={{ minHeight: "70vh" }}
                        columns={columns}
                        rows={rows}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                            toolbar: {
                                csvOptions: {
                                    fileName: `${
                                        user.first_name
                                    }_pi_${new Date().toLocaleDateString()}`,
                                },
                            },
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30, 40, 50]}
                    />
                </div>
            </div>
        </div>
    );
}
