import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { custmorHaveFeedback } from './Api'
import { Stack } from '@mui/material'

function ShowAllFeedback() {
  const [feedback, setFeedBack] = useState([])
  useEffect(() => {
    custmorHaveFeedback(setFeedBack)
  }, [])
  return (
    <div style={{ padding: "0 1%", marginTop: "4vh" }}>
      <div className='table-container1' style={{ marginTop: 0 }}>

        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th>Sr. No</th>
              <th>Name</th>
              <th>Phone</th>
              <th>City</th>
              <th style={{width:"200px"}}>FeedBack</th>
              <th>User</th>
              <th>Alex Linked</th>
              <th>Google Home Linked</th>
              <th>Schedule</th>
              <th>Scene</th>
              <th>Stack</th>
            </tr>
          </thead>
          <tbody>
            {
              feedback.length !== 0 ?
                feedback.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td
                        onClick={() => navigator.clipboard.writeText(e.generatedHex)}
                        style={{ cursor: "pointer" }}
                      >{e.firstName}</td>
                      <td>{e.phone}</td>
                      <td>{e.city}</td>
                      <td>
                       <Stack>
                      {e.clientFeedback.map((ele,i)=>{
                        return(
                         <item>{ele}</item>
                        )
                      })}
                      </Stack>
                      </td>
                      <td>{e.userCount}</td>
                      <td>{e.alexaLinked?"Linked":"Not Linked"}</td>
                      <td>{e.googleHomeLinked?"Linked":"Not Linked"}</td>
                      <td>{e.schedule}</td>
                      <td>{e.scene}</td>
                      <td>{e.stack}</td>
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ShowAllFeedback