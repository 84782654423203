import React from 'react'
import { useState } from 'react'
import { state,ElectricianIncentiveZone } from '../Data';
import { useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { useEffect } from 'react';

export default function ZoneAssign({e,reload,setReload,notify}) {
    let [pop,setPop] = useState(false)
    let [zone,setZone] = useState([])
    let Select = useRef(null)
    const handleSubmit = (event)=>{
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data.get('city'));
        console.log(Select.current.state.selectedValues);
        let city = Select.current.state.selectedValues.map((e)=>{
               return e.value
        })
        console.log(city,"k");
        axios.post(`${serverUrl.u}/api/tele/addZoneOfElectrician`,{
            "employeeId":e.employeeId,
            "newZone":city
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                setReload(!reload)
                notify(res.data.message)
                setPop(false)
                }else{
                    notify(res.data.message)
                }
            }).catch(function (err) {
                console.log(err);
                notify("Failed")
            })
    }
    const filterZone = ()=>{
        let zo = []
       zo = ElectricianIncentiveZone.filter((ele)=>{
            if(!e.zone.includes(ele.value)){
                return ele
            }
        })
        setZone(zo)
    }
    useEffect(()=>{
      filterZone()
    },[])
  return (
    <>
       <td><button className='btn btn-primary' onClick={()=>setPop(true)}>Assign Zone</button></td>
       {
        pop===true?
         <div style={{position:"fixed",float:"left",top:0,left:0,height:"100vh",width:"100vw",overflowX:"scroll",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div style={{width:"300px",backgroundColor:"whitesmoke",padding:"25px",borderRadius:"12px",position:"absolute"}}>
                <div style={{width:"100%",textAlign:"right",cursor:"pointer"}} onClick={()=>setPop(false)}>X</div>
                <form onSubmit={handleSubmit}>
                <button className='btn btn-primary' type='submit'>Submit</button>
                <div className="row">
             <div className="col-12">
              <label>City</label>
              <div class="input-group mb-1" >
                 
                 <Multiselect
                    displayValue="value"
                    options={zone}
                     ref={Select}
                    style={{
                        width: "100px !important",
                        multiselectContainer: {
                            marginTop: "1px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />
             </div> 
            </div>  
          </div>
         
                </form>
            </div>

        </div>:null
       }
    </>
  )
}
