import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { pages, serverUrl } from '../ApiUrl';
import { Button, Grid, Stack } from '@mui/material';
import { planDuration, plan, planCost, orderTyepes } from '../Data';
import ReactWhatsapp from "react-whatsapp";
import { WhatsApp } from "@mui/icons-material";
import './PlanUpdate.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { subHistory } from './SubscriptionHistory';
import moment from 'moment';
import ConfirmationForPaymentLinkRzp from '../Model/ConfirmationForPaymentLinkRzp';
import ConfirmationForPaymentLink from '../Model/ConfirmationForPaymentLink';
import { notifyFailed } from '../Common/Notification';
import { CheckClientIsOverdue } from '../SearchClient/Api';
import HisabForZohoRazorPay from '../SearchClient/HisabForZohoRazorPay';
import OverdueInvoiceSelect from '../Common/OverdueInvoiceSelect';
import { countApplianceInHouse } from '../Isp/ClientRegister/function';


export default function CollectRazorpayRemaning({ e }) {
  let [razPop, setRazPop] = useState(false)
  let [amount,setAmount] = useState(0) // invoice
    let [zohoInvoice,setZohoInvoices] = useState([])
    let [unpaidAappliance,setUnpaidAappliance] = useState({})

    let [collectoverdue,setCollectoverdue] = useState(true)
  let [planName, setPlanName] = useState();
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let [apps, setApp] = useState(e.totalAppliances)
  let [planN, setPlanN] = useState(e.planId);
  let [btn, setBtn] = useState("Update");
  let [overdue, setOverdue] = useState(false)
  let [hisabDetail, setHisabDetail] = useState({})
  let [planD, setPlanD] = useState("");
  let [partialAmoun, setPartialAmoun] = useState(-1)
  let [reloadLink, setReloadLink] = useState(false);
  let [paymentLink, setPaymentLink] = useState("");

  let [coastperSecurityApplinaces,setCoastperSecurityApplinaces] = useState(100)
  let [newAppliances,setNewAppliances] = useState(e.totalAppliances)
  let [okBtn, setokBtn] = useState(false);
  const [text, setText] = useState("");
  let [whatappNumber, setWhatappNumber] = useState();
  let [secIns, setSecIns] = useState({
    security: e.currentSecurity,
    installation: e.currentInstallation,
  });
  const handlePlanDuration = (event) => {
    setPlanD(event.target.value);
  };
  useEffect(() => { planNameSet() }, [e])
  
  let err = (text) => toast.error(text);
  let notify = (text) => toast(text);

  const planNameSet = () => {
    switch (e.planId) {
      case 0:
        setPlanName("Custom");
        break;
      case 1:
        setPlanName("1 Room");
        break;
      case 2:
        setPlanName("1 BHK");
        break;
      case 3:
        setPlanName("2 BHK");
        break;
      case 4:
        setPlanName("3 BHK");
        break;
      case 5:
        setPlanName("4 BHK");
        break;
      case 6:
        setPlanName("5 BHK");
        break;
      case 7:
        setPlanName("6 BHK");
        break;
    }
  };
  const updatePlanComp = async (userData, fee, total, qu) => {
    console.log(userData, "user", fee, total);
    await axios
      .post(`${serverUrl.u}/api/tele/createRazorFullPayment`, {
        phone: userData.phone,
        generatedHex: e.generatedHex,
        totalAppliances: Number(qu),
        planFrequency: userData.planType,
        planId: userData.planId,
        security: Number(userData.security),
        installation: Number(userData.installion),
        collectOverdue: userData.collectOverdue,
        applyCreditNotes: userData.creditNote === "true" ? true : false,
        postPayment: userData.postPayment,
        invoiceArray:zohoInvoice,
        upsellCapex: (orderTyepes[0].value === e.orderType || orderTyepes[4].value === e.orderType) ? false : true

      })
      .then(function (response) {
        console.log(response);
        if (response.data.message === "payment link create" || response.data.message==="ok") {
          notify(response.data.message)
          if (response.data.url !== "") {
            setWhatappNumber(userData.phone);
            setText(`Hey ${userData.name},
           Book your Aliste Smart Home in a few clicks by carrying out a payment on the following link :  ${serverUrl.u}${serverUrl.partialPay}${e.generatedHex}`);
            navigator.clipboard.writeText(`${serverUrl.u}${serverUrl.partialPay}${e.generatedHex}`);
            setPaymentLink(`${serverUrl.u}${serverUrl.partialPay}${e.generatedHex}`);
            
          }
          setBtn("Update")
          setReloadLink(true);

          setokBtn(true);
          let newDate = new Date()
          subHistory("RazorPay Link Created", e, "RazorPay Link Created", total)
        } else {
          err(response.data.message)
          setBtn("Failed")
        }
      })
      .catch(function (error) {
        console.log(error);
        //  err("Error")
        setBtn("Failed")
        if (error.response.data.success === false) {
          err(error.response.data.message)
        }
      });
  };
  const handleApplianceChange = (val,sequrityCoast) => {
    val = Number(val);
    let secu = val * sequrityCoast;
    let inst;
    if (val > 10) {
      let cal = val - 10;
      inst = 1000 + cal * 50;
    } else {
      inst = 1000;
    }
    setSecIns({
      security: secu,
      installation: inst,
    });
  };
  const handlePlan = (event) => {

    setPlanName(event.target.value);
    if (event.target.value === "Custom") {
      setPlanN(0)

    } else if (event.target.value === "1 Room") {
      setPlanN(1)
    } else if (event.target.value === "1 BHK") {
      setPlanN(2)
    } else if (event.target.value === "2 BHK") {
      setPlanN(3)
    } else if (event.target.value === "3 BHK") {
      setPlanN(4)
    } else if (event.target.value === "4 BHK") {
      setPlanN(5)
    }
    else if (event.target.value === "5 BHK") {
      setPlanN(6)
    } else if (event.target.value === "6 BHK") {
      setPlanN(7)
    }
    console.log(event.target.value);
  };
  const handleSubmit = async (event) => {
    setBtn("Processing..")
    setText("")
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setReloadLink(false);
    let userData2 = {
      name: data.get("name"),
      phone: data.get("phone"),
      city: data.get("city"),
      address: data.get("address"),
      planType: data.get("planDuration"),
      plan: data.get("planName"),
      security: data.get("security"),
      installion: data.get("installion"),
      quantityAppliances: data.get("appliances"),
      partialAmount: data.get("partial"),
      zohoSecurity: Number(data.get("security")) - Number(data.get("partial")),
      planId: planN,
      creditNote: data.get("creditNote"),
      overdueCollect: data.get("overdueCollect"),
      postPayment: (data.get('postPayment') === null || data.get('postPayment') === undefined) ? false : (data.get('postPayment') === "true" ? true : false)
    };
    let oldAppliance = Number(e.totalAppliances) - (newAppliances)
    let qu = 0
      qu = newAppliances;
  


    let fee;
    let planDurations = userData2.planType;
    let createdDate = e.createdAt;

    await axios.post(`${serverUrl.u}/api/tele/getFirstRegistrationDate`, {
      phone: e.phone
    }).then(function (res) {
      console.log(res.data.data);
      createdDate = res.data.data
    }).catch(function (err) {
      console.log(err);
    })
    if (planDurations === "Monthly") {
      if (new Date(createdDate) > new Date('2023-07-25T00:00:00')) {
        fee = (qu * planCost.Monthly) * 30
      } else {
        fee = (qu * 30)
      }
    } else if (planDurations === "Quarterly") {
      fee = (qu * planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
      fee = (qu * planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
      fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
      fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
      fee = (qu * planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
      fee = (qu * planCost.TwoYears) * (365 * 2);
    } else if (planDurations === "Three Years") {
      fee = (qu * planCost.ThreeYears) * (365 * 3);
    }

    let total = Math.round(((fee + Number(userData2.installion)) * 1.18 + Number(userData2.zohoSecurity)).toFixed(2));
    setHisabDetail({ ...userData2, qu: qu !== 0 ? qu : userData2.quantityAppliances,coastperSecurityApplinaces:coastperSecurityApplinaces })
    updatePlanComp(userData2, fee, total, qu);
  };
  // const handlleTotalAppliance = (app) => {

  //   let diff = (e.security/coastperSecurityApplinaces)-app
  
  //   let totalApp = diff + app
  //   console.log('====================================');
  //   console.log(totalApp,e.totalAppliances,app,diff,e.security,"rrrrrr");
  //   console.log('====================================');
  //   setApp(totalApp)
  // }
  useEffect(() => {
    const check = async () => {
      let res = await CheckClientIsOverdue(e.generatedHex)
      console.log(res,"overdue check");
      setOverdue(res)
    }
    check()
  }, [razPop])
  useEffect(() => {
    if (e.currentSecurity === 0 && razPop === true) {
      notifyFailed("Current Security can not be zero contact admin")
    }
    if(razPop){
      countApplianceInHouse(e).then((res)=>{
        console.log(res);
        if(res.type===1){
           if(res.res.data.success){
            let sub=e
            let newAppliances = res.res.data.newAppliances
            let newTotalAppliances = res.res.data.newAppliances
            let app  =res.res.data.newAppliances
            let collectSecurity = true
          
            if (sub.partialPayment === false) {
              if (Number(sub.totalAppliances) !== Number(app)) {
                if (sub.oldDrop === true) {
                  if (Number(app) > -1) {
                    newAppliances = Number(app)
                    newTotalAppliances = Number(app) + Number(sub.totalAppliances)
                  } else {
                    newAppliances = Number(app)
                    collectSecurity = false
                  }
                } else if (sub.oldDrop === false) {
                  newAppliances = app
                  console.log(newAppliances, "3");
                }
              } else if (Number(sub.totalAppliances) === Number(app)) {
                notify("All Payment Clear")
                newAppliances = 0
                console.log(newAppliances, "4");
              }
            } else if (sub.partialPayment === true) {
              newAppliances = app
            }
            let newApp = newAppliances
            let oldApp = 0
            if (e.changesInSubscription.length > 0) {
              oldApp = e.changesInSubscription[e.changesInSubscription.length - 1].oldAppliances
            }
          
            let totalApp = newApp + oldApp

              setNewAppliances(totalApp)
              setUnpaidAappliance({...res.res.data,newAppliances:totalApp})
      
           }else{
            setUnpaidAappliance({})
            setNewAppliances(0)
           }
        }else{
          let msg = res?.res?.response?.data?.message?(res?.res?.response?.data?.message):res.res.message
          notifyFailed(msg)
          setNewAppliances(0)
          setUnpaidAappliance({})
        }
      })
    }
  }, [e, razPop])
  return (
    <>

      <ConfirmationForPaymentLinkRzp e={e} btnName={"Complete Payment Razorpay"} setRazPop={setRazPop} />


      {razPop === true ? (

        <div className='popUpBackGround'>
          <div className="innerModalPop" style={{maxHeight:"80vh",overflowY:"scroll",marginTop:'5vh'}}>
            <form onSubmit={handleSubmit}>
              <div className="modalCross" >
                <span className='crossIcon' onClick={() => {
                  setRazPop(false)
                  setReloadLink(false)
              setZohoInvoices([])
              setAmount(0)
                }}>
                  x
                </span>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>Name</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      autoComplete="name"
                      placeholder="Name"
                      aria-label="name"
                      aria-describedby="basic-addon1"
                      value={e.name}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Phone Number</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      autoComplete="current-phone"
                      placeholder="Phone"
                      aria-label="phone"
                      aria-describedby="basic-addon1"
                      value={e.phone}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>City</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      name="city"
                      autoComplete="current-city"
                      placeholder="City"
                      aria-label="city"
                      aria-describedby="basic-addon1"
                      value={e.city}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Address</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      name="address"
                      autoComplete="current-address"
                      placeholder="Address"
                      aria-label="address"
                      aria-describedby="basic-addon1"
                      defaultValue={e.address}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                {/* <Grid item xs={12} md={6}>
                  <label>Plan Name</label>
                  <div class="input-group mb-3">

                    <select class="custom-select height-select" required id="inputGroupSelect01" onChange={handlePlan} defaultValue="choose one"
                      name="planName"
                      label="planName"
                      autoComplete="current-planName" value={planName}>
                      {plan.map((option) => (
                        <option value={option.text}>{option.text}</option>
                      ))}
                    </select>
                  </div>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <label>Plan Duration</label>
                  <div class="input-group mb-3">

                    <select className="custom-select height-select" required id="inputGroupSelect01" onChange={handlePlanDuration}
                      name="planDuration"
                      label="planDuration"
                      autoComplete="current-planDuration"
                      defaultValue={e.planFrequency}>
                      {planDuration.map((option) => (
                        <option value={option.value}>{option.value}</option>
                      ))}

                    </select>

                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Security Cost Per Appliance?. </label>
                  <div class="input-group mb-3">
                    <input
                      type="number"
                      class="form-control"
                      id="securityCost"
                      name="securityCost"
                      autoComplete="current-securityCost"
                      placeholder="securityCost"
                      aria-label="securityCost"
                      min={0}
                      aria-describedby="basic-addon1"
                      value={coastperSecurityApplinaces}
                      onChange={(ee)=>{
                            setCoastperSecurityApplinaces(Number(ee.target.value))
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>Overdue Collect?</label>
                  <div class="input-group mb-3">

                  <select class="custom-select height-select" required id="inputGroupSelect01"
                    name="overdueCollect"
                    label="overdueCollect"
                    autoComplete="current-overdueCollect" 
                    style={{zIndex:8}}
                    defaultValue={collectoverdue}
                    onChange={(val) =>{
                      setCollectoverdue(val.target.value==="true"?true:false)
                      if(val.target.value==="false"){
                        setZohoInvoices([])
                      }
                    }}
                    >
                      <option value={true}>{"Yes"}</option>
                      <option value={false}>{"No"}</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Credit Note</label>
                  <div class="input-group mb-3">

                    <select className="custom-select height-select" required id="inputGroupSelect01"
                      name="creditNote"
                      style={{zIndex:8}}
                      label="creditNote"
                      defaultValue={"true"}
                      autoComplete="current-creditNote"
                    >
                      <option value={true}>{"Yes"}</option>
                      <option value={false}>{"No"}</option>
                    </select>

                </div>
              </Grid>
            </Grid>
            {
                collectoverdue ===true?
                <Grid container spacing={2} sx={{zIndex:6}}>
                  <Grid item md={12} sx={{zIndex:7}}>
              <OverdueInvoiceSelect e={e} amount={amount} setAmount={setAmount} setZohoInvoices={setZohoInvoices} pop={collectoverdue}/>
              </Grid>
            </Grid>:null
              }
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <label>Security</label>
                <div class="input-group mb-3">
                  <input
                    type="Number"
                    class="form-control"
                    id="security"
                    name="security"
                    onWheel={event => event.currentTarget.blur()}
                    autoComplete="current-security"
                    placeholder="Security"
                    aria-label="security"
                    aria-describedby="basic-addon1"
                    value={secIns.security}
                    onChange={(val) =>
                      setSecIns({ ...secIns, security: val.target.value })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Installation</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="installion"
                    name="installion"
                    autoComplete="current-installion"
                    placeholder="installion"
                    aria-label="installion"
                    aria-describedby="basic-addon1"
                    value={secIns.installation}
                    onChange={(val) =>
                      setSecIns({ ...secIns, installation: val.target.value })
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              {/* <Grid item xs={12} md={6}>
                <label>Partial Payment</label>
                <div class="input-group mb-3">
                  <input
                    type="Number"
                    class="form-control"
                    id="partial"
                    name="partial"
                    onWheel={event => event.currentTarget.blur()}
                    autoComplete="current-partial"
                    placeholder="Partial"
                    aria-label="partial"
                    aria-describedby="basic-addon1"
                    value={partialAmoun}
                  />
                </div>
              </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <label>Total Appliances </label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      value={apps}
                      onChange={(e)=>setApp(Number(e.target.value))}
                    />
                  </div>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <label>Appliances in House Unpaid</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      disabled
                      class="form-control"
                      value={Object.keys(unpaidAappliance).length>0?(unpaidAappliance?.newAppliances):0}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Appliances</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      id="appliances"
                      name="appliances"
                      onWheel={event => event.currentTarget.blur()}
                      autoComplete="current-appliances"
                      placeholder="Appliances"
                      aria-label="appliances"
                      min={0}
                      aria-describedby="basic-addon1"
                      value={newAppliances}   
                      disabled={!["Akhil","Digvijay"].includes(loginName)?true:false}                  
                       onChange={(val) => {
                        setNewAppliances(Number(val.target.value))
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {
                (moment(e.InstallationDate).format('MM') !== moment(new Date()).format('MM')) && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <label>Delay Month Payment</label>
                      <div class="input-group mb-3">
                        <select class="custom-select height-select" required id="inputGroupSelect01" defaultValue="true"
                          name="postPayment"
                          label="postPayment"
                          autoComplete="current-postPayment" >
                          <option value={true}>{"Yes"}</option>
                          <option value={false}>{"No"}</option>
                        </select>
                      </div>
                    </Grid>
                  </Grid>
                )
              }
             
              <Grid container spacing={4}>
              <Stack direction="row" spacing={3} mt={3} pl={5}>
                  <Button variant="contained" color="primary" type="submit" disabled={btn==="Processing.."?true:false}>
                    {btn}
                  </Button>
                {text !== "" ? (
                  <>
                    
                      <ReactWhatsapp
                        number={whatappNumber}
                        message={text}
                        style={{
                          backgroundColor: "#25D366",
                          border: "none",
                          borderRadius: "12px",
                          padding: "5px",

                        }}
                      >
                        <WhatsApp
                          sx={{
                            color: "white",
                            verticalAlign: "middle",
                            fontSize: "xx-large",
                          }}
                        />
                      </ReactWhatsapp>
                
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => navigator.clipboard.writeText(paymentLink)}
                      >
                         Copy
                      </Button>
                  </>
                ) : null}
                </Stack>
               </Grid>
            </form>
            <HisabForZohoRazorPay
              detail={hisabDetail}
              sub={e}
            />
       

          </div>
        </div>
      ) : null}
    </>
  )
}
