import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { Margin, ReplyTwoTone } from '@mui/icons-material';
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({data,id,type}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const getDate = ()=>{
    console.log("getDate");
    if(data.trnxDetails.length>0 && data.trnxDetails[data.trnxDetails.length-1].hasOwnProperty('date')){
      return moment(data.trnxDetails[data.trnxDetails.length-1].date).format('DD-MM-YYYY')
    } else if(data.trnxRZP.length>0 &&  data.trnxRZP[data.trnxRZP.length-1].hasOwnProperty('date')){
      return moment(data.trnxRZP[data.trnxRZP.length-1].date).format('DD-MM-YYYY')
    } else {
      return moment(data.createdTime).format('DD-MM-YYYY')
    }
    
  }
  return (
    <Card sx={{ maxWidth: 1200, marginBottom:2}}>
     
     <CardContent className='labelCardContent'>
        <div className='labelDivFormating'>
        <label className='subCardLabelFormating' >{type==="inv"?"System_Inv_Number":"Hex"}</label>
        <label className='subCardLabelFormating'>{type==="inv"?(data.invoiceNumber?data.invoiceNumber:'0'):data.generatedHex}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='subCardLabelFormating' >{type==="inv"?"ZohoInvNumber":"Event Type"}</label>
        <label className='subCardLabelFormating'>{type==="inv"?(data.zohoInvoiceNumber?data.zohoInvoiceNumber:'0'):data.eventType}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='subCardLabelFormating' >{type==="inv"?"IsFirstInvoice":"Comments"}</label>
        <label className='subCardLabelFormating'>{type==="inv"?(data.isFirstInvoice?data.isFirstInvoice:'false'):data.comments}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='subCardLabelFormating' >Amount</label>
        <label className='subCardLabelFormating'>{type==="inv"?(data.actualTotalAmount?data.actualTotalAmount:data.amount):data.amount}</label>
        </div>
        {type==="inv"?
        <div className='labelDivFormating'>
        <label className='subCardLabelFormating' >IsUpsell</label>
        <label className='subCardLabelFormating'>{data.is_UpSell?data.is_UpSell:'false'}</label>
        </div>:null
        }
        
        <div className='labelDivFormating'>
        <label className='subCardLabelFormating' >Status</label>
        <label className='subCardLabelFormating'>{type==="inv"?(data.status?data.status:'paid'):data.status}</label>
        </div>
        <div className='labelDivFormating'>
        <label className='subCardLabelFormating' >CaptureDate</label>
        <label className='subCardLabelFormating'>{type==="inv"?(getDate()):moment(data.createdAt?data.createdAt:data.createdAt).format('DD-MM-YYYY')}</label>
        </div>
        
      </CardContent>
      <CardActions disableSpacing>
        
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{height:0,outline:'none',border:'none'}}
        >
          
        </ExpandMore>
      </CardActions>
      
    </Card>
  );
}
