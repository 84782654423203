import axios from 'axios'
import React, { useRef, useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { department } from '../Data'

export default function ({e,reload, setReload,  notify}) {
    let [changeDepartmentPoP,setChangeDepartmentPoP] = useState(false)
    let departments = useRef("Sales")
    const changeDepartmentFun = (dep)=>{
        axios.post(`${serverUrl.u}/api/tickets/ChangeDepartment`,{
            department:dep, 
            tickedId:e._id
        }).then(function (res) {
           console.log(res); 
           if(res.status===200){
            setReload(!reload)
            notify("Department Changed")
            setChangeDepartmentPoP(false)
           }else{
            notify(res.data.message)
           }
        }).catch(function (err) {
            console.log(err);
            notify(err.message)
        })
    }
  return (
    <>
    <button className='btn btn-primary' onClick={()=>setChangeDepartmentPoP(true)}>Change Department</button>
{
    changeDepartmentPoP===true?
    <div className="greivance-container" style={{position:"fixed", top:"35% !important"}}>
        <span style={{position:"absolute",right:"15px",fontSize:"25px"}} onClick={() => setChangeDepartmentPoP(!changeDepartmentPoP)}>x</span>
       
        <label  style={{marginTop:"25px"}}>Select Stage</label>
        <div class="input-group mb-3">
          <select defaultValue="Sales" ref={departments} style={{boxShadow: "0px -25px 20px -20px rgba(0, 0, 0, 0.45), 25px 0 20px -20px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45)",width:"100%",borderRadius:"12px",fontSize:"18px"}}>
                 {
                  department.map((e,i)=>{
                    return(
                      <option value={e.value}>{e.value}</option>
                    )
                  })
                 }
{/*                    
                   <option value="General">General</option>
                   <option value="Dispatch">Dispatch</option>
                   <option value="Sales">Sales</option>
                   <option value="Refund">Refund</option> */}
          </select>
        </div>
       
        <button className='btn btn-primary' onClick={()=>changeDepartmentFun(departments.current.value)}>Change Department</button>
    </div>:null
  }
    </>
  )
}
