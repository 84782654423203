import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { planDuration } from '../Data'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import SubscriptionHisab from './SubscriptionHisab'
import { notifyFailed } from '../Common/Notification'
import { revertMandate } from '../Function/AxiosControl'

export default function UpgradeDowngradeUi({ pop, setPop, data,setReload, reload }) {
  let userRole = useSelector((state) => state.sar.data?.user?.roles)
  // let [amount,setAmount] = useState(0)
  let [drop, setDrop] = useState(false)
  let [overDueInvoiceCount,setOverDueInvoiceCount] = useState(0)
  const [upgradeDowngradeData,setUpgradeDowngradeData] = useState({})
  const notify = (text) => toast(text);
  useEffect(()=>{
    if(pop===false) return
    setOverDueInvoiceCount(0)
    if(data.reatachMandate===true){
      revertMandate(data.generatedHex)
    }
    // invoiceDetails(data.generatedHex)
  },[pop])
 
  const handleSubmit = (event) => {

    event.preventDefault();
    const datas = new FormData(event.currentTarget);
    let sendData = {
      app: datas.get('appliances'),
      fre: datas.get('planDuration'),
      choose: datas.get('choose'),
      drop: datas.get('drop'),
      partialAmount: datas.get('partialAmount'),
      installations:datas.get('installations'),
    }
    if (Number(sendData.app)!==data.totalAppliances) {
      console.log("come 1");
      if (Number(sendData.app)>data.totalAppliances) {
        handeleUpGrade(sendData)
      } else {
        handeleDownGrade(sendData)
      }
    } else {
      console.log(sendData.drop,"drop");
     console.log("come 2");
      if (sendData.fre!==data.planFrequency) {
        let newFrequency = planDuration.filter((ele) => {
          if (ele.value === sendData.fre) {
            return ele
          }
        })
        let oldFrequency = planDuration.filter((ele) => {
          if (ele.value === data.planFrequency) {
            return ele
          }
        })
        console.log("new", newFrequency, "old", oldFrequency, data)
        if (newFrequency[0].id > oldFrequency[0].id ) {
          console.log("upgrade");
          handeleUpGrade(sendData)
        } else if (newFrequency[0].id < oldFrequency[0].id ) {
          console.log("downgrade");
          handeleDownGrade(sendData)
        }
      }
    }
  }
  const handeleDownGrade = (sendData) => {
     if(overDueInvoiceCount>1 && sendData.overdueDecision==="false" && !userRole.includes("Super Admin")) {
      
        notify("Please take overdue amount client has more than 1 overdue")
        return
     }
     let toApp = sendData.app === null ? Number(data.totalAppliances) : Number(sendData.app)
     if(Number(toApp)<1){
       notify(`Appliances in minus ${toApp}`)
       return
     }
     let detail = {
      totalAppliances: sendData.app === null ? Number(data.totalAppliances) : Number(sendData.app),
      planFrequency: sendData.fre === null ? data.planFrequency : sendData.fre,
      PaymentMode: "razorpay",
      generatedHex: data.generatedHex,
      downGrade: ((data.planFrequency !== sendData.fre) && (data.totalAppliances !== Number(sendData.app))) ? "Both" : ((data.totalAppliances !== Number(sendData.app) ? "Appliances" : "Frequency")),
      isFrequencyUpgrade: data.planFrequency === sendData.fre ? false : true,
      collectOverduePayment:false,
      installations:Number(sendData.installations),
      partialPayment:true,
      paymentAmount:0,
      overDueAmount:0,
      oldClient:false,
      oldDrop:true,
    }
   setUpgradeDowngradeData(detail)
  }
  const handeleUpGrade = (sendData) => {
     console.log(sendData,"dd");
     if(overDueInvoiceCount>1 && sendData.overdueDecision==="false" && !userRole.includes("Super Admin")) {
      notify("Please take overdue amount client has more than 1 overdue")
      return
   }
   console.log(sendData.app,"app");
   let toApp =sendData.app === null ? Number(data.totalAppliances) : (sendData.drop == "false" ? Number(sendData.app) - Number(data.totalAppliances) : Number(sendData.app))
   if(Number(toApp)<0){
    notify(`Appliances in minus ${toApp}`)
    return
  }
  if(Number(toApp)===0){
    toApp=Number(data.totalAppliances);
  }
  let detail = {
    totalAppliances: toApp,
    planFrequency: sendData.fre === null ? data.planFrequency : sendData.fre,
    PaymentMode: "razorpay",
    generatedHex: data.generatedHex,
    upGrade: ((data.planFrequency !== sendData.fre) && (data.totalAppliances !== Number(sendData.app))) ? "Both" : ((data.totalAppliances !== Number(sendData.app) ? "Appliances" : "Frequency")),
    isFrequencyUpgrade: data.planFrequency === sendData.fre ? false : true,
    oldDrop: sendData.drop == "true" ? true : false,
    partialPayment:true,
    paymentAmount:Number(sendData.partialAmount),
    installations:Number(sendData.installations),
    collectOverduePayment:false,
    overDueAmount:0,
    oldClient:false,
    type:"upgradeSubscriptions"
  }
  setUpgradeDowngradeData(detail)
}
  const handleApplianceChange = (app) => {
    let drop = data.totalAppliances - Number(app)
    if (drop < 0) {
      setDrop(true)
    } else {
      setDrop(false)
    }
  }


   const checkUpgradeDownGrade = (value)=>{
    let newFrequency = planDuration.filter((ele) => {
      if (ele.value === value) {
        console.log(ele,"newFre");
        return ele
      }
    })
    let oldFrequency = planDuration.filter((ele) => {
      if (ele.value === data.planFrequency) {
        return ele
      }
    })
    console.log(newFrequency,oldFrequency,"ild");
    if (newFrequency[0].id > oldFrequency[0].id) {
      console.log("upgrade");
      setDrop(true)
      
    } else if (newFrequency[0].id < oldFrequency[0].id) {
      console.log("downgrade");
       setDrop(true)
    }
   }
  return (
    <div>
      {pop === true ? (
        <div className='popUpBackGround'>
        <div className="innerModalPop">
          <form onSubmit={handleSubmit}>
           
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <label>Name</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    autoComplete="name"
                    placeholder="Name"
                    aria-label="name"
                    aria-describedby="basic-addon1"
                    value={data.name}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Phone Number</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    name="phone"
                    autoComplete="current-phone"
                    placeholder="Phone"
                    aria-label="phone"
                    aria-describedby="basic-addon1"
                    value={data.phone}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={4}>

              <Grid item xs={12} md={6}>
                <label>Appliances</label>
                <div class="input-group mb-3">
                  <input
                    type="Number"
                    class="form-control"
                    id="appliances"
                    name="appliances"
                    onWheel={event => event.currentTarget.blur()}
                    autoComplete="current-appliances"
                    placeholder="Appliances"
                    aria-label="appliances"
                    aria-describedby="basic-addon1"
                    defaultValue={data.totalAppliances}
                    onChange={(ele) => handleApplianceChange(ele.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <label>Plan Duration</label>
                <div class="input-group mb-3">

                  <select className="custom-select height-select" required id="inputGroupSelect01"
                    name="planDuration"
                    label="planDuration"
                    autoComplete="current-planDuration"
                    defaultValue={data.planFrequency}
                    onChange={(ev)=>checkUpgradeDownGrade(ev.target.value)}
                    >
                    {planDuration.map((option) => {
                      if (option.value !== "Test (Weekly)") {
                        return (
                          <option value={option.value}>{option.value}</option>
                        )
                      }
                    })}

                  </select>

                </div>
              </Grid>
            </Grid>


            <Grid container spacing={4}>

            
            
                  <Grid item xs={12} md={6}>
                    <label>Drop Old Subscription</label>
                    <div class="input-group mb-3">
                      <select className="custom-select height-select"
                        required id="inputGroupSelect01"
                        name="drop"
                        label="drop"
                        autoComplete="current-drop"
                        value={drop}
                        onChange={(ele)=>setDrop(ele.target.value==="true"?true:false)}

                      >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>

                  </Grid>
                  <Grid item xs={12} md={6}>
                <label>Partial Amount</label>
                <div class="input-group mb-3">
                  <input
                    type="Number"
                    class="form-control"
                    id="partialAmount"
                    name="partialAmount"
                    onWheel={event => event.currentTarget.blur()}
                    autoComplete="current-partialAmount"
                    placeholder="partialAmount"
                    aria-label="partialAmount"
                    aria-describedby="basic-addon1"
                    defaultValue={0}
                  />
                </div>
              </Grid>
              </Grid>
         
                  
              
                  <Grid container spacing={4}>
                      <Grid item xs={12} md={6}>
                <label>Installation Charges</label>
                <div class="input-group mb-3">
                  <input
                    type="Number"
                    class="form-control"
                    id="installations"
                    name="installations"
                    onWheel={event => event.currentTarget.blur()}
                    autoComplete="current-installations"
                    placeholder="installations"
                    aria-label="installations"
                    aria-describedby="basic-addon1"
                    defaultValue={0}
                  />
                </div>
              </Grid>
             
                  </Grid>
              

              <Grid container spacing={4} mt={1}>
                
               
                
                   <SubscriptionHisab
                     detail={upgradeDowngradeData}
                     setDetail={setUpgradeDowngradeData}
                     sub={data}
                     setDrop={setDrop}
                     setPop={setPop}
                     setReload={setReload}
                     reload={reload}
                   />
            

            </Grid>
          </form>


        </div>
        </div>
      ) : null}
    </div>
  )
}
