import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { kmReportDaily, kmsReportTableAccess, lastLocationLogElectrician, locationOfClientSites } from "./Api";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import './map.css'
import { availableElectrician } from "../Redux/installationActions";
import { CSVLink } from "react-csv";
const mapContainerStyle = {
  width: "100%",
  height: "75vh",
};

function Map() {
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)

  const electrician = useSelector((state) => state.electrician);
  console.log(electrician, "cdcccc");
  const [activeMarker, setActiveMarker] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [dataSite, setDataSite] = useState([]);
  const [reportKm, setReportKm] = useState([]);
  useEffect(() => {
    availableElectrician();
    lastLocationLogElectrician().then((res) => {
      console.log(res,"ggg");
      setData(res);
    });
    locationOfClientSites().then((res) => {
      setDataSite(res);
    });  
  }, [refresh]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    data.forEach((position) => bounds.extend(position));
    dataSite.forEach((position) => bounds.extend(position));
    map.fitBounds(bounds);
  };
  const electricianInfo = (id) => {
    console.log(id);
    let findd = {};
    findd = electrician.find((e) => e.employeeId === id);
    console.log(findd, "ff");
    if (
      findd !== undefined &&
      Object.keys(findd).length !== 0 &&
      findd.firstName
    ) {
      return findd.firstName;
    } else {
      return "";
    }
  };
  const handleSubmitDate = async (e) => {
    e.preventDefault();
    setReportKm([])
    let data = new FormData(e.currentTarget);

    let date = {
      startDate: data.get('startDate'),
      endDate: data.get('endDate'),
    }
   kmReportDaily(date).then(res=>{
    if(res.length>0){
       setReportKm(res)
    }else{
      setReportKm([])
    }
   })
  }
  return (
    <div style={{ width: "100%", padding: "0 4%", marginTop: "5vh" }}>
          <form onSubmit={handleSubmitDate} >
        <div className='row' style={{ marginBottom: "5px" }}>
          <div className='col-2 '>
            <label>Start Date</label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                name="startDate"
                defaultValue={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substr(0, 10)}
                autoComplete='current-startDate'
              />
            </div>
          </div>
          <div className='col-2 '>
            <label>End Date</label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                name="endDate"
                defaultValue={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10)}
                autoComplete='current-endDate'
              />
            </div>
          </div>
          <div className='col-2'>
            <label></label>
            <div className='input-group mt-2 '>
              <button className='btn btn-success' style={{ fontSize: "26px !important" }} type='submit'>Download Kms Report</button>
            </div>
          </div>
          <div className='col-2'>
            <label></label>
            <div className='input-group mt-2 '>
           {reportKm.length>0 && <CSVLink className='btn btn-primary' filename="KmReport.csv" style={{ marginLeft: "2%",marginTop:"2%",marginBottom:"5%" }} data={reportKm}>Download CSV</CSVLink>}
            </div>
          </div>
        </div>
      </form>
      <Button
        variant="contained"
        sx={{ mb: 2 }}
        onClick={() => setRefresh(!refresh)}
      >
        Refresh
      </Button>
  
      <LoadScript
        googleMapsApiKey="AIzaSyBqO5gAoCNGU4GU_BoWui5KZyZ8ULZrEuA"
        loadingElement="Loading..."
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={data.length !== 0 && data[0]}
          onLoad={handleOnLoad}
        >
          <>
          {data.map((location) => (
            <div key={location.employeeId}>
            <Marker
            icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}

              key={location.employeeId}
              position={location}
              onClick={() => handleActiveMarker(location.employeeId)}
            >
            
                 <OverlayView
                 position={location}
                 mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
               >
                   <div className="custom-info">
                    {location.employeeId} -{" "}
                    {electricianInfo(location.employeeId)}
                  </div>
                </OverlayView>
            </Marker>
            </div>
          ))}
          {dataSite.map((location) => (
             <div key={location.generatedHex}>
            <Marker

              key={location.employeeId}
              position={location}
              onClick={() => handleActiveMarker(location.generatedHex)}
            >
              {/* {activeMarker === location.generatedHex ? ( */}
                {/* <InfoWindow > */}
                <OverlayView
                position={location}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                  <div className="custom-info">
                    {location.name} - {location.city}
                  </div>
                  </OverlayView>
                {/* </InfoWindow> */}
              {/* ) */}
             {/* : null}  */}
            </Marker>
            </div>
             ))}
        </>
        </GoogleMap>
      </LoadScript>
      {(userRole?.includes("Super Admin") || kmsReportTableAccess.includes(employeeId)) && (
        <div className="mt-4">
          <div className="mb-2">Month Wise KMs</div>
          <iframe
            style={{
              background: "#FFFFFF",
              border: "none",
              borderRadius: "2px",
              boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
            }}
            width="100%"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=6688fd95-995d-43a9-85ca-7623100a6f5b&maxDataAge=300&theme=light&autoRefresh=true"
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default Map;
