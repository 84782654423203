import React, { useState } from "react";
import { fetchCreditNote, fetchCreditNoteComplete, fetchCreditNoteCsv, fetchSequrityReceipt } from "./Api";
import { notify, notifyFailed } from "../Common/Notification";
import { CSVLink } from "react-csv";
import { Stack } from "@mui/material";
import EditCreditNote from "./component/EditCreditNote";

function CreditNote() {
  const [data,setData]  = useState([])
  const [completeData,setCompleteData]  = useState([])
  const [dates,setDates] = useState({})
  const refresh = async(date)=>{
    await fetchCreditNoteCsv(date).then(res=>{
       
      if(res.type===1){
          if(res.data.success){
            if(res.data.data.length>0){
           setData(res.data.data)
            }else{
              notify('Data Not Found')
            }
           
          }else{
              notifyFailed(res.data.message)
          }
      }else{
          let msg = res?.response?.data?.message?(res.response.data.message):res.message
          notifyFailed(msg)
      }
  })
  await fetchCreditNoteComplete(date).then(res=>{
     
    if(res.type===1){
        if(res.data.success){
         setCompleteData(res.data.data)
         
        }else{
            notifyFailed(res.data.message)
        }
    }else{
        let msg = res?.response?.data?.message?(res.response.data.message):res.message
        notifyFailed(msg)
    }
})
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData(e.currentTarget);

    let date = {
      firstDate: data.get("startDate"),
      lastDate: data.get("endDate"),
    };
    setDates(date)
    await refresh(date)
  };
  return (
    <div style={{ width: "100%", padding: "0 4%", marginTop: "5vh" }}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-2 ">
            <label>Start Date</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="startDate"
                defaultValue={new Date(
                  new Date().setDate(new Date().getDate() - 10)
                )
                  .toISOString()
                  .substr(0, 10)}
                autoComplete="current-startDate"
              />
            </div>
          </div>
          <div className="col-2 ">
            <label>End Date</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="endDate"
                defaultValue={new Date(
                  new Date().setDate(new Date().getDate() + 1)
                )
                  .toISOString()
                  .substr(0, 10)}
                autoComplete="current-endDate"
              />
            </div>
          </div>
          <div className="col-2">
            <label></label>
            <div className="input-group mt-2 ">
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "2%",
                  marginTop: "2%",
                  marginBottom: "5%",
                }}
                type="submit"
              >
                Get Data
              </button>
            
            </div>
          </div>
          {
            data.length>0 && (
                <div className="col-2">
                <label></label>
                <div className="input-group mt-2 ">
                <CSVLink data={data} filename="Credit_Note.csv">
                  <button 
                    type="button" 
                    className="btn btn-primary"
                    style={{
                        marginLeft: "2%",
                        marginTop: "2%",
                        marginBottom: "5%",
                      }}
                  >Download</button>
                </CSVLink>
                </div>
                </div>
            )
          }
        
        </div>
      </form>

      <div className='table-container1' style={{ marginTop: "10px", marginBottom: "30px", height: "fit-content" }} key={"imp"}>
              <table className='table table-sm table-bordered'>
                <thead>
                  <tr >
                    <th>Sr. No.</th>
                    <th>Credit Note Number</th>
                    <th>Name</th>
                    <th>Hex</th>
                    <th>Amount</th>
                    <th>CGST</th>
                    <th>IGST</th>
                    <th>SGST</th>
                    <th>Total Amount</th>
                    <th scope="col">Zoho Invoice Number</th>
                    <th>Invoice Number</th>
                    <th>Created Date</th>
                    <th>City</th>
                    <th>Plan Code</th>
                    <th>Plan Frequency</th>
                    <th>Quantity</th>
                    <th>Un Used Days</th>
                    <th>Gst Client</th>
                    <th>Gst Nane</th>
                    <th>Gst Number</th>
                    <th>Master Cancel</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {

                     data.map((e, i) => {
                      let creditNoteData = completeData.find(cr=>cr._id===e.creditNoteId)
                      return (
                        <tr key={i} >
                          <td>{(i + 1)}</td>
                          <td>{e.creditNoteNumber}</td>
                          <td>{e.name}</td>
                          <td>{e.generatedHex}</td>
                          <td>{e?.amount.toFixed(2)}</td>
                          <td>{e?.CGST.toFixed(2)}</td>
                          <td>{e?.IGST.toFixed(2)}</td>
                          <td>{e?.SGST.toFixed(2)}</td>
                          <td>{e?.Totalamount.toFixed(2)}</td>
                          <td>{e.zohoInvoiceNumber}</td>
                          <td>{e?.invoiceNumber}</td>
                          <td>{e?.createdDate}</td>
                          <td>{e.city}</td>
                          <td>{e?.planCode}</td>
                          <td>{e?.planFrequency}</td>
                          <td>{e?.qty}</td>
                          <td>{e?.unusedDays}</td>
                          <td>{e.isGstClient?"Yes":'No'}</td>
                          <td>{e?.gstName}</td>
                          <td>{e?.gstNumber}</td>
                          <td>{e.masterCancel?"Yes":'No'}</td>
                          <td>
                            <Stack>
                             {creditNoteData!==undefined && Object.keys(creditNoteData).length>0 && <EditCreditNote data={creditNoteData} date={dates} refresh={refresh}/>}
                            </Stack>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
    </div>
  );
}

export default CreditNote;
