import { useEffect, useState } from 'react';
import { apiRequest } from '../../utils';
import { serverUrl } from '../ApiUrl';
import { notify, notifyFailed } from '../Common/Notification';
import { Box, Button, Modal, TextField } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { LedgerTransactionRecord } from './LedgerTransactionRecord';

export default function LedgerForGeneratedHex({ generatedHex }) {
  const [data, setData] = useState([]);
  const [firstDate, setFirstDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [open, setOpen] = useState(false);
  const [finalLedger, setFinalLedger] = useState([]);
  const fetchLedger = async () => {
    if (firstDate === '' || lastDate === '') {
      notifyFailed('Please enter the dates!!');
      return;
    }
    const response = await apiRequest({
      url: `${serverUrl.sub}/invoice/ledger`,
      method: 'POST',
      data: {
        // generatedHex: '2877d3',
        // firstDate: '2022-11-10',
        // lastDate: '2024-02-12',
        firstDate,
        lastDate,
        generatedHex,
      },
    });

    if (!response?.success) {
      notifyFailed(response?.message || 'Failed to fetch ledger!!');

      return;
    }

    notify(response?.data?.message);
    setData(response?.data?.data);
  };

  function processData() {
    if (!data.length) return;

    let finalData = [];
    let sno = 0;
    for (const ledger of data) {
      sno += 1;
      finalData.push({
        sno,
        date: ledger?.billingDate,
        hexCode: ledger?.hex,
        invoiceNumber: ledger?.invoiceNumber,
        duration: ledger?.duration,
        debit: ledger?.amount,
        credit: ledger?.credit,
        status: ledger?.status,
        trnxRecord: ledger?.trnxRecord,
      });

      if (ledger?.creditNoteNumber) {
        sno += 1;

        finalData.push({
          sno,
          date: ledger?.billingDate,
          hexCode: ledger?.hex,
          invoiceNumber: ledger?.invoiceNumber,
          duration: ledger?.duration,
          debit: ledger?.amount,
          credit: ledger?.creditNoteAmount,
          status: ledger?.status,
          trnxRecord: ledger?.trnxRecord,
        });
      }
    }

    setFinalLedger(finalData);
  }

  useEffect(() => {
    processData();
  }, [data]);

  const columns = finalLedger[0]
    ? Object.keys(finalLedger[0]).map((h) => {
        if (h === 'trnxRecord') {
          return {
            field: 'trnxRecord',
            headerName: 'TrnxRecord',
            renderCell: (params) => {
              return (
                <LedgerTransactionRecord
                  transactionRecord={params?.row?.trnxRecord}
                />
              );
            },
            width: 200,
          };
        }
        return {
          field: h,
          headerName: h[0].toUpperCase() + h.slice(1),
          width: 150,
        };
      })
    : [];

  return (
    <div>
      <Button variant='contained' onClick={() => setOpen(true)}>
        Ledger
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            maxHeight: 500,

            // maxWidth: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            overflow: 'scroll',
            p: 4,
          }}
        >
          <div>
            <h3 style={{ marginBottom: '20px' }}>Ledger for {generatedHex}</h3>
            <div style={{ display: 'flex', gap: '20px', overflowY: 'scroll' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '30px' }}
              >
                <label>First Date:</label>
                <TextField
                  type='date'
                  value={firstDate}
                  onChange={(e) => setFirstDate(e.target.value)}
                />
              </div>

              <div
                style={{ display: 'flex', alignItems: 'center', gap: '30px' }}
              >
                <label>Last Date:</label>
                <TextField
                  type='date'
                  value={lastDate}
                  onChange={(e) => setLastDate(e.target.value)}
                />
              </div>

              <Button variant='contained' onClick={fetchLedger}>
                Get Ledger
              </Button>
            </div>
            <div style={{ padding: 5, paddingTop: 10 }}>
              {finalLedger.length ? (
                <DataGrid
                  columns={columns}
                  rows={finalLedger.map((d) => ({
                    id: d?.sno,
                    ...d,
                  }))}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      csvOptions: { fileName: `Created_time` },
                    },
                  }}
                  pageSizeOptions={[10, 20, 30]}
                />
              ) : (
                <h2 style={{ textAlign: 'center' }}>No ledger to show!!</h2>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
