import { Box, Button, Modal, Stack } from '@mui/material'
import React from 'react'
import { updateGstDetailsInInvoices } from '../Common/applianceDifference';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
};

function UpdategstDetial({inv}) {
    const [open, setOpen] = React.useState(false);
    const [btn,setBtn] = React.useState("Submit")
    const handleClose = () => {
        setOpen(false)
    };
    const handleGSTDetails =  async(event)=>{
        event.preventDefault();
      
        const data = new FormData(event.currentTarget);
            let detail = {
                gstNumber:data.get('gstNumber'),
                gstName:data.get('gstName'),
              
            }
      let res = await updateGstDetailsInInvoices(inv.invoiceNumber,detail.gstName,detail.gstNumber)
        if(res){
            handleClose()
        }
      }   
    return (
        <>
            <button 
              className='btn btn-primary'
              onClick={() => setOpen(true)}
            >
                Update GST Details
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack direction="row" spacing={2} >
                    
                                <form onSubmit={handleGSTDetails}>
                                  
                                    <label className="specific-label">GST Name</label>
                                    <div className="input-group">
                                        <input type="text"
                                            name="gstName"
                                            autoComplete='current-gstName'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                        />
                                    </div>
                                    <label className="specific-label">GST Number</label>
                                    <div className="input-group">
                                        <input type="text"
                                            name="gstNumber"
                                            autoComplete='current-gstNumber'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                        />
                                    </div>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type="submit"
                                        className='mt-2'
                                        disabled={btn==="processing..."?true:false}
                                    >
                                        {btn}
                                    </Button>
                                </form>
                    </Stack>
                   
                </Box>
            </Modal>
        </>
    )
}

export default UpdategstDetial