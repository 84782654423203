import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverUrl } from "./ApiUrl";
import "./SearchClient.css";
import { useSelector } from "react-redux";
import SearchResult from "./Function/SearchResult";
import Spinner from "./Function/Spinner";
import { upgradePendingEmptyRequestAll, upgradeRequestEmptyAll } from "./Redux/UpgradeRequestAction";
import { useDispatch } from "react-redux";
import { notify, notifyFailed } from "./Common/Notification";
export default function SearchClient() {
  let [searchDetail, setSearchDetail] = useState({ name: "Anmol", generatedHex: false })
  let [spinner, setSpinner] = useState(false)
  let [details, setDetails] = useState([]);
  let [reloadW, setReload] = useState(false);
  let loginName = useSelector((state) => state.sar.data.user.first_name);

  let dispatch = useDispatch()
  let [expand, setexpand] = useState(false)
  useEffect(() => {
    searchCustomer(searchDetail)
    dispatch(upgradeRequestEmptyAll())

    dispatch(upgradePendingEmptyRequestAll())
  }, [])
  useEffect(()=>{
    searchCustomer(searchDetail)
  },[reloadW])


  const searchCustomer = (data) => {
    setDetails([])
    setSpinner(true)
    axios
      .post(`${serverUrl.u}/api/tele/searchClient`, {
        value: data.name,
        generatedHex: data.generatedHex,
      })
      .then(function (res) {
        if (res.data.success === true) {
          setDetails(res.data.data);
          if (res.data.data.length === 1) {

            setexpand(true)
          } else {
            setexpand(false)
          }
          notify("Data Load Successfully");
          setSpinner(false)
        } else {
          notify(res.data.message);
          setSpinner(false)
        }
      })
      .catch(function (err) {
        console.log(err);
        notifyFailed("Client Search Failed");
        setSpinner(false)
      });
  };

  const borserRadiusInput = { borderRadius: "12px" }
  const checkInuptValue = (value) => {
    var hasNumber = /\d/;
    let valueGen = false;
    if (value.length === 6) {
      valueGen = hasNumber.test(value);
    } else if (value.length > 14 && value.length === 24) {
    }
    searchCustomer({ name: value, generatedHex: valueGen })
  }
  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      checkInuptValue(event.target.value.trim())
    }
  }
  return (
    <div style={{ backgroundColor: "#F6F6F6", height: "90vh", overflowY: "scroll", width: "100%", padding: "0 2%" }} className="searchContainer">
      <div style={{ textAlign: "right", }}>
        <img
          onClick={() =>
            window.open(
              "https://docs.google.com/document/d/1D838nfj_n4HR8pHXWASB31Fcbv0uq18xNQ4ZbJIQoFg/edit?usp=sharing",
              "_blank",
              "noreferrer"
            )
          }
          src={process.env.PUBLIC_URL + "/assets/icon/user-guide.png"}
          style={{ height: "38px", marginTop: "2vh" }}
        />
      </div>
      <div style={{ marginRight: 0 }}>
        <div>

          <div className="row mt-2" style={{ zIndex: 4, position: "relative" }}>
            <div className="col-md-5 col-sm-12">
              <div class="input-group ">
                <input type="text" style={borserRadiusInput} class="form-control" placeholder="Search By Client Name,Phone Number,GeneratedHex" aria-label="name" aria-describedby="basic-addon1" onBlur={(e) => {
                  setSearchDetail({ name: e.target.value.trim() })
                }} onKeyDown={handleKeyDown}/>
              </div>
            </div>

            <div className="col-md-2 col-sm-12">
              <button
                className="btn btn-primary search-btn"
                onClick={() => {
                  checkInuptValue(searchDetail.name)
                }}
              >
                Search
              </button>
            </div>
          </div>
          {
            spinner === true ?
              <Spinner /> : null
          }

          {
            details.length !== 0 ?
              details.map(data => {
                return (
                  <SearchResult
                    e={data}
                    loginName={loginName}
                    setReload={setReload}
                    reload={reloadW}
                    expand={expand}
                  />
                )
              }) : null
          }

        </div>
      </div>
      <div style={{ height: "50px" }}></div>

    </div>
  );
}
