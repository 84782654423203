import axios from "axios";
import React, { useEffect, useState } from "react";
import { notify, notifyFailed } from "../Common/Notification";
import { serverUrl } from "../ApiUrl";
import NotificationModal from "./modal";
import { Button } from "@mui/material";
// import { notifyFailed } from '../Common/Notification';

export default function Notification() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [edit, setEdit] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState({});


  const fetchDetails = () => {
    // setLoading(true);
    axios
      .get(`${serverUrl.u}/notification/fetchAllNotifications`)
      .then((res) => {
        setLoading(false);
        setResponse(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed(err?.response?.data?.message);
      });
  }
  useEffect(() => {
    setLoading(true);
    fetchDetails()
  }, []);

  const showModal = (n) => {
      setData(n);
    setEdit(true);
  }

  const deleteNotification = (nt) => {
    setLoading(true);
    axios.post(`${serverUrl.u}/notification/deleteNotification/${nt._id}`).then(res => 
        {
        setLoading(false);
        fetchDetails();
        notify(res?.data?.message)
        }
        ).catch(err => {
            setLoading(false);
            notifyFailed(err?.data?.message)
        })
  }
  
  
  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="row">
        <div className="col-2">
          {/* <label className="create-label1">Select Subscription Type</label>
          <select
          // defaultValue={subscriptionType}
          // onChange={(e) => setSubscriptionType(e.target.value)}
          >
            {
              // orderTyepes.map((type) => (
              //   <option>{type?.value}</option>
              // ))
            }
          </select> */}
        </div>
        <div className="col-2">
          {/* <label className="create-label1">Start Date</label>
          <div class="input-group ">
            <input
              type="date"
              // onChange={
              //     (e) => setFirstDate(e.target.value)
              //     }
            />
          </div> */}
        </div>
        <div className="col-2">
          {/* <label className="create-label1">End Date</label>
          <div className="input-group ">
            <input
              type="date"
              onChange={(e) => {
                // setLastDate(e.target.value);
              }}
            />
          </div> */}
        </div>
        <div className="col-2">
          {/* <label className="create-label1">First Installation Date</label>
          <div className="input-group ">
            <input
              type="date"
              onChange={(e) => {
                // setInstallationDate(e.target.value);
              }}
            />
          </div> */}
        </div>
        <div className="col-2">
          {/* <label className="create-label1">Last Installation Date</label>
          <div className="input-group ">
            <input
              type="date"
              onChange={(e) => {
                // setLastInstallationDate(e.target.value);
              }}
            />
          </div> */}
        </div>
        <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
                setEdit(true);
            }}
          >
            Add New
          </button>
        </div>
        {/* <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            // disabled={btn === "processing..." ? true : false}
            onClick={() => {
              //   generateCsvData();
            }}
          >
            Generate CSV
          </button>
        </div> */}
      </div>

      {
        // loading && <Spinner />
      }
      <div className="table-container1" style={{height: "90vh"}}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Title</th>
              <th>Body</th>
              <th>PrefferedTime</th>
              <th>Days</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              //   Object.keys(response).length > 0 &&  Object.keys(statusToDisplay).map(status => {
              //    return (
              //      <tr style={{ backgroundColor: colors[status] }}>
              //        <td>{status}</td>
              //        {tele.map((tc) => {
              //          return (
              //            <td>
              //              {statusToDisplay[status] === "calculate"
              //                ? calculateData(status, tc)
              //                : response[tc][statusToDisplay[status]]}
              //            </td>
              //          );
              //        })}
              //      </tr>
              //    );
              //   })

              response.length > 0 &&
                response.map((n) => {
                  return (
                    <tr>
                      <td>{n.title}</td>
                      <td>{n.body}</td>
                      <td>
                        <div>
                          <ul>
                            {n.preferedTime.map((pt) => (
                              <li>{pt}</li>
                            ))}
                          </ul>
                        </div>
                      </td>
                      <td>
                        <div>
                          <ul>
                            {n.days.map((d) => (
                              <li>{d}</li>
                            ))}
                          </ul>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <Button
                            onClick={() => showModal(n)}
                            style={{
                              backgroundColor: "#1F75FE",
                              color: "white",
                              borderRadius: "10px",
                              boxShadow: "none",
                              padding: "5px 10px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-2 h-2"
                              style={{ width: "30px", height: "auto" }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Button>

                          <Button
                            style={{
                              backgroundColor: "#E23D28",
                              color: "white",
                              borderRadius: "10px",
                              boxShadow: "none",
                              padding: "10px 10px",
                            }}
                            onClick={() => deleteNotification(n)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                              style={{ width: "30px", height: "auto" }}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })
            }
          </tbody>
        </table>
      </div>
      
      <NotificationModal open={edit} setOpen={setEdit} fetchDetails={fetchDetails} data={data}/>
    </div>
  );
}
