import { useState } from 'react'
import axios from 'axios'
import React from 'react'
import { MovementTransactionsType, serverUrl } from '../../ApiUrl'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { notify, notifyFailed } from '../../Common/Notification'
import moment from 'moment'

function Movement() {
    let token = useSelector((state) => state.sar.data?.token)
    let movementType = Object.keys(MovementTransactionsType)
    const [devices, setDevices] = useState([])
    const deviceList = () => {
        axios.get(`${serverUrl.s}/movements/withUser`, {
            headers: {
                AccessToken: token
            }
        }).then((res) => {
            console.log(res);
            if (res.data.success) {
                notify(res.data.message);
                if (res.data.data.devices.length === 0) {
                    notify("Device not found")
                }
                setDevices(res.data.data.devices)
            } else {
                notifyFailed(res.data.message)
            }
        }).catch((err) => {
            console.log(err);
            notifyFailed("Something went wrong")
        })
    }
    useEffect(() => {
        deviceList()
    }, [])
    return (
        <div style={{ padding: "0 1%", marginTop: "4vh" }}>
            <div className='table-container1'>
                <table className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" >Device Id</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            devices.length !== 0
                                ? devices.map((device, i) => {
                                    return (

                                        <tr key={i}>
                                            <td style={{ fontWeight: "bold" }}>{device.deviceId}</td>
                                            <td>{moment(device.transactions[device.transactions.length - 1].transactionDate).format("DD-MMM-YYYY")}</td>
                                        </tr>
                                    );
                                })
                                : null
                        }
                    </tbody>
                </table>
            </div>

            <div style={{ height: "40px" }}></div>

        </div>
    )
}

export default Movement