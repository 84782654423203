import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'

export default function RequestComplete({data,notify,reload,SetReload}) {

    const reslove = () =>{
        axios.post(`${serverUrl.u}/app/req/resolveRequest`,{
            subId:data._id
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                notify(res.data.message)
                SetReload(!reload)
            }else{
                notify(res.data.message)

            }
        }).catch(function (err) {
            console.log(err);
            notify("Failed")

        })
    }
  return (
    <button className='btn btn-primary' onClick={()=>reslove()}>Request Completed</button>
  )
}
