import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { Button, Grid } from '@mui/material';
import { notify, notifyFailed } from '../Common/Notification';
import { orderTyepes } from '../Data';

export default function HouseAccess({ e, w }) {
  let [forms, setForm] = useState(false);
  let [type, setType] = useState('number');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let login_id = data.get('login_id');
    if (type === 'number') {
      if (login_id.includes('@')) {
        login_id = login_id;
      } else {
        login_id = '+91' + login_id;
      }
    }
    await axios
      .post(`${serverUrl.u}/api/tele/addInHouse`, {
        houseId: e.houseId,
        phone: login_id,
      })
      .then(function (res) {
        console.log(res);
        notify(res.data.message);
        setForm(false);
      })
      .catch(function (err) {
        console.log(err);
        notify('Error');
      });
  };
  return (
    <>
      {e.houseId !== '' ? (
        <>
          <button
            className='btn btn-primary'
            size='small'
            variant='contained'
            // sx={{ width: w === 1 && "100%" }}
            onClick={() => {
              if (orderTyepes[7].value === e.orderType && e.houseId === '') {
                notifyFailed('Contact Sales Person for SmartMeter');
                return;
              }
              setForm(true);
            }}
          >
            House Access
          </button>
        </>
      ) : null}

      {forms === true ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            zIndex: 1,
          }}
        >
          <div
            style={{
              width: 'max-content',
              backgroundColor: 'white',
              padding: '15px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: '12px',
              position: 'relative',
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className='cross1' onClick={() => setForm(false)}>
                x
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <label>Login Type</label>
                  <div className='input-group'>
                    <select
                      onChange={(val) => setType(val.target.value)}
                      className='form-control'
                      value={type}
                      required
                    >
                      <option value={'number'}>Phone</option>
                      <option value={'email'}>Email</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <label>Login ID</label>
                  <div class='input-group mb-3'>
                    <input
                      type={type}
                      class='form-control'
                      id='login_id'
                      name='login_id'
                      autoComplete='login_id'
                      placeholder='login_id'
                      aria-label='login_id'
                      aria-describedby='basic-addon1'
                      required
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} md={2}>
                  <button className='btn btn-primary' type='submit'>
                    House Access
                  </button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}
