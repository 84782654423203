import axios from "axios"
import { serverUrl } from "../ApiUrl"

export const installationChart = async(data)=>{
    const nextDay = new Date(data.date)
    nextDay.setDate(nextDay.getDate() + 1)
  return await axios.post(`${serverUrl.u}/api/number/getInstallationStats`,{
        "city":data.city,
        "teleCallerId":data.teleCallerId,
        "dayStart":data.date,
        "dayEnd":nextDay
    }).then(function (res) {
        console.log(res);
        return {res:res}
    }).catch(function (err){
        console.log(err);
        return {err:err}
    })
}

export const salesChart =async(data)=>{
    const nextDay = new Date(data.date)
    nextDay.setDate(nextDay.getDate() + 1)
  return await axios.post(`${serverUrl.u}/api/number/getSalesNumber`,{
        "city":data.city,
        "teleCallerId":data.teleCallerId,
        "dayStart":data.date,
        "dayEnd":nextDay
    }).then(function (res) {
        console.log(res);
        return {res:res}
    }).catch(function (err){
        console.log(err);
        return {err:err}
    })
}