import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { pages, serverUrl } from '../ApiUrl';
// import { useDispatch } from "react-redux";
// import { updateInstallationData } from "../Redux/installationActions";
// import { updateAddressLayout } from "../Redux/Action/adderess&layout";
// import { subHistory } from "./SubscriptionHistory";

export default function ReinstallationSiteNumber({
  e,
  setReload,
  reloadW,
  page,
}) {
  const [count, setCount] = useState('');

  useEffect(() => {
    reschedule();
  }, []);

  const reschedule = async () => {
    await axios
      .post(`${serverUrl.u}/subscription/reschedule`, {
        generatedHex: e.generatedHex,
      })
      .then(function (res) {
        setCount(res.data?.data?.count || '');
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <td label='Address'>
        <div
          className='hover-wide'
          // contentEditable="true"
          // suppressContentEditableWarning={true}
          onBlur={(ele) => {
            // addressUpdate(ele.target.innerText, e);
          }}
        >
          {count}
        </div>
      </td>
    </>
  );
}
