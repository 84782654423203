import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { apiRequest } from '../../utils';
import { serverUrl } from '../ApiUrl';
import { notify, notifyFailed } from '../Common/Notification';

export function AddInvoiceTrnx({ inv }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [paymentMode, setPaymentMode] = useState('Bank');
  const [trnxId, setTrnxId] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    console.log(
      '[data]',
      inv?.invoiceNumber,

      paymentMode,
      trnxId,
      amount,
      date
    );

    const response = await apiRequest({
      method: 'POST',
      url: `${serverUrl.sub}/invoice/addTrnx`,
      data: {
        invoiceNumber: inv?.invoiceNumber,
        trnxData: {
          payment_mode: paymentMode,
          gateway_transaction_id: trnxId,
          amount,
          date,
        },
      },
    });
    console.log(response);
    if (!response.success) {
      notifyFailed(response?.message || 'Failed to add transaction!!');

      return;
    }

    notify(response?.message || 'Transaction Added Successfully.');
    setOpen(false);
  }
  return (
    <div>
      <Button onClick={handleOpen} variant='contained'>
        Add Transaction
      </Button>
      <Modal open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              background: 'white',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Grid container spacing={2}>
              {/* First Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Amount'
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type='number'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type='date'
                  fullWidth
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{ height: '100% !important' }}>
                  <InputLabel id='demo-simple-select-label'>
                    Payment Mode
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    // value={age}
                    label='Payment Mode'
                    onChange={(e) => setPaymentMode(e.target.value)}
                    fullWidth
                    sx={{ height: '100% !important' }}
                  >
                    <MenuItem value={'Bank'}>Bank</MenuItem>
                    <MenuItem value={'rzp'}>RZP</MenuItem>
                    <MenuItem value={'paytm'}>Paytm</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type='text'
                  label='Gateway Transaction Id'
                  fullWidth
                  value={trnxId}
                  onChange={(e) => setTrnxId(e.target.value)}
                />
              </Grid>

              <Grid item sm={12}>
                <Button
                  type='Submit'
                  variant='contained'
                  sx={{ width: '100%' }}
                >
                  Add Transaction
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
