import { BrowserRouter, Routes, Route,Navigate,useLocation } from 'react-router-dom'
import './App.css';
import Main from './component/Main';
import Login from './component/Login';
import RoomDisplay from './component/Room/RoomDisplay';
import CscOrderPaidStatus from './component/CscOrderPaidStatus';
import Pdf2 from './component/pdf/Pdf2';
import InvoicePdf from './component/pdf/InvoicePdf';
import InvoiceNewPdf from './component/pdf/InvoiceNewPdf';

import { PdfComponent } from './component/pdf/PdfComponent';
import WebCam from './component/Web/WebCam';



function App() {

  return (
    <div style={{height:'100vh',overflow:'scroll '}}>
      
      <BrowserRouter>
        <Routes>
        <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
              <Route path="/main" element={<Main />} />
              <Route path='/:roots' element={<Main />}/>
              <Route path="/new" element={<RoomDisplay />} />
              
              <Route path="/pdf2" element={<Pdf2 />} />
              <Route path="/cam" element={<WebCam />} />
              <Route path="/invoicePdf" element={<InvoicePdf />} />
              <Route path="/invoiceNewPdf" element={<InvoiceNewPdf />} />
              <Route path='/Quto' element={<PdfComponent />} />
              <Route path='/CscOrderPaidStatus' element={<CscOrderPaidStatus />} />
        </Routes>
      </BrowserRouter>
   
    </div>
  );
}

export default App;
