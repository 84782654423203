import axios from "axios"
import { pages, serverUrl } from "../ApiUrl"
import moment from "moment"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeInstallationData } from "../Redux/installationActions";
import { subHistory } from "./SubscriptionHistory";
import { planCost } from "../Data";
import { AspectRatio } from "@mui/icons-material";
import { notifyFailed } from "../Common/Notification";
const notify = (text) => toast(text);
export const updateAllInvoiceStatus = async () => {
  return await axios.post(`${serverUrl.u}/api/zoho/updateAllInvoiceStatus`).then(
    function (res) {
      return res;
    }
  ).catch(function (err) {
    console.log(err);
    return "error occured"
  })
}

export const SubscriptionExistNewSubscriptionNotAllowed = async (number) => {
  await axios.post(`${serverUrl.u}/api/sub/checkSubscriptionExistOrNot`, {
    phone: number
  }).then(function (res) {
    
  }).catch(function (err) {
    console.log(err);
  })

}

export const getFilledDetail = async (number) => {
  return await axios.post(`${serverUrl.u}/api/sub/checkSubscriptionExistOrNot`, {
    phone: number
  }).then(async function (res) {
    if (res.data.message !== "No subscription Found") {
      return [res, "Client Already Register"]
    } else {
      return await axios.get(`${serverUrl.u}/v3/temporaryCart/getDetail?phone=${number}`).then(function (res) {
        return [res, "New Client"];
      }).catch(function (err) {
        return [err, "Detail Not Found"];
      })
    }
  }).catch(function (err) {
    return [err, "Error Occured"]
  })

}

let Check = (findData) => {
  let qu = findData.totalAppliances;
  let sequrity = qu * 100;
  let Installion;
  if (qu > 10) {
    let cal = qu - 10;
    Installion = 1000 + (cal * 50);
  } else {
    Installion = 1000;
  }
  let fee;
  let planDurations = findData.planFrequency;
  if (planDurations === "Monthly") {
    fee = qu * 30
  } else if (planDurations === "Quarterly") {
    fee = qu * 90
  } else if (planDurations === "Semi Annualy") {
    fee = qu * 180
  } else if (planDurations === "Annualy") {
    fee = qu * 365
  } else if (planDurations === "Annualy-3") {
    fee = (qu*planCost.Annualy) * 365
  } else if (planDurations === "Test (Weekly)") {
    fee = qu * 7
  } else if (planDurations === "Two Years") {
    fee = qu * (365 * 2);
  } else if (planDurations === "Three Years") {
    fee = qu * (365 * 3);
  }
  return (fee + Installion) * 1.18 + sequrity
}
let subscriptionData = [];
export const getAllSubscriptions = async () => {
  await axios.post(`${serverUrl.u}/api/tele/getAllSubscriptions`).then(function (response) {
    subscriptionData = response.data.data;

  }).catch(function (error) {
    console.log(error);
  })
}

const calculateActualPlanCost = (e) => {
  let planDurations = e.planFrequency;
  let fee = 0;
  
  let qu = e.totalAppliances;
  if (planDurations === "Monthly") {
    fee = (qu*planCost.Monthly) * 30
  } else if (planDurations === "Quarterly") {
    fee = (qu*planCost.Quarterly) * 90
  } else if (planDurations === "Semi Annualy") {
    fee = (qu*planCost.SemiAnnualy) * 180
  } else if (planDurations === "Annualy") {
    fee = (qu*planCost.Annualy) * 365
  } else if (planDurations === "Annualy-3") {
    fee = (qu*planCost.Annualy) * 365
  } else if (planDurations === "Test (Weekly)") {
    fee = (qu*planCost.TestWeekly) * 7
  } else if (planDurations === "Two Years") {
    fee = (qu*planCost.TwoYears) * (365*2);
  } else if (planDurations === "Three Years") {
    fee = (qu*planCost.ThreeYears) * (365*3);
  }
  // let installation = 300;
  // if(e.totalAppliances>10){
  //     let app = e.totalAppliances-10;
  //     installation=300+(app*20)
  // }
  // let total = Number(((fee+installation)*1.18+e.totalAppliances*100).toFixed(2));
  return fee;
}

export const calculatePartialAmount = async(data)=>{
 return await axios.post(`${serverUrl.u}/api/tele/calPartialAmount`,{
      generatedHex:data.generatedHex
    }).then(function (res) {
      
      if(res.status===200){
      
      return res.data.totalamount;
      
      }else{
         notifyFailed(res.data.message)
         return 0
      }
    }).catch(function (err) {
      console.log(err);
      return 0
    })
}

export const upsellProduct=async()=>{
 return await axios.post(`${serverUrl.u}/v3/affiliateProducts/listAll`).then(
    function (res) {
      console.log(res);
      if(res.data.success===true){
      return(res.data.data.products)
      }else{
        notifyFailed(res.data.message)
        return []
      }
    }
  ).catch(function (err) {
    console.log(err);
    notifyFailed("Product Found Failed")
    return []
  })
}

export const specificSubscription = async (generatedHex) => {
 return await axios.post(`${serverUrl.u}/api/tele/getSubscription`, {
    generatedHex: generatedHex
  }).then(function (res) {
    
    return ["then",res]
  }).catch(function (err) {
    console.log(err);
    return ["Failed"]
  })
}
export const getSubscriptionInvoice = async () => {
  let bill = [];
  let MixData = [];
  return await axios.post(`${serverUrl.u}/api/tele/getAllInvoices`).then(
    async function (response) {
      let detail = response.data.data;
      detail.sort(function (a, b) {
        return (a.invoiceNumber) - (b.invoiceNumber);
      });
      console.log(detail,"invoices");
      let Data = [];
      await axios.post(`${serverUrl.u}/api/tele/fetchLimitedData`).then(
        function (res) {
          
          if (res.data.success === true) {
            Data = res.data.finalData
          }
        }
      ).catch(function (err) {
        console.log(err);
      })
      for (const e of detail) {
        
        let findData = Data.find(ele => ele.generatedHex === e.generatedHex)
        
        if (e.isFirstInvoice === true) {
        
          bill.push({
            "Invoice Number": e.invoiceNumber>1200?e.invoiceNumber-1200:e.invoiceNumber,
            "Client Name": e.name,
            "TeleCaller Name": findData !== undefined ? (findData.teleCallerId) : "Subscription Not found",
            "Second Sales Person": findData !== undefined ? (findData.secondarySalesPerson) : "Subscription Not found",
            "City": findData?.city ? (findData.city) : "",
            "Phone": findData !== undefined ? `${findData.phone}` : "subscription not found",
            "Hex Code": e.generatedHex,
            "Email": findData !== undefined ? findData.email : "subscription not found",
            "Created Date": findData !== undefined ? findData.createdAt : "subscription not found",
            "Plan Frequency": findData !== undefined ? (findData.planFrequency) : "Subscription Not Found",
            "GST Client": findData !== undefined ? ((findData.isGstClient === true || e.Is_Business === true) ? "Yes" : "No") : "Subscription Not Found",
            "GST Name": findData !== undefined ? ((findData.gstName === "") ? (e.BillTo) : (findData.gstName)) : "Subscription Not Found",
            "GST Number": findData !== undefined ? ((findData.gstNumber === "") ? (e.BusinessGst) : (findData.gstNumber)) : "Subscription Not Found",
            "Phone": findData !== undefined ? (findData.phone) : "Subscription Not Found",
            "Status": e.status,
            "UpSell": e.is_UpSell === true ? "UpSell" : "",
            "B2B Subscription": e.IsB2BSubscription === true ? "Yes B2B" : "No",
            "Audit Cancel": e.auditingCancel === true ? "Yes" : "No",
            "Dropped Client": findData !== undefined ? (findData.hasOwnProperty('reasonForDrop') ? "Yes" : "No") : "Subscription Not Found",
            "Is First Invoice": e.isFirstInvoice === true ? "Yes" : "No",
            "Sub_UpgradeDownGrade": findData !== undefined ? findData.Sub_UpgradeDowngrade : "",
            "Old Hex": findData !== undefined ? (findData.oldHex.length !== 0 ? (findData.oldHex.map(h => h)) : "") : "",
            "New Hex": findData !== undefined ? findData.newHex : "",
            "Reson of Converted": findData !== undefined ? findData.reasonOfConverted : "",
            "Master Cancel": e.masterCancel === true ? "Yes Master Cancel" : "No",
            "Zoho Invoice Number": e.zohoInvoiceNumber,
            "Created Date": moment(e.createdTime).format("MMM Do"),
            "Mandate Date": findData !== undefined ? moment(findData.mandateDate).format("MMM Do") : "No Subscription Found",
            // "Security": findData !== undefined ? (findData.security) : "No Subscription Found",
            "Recursive": findData !== undefined ? (findData.recursiveAmount) : "No Subscription Found",
            "Installation": findData !== undefined ? (findData.installation) : "No Subscription Found",
            "Tax": findData !== undefined ? ((Number(findData.recursiveAmount) + Number(findData.installation)) * 0.18) : "No Subscription Found",
            "Total": findData !== undefined ? (Number(findData.totalAmount)-Number(findData.security)) : "No Subscription Found",
            "Difference BW final total & tranasction amount": findData !== undefined ? (Number(findData.totalAmount) - (e.trnxDetails.reduce((previousValue, currentValue) => {
              return previousValue + Math.round(currentValue.amount)
            }, 0) + e.trnxRZP.reduce((previousValue, currentValue) => {
              return previousValue + Math.round(currentValue.amount)
            }, 0))) : "Subscription not found",
            "Transaction mode & id": e.trnxDetails.map((ele) => ele.gateway_transaction_id !== undefined ? `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  ` : `${ele.payment_mode} - ${ele.amount} - ${ele.transaction_id}  `),
            "Transaction RazerPay": e.trnxRZP.map((ele) => `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  `),
            "Transaction Mode": e.paymentMode,
            //  "DiffInCall":findData !== undefined?(((Number(findData.recursiveAmount) + Number(findData.installation)) * 1.18)-(Number(findData.totalAmount)-Number(findData.security))).toFixed(2):"Subscription not found",
            "Scurity":findData !== undefined ?Number(findData.security) : "No Subscription Found",
            "Total_With_Security":findData !== undefined ? (Number(findData.totalAmount)-Number(0)) : "No Subscription Found",
            "UpSellData":e.is_UpSell === true?JSON.stringify(e):"",
          })
        }
        else {
        
          bill.push({
            "Invoice Number": e.invoiceNumber>1200?e.invoiceNumber-1200:e.invoiceNumber,
            "Client Name": e.name,
            "TeleCaller Name": findData !== undefined ? (findData.teleCallerId) : "Subscription Not found",
            "Second Sales Person": findData !== undefined ? (findData.secondarySalesPerson) : "Subscription Not found",
            "City": findData?.city ? (findData.city) : "",
            "Phone": findData !== undefined ? findData.phone : "subscription not found",
            "Hex Code": e.generatedHex,
            "Email": findData !== undefined ? findData.email : "subscription not found",
            "Plan Frequency": findData !== undefined ? (findData.planFrequency) : "Subscription Not Found",
            "GST Client": findData !== undefined ? ((findData.isGstClient === true || e.Is_Business === true) ? "Yes" : "No") : "Subscription Not Found",
            "GST Name": findData !== undefined ? ((findData.gstName === "") ? (e.BillTo) : (findData.gstName)) : "Subscription Not Found",
            "GST Number": findData !== undefined ? ((findData.gstNumber === "") ? (e.BusinessGst) : (findData.gstNumber)) : "Subscription Not Found",
            "Phone": findData !== undefined ? (findData.phone) : "Subscription Not Found",
            "Status": e.status,
            "UpSell": e.is_UpSell === true ? "UpSell" : "",
            "B2B Subscription": e.IsB2BSubscription === true ? "Yes B2B" : "No",
            "Audit Cancel": e.auditingCancel === true ? "Yes" : "No",
            "Dropped Client": findData !== undefined ? (findData.hasOwnProperty('reasonForDrop') ? "Yes" : "No") : "Subscription Not Found",
            "Is First Invoice": e.isFirstInvoice === true ? "Yes" : "No",
            "Master Cancel": e.masterCancel === true ? "Yes Master Cancel" : "No",
            "Sub_UpgradeDownGrade": "",
            "Old Hex": "",
            "New Hex": "",
            "Reson of Converted": "",
            "Zoho Invoice Number": e.zohoInvoiceNumber,
            "Created Date": moment(e.createdTime).format("MMM Do"),
            "Mandate Date": findData !== undefined ? moment(findData.mandateDate).format("MMM Do") : "No Subscription Found",
            // "Security": 0,
            "Recursive": e.is_UpSell === false ? (e.additionalInfo !== undefined ? (e.additionalInfo[0].item_total).toFixed(2) : (e.amount - e.amount * 0.18)) : (e.additionalInfo !== undefined ? `UpSell ${e.additionalInfo[0].item_total.toFixed(2)}` : 0),
            "Installation": 0,
            "Tax": e.is_UpSell === false ? (e.additionalInfo !== undefined ? ((e.additionalInfo[0].item_total) * 0.18).toFixed(2) : 0) : (e.additionalInfo !== undefined ? `UpSell ${(e.additionalInfo[0].item_total * 0.18).toFixed(2)}` : 0),
            "Total": Number(e.amount),
            "Difference BW final total & tranasction amount": (Number(e.amount) - (e.trnxDetails.reduce((previousValue, currentValue) => {
              return previousValue + Math.round(currentValue.amount)
            }, 0) + e.trnxRZP.reduce((previousValue, currentValue) => {
              return previousValue + Math.round(currentValue.amount)
            }, 0))),
            "Transaction mode & id": e.trnxDetails.map((ele) => ele.gateway_transaction_id !== undefined ? `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  ` : `${ele.payment_mode} - ${ele.amount} - ${ele.transaction_id}  `),
            "Transaction RazerPay": e.trnxRZP.map((ele) => `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  `),
            "Transaction Mode": e.paymentMode,
            // "DiffInCall":e.is_UpSell === false ? (e.additionalInfo !== undefined ? (e.additionalInfo[0].item_total).toFixed(2) : (e.amount - e.amount * 0.18)) : (e.additionalInfo !== undefined ?  e.additionalInfo[0].item_total.toFixed(2): 0)*1.18-Number(e.amount),
            "Scurity":0 ,
            "Total_With_Security":0,
            "UpSellData":e.is_UpSell === true?JSON.stringify(e):"",
          })
        }
        MixData.push({ ...e, phone: findData?.phone ? (findData.phone) : "", teleCallerId: findData?.teleCallerId ? (findData.teleCallerId) : "", houseId: findData?.houseId ? (findData.houseId) : "", active: findData?.active ? (findData.active) : "", InstallationDate: findData?.InstallationDate ? (findData?.InstallationDate) : null, address: findData?.address ? (findData.address) : "", city: findData?.city ? (findData.city) : "Any For Now", commentTele: findData?.comments ? (findData.comments) : "", zip: findData?.zip ? (findData.zip) : "", sub_id: findData?._id ? (findData._id) : "", mandateDate: findData?.mandateDate ? (findData.mandateDate) : null, totalAppliances: findData?.totalAppliances ? (findData.totalAppliances) : 0, startTakingPaymentOverdue: findData?.startTakingPaymentOverdue ? (findData.startTakingPaymentOverdue) : false, B2BRoom_No: findData !== undefined ? (findData.B2BRoom_No) : "", Dropped_Client: findData !== undefined ? (findData.hasOwnProperty('reasonForDrop') ? "Yes Dropped" : "No") : "",secondarySalesPerson:findData !== undefined ?findData.secondarySalesPerson:"" },)
      }
      return [bill, MixData, detail, "then"];
    })
    .catch(function (error) {
      console.log(error);
      return [error, "catch"]
    })
}

export const getInvoiceDateWise = async (startDate,endDate,page,recordPerPage) => {
  return await axios.post(`${serverUrl.u}/api/tele/getInvoiceDateWise?p=${page-1}`,{
    firstDate:startDate,
    lastDate:endDate,
    booksPerPage:recordPerPage
  }).then(
    async function (response) {
      console.log(response,"dateWise");
      let detail = response.data.finalData;
      let totalPage = response.data.totalPage;
      detail.sort(function (a, b) {
        return (a.invoiceNumber) - (b.invoiceNumber);
      });
      return [detail,totalPage, "then"];
    })
    .catch(function (error) {
      console.log(error);
      return [error, "catch"]
    })
}

export const getAllRequestsParticular = async (loginName) => {
  let MixData = [];
  return await axios.post(`${serverUrl.u}/api/zoho/getAllRequestsParticular`, {
    enteringPerson: loginName,
  }).then(async function (res) {
    await getAllSubscriptions();
    for (const e of res.data.slot) {
      let findData = subscriptionData.find(ele => ele.generatedHex === e.generatedHex)
      MixData.push({ ...e, name: findData == null ? "Deleted" : findData.name, subId: findData !== undefined ? findData._id : "0", planFrequency: findData !== undefined ? findData.planFrequency : "Not found", partialPayment: findData !== undefined ? findData.partialPayment : "not found", teleCallerId: findData !== undefined ? findData.teleCallerId : "not found", city: findData !== undefined ? findData.city : "not found", phone: findData !== undefined ? findData.phone : "not found", totalAppliances: findData !== undefined ? findData.totalAppliances : "not found", fieldVisiteDateTime: findData !== undefined ? findData.fieldVisiteDateTime : "not found" },)

    }
    return MixData;

  }).catch(function (err) {
    console.log(err);

  })
}
export const getAllRequests = async () => {
  let MixData = []
  return await axios.post(`${serverUrl.u}/api/zoho/getAllRequests`).
    then(async function (res) {
      await getAllSubscriptions();
      for (const e of res.data.slot) {
        let findData = subscriptionData.find(ele => ele.generatedHex === e.generatedHex)
        MixData.push({ ...e, name: findData == null ? "Deleted" : findData.name, subId: findData !== undefined ? findData._id : "0", planFrequency: findData !== undefined ? findData.planFrequency : "Not found", partialPayment: findData !== undefined ? findData.partialPayment : "not found", teleCallerId: findData !== undefined ? findData.teleCallerId : "not found", city: findData !== undefined ? findData.city : "not found", phone: findData !== undefined ? findData.phone : "not found", fieldVisiteDateTime: findData !== undefined ? findData.fieldVisiteDateTime : "not found", totalAppliances: findData !== undefined ? findData.totalAppliances : "not found" })
      }
      return MixData;
    }).catch(function (err) {
      console.log(err);
    })
}
export const getAllDepartmentRequestsParticular = async (dep, page) => {

  return await axios.post(`${serverUrl.u}/api/tickets/getAllDepartmentRequests/?p=${page - 1}`, {
    department: dep,
  }).then(async function (res) { 
    return res.data
  }).catch(function (err) {
    console.log(err);

  })
}
export const getAllDepartmentRequests = async () => {
  let MixData = []
  return await axios.post(`${serverUrl.u}/api/tickets/getAllDepartmentRequests`).
    then(async function (res) {
      await getAllSubscriptions();
      for (const e of res.data.slot) {
        let findData = subscriptionData.find(ele => ele.generatedHex === e.generatedHex)
        MixData.push({ ...e, name: findData == null ? "Deleted" : findData.name, subId: findData !== undefined ? findData._id : "0", planFrequency: findData !== undefined ? findData.planFrequency : "Not found", partialPayment: findData !== undefined ? findData.partialPayment : "not found", teleCallerId: findData !== undefined ? findData.teleCallerId : "not found", city: findData !== undefined ? findData.city : "not found", phone: findData !== undefined ? findData.phone : "not found", fieldVisiteDateTime: findData !== undefined ? findData.fieldVisiteDateTime : "not found", totalAppliances: findData !== undefined ? findData.totalAppliances : "not found" })
      }
      return MixData;
    }).catch(function (err) {
      console.log(err);
    })
}

export const salesUserData = async (token) => {
  return await axios.get("https://int.alistetechnologies.com/users/salesUsers", {

    headers: {
      AccessToken: token
    }
  }).then(function (response) {
    return response.data.data.users;
  }).catch(function (error) {
    console.log("list error   ", error);

  })
}

export const InstallationUser = async (token) => {
  return await axios.get("https://int.alistetechnologies.com/users/installationUsers", {
    headers: {
      AccessToken: token
    }
  }).then(function (response) {

    return response.data.data.users

  }).catch(function (error) {
    console.log(error);
  })
}

export const updateStartMakingPayment = async (data) => {
  return await axios.post(`${serverUrl.u}/api/dispatch/updateStartMakingPayment`, {
    subId: data.sub_id,
    status: !data.startTakingPaymentOverdue
  }).then(function (res) {
    return "Sucess"
  }).catch(function (err) {
    console.log(err);
  })
}

    export const appliancesLeftPayment = (data,SetWebsiteReload,websiteReload)=>{
      let page = "specificElectrician"
      axios.post(`${serverUrl.u}/api/tele/countApplianceInHouse`,{
          houseId:data.houseId
        }).then(function (res) {
        
          if(res.data.success===true){
            
            if(res.data.newAppliances===0){
               updateInstalledtwoHouse(data,page,"",SetWebsiteReload,websiteReload)
            }else{
              notify("Appliance Payment is pending")
            }
          
          }else{
            notify(res.data.message)
          }
        }).catch(function (err) {
            console.log(err);
            notify("House Not found")
        })
    }

    export const updateInstalledtwoHouse = async(data,page,dispatch,notify,loginName,SetWebsiteReload,websiteReload)=>{
           await axios.post(`${serverUrl.u}/api/dispatch/updateInstalledtwoHouse`,{
            generatedHex:data.generatedHex,
            houseId:data.houseId,
            firstTime:data.finalInstalled===false?true:false
           }).then(function (res) {
            if(page===pages.installation){
             dispatch(removeInstallationData(data))
             if(data.finalInstalled===false){
              subHistory('First Installation Complete',data,"First Installation Complete","")
            }
            }else{
              SetWebsiteReload(!websiteReload)
              if(data.finalInstalled===false){
                subHistory('First Installation Complete',data,"First Installation Complete","")
              }
            }
           }).catch(function (err) {
            console.log(err);
           })
    }

export const deletesubscription = async (data) => {
  return await axios.post(`${serverUrl.u}/api/tele/deletesubscription`, {
    hex: data.generatedHex
  }).then(function (res) {
    return res;
  }).catch(function (err) {
    console.log(err);
    return err
  })
}


export const countOverDue = async () => {
  return await axios.get(`${serverUrl.u}/api/tele/countOverdue`
  ).then(function (res) {
    return res.data.countOverdueInvoice;
  }).catch(function (error) {
    console.log(error);
    return "err"
  })
}

export const halfInstallation = async (hex,app) => {
  return await axios.post(`${serverUrl.u}/api/tele/changeHalfInstallation`, {
    generatedHex: hex,
    numberOfAppliances:app
  }).then(function (res) {
    return [res, "then"]
  }).catch(function (err) {
    return [err, "catch"]
  })

}

export const revertMandate = async(generatedHex)=>{
  return await axios.post(`${serverUrl.u}/api/tele/revertReatachMandate`,{
    generatedHex
  }).then(function (res) {
    if(res.data.success===true){
      return res.data
    }else{
      notifyFailed(res.data.message)
      return {}
    }
  }).catch(function (err) {
     notifyFailed("Revert Mandate is Failed")
     return {}
  })
}


 export const cleanNumber = (num) => {
  try {
    let cn = String(num).replace(/\D/g, ''); // replace non digits
    if (cn.startsWith('0')) {
      // the number is starting with 0, remove it
      cn = cn.slice(1, cn.length);
    }
    if (cn.length > 10) {
      cn = cn.slice(cn.length - 10, cn.length);
    }
    return `+91${cn}`;
  } catch (_) {
    return num;
  }
};