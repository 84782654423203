import axios from 'axios'
import React, { useState } from 'react'
import { pages, serverUrl } from '../ApiUrl'
import { removeInstallationData } from '../Redux/installationActions'
import { useDispatch } from 'react-redux'
import { subHistory } from '../Function/SubscriptionHistory'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { notify, notifyFailed } from '../Common/Notification'

export default function UninstallDoneBtn({data,setReload,reload,page="other"}) {
   const dispatch = useDispatch()
   let [btn,setBtn] = useState('Uninstallation Done')
   let loginName = useSelector((state) => state.sar.data.user.first_name)

  const checkHouseEmpty = async()=>{
  return await axios.post(`${serverUrl.u}/api/tele/checkHouseIsEmptyOrNot`,{
       houseId: data.houseId 
      // houseId:"5c60ff61ea382800173b1165"
    }).then(function (res) {
          console.log(res,"check house empty");
          if(res.data.success===true){
            return res.data.data
          }else{
            notify("House is not empty")
            setBtn("Uninstallation Done")
            return false
          }
        
    }).catch(function (err) {
        console.log(err);
        notify("Error Occur");
        return false
    })

  }
  const uninstallDone = async()=>{
    setBtn("Loading...")
    axios.post(`${serverUrl.u}/api/tele/updatePickedUp`,{
        generatedHex: data.generatedHex 
    }).then(function (res) {
  
        if(res.status===200){
          subHistory("Uninstallation Done",data,"UnInstallation Done","UnInstallation Done")
          if(page===pages.installation){
             dispatch(removeInstallationData(data))

          }else{
            setReload(!reload)
          }
          notify(res.data.message)
        
        }else(
          notify(res.data.message)
        )
        setBtn("Uninstallation Done")
    }).catch(function (err) {
        console.log(err);
        notify("Error Occur");
        setBtn("Uninstallation Done")
    })
}
  const getDeviceDifferenceOrderType = async(app)=>{
       await axios.post(`${serverUrl.u}/api/tele/getNumberOfApplianceInHouseAccordingToType`,{
          houseId:data.houseId,
          orderType:data.orderType
        }).then(function (res) {
             console.log(res);
             if(res.data.success===true){
              notify(res.data.message)
              if(res.data.data.success===true){
             
                  
                  if(res.data.data.data.totalAppliances===0){
                    uninstallDone()
                  }
                  else if(res.data.data.data.totalAppliances===app){
                    uninstallDone()
                  }else{
                    notifyFailed(`Contact TeleCaller there is appliance difference ${res.data.data.data.totalAppliances}`)
                    setBtn("Uninstallation Done")
                  }
              }else{
                notifyFailed("Device in house Not found Failed")
                setBtn("Uninstallation Done")
              }
            }else{
              uninstallDone()
              
              setBtn("Uninstallation Done")
            }
        }).catch(function (err) {
             console.log(err);
             notifyFailed("Device in house Failed")
             setBtn("Uninstallation Done")
        })
  }

  const getAllPaidSubscription = async()=>{
    setBtn("Loading...")
      await axios.post(`${serverUrl.u}/api/tele/getPaidSubscriptionsWithPhone`,{
        phone:data.phone,
        orderType:data.orderType
       }).then(function (res) {
           console.log(res);
           if(res.data.success===true){
            notify(res.data.message)
            if(res.data.data.success===true){
              if(res.data.data.data.subscriptions.length!==0){
                let sub = res.data.data.data.subscriptions
                let skipYourSelf = sub.filter(e=>e.generatedHex!==data.generatedHex)

                let totalAppliances = skipYourSelf.reduce((total,currentValue)=>{
                      if(currentValue.partialPayment===true && currentValue.changesInSubscription.length !== 0 && currentValue.changesInSubscription[currentValue.changesInSubscription.length - 1].oldAppliances !== undefined){
                        return Number(total)+Number(currentValue.changesInSubscription[currentValue.changesInSubscription.length - 1].oldAppliances)
                      }
                        return Number(total)+Number(currentValue.totalAppliances)
                },[0])
                
                getDeviceDifferenceOrderType(totalAppliances)
              }else{
                getDeviceDifferenceOrderType(0)
              }
            }else{
              getDeviceDifferenceOrderType(0)
             }
           }else{
            getDeviceDifferenceOrderType(0)
           }
       }).catch(function (err) {
         console.log(err);
         notifyFailed("Paid Subscription Found Failed")
         setBtn("Uninstallation Done")
       })
  }

  
  return (
    <>
      <Button variant='contained' onClick={()=>getAllPaidSubscription()} style={{pointerEvents:btn==="Loading..."?"none":""}}>{btn}</Button>
    </>
  )
}
