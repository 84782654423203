import { Button } from '@mui/material'
import React, { useState } from 'react'
import ModelCommon from '../Common/ModelCommon'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { notify, notifyFailed } from '../Common/Notification'
import { subHistory } from '../Function/SubscriptionHistory'
import { electicianLocationLog, typeOfLocaionLogs } from './api'
import { useSelector } from 'react-redux'

function SiteVisitDoneByElectrician({sub,page,reload,setReload,input=false}) {
  let [siteVisitComment,setSiteVistComment] = useState("")
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)

  const siteVistdone =async()=> {
    let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.leave,sub)
    if(!res.success){
      notifyFailed("First Click on Day Start Button")
      return
    }
      axios.post(`${serverUrl.u}/app/elec/siteVisiteCompletedByElectrician`,{
        generatedHex:sub.generatedHex,
        comments:siteVisitComment,
      }).then((res)=>{
        if(res.data.success===true){
          subHistory("Site Visit Done By Elecetrician",{...sub},"Site Visit Completed",res.data.message)
          notify(res.data.message)
          setReload(!reload)
        }else{
          notifyFailed(res.data.message)
        }
      }).catch((err)=>{
        notify("Failed")
      })
  }
  return (
    <>
      <ModelCommon agree={siteVistdone} btnName={"Site Visit Done"} text={"Are you sure site visit is done ?"} input={input} page={page} setComment={setSiteVistComment}/>
    </>
  )
}

export default SiteVisitDoneByElectrician