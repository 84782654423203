import React, { useState } from 'react'
import { NumberChart } from './data';

function NumbersDashboard() {
    const [selectDuration,setSelectedDuration] = useState("AllDays")
    console.log(NumberChart["AllDays"].dailyMetrix,"cha");
    return (
        <div style={{ padding: 0, marginTop: "15px" }}>
            
            <div className='col-4 ml-4'>
                <label>Duration</label>
                <select
                  className="custom-select height-select"
                  onChange={(e)=>setSelectedDuration(e.target.value)}
                >
                    <option value={"AllDays"}>Days Wise</option>
                    <option value={"CurrentMonth"}>Months Wise</option>
                </select>
            </div>
            {
                Object.keys(NumberChart[selectDuration]).map((e)=>(
<iframe style={{
                background: "#FFFFFF",
                border: "none",
                borderRadius: "2px",
                boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                marginLeft: "5%",
                marginTop: "2%"
            }}
                width="90%"
                height="480"
                src={NumberChart[selectDuration][e]}>
            </iframe>
                ))
            }
            {/* <iframe style={{
                background: "#FFFFFF",
                border: "none",
                borderRadius: "2px",
                boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                marginLeft: "5%",
                marginTop: "2%"
            }}
                width="90%"
                height="480"
                src={NumberChart[selectDuration].glance}>
            </iframe>

            <iframe style={{
                background: "#FFFFFF",
                border: "none",
                borderRadius: "2px",
                boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                marginLeft: "5%",
                marginTop: "2%"
            }}
                width="90%"
                height="480"
                src={NumberChart[selectDuration].allInstallationDone}>
            </iframe>

            <iframe style={{
                background: "#FFFFFF",
                border: "none",
                borderRadius: "2px",
                boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                marginLeft: "5%",
                marginTop: "2%"
            }}
                width="90%"
                height="480"
                src={NumberChart[selectDuration].booking}>
            </iframe>

            <iframe style={{
                background: "#FFFFFF",
                border: "none",
                borderRadius: "2px",
                boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                marginLeft: "5%",
                marginTop: "2%"
            }}
                width="90%"
                height="480"
                src={NumberChart[selectDuration].revenue}>
            </iframe>
            
                    
            <iframe style={{
                background: "#FFFFFF",
                border: "none",
                borderRadius: "2px",
                boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                marginLeft: "5%",
                marginTop: "2%"
            }}
                width="90%"
                height="480"
                src={NumberChart[selectDuration].revenuebifurcate}>
            </iframe>
                 */}
            
            <div style={{height:"100px"}}></div>
        </div>
    )
}

export default NumbersDashboard;