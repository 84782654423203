import React, { useState } from 'react';
import OfflineClient from './OfflineClient';
// import CreateLead from './CreateLead'
import BusinessOfflineRegistration from './BusinessOfflineRegistration';
import PartialOfflineClientRegister from './PartialOfflineClientRegister';
import { useSelector } from 'react-redux';
import NewClientRegister from './NewClientRegister';
import ProductSelling from '../Product/ProductSelling';
import ProductCreation from '../ProductCreation';
import SmartMeterClientRegister from './SmartMeterClientRegister';
import CustomerRegister from './CustomerRegister';
import { user_roles } from '../Data';
import { NewSmartMeterPropertyRegister } from './NewSmartMeterPropertyRegister';

export default function ClientRegister({ Leadpage, InstallationPage }) {
  let [tab, setTab] = useState('Smart_Meter_Property');
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let employeeId = useSelector((state) => state.sar.data?.user?.employeeId);

  return (
    <div style={{ padding: '0 4%', marginTop: '4vh' }} className='performa'>
      <div style={{ marginBottom: '20px' }}>
        <button
          className={
            tab === 'Smart_Meter_Property'
              ? 'btn btn-primary mt-2'
              : 'btn btn-light mt-2'
          }
          onClick={() => setTab('Smart_Meter_Property')}
        >
          Smart Meter Property
        </button>

        {(userRole?.includes(user_roles.SuperAdmin) ||
          userRole?.includes(user_roles.SmartMeter)) && (
          <button
            className={
              tab === 'Smart_Meter_Client'
                ? 'btn btn-primary ml-2 mt-2'
                : 'btn btn-light ml-2 mt-2'
            }
            onClick={() => setTab('Smart_Meter_Client')}
          >
            Smart Meter Client
          </button>
        )}
        <button
          className={
            tab === 'Online_Client'
              ? 'btn btn-primary mt-2'
              : 'btn btn-light mt-2'
          }
          onClick={() => setTab('Online_Client')}
        >
          Automation Client
        </button>

        {/* <button
          className={
            tab === "performa" ? "btn btn-primary mt-2" : "btn btn-light mt-2"
          }
          onClick={() => setTab("performa")}
        >
          Performa
        </button> */}
        <button
          className={
            tab === 'UpSell__Quotation'
              ? 'btn btn-primary ml-2 mt-2'
              : 'btn btn-light ml-2 mt-2'
          }
          onClick={() => setTab('UpSell__Quotation')}
        >
          UpSell Quotation
        </button>
        {/*  <button className={tab==="Product_Create"?"btn btn-primary ml-2 mt-2":'btn btn-light ml-2 mt-2'} onClick={()=>setTab("Product_Create")}>Product Register</button> */}

        {userRole?.includes('Super Admin') ? (
          <>
            {/* <button className={tab==="Offline_Client_New"?"btn btn-primary ml-2 mt-2":'btn btn-light ml-2 mt-2'} onClick={()=>setTab("Offline_Client_New")}>Complete Payment Offline Client New</button> */}
            {/* <button className={tab==="Offline_Client_Old"?"btn btn-primary ml-2 mt-2":'btn btn-light ml-2 mt-2'} onClick={()=>setTab("Offline_Client_Old")}>Existing Partial Client Full Payment</button> */}
            {/* <button
              className={
                tab === "UpSell_Client"
                  ? "btn btn-primary ml-2 mt-2"
                  : "btn btn-light ml-2 mt-2"
              }
              onClick={() => setTab("UpSell_Client")}
            >
              UpSell
            </button> */}
            <button
              className={
                tab === 'Product_Create'
                  ? 'btn btn-primary ml-2 mt-2'
                  : 'btn btn-light ml-2 mt-2'
              }
              onClick={() => setTab('Product_Create')}
            >
              Product Register
            </button>
            <button
              className={
                tab === 'B2B_Client'
                  ? 'btn btn-primary ml-2 mt-2'
                  : 'btn btn-light ml-2 mt-2'
              }
              onClick={() => setTab('B2B_Client')}
            >
              B2B Client
            </button>
          </>
        ) : null}
      </div>
      {tab === 'Smart_Meter_Property' ? (
        <NewSmartMeterPropertyRegister Leadpage={Leadpage} />
      ) : null}
      {tab === 'Online_Client' ? (
        <NewClientRegister Leadpage={Leadpage} />
      ) : null}
      {tab === 'UpSell__Quotation' ? (
        <ProductSelling InstallationPage={InstallationPage} />
      ) : null}
      {tab === 'Product_Create' ? <ProductCreation /> : null}
      {tab === 'Offline_Client_New' ? <OfflineClient /> : null}
      {tab === 'Offline_Client_Old' ? <PartialOfflineClientRegister /> : null}
      {tab === 'B2B_Client' ? <BusinessOfflineRegistration /> : null}
      {tab === 'Smart_Meter_Client' ? <SmartMeterClientRegister /> : null}
      {tab === 'customer_register' ? <CustomerRegister /> : null}
      {/* {tab === "performa" ? <Performa /> : null} */}
    </div>
  );
}
