import axios from 'axios'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import moment from 'moment'

export default function ClientSubscriptionChangeHistories({ data, notify }) {
    let [history, setHistory] = useState([])
    const ClientHistory = () => {
        axios.post(`${serverUrl.u}/api/tele/getClientSubscriptionHistory`, {
            phone: data.phone
        }).then(function (res) {
            if (res.data.success === true) {
                if (res.data.finalSubscription.length > 0) {
                    setHistory(res.data.finalSubscription)
                } else {
                    notify("History Not Available")
                }
            } else {
                notify("Error Occur")
            }
            console.log(res);
        }).catch(function (err) {
            console.log(err);
            notify("Error Occur")
        })
    }
    return (
        <>
            <button className='btn btn-primary' onClick={()=>ClientHistory()}>Client History</button>

            {
                history.length !== 0 ?
                    <div style={{ height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, display: "flex", justifyContent: "center", alignItems: "center", zIndex:4 }}>
                        <div style={{ width: "90%",height:"fit-content", borderRadius: "12px", backgroundColor: "whitesmoke", padding: "10px" }}>
                            <div style={{ width: "100%", textAlign: "right" }}>
                                <span style={{ cursor: "pointer" }} onClick={()=>setHistory([])}>X</span>
                            </div>
                            <div style={{width:"90% !important"}}>
                            <table class="table table-hover" >
                                <thead>
                                    <tr>
                                        <th >Name</th>
                                        <th >Phone</th>
                                        <th >New Hex</th>
                                        <th >Old Appliances</th>
                                        <th >New Appliances</th>
                                        <th >New Plan Frequency</th>
                                        <th >Old Plan Frequency</th>
                                        <th >Reson of Subscription Change</th>
                                        <th >Subscription UpGrade/ DownGrade Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        history.map((ele) => {
                                            return (
                                                <tr>
                                                    <td>{ele.name}</td>
                                                    <td>{ele.phone}</td>
                                                    <td>{ele.newHex}</td>
                                                    <td>{ele.oldTotalAppliances}</td>
                                                    <td>{ele.newTotalAppliances}</td>
                                                    <td>{ele.oldplanFrequency}</td>
                                                    <td>{ele.newplanFrequency}</td>
                                                    <td>{ele.reasonOfConverted}</td>
                                                    <td>{moment(ele.date).format("DD-MMM-YYYY")}</td>
                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </table>
                            </div>
                        </div>

                    </div> : null
            }
        </>
    )
}
