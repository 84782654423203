import React from 'react'
import { useState } from 'react'
import CardInvoice from './CardInvoice';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';

export default function InvoiceDetails({e,notify,invoice,setInvoice}) {
    
    const invoiceDetails = async () => {
        await axios
          .post(`${serverUrl.u}/api/tele/getAllInvoicesDetails`, {
            generatedHex: e.generatedHex,
          })
          .then(function (res) {
            console.log(res.data);
            if (res.data.success === true) {
               setInvoice(res.data.data)
               if(res.data.data.length>0){
                notify("Invoices Data Loaded")
               }else{
                notify("Invoice Not Available")
               }
            } else {
                notify("Failed")
            }
          })
          .catch(function (err) {
            console.log(err);
            notify("Failed")
          });
      };
  return (
    <div>
       <button className='btn btn-primary' onClick={()=>invoiceDetails()}>Get Invoice Details</button>
   
    </div>
  )
}
