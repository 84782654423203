import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './ConvertedLead.css'
import './DispatchData.css'
import './PaymentLinkGen.css'
import Qutoes from './Qutoes'
import { serverUrl } from './ApiUrl'
import { useSelector } from 'react-redux'
import { DragDropContext,Droppable,Draggable } from "react-beautiful-dnd";
import { CSVLink } from "react-csv";
import moment from 'moment'

export default function Lead() {
  let [details, setDetails] = useState([]);
  let [displayData,setDisplayData] = useState([])
  let [q, setq] = useState("");
  let [reloadW, setReload] = useState(false)
  let userRoles = useSelector((state) => state.sar.data.user)
  let startDate = useRef(new Date())
  let endDate = useRef(new Date())

  const getData=() => {
    axios.post(`${serverUrl.u}/v3/temporaryCart/leadDump`,{
        startDate:startDate.current.value,
    endDate: endDate.current.value,
    format: "json"
    }).then(function (res) {
        console.log(res.data.data.jsonDump,"lead");
        let data = []
        for(let i of res.data.data.jsonDump){
         let obj = {
            "City": i.City,
            "Conversion Date": moment(new Date(i["Conversion Date"])).format('DD-MM-YYYY'),
            "Converted": i.Converted,
            "Date": i.Date,
            "Email": i.Email,
            "Last Process": i["Last Process"],
            "Last Process Time": i["Last Process Time"],
            "Name": i.Name,
            "Phone": i.Phone,
            "Source": i.Source,
            "Source Count": i["Source Count"],
        }
        data.push(obj)
        }
     
        setDisplayData(data)
    }).catch(function (err) {
        console.log(err);
    })
  }

  



 
  const handleSarch = async (name) => {
    setq(name)
    await axios.post(`${serverUrl.u}/api/tele/getAllTemporarySubscriptionsSearch`, {
      name: name,
    }).then(function (response) {
      setDetails(response.data.data)
    }).catch(function (error) {
      console.log(error);
    })
  }
  

  const handleDragEnd = (results) =>{
    console.log(results)
    if(!results.destination) return
    let temData = [...displayData]
    let [SelectedRow] = temData.splice(results.source.index,1)
    temData.splice(results.destination.index,0,SelectedRow)
    setDisplayData(temData)
  }

  return (
    <div  style={{padding:"0 4%",marginTop:"4vh"}}>

     
            <div className='input-field'><input type="text" value={q} placeholder="Search here...." onChange={(e) => handleSarch(e.target.value)} /></div>
          
            <div className='row'>
                  
                  <div className="col-2">
                  <label className="create-label1">Start Date</label>
                      <div class="input-group ">
                        <input type="date" ref={startDate}/>
                        </div>
                  </div>
                  <div className="col-2">
                  <label className="create-label1">End Date</label>
                      <div class="input-group ">
                        <input type="date" ref={endDate}/>
                        </div>
                  </div>
                  <div className="col-1">
                     <button className='btn btn-primary mt-3' onClick={()=>getData()}>Get Data</button>
                  </div>
                  {
                    displayData.length!==0?
                    <div className="col-2">
                  <CSVLink className='btn btn-primary mt-3' data={displayData}  filename={`Lead_${startDate.current.value}_to_${endDate.current.value}.csv`}>Download CSV</CSVLink>
                  </div>:null
                  }
                  
            </div>
    
           
            <div className='table-container1'>
            <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Name<span style={{ color: "red" }}>*</span></th>
                    <th>City</th>
                    <th style={{width:"110px"}}>Phone</th>
                    <th style={{width:"250px"}}>Email</th>
                    <th>Converted</th>
                    <th>Conversion Date</th>
                    <th>Source</th>
                    <th>Source Count</th>
                    <th>Last Process</th>
                    <th>Last Process Time</th>
                  </tr>
                </thead>
                <Droppable droppableId="droppable-1">
  {(provided, snapshot) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {

displayData !== 0 ?

displayData.map((e, i) => {
                        return (
                          <Draggable draggableId={e.Phone} index={i} key={e.Phone}>
                          {(provided, snapshot) => (
                          <tr key={i} ref={provided.innerRef}
                          {...provided.draggableProps}
                          >
                            <td>{i+1}</td>
                            <td style={{fontWeight:"bold",cursor:"pointer"}} {...provided.dragHandleProps} >{e.Name}</td>
                            <td>{e.City}</td>
                            <td><a href={`tel:${e.Phone}`}>{e.Phone}</a></td>
                            <td>{e.Email}</td>
                            <td>{e.Converted}</td>
                            <td>{e["Conversion Date"]}</td>
                            <td>{e.Source}</td>
                            <td>{e["Source Count"]}</td>
                            <td>{e["Last Process"]}</td>
                            <td>{e["Last Process Time"]}</td>
                          </tr>
                          )}
                          </Draggable>
                        )
                      }) : null
                  }
                  {provided.placeholder}
                </tbody>
               )
                }
                </Droppable>
              </table>
            </DragDropContext>
            </div>
            
            <Qutoes /> 
 
    </div>
  )
}
