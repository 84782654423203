import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { notify, notifyFailed } from '../Common/Notification';
import './PostPayment.css'
import { useEffect } from 'react';
import { upsellProduct } from '../Function/AxiosControl';
import Multiselect from 'multiselect-react-dropdown';
import { NumbersRounded } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "max-content",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    overflowY: "scroll",
    maxHeight: "85vh",
}
function MakeTransactionData({ transactionCount, setTransactionCount }) {
    const [transaction, setTransaction] = useState({ id: "", amount: 0, date: "" })
    const [btnDisable, setBtnDisable] = useState(false)
    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <label>Transaction Id</label>
                <div class="input-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        id="id"
                        name="id"
                        disabled={btnDisable}
                        onChange={(event) => {
                            setTransaction({ ...transaction, id: event.target.value })

                        }}
                    />
                </div>
            </Grid>
            <Grid item md={3}>
                <label>Transaction Amount</label>
                <div class="input-group mb-3">
                    <input
                        type="number"
                        class="form-control"
                        id="amount"
                        min={0}
                        name="amount"
                        disabled={btnDisable}
                        onChange={(event) => {
                            setTransaction({ ...transaction, amount: Number(event.target.value) })
                        }}
                    />
                </div>
            </Grid>
            <Grid item md={3}>
                <label>Transaction Date</label>
                <div class="input-group mb-3">
                    <input
                        type="date"
                        class="form-control"
                        id="date"
                        name="date"
                        disabled={btnDisable}
                        onChange={(event) => {
                            setTransaction({ ...transaction, date: event.target.value })
                        }}
                    />
                </div>
            </Grid>
            <Grid item md={3}>
                <label ></label>
                <Button

                    onClick={() => {
                        setTransactionCount([...transactionCount, transaction])
                        setBtnDisable(true)
                    }}
                    sx={{ width: 1, marginTop: 1 }}
                    size='small'
                    variant="contained"
                    disabled={btnDisable}
                >{"Add"}</Button>
            </Grid>
        </Grid>

    )
}

export default function PostPayment({ sub }) {
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    const [btnName, setBtnName] = useState("Submit")
    const [open, setOpen] = React.useState(false);
    let [numberTransAction, setNumberTransAction] = useState(1)
    const [form, setForm] = useState({ name: sub.name, createdBy: loginName, Is_Business: "false", In_Up: "false" })
    const [transactionCount, setTransactionCount] = useState([])
    const [upSellProduct, setUpSellProduct] = useState([])
    const [selectedProductData,setSelctedProductData] = useState([])
    const [demo,setDemo] = useState(0)
    const [SubTotal,setSubTotal] = useState(0)
    const handleOpen = async () => {
        setOpen(true)
        setSelctedProductData([])
        let upsell = await upsellProduct()
        console.log(upsell);
        let Products = []
        for (let product of upsell) {
            Products.push({ ...product, qty: 1 })
        }
        setUpSellProduct(Products)

    };
    const handleClose = () => {
        setOpen(false)
        setNumberTransAction(1)
        setForm({})
        setTransactionCount([])
    };
    const salesUsers = useSelector(state => state.salesUser)

    const selectedValue = (data)=>{
        let newData = []
          for(let pro of data){
            let find =false;
            for(let innerPro of selectedProductData){
               if(pro._id===innerPro._id){
                  find=true
                  break;
               }
            }
            if(find===false){
              newData.push(pro)
            }
          }
        setSelctedProductData([...selectedProductData,...newData])
          setDemo(data.length)
    }
    const onRemove = (data)=>{
        console.log(data,"remove");
        let res = selectedProductData.filter(x => data.some(y => y._id === x._id));
        console.log(res,"res");
        setSelctedProductData(res)
    }
     
    const onChange = (data,id)=>{
        let products = []
       for(let product of selectedProductData){
        if(id===product._id){
            let obj = {}
            if(data.target.name==="selling_price" || data.target.name==="qty"){
                obj={
                    ...product,
                    [data.target.name]:Number(data.target.value)
                 }
            }else{
          obj={
            ...product,
            [data.target.name]:data.target.value
         }}
         products.push(obj)
        }else{
            products.push(product)
        }
       }
       setSelctedProductData(products)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setBtnName("Processing...")
        console.log(selectedProductData,form,"");
        let products = []
         for(let product of selectedProductData){
            let obj = { 
                P_Id: `postInstallationPayment (${product.startDate} "to" ${product.endDate})`, 
                sellingPricePerPic: Number(product.selling_price), 
                mrpPricePerPic: Number(product.mrp), 
                qty: product.qty, 
                hsn_code: product.hsn_code, 
                productName: product.name
            }
            products.push(obj)
         }
         console.log(products,"producrs");
         
        let detail = {
            ...form,
            name: form.name.trim(),
            amount: Number(form.amount),
            subTotal:SubTotal,
            generatedHex: sub.generatedHex,
            BusinessGst: form.BusinessGst === undefined ? "" : form.BusinessGst,
            In_Up: form.In_Up === "true" ? true : false,
            Is_Business: form.Is_Business === "true" ? true : false,
            trnxArray: transactionCount,
            product:products
        }
        console.log(detail, "detail");
        setBtnName("Submit")

        axios.post(`${serverUrl.u}/app/tele/registerPostPaymentInvoice`, detail).then(
            (res) => {
                if (res.data.success === true) {
                    notify(res.data.message)
                    setBtnName("Submit")
                } else {
                    notifyFailed(res.data.message)
                    setBtnName("Retry")
                }
            }
        ).catch((err) => {
            notify("Failed")
            setBtnName("Retry")
        })
    }
    const handleChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value })
        if(event.target.name==="amount"){
            setSubTotal(Number(event.target.value)-Number(event.target.value)*0.18)
        }
        if(event.target.name==="subTotal"){
            console.log("called");
            setSubTotal(Number(event.target.value))
        }
    }
    useEffect(()=>{

    },[form])
    return (
        <div>
            <Button
                onClick={handleOpen}
                sx={{ width: 1 }}
                size='small'
                variant="contained"
            >{"Post Payment Invoice"}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="ModelBox">
                    <Grid container>
                        <Grid item md={12} sx={{ textAlign: "right" }}>
                            <h4
                                onClick={handleClose}
                                style={{ cursor: "pointer" }}
                            >X</h4>
                        </Grid>

                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} >
                            <Grid item md={6}>
                                <label>Client Name</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="name"
                                        name="name"
                                        autoComplete="current-name"
                                        aria-label="name"
                                        aria-describedby="basic-addon1"
                                        defaultValue={sub.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <label >First Telecaller Name</label>
                                <div class="input-group">
                                    <select
                                        class="custom-select height-select"
                                        id="inputGroupSelect01"
                                        name="createdBy"
                                        label="createdBy"
                                        autoComplete="current-createdBy"
                                        defaultValue={loginName}
                                        onChange={handleChange}
                                    >
                                        {salesUsers?.map((option) => (
                                            <option value={option.first_name}>{option.first_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <label>Amount(With Gst)</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="amount"
                                        name="amount"
                                        min={0}
                                        autoComplete="current-amount"
                                        aria-label="amount"
                                        aria-describedby="basic-addon1"
                                        defaultValue={0}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={3}>
                                <label>Amount(WithOut Gst)</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="subTotal"
                                        name="subTotal"
                                        min={0}
                                        autoComplete="current-subTotal"
                                        aria-label="subTotal"
                                        aria-describedby="basic-addon1"
                                        value={SubTotal}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <label>Bill To Address</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="BillTo"
                                        name="BillTo"
                                        autoComplete="current-BillTo"
                                        aria-label="BillTo"
                                        aria-describedby="basic-addon1"
                                        onChange={handleChange}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item md={6}>
                                <label >UP Sell</label>
                                <div class="input-group mb-3">
                                    <select
                                        class="custom-select height-select"
                                        id="inputGroupSelect01"
                                        name="In_Up"
                                        label="In_Up"
                                        autoComplete="current-In_Up"
                                        defaultValue={form.In_Up}
                                        onChange={handleChange}
                                    >
                                        <option value={"false"}>{"No"}</option>
                                        <option value={"true"}>{"Yes"}</option>
                                    </select>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <label >Business GST</label>
                                <div class="input-group mb-3">
                                    <select
                                        class="custom-select height-select"
                                        id="inputGroupSelect01"
                                        name="Is_Business"
                                        label="Is_Business"
                                        autoComplete="current-Is_Business"
                                        defaultValue={form.Is_Business}
                                        onChange={handleChange}
                                    >
                                        <option value={"false"}>{"No"}</option>
                                        <option value={"true"}>{"Yes"}</option>
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <label>Comment</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="comments"
                                        name="comments"
                                        autoComplete="current-comments"
                                        aria-label="comments"
                                        aria-describedby="basic-addon1"
                                        onChange={handleChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <label>Business Gst Number</label>
                                <div class="input-group mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="BusinessGst"
                                        name="BusinessGst"
                                        autoComplete="current-BusinessGst"
                                        aria-label="BusinessGst"
                                        aria-describedby="basic-addon1"
                                        onChange={handleChange}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container mb={4}>
                            <Grid item md={12}>
                                <Multiselect
                                    displayValue="name"
                                    onRemove={onRemove}
                                    onSelect={selectedValue}
                                    options={upSellProduct}
                                    style={{
                                        width: "100px !important",
                                        multiselectContainer: {
                                            marginTop: "5px",
                                            backgroundColor: "#ffffff"
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {
                            selectedProductData.length!==0?
                            <Grid container spacing={1}>
                            <div className='row ml-2 mb-2'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Selling Price</th>
                                        <th>Quantity</th>
                                        <th>HSN Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        selectedProductData.map((e) => {
                                            return (
                                                <tr>
                                                    <td>{e.name}</td>
                                                    <td><input 
                                                        type='date'
                                                        name="startDate"
                                                        onChange={(ele) => onChange(ele, e._id)}
                                                    /></td>
                                                    <td><input 
                                                        type='date'
                                                        name="endDate"
                                                        onChange={(ele) => onChange(ele, e._id)} 
                                                    /></td>
                                                    <td>
                                                        <input 
                                                          type="number"  
                                                          value={e.selling_price} 
                                                          onChange={(ele) => onChange(ele, e._id)} 
                                                          min={1} 
                                                          style={{ width: "60px" }} 
                                                          name='selling_price'
                                                        />
                                                    </td>
                                                    <td>
                                                        <input 
                                                          type="number" 
                                                          value={e.qty} 
                                                          onChange={(ele) => onChange(ele, e._id)} 
                                                          min={1} 
                                                          style={{ width: "60px" }} 
                                                          name='qty'
                                                        />
                                                    </td>
                                                    <td>
                                                        <input 
                                                          type="text" 
                                                          value={e.hsn_code} 
                                                          onChange={(ele) => onChange(ele, e._id)} 
                                                          style={{ width: "60px" }} 
                                                          name='hsn_code'
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>
                            </Grid>:null
                        }
                        {
                            Array.from({ length: numberTransAction }).map(e => (
                                <MakeTransactionData transactionCount={transactionCount} setTransactionCount={setTransactionCount} />

                            ))
                        }

                        <Grid container spacing={2} mb={2}>
                            <Grid item md={2}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={btnName === "Processing..." ? true : false}
                                >
                                    {btnName}
                                </Button>
                            </Grid>
                            <Grid item md={6}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='button'
                                    onClick={() => {
                                        setNumberTransAction(numberTransAction + 1)
                                    }}
                                >
                                    ADD more transaction
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}