import React, { useState } from "react";
import { user_roles } from "../Data";
import { useSelector } from "react-redux";
import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";
import { subHistory } from "./SubscriptionHistory";

function NotShowingOnGrievencePageHeena({ sub, reload, setReload }) {
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let [deleteAndDropPop,setDeleteAndDropPop] = useState(false)
  let [btntext,setBtnText] = useState("Submit")
  const [timeHave,setTimeHave] = useState(true)

  const makeVisible =async(event)=>{
    event.preventDefault();
    setBtnText("Processing....")
    const datas = new FormData(event.currentTarget);
    let data={
        generatedHex:sub.generatedHex,
        date:datas.get('uninstallDate'),
        subscriptionStage:datas.get('subscriptionStage')
    }
   
   
     await axios.post(`${serverUrl.u}/api/acitvateUninstallation`,data).then((res)=>{
        console.log(res);
        if(res.data.success){
            notify(res.data.message)
            subHistory('UnInstallaion Reactivate',{...sub,payload:data},"UnInstallaion Reactivate","uninstallation")
            setReload(!reload)
        }else{
            notifyFailed(res.data.message)
        }
     }).catch(err=>{
        console.log(err);
        let msg  = err?.response?.data?.message?(err?.response?.data?.message):err?.message
        notifyFailed(msg)
     }).finally(()=>{
        setBtnText("Submit")
     })
  }

  if (
    sub.dropped === true &&
    sub.toBePickedUp === false &&
    sub.subscriptionStage === "grivience" &&
    (userRole.includes(user_roles.SuperAdmin) ||
      userRole.includes(user_roles.SalesAdmin))
  ) {
    return (
      <>
        <button 
        onClick={()=>setDeleteAndDropPop(true)}
        className="btn btn-primary width">
          Make Visible on Grievance Page
        </button>
        {
        deleteAndDropPop === true ?
          <div className="reson-container" style={{zIndex:12}}>
            <div className="reson-cross" onClick={() => setDeleteAndDropPop(!deleteAndDropPop)}>x</div>
            <form onSubmit={makeVisible} validate={true.toString()}>
         
            
            <label>Select Stage</label>
          <div class="input-group mb-3">
            <select
            class="custom-select height-select"
              defaultValue="address&layout"
              name='subscriptionStage'
              autoComplete='current-subscriptionStage'
            >

              <option value="address&layout">Address & Layout</option>
              <option value="grivience">Grivience</option>
              <option value="ready to install">Installation</option>
              </select>
              </div>
              <label className="create-label1">Installation Time? <span className="required_star">*</span></label>
                      <div class="input-group mb-1">
                        <select class="custom-select height-select" required id="inputGroupSelect01"
                          name="time"
                          label="time"
                          autoComplete="current-time"
                          defaultValue={"true"}
                          onChange={(e)=>setTimeHave(e.target.value==="false"?false:true)}
                        >
                            <option value={"true"}>{"Yes"}</option>
                            <option value={"false"}>{"No"}</option>
                        </select>
                      </div>
              <label>Uninstall Date</label>
              <div class="input-group mb-3">
                {
                    timeHave ?<input type="datetime-local" class="form-control" required id="outlined" label="uninstallDate" name='uninstallDate' autoComplete="uninstallDate"   aria-describedby="basic-addon1"/>: <input type="date" class="form-control" required id="outlined" label="uninstallDate" name='uninstallDate' autoComplete="uninstallDate"   aria-describedby="basic-addon1"/>
                }
              </div>
                                
                <button className="btn btn-primary" type='submit' style={{pointerEvents:btntext==="Processing...."?"none":""}}>{btntext}</button>
                </form>
           </div> : null
      }
      </>
    );
  }
}

export default NotShowingOnGrievencePageHeena;
