
import axios from 'axios'
import React, { useRef } from 'react'
import { serverUrl } from '../ApiUrl'
import './SearchWithDrop.css'

export default function SearchWithDrop({setDisplayData}) {
    let search = useRef("")
    const result = (name)=>{
    
        axios.post(`${serverUrl.u}/api/tele/searchSingleSubscriptionInDrop`,{
            name:name,
        }).then(function (res) {
            console.log(res,"drop");
            setDisplayData(res.data.alisteSubscription)
        }).catch(function (err) {
            console.log(err);
        })
    }
  return (
    <div style={{position:"relative",width:"100%"}}>
<input type="text" style={{float:"right"}} ref={search}  placeholder="Search here by name...."/>
<div className='search-btn-container-drop'>
<button className='btn btn-primary'  style={{borderRadius:"12px",position:"absolute",right:"0.2%",padding:"0 4px",top:"10%",fontSize:"20px !important"}} onClick={()=>result(search.current.value)}>Search</button>
</div>
</div>  )
}
