import { Installation } from "./actionIndex";
import axios from "axios";
import { serverUrl } from "../ApiUrl";
import store from ".";
export const installationSaveData = (data) => {
  return {
    type: Installation.Installation_DATA,
    payload: data
  }
}

export const updateInstallationData = (data) => {
  return {
    type: Installation.UpdateInstallion_DATA,
    payload: data
  }
}

export const removeInstallationData = (data) => {
  return {
    type: Installation.Remove_DATA,
    payload: data
  }
}

export const electricianList = (data)=>{
  return {
    type:Installation.AVAILABLE_ELECTRICIAN,
    payload:data
  }
}

export const InstallationDataApi = async (dispatch) => {
  await axios.post(
    `${serverUrl.u}/api/tele/getAllNotInstalled`
  )
    .then(async function (response) {
      let detail = response.data.data.sort(function (a, b) {
        return new Date(a.InstallationDate) - new Date(b.InstallationDate);
      });
      dispatch(installationSaveData(detail))
      // // Assuming you have two arrays: oldArray and newArray
      // let oldArray = store.getState().installationData
      // let newArray = detail
      // // Assuming you have two arrays: oldArray and newArray

      // // Check if the length of the arrays is the same
      // if (oldArray.length === newArray.length) {
      //   // Iterate over each object in the array
      //   for (let i = 0; i < oldArray.length; i++) {
      //     const oldObject = oldArray[i];
      //     const newObject = newArray[i];

      //     // Check if both objects exist at the same index
      //     if (oldObject && newObject) {
      //       // Compare each property of the objects
      //       for (const key in oldObject) {
      //         if (oldObject.hasOwnProperty(key) && newObject.hasOwnProperty(key)) {
      //           // Check if the property is an object
      //           if (typeof oldObject[key] === 'object' && typeof newObject[key] === 'object') {
      //             // Recursively compare nested objects
      //             const nestedChanges = compareObjects(oldObject[key], newObject[key]);
      //             if (nestedChanges) {
      //               console.log(`Changes detected in nested object at key ${key} of object at index ${i}`);
      //               dispatch(installationSaveData(detail))

      //               // If you just want to know if any change occurred, you can return true here
      //               // return true;
      //             }
      //           } else if (oldObject[key] !== newObject[key]) {
      //             console.log(`Value of ${key} has changed in object at index ${i}`);
      //             dispatch(installationSaveData(detail))

      //             // If you just want to know if any value has changed, you can return true here
      //             // return true;
      //           }
      //         }
      //       }
      //     } else {
      //       console.log(`Objects at index ${i} are missing in one of the arrays`);
      //       dispatch(installationSaveData(detail)) 

      //       // If you want to treat this as a change, you can return true here
      //       // return true;
      //     }
      //   }
      // } else {
      //   console.log("Arrays have different lengths");
      //   dispatch(installationSaveData(detail))

      //   // If you want to treat this as a change, you can return true here
      //   // return true;
      // }

      // // Function to recursively compare nested objects
      // function compareObjects(obj1, obj2) {
      //   for (const key in obj1) {
      //     if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      //       if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      //         const nestedChanges = compareObjects(obj1[key], obj2[key]);
      //         if (nestedChanges) {
      //           return true;
      //         }
      //       } else if (obj1[key] !== obj2[key]) {
      //         return true;
      //       }
      //     }
      //   }
      //   return false;
      // }

      // if (JSON.stringify(detail) !== JSON.stringify(store.getState().installationData)) {
      // }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const availableElectrician = async()=>{
  axios.post(`${serverUrl.u}/api/elec/getAvailableElectrication`,{
      InstallationDate:new Date()
  }).then(function (res) {
      // console.log(res);
      if(res.data.success){
          if(res.data.data.success){
             store.dispatch(electricianList(res.data.data.availableElectrician))
          }else{
            store.dispatch(electricianList([]))
          }
      }else{
        store.dispatch(electricianList([]))
      }
  }).catch(function (err) {
      console.log(err);
      store.dispatch(electricianList([]))
  })
}