import React, { useEffect, useState } from "react";
import "./ConvertedLead.css";
import "./DispatchData.css";
import moment from "moment/moment";
import './Installation.css'
import Qutoes from "./Qutoes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { refundRecords } from "./Redux/action";
import { useDispatch } from "react-redux";
import axios from "axios";
import { serverUrl } from "./ApiUrl";
import SearchWithDrop from "./Function/SearchWithDrop";
import { subHistory } from "./Function/SubscriptionHistory";
import ReteriveClient from "./DropClient/ReteriveClient";
export default function DropClients() {
  let [displayData,setDisplayData] = useState([])
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let [page,setPage] = useState(1)
  let [totalPage,setTotalPage] = useState(1)
  let [q, setq] = useState("");
  let [reload,setReload] = useState(false);
  let userRole =useSelector((state)=>state.sar.data.user.roles)
  let RefundDetail = useSelector((state)=>state.refudRecord)
  const [searchParam] = useState(["name"]);
  const notify = (text) => toast(text);
  
  useEffect(()=>{
     axios.get(`${serverUrl.u}/api/sub/getDroppedSubscription/?p=${page-1}`).then(
        function (res) {
            console.log(res);
            setDisplayData(res.data.droppedSubscription)
            setTotalPage(res.data.totalPage)
        }
     ).catch(function (err) {
           console.log(err);
     })
  },[page,reload])
  
  const clientRetirvel = async(id)=>{
       axios.post(`${serverUrl.u}/api/subscription/reteriveDropClient`,{
        generatedHex:id.generatedHex,
       }).then(function (res) {
          console.log(res);
          if(res.data.success===true){
            notify(res.data.message)
            subHistory("Client Reterive",id,"Client Reterive","Client Reterive")
          }else{
            notify(res.data.message)
          }
       }).catch(function (error) {
          console.log(error);
          notify("Failed")
       })
  }
 
  return (
    <div  style={{padding:"0 4%",marginTop:"4vh"}}>
      
      <div className="input-field">
      {/* <SearchDataWithStage setDisplayData={setDisplayData} stage={"installed"}/> */}
      <SearchWithDrop setDisplayData={setDisplayData}/>
      </div>
      
        <ToastContainer />
        <nav aria-label="..." className='pagination-container'>
      <ul class="pagination">
        {
          [...Array(totalPage)].map((e,i)=>{
              return(
                
                <li className={page===i+1?"page-item active":"page-item"} onClick={()=>setPage(i+1)}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
</nav>
      <div className='table-container1' style={{marginTop:0}}>
      <table className="table table-sm table-bordered ">
        <thead>
          <tr>
          <th>Sr. No.</th>
              <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
              <th scope="col">Telecaller Name</th>
              <th>Client Status</th>
              <th scope="col">City</th>
              <th>Address<span style={{color:"red"}}>*</span></th>
              <th>Reason of Drop</th>
              <th>Hyper Link<span style={{color:"red"}}>*</span></th>
              <th scope="col">Phone</th>
              <th>Payment</th>
              <th scope="col">Drop Created At</th>
              <th scope="col">Appliances</th>
              <th scope="col">Selected Plan</th>
              {/* <th>Retrive</th> */}
          </tr>
        </thead>
        <tbody>
          {displayData.length!==0
            ? displayData.map((e, i) => {
                return (
                    <tr key={i}>
                      <td>{(i+1)+((page-1)*50)}</td>
                      <td className="pr-15 pl-55" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                      <td>{e.teleCallerId}</td>
                      <td style={{color:e.dropped === false ?"#008000":"#FF0000"}}>{e.dropped === true ? "Dropped" : "Active"}</td>
                      <td>{e.city}</td>
                      <td>{e.address}</td>
                      <td>{e.reasonForDrop}</td>
                      <td label="Hyper Link" contenteditable="true" >{e.addressHyperlink}</td>
                      <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                      <td style={{ color: `${e.partialPayment === false ? "green" :(e.partialAmount===0)?"red":"orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount===0)?"Zero":`Partial`}  </td>
                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <td>{e.totalAppliances}</td>
                      <td>{e.planFrequency}</td>
                      {/* <td><button className="btn btn-success" onClick={()=>clientRetirvel(e)}>Retrive Client</button></td> */}
                       <td><ReteriveClient e={e} notify={notify} reload={reload} setReload={setReload}/></td> 
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
     
      
    <Qutoes/>
    </div>
  );
}
