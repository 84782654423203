import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { Grid } from '@mui/material';
import { PaymentModes } from '../Data';
import { salesUserData } from './AxiosControl';
import './PlanUpdate.css'
import { useSelector } from 'react-redux';


export default function RefundDetails({e,notify}) {
    let [forms,setForm] = useState(false)
    let [paymentMode,setPaymentMode] = useState("BankTransfer")
    let salesData = useSelector(state=>state.salesUser)
    let token = useSelector((state)=>state.sar.data.token)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    
      const updateRefundDetails = async (userData) => {
        console.log(userData,"refund form");
        await axios.post(`${serverUrl.u}/app/fnd/addFundRecord`, {
          telecallerId:userData.telecallerId,
            refunndTo:userData.refunndTo,
            generatedHex:e.generatedHex,
            refundAmount:userData.refundAmount,
            refundMethod:userData.refundMethod,
            refundDate:userData.refundDate,
            refundUpiId:userData.refundUpiId,
            reasonOfRefund:userData.refundReson,
            bankTransferDetails:{
                 Name:userData.bankNameClient,
                 Account_Number:userData.accountNumber,
                 IFSC:userData.ifsc
            }
          })
          .then(function (response) {
            console.log(response);
            if(response.data.status===true){
                notify(response.data.message)
                setForm(false)
            }else{
                notify("error occur")
            }
          })
          .catch(function (error) {
            console.log(error);
            notify("error occur")
          })
      };
     
      const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let userData = {
          refunndTo: data.get("refundTo"),
          telecallerId: data.get("telecallerId"),
          refundAmount: data.get("refundAmount"),
          refundMethod: data.get("refundMode"),
          refundDate: data.get("refundDate"),
          refundUpiId: data.get("refundUpi"),
          refundReson:data.get('refundReson'),
          bankNameClient:data.get('bankNameClient'),
          accountNumber:data.get("accountNumber"),
          ifsc:data.get('ifsc'),
        };
              updateRefundDetails(userData);
      };
  return (
    <>
    <button
      className="btn btn-primary"
      onClick={() => setForm(true)}
    >
      Refund Details
    </button>
  
  

     {forms=== true ? (
        <div className="updatePlan">
          <form onSubmit={handleSubmit}>
            <div className="cross1" onClick={() => setForm(false)}>
              x
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <label>Name <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="refundTo"
                    required
                    name="refundTo"
                    autoComplete="refundTo"
                    placeholder="refund To"
                    aria-label="refundTo"
                    aria-describedby="basic-addon1"
                    value={e.name}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>TeleCaller <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <select class="custom-select height-select" 
                                  required 
                                  id="telecallerId" 
                                  name="telecallerId"
                                  aria-label="telecallerId"
                                  aria-describedby="basic-addon1"
                                  autoComplete="current-telecallerId" 
                                  defaultValue={loginName}
                                  >
                                     {salesData?.map((option) => (
                                         <option value={option?.first_name}>{option?.first_name}</option>
                                      ))}
                                       
                          </select>
                </div>
              </Grid>
            </Grid>
          
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <label>Hex Code <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="hex"
                    name="hex"
                    required
                    autoComplete="current-hex"
                    aria-label="hex"
                    aria-describedby="basic-addon1"
                    value={e.generatedHex}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Refund Amount <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                <input
                    type="text"
                    class="form-control"
                    id="refundAmount"
                    name="refundAmount"
                    autoComplete="current-refundAmount"
                    placeholder="refund Amount"
                    aria-label="refundAmount"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <label>Refund Method <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                <select class="custom-select height-select" 
                                    required 
                                    id="inputGroupSelect01" 
                                    defaultValue="choose one"
                                    name="refundMode"
                                    label="City"
                                    onChange={(e)=>setPaymentMode(e.target.value)}
                                    autoComplete="current-refundMode" 
                                    >
                                      {PaymentModes.map((option) => (
                                      <option value={option.value}>{option.value}</option>
                                      ))}
                            </select>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Refund Date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    class="form-control"
                    id="refundDate"
                    name="refundDate"
                    required
                    autoComplete="current-refundDate"
                    placeholder="refund Date"
                    aria-label="refundDate"
                    aria-describedby="basic-addon1"
                   
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              
              <Grid item xs={12} md={6}>
              <label>Reson for Refund <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="refundReson"
                    name="refundReson"
                    autoComplete="current-refundReson"
                    placeholder=""
                    required
                    aria-label="refundReson"
                    aria-describedby="basic-addon1"
                    
                  />    
                </div>
              </Grid>
              {
                paymentMode==="upi"?
                <Grid item xs={12} md={6}>
                <label>Refund Upi Id<span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="refundUpi"
                    name="refundUpi"
                    autoComplete="current-refundUpi"
                    placeholder=""
                    required
                    aria-label="refundUpi"
                    aria-describedby="basic-addon1"
                    
                  />
                </div>
              </Grid>:
              <Grid item xs={12} md={6}>
              <label>Client Name in Bank<span style={{color:"red"}}>*</span></label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="bankNameClient"
                  name="bankNameClient"
                  autoComplete="current-bankNameClient"
                  placeholder=""
                  required
                  aria-label="bankNameClient"
                  aria-describedby="basic-addon1"
                  
                />
              </div>
            </Grid>
              }
              
            </Grid>
            {
                paymentMode!=="upi"?
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
              <label>Account Number <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="accountNumber"
                    name="accountNumber"
                    autoComplete="current-accountNumber"
                    placeholder=""
                    required
                    aria-label="accountNumber"
                    aria-describedby="basic-addon1"
                    
                  />    
                </div>
              </Grid>
              
                <Grid item xs={12} md={6}>
                <label>IFSC Code<span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="ifsc"
                    name="ifsc"
                    autoComplete="current-ifsc"
                    placeholder=""
                    required
                    aria-label="ifsc"
                    aria-describedby="basic-addon1"
                    
                  />
                </div>
              </Grid>
              </Grid>:null
            }
            <Grid container spacing={4}>
              <Grid item xs={12} md={2}>
                <button className="btn btn-primary" type="submit">
                  Update Refund Details
                </button>
              </Grid>
              
            </Grid>
          </form>
             </div>
      ) : null}
      </>
  )
}
