import { Box, Button, Grid, Modal, Stack } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { clientRetention } from './Api';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    borderRadius: "12px",
    overflowY: "scroll",
    maxHeight: "85vh",
}
const labelStyle = {
    fontSize:"10px",
    margin:0
}
function ClientHouseWiseRetention({sub}) {
    const [open, setOpen] = React.useState(false);
    const [time, setTime] = useState({})
    const [onlineCommand,setOnlineCommand] = useState(0)
    const [btn,setBtn] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => setOpen(true)}
            >Retention</Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="ModelBox">

                    <Stack direction={"row"} spacing={2} mt={2}>
                        <Grid container spacing={1}>
                            <Grid item md={6} sm={2} xs={5}>
                                <label style={labelStyle}>Start Date</label>
                                <div class="input-group mb-1">
                                    <input
                                        type="date"
                                        className="form-control w-100"
                                        id="date"
                                        name="date"
                                        onChange={(event) => {
                                            setTime({ ...time, startDate: event.target.value })

                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={6} sm={2} xs={5}>
                                <label style={labelStyle}>End Date</label>
                                <div class="input-group mb-1">
                                    <input
                                        type="date"
                                        className="form-control w-100"
                                        id="date"
                                        name="end_date"
                                        onChange={(event) => {
                                            setTime({ ...time, endDate: event.target.value })

                                        }}
                                    />
                                </div>
                            </Grid>



                        </Grid>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled={btn}
                            onClick={
                                async () => {
                                    // handleClose()
                                setBtn(true)
                                 let command = await clientRetention(time,sub)
                                 setOnlineCommand(command)
                                 setBtn(false)
                                }}
                        >Submit</Button>
                    </Stack>
                    <Stack direction={"row"} spacing={2} mt={2} style={{paddingLeft:"8px"}}>
                        {
                          onlineCommand!==0?
                          <Stack>Total Online Command {onlineCommand}</Stack>:null
                        }
                    </Stack>
                </Box>

            </Modal>
        </>
    )
}

export default ClientHouseWiseRetention