import React, { useState, useRef } from 'react';
import axios from 'axios';
import { pages, serverUrl } from '../ApiUrl';
import { deleteAndDropCategory, grevianceCategrory, user_roles } from '../Data';
import './Deleteanddrop.css';
import { subHistory } from './SubscriptionHistory';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  removeInstallationData,
  updateInstallationData,
} from '../Redux/installationActions';
import Multiselect from 'multiselect-react-dropdown';
import { notify } from '../Common/Notification';
import { removeAddressLayout } from '../Redux/Action/adderess&layout';
import { smartMeterOrderTypes } from './data';

export default function Deleteanddrop({ e, data, setReload, reloadW, page }) {
  let [deleteAndDropPop, setDeleteAndDropPop] = useState('false');
  let [btntext, setBtnText] = useState('Reson');
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let employeeId = useSelector((state) => state.sar.data?.user?.employeeId);

  let [categorys, setCategory] = useState();
  let [pickup, setPickUP] = useState(
    page === pages.grivianceDeartment ? true : false
  );
  let Select = useRef();
  const smartMeterTrue = smartMeterOrderTypes.includes(data.orderType);
  let dispatch = useDispatch();
  const handleSubmit = async (event) => {
    if (!['search', pages.department, pages.addressLayout].includes(page)) {
      if (
        data.zone === '' ||
        data.zone === null ||
        data.zone === undefined ||
        data.zone === 'empty'
      ) {
        notify('Plz Select Subscription zone Before Sending');
        return;
      }
    }

    event.preventDefault();
    setBtnText('Processing....');
    const datas = new FormData(event.currentTarget);
    let userData = {
      reson: datas.get('reson'),
      category: datas.get('category'),
      pickup: datas.get('pickup'),
      uninstallDate: datas.get('uninstallDate'),
      other: datas.get('other'),
      subscriptionStage: smartMeterTrue
        ? 'ready to install'
        : datas.get('subscriptionStage'),
      // masterCancel:datas.get('masterCancel')!==undefined?(datas.get('masterCancel')==="false"?false:true):false
      masterCancel: smartMeterTrue ? false : datas.get('masterCancel') || false,
    };
    let categoryGrivianceDepartment = '';
    if (page === pages.grivianceDeartment) {
      categoryGrivianceDepartment = Select.current.state.selectedValues.reduce(
        (pre, curr) => {
          return pre + curr.value + ',';
        },
        ['']
      );
    }

    deleteAndDrop(userData, categoryGrivianceDepartment);
  };
  const deleteAndDrop = async (userData, categoryGrivianceDepartment) => {
    await axios
      .post(`${serverUrl.u}/api/subscription/deleteanddrop`, {
        subId: e,
        reasonForDrop:
          page === pages.grivianceDeartment
            ? categoryGrivianceDepartment
            : categorys !== 'Other'
            ? userData.category
            : userData.other,
        pickup: userData.pickup === 'true' ? true : false,
        pickedUpDate:
          userData.pickup === 'true' ? userData.uninstallDate : new Date(),
        loggingPerson: loginName,
        masterDrop: userData.masterCancel,
        subscriptionStage: userData.subscriptionStage,
      })
      .then(function (res) {
        if (res.status === 200) {
          notify(res.data.message);
          setDeleteAndDropPop(false);
          setBtnText('Reson');
          subHistory(
            pages.grivianceDeartment
              ? categoryGrivianceDepartment
              : categorys !== 'Other'
              ? userData.category
              : userData.other,
            data,
            'Delete&Drop',
            userData.pickup
          );
          if (page === pages.addressLayout) {
            dispatch(removeAddressLayout(res.data.data.DroppedSubscription));
          } else if (
            page === pages.installation &&
            userData.pickup === 'true'
          ) {
            dispatch(updateInstallationData(res.data.data.DroppedSubscription));
          } else if (page === pages.department) {
            ticketResolve();
          } else if (page === pages.grivianceDeartment) {
            for (
              let i = 0;
              i < Select.current.state.selectedValues.length;
              i++
            ) {
              subHistory(
                `grievance convert to unistallation`,
                data,
                `${'Grivance Department'}`,
                `${Select.current.state.selectedValues[i].value}`,
                userData.reson,
                userData.pickup === 'true' ? userData.uninstallDate : new Date()
              );
            }
            setReload(!reloadW);
          } else if (userData.pickup !== 'true') {
            dispatch(removeInstallationData(data));
          } else {
            setReload(!reloadW);
          }
        } else {
          notify(res.data.message);
          setBtnText('Try Again');
        }
      })
      .catch(function (er) {
        console.log(er);
        notify(er.message);
        setBtnText('Try Again');
      });
  };
  const ticketResolve = async () => {
    await axios
      .post(`${serverUrl.u}/api/zoho/ticketResolve`, {
        uniqueId: data._id,
      })
      .then(function (res) {
        console.log(res);
        if (res.status === 200) {
          notify(res.data.msg);

          setReload(!reloadW);
        } else {
          notify(res.data.msg);
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err.message);
      });
  };
  return (
    <>
      <button
        className='btn btn-primary width'
        style={{ backgroundColor: '#dc3545', borderColor: '#dc3545' }}
        onClick={() => setDeleteAndDropPop(true)}
      >
        Delete And Drop & Uninstall
      </button>
      {deleteAndDropPop === true ? (
        <div className='reson-container' style={{ zIndex: 12 }}>
          <div
            className='reson-cross'
            onClick={() => setDeleteAndDropPop(!deleteAndDropPop)}
          >
            x
          </div>
          <form onSubmit={handleSubmit} validate={true.toString()}>
            {(userRole.includes(user_roles.SuperAdmin) ||
              employeeId === '1024' ||
              employeeId === '1058') && (
              <>
                {!smartMeterTrue && (
                  <>
                    <label>Master Drop</label>
                    <div class='input-group mb-3'>
                      <select
                        class='custom-select height-select'
                        id='inputGroupSelect01'
                        name='masterCancel'
                        label='masterCancel'
                        autoComplete='current-masterCancel'
                        required
                      >
                        <option value={true}>{'Yes'}</option>
                        <option value={false}>{'No'}</option>
                      </select>
                    </div>
                  </>
                )}
              </>
            )}
            {!smartMeterTrue && (
              <>
                <label>Select Stage</label>
                <div class='input-group mb-3'>
                  <select
                    class='custom-select height-select'
                    defaultValue='address&layout'
                    name='subscriptionStage'
                    autoComplete='current-subscriptionStage'
                  >
                    <option value='address&layout'>Address & Layout</option>
                    <option value='grivience'>Grivience</option>
                    <option value='ready to install'>Installation</option>
                  </select>
                </div>
              </>
            )}
            <label>Category</label>
            {page === pages.grivianceDeartment ? (
              <div className='input-group'>
                <Multiselect
                  displayValue='value'
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck() {}}
                  options={grevianceCategrory}
                  ref={Select}
                  style={{
                    width: '100px !important',
                    multiselectContainer: {
                      marginTop: '3px',
                      backgroundColor: '#ffffff',
                    },
                  }}
                />
              </div>
            ) : (
              <>
                <div class='input-group mb-3'>
                  <select
                    class='custom-select height-select'
                    id='inputGroupSelect01'
                    name='category'
                    label='category'
                    autoComplete='current-category'
                    required
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {deleteAndDropCategory?.map((option) => (
                      <option value={option.value}>{option.value}</option>
                    ))}
                  </select>
                </div>
                {categorys === 'Other' ? (
                  <>
                    <label>Other Category</label>
                    <div class='input-group mb-3'>
                      <input
                        type='text'
                        class='form-control'
                        required
                        name='other'
                        autoComplete='current-other'
                      />
                    </div>
                  </>
                ) : null}
              </>
            )}

            <label>Uninstall Device</label>
            <div class='input-group mb-3'>
              <select
                class='custom-select height-select'
                id='inputGroupSelect01'
                name='pickup'
                label='pickup'
                autoComplete='current-pickup'
                onChange={(ele) => setPickUP(ele.target.value)}
                defaultValue={smartMeterTrue ? 'true' : pickup}
                required
              >
                <option value={'false'}>No</option>
                <option value={'true'}>Yes</option>
              </select>
            </div>
            {pickup === 'true' || pickup === true || smartMeterTrue ? (
              <>
                <label>Uninstall Date</label>
                <div class='input-group mb-3'>
                  <input
                    type='datetime-local'
                    class='form-control'
                    required
                    id='outlined'
                    label='uninstallDate'
                    name='uninstallDate'
                    autoComplete='uninstallDate'
                    aria-describedby='basic-addon1'
                  />
                </div>
              </>
            ) : null}
            <button
              className='btn btn-primary'
              type='submit'
              style={{
                pointerEvents: btntext === 'Processing....' ? 'none' : '',
              }}
            >
              {btntext}
            </button>
          </form>
        </div>
      ) : null}
    </>
  );
}
