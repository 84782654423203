import React from 'react'
import './InvoicePdf.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { ToWords } from 'to-words'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function InvoiceNewPdf() {
  const location = useLocation()
  const navigate = useNavigate();
  let [path, setPath] = useState();
  let [send,setSend] = useState(false)
  let [layout, setLayout] = useState([]);
  let [layoutNew, setLayoutNew] = useState();
  let [iframes,setIframe ] = useState(false)
  const notify = (text) => toast(text);
  useEffect(() => {
    const { from, path } = location.state;
    console.log(from,"from");
    setLayoutNew(from);
    axios.post(`${serverUrl.u}/api/tele/getSubscription`,{
        generatedHex:from.generatedHex
    }).then(function (res) {
        console.log(res.data.data,"check2");
        setLayout(res.data.data)
    }).catch(function (error) {
        console.log(error);
    })
   
    console.log("from",from);
    setPath(path)
  }, [])
  
 
  
  const date = new Date(layout?.InstalledDate);
 
  
  
  if(layout?.planFrequency==="Monthly"){
  date.setDate(date.getDate() + 30)
  }else if(layout?.planFrequency==="Quarterly"){
    date.setDate(date.getDate() + 100)
  }else if(layout?.planFrequency==="Semi Annualy"){
    date.setDate(date.getDate() + 210)
  }else if(layout?.planFrequency==="Annualy"){
    date.setDate(date.getDate() + 455)
  }else if(layout?.planFrequency==="Test (Weekly)"){
    date.setDate(date.getDate() + 7)
  }else if(layout?.planFrequency==="Two Years"){
    date.setDate(date.getDate() + 910)
  }else if(layout?.planFrequency==="Three Years"){
    date.setDate(date.getDate() + 1365)
  }
  

let [clientName,setClientName] =useState(layoutNew?.name)
  
  useEffect(() => {
    console.log("lay",layout);
    setClientName(layout?.name)
  }, [layout])
  
  const sendInvoiceSeq =(data)=>{
    axios.post(`https://web.alistetechnologies.com/api/tele/sendmail`,{
      email:data.email,
      name:data.name,
      fileName:'securityInvoice.pdf',
      paths:'public/invoicePdf/securityInvoice.pdf',
      generatedHex:data.generatedHex,
  }).then(function (res) {
    
      if(res.data.success===true){
        notify(res.data.message)
      }else{
        notify("Failed")
      }
    
  }).catch(function (error) {
      console.log(error);
      notify("Failed")
  })
  }
  const sendInvoice =(data)=>{
    axios.post(`https://web.alistetechnologies.com/api/tele/sendmail`,{
      email:data.email,
      name:data.name,
      fileName:'invoice.pdf',
      paths:'public/invoicePdf/invoice.pdf',
      generatedHex:data.generatedHex,
  }).then(function (res) {
      console.log(res);
      if(res.data.success===true){
        notify(res.data.message)
        sendInvoiceSeq(data)
      }else{
        notify("some error occur")
      }
    
  }).catch(function (error) {
      console.log(error);
      // notify(error.data.message)
      notify("some error occur")
  })
  }
  
  const downloadFile = async(blob,fileName) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    
      link.download = fileName;
    // some browser needs the anchor to be in the doc
   await document.body.append(link);
    link.click();
    link.remove();
    
    
  };
  const InvoiceGenerate = (e)=>{
    setIframe(true)
    console.log(e,"check1",layoutNew);
    
    axios.post(`https://web.alistetechnologies.com/api/tele/generatePdf`,{
      generatedHex:e.generatedHex,
      partialPayment:layoutNew.partialPayment===true?"true":"false",
      invoiceNumber:layoutNew.invoiceNumber
  },).then(function (res) {
  
      // downloadFile(new Blob([res.data]), `${e.name}.pdf`)
      if(res.status===200){
        setSend(true);
      
       // document.querySelector('#pdf-frame').src = URL.createObjectURL(res.data);
      }else{
        notify("Error Occur")
      }
  }).catch(function (error) {
      console.log(error);
      notify(error.data.message)
  })
  }
 
  return (
    <>
    {/* <button className='btn btn-primary' onClick={domtml}>Generate Pdf</button> */}
    <button className='btn btn-primary' onClick={()=>InvoiceGenerate(layoutNew)}>Generate Pdf</button>
      <button className='btn btn-primary' style={{ marginLeft: "5%" }} onClick={() => navigate(`/${path}`)}>Back</button>
  
      {(layout.length!==0 && layout.hasOwnProperty('email')===true && send===true)?
     
      <button className='btn btn-primary' style={{ marginLeft: "5%" }} onClick={() => sendInvoice(layout)}>Send Invoice (<span style={{fontSize:"10px"}}>{layout.email}</span>)</button>
:null
}
{
  iframes===true?
<iframe id="pdf-frame"></iframe>:null
}

     <ToastContainer/>
    </>
  )
}
