import axios from "axios";
import { serverUrl } from "../ApiUrl";

export const updateRefundReferenceId = async(userData,e,notify) =>{
  return await  axios.post(`${serverUrl.u}/app/fnd/updateReferenceId`,{
        referenceId:userData.refferenceId,
        refundBy:userData.refundBy,
        refundAmount:Number(userData.refundAmount), 
        sId:e._id,
      
    }).then(function (res) {
        console.log(res);
        if(res.data.success===true){
            notify("Refference id updated")
            return res;
        }else{
            notify(res.data.message)
        }
        
    }).catch(function (err) {
        console.log(err);
        notify(err.message)
        return err;
    })
}