import React from 'react'
import {  PDFViewer} from '@react-pdf/renderer';
import Qutoo from './Qutoo';

export const PdfComponent = () => {

    // const Data ={
    //     name: 'Shreyansh',
    //     planName: '1BHK',
    //     ratePerDay: 4,
    //     totalAplliances: 4,
    //     total: 100,
    //     tax: 18,
    //     discount: 50,
    //     planDuration: 'Monthly',
    //     securityDeposite: 100,
    //     installionCharges: 100,
    //     planRate: 4,
    //     paymentLink: "https://github.com"
    // }
    // const today = new Date();
    // const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // const dis = Data.discount == 0 ? "none" : "";
    // let offer = ""
    // switch (Data.planDuration) {
    //     case "Monthly":
    //         offer = "";
    //         break;
    //     case "Quarterly":
    //         offer = "+ 10 Days Free";
    //         break;
    //     case "Semi Annualy":
    //         offer = "+ 1 Month Free";
    //         break;
    //     case "Annualy":
    //         offer = "+ 3 Months Free";
    //         break;
    //     default:
    //         break;
    // }
    // const d = new Date();
    // let nameOfMonth = month[d.getMonth()];
    
  
    return (

<PDFViewer style={{height:"100vh",width:"100vw"}} orientation="portrait">
    <Qutoo/>
</PDFViewer>
      
  
   )
    }