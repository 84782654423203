import axios from 'axios';
import React from 'react'
import { useState } from 'react'
import { serverUrl } from '../ApiUrl';
import { useEffect } from 'react';
import { cleanNumber } from '../Function/AxiosControl';
import { WhatsApp } from '@mui/icons-material';
import ReactWhatsapp from 'react-whatsapp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function GetOverduePaymentLinkRzp({e, notify }) {
  let [pop, setPop] = useState(false)
  let [btn,setBtn] = useState("Search")
  let [btn1,setBtn1] = useState("Submit")
  let [invoice, setInvoice] = useState([])
  let [selectedInvoice, setSelectedInvoice] = useState([])
  let [amount,setAmount] = useState(0)
  let [paymentLink,setPaymentLink] = useState("")
  let [zohoInvoice,setZohoInvoices] = useState([])
  let [phoneNumbers,setPhoneNumber] = useState(0)
  let notifySuccess = (text)=> toast.success(text)
  let notifyFailed = (text)=> toast.error(text)
  useEffect(()=>setAmount(0),[])
 

  function getInvoices(){
    axios.post(`${serverUrl.u}/api/tele/getAllOverdueInvoice`, {
      phone: e.phone
    }).then(function (res) {
      console.log(res);
      if (res.data.success === true) {
        notify(res.data.message)
        setInvoice(res.data.finalInvoice) 
      }else{
        notify(res.data.message)
      }
    }).catch(function (err) {
      console.log(err);
      notify(err.response.data.message)
      
    })
  }

  const getSelectedInput = (checked,data)=>{
     if(checked===true){
        setSelectedInvoice([...selectedInvoice,data])
     }else{
      let newFilterInvoice = [...selectedInvoice]
      newFilterInvoice = newFilterInvoice.filter((inv)=>{
        if(data.zohoInvoiceNumber!==inv.zohoInvoiceNumber){
          return inv
        }
      })
      setSelectedInvoice(newFilterInvoice)
     }
  }
  const getSelectedAllInput = (checked,data)=>{
    let inputs = window.document.querySelectorAll('.checkSingle')
    for(let i of inputs){
      i.checked=checked
    }
    if(checked===true){
       setSelectedInvoice(data)
    }else{
     let newFilterInvoice = []
     setSelectedInvoice(newFilterInvoice)
    }
 }
  useEffect(()=>{
    let value = selectedInvoice.reduce((intialvalue,currentValue)=>{
                return intialvalue+currentValue.actualTotalAmount
    },0) 
    setAmount(Math.round(value))
    let array=[]
    for(let i of selectedInvoice){
         array.push({zohoInvoiceNumber:i.zohoInvoiceNumber,generatedHex:i.generatedHex,invoiceNumber:i.invoiceNumber})
    }
    setZohoInvoices(array)

  },[selectedInvoice])

  const getOverdueInvoicePaymentLink =async ()=>{
    setBtn1("Processing...")
    setPaymentLink('')
        let number = await cleanNumber(phoneNumbers)

        axios.post(`${serverUrl.u}/api/tele/getOverdueInvoiceLink`,{
          phone:e.phone, 
          zohoInvoice:zohoInvoice, 
          totalAmount:amount
        }).then(function (res) {
           console.log(res);
           if(res.data.success===true){
               setPaymentLink(res.data.url)
           }else(
            setPaymentLink('')
           )
           setBtn1("Submit")
        }).catch(function (err) {
           console.log(err);
           setPaymentLink('')
           setBtn1("Submit")
        })
  }
  return (
    <>
      <button className='btn btn-primary' 
         onClick={() =>{ 
          setPop(true)
          getInvoices()    
          setAmount(0) 

      }}>Overdue Invoice Payment RZP</button>

      {
        pop === true ?
          <div style={{ position: "fixed", height: "100vh", width: "100vw", display: "flex", overflow: "scroll", justifyContent: "center", alignItems: "center", top: 0, left: 0,zIndex:4}}>
            <div style={{ width:"fit-content", backgroundColor: "lightGray", padding: "10px 15px", borderRadius: "12px" ,maxHeight:"80vh",overflowY:"scroll",marginTop:'5vh'}}>
              <div style={{width:"100%",position:"relative",height:"35px"}}>
                <span 
                  style={{
                    position:"absolute",
                    right:"10px",
                    cursor:"pointer"
                  }}
                  onClick={()=>{
                      setPop(false)
                      setPaymentLink('')
                      setInvoice([])
                      setAmount(0)
                      setSelectedInvoice([])
                      setZohoInvoices([])
                      setPhoneNumber('')
                      }}>X</span>
              </div>
              {/* <form onSubmit={handleClientSearch}>
                <div className='row'>
                  <div className="form-group col-7">
                    <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Phone Number" autoComplete='current-phone' name='phone' />
                  </div>
                  <div className='col-5'>
                    <button className='btn btn-primary' type='submit' style={{pointerEvents:btn==="Search"?"":"none"}}>{btn}</button>
                  </div>
                </div>
              </form> */}
              {
                invoice.length !== 0 ?
                  <table className="table" style={{width:"35vw"}}>
                    <thead className="thead-light">
                      <tr>
                        <th><input type='checkbox'  style={{marginRight:'10px'}} name="chkAll" onChange={(event)=>getSelectedAllInput(event.target.checked,invoice)}/>Select</th>
                        <th scope="col">Hex</th>
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Status</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                     {
                      invoice.map((inv,index)=>{
                    
                        
                            return(
                              <tr>
                                <td><input type='checkbox' name="chk" className='checkSingle' onChange={(event)=>getSelectedInput(event.target.checked,inv)}/></td>
                                <td>{inv.generatedHex}</td>
                                <td>{inv.zohoInvoiceNumber}</td>
                                <td>{inv.status}</td>
                                <td>{inv.actualTotalAmount}</td>

                              </tr>
                            
                        
                        )
                      })
                     }

                    </tbody>
                  </table> : <div>Loading...</div>
              }
              {
                amount!==0?
                <div className='row'>
                <div className="form-group col-7">
                  <input type="number" className="form-control" id="exampleInputEmail1" placeholder="amount" autoComplete='current-amount' name='amount' value={amount}/>
                </div>
                <div className='col-5'>
                  <button className='btn btn-primary' type='submit' style={{pointerEvents:btn1==="Submit"?"":"none"}} onClick={()=>getOverdueInvoicePaymentLink()}>{btn1}</button>
                </div>
              </div>:null
              }
             
              
              {paymentLink!=="" ? (
                <div className='razorpayPaymentLink'>
                  
                    <ReactWhatsapp
                      number={`${phoneNumbers}`}
                      message={paymentLink}
                      style={{
                        backgroundColor: "#25D366",
                        border: "none",
                        borderRadius: "12px",
                        padding: "5px",
                    
                      }}
                    >
                      <WhatsApp
                        sx={{
                          color: "white",
                          verticalAlign: "middle",
                          fontSize: "xx-large",
                        }}
                      />
                    </ReactWhatsapp>
                  
                 
                    <button
                      className="btn btn-primary btn-width "
                      onClick={() => navigator.clipboard.writeText(paymentLink)}
                    >
                   Copy
                    </button>
                  
                  <ToastContainer/>
                </div>
              ) : null}

            </div>
          </div> : null
      }

    </>
  )
}
