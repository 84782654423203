import axios from 'axios'
import moment from 'moment'
import React,{useRef} from 'react'
import { serverUrl } from './ApiUrl'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirstandLastDate } from './Common/applianceDifference'
import { notifyFailed } from './Common/Notification'

export default function AllElectricianIncentive() {
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    let startDate = useRef(new Date())
    const notify = (text) => toast(text);
    let endDate = useRef(new Date())
    let [data,setData] = useState([])

    const getIncentive = ()=>{
      let gg = new Date(startDate.current.value)
      let data= getFirstandLastDate(startDate.current.value)
      setData([])
       
        axios.post(`${serverUrl.u}/app/elec/getCompleteIncentiveOfElectrician`,{
            "firstDate":gg,
            "lastDate":data.lastDay
        }).then(function (res) {
          
             if(res.data.success===true){
                if(res.data.data.success===true){
                    setData(res.data.data.data.arrayOfEmployeIncentive)
                }else{
                    notifyFailed("Record not Found")
                    setData([])
                }
             }
        }).catch(function (err) {
             console.log(err);
        })
    }
  return (
    <div style={{padding:"0 1%",marginTop:"4vh"}}>
        <ToastContainer/>
         <div className='row'>
                  
                  <div className="col-md-2 col-sm-5">
                  <label className="create-label1">Month</label>
                      <div class="input-group ">
                        <input type="month" ref={startDate} style={{width:"100%"}}/>
                        </div>
                  </div>
                 
                  <div className="col-md-1 col-sm-5">
                     <button className='btn btn-primary mt-3' onClick={()=>getIncentive()}>Get Data</button>
                  </div>
                
                  
            </div>
            <div className='table-container1' style={{marginTop:"20px"}}>
    <table className="table table-sm table-bordered ">
        <thead>
          <tr>
          <th>Sr. No.</th>
              
              <th scope="col">Electrician Name</th>
              <th>Total Appliances</th>
              <th>Appliance Incentive</th>
              <th>Upgrade Appliances</th>
              <th>Upgrade Appliance Incentive</th>
              <th>Grievance Site</th>
              <th>Grievance Incentive</th>
              <th>B2B Appliances</th>
              <th>B2B Incentive</th>
              <th>Total Incentive</th>
           
          </tr>
        </thead>
        <tbody>
          {data.length!==0
            ? data.map((e, i) => {
              let name = Object.keys(e)[0]
                return (
                    <tr key={i}>
                       <td>{i+1}</td> 
                      <td>{name}</td>
                      <td>{e[name]?.countofappliances}</td>
                      <td>{e[name]?.applianceIncentive}</td>
                      <td>{e[name]?.countUpgradeAppliances}</td>
                      <td>{e[name]?.upgradeApplianceIncentive}</td>
                      <td>{e[name]?.countOfGrievanceSite}</td>
                      <td>{e[name]?.grievanceIncentive}</td>
                      <td>{e[name].countOfB2BAppliances}</td>
                      <td>{e[name].countOfB2BIncentive}</td>
                      <td>{e[name].totalIncentive}</td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
       
    </div>
    <div style={{marginBottom:"60px",height:"60px"}}></div>

    </div>
  )
}
