import React, { useEffect, useState } from 'react'
import { siteStatusData } from './Api';
import { notifyFailed } from '../../Common/Notification';

export default function AllElectricianSiteStatus() {
    const [siteStaus, setSiteStaus] = useState([])
    useEffect(() => {
        siteStatusData().then(
            function (res) {
                console.log(res,"status");
                setSiteStaus(res)
            }
        ).catch(
            function (err) {
                notifyFailed("Failed")
            }
        )
    }, [])
    
    return (
        <div style={{ padding: "0 1%", marginTop: "4vh" }}>
            <div className='table-container1 mt-1'>

                <table className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" >Client Name</th>
                            <th scope="col">Phone</th>
                            <th>City</th>
                            <th>Electrician</th>
                            <th>Site Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            siteStaus.length !== 0
                                ? siteStaus.map((site, i) => {
                                    return (

                                        <tr key={i}>
                                            <td style={{ fontWeight: "bold" }}>{site.name}</td>
                                            <td>{site.phone}</td>
                                            <td>{site.city}</td>
                                            <td>
                                                <ul>
                                                    {
                                                        site.electrician.map((elec) => {
                                                            return (
                                                                <li>{elec.electricianName}</li>
                                                            )
                                                        })}
                                                </ul>
                                            </td>
                                            <td>{site.electricianstatus}</td>
                                            
                                        </tr>
                                    );
                                })
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
