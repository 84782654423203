import { Card } from '@mui/material'
import React from 'react'
import InvoiceDownloadLink from '../Invoice/InvoiceDownloadLink'
import InvoiceSend from '../Invoice/InvoiceSend'
import moment from 'moment'
import UpdategstDetial from './UpdategstDetial'
import CreditNoteDownload from '../Invoice/CreditNoteDownload'

export default function CreditNoteDetail({note}) {
  return (
    <Card 
      sx={{ 
            padding: "20px 30px", 
            margin: "10px 0", 
            boxShadow: "none", 
            position: "relative", 
            borderRadius: "13px",
            border: "1px solid #C6C6C6",
            width:"97%"
          }}
      key={note.creditNoteNumber}    
    >
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%"}}>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">CreditNote Number</div>
        <div className="mainHeadingSub">{note.creditNoteNumber}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Amount</div>
        <div className="mainHeadingSub">{note.amount}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Remaining Amount</div>
        <div className="mainHeadingSub">{note.remainingAmount}</div>
      </div>
    
          <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Zoho Invoice</div>
        <div className="mainHeadingSub" >{note.zohoInvoiceNumber}</div>
      </div>
        
      <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
      <div>
        <CreditNoteDownload creditNoteNumber={note.creditNoteNumber} name={note.name}/>
      </div>
      </div>
     
    </div>
</Card>
  )
}
