import React, { useState } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'

export default function ChangeCalculationProblem({e,notify,reload,setReload}) {
    let [btnText,setBtnText] = useState("Change Calculation Problem")
  const changeCalculationProblem = ()=>{
    setBtnText("Processing...")
      axios.post(`${serverUrl.u}/api/tele/changeCalculationProblem`,{
        generatedHex:e.generatedHex
      }).then(function (res) {
         console.log(res);
         if(res.status===200){
            notify(res.data.message)
            setBtnText("Change Calculation Problem")
            setReload(!reload)
         }else{
            notify(res.data.message)
            setBtnText("Failed")
         }
      }).catch(function (err) {
         console.log(err);
         notify(err.message)
         setBtnText("Failed")
      })
  }

  return (
    <div>
        {
            e.calculationProblem===true?
            <button className='btn btn-primary' onClick={()=>changeCalculationProblem()}>{btnText}</button>:
            <span>No Problem</span>
        }
      
    </div>
  )
}
