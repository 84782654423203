import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
  };

function CommonConfirmationModel({setOpen,open,text,agree}) {
    const handleClose =()=>{
        setOpen(false)
    }
  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Confirmation
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {text}
      </Typography>
      <Typography id="modal-modal-footer" sx={{ mt: 2 }}>
          <Button variant='contained' sx={{mr:2}} onClick={handleClose}>No</Button>
          <Button variant='contained' onClick={agree}>Yes</Button>
      </Typography>
    </Box>
  </Modal>
  )
}

export default CommonConfirmationModel