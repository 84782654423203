import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'
import { removeInstallationData } from '../Redux/installationActions'
import { useDispatch } from 'react-redux'

export default function SendToAddressLayout({data,notify}) {
    const dispatch = useDispatch()
    const sendToAddressLayout = ()=>{
        axios.post(`${serverUrl.u}/api/tele/changeSubscriptionStageInstallationToAddressLayout`,{
            "generatedHex":data.generatedHex
        }).then(function (res) {
            console.log(res);
            if(res.status===200){
                dispatch(removeInstallationData(res.data.alisteSubscription))
                notify(res.data.message)
            }else{
                notify(res.data.message)
            }
        }).catch(function (err) {
            console.log(err);
            notify("Failed")
        })
    }
  return (
    <>
      <button className='btn btn-primary' onClick={()=>sendToAddressLayout()}>Send to Address&Layout</button>
    </>
  )
}
