import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import moment from 'moment'

export default function GrevianceCommentDep({ dataObject, reloadW, setReload, notify }) {
    let [pop, setPop] = useState(false)
    let [reslove, setReslove] = useState("false")

    const handleSubmit = (event) => {
        event.preventDefault();
        const datas = new FormData(event.currentTarget);
        let detail = {
            comment: datas.get('comment')
        }
        Comments(detail)
    }
    const Comments = (detail) => {
        axios.post(`${serverUrl.u}/api/grv/addComments`, {
            generatedHex: dataObject.generatedHex,
            commentByGrivienceDepartment: `${detail.comment} - ${moment(new Date()).format("DD-MM-YYYY")}`
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
              notify(res.data.message)
              setPop(false)
              setReload(!reloadW)
            }else{
                notify("Failed")
            }
        }).catch(function (err) {
            console.log(err);
            notify("Failed")
        })
    }
    return (
        <>
            <td onClick={() => setPop(true)}>{dataObject.commentByGrivienceDepartment}</td>
            {
                pop === true ?
                    <div style={{ position: "fixed", top: "25%", left: "40%", width: "20%", padding: "15px",zIndex:"5", backgroundColor: "whitesmoke",borderRadius:"12px",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                        <div style={{ position: "relative", width: "100%" }}><span style={{ cursor: "pointer", position: "absolute", right: "5px" }} onClick={() => setPop(false)}>X</span></div>

                        <form onSubmit={handleSubmit}>
                            <label className="specific-label">Client Name</label>

                            <div className="input-group">
                                <input type="text"
                                    name="name"
                                    autoComplete='current-name'
                                    className="form-control"
                                    required
                                    disabled
                                    value={dataObject.name}
                                    aria-label="name"
                                    aria-describedby="basic-addon1" />
                            </div>
                            <label className="specific-label">Comment</label>

                            <div className="input-group">
                                <input type="text"
                                    name="comment"
                                    autoComplete='current-comment'
                                    className="form-control"
                                    required
                                    aria-label="comment"
                                    aria-describedby="basic-addon1" />
                            </div>


                            <div><button type='submit' className="btn2 btn-primary mt-2" >Done</button></div>
                        </form>
                    </div> : null
            }

        </>
    )
}
