import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { serverUrl } from "../ApiUrl";
import MultiSelectProduct from "./MultiSelectProduct";
import { UsbTwoTone } from "@mui/icons-material";
import Multiselect from "multiselect-react-dropdown";
import { subHistory } from "../Function/SubscriptionHistory";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactWhatsapp from "react-whatsapp";
import { WhatsApp } from "@mui/icons-material";
import FileSaver from "file-saver";
import moment from "moment";
import { ci } from "../city/Cities";
import Autocomplete from "@mui/material/Autocomplete";
import SelectCityInput from "../city/SelectCityInput";
import { InvoiceGenerate } from "../Function/InvoieDownloadFun";
import { notifyFailed } from "../Common/Notification";
import { user_roles } from "../Data";
import AlertSuccessModel from "../Common/AlertSuccessModel";
import { razorpayLink } from "../SpecificElectricianUpGradeDownGrade.js/UpgradeDownGrade/ApplincesDifferenceApi";
import { changeSubscriptionStage } from "../Common/applianceDifference";
import ProductCreation from "../ProductCreation";
import JSZip from "jszip";

export default function ProductSelling({ InstallationPage }) {
  let [paymentLink, setPaymentLink] = useState("");
  const [text, setText] = useState("");
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let [showProductCreate, setShowProductCreate] = useState(false);
  let [whatappNumber, setWhatappNumber] = useState();
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let employeeId = useSelector((state) => state.sar.data?.user?.employeeId);
  let token = useSelector((state) => state.sar.data?.token);
  let [paymentMode, setPaymentMode] = useState("");
  let [open, setOpen] = useState(false);
  const [makePerformaInvoice, setMakePerformaInvoice] = useState(false);
  const [paymentLinkBtn, setPaymentLinkBtn] = useState("Payment Link");
  let [formData, setFormData] = useState({
    IsBusiness: false,
    Customer: false,
    IsUP: false,
    IsMrpShow: "false",
    partialAmount: 0,
    installation: 0,
    payment_mode: "Select Mode",
    termConditionArray: "",
  });
  let [newProductDataItem, setNewProductDataItem] = useState([]);
  let [btnWithOutLink, setBtnWithOutLink] = useState(
    "Register Invoice Without Link"
  );
  let [btnPaymentLink, setBtnPaymentLink] = useState("Payment Link");
  const [btnPerforma, setBtnPerforma] = useState("Performa Invoice");
  let [btnQuto, setBtnQuto] = useState("Quotation");
  const [displayData, setDisplayData] = useState([]);
  const notify = (text) => toast(text);
  let [amounts, setAmount] = useState({
    subTotal: 0,
    tax: 0,
    total: 0,
    installationCharge: 0,
  });
  const Product = useRef({});

  const handleChange = (event) => {
    console.log(event);
    if (event.target.name === "email" || event.target.name === "phone") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value.trim(),
      });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };
  const setCity = (value) => {
    handleChange({ target: { name: "city", value: value } });
  };
  const productList = () => {
    axios
      .post(`${serverUrl.u}/v3/affiliateProducts/listAll`)
      .then(function (res) {
        console.log(res);
        setDisplayData(res.data.data.products);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  useEffect(() => {
    productList();
  }, []);

  useEffect(() => {
    if (!formData.hasOwnProperty("hex") && formData.hex === "") return;
    axios
      .post(`${serverUrl.u}/api/tele/getSubscription`, {
        generatedHex: formData.hex,
      })
      .then(function (res) {
        console.log(res.data.data, "check2");
        if (res.data.success === true) {
          let detail = res.data.data;
          setFormData({
            ...formData,
            success: true,
            clientName: detail.name,
            address: detail.address,
            phone: detail.phone.trim(),
            email: detail.email.trim(),
            city: detail.city,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [formData.hex]);
  const onChange = (value, id, key) => {
    let newData = newProductDataItem.map((e) => {
      if (e._id === id) {
        return { ...e, [key]: value };
      } else {
        return e;
      }
    });
    setNewProductDataItem(newData);
  };

  const selectedValue = (data) => {
    console.log(data, "sjja");
    let newProductData = [];
    if (newProductDataItem.length === 0) {
      newProductData = data.map((e) => {
        return { ...e, qty: 1 };
      });
    } else {
      let res = data.filter(
        (x) => !newProductDataItem.some((y) => y._id === x._id)
      );
      res = res.map((e) => {
        return { ...e, qty: 1 };
      });
      newProductData = [...newProductDataItem, ...res];
    }
    if (data.length !== 0) {
      setNewProductDataItem(newProductData);
    } else {
      setNewProductDataItem([]);
    }
  };
  const onRemove = (data) => {
    let res = newProductDataItem.filter((x) =>
      data.some((y) => y._id === x._id)
    );
    setNewProductDataItem(res);
  };
  useEffect(() => {
    let subTotal = newProductDataItem.reduce(
      (previous, current) => {
        return Number(previous + Number(current.selling_price * current.qty));
      },
      [0]
    );
    subTotal = Number(Math.round(subTotal));
    let installationCharge = newProductDataItem.reduce(
      (previous, current) => {
        return Number(
          previous + Number(current.installationCharge * current.qty)
        );
      },
      [0]
    );
    setAmount({
      subTotal: subTotal,
      tax: Math.round(subTotal * 0.18),
      total: Math.round(subTotal * 1.18),
      installationCharge: installationCharge,
    });
    setFormData({ ...formData, installation: installationCharge });
  }, [newProductDataItem]);
  const InvoiceGenerated = async (hex, invoiceNumber, name) => {
    let res = await InvoiceGenerate(
      { partialPayment: false, generatedHex: hex },
      { invoiceNumber: invoiceNumber }
    );

    if (res[0] === "then") {
      if (res[1].status === 200) {
    
          // Create a Blob from the response data (PDF)
          const blob = new Blob([res[1].data], { type: "application/pdf" });

          // Use file-saver to download the file
          FileSaver.saveAs(blob, `invoice.pdf`);
        
      } else {
        notify("Failed");
      }
    } else {
      notify("Failed");
    }
    console.log(res, "invoice");
  };
  const trasferStock = (detail) => {
    return new Promise((resolve) => {
      axios
        .post(`${serverUrl.stock}/transfer`, detail, {
          headers: {
            AccessToken: token,
          },
        })
        .then(function (res) {
          if (res.data.success) {
            resolve({ success: res.data.success, data: res.data.data });
            notify(res.data.message);
            subHistory(
              `Stock transfer by ${loginName}`,
              detail,
              "Transfer Stock",
              `Stock tranfer to ${detail.clientName}`,
              "",
              new Date()
            );
          } else {
            resolve({ success: false, data: {} });
            notifyFailed(res.data.message);
          }
        })
        .catch(function (err) {
          resolve({ success: false, data: {} });
          notifyFailed(err?.response?.data?.message);
        });
    });
  };
  const invoice = async (res, trnxBankCash, itemArray) => {
    let detail = {
      orderId: "",
      generatedHex: res.data.data.subscription.generatedHex,
      product: itemArray,
      name: formData.clientName,
      address: `${formData.address},${formData.city}`,
      BillTo: formData.clientName,
      amount: amounts.total,
      Is_Business: formData.IsBusiness,
      BusinessGst: formData.IsBusiness === true ? formData.GstNumber : null,
      In_Up: formData.IsUP,
      productName: itemArray[0].productName,
      hsn_code: itemArray[0].hsn_code,
      additionalInfo: [{ item_total: amounts.subTotal }],
      is_UpSell: true,
      createdBy: loginName,
      trnxBankCash,
    };

    await axios
      .post(`${serverUrl.u}/api/tele/createInvoice`, detail)
      .then(function (ress) {
        if (ress.data.success === true) {
          let e = ress.data.invoiceNumber;
          let invoiceNumbers =
            e.invoiceNumber > 7975
              ? e.invoiceNumber - 7975
              : e.invoiceNumber > 1200
              ? e.invoiceNumber - 1200
              : e.invoiceNumber;
          notify(`Register SuccessFully Invoice Number ${invoiceNumbers}`);
          setBtnWithOutLink("Register Invoice Without Link");
          InvoiceGenerated(
            res.data.data.subscription.generatedHex,
            ress.data.invoiceNumber,
            formData.clientName
          );
          subHistory(
            "Multiple Product Upsell Created Without Link",
            detail,
            "Upsell Created Without Link",
            "WithOut Link"
          );
          setOpen(true);
          let promise = [];
          for (let p of itemArray) {
            const {
              P_Id,
              sellingPricePerPic,
              mrpPricePerPic,
              qty,
              hsn_code,
              productName,
              _id,
            } = p;
            let data = {
              product_id: _id,
              quantity: Number(qty),
              from: Number(employeeId),
              to: formData.clientName,
              meta_data: {
                clientName: formData.clientName,
                Qty: qty,
                prodcutName: productName,
                Invoice: ress.data.invoiceNumber,
                payment: sellingPricePerPic * qty * 1.18,
              },
            };
            promise.push(trasferStock(data));
          }
        } else {
          notify(res.data.message);
          setBtnWithOutLink("Register Invoice Without Link");
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err.response.data.message);
        setBtnWithOutLink("Register Invoice Without Link");
      });
  };
  useEffect(() => {
    let subTotal = newProductDataItem.reduce(
      (previous, current) => {
        return Number(previous + Number(current.selling_price * current.qty));
      },
      [0]
    );
    let installationCharge = newProductDataItem.reduce(
      (previous, current) => {
        return Number(
          previous + Number(current.installationCharge * current.qty)
        );
      },
      [0]
    );
    subTotal = Number(Math.round(subTotal));
    setAmount({
      subTotal: subTotal,
      tax: Math.round((subTotal + Number(installationCharge)) * 0.18),
      total: Math.round((subTotal + Number(installationCharge)) * 1.18),
      installationCharge: installationCharge,
    });
  }, [newProductDataItem]);

  const errorHandle = () => {
    console.log(formData, "form");
    if (
      formData.clientName === null ||
      formData.clientName === "" ||
      formData.clientName === undefined
    ) {
      notify("Fill Client Name");
      return true;
    }
    if (
      formData.address === null ||
      formData.address === "" ||
      formData.address === undefined
    ) {
      notify("Fill Address");
      return true;
    }
    if (
      formData.email === null ||
      formData.email === "" ||
      formData.email === undefined
    ) {
      notify("Fill Email");
      return true;
    }
    if (
      formData.city === null ||
      formData.city === "" ||
      formData.city === undefined
    ) {
      notify("Fill City");
      return true;
    }
    if (
      formData.phone === null ||
      formData.phone === "" ||
      formData.phone === undefined ||
      formData.phone.trim().length > 13
    ) {
      notify("Enter Phone Number");
      return true;
    }
    if (newProductDataItem.length === 0) {
      notify("Select Product");
      return true;
    }
  };
  const sellProductWithoutLink = () => {
    let d = errorHandle();
    if (d === true) {
      return;
    }
    if (formData.payment_mode === "Select Mode") {
      notifyFailed("Select Payment Mode");
      return;
    }
    if (
      formData.transactionId === undefined ||
      formData.transactionId.length === 0
    ) {
      notifyFailed("Enter Transaction Id");
      return;
    }
    if (
      formData.transactionAmount === undefined ||
      formData.transactionAmount.length === 0
    ) {
      notifyFailed("Enter Transaction Amount");
      return;
    }
    setBtnWithOutLink("Processing...");
    let itemArray = [];
    for (let item of newProductDataItem) {
      let obj = {
        P_Id: item.product_code,
        sellingPricePerPic: item.selling_price,
        mrpPricePerPic: item.mrp,
        qty: item.qty,
        hsn_code: item.hsn_code,
        productName: item.name,
        _id: item._id,
      };
      itemArray.push(obj);
    }
    let trnxBankCash = [];
    let objRZP = {
      payment_mode: formData.payment_mode,
      gateway_transaction_id: formData.transactionId,
      amount: Number(formData.transactionAmount),
      uniqueId: 1,
    };
    trnxBankCash.push(objRZP);
    console.log(trnxBankCash, "ayment_mode");
    let detail = {
      upsellItems: itemArray,
      perApplianceCost: 0,
      orderOrigin: "CRM",
      totalAppliances: 0,
      recursiveAmount: amounts.subTotal,
      totalAmount: Number(amounts.total),
      secondarySalesPerson: "empty",
      partialPayment: true,
      partialAmount: 0,
      refferal: "empty",
      installation: Number(amounts.installationCharge),
      teleCallerId: loginName,
      comments: "",
      zip: "",
      address: formData.address,
      zone: "",
      city: formData.city,
      phone: formData.phone,
      email: formData.email,
      first_name: formData.clientName,
      last_name: "",
      orderType: "upSell",
    };
    axios
      .post(`${serverUrl.u}/api/zoho/registerLeadAndLinkUpsell`, detail)
      .then(async function (res) {
        console.log(res, "register upsell");
        if (res.data.success === true) {
          notify(res.data.message);
          await invoice(res, trnxBankCash, itemArray);
        } else {
          notify(res.data.message);
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err?.response?.data?.message);
        notifyFailed(err?.message);
        setBtnWithOutLink("Register Invoice Without Link");
      });
  };

  const downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    setBtnQuto("Quotation");
    // in case the Blob uses a lot of memory
    // setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };
  const makeQuto = async () => {
    setBtnQuto("Processing...");
    let itemArray = [];
    let count = 0;
    for (let item of newProductDataItem) {
      let obj = {
        Sr: count + 1,
        price: item.selling_price,
        mrp: item.mrp,
        Qty: item.qty,
        Amount: Number(item.selling_price) * Number(item.qty),
        desc: item.name,
      };
      count++;
      itemArray.push(obj);
    }
    let detail = {
      product: itemArray,
      name: formData.clientName,
      date: moment(new Date()).format("DD-MMM-YYYY"),
      Freight: amounts.installationCharge,
      total: amounts.total,
      subTotal: amounts.subTotal,
      tax: amounts.tax,
      isGst: formData.IsBusiness,
      mrp: formData.IsMrpShow === "false" ? false : true,
      gstDetails: {
        address: `${formData.address},${formData.city}`,
        gstName: formData.clientName,
        gstNumber: formData.IsBusiness === true ? formData.GstNumber : null,
      },
      termConditionArray:
        formData?.termConditionArray === ""
          ? []
          : formData?.termConditionArray.split(","),
      termCondition: formData?.termCondition === true ? true : false,
    };
    await axios
      .post(`${serverUrl.u}/api/tele/upsellQuoteData`, detail)
      .then(async function (res) {
        console.log(res.data.data.quoteNO);
        await axios
          .post(`${serverUrl.u}/api/tele/makeUpsellQuotation`, {
            ...detail,
            quoteNo: res.data.data.quoteNO,
          })
          .then(function (ress) {
            console.log(ress);
            //    downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`)
            // FileSaver.saveAs(`${serverUrl.u+ ress.data.urlObj[0].substring(6)}`, `${"fff.pdf"}`);
            notify("Please Wait 2 Seconds to generated pdf");
            setTimeout(
              FileSaver.saveAs(
                `${serverUrl.u + ress.data.urlObj[0].substring(6)}`,
                `${detail.name}.pdf`
              ),
              2000
            );
          })
          .catch(function (errr) {
            console.log(errr);
          });
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      });
    console.log(detail);
    setFormData({ ...formData, termConditionArray: "" });
    setBtnQuto("Quotation");
  };
  const performaQuto = async () => {
    setBtnPerforma("Processing...");
    let itemArray = [];
    let count = 0;
    for (let item of newProductDataItem) {
      let obj = {
        Sr: count + 1,
        price: item.selling_price,
        mrp: item.mrp,
        Qty: item.qty,
        Amount: Number(item.selling_price) * Number(item.qty),
        desc: item.name,
        hsn: item.hsn_code,
      };
      count++;
      itemArray.push(obj);
    }
    let detail = {
      product: itemArray,
      generatedHex:
        formData.hex === undefined || formData.hex === null
          ? "hex"
          : formData.hex,
      name: formData.clientName,
      date: moment(new Date()).format("DD-MMM-YYYY"),
      Freight: 0,
      TotalAmount: amounts.total,
      subTotal: amounts.subTotal,
      Tax: amounts.tax,
      state: formData.city,
      isGst: formData.IsBusiness,
      mrp: formData.IsMrpShow === "false" ? false : true,
      billTo: {
        address: `${formData.BillToAddress}`,
        name: formData.BillToName,
      },
      shipTo: {
        address: `${formData.ShipToAddress}`,
        name: formData.ShipToName,
      },
      gstName: formData.GSTName,
      gstNumber: formData.IsBusiness === true ? formData.GstNumber : null,
      termConditionArray:
        formData?.termConditionArray === ""
          ? []
          : formData?.termConditionArray.split(","),
      termCondition: formData?.termCondition === true ? true : false,
    };
    await axios
      .post(`${serverUrl.u}/api/tele/registerPerformaInvoice`, detail)
      .then(async function (res) {
        await axios
          .post(`${serverUrl.u}/api/tele/makePIPdf`, {
            ...detail,
            performaNumber: res.data.data.piNumber,
          })
          .then(function (ress) {
            console.log(ress);
            //    downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`)
            // FileSaver.saveAs(`${serverUrl.u+ ress.data.urlObj[0].substring(6)}`, `${"fff.pdf"}`);
            notify("Please Wait 2 Seconds to generated pdf");
            setTimeout(
              FileSaver.saveAs(
                `${serverUrl.u + ress.data.urlObj[0].substring(6)}`,
                `${detail.name}.pdf`
              ),
              2000
            );
          })
          .catch(function (errr) {
            console.log(errr.response.data.message);
          });
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      });
    console.log(detail);
    setBtnPerforma("Performa Invoice");
    setFormData({ ...formData, termConditionArray: "" });
  };

  const sellProductLinkNew = () => {
    let dd = errorHandle();
    if (dd === true) {
      return;
    }
    setBtnPaymentLink("Processing...");
    let itemArray = [];
    for (let item of newProductDataItem) {
      let obj = {
        P_Id: item.product_code,
        sellingPricePerPic: item.selling_price,
        mrpPricePerPic: item.mrp,
        qty: item.qty,
        hsn_code: item.hsn_code,
        productName: item.name,
      };

      itemArray.push(obj);
    }
    let detail = {
      upsellItems: itemArray,
      perApplianceCost: 0,
      orderOrigin: "CRM",
      totalAppliances: 0,
      recursiveAmount: amounts.subTotal,
      totalAmount: Number(amounts.total),
      secondarySalesPerson: "empty",
      partialPayment: true,
      partialAmount: Number(formData.partialAmount),
      refferal: "empty",
      installation: Number(amounts.installationCharge),
      teleCallerId: loginName,
      comments: "",
      zip: "",
      address: formData.address,
      zone: "",
      city: formData.city,
      phone: formData.phone,
      email: formData.email,
      first_name: formData.clientName,
      last_name: "",
      orderType: "upSell",
    };
    if (detail.partialAmount === detail.totalAmount) {
      detail.partialAmount = 0;
    }
    axios
      .post(`${serverUrl.u}/api/zoho/registerLeadAndLinkUpsell`, detail)
      .then(async function (res) {
        console.log(res);
        if (res.data.success === true) {
          notify(res.data.message);
          setBtnPaymentLink("Payment Link");
          if (Number(formData.partialAmount) === 0 && loginName === "BHAVYA") {
            let hex = res.data.data.subscription.generatedHex;
            await changeSubscriptionStage(hex, "ready to install");
            InstallationPage();
          }
          if (
            Number(detail.partialAmount) !== 0 &&
            Number(formData.partialAmount) < Number(detail.totalAmount)
          ) {
            setWhatappNumber(formData.phone);
            setText(`${res.data.data.partialPaymentDetails.short_url}`);
            navigator.clipboard.writeText(
              res.data.data.partialPaymentDetails.short_url
            );
            setPaymentLink(res.data.data.partialPaymentDetails.short_url);
            subHistory(
              "Multiple Product Upsell Created With Link",
              detail,
              "Upsell Created With Link",
              res.data.data.partialPaymentDetails.short_url
            );
            setOpen(true);
          } else {
            if (Number(formData.partialAmount) === Number(detail.totalAmount)) {
              razorpayLink(
                res.data.data.subscription,
                setText,
                setWhatappNumber,
                setPaymentLink,
                0,
                0,
                0,
                0,
                new Date(),
                setPaymentLinkBtn,
                "monthly",
                [],
                loginName
              );
            }
            subHistory(
              "Multiple Product Upsell Created With Link",
              detail,
              "Upsell Created With Link",
              "Zero payment Upsell Register"
            );
          }
        } else {
          notify(res.data.message);
          setBtnPaymentLink("Failed");
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err.response.data.message);
        setBtnPaymentLink("Failed");
      });
  };

  return (
    <div style={{ marginTop: "4vh" }}>
      <ToastContainer />
      <AlertSuccessModel open={open} setOpen={setOpen} />
      <Box
        component="div"
        className="make_quto"
        sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
      >
        <FormControl variant="standard" sx={{ minWidth: 150 }} required={true}>
          <InputLabel id="demo-simple-select-standard-label">
            Customer is Register?
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            defaultValue={false}
            onChange={handleChange}
            label="Customer"
            name="Customer"
            autoWidth
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
        {formData.hasOwnProperty("Customer") && formData.Customer === true ? (
          <TextField
            id="standard-helperText"
            label="Hex Code"
            variant="standard"
            name="hex"
            autoWidth
            sx={{ minWidth: "100px" }}
            onBlur={handleChange}
          />
        ) : null}
        {/* <FormControl variant="standard" sx={{ minWidth: 200 }} required>
          <InputLabel id="demo-simple-select-standard-label">
            Want to Make Performa Invoice ?
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            defaultValue={makePerformaInvoice}
            onChange={(e) =>
              setMakePerformaInvoice(e.target.value === "true" ? true : false)
            }
            label="Want to Make Performa Invoice ?"
            autoWidth
          >
            <MenuItem value={"false"}>No</MenuItem>
            <MenuItem value={"true"}>Yes</MenuItem>
          </Select>
        </FormControl> */}
      </Box>

      <Box
        component="div"
        className="make_quto"
        sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
      >
        <TextField
          id="standard-helperText"
          label="Client Name"
          variant="standard"
          name="clientName"
          value={formData.clientName ? formData.clientName : " "}
          autoWidth
          sx={{ minWidth: "100px" }}
          onChange={handleChange}
        />
        {makePerformaInvoice === false && (
          <TextField
            id="standard-helperText"
            label="Address"
            variant="standard"
            name="address"
            value={formData.address ? formData.address : " "}
            autoWidth
            sx={{ minWidth: "300px" }}
            onChange={handleChange}
          />
        )}
        <TextField
          id="standard-helperText"
          label="Phone"
          variant="standard"
          name="phone"
          value={formData.phone ? formData.phone : " "}
          autoWidth
          sx={{ minWidth: "100px" }}
          autoFocus
          onChange={handleChange}
        />
        <TextField
          id="standard-helperText"
          label="Email"
          name="email"
          autoWidth
          sx={{ minWidth: "100px" }}
          value={formData.email ? formData.email : " "}
          onChange={handleChange}
          variant="standard"
        />
        {makePerformaInvoice === true && (
          <>
            <TextField
              id="standard-helperText"
              label="Bill To Address"
              name="BillToAddress"
              autoWidth
              sx={{ minWidth: "100px" }}
              onChange={handleChange}
              variant="standard"
            />
            <TextField
              id="standard-helperText"
              label="Bill To Name"
              name="BillToName"
              autoWidth
              sx={{ minWidth: "100px" }}
              onChange={handleChange}
              variant="standard"
            />
            <TextField
              id="standard-helperText"
              label="Ship To Address"
              name="ShipToAddress"
              autoWidth
              sx={{ minWidth: "100px" }}
              onChange={handleChange}
              variant="standard"
            />
            <TextField
              id="standard-helperText"
              label="Ship To Name"
              name="ShipToName"
              autoWidth
              sx={{ minWidth: "100px" }}
              onChange={handleChange}
              variant="standard"
            />
            <TextField
              id="standard-helperText"
              label="GST Name"
              name="GSTName"
              autoWidth
              sx={{ minWidth: "100px" }}
              onChange={handleChange}
              variant="standard"
            />
          </>
        )}

        <FormControl variant="standard" sx={{ minWidth: 250 }}>
          <label className="mb-0">
            {formData.city ? formData.city : "City"}
          </label>
          <SelectCityInput
            value={formData.city}
            setValue={(name) => setCity(name)}
          />
        </FormControl>
      </Box>

      <Box
        component="div"
        className="make_quto"
        sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
      >
        {makePerformaInvoice === false && (
          <FormControl variant="standard" sx={{ minWidth: 150 }} required>
            <InputLabel id="demo-simple-select-standard-label">
              Is Uttar Pradesh Sell ?
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              defaultValue={false}
              onChange={handleChange}
              label="Is Uttar Pradesh Sell ?"
              name="IsUP"
              autoWidth
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        )}
        {makePerformaInvoice === false && (
          <FormControl variant="standard" sx={{ minWidth: 150 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Mrp Show?
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              defaultValue={"false"}
              onChange={handleChange}
              label="IsMrpShow"
              name="IsMrpShow"
              autoWidth
            >
              <MenuItem value={"false"}>No</MenuItem>
              <MenuItem value={"true"}>Yes</MenuItem>
            </Select>
          </FormControl>
        )}

        <FormControl variant="standard" sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-standard-label">
            IsBusiness
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            defaultValue={false}
            onChange={handleChange}
            label="IsBusiness"
            name="IsBusiness"
            autoWidth
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
        {formData.IsBusiness === true ? (
          <TextField
            id="standard-helperText"
            label="GST Number"
            variant="standard"
            name="GstNumber"
            value={formData.GstNumber ? formData.GstNumber : " "}
            autoWidth
            onChange={handleChange}
            sx={{ minWidth: "100px" }}
          />
        ) : null}
      </Box>
      <Box component="div" className="make_quto">
        {/* <MultiSelectProduct setNewProductDataItem={setNewProductDataItem}/> */}
        <Multiselect
          displayValue="name"
          onRemove={onRemove}
          onSelect={selectedValue}
          options={displayData}
          // ref={Select}
          style={{
            width: "100px !important",
            multiselectContainer: {
              marginTop: "5px",
              backgroundColor: "#ffffff",
            },
          }}
        />
      </Box>
      {newProductDataItem.length !== 0 ? (
        <Box
          component="div"
          className="make_quto "
          style={{ overflowX: "scroll" }}
        >
          <div className="row ml-2 mb-2">
            <table className="table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>M.R.P</th>
                  <th>Selling Price</th>
                  <th>Quantity</th>
                  <th>HSN Code</th>
                  <th>Installation Charges</th>
                </tr>
              </thead>
              <tbody>
                {newProductDataItem.map((e) => {
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          value={e.name}
                          onChange={(ele) =>
                            onChange(ele.target.value, e._id, "name")
                          }
                          style={{ width: "60px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={e.mrp}
                          onChange={(ele) =>
                            onChange(Number(ele.target.value), e._id, "mrp")
                          }
                          min={1}
                          style={{ width: "60px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={e.selling_price}
                          onChange={(ele) =>
                            onChange(
                              Number(ele.target.value),
                              e._id,
                              "selling_price"
                            )
                          }
                          min={1}
                          style={{ width: "60px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={e.qty}
                          onChange={(ele) =>
                            onChange(Number(ele.target.value), e._id, "qty")
                          }
                          min={1}
                          style={{ width: "60px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={e.hsn_code}
                          onChange={(ele) =>
                            onChange(ele.target.value, e._id, "hsn_code")
                          }
                          style={{ width: "60px" }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={e.installationCharge}
                          onChange={(ele) =>
                            onChange(
                              ele.target.value,
                              e._id,
                              "installationCharge"
                            )
                          }
                          style={{ width: "60px" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Box
            component="div"
            sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}
          >
            <TextField
              id="standard-helperText"
              label="Sub Total"
              variant="standard"
              name="subTotal"
              value={amounts.subTotal}
              autoWidth
              sx={{ minWidth: "100px" }}
            />
            <TextField
              id="standard-helperText"
              label="Installation Charges"
              variant="standard"
              name="installation"
              value={
                amounts.installationCharge ? amounts.installationCharge : 0
              }
              autoWidth
              sx={{ minWidth: "100px" }}
              autoFocus
              type="number"
              disabled
            />
            <TextField
              id="standard-helperText"
              label="Tax"
              variant="standard"
              name="tax"
              value={amounts.tax}
              onChange={(e) =>
                setAmount({ ...amounts, tax: Number(e.target.value) })
              }
              autoWidth
              sx={{ minWidth: "100px" }}
              type="number"
            />
            <TextField
              id="standard-helperText"
              label="Total"
              variant="standard"
              name="total"
              value={amounts.total}
              autoWidth
              sx={{ minWidth: "100px" }}
              onChange={(e) =>
                setAmount({ ...amounts, total: Number(e.target.value) })
              }
              autoFocus
              type="number"
            />
            <TextField
              id="standard-helperText"
              label="Partial Amount"
              variant="standard"
              name="partialAmount"
              value={formData.partialAmount ? formData.partialAmount : 0}
              autoWidth
              sx={{ minWidth: "100px" }}
              autoFocus
              onChange={handleChange}
              type="number"
            />
          </Box>
        </Box>
      ) : null}
      {/* <Box
        component="div"
        className="make_quto"
        sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
      >
        <FormControl variant="standard" sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Payment Mode
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            defaultValue={"Select Mode"}
            onChange={handleChange}
            label="Payment Mode"
            name="payment_mode"
            autoWidth
          >
            <MenuItem value={"Select Mode"}>Select Mode</MenuItem>
            <MenuItem value={"cash"}>Cash</MenuItem>
            <MenuItem value={"cheque"}>Cheque</MenuItem>
            <MenuItem value={"Bank Transfer"}>Bank Transfer</MenuItem>
          </Select>
        </FormControl>
        {formData.payment_mode !== "Select Mode" ? (
          <>
            <TextField
              id="standard-helperText"
              label="Transaction Id"
              variant="standard"
              name="transactionId"
              value={formData.transactionId ? formData.transactionId : ""}
              autoWidth
              onChange={handleChange}
              sx={{ minWidth: "100px" }}
            />

            <TextField
              id="standard-helperText"
              label="Transaction Amount"
              variant="standard"
              type="number"
              name="transactionAmount"
              value={
                formData.transactionAmount ? formData.transactionAmount : ""
              }
              autoWidth
              onChange={handleChange}
              sx={{ minWidth: "100px" }}
            />
          </>
        ) : null}
      </Box> */}
      <Box
        component="div"
        className="make_quto"
        sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}
      >
        <FormControl variant="standard" sx={{ minWidth: 150 }} required={true}>
          <InputLabel id="demo-simple-select-standard-label">
            Show Term&Condition?
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            defaultValue={false}
            onChange={handleChange}
            label="termCondition"
            name="termCondition"
            autoWidth
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
        {formData?.termCondition && (
          <FormControl
            variant="standard"
            sx={{ minWidth: 150 }}
            required={true}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Edit Term&Condition?
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              defaultValue={false}
              onChange={handleChange}
              label="termCondition"
              name="EdittermCondition"
              autoWidth
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        )}
        {formData?.EdittermCondition && (
          <FormControl
            variant="standard"
            sx={{ minWidth: 450 }}
            required={true}
          >
            {/* <InputLabel id="demo-simple-select-standard-label">Enter Term&Condition with "," seprator </InputLabel> */}

            <TextField
              id="outlined-textarea"
              label="Enter Term&Condition with ',' seprator"
              placeholder="Placeholder"
              name="termConditionArray"
              onChange={handleChange}
              multiline
            />
          </FormControl>
        )}
      </Box>

      <Box
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", marginBottom: "50px" }}
      >
        {makePerformaInvoice === false && (
          <>
            {!userRole.includes(user_roles.Installation) && (
              <></>
              // <Button
              //   variant="contained"
              //   onClick={() => sellProductWithoutLink()}
              //   style={{
              //     pointerEvents:
              //       btnWithOutLink === "Processing..." ? "none" : "",
              //   }}
              // >
              //   {btnWithOutLink}
              // </Button>
            )}

            {/* <Button
              variant="contained"
              onClick={() => sellProductLinkNew()}
              style={{
                pointerEvents: btnPaymentLink === "Processing..." ? "none" : "",
              }}
            >
              {btnPaymentLink}
            </Button> */}
            <Button
              variant="contained"
              onClick={() => makeQuto()}
              style={{
                pointerEvents: btnPerforma === "Processing..." ? "none" : "",
              }}
            >
              {btnQuto}
            </Button>
          </>
        )}
        {/* <Button
          variant="contained"
          onClick={() => performaQuto()}
          style={{
            pointerEvents: btnPerforma === "Processing..." ? "none" : "",
          }}
        >
          {btnPerforma}
        </Button>
        <Button variant="contained" onClick={() => setShowProductCreate(true)}>
          New Product Register
        </Button> */}

        {text !== "" ? (
          <>
            <Button
              variant="contained"
              onClick={() => navigator.clipboard.writeText(paymentLink)}
            >
              Copy
            </Button>

            <ReactWhatsapp
              number={whatappNumber}
              message={text}
              type="button"
              className="btn2 btn-primary mt-2"
              style={{
                backgroundColor: "#25D366",
                border: "none",
                borderRadius: "12px",
                padding: "5px",
              }}
            >
              <WhatsApp
                sx={{
                  color: "white",
                  verticalAlign: "middle",
                  fontSize: "xx-large",
                }}
              />
            </ReactWhatsapp>
          </>
        ) : null}
      </Box>
      {showProductCreate && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
            zIndex: 5,
            overflow: "scroll",
          }}
        >
          <div
            style={{
              width: "max-content",
              height: "max-content",
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "12px",
              marginTop: "14vh",
              position: "relative",
            }}
          >
            <div
              style={{
                height: "35px",
                width: "35px",
                position: "absolute",
                right: "5px",
                top: "5px",
              }}
              onClick={() => {
                setShowProductCreate(!showProductCreate);
                productList();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
            <ProductCreation />
          </div>
        </div>
      )}
    </div>
  );
}
