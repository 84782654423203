import { Button, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { notifyFailed } from "../Common/Notification";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  LoadScript,
  InfoWindow,
} from "@react-google-maps/api";


function MacId() {
  const libraries = ["places"];
  
  const center = {
    lat: 7.2905715, // default latitude
    lng: 80.6337262, // default longitude
  };

  let token = useSelector((state) => state.sar.data.token);

  let [decision, setdecision] = useState("id");
  let [device, setDevice] = useState("");
  let [phone, setPhone] = useState("");
  let [displayData, setDisplayData] = useState([]);
  const fetchMacId = (ids) => {
    axios
      .post(
        `${serverUrl.s}/deviceIdAssign/macs`,
        {
          deviceIds: ids,
        },
        {
          headers: {
            AccessToken: token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let dev = res.data.data.devices;
          setDisplayData(dev);
        } else {
          notifyFailed("Failed");
        }
      })
      .catch((err) => {
        notifyFailed(err?.response?.data?.message);
      });
  };
  const checkUserExists = (email) => {
    axios
      .get(
        `${
          serverUrl.u
        }/v2/user/exists/${email}?user=${email}&time=${new Date().getTime()}`
      )
      .then(
        (result) => {
          const { data, success, message } = result.data;
          if (success) {
            if (data.exists) {
              let houseId = data.user.selectedHouse;
              let ph = data.user.email;
              fetchouse(houseId, ph);
            } else {
              notifyFailed("User Not Exist");
              return;
            }
          } else {
            notifyFailed(message);
          }
        },
        (error) => {
          var message = error.message;
          if (error.response && error.response.data) {
            var message = error.response.data.message;
          }
          notifyFailed(message);
        }
      )
      .catch((error) => {
        notifyFailed("Something went wrong");
      });
  };
  const fetchouse = (houseId, email) => {
    axios
      .get(`${serverUrl.u}/api/fetch/house2/${houseId}/${email}?user=${email}`)
      .then((result) => {
        let rooms = result.data.rooms;
        let ids = [];
        for (let i of rooms) {
          for (let d of i.devices) {
            ids.push(d.deviceId);
          }
          ids = ids.concat(i.curtains.map((c) => c.curtainid));
          ids = ids.concat(i.novas.map((d) => d.deviceId));
          ids = ids.concat(i.energiSync.map((d) => d.deviceId));
        }
        fetchMacId(ids);
      })
      .catch((err) => {
        notifyFailed("Something went wrong");
      });
  };

  const handleSubmit = () => {
    if (decision === "id") {
      fetchMacId(device.split(","));
    } else {
      if (phone.length < 10 && phone.length > 13) {
        notifyFailed("Enter Vaild Number");
        return;
      }
      checkUserExists(phone);
    }
  };

  // const { isLoaded, loadError } = useLoadScript({
  //     googleMapsApiKey: 'AIzaSyBqO5gAoCNGU4GU_BoWui5KZyZ8ULZrEuA',
  //     libraries,
  //   });

  //   if (loadError) {
  //     return <div>Error loading maps</div>;
  //   }

  //   if (!isLoaded) {
  //     return <div>Loading maps</div>;
  //   }
  const mapRef = useRef(null);
 
  return (
    <div style={{ width: "100%", padding: "0 4%", marginTop: "5vh" }}>
      
      <ToastContainer />
      <Grid spacing={1} p={2}>
        <Grid item md={3} xs={12}>
          <label>Search MacId ?</label>
          <select
            className="form-control"
            value={decision}
            onChange={(val) => setdecision(val.target.value)}
          >
            <option value={"id"}>Device Ids</option>
            <option value={"number"}>House user Phone Number</option>
          </select>
        </Grid>
        {decision === "id" ? (
          <Grid md={3} xs={12}>
            <label>Enter device id Seprated with (,)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Device ids"
              onChange={(val) => setDevice(val.target.value)}
            />
          </Grid>
        ) : (
          <Grid md={3} xs={12}>
            <label>Enter House User Phone Number</label>
            <input
              type="number"
              className="form-control"
              placeholder="phone number"
              onChange={(val) => setPhone(val.target.value)}
            />
          </Grid>
        )}
        <Grid md={3} xs={6} sx={{ mt: 2 }}>
          <Button variant="contained" onClick={() => handleSubmit()}>
            Search
          </Button>
        </Grid>
        {displayData.length > 0 && (
          <Grid sx={{ mt: 2 }} md={3} xs={6}>
            <CSVLink
              className="btn btn-primary"
              filename={`MacIds.csv`}
              data={displayData}
            >
              Download CSV
            </CSVLink>
          </Grid>
        )}
      </Grid>
      <div className="table-container1" style={{ margin: 20 }}>
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Device id</th>
              <th>
                Mac Id<span style={{ color: "red" }}> *</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {displayData &&
              displayData.map((d) => (
                <tr key={d._id}>
                  <td>{d.deviceId}</td>
                  <td
                    onClick={() => {
                      navigator.clipboard.writeText(d.mac);
                    }}
                  >
                    {d.mac}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MacId;
