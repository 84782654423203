import { AppBar, Box, Tab, Tabs } from "@mui/material";
import "./styles/Customers.scss";
import { useState } from "react";

import { PeopleAlt, PersonAdd } from "@mui/icons-material";
import ListCustomers from "./components/ListCustomers";

import RegisterCustomer from "./components/RegisterCustomer";
import Customer from "./components/Customer";
import Test from "./components/Test";

export default function Customers() {
  const [tab, setTab] = useState(0);
  const [customerData, setCustomerData] = useState({})

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };
  return (
    <div className="customer">
      <div className="navigation-tabs mt-4">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <AppBar
            position="static"
            color="transparent"
            sx={{ bgcolor: "#f1f0f0", color: "white" }}
          >
            <Tabs
              value={tab}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                label="View Customers"
                icon={<PeopleAlt />}
                iconPosition="start"
              />
              <Tab
                label="Register New Customer"
                icon={<PersonAdd />}
                iconPosition="start"
              />
              <Tab label="Item Three" disabled />
            </Tabs>
          </AppBar>

          <Box sx={{ p: 3 }}>
            {tab === 0 && <ListCustomers setCustomerData={setCustomerData} setTab={setTab} />}
            {tab === 1 && <RegisterCustomer setTab={setTab} customerData={customerData} setCustomerData={setCustomerData}/>}
          </Box>
        </Box>
      </div>
    </div>
  );
}
