export const planCodeAndValidity = {
  a1: 30,
  a2: 30,
  ea1: 30,
  hivm: 30,
  la1: 30,
  va1: 30,
  gs1: 30,
  ga1: 30,
  sea1: 30,
  lsa1: 30,
  pb1: 30,
  lsa2: 30,
  alexah1: 30,
  aq1: 90,
  aq2: 90,
  ISP1Quarterly: 90,
  maq1: 90,
  ea2: 90,
  ea22: 90,
  ahy1: 180,
  ahy2: 180,
  lahy1: 180,
  sa1: 180,
  hl1: 365,
  ay1: 365,
  ay11: 365,
  'May-01': 365,
  say1: 365,
  msay1: 365,
  lsay1: 365,
  han: 365,
  ay111: 365,
  ay12: 365,
  pay1: 365,
  ay2: 730,
  lay2: 730,
  l1ay2: 730,
  ay3: 1095,
  ay32: 1095,
  'ay5.1': 1825,
  ay5: 1825,
  '2a': 60,
  '2ea1': 60,
  ess1: 30,
  pay2: 730,
};
