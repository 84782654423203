import { ci } from "../city/Cities";

const initailState = {};

export const saveReduce = (state = initailState, action) => {
  switch (action.type) {

    case "save":
      return {data:action.payload};


    default:
      return state;
  }
};


export const saveSwitchDetailReduce = (state = [], action) => {
  switch (action.type) {

    case "saveSwitch":
      return [...state,...action.payload];
    

    default:
      return [...state];
  }
};

export const switchUpdateReduce = (state = [], action) => {
  switch (action.type) {

    case "switchUpdate":
      return [action.payload];
     
    default:
      return [...state];
  }
};


// new

export const switchboardRecordReducer = (state=[],action)=>{
  
      switch (action.type) {
        case "defaultLayout":
           return action.payload;
        case "addNewRoom":
             const i=state?.length
              const newData = state.concat ({
                roomName: "Living Room",
                roomId: i,
                switchboards: [
                  {
                    switchboardNumber: 1,
                    appliances: {
                      fans: 0,
                      light: 0,
                      ac: 0,
                      geyser: 0,
                      tv: 0,
                      socket: 0,
                      other:0,
                      ir:0
                    },
                  },
                ],
              })
              return newData;
              case "switchBoardEmpty":
                return []
              case "addNewSwitchinRoom":
                
                let data = state.map((e)=>{
                if(e.roomId ==action.payload.roomId){
                  return action.payload
                }else{
                  return e;
                }
                })
                return data
        
        case "deleteRoom" :
          
          let data1 = state.filter((e)=>{
            if(e.roomId!==action.payload.roomId){
              return e
            }
          })
          return data1
        default:
          return state;
        
      }
}

// installation not complete Data 
export const installationNotCompleteReducer = (state=[],action)=>{
  
      switch (action.type) {
        case "intallationPending":
           return action.payload;
        default:
          return state;
        
      }
}