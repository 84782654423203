import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { serverUrl } from './ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { salesUserData } from './Function/AxiosControl'
import { Button } from '@mui/material';
import './IncentiveCalculator.css'
export default function NewTeleCallerIncentive() {
  let userRole = useSelector((state) => state.sar.data?.user?.roles)
  let teleCallers = useSelector((state) => state.sar.data.user.first_name);
  let [salesUserDatas, setSalesUserDatas] = useState([])
  let [primartIncentive, setPrimartIncentive] = useState(0)
  let [SecondIncentive, setSecondIncentive] = useState(0)
  let [reloadW, setReload] = useState(false);
  let [teleCallerName, setTeleCallerName] = useState(teleCallers)
  let [teleCallerSecondName, setTeleCallerSecondName] = useState(teleCallers)
  let token = useSelector((state) => state.sar.data.token)
  const err = (text) => toast.error(text);
  const notify = (text) => toast(text);
  console.log(token);
  let date = new Date();
  let [month, setDate] = useState()
  let [monthSecond, setDateSecond] = useState()
  useEffect(() => {
    const sale = async () => {
      let salesUser = await salesUserData(token);
      setSalesUserDatas(salesUser);
      if (salesUser === undefined) {
        err("Logout first before use")
      }
      console.log(salesUser);
    }
    sale();
  }, [])
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let userData2 = {
      startDate: data.get('date'),
      endDate: data.get('endDate'),
      teleCallerId: data.get("telecaller"),

    }

    primaryTeleCallerIncentive(userData2)

  };
  const handleSubmitSecondary = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let userData2 = {
      Date: data.get('date'),
      teleCallerId: data.get("telecaller"),

    }
    secondaryTeleCallerIncentive(userData2)

  };
  const handleChange = (event) => {
    setTeleCallerName(event.target.value);

  };
  const handleChangeSecond = (event) => {
    setTeleCallerSecondName(event.target.value);

  };
  const primaryTeleCallerIncentive = async (data) => {
    setDate(data.Date)
    await axios.post(`${serverUrl.u}/api/tele/getIncentiveOfPrimary`, {
      teleCallerId: userRole?.includes("Super Admin") ? (data.teleCallerId) : teleCallers,
      "firstDate": data.startDate,
      "lastDate": data.endDate,
    }).then(function (res) {
      console.log(res.data);
      setPrimartIncentive(res.data)
      // notify(res.data.message)
      // setReload(!reloadW)
    }).catch(function (error) {
      console.log(error);
      // notify(error.data.message)
    })
  }
  const secondaryTeleCallerIncentive = async (data) => {
    setDateSecond(data.Date)
    await axios.post(`${serverUrl.u}/api/zoho/calculateincentivesSecondary`, {
      secondarySalesPerson: userRole?.includes("Super Admin") ? (data.teleCallerId) : teleCallers,
      currentMonth: moment(data.Date).format('MMM YYYY'),
    }).then(function (res) {
      console.log(res.data);
      setSecondIncentive(res.data)
      // notify(res.data.message)
      // setReload(!reloadW)
    }).catch(function (error) {
      console.log(error);
      // notify(error.data.message)
    })
  }



  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <ToastContainer />
      <div>
        <div>Primary TeleCaller Incentive</div>
        {
          salesUserDatas !== 0 ?
            <Box component="form" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form"
            >
              <div className="make_quto">
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">Start Date <span className="required_star">*</span></label>
                    <div class="input-group " style={{ marginTop: "15px" }}>
                      <input className="custom-select height-select"
                        required
                        id="inputGroupSelect01"
                        type="date"
                        name="date"

                        autoComplete="current-date"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="create-label1">End Date <span className="required_star">*</span></label>
                    <div class="input-group " style={{ marginTop: "15px" }}>
                      <input className="custom-select height-select"
                        required
                        id="inputGroupSelect01"
                        type="date"
                        name="endDate"
                        autoComplete="current-endDate"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">Primary TeleCaller Name <span className="required_star">*</span></label>
                    <div class="input-group ">
                      <select class="custom-select height-select"
                        required
                        id="inputGroupSelect01"
                        onChange={handleChange}
                        value={teleCallerName}
                        name="telecaller"
                        disabled={userRole?.includes("Super Admin") ? false : true}
                        autoComplete="telecaller"
                      >

                        {salesUserDatas?.map((option) => (
                          <option value={option?.first_name}>{option?.first_name}</option>
                        ))}
                        <input type="text" />
                      </select>
                    </div>

                  </div>
                  <div className="col-6">

                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        marginTop: "22px",
                      }}
                    >
                      Calculate Incentive
                    </Button>
                  </div>

                </div>

              </div>
            </Box> : null
        }
        {console.log(month)}

        {
          primartIncentive !== 0 ?
            <Box component="form" className="make_quto" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form" >
              <label className="create-label1" style={{ fontSize: "large", fontWeight: "700" }}>Total Primary Incentive for {moment(month).format('MMM YYYY')}  :-  ₹ {primartIncentive.totalIncentiveOfTeleCaller.toFixed(2)} </label><br />
              <label className="create-label1" style={{ fontSize: "large", fontWeight: "700" }}>Total Confired Subscription for {moment(month).format('MMM YYYY')}  :-  {primartIncentive.count} </label><br />
              {/* <label className="create-label1" style={{fontSize: "large",fontWeight: "700"}}>Total Confired Appliances for {moment(date).format('MMM YYYY')}  :-  {primartIncentive.totalAppliance} </label> */}
              {
                primartIncentive.inc?.length !== 0 ?

                  <div style={{ marginTop: "25px" }}>
                    <table>
                      <tr>
                        <th style={{ padding: "0px 15px" }}>Client Name</th>
                        <th style={{ padding: "0px 15px" }}>Hex Code</th>
                        <th style={{ padding: "0px 15px" }}>Incentive</th>
                        <th style={{ padding: "0px 15px" }}>Total Appliance</th>
                        {/* <th style={{padding:"0px 15px"}}>Site Visited Appliances</th> */}
                      </tr>
                      {
                        primartIncentive.inc?.map((e) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "center" }}>{e.name}</td>
                              <td style={{ textAlign: "center" }}>{e.generatedHex}</td>
                              <td style={{ textAlign: "center" }}>{e.incentive.toFixed(2)}</td>
                              <td style={{ textAlign: "center" }}>{e.totalAppliances}</td>
                              {/* <td style={{textAlign:"center"}}><div contenteditable="false" onBlur={(ele)=>updateSiteVisitedAppliances(ele.target.innerText,e)}>{e.siteVisitedAppliances}</div></td> */}
                            </tr>
                          )
                        })
                      }
                    </table>
                  </div> : null
              }
            </Box> : null
        }
      </div>

      <div style={{display:"none"}}>
        <div>Secondary TeleCaller Incentive</div>
        {
          salesUserDatas !== 0 ?
            <Box component="form" validate={true.toString()} onSubmit={handleSubmitSecondary} id="create-course-form">
              <div className="make_quto">
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">Secondary TeleCaller Name <span className="required_star">*</span></label><br />

                    <div class="input-group ">
                      <select class="custom-select height-select"
                        required
                        id="inputGroupSelect01"
                        onChange={handleChangeSecond}
                        value={teleCallerSecondName}
                        name="telecaller"
                        disabled={userRole?.includes("Super Admin") ? false : true}
                        autoComplete="telecaller"
                      >

                        {salesUserDatas?.map((option) => (
                          <option value={option?.first_name}>{option?.first_name}</option>
                        ))}
                        <input type="text" />
                      </select>
                    </div>
                    <div class="input-group " style={{ marginTop: "15px" }}>
                      <input className="custom-select height-select"
                        required
                        id="inputGroupSelect01"
                        type="date"
                        name="date"

                        autoComplete="current-date"
                      />
                    </div>
                  </div>
                  <div className="col-6">

                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        marginTop: "22px",
                      }}
                    >
                      Calculate Incentive
                    </Button>
                  </div>

                </div>
              </div>
            </Box> : null
        }
        {
          SecondIncentive !== 0 ?
            <Box component="form" className="make_quto" validate={true.toString()} onSubmit={handleSubmitSecondary} id="create-course-form"
              sx={{ ml: 1 }}>
              <label className="create-label1" style={{ fontSize: "large", fontWeight: "700" }}>Total Secondary Incentive for {moment(monthSecond).format('MMM YYYY')}  :-  ₹ {SecondIncentive.totalIncentiveOfSecondTeleCaller.toFixed(2)} </label><br />
              <label className="create-label1" style={{ fontSize: "large", fontWeight: "700" }}>Total Confired Subscription for {moment(monthSecond).format('MMM YYYY')}  :-  {SecondIncentive.count} </label><br />
              {/* <label className="create-label1" style={{fontSize: "large",fontWeight: "700"}}>Total Confired Appliances for {moment(date).format('MMM YYYY')}  :-  {SecondIncentive.totalAppliance} </label> */}

              {
                SecondIncentive.inc.length !== 0 ?

                  <div style={{ marginTop: "25px" }}>
                    <table>
                      <tr>
                        <th style={{ padding: "0px 15px" }}>Client Name</th>
                        <th style={{ padding: "0px 15px" }}>Hex Code</th>
                        <th style={{ padding: "0px 15px" }}>Incentive</th>
                        <th style={{ padding: "0px 15px" }}>Total Appliances</th>
                      </tr>
                      {
                        SecondIncentive.inc.map((e) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "center" }}>{e.name}</td>
                              <td style={{ textAlign: "center" }}>{e.generatedHex}</td>
                              <td style={{ textAlign: "center" }}>{e.incentive.toFixed(2)}</td>
                              <td style={{ textAlign: "center" }}>{e.totalAppliances}</td>
                            </tr>
                          )
                        })
                      }
                    </table>
                  </div> : null
              }

            </Box> : null
        }
      </div>
    </div>
  )
}
