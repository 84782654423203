import axios from 'axios'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { subHistory } from '../Function/SubscriptionHistory';

export default function SalesSetUninstallation({e}) {
    let [pop,setPop] = useState(false)
    let [btn,setBtn] = useState("Uninstall")
    let notifySuccess = (text)=> toast.success(text)
    let notifyFailed = (text)=> toast.error(text)
 
    const handleUninstall = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
       
        setBtn("Loading...")
        let detail = {
            comment:data.get('comment'),
            installationDate:data.get('installationDate')
        }    
        uninstallationSetBySales(detail)
      }

    const uninstallationSetBySales = (detail)=>{
      const paylod = {
        generatedHex:e.generatedHex,
        InstallationDate:detail.installationDate,
        comments:`${detail.comment} - ${moment(new Date()).format("DD-MM-YYYY")}`,
    }
        axios.post(`${serverUrl.u}/api/subscription/setUninstallation`,paylod).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                notifySuccess(res.data.message)
                subHistory("Uninstallation Request Raise",{...e,paylod},"UnInstallation Request Raise",detail.installationDate)
            }else{
                notifyFailed(res.data.message)
            }
            setBtn("Uninstall")
        }).catch(function (err) {
            console.log(err);
            setBtn("Uninstall")
            notifyFailed("Failed")
        })
    }
  return (
    <>
       <button className='btn btn-primary' onClick={()=>setPop(true)}>Uninstallation Request</button>

       {
        pop===true?
        <div style={{ position: "fixed", height: "100vh", width: "100vw", display: "flex", overflow: "scroll", justifyContent: "center", alignItems: "center", top: 0, left: 0,zIndex:4}}>
            <div style={{ width:"20vw", backgroundColor: "lightGray", padding: "10px 15px", borderRadius: "12px" ,}}>
              <div style={{width:"100%",position:"relative",height:"35px"}}>
                <span 
                  style={{
                    position:"absolute",
                    right:"10px",
                    cursor:"pointer",
                    width:"fit-content"
                  }}
                  onClick={()=>{
                          setPop(false)
                      }}>X</span>
              </div>
              
             
               <form onSubmit={handleUninstall}>
            
                <div className="form-group ">
                  <input 
                    type="text" 
                    required
                    className="form-control" 
                    id="exampleInputEmail1" 
                    placeholder="Comment" 
                    autoComplete='current-comment' 
                    name='comment'
                  />
                </div>
                <div className="input-group">
                    <input
                      required
                      type='date'
                      className="form-control"
                      name="installationDate"
                      autoComplete='current-installationDate'
                    />
                </div> 
                <div className='mt-3 '  >
                  <button  className='btn btn-primary' type='submit' style={{pointerEvents:btn==="Uninstall"?"":"none",width:"30% !important"}}>{btn}</button>
                </div>
              
              </form>
             
              
                  
                  
                  <ToastContainer/>
             

            </div>
        </div>:null
       }
    </>
  )
}
