import React from "react";
import "./SwitchBoard.css";
import {addSwitchBoardInRoom} from '../Redux/action'
import { useDispatch } from "react-redux";
import { style } from "@mui/system";
export default function SwitchBoard(props) {
  let dispatch=useDispatch();
  const { Switchboard, data,appliancesLeft} = props;
  const { fans,
    light,
    ac,
    geyser,
    tv,
    socket,
    other,ir} = data.switchboards[Switchboard].appliances;
    console.log(fans,light,ac,geyser,tv,socket,other);
  return (
    <div style={{ marginBottom: "4vh" }}>
      <div className="room-appliance-count">
        <div className="IMG">
          <img
            style={{
              height: "17.54px",
              width: "11.24px",
              display: "flex",
              alignItems: "center",
              justifyItems: "centre",
            }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group.png"}
            alt="light"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">Lights</div>
        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( light > 0) {
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.light -= 1;
                dispatch(addSwitchBoardInRoom(newData))
              }
            }}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{light}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.light += 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="room-appliance-count">
        <div><img src={process.env.PUBLIC_URL+'/assets/icon/refresh icon.png'} 
                   style={{height: "17px",
                           width: "17px",
                           backgroundColor: "black",
                           float: "right",
                           borderRadius: "12px",
                           cursor:"pointer"}}
                    onClick={()=>{
                      const newData = {...data};
                newData.switchboards[Switchboard].appliances.fans = 0;
                dispatch(addSwitchBoardInRoom(newData))
                     } }/>
        </div>
        <div className="IMG">
          <img
            style={{ height: "17.41px", width: "19.97px" }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group-1.png"}
            alt="fan"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">Fan</div>

        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( fans > 0) {
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.fans -= 1;
                dispatch(addSwitchBoardInRoom(newData))
              }
            }}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{fans}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.fans += 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}
          > +
          </div>
        </div>
      </div>
      <div className="room-appliance-count">
        <div className="IMG">
          <img
            style={{ height: "12.98px", width: "18.72px" }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group w.png"}
            alt="ac"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">AC</div>
        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( ac > 0) {

                const newData = {...data};
                newData.switchboards[Switchboard].appliances.ac -= 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{ac}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.ac += 1;
                dispatch(addSwitchBoardInRoom(newData))
              
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="room-appliance-count">
        <div className="IMG">
          <img
            style={{ height: "12.98px", width: "18.72px" }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group w.png"}
            alt="ac"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">Geyser</div>
        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( geyser > 0) {

                const newData = {...data};
                newData.switchboards[Switchboard].appliances.geyser -= 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{geyser}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.geyser += 1;
                dispatch(addSwitchBoardInRoom(newData))
              
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="room-appliance-count">
        <div className="IMG">
          <img
            style={{ height: "12.98px", width: "18.72px" }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group w.png"}
            alt="ac"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">Tv</div>
        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( tv > 0) {

                const newData = {...data};
                newData.switchboards[Switchboard].appliances.tv -= 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{tv}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.tv += 1;
                dispatch(addSwitchBoardInRoom(newData))
              
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="room-appliance-count">
        <div className="IMG">
          <img
            style={{ height: "12.98px", width: "18.72px" }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group w.png"}
            alt="ac"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">Socket</div>
        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( socket > 0) {

                const newData = {...data};
                newData.switchboards[Switchboard].appliances.socket -= 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{socket}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.socket += 1;
                dispatch(addSwitchBoardInRoom(newData))
              
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="room-appliance-count">
        <div className="IMG">
          <img
            style={{ height: "12.98px", width: "18.72px" }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group w.png"}
            alt="ac"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">Other</div>
        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( other > 0) {

                const newData = {...data};
                newData.switchboards[Switchboard].appliances.other -= 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{other}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.other += 1;
                dispatch(addSwitchBoardInRoom(newData))
              
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className="room-appliance-count">
        <div className="IMG">
          <img
            style={{ height: "12.98px", width: "18.72px" }}
            src={process.env.PUBLIC_URL + "/assets/icon/Group w.png"}
            alt="ac"
          ></img>
        </div>
        <div className="SwitchBoard-appliance-name-text">IR</div>
        <div className="room-appliance-counter">
          <div
            className="SwitchBoard-count-numbrt-minus"
            onClick={() => {
              if( ir > 0) {

                const newData = {...data};
                newData.switchboards[Switchboard].appliances.ir -= 1;
                dispatch(addSwitchBoardInRoom(newData))
            }}}
          >
            -
          </div>
          <div className="SwitchBoard-count-numbrt-text">{ir}</div>
          <div
            className="SwitchBoard-count-numbrt-plus"
            onClick={() => {
              if(appliancesLeft===0) return;
                const newData = {...data};
                newData.switchboards[Switchboard].appliances.ir += 1;
                dispatch(addSwitchBoardInRoom(newData))
              
            }}
          >
            +
          </div>
        </div>
      </div>
    </div>
  );
}
