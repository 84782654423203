import React from 'react'
import { dashboardLink } from './DashBordLink'
import { useSelector } from 'react-redux'

export default function Dasboard() {
  let teleCallers = useSelector((state)=>state.sar.data.user.first_name)
  return (
    <div style={{padding:0,margin:0}}>
      {
        dashboardLink.map((ele)=>{
           if(teleCallers===ele.name){
            return(
              <iframe style={{
                background: "#F1F5F4",
                border: "none",
                borderRadius: "2px",
                boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                width: "90vw",
                height: "89vh"}}
                 src={ele.link}></iframe>
            )
           }
        })
      }
        
    </div>
  )
}
