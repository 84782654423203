import { useEffect, useRef, useState } from "react";
import UpsellApis from "./UpsellApis";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, CircularProgress } from "@mui/material";
import { Cached, Delete, Edit } from "@mui/icons-material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UpsellInputRow from "./InputRow";
import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";
import { apiRequest } from "../../utils";

export default function UpsellItem() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("CREATE");
  const [formData, setFormData] = useState({
    type: "goods",
    name: "",
    code: "",
    hsnCode: "",
    rate: 0,
    mrp: 0,
    cgst: 9,
    sgst: 9,
    igst: 18,
    media: [],
    stock: 0,
    description: "",
    tags: [],
    meta_data: {},
  });

  const fetchData = async () => {
    setLoading(true);

    const response = await UpsellApis.getAllUpsellItems();

    if (!response.success) {
      // setItems(response.data.data);
      setLoading(false);
      // setError()
    }
    setLoading(false);
    setData(response?.data?.items);
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function handleUpdate(e) {
    e.preventDefault();
    // console.log("FORM_DATA -", formData);

    let finalData = formData;
    finalData.id = formData._id;
    finalData.mrp = Number(formData.mrp);

    const response = await UpsellApis.updateUpsellItems(formData);

    if (!response.success) {
      notifyFailed("Something Failed!");
      return;
    }

    notify("Item Updated");
    setOpenModal(false);
    setModalType("CREATE");
    setFormData({
      type: "goods",
      name: "",
      code: "",
      hsnCode: "",
      rate: 0,
      mrp: 0,
      cgst: 9,
      sgst: 9,
      igst: 18,
      media: [],
      stock: 0,
      description: "",
      tags: [],
      meta_data: {},
    });
    // notify("Successfully Added");'
    fetchData();
  }

  function handleEdit(payload) {
    setModalType("UPDATE");
    setOpenModal(true);
    setFormData(payload.data);
  }

  function handleDelete() {
    console.log("Handle Delete Clicked");
  }

  function handleClose() {
    setOpenModal(false);
    setFormData({
      type: "goods",
      name: "",
      code: "",
      hsnCode: "",
      rate: 0,
      mrp: 0,
      cgst: 9,
      sgst: 9,
      igst: 18,
      media: [],
      stock: 0,
      description: "",
      tags: [],
      meta_data: {},
    });
  }

  async function handleCreate(e) {
    e.preventDefault();

    try {
      const response = await apiRequest({
        url: `${serverUrl.u}/v3/items/create`,
        method: "POST",
        data: formData,
      });

      if (!response?.success) {
        notifyFailed(response.message || "Something went wrong!");
        return;
      }

      notify(response?.message || "Successfully created item");
    } catch (error) {
      console.log(error, " Upsell Item error");
    } finally {
      await fetchData();
      setOpenModal(false);
      setFormData({
        type: "goods",
        name: "",
        code: "",
        hsnCode: "",
        rate: 0,
        mrp: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        media: [],
        stock: 0,
        description: "",
        tags: [],
        meta_data: {},
      });
    }
  }

  const generateColumnNames = (key) => {
    switch (key) {
      case "_id":
        return "ID";
      default:
        return key.charAt(0).toUpperCase() + key.slice(1);
    }
  };

  // REQUESTS
  // Update Stocks
  const updateItemStock = async (data) => {
    const response = await apiRequest({
      url: `${serverUrl.u}/v3/items/stockStatus?itemCode=${data.code}`,
      method: "POST",
    });

    if (!response.success) {
      notifyFailed(response.message);
      return;
    }

    notify(
      `Details of stock ${response.data?.data?.itemCode} - ${response.data?.data?.stock}`
    );

    fetchData();
  };

  const updateStock = async () => {
      setLoading(true);
    const response = await apiRequest({
      url: `${serverUrl.sub}/v3/items/updateStock`,
      method: "GET",
    });


    if (!response.success) {
      notifyFailed(response.message);
      setLoading(false);
      return;
    }

    notify(response.message);

    setLoading(false);
    setData(response.data?.data?.items)
  }

  const unwantedColumns = ["createdAt", "updatedAt", "__v", "_id"];
  const columns =
    data.length > 0
      ? Object.keys(data[0])
          .filter((h) => (unwantedColumns.includes(h) ? false : true))
          .map((h) => {
            return {
              field: h,
              headerName: generateColumnNames(h),
              width: 150,
            };
          })
      : [];

  // Add action buttons
  columns.push({
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params) => (
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit({ type: "update", data: params.row })}
        >
          <Edit />
        </Button>
        {/* <Button
          variant="outlined"
          style={{ color: "red", border: "1px solid red" }}
          onClick={() => handleDelete(params.row)}
        >
          <Delete />
        </Button> */}

        <Button
          variant="outlined"
          style={{ color: "red", border: "1px solid red" }}
          onClick={() => updateItemStock(params.row)}
        >
          <Cached />
        </Button>
      </div>
    ),
  });

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openModal]);

  function onChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="row">
        <div className="col-2">
          <label className="create-label1"></label>
          <div className="input-group">

            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
              style={{ marginBottom: "10px", width: "100%" }}
            >
              Add Item
            </Button>

          </div>
        </div>

        <div className="col-2">
          <label className="create-label1"></label>
          <div className="input-group">
            {loading ? <Button variant="contained" style={{  width: "200px", marginBottom: "10px"}}><CircularProgress style={{color: "white"}} size={24}/></Button>:
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => updateStock()}
              style={{ marginBottom: "10px", width: "200px" }}
            >
              Update Stocks
            </Button>}
          </div>
        </div>
      </div>

      <div className="table-container1">
        <DataGrid
          columns={columns}
          rows={data.map((d, i) => ({
            ...d,
            id: i,
          }))}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              csvOptions: { fileName: `Upsell_items` },
            },
          }}
          pageSizeOptions={[10, 50, 100, 150, 200]}
        />
      </div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <form
          style={{ padding: "20px" }}
          onSubmit={modalType === "CREATE" ? handleCreate : handleUpdate}
        >
          <DialogTitle id="scroll-dialog-title">
            {modalType === "CREATE" ? "Create Item" : "Update Item"}
          </DialogTitle>
          <DialogContent dividers={"paper" === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                  className="col-6"
                >
                  <label>Type</label>
                  <select
                    name="type"
                    style={{
                      padding: "8px",
                      color: "#495057",
                      backgroundColor: "white",
                      border: "1px solid #ced4da",
                      borderRadius: "0.25rem",
                    }}
                    value={formData["type"]}
                    onChange={onChange}
                  >
                    <option value="goods">goods</option>
                    <option value="service">service</option>
                  </select>
                </div>
                <UpsellInputRow
                  label={"name"}
                  value={formData["name"]}
                  onChange={onChange}
                />
                <UpsellInputRow
                  label={"code"}
                  value={formData["code"]}
                  onChange={onChange}
                />
                <UpsellInputRow
                  label={"hsnCode"}
                  value={formData["hsnCode"]}
                  onChange={onChange}
                />
                <UpsellInputRow
                  label={"rate"}
                  value={formData["rate"]}
                  onChange={onChange}
                  type="number"
                />
                <UpsellInputRow
                  label={"mrp"}
                  value={formData["mrp"]}
                  onChange={onChange}
                  type="number"
                />
                <UpsellInputRow
                  label={"cgst"}
                  value={formData["cgst"]}
                  onChange={onChange}
                  type="number"
                />
                <UpsellInputRow
                  label={"sgst"}
                  value={formData["sgst"]}
                  onChange={onChange}
                  type="number"
                />
                <UpsellInputRow
                  label={"igst"}
                  value={formData["igst"]}
                  onChange={onChange}
                />
                <UpsellInputRow
                  label={"stock"}
                  value={formData["stock"]}
                  onChange={onChange}
                />
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Description</label>
                <textarea
                  className="form-control"
                  onChange={onChange}
                  name="description"
                  required
                  value={formData["description"]}
                ></textarea>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit">
              {modalType === "UPDATE" ? "Update" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
