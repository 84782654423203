import { Box, Button, Modal } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useState } from 'react';

export function LedgerTransactionRecord({ params, transactionRecord = [] }) {
  const [open, setOpen] = useState(false);
  console.log('[params]', params);
  const columns = [
    {
      field: 'mode',
      headerName: 'Mode',
      width: 150,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
    },
    {
      field: 'trnxId',
      headerName: 'TranxId',
      width: 300,
    },
  ];
  return (
    <div>
      <Button variant='contained' onClick={() => setOpen(true)}>
        Trnx Record
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <DataGrid
            columns={columns}
            rows={transactionRecord.map((d, i) => ({
              id: i,
              ...d,
            }))}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { fileName: 'Transaction_record' },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
          />
        </Box>
      </Modal>
    </div>
  );
}
