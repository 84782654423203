import  React, {useEffect,useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios'
import { serverUrl } from './ApiUrl';
import { useSelector } from 'react-redux';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';

 

const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
  { field: 'name', headerName: 'name', width: 140 },
  {field:'phone',headerName:"Phone",width:140},
  {field:'email',headerName:"Email",width:220},
  {field:'city',headerName:"City",width:140},
  {
    field: 'address',
    headerName: 'Address',
    type: 'number',
    width: 220,
  },
  {field:'createdAt',headerName:"Created Date",width:140},
  { field: 'teleCallerId', headerName: 'TeleCaller Name', width: 130 },
  {field:'secondarySalesPerson',headerName:"Second Sales Person",width:180},
  {field:'totalAppliances',headerName:"Total Appliances",width:140},
  {field:'invoiceId',headerName:"Invoice Id",width:100},
  {field:'InstalledDate',headerName:"Installed Date",width:140},
  {field:'houseId',headerName:"House Id",width:140},
  {field:'planFrequency',headerName:"Plan Frequency",width:140},
  {field:'InstallationDate',headerName:"Installation Date",width:140},
  {field:'comments',headerName:"Comment",width:140},
 
];


export default function CscOrderPaidStatus() {
    let [reloadW,setReload] = useState(false);
    let [details,setDetails] = useState([])
    const navigate = useNavigate();
    let [convert,setConvert] = useState()
    let [path,setPath] = useState();
    const location = useLocation()
    useEffect(()=>{
      const { from,path} = location.state
      setPath(path)
    },[])
    let userRoles = useSelector((state) => state.sar.data.user)
    useEffect(() => {

        let data = async () => {
          await axios.post(`${serverUrl.u}/api/tele/getAllSubscriptionsParticular`, {
            teleCallerId: userRoles?.first_name
          })
            .then(function (response) {
              let detail = response.data.data;
              console.log("detail   ",detail);
              let array = []
              detail.map((e,i)=>{
               array.push({
                    id:i,
                    "name": e.name,
                    "Hex Code":e.generatedHex,
                    "totalAppliances": e.totalAppliances,
                    "Extra Payment":e.extra1,
                    "Selected Plan":e.planFrequency,
                    "city": e.city,
                    "phone": JSON.stringify(e.phone),
                    "createdAt": moment(e.createdAt).format("MMM Do LT"),
                    "Mandate Date": moment(e.mandateDate).format("MMM Do LT"),
                    "comments": e.comments,
                    "address": e.address,
                    "email": e.email,
                    "online Payment":e.onlinePayment  === false ? "No" : "Yes",
                    "Payment Carrier":e.paymentCarrier,
                    "Payment Status":e.paymentStatus,
                    "partial Payment Status":e.partialPayment === false ? "No" : "Yes",
                   "installed":e.installed === false ? "NO" : "YES",
                    "teleCallerId": e.teleCallerId,
                    "secondarySalesPerson": e.secondarySalesPerson,
                  "Dispatch":e.dispatchStatus,
                  "Dispatch Status":e.dispatched === false ? "NO" : "YES",
                  "Picked for Installation":e.pickedForInstallation === false ? "NO" : "YES",
                  "Subscription Stage":e.subscriptionStage
              }) 
              })
              setDetails(array)
           }).catch(function (error) {
              console.log(error);
            })
        }
        let dataAll = async () => {
          await axios.get(`${serverUrl.u}/api/tele/getAllSubscriptions`).then(function (response) {
            let detail = response.data.data;
            console.log("detail   ",detail);
            setConvert(detail)
            let array = []
            
            detail.map((e,i)=>{
            array.push({
                id:i,
                "name": e.name,
                "Hex Code":e.generatedHex,
                "totalAppliances": e.totalAppliances,
                "Extra Payment":e.extra1,
                "Selected Plan":e.planFrequency,
                "city": e.city,
                "phone": JSON.stringify(e.phone),
                "createdAt": moment(e.createdAt).format("MMM Do LT"),
                "Mandate Date": moment(e.mandateDate).format("MMM Do LT"),
                "comments": e.comments,
                "address": e.address,
                "email": e.email,
                "online Payment":e.onlinePayment  === false ? "No" : "Yes",
                "Payment Carrier":e.paymentCarrier,
                "Payment Status":e.paymentStatus,
                "partial Payment Status":e.partialPayment === false ? "No" : "Yes",
               "installed":e.installed === false ? "NO" : "YES",
                "teleCallerId": e.teleCallerId,
                "secondarySalesPerson": e.secondarySalesPerson,
              "Dispatch":e.dispatchStatus,
              "Dispatch Status":e.dispatched === false ? "NO" : "YES",
              "Picked for Installation":e.pickedForInstallation === false ? "NO" : "YES",
              "Subscription Stage":e.subscriptionStage 
          }) 
            })
            setDetails(array)
          
          })
            .catch(function (error) {
              console.log(error);
            })
        }
        userRoles?.roles.includes("Super Admin") ? dataAll() : data()
      }, [reloadW])
    
  return (
    <>
    <CSVLink className='btn btn-primary' style={{ marginLeft: "2%",marginTop:"2%",marginBottom:"5%" }} data={details}>Download CSV</CSVLink>
        <button className='btn btn-primary' style={{ marginLeft: "5%",marginBottom:"5%",marginTop:"2%" }} onClick={() => navigate(`/${path}`)}>Back</button>
   
    <div style={{ height: 400, width: '100%' }}>
        {
            details.length!==0?
      <DataGrid
        rows={details}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[20]}
        
      />:null
        }
        
    </div>
    </>
  );
}