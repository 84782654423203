import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ConvertedLead.css";
import "./DispatchData.css";
import "./PaymentLinkGen.css";
import moment from "moment/moment";
import Qutoes from "./Qutoes";
import { serverUrl } from "./ApiUrl";
import "./PartialPayment.css";
import { useSelector } from "react-redux";
import CommentUdate from "./Function/CommentUdate";
import AddressUpdate from "./Function/AddressUpdate";
import { CSVLink } from "react-csv";
import { planCost } from "./Data";
import { calculatePartialAmount } from "./Function/AxiosControl";



let CsvData = [];
let CsvMake = async (details) => {
  if (details.length === 0) return;

  for (const val of details) {
    let amount = await calculatePartialAmount(val);

    let obj = {
      "Client Name": val.name,
      "City": val.city,
      "Address": val.address,
      "Email": val.email,
      "Phone": val.phone,
      "Created At": val.createdAt,
      "Total Appliances": val.totalAppliances,
      "Plan Frequency": val.planFrequency,
      "Partial Status": val.partialPayment === true ? "Yes" : "NO",
      "Partial Amount": amount,
    }
    CsvData.push(obj);
  }
  return CsvData
}
export default function PartialPayment() {
  let teleCallers = useSelector((state) => state.sar.data.user.first_name)
  let userRoles = useSelector((state) => state.sar.data.user)
  let [details, setDetails] = useState([]);
  let [q, setq] = useState("");
  let [csvFinal, setCsvFinal] = useState([])
  const [searchParam] = useState(["phone", "name"]);
  let [reloadW, setReload] = useState(false);
  let token = useSelector((state) => state.sar.data.token)


  useEffect(() => {
    let data = async () => {
      await axios
        .post(`${serverUrl.u}/api/tele/getAllPartialSubscriptionsParticular`, {
          teleCallerId: teleCallers,
        })
        .then(function (response) {
          let detail = response.data.data;

          setDetails(detail);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    let dataAll = async () => {
      await axios
        .post(`${serverUrl.u}/api/tele/getAllPartialSubscriptions`)
        .then(async function (response) {
          let detail = response.data.data;
          setDetails(detail);
          let da = await CsvMake(detail)

          setCsvFinal(da)

        })
        .catch(function (error) {
          console.log(error);
        });
    };
    userRoles?.roles.includes("Super Admin") ? dataAll() : data()
  }, []);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return item[newItem].indexOf(q.toLowerCase()) > -1;
      });
    });
  }

  return (
    <div className="container-fluid">
      <div className="input-field">
        {
          csvFinal.length !== 0 ?
            <div style={{ marginRight: "15px" }}><CSVLink data={csvFinal}><img src={process.env.PUBLIC_URL + '/assets/icon/csv.png'} style={{ height: "35px" }} /></CSVLink></div>
            : null
        }
        <input
          type="text"
          value={q}
          placeholder="Search here name or phone number...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
      <div className="table-container1">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
              <th scope="col">Telecaller Name</th>
              <th scope="col">City</th>
              <th>Address<span style={{ color: "red" }}>*</span></th>
              <th scope="col">Phone</th>
              <th>Partial Payment</th>
              <th scope="col">Created At</th>
              <th scope="col">Appliances</th>
              <th scope="col">Selected Plan</th>

              <th scope="col">Comments<span style={{ color: "red" }}>*</span></th>

            </tr>
          </thead>
          <tbody>
            {details !== 0
              ? search(details).map((e, i) => {
                return (
                  <tr key={i}>
                    <td className="pr-15 pl-55" style={{ cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(e.generatedHex) }}>{e.name}</td>
                    <td>{e.teleCallerId}</td>
                    <td>{e.city}</td>
                    <AddressUpdate e={e} setReload={setReload} reloadW={reloadW} page={"partialPage"} />
                    <td>
                      <a href={`tel:${e.phone}`}>{e.phone}</a>
                    </td>
                    <td style={{ color: `${e.partialPayment === false ? "green" : (e.partialAmount === 0) ? "red" : "orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount === 0) ? "Zero" : `Partial-${e.partialAmount}`}</td>

                    <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                    <td>{e.totalAppliances}</td>
                    <td>{e.planFrequency}</td>
                    <CommentUdate e={e} setReload={setReload} reloadW={reloadW} page={"partialPage"} />

                  </tr>
                );
              })
              : null}
          </tbody>
        </table>
      </div>
      <Qutoes />
    </div>
  );
}
