export default function UpsellInputRow({
  label,
  type = "text",
  onChange,
  value,
}) {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      className="col-6"
    >
      <label>{label}</label>
      <input
        value={value}
        name={label}
        onChange={(e) => onChange(e)}
        className="form-control"
        required
        type={type}
      />
    </div>
  );
}
