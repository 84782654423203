import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { serverUrl } from '../ApiUrl';
import { notify, notifyFailed } from '../Common/Notification';
import { user_roles } from '../Data';

export default function ChangeBothTeleCaller({data}) {
    let salesUser = useSelector((state)=>state.salesUser)
    let [pop,setPop] = useState(false)
    const handleSubmit =(e)=>{
        e.preventDefault();
        let data = new FormData(e.currentTarget);
        console.log(data);
        axios.post(`${serverUrl.u}/api/tele/changeTelecaller`,{
          generatedHex:data.get('generatedHex'),
          teleCallerId:data.get('teleCallerId'),
          secondarySalesPerson:data.get('secondarySalesPerson')
        }).then(
          function (res) {
            console.log(res);
            notify(res.data.message)
            setPop(false)
          }
        ).catch(function (err) {
           console.log(err);
           notifyFailed(err.response.data.message)
        })
    }
  return (
    <>
         <button 
           className='btn btn-primary'
           onClick={()=>setPop(true)}
         >Change TeleCaller</button>

         {
            pop===true?
            <div style={{height:"100vh",width:"100vw",position:"fixed",top:0,left:0,display:"flex",justifyContent:"center",alignItems:"center",overflow:"scroll",zIndex:4}}>
                  <div style={{width:"30%",backgroundColor:"whitesmoke",borderRadius:"12px",padding:"1% 2%"}}>
                     <div style={{textAlign:"right"}}>
                        <span 
                          style={{cursor:"pointer"}}
                          onClick={()=>setPop(false)}
                        >
                            X
                        </span>
                     </div>
                     <form onSubmit={handleSubmit}>
                        <div>
                            <label>Client Name</label>
                            <div className='input-group mb-3'>
                            <select className="custom-select height-select" 
                                    id="inputGroupSelect01"
                                    name="generatedHex"
                                    label="generatedHex"
                                    autoComplete="current-generatedHex"
                                    defaultValue={data.generatedHex}
                                    required
                                    >
                                    
                                    
                                    <option value={data.generatedHex}>{data.name}</option>
                               
                            </select> 
                            </div>
                        </div>
                        <div>
                            <label>First TeleCaller</label>
                            <div className="input-group mb-3">
                            <select className="custom-select height-select" 
                                    id="inputGroupSelect01"
                                    name="teleCallerId"
                                    label="teleCallerId"
                                    autoComplete="current-teleCallerId"
                                    defaultValue={data.teleCallerId}
                                    required
                                    >
                                    
                                    {salesUser?.map((option) => (
                                    <option value={option.first_name}>{option.first_name}</option>
                                    ))}
                            </select>
                        </div>
                        </div>
                        <div>
                            <label>Second TeleCaller</label>
                            <div className="input-group mb-3">
                            <select className="custom-select height-select" 
                                    id="inputGroupSelect01"
                                    name="secondarySalesPerson"
                                    label="secondarySalesPerson"
                                    autoComplete="current-secondarySalesPerson"
                                    defaultValue={data.secondarySalesPerson}
                                    required
                                    >
                                    <option value={"empty"}>Empty</option>
                                    {salesUser?.map((option) => {
                                    if(option.roles!==undefined && option.roles.includes(user_roles.LeadHandler)){

                                      return(
                                    <option value={option.first_name}>{option.first_name}</option>
                                    )}})}
                            </select>
                        </div>
                        </div>
                        <button className='btn btn-primary' type='submit'>Submit</button>
                     </form>
                  </div>
            </div>:null
         }
    </>
  )
}
