
import { WhatsApp } from '@mui/icons-material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import ReactWhatsapp from 'react-whatsapp'
import { zohoLink } from '../Common/applianceDifference'
import { serverUrl } from '../ApiUrl'
import { Button } from '@mui/material'

function ReAttachMandatePaymentLink({ sub }) {
    const [paymentLink, setPaymentLink] = useState("")
    const [btn,setBtn] = useState("ReAttachMandate PaymentLink")

    const handleSubmit = async() => {
        setBtn("Processing...")
        let detail = {
            generatedHex: sub.generatedHex,
            totalAppliances: sub.totalAppliances,
            security: 0,
            planFrequency: sub.planFrequency,
            planId: sub.planId,
            installation: 0,
            collectOverdue: false,
            applyCreditNotes: false,
            invoiceArray: []
        }
      let res = await zohoLink(detail)
      if(res===true){
        setPaymentLink(
            `${serverUrl.u}${serverUrl.partialPayZoho}${sub.generatedHex}`
          );
      }else{
        setPaymentLink("")
      }
      setBtn("ReAttachMandate PaymentLink")
    }
    return (
        <>
            <Button
                
                variant="contained"
                size="small"
                color='primary'
                onClick={handleSubmit}
                disabled={btn==="Processing..."?true:false}
            >
                {btn}
            </Button>
            {
                paymentLink !== "" && (
                    <div className='popUpBackGround'>
                        <div className='innerModalPop'>
                            <div className='modalCross'>
                                <span className='crossIcon'
                                  onClick={()=>setPaymentLink("")}
                                >X</span>
                            </div>
                            <Stack direction="row" spacing={2}>
                                <ReactWhatsapp
                                    number={sub.phone}
                                    message={paymentLink}
                                    style={{
                                        backgroundColor: "#25D366",
                                        border: "none",
                                        borderRadius: "12px",
                                        padding: "5px",

                                    }}
                                >
                                    <WhatsApp
                                        sx={{
                                            color: "white",
                                            verticalAlign: "middle",
                                            fontSize: "xx-large",
                                        }}
                                    />
                                </ReactWhatsapp>


                                <Button
                                    variant="contained"
                                    size="small"
                                    color='primary'
                                    onClick={() => navigator.clipboard.writeText(paymentLink)}
                                >
                                    Copy
                                </Button>
                            </Stack>
                        </div>

                    </div>
                )
            }
        </>
    )
}

export default ReAttachMandatePaymentLink