import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { serverUrl } from '../../ApiUrl';
import Multiselect from 'multiselect-react-dropdown';
import { subHistory } from '../../Function/SubscriptionHistory';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactWhatsapp from 'react-whatsapp'
import { WhatsApp } from '@mui/icons-material'
import SelectCityInput from '../../city/SelectCityInput';
import { notifyFailed } from '../../Common/Notification';
import { ElectricianIncentiveZone } from '../../Data';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { assignElectrician, changeDate, changeSubscriptionStage, sendToDispatch, timeFormate } from '../../Common/applianceDifference';
import AlertSuccessModel from '../../Common/AlertSuccessModel';

export default function ElectricianUpsell({LoadPage}) {
    let [paymentLink, setPaymentLink] = useState("")
    const [text, setText] = useState("");
    let userRole = useSelector((state) => state.sar.data?.user?.roles)
    let [open, setOpen] = useState(false)
    let [whatappNumber, setWhatappNumber] = useState();
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let employeeId = useSelector((state) => state.sar.data?.user?.employeeId)
    const [makePerformaInvoice, setMakePerformaInvoice] = useState(false)
    let [formData, setFormData] = useState({
        IsBusiness: false,
        Customer: false,
        IsUP: false,
        IsMrpShow: "false",
        partialAmount: 0,
        installation: 0,
        payment_mode: "Select Mode",
        installationDate: timeFormate(new Date())
    });

    let [newProductDataItem, setNewProductDataItem] = useState([])
    let [btnPaymentLink, setBtnPaymentLink] = useState("Register UpSell")
    const [displayData, setDisplayData] = useState([])
    const notify = (text) => toast(text);
    let [amounts, setAmount] = useState({
        subTotal: 0,
        tax: 0,
        total: 0,
        installationCharge: 0
    })
    const Product = useRef({})

    const handleChange = (event) => {
        console.log(event);
        if (event.target.name === "email" || event.target.name === "phone") {
            setFormData({ ...formData, [event.target.name]: event.target.value.trim() })
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value })
        }
    }
    const setCity = (value) => {
        handleChange({ target: { name: "city", value: value } })
    }
    useEffect(() => {
        axios.post(`${serverUrl.u}/v3/affiliateProducts/listAll`).then(
            function (res) {
                console.log(res);
                setDisplayData(res.data.data.products)
            }
        ).catch(function (err) {
            console.log(err);
        })
    }, [])
    useEffect(() => {
        console.log(formData);
    }, [formData])
    useEffect(() => {
        if (!formData.hasOwnProperty('hex') && formData.hex === '') return
        axios.post(`${serverUrl.u}/api/tele/getSubscription`, {
            generatedHex: formData.hex
        }).then(function (res) {
            console.log(res.data.data, "check2");
            if (res.data.success === true) {
                let detail = res.data.data
                setFormData({ ...formData, success: true, clientName: detail.name, address: detail.address, phone: detail.phone.trim(), email: detail.email.trim(), city: detail.city })
            }
        }).catch(function (error) {
            console.log(error);
        })
    }, [formData.hex])
    const onChange = (value, id, key) => {

        let newData = newProductDataItem.map((e) => {
            if (e._id === id) {
                return { ...e, [key]: value }
            } else {
                return e
            }
        })
        
        let subTotal = newData.reduce((previous, current) => {
            return Number(previous + Number(current.selling_price * current.qty))
        }, [0])
        let installationCharge = newData.reduce((previous, current) => {
            return Number(previous + Number(current.installationCharge * current.qty))
        }, [0])
        subTotal = Number(Math.round(subTotal))
        setAmount({
            subTotal: subTotal,
            tax: Math.round((subTotal + Number(installationCharge))* 0.18),
            total: Math.round((subTotal + Number(installationCharge))* 1.18),
            installationCharge: installationCharge
        })
        setNewProductDataItem(newData)
    }
  
    const selectedValue = (data) => {
        
        let newProductData = []
        if (newProductDataItem.length === 0) {
            newProductData = data.map(e => {
                return { ...e, qty: 1 }
            })
        } else {
            let res = data.filter(x => !newProductDataItem.some(y => y._id === x._id));
            res = res.map(e => {
                return { ...e, qty: 1 }
            })
            newProductData = [...newProductDataItem, ...res]
        }
        if (data.length !== 0) {
            setNewProductDataItem(newProductData)
        } else {
            setNewProductDataItem([])
        }
    }
    const onRemove = (data) => {
        let res = newProductDataItem.filter(x => data.some(y => y._id === x._id));
        setNewProductDataItem(res)
    }
  

    useEffect(() => {
        if (newProductDataItem.length === 0) return
        let subTotal = newProductDataItem.reduce((previous, current) => {
            return Number(previous + Number(current.selling_price * current.qty))
        }, [0])
        let installationCharge = newProductDataItem.reduce((previous, current) => {
            return Number(previous + Number(current.installationCharge * current.qty))
        }, [0])
        subTotal = Number(Math.round(subTotal))
        setAmount({
            subTotal: subTotal,
            tax: Math.round((subTotal + Number(installationCharge)) * 0.18),
            total: Math.round((subTotal + Number(installationCharge)) * 1.18),
            installationCharge: installationCharge
        })

        console.log(newProductDataItem, "d2");
    }, [newProductDataItem])

    const errorHandle = () => {
        console.log(formData, "form");
        if (formData.clientName === null || formData.clientName === "" || formData.clientName === undefined) {
            notify("Fill Client Name")
            return true
        }
        if (formData.address === null || formData.address === "" || formData.address === undefined) {
            notify("Fill Address")
            return true
        }
        if (formData.email === null || formData.email === "" || formData.email === undefined) {
            notify("Fill Email")
            return true
        }
        if (formData.city === null || formData.city === "" || formData.city === undefined) {
            notify("Fill City")
            return true
        }
        if (formData.phone === null || formData.phone === "" || formData.phone === undefined || formData.phone.trim().length > 13) {
            notify("Enter Phone Number")
            return true
        }
        if (newProductDataItem.length === 0) {
            notify("Select Product")
            return true
        }


    }

    const sellProductLinkNew = () => {
        let dd = errorHandle()
        if (dd === true) {
            setBtnPaymentLink("Failed")
            return
        }
        if (formData.zone === undefined || formData.zone === "") {
            notifyFailed("Please Select Zone")
            setBtnPaymentLink("Failed")
            return
        }
        console.log(formData.installationDate, "install date");
        setBtnPaymentLink("Processing...")
        let itemArray = []
        for (let item of newProductDataItem) {
            let obj = {
                P_Id: item.product_code,
                sellingPricePerPic: item.selling_price,
                mrpPricePerPic: item.mrp,
                qty: item.qty,
                hsn_code: item.hsn_code,
                productName: item.name,
            }

            itemArray.push(obj)
        }
        let detail = {
            upsellItems: itemArray,
            perApplianceCost: 0,
            orderOrigin: 'CRM',
            totalAppliances: 0,
            recursiveAmount: amounts.subTotal,
            totalAmount: Number(amounts.total),
            secondarySalesPerson: "empty",
            partialPayment: true,
            partialAmount: formData.partialAmount,
            refferal: "empty",
            installation: Number(amounts.installationCharge),
            teleCallerId: loginName,
            comments: "",
            zip: "",
            address: formData.address,
            zone: formData.zone,
            city: formData.city,
            phone: formData.phone,
            email: formData.email,
            first_name: formData.clientName,
            last_name: "",
            orderType: "upSell"
        }

        axios.post(`${serverUrl.u}/api/zoho/registerLeadAndLinkUpsell`, detail).then(async function (res) {
            console.log(res);
            if (res.data.success === true) {
                notify(res.data.message)
                setBtnPaymentLink("Register UpSell")
                if (Number(formData.partialAmount) !== 0) {
                    setWhatappNumber(formData.phone);
                    setText(`${res.data.data.partialPaymentDetails.short_url}`);
                    navigator.clipboard.writeText(res.data.data.partialPaymentDetails.short_url);
                    setPaymentLink(res.data.data.partialPaymentDetails.short_url);
                    subHistory("Multiple Product Upsell Created With Link By Electrician", detail, "Upsell Created With Link", res.data.data.partialPaymentDetails.short_url)
                } else {
                    subHistory("Multiple Product Upsell Created WithOut Link By Electrician", detail, "Upsell Created Without Link", "Zero payment Upsell Register")
                    let hex = res.data.data.subscription.generatedHex
                    let id = res.data.data.subscription._id
                    changeDate(hex, formData.installationDate, loginName)
                    // sendToDispatch(id)
                    await changeSubscriptionStage(hex, "ready to install")
                    let ele = [{ "name": loginName, Id: employeeId }]
                    assignElectrician(hex, ele)
                    LoadPage()
                }
                setOpen(true)
            } else {
                notify(res.data.message)
                setBtnPaymentLink("Failed")
            }
        }).catch(function (err) {
            console.log(err);
            notify(err.response.data.message)
            setBtnPaymentLink("Failed")
        })
    }

    return (
        <div style={{ marginTop: "4vh", padding: "10px" }}>
            <ToastContainer />
            <AlertSuccessModel open={open} setOpen={setOpen} />
            <Box component='div' className='make_quto' sx={{ display: "flex", gap: 2, flexWrap: "wrap", alignItems:"flex-end" }}>
                <FormControl variant="standard" sx={{ minWidth: 150 }} required={true}>
                    <InputLabel id="demo-simple-select-standard-label">Customer is Register?</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        defaultValue={false}
                        onChange={handleChange}
                        label="Customer"
                        name="Customer"
                        autoWidth
                    >

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                </FormControl>
                {
                    (formData.hasOwnProperty('Customer') && formData.Customer === true) ?
                        <TextField id="standard-helperText" label="Hex Code" variant="standard" name="hex" autoWidth sx={{ minWidth: "100px" }} onBlur={handleChange} /> : null
                }
                <FormControl variant="standard" sx={{ minWidth: 150 }} required={true}>
                    <InputLabel id="demo-simple-select-standard-label">Zone</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        defaultValue={false}
                        onChange={handleChange}
                        label="zone"
                        name="zone"
                        autoWidth
                    >

                        {
                            ElectricianIncentiveZone.map((z) => (
                                <MenuItem value={z.value}>{z.value}</MenuItem>
                            ))
                        }

                    </Select>
                </FormControl>

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                        <DateTimePicker
                            label="Installation Date"

                            name="installationDate"
                            value={formData.installationDate}
                            onChange={(value) => setFormData({ ...formData, installationDate: value })}
                        />
                    </DemoContainer>
                </LocalizationProvider> */}
                <TextField 
                   id="standard-helperText" 
                   label="Installation Date" 
                   variant="standard" 
                   type='date'
                   name="installationDate" 
                   value={formData.installationDate} 
                   autoWidth sx={{ minWidth: "100px" }} 
                   onChange={(e) => setFormData({ ...formData, installationDate: e.target.value })}
                   />


            </Box>

            <Box component='div' className='make_quto' sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                <TextField id="standard-helperText" label="Client Name" variant="standard" name="clientName" value={formData.clientName ? formData.clientName : ' '} autoWidth sx={{ minWidth: "100px" }} onChange={handleChange} />
                <TextField id="standard-helperText" label="Address" variant="standard" name="address" value={formData.address ? formData.address : ' '} autoWidth sx={{ minWidth: "300px" }} onChange={handleChange} />
                <TextField id="standard-helperText" label="Phone" variant="standard" name="phone" value={formData.phone ? formData.phone : ' '} autoWidth sx={{ minWidth: "100px" }} autoFocus onChange={handleChange} />
                <TextField
                    id="standard-helperText"
                    label="Email"
                    name="email"
                    autoWidth sx={{ minWidth: "100px" }}
                    value={formData.email ? formData.email : ' '}
                    onChange={handleChange}
                    variant="standard"
                />

                <FormControl variant="standard" sx={{ minWidth: 250 }}>
                    <label className='mb-0'>{formData.city ? formData.city : "City"}</label>
                    <SelectCityInput value={formData.city} setValue={(name) => setCity(name)} />
                </FormControl>
            </Box>

            <Box component='div' className='make_quto'>
                <Multiselect
                    displayValue="name"
                    onRemove={onRemove}
                    onSelect={selectedValue}
                    options={displayData}
                    style={{
                        width: "100px !important",
                        multiselectContainer: {
                            marginTop: "5px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />
            </Box>
            {
                newProductDataItem.length !== 0 ?
                    <Box component='div' className='make_quto ' style={{ overflowX: "scroll" }}>

                        <div className='row ml-2 mb-2'>
                            <table className='table'>
                                <thead>
                                    <tr>

                                        <th>Product Name</th>
                                        <th>M.R.P</th>
                                        <th>Selling Price</th>
                                        <th>Quantity</th>
                                        <th>HSN Code</th>
                                        <th>Installation Charges</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        newProductDataItem.map((e) => {
                                            return (
                                                <tr>
                                                    <td><input type="text" value={e.name} onChange={(ele) => onChange(ele.target.value, e._id, "name")} style={{ width: "60px" }} /></td>
                                                    <td><input type="number" value={e.mrp} onChange={(ele) => onChange(Number(ele.target.value), e._id, "mrp")} min={1} style={{ width: "60px" }} /></td>
                                                    <td><input type="number" value={e.selling_price} onChange={(ele) => onChange(Number(ele.target.value), e._id, "selling_price")} min={1} style={{ width: "60px" }} /></td>
                                                    <td><input type="number" value={e.qty} onChange={(ele) => onChange(Number(ele.target.value), e._id, "qty")} min={1} style={{ width: "60px" }} /></td>
                                                    <td><input type="text" value={e.hsn_code} onChange={(ele) => onChange(ele.target.value, e._id, "hsn_code")} style={{ width: "60px" }} /></td>
                                                    <td><input type="text" value={e.installationCharge} onChange={(ele) => onChange(ele.target.value, e._id, "installationCharge")} style={{ width: "60px" }} /></td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>
                        <Box component='div' sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                            <TextField id="standard-helperText" label="Sub Total" variant="standard" name="subTotal" value={amounts.subTotal} autoWidth sx={{ minWidth: "100px" }} />
                            <TextField id="standard-helperText" label="Installation Charges" variant="standard" name="installation" value={amounts.installationCharge ? amounts.installationCharge : 0} autoWidth sx={{ minWidth: "100px" }} autoFocus type='number' disabled />
                            <TextField id="standard-helperText" label="Tax" variant="standard" name="tax" value={amounts.tax} onChange={(e) => setAmount({ ...amounts, tax: Number(e.target.value) })} autoWidth sx={{ minWidth: "100px" }} type='number' />
                            <TextField id="standard-helperText" label="Total" variant="standard" name="total" value={amounts.total} autoWidth sx={{ minWidth: "100px" }} onChange={(e) => setAmount({ ...amounts, total: Number(e.target.value) })} autoFocus type='number' />
                            <TextField id="standard-helperText" label="Advance Amount" variant="standard" name="partialAmount" value={formData.partialAmount ? formData.partialAmount : 0} autoWidth sx={{ minWidth: "100px" }} autoFocus onChange={handleChange} type='number' />
                        </Box>
                    </Box> : null
            }


            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", marginBottom: "50px" }}>




                <Button
                    variant="contained"
                    onClick={() => sellProductLinkNew()}
                    style={{ pointerEvents: btnPaymentLink === "Processing..." ? "none" : "" }}
                >{btnPaymentLink}</Button>





                {text !== "" ? (
                    <>

                        <Button
                            variant="contained"
                            onClick={() => navigator.clipboard.writeText(paymentLink)}
                        >
                            Copy
                        </Button>



                        <ReactWhatsapp
                            number={whatappNumber}
                            message={text}
                            type='button'
                            className="btn2 btn-primary mt-2"
                            style={{
                                backgroundColor: "#25D366",
                                border: "none",
                                borderRadius: "12px",
                                padding: "5px",

                            }}
                        >
                            <WhatsApp
                                sx={{
                                    color: "white",
                                    verticalAlign: "middle",
                                    fontSize: "xx-large",
                                }}
                            />
                        </ReactWhatsapp>

                    </>
                ) : null}
            </Box>
        </div>
    )
}
