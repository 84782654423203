import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'
import { Button } from '@mui/material'
import { notify, notifyFailed } from '../Common/Notification'
import { useSelector } from 'react-redux'
import { useState ,useEffect} from 'react'
import { orderTyepes } from '../Data'
import { subHistory } from '../Function/SubscriptionHistory'
import { electicianLocationLog, typeOfLocaionLogs } from './api'

function FittingStarted({sub,distanceLog=true}) {
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)
  let [deviceId,setDeviceId] = useState("")
  let [type,setType] = useState('number')
  let [btn,setBtn] = useState("Create Home")
  let [deviceIdAvilable,setDeviceIdAvilable] = useState("true")
  let [pop,setPop] = useState(false)
  let [loginId,setLoginId] = useState("")
  let [popHouse,setPopHouse] = useState(false)
  let [paidSubscription,setPaidSubscription] = useState([])
  const fittingStart = async() =>{
  
    await axios.post(`${serverUrl.u}/api/elec/fittingStart`,{
        generatedHex:sub.generatedHex
    }).then(function (res) {
        console.log(res);
        if(res.data.success){
         window.location.reload()
        }else{
            notifyFailed("Fitting Failed")
        }
    }).catch(function (err) {
        console.log(err);
        notifyFailed("Fitting Started Failed")
    })
}
  const checkSub=()=>{
    axios.post(`${serverUrl.u}/api/sub/checkSubscriptionExistOrNot`,{
      phone: sub.phone
    }).then(
      function (res) {
        console.log(res);
        if(res.data.success===true){
          if(Object.keys(res.data.data).length!==0){
            setPaidSubscription(res.data.data.user)
            setPop(true)
          }else{
            setPaidSubscription({})
            // fittingStart()
            setPopHouse(true)
          }
        }else{
          setPaidSubscription({})
          // fittingStart()
          setPopHouse(true)
        }
       
      }
    ).catch(function (err) {
      console.log(err);

      setPaidSubscription({})
      // fittingStart()
      setPopHouse(true)
    })
  }
    
    const updateHousidDeviceid = async(decision,id)=>{
      // if(deviceIdAvilable==="false"){
      //   fittingStart()
      //   return
      // }
      
      await axios.post(`${serverUrl.u}/api/tele/updateHouseIdAndDevices`, {
        generatedHex: sub.generatedHex,
        deviceId: decision,
        id,
      }).then(function (res) {
        
        if (res.data.success === true) {
          notify("Data Saved Successfully");
          subHistory('House Id Updated', {...sub,payload:{
            deviceId: decision,
            id,
          }}, "House Id Updated", "")
          fittingStart()
        } else {
          notifyFailed(res.data.message);
        
        }
      }).catch(function (error) {
        console.log(error);
        notifyFailed(error.response.data.message);
  
      })
    }
    
  
  function success(pos) {
    let crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    handleSubmit(crd)
}
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    notifyFailed(err.message)
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocationAccess = async(event) => {
    event.preventDefault();
    const Formdata = new FormData(event.currentTarget);
    if (navigator.geolocation) {
     await navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
           navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
           navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            notifyFailed("Allow Location Permissions")
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
      notifyFailed("Geolocation is not supported by this browser.")
   
    }
  }
    const handleSubmit = async (locationDetails)=>{
      setBtn('Processing...')
       
        let number = loginId
        console.log(number);
        if(number.length===0) {
          notifyFailed("Enter Id")
          setBtn("Create Home")
          return
        }
        const cleanNumber = (num) => {
            try {
              let cn = String(num).replace(/\D/g, ''); // replace non digits
              if (cn.startsWith('0')) {
                // the number is starting with 0, remove it
                cn = cn.slice(1, cn.length);
              }
              if (cn.length > 10) {
                cn = cn.slice(cn.length - 10, cn.length);
              }
              return `+91${cn}`;
            } catch (_) {
              return num;
            }
          };
          if(type==="number"){
          number= await cleanNumber(number);
          }
        axios.post(`${serverUrl.u}/api/sub/createHouse`,{
            masters:[number],
            createdBy:number,
            generatedHex:sub.generatedHex,
            latitude:locationDetails.latitude,
      longitude:locationDetails.longitude
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                notify(res.data.message)
                subHistory(`Create Home by ${loginName}`,sub,"Create Home",number)
                setLoginId("")
                window.location.reload()
            }else{
                notify(res.data.message)
                setLoginId("")
            }
            setBtn("Create Home")
        }).catch(function (err) {
            console.log(err);
            notify("Failed")
            setBtn("Create Home")
            setLoginId("")
        })
    }
  return (
    <>
    <Button
      variant='contained'
      color='primary'
      onClick={async()=>{
        if(distanceLog){
        let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.reach,sub)
        console.log(res,"fit");
        if(!res.success){
          notifyFailed("First Click on Day Start Button")
          return
        }
      }
        if(sub.houseId==="" && orderTyepes[2].value!==sub.orderType){
          checkSub()
        }else if(sub.houseId!=="" && orderTyepes[2].value!==sub.orderType){
          updateHousidDeviceid(false,sub.houseId)
          // setPop(true)
        }else{
          fittingStart()
        }
      }}
    >{sub.reeInstallation?"ReInstallation Started":"Fitting Started"}</Button>
    {
      pop?
      <div style={{position:"fixed",top:0,left:0,display:"flex",alignItems:"center",justifyContent:"center",height:"100vh",width:"100vw"}}>
        <div style={{
          backgroundColor:"whitesmoke",
          borderRadius:"12px",
          padding:"12px"
        }}>
            <div>
              <span
                onClick={()=>setPop(false)}
              >X</span>
            </div>
            {/* <label>Have Device Id</label>
            <select
               className='form-control'
               onChange={(e)=>setDeviceIdAvilable(e.target.value)}
            >
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </select> */}
            <label>Device id</label>
            <input
              type='text'
              className='form-control'
              onChange={(e)=>setDeviceId(e.target.value)}
            />
            <Button
              variant='contained'
              color='primary'
              className='mt-3'
              size='small'
              onClick={()=>updateHousidDeviceid(true,deviceId)}            
            >Submit</Button>
        </div>
      </div>:null
    }
    {
        popHouse===true?
        <div  className='homeContainer'>
            <div style={{position:"relative",width:"100%"}}><span style={{position:"absolute",right:"5px"}} onClick={()=>setPopHouse(false)}>X</span></div>
            <form onSubmit={getLocationAccess}>
            <label style={{width:'90%'}}>Enter Electrician Number/Email Register With Aliste App?</label>
                <div className="input-group mb-3">
                  <select
                    onChange={(val)=>setType(val.target.value)}
                    className="form-control"
                    required
                    value={type}
                  >
                    <option value={'number'}>Phone</option>
                    <option value={'email'}>Email</option>
                  </select>
                </div>
                <div className="input-group mb-3">
                    <input
                      type={type}
                      name="number"
                      className="form-control"
                      autoComplete='current-number'
                      value={loginId}
                      onChange={(e)=>setLoginId(e.target.value)}
                      required
                    />
                </div>
                <button type='submit' className='btn btn-primary' style={{pointerEvents:btn==="Create Home"?"":"none"}}>{btn}</button>
            </form>
        </div>:null
      }
    </>
  )
}

export default FittingStarted