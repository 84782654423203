import React, { useState } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'

export default function TicketResolve({e,notify,reload,setReload,spinnerActive,setSpinnerActive,tikResolveBtnText,setTikResolveBtnText}) {
  const ticketResolve = ()=>{
   setSpinnerActive(true)
   setTikResolveBtnText("Processing...")
     
      axios.post(`${serverUrl.u}/api/zoho/ticketResolve`,{
        uniqueId:e._id
      }).then(function (res) {
         console.log(res);
         if(res.status===200){
            notify(res.data.msg)
           
            setReload(!reload)
            
         }else{
            notify(res.data.msg)
            setTikResolveBtnText("Failed")
         }
      }).catch(function (err) {
         console.log(err);
         notify(err.message)
         setTikResolveBtnText("Failed")
      })
  }

  return (
    
            <button className='btn btn-primary width' onClick={()=>ticketResolve()}>{tikResolveBtnText}</button>
    
  )
}
