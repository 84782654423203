import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './ConvertedLead.css'
import './DispatchData.css'
import './PaymentLinkGen.css'
import './PartialPayment.css'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs';
import Qutoes from './Qutoes'
import { serverUrl } from './ApiUrl'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { InstallationUser } from "./Function/AxiosControl";
import { salesUserData } from './Function/AxiosControl'
import { StagesSubscription, state } from './Data'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddressUpdate from './Function/AddressUpdate';
import PlanUpdate from './Function/PlanUpdate'
import SearchDataWithStage from './Function/SearchDataWithStage'
import { addGrevianceClient } from './ApiCalls/AddGreviance'
import CommentUdate from './Function/CommentUdate'
import { ci } from './city/Cities'
export default function PaidOrderStatus({ wholeReload }) {
  let [details, setDetails] = useState([]);
  let [displayData, setDisplayData] = useState([])
  let [salesUserDatas, setSalesUserDatas] = useState()
  let [userName, setUserName] = useState()
  let [GerivanceType, setGrivanceType] = useState("Misc")
  let token = useSelector((state) => state.sar.data.token)
  let [filterOption, setFilterOption] = useState(false)
  let [update, setUpdateData] = useState({})
  let [q, setq] = useState("");
  let grivance = useRef(null);
  let grivanceDate = useRef(null);
  let grivanceComment = useRef(null);
  let gstName = useRef(null)
  let gstNumber = useRef(null)
  let [reloadW, setReload] = useState(false);
  const [searchParam] = useState(["phone", "name"]);
  let [resetData, setResetData] = useState()
  let [partialD, setPartialD] = useState(false);
  let [grivanceDisplay, setGrivanceDisplay] = useState(false)
  let [addOnDisplay, setAddOnDisplay] = useState(false)
  let [deviceObject, setDeviceObject] = useState({})
  let [gstContainer, setGstContainer] = useState(false)
  let [occurnce, setOccurnce] = useState([])
  let [page, setPage] = useState(1)
  let [totalPage, setTotalPage] = useState(1)
  let [filterParameter,setFilterParameter] = useState({
    teleCallerId:"All",
    city:"All",
    subscriptionStage:"All"
  })
  let [filter, setFilter] = useState({
    partialPayment: false,
    dispatched: false,
    installation: false,
    nonPartialPayent: false
  })
  let userRoles = useSelector((state) => state.sar.data.user)
  let teleCallers = useSelector((state) => state.sar.data.user.first_name)
  let [secIns, setSecIns] = useState({
    security: 0,
    installation: 0
  });
  const notify = (text) => toast(text);
  useEffect(() => {

    let dataAll = async () => {
      setFilterOption(true)
      await axios.post(`${serverUrl.u}/api/tele/GetAllSubscriptions/?p=${page - 1}`,filterParameter).then(function (response) {
        let detail = response.data.data.sort((a,b)=>new Date(b.InstallationDate).getTime()-new Date(a.InstallationDate).getTime());
        setTotalPage(response.data.totalPage)
        console.log(detail);
        if (filter.partialPayment === false && filter.dispatched === false && filter.installation === false && filter.nonPartialPayent === false) {
          setDetails(detail)
          setDisplayData(detail)
        } else if (filter.partialPayment === true && filter.dispatched === true && filter.installation === true) {
          let partialData = detail.filter(e => (e.partialPayment === true))
          let dispatchData = partialData.filter(e => (e.dispatched === true))
          let installData = dispatchData.filter(e => (e.installed === true))
          setDetails(installData);
          setDisplayData(detail)
        } else if (filter.partialPayment === true && filter.dispatched === false && filter.installation === false && filter.nonPartialPayent === false) {
          detail = detail.filter(e => (e.partialPayment === true))
          setDetails(detail)
          setDisplayData(detail)
        } else if (filter.partialPayment === false && filter.dispatched === true && filter.installation === false && filter.nonPartialPayent === false) {
          detail = detail.filter(e => (e.dispatched === true))
          setDetails(detail)
          setDisplayData(detail)
        } else if (filter.partialPayment === false && filter.dispatched === false && filter.installation === true && filter.nonPartialPayent === false) {
          detail = detail.filter(e => (e.installed === true))
          setDetails(detail)
          setDisplayData(detail)
        } else if (filter.partialPayment === false && filter.dispatched === false && filter.installation === false && filter.nonPartialPayent === true) {
          detail = detail.filter(e => (e.partialPayment === false))
          setDetails(detail)
          setDisplayData(detail)
        }
      }).catch(function (error) {
        console.log(error);
      })
    }
    let data = async () => {
      await axios.post(`${serverUrl.u}/api/tele/GetAllSubscriptions/?p=${page - 1}`, {
        ...filterParameter,
        teleCallerId: teleCallers,
      })
        .then(function (response) {
          let detail = response.data.data.sort((a,b)=>new Date(b.InstallationDate).getTime()-new Date(a.InstallationDate).getTime());
          setTotalPage(response.data.totalPage)
          console.log(detail);
          if (filter.partialPayment === false && filter.dispatched === false && filter.installation === false && filter.nonPartialPayent === false) {
            setDetails(detail)
            setDisplayData(detail)
          } else if (filter.partialPayment === true && filter.dispatched === true && filter.installation === true) {
            let partialData = detail.filter(e => (e.partialPayment === true))
            let dispatchData = partialData.filter(e => (e.dispatched === true))
            let installData = dispatchData.filter(e => (e.installed === true))
            setDetails(installData);
            setDisplayData(detail)
          } else if (filter.partialPayment === true && filter.dispatched === false && filter.installation === false && filter.nonPartialPayent === false) {
            detail = detail.filter(e => (e.partialPayment === true))
            setDetails(detail)
            setDisplayData(detail)
          } else if (filter.partialPayment === false && filter.dispatched === true && filter.installation === false && filter.nonPartialPayent === false) {
            detail = detail.filter(e => (e.dispatched === true))
            setDetails(detail)
            setDisplayData(detail)
          } else if (filter.partialPayment === false && filter.dispatched === false && filter.installation === true && filter.nonPartialPayent === false) {
            detail = detail.filter(e => (e.installed === true))
            setDetails(detail)
            setDisplayData(detail)
          } else if (filter.partialPayment === false && filter.dispatched === false && filter.installation === false && filter.nonPartialPayent === true) {
            detail = detail.filter(e => (e.partialPayment === false))
            setDetails(detail)
            setDisplayData(detail)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    }
    userRoles?.roles.includes("Super Admin") ? dataAll() : data()
    const sale = async () => {
      let salesUser = await salesUserData(token);
      setSalesUserDatas(salesUser);
      let installUser = await InstallationUser(token);
      setUserName(installUser)
      console.log(salesUser, installUser);
    }
    sale();
  }, [reloadW, filter, wholeReload, page,filterParameter])
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }


  const planNameSet = (data) => {
    setSecIns({
      security: data.security,
      installation: data.installation
    })
  }
  const handleApplianceChange = (val) => {
    val = Number(val);
    let secu = val * 100;
    let inst;
    if (val > 10) {
      let cal = val - 10;
      inst = 1000 + (cal * 50);
    } else {
      inst = 1000;
    }
    setSecIns(
      {
        security: secu,
        installation: inst
      }
    )
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userData2 = {
      parentHex: data.get('PHex'),
      chilHex: data.get('CHex')
    }
    console.log("Hex Code  ", userData2);

    updateAddOnPlan(userData2)
  }

  const updateAddOnPlan = async (userData) => {
    console.log("call");
    await axios.post(`${serverUrl.u}/api/tele/additionalSubscription`,
      {
        parentHex: userData.parentHex,
        childHex: userData.chilHex
      }).then(function (response) {
        console.log(response);
        if (response.data.success === true) {
          setAddOnDisplay(false)
          setReload(!reloadW)
        }
      }).catch(function (error) {
        console.log(error);
      })
  }
  const changeGstStatus = (e) => {
    axios.post(`${serverUrl.u}/api/tele/changeGSTStatus`, {
      generatedHex: e.generatedHex,
      boole: !e.isGstClient
    }).then(function (res) {
      console.log(res);
      setReload(!reloadW)
    }).catch(function (error) {
      console.log(error);
    })
  }
  const changeGstName = async (name, number, e) => {
    await axios.post(`${serverUrl.u}/api/tele/changeGSTName`, {
      generatedHex: e.generatedHex,
      boole: name
    }).then(function (res) {
      console.log(res);
      changeGstNumber(number, e)
    }).catch(function (error) {
      console.log(error);
    })
  }
  const changeGstNumber = async (number, e) => {
    await axios.post(`${serverUrl.u}/api/tele/changeGSTNumber`, {
      generatedHex: e.generatedHex,
      boole: number
    }).then(function (res) {
      console.log(res);
      setGstContainer(false)
      setReload(!reloadW)
    }).catch(function (error) {
      console.log(error);
    })
  }
  const updateGstDetails = async (name, number, e) => {
    await axios.post(`${serverUrl.u}/api/tele/changeGSTStatus`, {
      generatedHex: e.generatedHex,
      boole: true
    }).then(function (res) {
      console.log(res);
      setReload(!reloadW)
      changeGstName(name, number, e);
    }).catch(function (error) {
      console.log(error);
    })
  }
  const updateSiteVisitedAppliances = async (appliances, e) => {
    await axios.post(`${serverUrl.u}/api/tele/updateSiteVisiteAppliances`, {
      generatedHex: e.generatedHex,
      numberOfAppliances: Number(appliances),
    }).then(function (res) {
      console.log(res);
      notify(res.data.message)
      setReload(!reloadW)
    }).catch(function (error) {
      console.log(error);
      notify(error.data.message)
    })
  }


  const colorDecide = (text) => {
    if (text === "dispatch") {
      return "rgb(251, 37, 118)"
    }
    if (text === "ready to install") {
      return "green"
    }
    if (text === "installed" || text === "installed & complete") {
      return "rgb(51, 47, 208)"
    }
  }

  const filterOnTelecaller = (name) => {
    if (name === "all") {
      setDisplayData(details)
    } else {
      let data = details.filter((e) => e.teleCallerId == name)
      setDisplayData(data);
    }
    console.log(displayData, "data");
  }
  const filterOnCity = (name) => {
    if (name === "all") {
      setDisplayData(details)
    } else {
      let data = details.filter((e) => e.city == name)
      setDisplayData(data);
    }
  }
  const filterOnStage = (value) => {
    if (value.includes("all")) {
      setDisplayData(details)
    } else {
      let data = details.filter((e) => value.includes(e.subscriptionStage))
      setDisplayData(data);
    }
  }
  const filterOnElectrician = (name) => {
    if (name === "all") {
      setDisplayData(details)
    } else {
      console.log(name, "name");
      let data = details.filter((e) => e.AssignedElectritian == name)
      console.log("e", data);
      setDisplayData(data);
    }
  }

  function findOcc(arr, key) {
    let arr2 = [];

    arr.forEach((x) => {

      // Checking if there is any object in arr2
      // which contains the key value
      if (arr2.some((val) => { return val[key] == x[key] })) {

        // If yes! then increase the occurrence by 1
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"]++
          }
        })

      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        let a = {}
        a[key] = x[key]
        a["occurrence"] = 1
        arr2.push(a);
      }
    })

    return arr2
  }



  useEffect(() => {
    let key = "phone"
    console.log("find", findOcc(displayData, key))
    setOccurnce(findOcc(displayData, key))
  }, [displayData])

  const similarColor = (phones) => {


    //  for (let e in occurnce) {
    //   console.log(e,"e");
    //     if(e.phone==phones && e.occurrence!==1){
    //       console.log("come");
    //       return "green";
    //     }else{
    //       return "white";
    //     }
    //    }
    let d = occurnce.some(e => {
      if (e.phone == phones && e.occurrence !== 1) {
        return true;
      } else { return false }
    })
    return d;
  }

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>

      <div className='input-field'>
        <div style={{ marginRight: "15px" }}><Link to="/CscOrderPaidStatus" state={{ from: {}, path: "Paid_Clients" }}><img src={process.env.PUBLIC_URL + '/assets/icon/csv.png'} style={{ height: "35px" }} /></Link></div>
        <SearchDataWithStage setDisplayData={setDisplayData} stage={"All"} /></div>
      <ToastContainer />
      <div>
        <input type="checkbox" checked={filter.partialPayment} onClick={(e) => {
          console.log(e.target.checked)
          setFilter({ ...filter, partialPayment: e.target.checked })
        }} />
        <label style={{ marginRight: "30px" }}>Partial Payment</label>
        {/* <input type="checkbox" checked={filter.dispatched} onClick={(e) => setFilter({ ...filter, dispatched: e.target.checked })} /> */}
        {/* <label style={{ marginRight: "30px" }}>Dispatch</label>
        <input type="checkbox" checked={filter.installation} onClick={(e) => setFilter({ ...filter, installation: e.target.checked })} />
        <label style={{ marginRight: "30px" }}>Installed</label> */}
        <input type="checkbox" checked={filter.nonPartialPayent} onClick={(e) => setFilter({ ...filter, nonPartialPayent: e.target.checked })} />
        <label style={{ marginRight: "30px" }}>Non Partial Payment</label>
      </div>

          <div className='row'>

      {
        filterOption === true &&
            <div className="col-2">
              <label className="create-label1">TeleCaller</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  onChange={(e) => setFilterParameter({...filterParameter,teleCallerId:e.target.value})}
                  value={filterParameter.teleCallerId}
                >
                  <option value="All">All</option>
                  {salesUserDatas?.map((option) => (
                    <option value={option.first_name}>{option.first_name}</option>
                  ))}
                </select>
              </div>
            </div>
      }
            <div className="col-2">
              <label className="create-label1">City</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  onChange={(e) => setFilterParameter({...filterParameter,city:e.target.value})}
                  value={filterParameter.city}
                >
                  <option value="All">All</option>
                  <option value="Any for Now">Any for Now</option>
                  {ci.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <label className="create-label1">Stage</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  value={filterParameter.subscriptionStage}
                  onChange={(e) => setFilterParameter({...filterParameter,subscriptionStage:e.target.value})}
            
                >
                  <option value={"All"}>All</option>
                  {StagesSubscription?.map((option) => (
                    <option value={option.value}>{option.name}</option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="col-2">
                      <label className="create-label1">Electrician</label>
                      <div class="input-group ">
                          <select class="custom-select height-select" 
                                 onChange={(e)=>filterOnElectrician(e.target.value)}
                                 defaultValue="all"
                                 >  
                                   <option value="all">All</option>
                                 <option value="empty">empty</option>
                                     {userName?.map((option) => (
                                         <option value={option.first_name}>{option.first_name}</option>
                                      ))}
                          </select>
                        </div>
                  </div> */}
          </div> 
      <nav aria-label="..." className='pagination-container'>
        <ul class="pagination">
          {
            [...Array(totalPage)].map((e, i) => {
              return (

                <li className={page === i + 1 ? "page-item active" : "page-item"} onClick={() => setPage(i + 1)}>
                  <a className="page-link" href="#" >{i + 1} </a>
                </li>
              )
            })
          }
        </ul>
      </nav>
      <div className='table-container1' style={{ marginTop: 0 ,height:'600px'}}>
        <table className="table table-sm table-bordered">
          <thead>
            <tr >
              <th>Sr. No.</th>
              <th scope="col" style={{ fontWeight: "bold" }}>Name</th>
              <th>Client Status</th>
              <th>Hex Code</th>
              <th>Address<span style={{ color: "red" }}>*</span></th>
              <th scope="col">City</th>
              <th scope="col" style={{ width: "110px" }}>Phone</th>
              <th scope="col">Comments</th>
              <th scope="col">Telecaller Name</th>
              <th>Second TeleCaller</th>
              <th scope="col">Selected Plan</th>
              <th scope="col">Appliances</th>
              {/* <th>Site Visited Appliances<span style={{ color: "red" }}>*</span></th> */}
              <th>Payment Status</th>
              <th style={{ color: "#FB2576" }}>Security</th>
              <th style={{ color: "#FB2576" }}>Installation</th>
              <th style={{ color: "#FB2576" }}>RecursiveAmount</th>
              <th style={{ color: "#0002A1" }}>Total Amount</th>
              <th>Gst Detail<span style={{ color: "red" }}>*</span></th>
              <th>Gst Name</th>
              <th>Gst Number</th>
              {/* <th style={{ width: "350px" }}>Action</th> */}
              {/* <th style={{ width: "280px" }}>Complete Payment</th> */}
              <th>Created At</th>
              <th>Mandate Date</th>
              {/* <th>Extra Subscription</th> */}
              {/* <th>Parent Subscription</th> */}
              {/* <th>Payment Carrier</th> */}
              {/* <th>Payment Status</th> */}
              <th>Installation Date</th>
              <th>Installation Done Date</th>
              {/* <th>Dispatched</th> */}
              <th style={{ width: "110px" }}>Devices</th>
              <th style={{ width: "110px" }}>subscriptionStage</th>
            </tr>
          </thead>
          <tbody>

            {
              (displayData !== 0 && occurnce.length !== 0) ?
                search(displayData).map((e, i) => {

                  return (
                    <tr key={i} style={{ backgroundColor: similarColor(e.phone) === true ? "#00800054" : "" }}>
                      <td>{(i + 1) + ((page - 1) * 30)}</td>
                      <td className="pr-15 pl-55" style={{ color: `${e.installed === true ? "green" : "black"}`, fontWeight: "bold" }}>{e.name}</td>
                      <td style={{ color: e.dropped === false ? "#008000" : "#FF0000" }}>{e.dropped === true ? "Dropped" : "Active"}</td>
                      <td style={{ color: `${e.partialPayment === true ? "orange" : "black"}` }}>{e.generatedHex}</td>
                      <AddressUpdate e={e} setReload={setReload} reloadW={reloadW} page={"paidOrder"} />
                      <td>{e.city}</td>
                      <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                      <CommentUdate e={e} setReload={setReload} reloadW={reloadW} page={"paidOrder"} />
                      <td>{e.teleCallerId}</td>
                      <td>{e.secondarySalesPerson === "empty" ? "" : e.secondarySalesPerson}</td>
                      <td>{e.planFrequency}</td>
                      <td>{e.totalAppliances}</td>
                      {/* <td><div className="hover-wide" contenteditable="false" onBlur={(ele) => updateSiteVisitedAppliances(ele.target.innerText, e)}>{e.siteVisitedAppliances}</div></td> */}
                      <td style={{ color: `${e.partialPayment === false ? "green" : (e.partialAmount === 0) ? "red" : "orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount === 0) ? "Zero" : `Partial`}</td>
                      <td style={{ color: "#FB2576" }}>{e.security}</td>
                      <td style={{ color: "#FB2576" }}>{e.installation}</td>
                      <td style={{ color: "#FB2576" }}>{e.recursiveAmount}</td>
                      <td style={{ color: "#0002A1" }}>{(e.totalAmount).toFixed(2)}</td>
                      <td onClick={() => {
                        setUpdateData(e)
                        setGstContainer(true)
                      }}>{e.isGstClient === true ? (`${e.gstName}  ${e.gstNumber}`) : "No"}</td>
                      <td >{e.gstName}</td>
                      <td >{e.gstNumber}</td>
                      {/* <td style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}>
                        <Link to="/pdf2" state={{ from: e, path: "Paid_Clients" }}>
                          <button className='btn btn-primary' type="button" >Layout</button>
                        </Link>
                        <button className='btn btn-primary' onClick={() => {
                          planNameSet(e)
                          setUpdateData(e)
                          setAddOnDisplay(true)
                        }}>Add-On
                        </button>
                        <button className="btn btn-primary" onClick={() => {
                          setUpdateData(e)
                          setGrivanceDisplay(!grivanceDisplay)
                        }}>Add Grevence
                        </button>

                      </td> */}
                      {/* <td>{e.partialPayment === true ? <PlanUpdate e={e} /> : ""}</td> */}


                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <td>{moment(e.mandateDate).format("MMM Do")}</td>
                      {/* <td><ul style={{
                        listStyle: "none",
                        padding: 0,
                      }}>
                        {e.extraSubscriptions?.map((el) => {
                          return (
                            <li>{el}</li>
                          )
                        })}</ul>
                      </td> */}
                      {/* <td>{e.extraSubscriptionTo === "empty" ? "" : e.extraSubscriptionTo}</td> */}
                      {/* <td>{e.paymentCarrier}</td> */}
                      {/* <td>{e.paymentStatus}</td> */}
                      <td>{moment(e.InstallationDate).format("MMM Do") !== "Invalid date" ? moment(e.InstallationDate).format("MMM Do YYYY") : ""}</td>
                      <td>{e.hasOwnProperty('installDoneDate')?(moment(e.installDoneDate).format("MMM Do") !== "Invalid date" ? moment(e.installDoneDate).format("MMM Do YYYY") : ""):""}</td>

                      {/* <td style={{ color: `${e.dispatched === false ? "green" : "red"}` }}>{e.dispatched === false ? "NO" : "YES"}</td> */}
                      <td><ul>{e.devices.map((ele) => {
                        return <li>{ele}</li>
                      })}</ul>
                      </td>
                      <td style={{ color: colorDecide(e.subscriptionStage) }}>{e.subscriptionStage}{e.grivience === true ? "  &  Grivience" : ""}</td>
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>


      {
        (addOnDisplay === true) ?
          <form className='form-update' onSubmit={handleSubmit}>
            <div className='cross1' onClick={() => setAddOnDisplay(false)}>x</div>
            <Grid container spacing={4} >
              <Grid item xs={12} md={6}>
                <label>Parent Hex</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="PHex" name="PHex" autoComplete="PHex" aria-label="PHex" aria-describedby="basic-addon1" />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Child Hex</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="CHex" name="CHex" autoComplete="current-CHex" aria-label="CHex" aria-describedby="basic-addon1" />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={2}>
                <button className='btn btn-primary' type="submit">Update</button>
              </Grid>
            </Grid>
          </form> : null
      }

      {
        grivanceDisplay === true ?

          <div className="greivance-container" style={{ top: "21% !important" }}>
            <span className="cross" onClick={() => setGrivanceDisplay(!grivanceDisplay)}>x</span>
            <div class="input-group mb-1">
              <input type="text" ref={grivance} placeholder="Enter Hex Code here..." defaultValue={update.generatedHex} />
            </div>
            <div class="input-group mb-1">
              <input type="date" ref={grivanceDate} />
            </div>
            <div class="input-group mb-1">
              <input type="text" ref={grivanceComment} placeholder="Enter Comment here..." defaultValue="" />
            </div>
            <div class="input-group mb-1" >
              <label>Problem Type</label>
              <select onChange={(e) => setGrivanceType(e.target.value)} defaultValue={GerivanceType} >
                <option value="WiFi">WiFi</option>
                <option value="Hanging">Hanging</option>
                <option value="Power">Power</option>
                <option value="Switch">Switch</option>
                <option value="Misc">Misc</option>

              </select>
            </div>
            <div class="input-group mb-1" >
              <label>If you want to send devices select Yes</label>
              <select onChange={(e) => setDeviceObject({ ...deviceObject, decision: e.target.value })} defaultValue="no">
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            {
              deviceObject?.decision == "yes" ?
                <div className="DeviceObjectDetails">
                  <div className="DeviceObjectDetails-specific">
                    <label >One Switch</label>
                    <input type="number" onWheel={event => event.currentTarget.blur()} onChange={(e) => setDeviceObject({ ...deviceObject, one: e.target.value })} />
                  </div>
                  <div className="DeviceObjectDetails-specific">
                    <label>Two Switch</label>
                    <input type="number" onWheel={event => event.currentTarget.blur()} onChange={(e) => setDeviceObject({ ...deviceObject, two: e.target.value })} />
                  </div>
                  <div className="DeviceObjectDetails-specific">
                    <label>Three Switch</label>
                    <input type="number" onWheel={event => event.currentTarget.blur()} onChange={(e) => setDeviceObject({ ...deviceObject, three: e.target.value })} />
                  </div>
                  <div className="DeviceObjectDetails-specific">
                    <label>4 + 1 Switch</label>
                    <input type="number" onWheel={event => event.currentTarget.blur()} onChange={(e) => setDeviceObject({ ...deviceObject, four: e.target.value })} />
                  </div>
                  <div className="DeviceObjectDetails-specific">
                    <label>Five Switch</label>
                    <input type="number" onWheel={event => event.currentTarget.blur()} onChange={(e) => setDeviceObject({ ...deviceObject, five: e.target.value })} />
                  </div>
                  <div className="DeviceObjectDetails-specific">
                    <label>IR</label>
                    <input type="number" onWheel={event => event.currentTarget.blur()} onChange={(e) => setDeviceObject({ ...deviceObject, ir: e.target.value })} />
                  </div>
                  <div className="DeviceObjectDetails-specific">
                    <label >Fan</label>
                    <input type="number" onWheel={event => event.currentTarget.blur()} onChange={(e) => setDeviceObject({ ...deviceObject, fan: e.target.value })} />
                  </div>
                </div> : null
            }
            <button className="btn btn-primary" onClick={() => addGrevianceClient(grivance.current.value, deviceObject, grivanceComment.current.value, GerivanceType, teleCallers, grivanceDate.current.value, notify, setReload, reloadW, setGrivanceDisplay, update)}>Greviane</button>
          </div> : null
      }
      {
        gstContainer === true ?
          <div className='update-form'>
            <span className='cross1' onClick={() => setGstContainer(false)}>X</span>
            <label className="create-label1">Gst Name</label>
            <div class="input-group ">
              <input type="text"
                class="form-control"
                ref={gstName}
                required
                aria-label="referenceId"
                aria-describedby="basic-addon1" />
            </div>

            <label className="create-label1">Gst Number</label>
            <div class="input-group ">
              <input type="text"
                class="form-control"
                ref={gstNumber}
                required
                aria-label="referenceId"
                aria-describedby="basic-addon1" />
            </div>

            <button className='btn btn-primary' style={{ marginTop: "15px" }} onClick={() => updateGstDetails(gstName.current.value, gstNumber.current.value, update)}>Update</button>

          </div> : null
      }
      <Qutoes />
    </div>
  )
}
