import { saveReduce,saveSwitchDetailReduce,switchboardRecordReducer,installationNotCompleteReducer} from "./reducer";
import { refundRecordReducer,refundPendingRecordReducer,refundCompleteRecordReducer } from "./refundReducer";
import {createStore,combineReducers} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import { salesUserReducer } from "./SalesUserReducer";
import { electricianListReducer, installationReducer } from "./installationReducer";
import { UpgradeRequestReducer,UpgradePendingRequestReducer } from "./UpgradeReducer";
import { adderessLayoutRed } from "./Reducer/addressLayoutReducer";
import { locationLogElectrician } from "./Reducer/locationLog";

const persistConfig = {
    key: 'persist-key',
    storage
}

const persistedReducer = persistReducer(persistConfig,combineReducers({
    sar: saveReduce,
    saveSwitch: saveSwitchDetailReduce,
    switchBoardsSave: switchboardRecordReducer,
    refudRecord:refundRecordReducer,
    salesUser:salesUserReducer,
    refundPendingRecord:refundPendingRecordReducer,
    refundCompleteRecord:refundCompleteRecordReducer,
    notInstalled:installationNotCompleteReducer,
    installationData:installationReducer,
    upgradeRequest:UpgradeRequestReducer,
    pendingUpgrade:UpgradePendingRequestReducer,
    addressLayout:adderessLayoutRed,
    electrician:electricianListReducer,
    electricianLocation:locationLogElectrician
}))

let store = createStore(persistedReducer);
let persistor = persistStore(store)
export default store;
export {persistor}