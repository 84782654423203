import axios from 'axios';
import React from 'react'
import { serverUrl } from '../ApiUrl';
import { useState } from 'react';
import { finalSelectDeviceUpdate } from '../Data';
import { useSelector } from 'react-redux';
import { subHistory } from '../Function/SubscriptionHistory';
import { changeInstallationDate } from '../ApiCalls/installationDate';
import { applyInitialState } from '@mui/x-data-grid/hooks/features/columns/gridColumnsUtils';
import { useDispatch } from 'react-redux';
import { removeAddressLayout } from '../Redux/Action/adderess&layout';

export default function FinalInstallationDone({data,notify}) {
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    const dispatch = useDispatch()
    let [intallDone,setInstallDone] = useState(false)
    let [websiteReload,SetWebsiteReload] = useState(false)
    const handleInstallDoneWithoutHouseId = async(event)=>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        let form = {
            deviceId:formData.get('deviceId'),
            type:formData.get('deviceType')
        }
        console.log(form,"form");
        axios.post(`${serverUrl.u}/api/dispatch/countTotalNumberOfDevice`, {
          generatedHex: data.generatedHex,
          deviceId: form.deviceId,
          type: form.type,
        }).then(function (res) {
          console.log(res);
          if (res.data.success === true) {
            // res.data.newAppliances
            if(res.data.newAppliances===0){
              notify(`Appliances difference is ${res.data.newAppliances}`)
  
               done()
            }else{
              notify(`Appliances difference is ${res.data.newAppliances}`)
            }
          }else(
            notify("Appliance difference have problem")
          )
        }).catch(function (err) {
          console.log(err);
          notify(err.response.data.message)
          notify("House Not Found")
        })
        const installDate = new Date();
        const done = async()=>{
          await axios.post(`${serverUrl.u}/api/dispatch/updateInstalledtwo`,{
            generatedHex:  data.generatedHex,
            deviceId:form.deviceId,
            type:form.type,
            firstTime:data.finalInstalled===false?true:false,
          }).then(function (res) {
            console.log(res,"done")
            if(res.data.success===true){
               setInstallDone(false)
               notify("Data Saved Successfully");
               if(data.finalInstalled===false){
                let newDate = new Date()
                subHistory('First Installation Complete',data,"First Installation Complete","")
              }
              dispatch(removeAddressLayout(data))
             changeInstallationDate(installDate,data,loginName,SetWebsiteReload,websiteReload,notify)
            }else{
              setInstallDone(true)
              console.log(res.data.message);
              notify(res.data.message);
            }
          }).catch(function (error) {
              console.log(error);
              notify(error.message);
          })
        }
        }
        const appliancesLeftPaymentInHouse = ()=>{
      
            axios.post(`${serverUrl.u}/api/tele/countApplianceInHouse`,{
                houseId:data.houseId,
                generatedHex: data.generatedHex
              }).then(function (res) {
                console.log(res);
                if(res.data.success===true){
                  
                  if(res.data.newAppliances===0){
                    handleInstallDoneWithHouseId()
                  }else{
                    notify("Appliance Payment is pending")
                  }
                
                }else{
                  notify(res.data.message)
                }
              }).catch(function (err) {
                  console.log(err);
                  notify("House Not found")
              })
          }
    const handleInstallDoneWithHouseId = async()=>{
            
            await axios.post(`${serverUrl.u}/api/dispatch/updateInstalledtwoHouse`,{
             generatedHex:data.generatedHex,
             houseId:data.houseId,
             firstTime:data.finalInstalled===false?true:false
            }).then(function (res) {
            
               SetWebsiteReload(!websiteReload)
               if(data.finalInstalled===false){
                 subHistory('First Installation Complete',data,"First Installation Complete","")
               }
               dispatch(removeAddressLayout(data))
            }).catch(function (err) {
             console.log(err);
            })
     }
  return (
    <>
      <button className='btn btn-primary' 
      onClick={()=>{
        
        if(data.houseId==""){
        setInstallDone(true)
        }else{
            appliancesLeftPaymentInHouse()
        }
        }}>Final Installation</button>
      {
      intallDone===true?
      <div style={{position:"fixed",top:"35%",left:"40%",width:"20%",backgroundColor:"whitesmoke",padding:"15px",borderRadius:"12px",zIndex:4}}>
        <span className="reson-cross" onClick={()=>setInstallDone(!intallDone)}>x</span>
      
        <div>
         <form onSubmit={handleInstallDoneWithoutHouseId}>   
        <label className="specific-label">Enter one Device Id</label>
        <div className="input-group">
                            <select className="custom-select height-select mb-2"
                            name='deviceType'
                            autoComplete='deviceType'
                            defaultValue={finalSelectDeviceUpdate[0].value}
                                >
                                     {finalSelectDeviceUpdate.map((option) => (
                                         <option value={option.value}>{option.value}</option>
                                      ))}
                                       
                          </select>
                  </div>
                  <div className="input-group mb-2">
                    <input type="text" 
                           name="deviceId"
                           className="form-control" 
                           required
                           aria-label="referenceId" 
                           autoComplete='current-deviceId'
                           aria-describedby="basic-addon1"  />
                  </div>
          <button className="btn btn-primary" type="submit">Done</button> 
          </form>       
        </div>
      </div>:null
    }
    </>
  )
}
