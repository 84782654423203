// Installation action

import { Upgrade } from "@mui/icons-material"
import { upgradeRequestApi } from "./UpgradeRequestAction"

export const Installation = {
    Installation_DATA:"INSTALLATION_DATA",
    UpdateInstallion_DATA:"UPDATE_DATA",
    Remove_DATA:"REMOVE_DATA",
    AVAILABLE_ELECTRICIAN:'AVAILABLE_ELECTRICIAN',
}

export const Invoices = {
    Invoices_DATA:"INVOICES_DATA"
}

export const addressLayout = {
     FETCH_DATA:"FETCH_DATA",
     UPDATE_DATA:"UPDATE_DATA",
     REMOVE_DATA:"REMOVE_DATA"
}
export const upgradeRequestAction={
    All_Request_Upgrade:"All_Request_Upgrade",
    All_Request_Empty_Upgrade:"All_Request_Empty_Upgrade",
    All_Request_Empty_Upgrade_All:"All_Request_Empty_Upgrade_All",
    All_Pending_Upgrade:"All_Pending_Upgrade",
    All_Pending_Empty_Upgrade:"All_Pending_Empty_Upgrade",
    All_Pending_Empty_Upgrade_All:"All_Pending_Empty_Upgrade_All"
}
export const locationTechniciansAction ={
    LOAD:'LOAD_LOCATION_LOG'
}