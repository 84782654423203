export function get3HoursInterval() {
  const intervals = [];
  const startHour = 0; // Start at midnight
  const endHour = 24; // End at midnight

  for (let i = startHour; i < endHour; i += 3) {
    let start = `${String(i).padStart(2, '0')}:00`;
    let end = `${String(i + 3).padStart(2, '0')}:00`;

    if (i + 3 === 24) {
      end = '24:00';
    } else if (i + 3 > 24) {
      end = '00:00';
    }

    intervals.push(`${start} - ${end}`);
  }
  return intervals;
}

export function addDaysToDate(date, daysToAdd) {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + daysToAdd);

  return newDate;
}

export function daysBetween(date1, date2) {
  // Parse the dates to ensure they are Date objects
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Calculate the difference in time (in milliseconds)
  const differenceInTime = d2 - d1;

  // Convert the time difference from milliseconds to days
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return Math.abs(differenceInDays); // Use Math.abs to ensure positive result
}
