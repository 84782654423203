export const colorDecide = (text) => {
    if (text === "dispatch") {
      return "rgb(251, 37, 118)"
    }
    if (text === "ready to install") {
      return "green"
    }
    if (text === "installed") {
      return "rgb(51, 47, 208)"
    }
  }

export const search = (data,q) => {
    if (data === undefined) return
    let newData = data.filter((e) => e.name.toUpperCase().includes(q.toUpperCase()))
    return newData
  }