import axios from "axios"
import { serverUrl } from "../../ApiUrl"
import { notifyFailed } from "../../Common/Notification"


export const siteStatusData = async()=>{
    return await axios.get(`${serverUrl.u}/api/elec/electricianStats`).then(
        function (res) {
            if(res.data.success===true){
                if(res.data.data.success===true){
                return res.data.data.data
                }else{
                    notifyFailed("Data Not Found")
                    return []
                }
            }else{
                notifyFailed("Data Not Found")
                return []
            }
        }
      ).catch(function (err) {
         notifyFailed("Failed")
         return []
      })
}