import React from 'react'
import axios from 'axios'
import { pages, serverUrl } from '../ApiUrl'
import { useDispatch } from 'react-redux'
import { updateInstallationData } from '../Redux/installationActions'
import { updateAddressLayout } from '../Redux/Action/adderess&layout'
import { subHistory } from './SubscriptionHistory'

export default function AddressUpdate({e,setReload,reloadW,page}) {
  let dispatch = useDispatch()
    const addressUpdate = async(address,data)=>{
        await axios.post(`${serverUrl.u}/api/tele/updateAddress`,{
          generatedHex :data.generatedHex,
          address:address
        }).then(function (res) {
          console.log(res);
          subHistory("Address Updated",e,"Address Updated","")
          if(page===pages.installation){
            dispatch(updateInstallationData(res.data.data.cart))
          }else if(page===pages.addressLayout){
            dispatch(updateAddressLayout(res.data.data.cart))
          }else{
            setReload(!reloadW)
          }
        }).catch(function (error) {
          console.log(error);
        })
      }
  return (
    <>
    <td label="Address">
    <div 
      className="hover-wide" 
      contentEditable="true" 
      suppressContentEditableWarning={true}
      onBlur={(ele)=>{addressUpdate(ele.target.innerText,e)}}
    >
    {e.address}
    </div>
    </td>
    </>
  )
}
