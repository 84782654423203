import React from 'react'
import axios from 'axios'
import { pages, serverUrl } from '../ApiUrl'
import { useDispatch } from 'react-redux'
import { updateAddressLayout } from '../Redux/Action/adderess&layout'
import { updateInstallationData } from '../Redux/installationActions'
import { notify } from '../Common/Notification'
import { subHistory } from './SubscriptionHistory'

export default function ChangeHyperLink({e,reloadW,setReload,page=""}) {
  const dispatch = useDispatch()
    const hyperLinkUpdate = async(link,data)=>{
        await axios.post(`${serverUrl.u}/api/subscription/updateHyperLink`,{
          subId: data._id,
          link:link
        }).then(function (res) {
          console.log(res);
        if(res.data.success===true){
          subHistory("Location Link Updated",data,"Location Link Updated","")
          if(page=pages.addressLayout){
            notify(res.data.message)
            dispatch(updateAddressLayout(res.data.data))
          }else if(page===pages.installation){
            notify(res.data.message)
            dispatch(updateInstallationData(res.data.data))
          }
          else{
            setReload(!reloadW)
          }
        }else{
          notify(res.data.message)
        }
          
        }).catch(function (error) {
          console.log(error);
          notify("Failed")
        })

      }
  return (
    <div className="hover-wide" contenteditable="true" onBlur={(ele)=>hyperLinkUpdate(ele.target.innerText,e)}>
        {e.addressHyperlink}
    </div>
  )
}
