import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { apiRequest } from "../../../utils";
import { serverUrl } from "../../../component/ApiUrl";
import { notify, notifyFailed } from "../../../component/Common/Notification";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function DeviceTable() {
  const [loading, setLoading] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [errors, setErrors] = useState({});
  const [houseData, setHouseData] = useState([]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (deviceId.length !== 7) {
      setErrors({
        ...errors,
        deviceId: "DeviceId must be 7 characters long",
      });

      return;
    }

    setLoading(true);

    const response = await apiRequest({
      url: `${serverUrl.web}/v3/house/deviceTableByDeviceId`,
      data: {
        deviceId,
      },
      method: "POST",
    });

    if (!response.success) {
      notifyFailed(response.message);
      setLoading(false);
      return;
    }

    notify("Successfully fetched the data :P");
    setHouseData(response.data?.data?.list);

    setLoading(false);
  }

  const columns = Object.keys(houseData[0] || {}).map((header) => {
    return {
      field: header,
      headerName: header[0].toUpperCase() + header.slice(1),
      width: 250,
    };
  });

  return (
    <div style={{ padding: "10px 20px", marginTop: 30 }}>
      <Box>
        <Paper sx={{ p: 4 }}>
          <Box>
            <form onSubmit={handleSubmit}>
              <TextField
                label="DeviceId"
                value={deviceId}
                name="deviceId"
                onChange={(e) => {
                  console.log(e.target);

                  if (e.target.value.length > 7) {
                    setErrors({
                      ...errors,
                      [e.target.name]: "DeviceId must be 7 characters long",
                    });
                  }

                  setDeviceId(e.target.value?.toUpperCase());
                }}
                autoFocus
                error={errors?.deviceId}
                helperText={errors?.deviceId}
                sx={{ mr: 4 }}
              />
              <Button
                variant="contained"
                type="submit"
                sx={{ minWidth: "100px" }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} size={28} />
                ) : (
                  "Fetch House"
                )}
              </Button>
            </form>
          </Box>
        </Paper>
      </Box>

      {/* Render Data */}
      <Box sx={{ mt: 5 }}>
        {loading ? (
          <Box>
            {/* <Skeleton height="50px" animation="wave" />
            <Skeleton height="400px" width="100%" /> */}
            <Skeleton height="50px" sx={{ transform: "unset", mb: 2 }} />
            <Skeleton height="500px" sx={{ transform: "unset" }} />
          </Box>
        ) : (
          <DataGrid
            columns={columns}
            rows={houseData.map((d, i) => ({ ...d, id: i + 1 }))}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30, 40]}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: `House_deviceIds`,
                },
              },
            }}
          />
        )}
      </Box>
    </div>
  );
}
