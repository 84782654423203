import React from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'

export const UpdateInvoiceComment = async(comment,data)=>{
    await axios.post(`${serverUrl.u}/api/tele/updateInvoiceComments`,{
        invoiceId:data._id,
        comments:comment
    }).then(function (res) {
      console.log(res);
    }).catch(function (error) {
      console.log(error);
    })
  }
export default function InvoiceCommentUpdate({e}) {
  
  return (
    <td><div className="hover-wide" contentEditable="true" onBlur={(ele)=>UpdateInvoiceComment(ele.target.innerText,e)} >{e.comments}</div></td>
  )
}
