import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import '../AssignElectrician.css'
import { updateInstallationData } from '../Redux/installationActions';
import { useDispatch } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import Button from '@mui/material/Button';
import { subHistory } from '../Function/SubscriptionHistory';
import { Stack } from '@mui/material';
import { user_roles } from '../Data';

export default function AssignAvailableElectricna({e,SetWebsiteReload,websiteReload,userName,notify,avilableElectrician}) {
    let userRole = useSelector((state) => state.sar.data?.user?.roles)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    let [disable,setDisable] = useState(false)
    let [displayDropDown,setDisplayDropDown] = useState(false) 
    let [confirmationDis, setConfirmationDis] = useState(false) 
    let [selectedValue,setSelectedValue] = useState([])
    // let [electrician,setElectrician] = useState([])
  
    let dispatch = useDispatch()  
    // const availableElectrician = async()=>{
    //     axios.post(`${serverUrl.u}/api/elec/getAvailableElectrication`,{
    //         InstallationDate:e.InstallationDate
    //     }).then(function (res) {
    //         // console.log(res);
    //         if(res.data.success){
    //             if(res.data.data.success){
    //                setElectrician(res.data.data.availableElectrician) 
    //             }else{
    //                 setElectrician([])
    //             }
    //         }else{
    //             setElectrician([])
    //         }
    //     }).catch(function (err) {
    //         console.log(err);
    //         setElectrician([])
    //     })
    // }
    // useEffect(()=>{
    //   if(displayDropDown){
    //     availableElectrician()
    //   }
    // },[displayDropDown])
      const assign = async()=>{
        setDisable(true)
        let electrician = []
        for(let i of selectedValue){
             electrician.push({"name":i.firstName,Id:i.employeeId})
        }      
        await axios.post(`${serverUrl.u}/api/dispatch/assignElectricianOnInstallationPage`,{
          generatedHex:e.generatedHex,
          electrician:electrician 
        }).then(function (response) {
        let detail=response;
        // console.log(detail);
        if(response.data.success===true){
          setDisplayDropDown(false)
          notify(response.data.message)
          dispatch(updateInstallationData(response.data.sub))
          subHistory("Electrician Assign From Installation Page",e,"Electrician Assign",JSON.stringify(electrician))
          setDisable(false)
        }else{
          notify("Error Occured")
          setDisable(false)
        }
        
      }).catch(function (error) {
        console.log(error);
        notify(error.response.data.message)
        setDisable(false)
      })
      setDisable(false)
    }
     
    return (
      <>
      
    
            {
                displayDropDown === false ? <td onClick={() => {if(userRole.includes(user_roles.AssignElectrician) || userRole.includes(user_roles
                  .SuperAdmin))setDisplayDropDown(true)}} label="Assigned Electritian *">
                  {Array.isArray(e.AssignedElectritian)?
                  
                  <ul>
                       {
                        e.AssignedElectritian && e.AssignedElectritian.map((name)=>{
                      
                          return(
                          <li>{(typeof name === 'object' && name !== null)?name.electricianName:name}</li>
                        )})
                       }
                  </ul>: e.AssignedElectritian}</td> : 
                <div className="input-group mb-3 assign"
                 
                >
                    
                    <Multiselect
                    displayValue="firstName"
                    onRemove={setSelectedValue}
                    onSelect={setSelectedValue}
                    options={avilableElectrician}
                    style={{
                        width: "70px !important",
                        multiselectContainer: {
                            marginTop: "3px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />
                {/* <select 
                  onChange={(val)=>{
                    let v = JSON.parse(val.target.value)
                    if(v.name!=="default"){
                      assign(v)
                    }
                  }}
                  className='form-control'
                  style={{width:'120px'}}
                >
                <option value={JSON.stringify([{"name":"default",Id:"default"}])}>Select Electrician</option>
                {
                  avilableElectrician.map((e)=>{
                    return(
                      <option value={JSON.stringify([{"name":e.firstName,Id:e.employeeId}])}>{e.firstName}</option>
                    )
                  })
                }

                </select> */}
                <Stack direction="row" gap={2}>
                <Button onClick={()=>assign()} sx={{mt:2}} variant="contained">Done</Button>
                <Button onClick={()=>setDisplayDropDown(false)} sx={{mt:2}} variant="contained">Close</Button>
                </Stack>
                </div>
            }
        
        </>
    )
}
