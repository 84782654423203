import axios from "axios";
import { notify, notifyFailed } from "../Common/Notification";
import { subHistory } from "../Function/SubscriptionHistory";
import { serverUrl } from "../ApiUrl";
import store from "../Redux";
import { updateAddressLayout } from "../Redux/Action/adderess&layout";
import Loading from "react-loading";
import { orderTyepes } from "../Data";
import moment from "moment";

export const updateDetails = async (data,update,dateChange,citys,setUpdateData,dispatch,updateAddressLayout,setUpdateDetail,userDetail,setUpdateInfoBtn) => {
    let payload = {
      subId: update._id,
      address: data.address,
      city: citys,
      zip: data.zipCode,
      alternatePhone: data.alternateNumber,
      name: data.client_name,
      comments: data.comment,
      priority: update.priority,
      datechanged: dateChange,
      date: data.date,
      zone: data.incentiveZone
    }
    await axios.post(`${serverUrl.u}/api/tele/updateConfirmedUserDetails`,payload).then(function (response) {
      if (response.data.success === true) {
        notify("Details Updated Successfully")
        setUpdateData([])
        dispatch(updateAddressLayout(response.data.data.cart))
        setUpdateDetail(false)
        if (update.InstallationDate == null) {
          subHistory("Installation date set first time", update, "Intallation date Change",data.date )
        }else{
          subHistory("Client Information Updated",{...update,payload:payload},"Client Information Update","")
        }
        
      } else {
        notifyFailed(response.data.message)
      }
      setUpdateInfoBtn("Update")
    })
      .catch(function (error) {
        console.log(error);
        notifyFailed(error.data.message)
        setUpdateInfoBtn("Update")
      })
  }

  export const interestOrNot = async(sub,setLoading,loading,site,intested) => {
    let val = loading
    setLoading(true)
      await axios.post(`${serverUrl.u}/api/tele/setInterestedOrNot`,{
        generatedHex:sub.generatedHex,
        categoryOfInterestedClient:site
      }).then((res)=>{
        console.log(res);
        if(res.data.success){
          store.dispatch(updateAddressLayout(res.data.subscription))
        }
        setLoading(false)
      }).catch((err)=>{
        console.log(err);
        setLoading(false)
      })

  }

  export const csvGenerateData = (data)=>{
    let csvData=[]
    console.log(data,"ffff");
    if(data===undefined) return []
    for(let d of data){
      csvData.push({
       "Type":d.orderType,
       "Name":d.name,
       "Hex":d.generatedHex,
       "Telecaller":d.teleCallerId,
       "Second Telecaller":d.secondarySalesPerson,
       'City':d.city,
       'Appliances':d.orderType !== orderTyepes[2].value?d.totalAppliances:'',
       "Admin Comment":(typeof d.headComments[0] === "object")?`${d.headComments[0].commentText} - ${moment.utc(d.headComments[0].createdAt).format("DD-MMM-YYYY")} - ${d.headComments[0].createdBy}`:d.headComments[0],
       "Comment":d.comments,
       "Electrician Comment":d.installerComments,
       "Selected Plan":d.planFrequency,
       "Address":d.address,
       "Location Link":d.addressHyperlink,
       "Phone":d.phone,
       "Payment Status":d.partialPayment === false ? "Paid" : (d.partialAmount === 0) ? "Zero" : "Partial",
       "Created Date":moment(d.createdAt).format("DD-MM-YYYY"),
       "Installation Date":moment.utc(d.InstallationDate).format('LT')!=="12:00 AM"?moment.utc(d.InstallationDate).format("llll"):moment.utc(d.InstallationDate).format("DD-MMM-yyyy"),
       "Site Visit":d.siteVisiteByElectrician === true ? "Site Visit Set" : "Not Set"
      })
    }
   return csvData
  }