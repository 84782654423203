export const INDIAN_STATES = {
    "Andhra Pradesh": { code: "AP", name: "Andhra Pradesh" },
    "Arunachal Pradesh": { code: "AR", name: "Arunachal Pradesh" },
    "Assam": { code: "AS", name: "Assam" },
    "Bihar": { code: "BR", name: "Bihar" },
    "Chhattisgarh": { code: "CG", name: "Chhattisgarh" },
    "Goa": { code: "GA", name: "Goa" },
    "Gujarat": { code: "GJ", name: "Gujarat" },
    "Haryana": { code: "HR", name: "Haryana" },
    "Himachal Pradesh": { code: "HP", name: "Himachal Pradesh" },
    "Jharkhand": { code: "JH", name: "Jharkhand" },
    "Karnataka": { code: "KA", name: "Karnataka" },
    "Kerala": { code: "KL", name: "Kerala" },
    "Madhya Pradesh": { code: "MP", name: "Madhya Pradesh" },
    "Maharashtra": { code: "MH", name: "Maharashtra" },
    "Manipur": { code: "MN", name: "Manipur" },
    "Meghalaya": { code: "ML", name: "Meghalaya" },
    "Mizoram": { code: "MZ", name: "Mizoram" },
    "Nagaland": { code: "NL", name: "Nagaland" },
    "Odisha": { code: "OD", name: "Odisha" },
    "Punjab": { code: "PB", name: "Punjab" },
    "Rajasthan": { code: "RJ", name: "Rajasthan" },
    "Sikkim": { code: "SK", name: "Sikkim" },
    "Tamil Nadu": { code: "TN", name: "Tamil Nadu" },
    "Telangana": { code: "TG", name: "Telangana" },
    "Tripura": { code: "TR", name: "Tripura" },
    "Uttar Pradesh": { code: "UP", name: "Uttar Pradesh" },
    "Uttarakhand": { code: "UK", name: "Uttarakhand" },
    "West Bengal": { code: "WB", name: "West Bengal" },
    "Andaman and Nicobar Islands": { code: "AN", name: "Andaman and Nicobar Islands" },
    "Chandigarh": { code: "CH", name: "Chandigarh" },
    "Dadra and Nagar Haveli and Daman and Diu": { code: "DN", name: "Dadra and Nagar Haveli and Daman and Diu" },
    "Lakshadweep": { code: "LD", name: "Lakshadweep" },
    "Delhi": { code: "DL", name: "Delhi" },
    "Puducherry": { code: "PY", name: "Puducherry" },
    "Ladakh": { code: "LA", name: "Ladakh" },
    "Jammu and Kashmir": { code: "JK", name: "Jammu and Kashmir" }
  };
  

  