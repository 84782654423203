import { Button } from '@mui/material'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import axios from 'axios'
import { notify, notifyFailed } from '../Common/Notification'
import { useDispatch } from 'react-redux'
import { updateInstallationData } from '../Redux/installationActions'

function PaymentLinkHeadOver({ sub }) {
    const dispatch = useDispatch(null)
    let [btn,setBtn] = useState(false)
    const paymentLinkSend = ()=>{
        setBtn(true)
       axios.post(`${serverUrl.u}/api/tele/updatePaymentIssueHandOver`,{
        generatedHex:sub.generatedHex
       }).then((res)=>{
           if(res.data.success){
            notify(res.data.message)
            // res.data.subscription
            dispatch(updateInstallationData(res.data.subscription))
           }else{
            notifyFailed(res.data.message)
           }
           setBtn(false)
       }).catch((err)=>{
           notifyFailed(err.response.data.message)
       })
    }
    return (
        <>
            {
                !sub.paymentRelatedHandOver ? <Button
                  variant='contained'
                  size='small'
                  onClick={()=>paymentLinkSend()}
                >
                        Payment Link Send
                    </Button> : <div>
                        Payment Link Sended
                    </div>
            }
        </>
    )
}

export default PaymentLinkHeadOver