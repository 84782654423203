import React, { useEffect, useState } from "react";
import axios from "axios";
import "../ConvertedLead.css";
import "../DispatchData.css";
import moment from "moment/moment";
import '../Installation.css'
import Qutoes from "../Qutoes";
import {serverUrl} from '../ApiUrl'
import { useRef } from "react";
import { useSelector } from "react-redux";
import { salesUserData } from '../Function/AxiosControl'
import { state } from '../Data'
import AssignElectrician from "./AssignElectrician";
import { InstallationUser } from "../Function/AxiosControl";
import { finalSelectDeviceUpdate } from "../Data";
import { CSVLink } from "react-csv";
import SearchDataWithStage from "../Function/SearchDataWithStage";
import { subHistory } from "../Function/SubscriptionHistory";
import { notify } from "../Common/Notification";
export default function Installed({wholeReload}) {
  let [details, setDetails] = useState([]);
  let [displayData,setDisplayData] = useState([])
  let [salesUserDatas,setSalesUserDatas] = useState()
  let [userName, setUserName] = useState()
  let [page,setPage] = useState(1)
  let [totalPage,setTotalPage] = useState(1)
  let token = useSelector((state)=>state.sar.data.token)
  let [update,setUpdate] = useState();
  let [csvBtnText,setCsvBtnText] = useState(false)
  let deviceIds = useRef(null);
  let typeOfDevice = useRef(null)
  let [q, setq] = useState("");
  let [reload,SetReload] = useState(false);
  let [finalInstalledStatus,SetFinalInstalledStatus] = useState(false)
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let userRole =useSelector((state)=>state.sar.data.user.roles)
  const [searchParam] = useState(["name"]);
  let [csv,setCsv] = useState([])
  useEffect(() => {
    let salesUser;
    let data = async () => {
      await axios
        .get(
          `${serverUrl.u}/api/tele/getAllInstalledd/?p=${page-1}`
        )
        .then(function (response) {
          let detail = response.data.data;
          setTotalPage(response.data.totalPage)
          setDetails(response.data.data);
          setDisplayData(detail)
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    data();
    const sale = async()=>{
      salesUser =await salesUserData(token);
      setSalesUserDatas(salesUser);
      let installUser=await InstallationUser(token);
      setUserName(installUser)
      }
      sale();
  }, [reload,page]);


let dataCsvss = async () => {
  let dataCsv=[]
  await axios
    .post(
      `${serverUrl.u}/api/tele/getAllInstalled`
    )
    .then(function (response) {
      let detail = response.data.data;
      for (const e of detail){
        if(e.subscriptionStage==="installed & complete" || e.subscriptionStage === "installed"){
      dataCsv.push({
        "Client Name":e.name,
        "Address":e.address,
        "City":e.city,
        "TeleCaller":e.teleCallerId,
        "Hex Code":e.generatedHex,
        "Plan Frequency":e.planFrequency,
        "Payment Status":e.partialPayment === false ?( "Paid" ):( (e.partialAmount===0)?"Zero":"Partial"),
        "Electrician":Array.isArray(e.AssignedElectritian)?
                  
        <ul>
             {
              e.AssignedElectritian && e.AssignedElectritian.map((name)=>{
                console.log(name,"j");
                return(
                <li>{(typeof name === 'object' && name !== null)?name.electricianName:name}</li>
              )})
             }
        </ul>: e.AssignedElectritian,
        "Installation Date":moment(e.InstallationDate).format("MMM Do LT YYYY"),
        "New Device Ids":e.devices,
        "Old Device":e.oldConfiguration,
       "Stage":e.subscriptionStage,
       "Installed":e.installed===true?"Yes":"No",
      })
    }
    }
    setCsv(dataCsv);
      console.log(detail);
      setCsvBtnText(false)
    })
    .catch(function (error) {
      console.log(error);
      setCsvBtnText(false)
    });
};
const finalInstalled = async(data,device,type)=>{
  console.log(data);
      await axios.post(`${serverUrl.u}/api/dispatch/updateInstalledtwo`,{
        generatedHex:  data.generatedHex,
        deviceId:device,
        type:type,
        firstTime:data.finalInstalled===false?true:false,
      }).then(function (res) {
        console.log(res)
        if(res.data.success===true){
           notify("Data Saved Successfully");
           SetFinalInstalledStatus(false)
           if(data.finalInstalled===false){
            subHistory('First Installation Complete',data,"First Installation Complete","")
          }
           SetReload(!reload);
        }else{
          notify(res.data.message);
          SetFinalInstalledStatus(false)
          SetReload(!reload);
        }
      }).catch(function (error) {
          console.log(error);
          notify(error.data.message);
      })
}

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
 
 const ActivateHouse = async(id,e)=>{
    await axios.post(`${serverUrl.u}/v2/house/activate`,{
      houseId:id,
    }).then(function (res) {
      console.log(res);
      subHistory("House Activated Installed",e,"House Active/Deactive","House Activated Installed Page")
      notify(res.data.message)
      
      SetReload(!reload)
    }).catch(function (err) {
      console.log(err);
      notify("Error")
    })
 }
 
 const UpdateDevices = async(id)=>{
  await axios.post(`${serverUrl.u}/api/tele/updateDevices`,{
    houseId:id,
  }).then(function (res) {
    console.log(res);
    notify(res.data.message)
    SetReload(!reload);
  }).catch(function (err) {
    console.log(err);
    notify("Error")
  })
}
 const DectivateHouse = async(id,e)=>{
  await axios.post(`${serverUrl.u}/v2/house/deactivate`,{
    houseId:id,
  }).then(function (res) {
    console.log(res);
    notify(res.data.message)
    subHistory("House Deactivated Installed",e,"House Active/Deactive","House Deactivated Installed Page")
    SetReload(!reload);
  }).catch(function (err) {
    console.log(err);
    notify("Error")
  })
}

const filterOnTelecaller = (name)=>{
  if(name==="all"){
    setDisplayData(details)
  }else{
    let data = details.filter((e)=>e.teleCallerId==name)
    setDisplayData(data);
  }
}
const filterOnCity = (name)=>{
  if(name==="all"){
    setDisplayData(details)
  }else{
    let data = details.filter((e)=>e.city==name)
    setDisplayData(data);
  }
}
const filterOnElectrician = (name)=>{
  if(name==="all"){
    setDisplayData(details)
  }else{
    console.log(name,"name");
    let data = details.filter((e)=>e.AssignedElectritian==name)
    console.log("e",data);
    setDisplayData(data);
  }
}

  return (
    <div >
      
      <div className="input-field">
      <SearchDataWithStage setDisplayData={setDisplayData} stage={"installed"}/>
      </div>
      
            <div className='row' style={{marginLeft:"0.7vw"}}>
                  <div className="col-2">
                      <label className="create-label1">TeleCaller</label>
                      <div class="input-group ">
                          <select class="custom-select height-select" 
                                 onChange={(e)=>filterOnTelecaller(e.target.value)}
                                 defaultValue="all"
                                 >  
                                 <option value="all">All</option>
                                     {salesUserDatas?.map((option) => (
                                         <option value={option.first_name}>{option.first_name}</option>
                                      ))}
                          </select>
                        </div>
                  </div>
                  <div className="col-2">
                      <label className="create-label1">City</label>
                      <div class="input-group ">
                          <select class="custom-select height-select" 
                                 onChange={(e)=>filterOnCity(e.target.value)}
                                 defaultValue="all"
                                 >  
                                   <option value="all">All</option>
                                 <option value="Any for Now">Any for Now</option>
                                     {state?.map((option) => (
                                         <option value={option.value}>{option.value}</option>
                                      ))}
                          </select>
                        </div>
                  </div>
                  <div className="col-2">
                      <label className="create-label1">Electrician</label>
                      <div class="input-group ">
                          <select class="custom-select height-select" 
                                 onChange={(e)=>filterOnElectrician(e.target.value)}
                                 defaultValue="all"
                                 >  
                                   <option value="all">All</option>
                                 <option value="empty">empty</option>
                                     {userName?.map((option) => (
                                         <option value={option.first_name}>{option.first_name}</option>
                                      ))}
                          </select>
                        </div>
                  </div>
                  {
        csv.length!==0?
                  <div className="col-2">
                      <label className="create-label1">CSV Sheet</label>
                      <div class="input-group ">
                      <CSVLink className='btn btn-primary' filename={`Installed.csv`} style={{ marginLeft: "2%"}} data={csv}>Download CSV</CSVLink>
                        </div>
                  </div>
                   :<div className="col-2">
                   <label className="create-label1">Create CSV Sheet</label>
                   <div class="input-group ">
                    {
                      csvBtnText===false?
                      <button className="btn btn-primary" onClick={()=>{dataCsvss()
                      setCsvBtnText(true)}}>Create Csv</button>
                     :<button className="btn btn-secondary" >Proccessing...</button>
                    }
                     </div>
               </div>
                  }
            </div>
      
    
  
  
        <nav aria-label="..." className='pagination-container'>
      <ul class="pagination">
        {
          [...Array(totalPage)].map((e,i)=>{
              return(
                
                <li className={page===i+1?"page-item active":"page-item"} onClick={()=>setPage(i+1)}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
</nav>
      <div className='table-container1' style={{marginTop:0}}>
      <table className="table table-sm  ">
        <thead>
          <tr>
          <th>Sr. No.</th>
            <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
            <th>TeleCaller Name</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th>Electritian</th>
            <th scope="col" style={{width:"120px"}}>Phone</th>
            <th scope="col">Created At</th>
            <th>Installation Date</th>
            <th scope="col">Devices</th>
            <th>Old Device</th>
            <th>Final Installed</th>
            {
              userRole?.includes("Super Admin")?
              <th>Action</th>:null
            }
          <th style={{width:"180px"}}>House Id</th>
          <th>Active Status</th>
            <th style={{width:"150px"}}>Activate/Deactivate</th>
            <th style={{width:"150px"}}>Update Devices</th>
            
        
          </tr>
        </thead>
        <tbody>
          {displayData !== 0
            ? search(displayData).map((e, i) => {
                return (
                  <tr key={i} style={{marginBottom:5}}>
                    <td>{(i+1)+((page-1)*30)}</td>
                    <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                    <td>{e.teleCallerId}</td>
                    <td label="Address"><div className="hover-wide">{e.address}</div></td>
                    <td label="City">{e.city}</td>
                    <AssignElectrician e={e} SetWebsiteReload={SetReload} websiteReload={reload} userName={userName} />
                    <td label="Phone"><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                    <td label="Date">{moment(e.createdAt).format("MMM Do LT")}</td>
                    <td label="Date">{moment(e.InstallationDate).format("MMM Do LT")}</td>
                    <td label="Devices"><div className="hover-wide"><ul style={{paddingLeft:"5px"}}>{e.devices.map((ele)=>{
                      return <li>{ele}</li>
             })}</ul></div></td>
                    <td><div className="hover-wide"><ul style={{paddingLeft:"5px"}}>{
                      e.oldConfiguration.map((ele)=>{
                        return <li>{ele}</li>
                      })
            }</ul></div></td>
                    <td style={{color:e.finalInstalled?"green":"red"}}>{e.finalInstalled?"Yes":"No"}</td>
                    
                    <td label="Action"><button className="btn btn-primary"  onClick={()=>{setUpdate(e)
                                                                           SetFinalInstalledStatus(true)}}>Final</button></td>
                    
                    <td label="House Id">{e.houseId}</td>
                    <td>{e.active===true?"Yes":"No"}</td>
                    {
                      e.houseId!==""?
                      <>
                      { 
                      e.active===false ?
                      <td><button className="btn btn-primary" onClick={()=>ActivateHouse(e.houseId,e)}>Activate</button></td>:
                      <td><button className="btn btn-primary" onClick={()=>DectivateHouse(e.houseId,e)}>Deactivate</button></td>
                      }
                      </>:<td></td>
                    }
                     {
                      e.houseId!==""?
                     
                      <td><button className="btn btn-primary" onClick={()=>UpdateDevices(e.houseId)}>Update Devices</button></td>            
                      :<td></td>
                    }
                   
                    {/* <td label="Faulty Device"><ul>{e.faultyDevices.map((ele)=>{
                      
                      return <li>{ele}</li>
                      
             })}</ul></td>
            
              <td label="New Device"><ul>{e.newDevices.map((ele)=>{
                      return <li>{ele}</li>
             })}</ul></td> */}
                    
                   
                    
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
      {
        finalInstalledStatus===true?
        <div className="Installed-final-popup">
          <span className="cross" onClick={()=>SetFinalInstalledStatus(false)}>X</span>
          <label>Device Id</label>
          <div class="input-group ">
          <select class="custom-select height-select mb-2" 
          ref={typeOfDevice} 
          defaultValue={finalSelectDeviceUpdate[0].value}
                                >
                                     {finalSelectDeviceUpdate.map((option) => (
                                         <option value={option.value}>{option.value}</option>
                                      ))}
                                       
                          </select>
                          </div>
                          <div className="input-group mb-3">
                <input type="text" 
                       class="form-control" 
                       id="deviceId" 
                       name="deviceId" 
                       autoComplete="current-deviceId" 
                       placeholder="Device Id" 
                       aria-label="deviceId" 
                       ref={deviceIds}
                       aria-describedby="basic-addon1" />
              </div>
              
              <button className="btn btn-primary" onClick={()=>finalInstalled(update,deviceIds.current.value,typeOfDevice.current.value)}>Done</button>
        </div>:null
      }
      
    <Qutoes/>
    </div>
  );
}
