import axios from 'axios'
import React from 'react'
import { pages, serverUrl } from '../ApiUrl'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { ElectricianIncentiveZone } from '../Data';
import { useDispatch } from 'react-redux';
import { updateInstallationData } from '../Redux/installationActions';
import { notify } from '../Common/Notification';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:"12px"
  };

function SubscriptionZoneUpdate({subscription,setReload,reload,page=""}) {
    let [zone,setZone] = useState(subscription.zone===""?"Select Zone":subscription.zone)
    let [btn,setBtn] = useState("Save Change")
     const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    const updateZone = ()=>{
        setBtn("Processing...")
        if(zone==="Select Zone" || zone===""){
            notify("Zone Not Selected")
            return
        }
        axios.post(`${serverUrl.u}/api/dispatch/assignZone`,{
            generatedHex:subscription.generatedHex,
            zone:zone
        }).then(function (res) {
            if(res.data.success === true){
                notify(res.data.message)
                handleClose()
                if(pages.installation===page){
                  dispatch(updateInstallationData(res.data.data))
                }else{
                setReload(!reload)
                }
            }else{
                notify(res.data.message)
            }
            setBtn("Save Change")
        }).catch(function (error) {
            notify("Failed")
            setBtn("Failed")
        })
    }
  return (
    <div>
    <Button variant='contained' sx={{width:"100%"}} onClick={handleOpen}>Update Zone</Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Zone
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <div className='row'>
          <div className="col-12">
                    <label className="create-label1">Subscription Zone <span className="required_star">*</span></label>
                    <div class="input-group mb-1">
                      <select class="custom-select height-select" required id="inputGroupSelect01"
                        name="incentiveZone"
                        label="incentiveZone"
                        autoComplete="current-incentiveZone"
                        defaultValue={subscription.zone===""?"Select Zone":subscription.zone}
                        onChange={(e)=>setZone(e.target.value)}
                        >
                          <option value={"Select Zone"}>Select Zone</option>
                        {ElectricianIncentiveZone.map((option) => (
                          <option value={option.value}>{option.value}</option>
                        ))}
                      </select>
                    </div>
                  </div>
          </div>
        </Typography>
        <Typography id="modal-modal-footer" sx={{ mt: 2 ,display:"flex"}}>
            <Button variant='contained' sx={{mr:2}} onClick={handleClose}>No</Button>
            <Button variant='contained' sx={{mr:2}} onClick={()=>updateZone()} style={{pointerEvents:btn==="Processing..."?"none":""}}>Save Change</Button>
        </Typography>
      </Box>
    </Modal>
  </div>
  )
}

export default SubscriptionZoneUpdate