import {
  Alert,
  Button,
  IconButton,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { getUserOtpApi } from "../api";
import { notifyFailed } from "../../../component/Common/Notification";
import { useSelector } from "react-redux";
import { ContentCopy } from "@mui/icons-material";

export default function UserLoginOtp() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const employeeCode = useSelector((state) => state.sar.data?.user?.employeeId);

  const hadleFetchOtp = async (e) => {
    console.log("HRERE");
    e.preventDefault();
    if (phoneNumber.length !== 10) {
      notifyFailed("PhoneNumber must be 10 characters long!!");
      return;
    }

    setLoading(true);

    const resp = await getUserOtpApi({
      phoneNumber,
      employeeCode,
    });
    console.log(resp, "- resp");
    setText(resp.data.otp.otp);
    if (!resp.success) {
      notifyFailed(resp.error);
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbarMessage("Text copied to clipboard!");
    } catch (error) {
      setSnackbarMessage("Failed to copy text.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="user-login-otp">
      <div className="container">
        <div className="input">
          <form onSubmit={hadleFetchOtp}>
            <Stack direction="row" spacing={2}>
              <TextField
                label="User PhoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button variant="contained" type="submit">
                Get OTP
              </Button>

              {text && (
                <>
                  <input
                    type="text"
                    label="Text to Copy"
                    readOnly
                    className="form-control"
                    // fullWidth
                    value={text}
                    style={{
                      marginBottom: "10px",
                      width: "200px",
                      paddingLeft: "50px",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    // startIcon={ContentCopy}
                    onClick={handleCopyClick}
                  >
                    Copy OTP
                  </Button>
                </>
              )}
            </Stack>
          </form>
        </div>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          action={
            <IconButton
              size="small"
              arai-label="close"
              onClick={handleSnackbarClose}
            >
              <span>&times;</span>
            </IconButton>
          }
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage.startsWith("Failed") ? "error" : "success"
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
