import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { serverUrl } from "../ApiUrl";
import Multiselect from "multiselect-react-dropdown";
import { notify, notifyFailed } from "../Common/Notification";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const s = {
  paddingVertical: {
    padding: "0 10px"
  },
  paddingHorizontal: {
    padding: "10px 0" 
  }
}
export default function NotificationModal({ open, setOpen, data, fetchDetails}) {

  const [d, setD] = React.useState({})
  const [update, setUpdate] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [body, setBody] = React.useState("");
  const [pt, setPt] = React.useState([]);
  const [days, setDays] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState([]);

  React.useEffect(() => {
    if (Object.keys(data).length <= 0) {
      setUpdate(false);
      return;
    }

    setD(data);
    setUpdate(true);
    setTitle(data.title);
    setBody(data.body);
    setPt(data.preferedTime)
    setDays(data.days);

    
    const days = data.days.map(d => ({value: d}))
    
    setSelectedValue(days);
    const pt = data.preferedTime.map(t => ({value: t}));
    setPt(pt);


  }, [data]);


  const daysOption = [
    {
      value: "SUNDAY"
    },
    {
      value: "MONDAY"
    },
    {
      value: "TUESDAY"
    },
    {
      value: "WEDNESDAY"
    },
    {
      value: "THURSDAY",
    },
    {
      value: "FRIDAY",
    },
    {
      value: "SATURDAY",
    },
  ];

  const ptOptions = [
    {
      value: "MORNING",
    },
    {
      value: "EVENING",
    },
    {
      value: "NIGHT"
    }
  ];

  const handleClose = () => setOpen(false);
  React.useEffect(() => {}, [open]);

  const addNew = () => {
    if (title === "" || body === "" || pt === "" || selectedValue === "") {
      notifyFailed("Fill in the complete Details");
      return
    }

    const daysCreated = selectedValue.map(v => v.value);

    const ptt = pt.map(v => v.value);
    
    axios.post(`${serverUrl.u}/notification/addNotification`, {
      title,
      body,
      preferedTime: ptt,
      days: daysCreated,
    })
      .then((res) => {
        setOpen(false);
        fetchDetails();
        notify(res?.data?.message);

        // Update state to normal
        setD({});
        setUpdate(false);
        setTitle("");
        setBody("");
        setPt([]);
        setSelectedValue([])
      })
      .catch((err) => {
        notifyFailed("Couldn't Add Notification");
        setOpen(false);
      });
  };

  const updateNotification = () => {
    if (title === "" || body === "" || pt === "" || selectedValue === "") {
      notifyFailed("Fill in the complete Details");
      return;
    }

    const daysCreated = selectedValue.map((v) => v.value);
    const ptt = pt.map((v) => v.value);

    axios.post(`${serverUrl.u}/notification/updateNotification`, {
      id: data._id,
      title,
      body,
      preferedTime: ptt,
      days: daysCreated,
    }).then((res) => {
      notify(res?.data?.message)
      setOpen(false);
      fetchDetails();

      // Make state empty
      setD({});
      setUpdate(false);
      setTitle("");
      setBody("");
      setPt([]);
      setSelectedValue([]);
    }).catch(err => {
      setOpen(false);
      notifyFailed("Couldn't Update Notification")
    }
      )
  }

  const clearState = () => {
    setD({});
    setUpdate(false);
    setTitle("");
    setBody("");
    setPt([]);
    setSelectedValue([]);
    setOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {update ? "Update Notification" : "Add Notification"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div
              className="row"
              style={{ flexDirection: "column", marginBottom: "10px" }}
            >
              <label style={{ marginRight: "10px" }}>Title</label>
              <input
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </div>
            <div
              className="row"
              style={{ flexDirection: "column", marginBottom: "10px" }}
            >
              <label>Body</label>
              <textarea
                type="text"
                onChange={(e) => setBody(e.target.value)}
                value={body}
              ></textarea>
            </div>
            <div
              className="row"
              style={{ flexDirection: "column", marginBottom: "10px" }}
            >
              <label>PrefferedTime</label>
              <Multiselect
                displayValue="value"
                onRemove={setPt}
                onSelect={setPt}
                options={ptOptions}
                selectedValues={pt}
                style={{
                  width: "70px !important",
                  multiselectContainer: {
                    marginTop: "3px",
                    backgroundColor: "#ffffff",
                  },
                }}
              />
            </div>
            <div
              className="row"
              style={{ flexDirection: "column", marginBottom: "10px" }}
            >
              <div className="col-2">
                <label>Days</label>
              </div>
              <Multiselect
                displayValue="value"
                onRemove={setSelectedValue}
                onSelect={setSelectedValue}
                options={daysOption}
                selectedValues={selectedValue}
                style={{
                  width: "70px !important",
                  multiselectContainer: {
                    marginTop: "3px",
                    backgroundColor: "#ffffff",
                  },
                }}
              />
            </div>
          </Typography>
          {update ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button onClick={updateNotification}>Update</Button>
              <Button variant="contained" color="error" onClick={clearState}>
                Cancel
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button onClick={addNew}>Add New</Button>
              <Button variant="contained" color="error" onClick={clearState}>
                Cancel
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
