import axios from "axios";
import { serverUrl } from "../ApiUrl";

const getAllUpsellItems = async () => {
  try {
    const response = await axios.get(`${serverUrl.u}/v3/items/fetch`);

    console.log("RESPONSE Data - ", response.data);

    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    console.log("getALlupsellItems error- ", error);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range 2xx

      console.error("Error response data - ", error.response.data);
      console.error("Error Response Status - ", error.response.status);
      console.error("Error Response Headers - ", error.response.headers);
      console.log("Error Message - ", error.message);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error Request - ", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error Message - ", error.message);
    }

    return {
      succes: false,
      error,
      data: [],
    };
  }
};

const updateUpsellItems = async (payload) => {
  try {
    const response = await axios.post(
      `${serverUrl.u}/v3/items/update`,
      payload
    );

    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range 2xx

      console.error("Error response data - ", error.response.data);
      console.error("Error Response Status - ", error.response.status);
      console.error("Error Response Headers - ", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error Request - ", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error Message - ", error.message);
    }

    return {
      success: false,
      error,
      data: [],
    };
  }
};

const createUpsellItems = async (payload) => {
  try {
    const response = await axios.post(
      `${serverUrl.u}/v3/items/create`,
      payload
    );

    if (!response?.data?.success) {
      return {
        success: false,
        data: {},
        message: response.data?.message || "Something Went Wrong!",
      };
    }

    return {
      success: true,
      data: response.data,
      message: response.data?.message || "Successfully Created Item!",
    };
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        data: {},
        message:
          error.response.data.message ||
          error.response.data.status ||
          "Something Went Wrong!",
      };
    }
    return {
      success: false,
      data: {},
      message: error.message || "Something Went Wrong!",
    };
  }
};

const UpsellApis = {
  getAllUpsellItems,
  updateUpsellItems,
};

export default UpsellApis;
