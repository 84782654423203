
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { pages, serverUrl } from '../ApiUrl';
import { Button, Grid, Stack } from '@mui/material';
import { planCost, planDuration } from '../Data';
import ReactWhatsapp from "react-whatsapp";
import { Mp, WhatsApp } from "@mui/icons-material";
import './PlanUpdate.css'

import { updateZohoLink, updateRazorpayLink } from '../ApiCalls/PaymentLinkApi';
import { subHistory } from './SubscriptionHistory';
import { useSelector } from 'react-redux';
import ConfirmationForPaymentLink from '../Model/ConfirmationForPaymentLink';
import { notify, notifyFailed } from '../Common/Notification';
import OverdueInvoiceSelect from '../Common/OverdueInvoiceSelect';
import moment from 'moment';
import { CheckClientIsOverdue } from '../SearchClient/Api';
import HisabForZohoRazorPay from '../SearchClient/HisabForZohoRazorPay';
import { countApplianceInHouse } from '../Isp/ClientRegister/function';

export default function PlanUpdate({e}) {
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let [zohoPop, setZohoPop] = useState(false)
    let [amount,setAmount] = useState(0) // invoice
    let [zohoInvoice,setZohoInvoices] = useState([])
  let [planName, setPlanName] = useState();
    let [collectoverdue,setCollectoverdue] = useState(true)
  let [reloadLink, setReloadLink] = useState(false);
  let [paymentLink, setPaymentLink] = useState("");
  let [apps, setApp] = useState(e.totalAppliances)
  let [hisabDetail,setHisabDetail] = useState({})
  let [unpaidAappliance,setUnpaidAappliance] = useState({})
  let [overdue,setOverdue] = useState(false)
  let [btn, setBtn] = useState("Update");
  let [okBtn, setokBtn] = useState("");
  const [text, setText] = useState("");
  let [name, setName] = useState("")
  let [whatappNumber, setWhatappNumber] = useState();
  let [coastperSecurityApplinaces,setCoastperSecurityApplinaces] = useState(100)
  let [newAppliances,setNewAppliances] = useState(e.totalAppliances)
  let [secIns, setSecIns] = useState({
    security: e.currentSecurity,
    installation: e.currentInstallation,
  });
  useEffect(() => {
    setText("")
    planNameSet();
  }, [e])
  useEffect(()=>{
    
    const check = async()=>{
      let res =  await CheckClientIsOverdue(e.generatedHex)
         setOverdue(res)
      }
      check()
  },[e,zohoPop])
 
  
  const planNameSet = () => {

    switch (e.planId) {
      case 0:
        setPlanName("Custom");
        break;
      case 1:
        setPlanName("1 Room");
        break;
      case 2:
        setPlanName("1 BHK");
        break;
      case 3:
        setPlanName("2 BHK");
        break;
      case 4:
        setPlanName("3 BHK");
        break;
      case 5:
        setPlanName("4 BHK");
        break;
      case 6:
        setPlanName("5 BHK");
        break;
      case 7:
        setPlanName("6 BHK");
        break;
    }
  };


  const handleApplianceChange = (val,coastperSecurit) => {
    val = Number(val);
    let secu = val * coastperSecurit;
    let inst;
    if (val > 10) {
      let cal = val - 10;
      inst = 1000 + cal * 50;
    } else {
      inst = 1000;
    }
    setSecIns({
      security: secu,
      installation: inst,
    });
  };

  const handleSubmit = async (event) => {
    setText("")
    event.preventDefault();
    setBtn("Processing..")
    const data = new FormData(event.currentTarget);
    setReloadLink(false);
    let userData2 = {
      name: data.get("name"),
      phone: data.get("phone"),
      city: data.get("city"),
      address: data.get("address"),
      planType: data.get("planDuration"),
      plan: data.get("planName"),
      security: data.get("security"),
      installion: data.get("installion"),
      quantityAppliances: data.get("appliances"),
      partialAmount: data.get("partial"),
      zohoSecurity: Number(data.get("security")) - Number(data.get("partial")),
      creditNote: data.get("creditNote"),
      overdueCollect: data.get("overdueCollect"),
      postPayment:(data.get('postPayment')===null || data.get('postPayment')===undefined)?false:(data.get('postPayment')==="true"?true:false)
    };
    let oldAppliance = Number(e.totalAppliances) - (e.currentSecurity / coastperSecurityApplinaces)
    let qu = 0
      qu = newAppliances;
  
    let fee = 0;
    let planDurations = userData2.planType;
    let createdDate = e.createdAt;

    await axios.post(`${serverUrl.u}/api/tele/getFirstRegistrationDate`, {
      phone: e.phone
    }).then(function (res) {
      console.log(res.data.data);
      createdDate = res.data.data
    }).catch(function (err) {
      console.log(err);
    })
    if (planDurations === "Monthly") {
      if (new Date(createdDate) > new Date('2023-07-25T00:00:00')) {
        fee = (qu * planCost.Monthly) * 30
      } else {
        fee = (qu * 30)
      }
    } else if (planDurations === "Quarterly") {
      fee = (qu * planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
      fee = (qu * planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
      fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
      fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
      fee = (qu * planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
      fee = (qu * planCost.TwoYears) * (365 * 2);
    } else if (planDurations === "Three Years") {
      fee = (qu * planCost.ThreeYears) * (365 * 3);
    }
    
    let totalAmount = Number((fee + Number(userData2.installion)) * 1.18 + Number(userData2.security));
    console.log(totalAmount, "amount",qu,userData2.quantityAppliances)
        userData2={...userData2,invoiceArray:zohoInvoice}
    setHisabDetail({...userData2,qu:qu !== 0 ? qu : userData2.quantityAppliances,coastperSecurityApplinaces:coastperSecurityApplinaces})
    if (totalAmount - (Number(userData2.partialAmount)) <= 15000) {
      const response = await updateZohoLink(userData2, fee, totalAmount, e, qu)
      if (response[0] === "then") {
        if (response[1].data.success === false) {
          notifyFailed(response[1].data.message)
          setBtn("Failed")
        }
        subHistory("Link Created", e, "Complete Link Created", totalAmount)
        if (response[1].data.url !== "") {
          setWhatappNumber(userData2.phone);
          console.log(response[1].data.data.subscription.totalAmount, "1");
          setText(`Hey ${e.firstName},
                 Book your Aliste Smart Home in a few clicks by carrying out a payment on the following link :  ${serverUrl.u}/payments/partial/${response[1].data.data.subscription.generatedHex}`);
          navigator.clipboard.writeText(
            `${serverUrl.u}${serverUrl.partialPayZoho}` +
            response[1].data.data.subscription.generatedHex
          );
          setPaymentLink(
            `${serverUrl.u}${serverUrl.partialPayZoho}` +
            response[1].data.data.subscription.generatedHex
          );
        }
        setReloadLink(true);
        setokBtn("Copy");
        setBtn("Update");
      } else if (response[0] === "catch") {
        notifyFailed(response[1].message)
        if (response[1].response.data.success === false) {
          notifyFailed(response[1].response.data.message)
        }
        setBtn('Failed')
      }
    } else {
      let responseRazor = await updateRazorpayLink(userData2, fee, totalAmount, e, qu)
      if (responseRazor[0] === "then") {
        if (responseRazor[1].data.message === "ok" || responseRazor[1].data.message === "payment link create") {
          if (responseRazor[1].data.url !== "") {
            setWhatappNumber(userData2.phone);
            setText(`Hey ${userData2.name},
         Book your Aliste Smart Home in a few clicks by carrying out a payment of on the following link :  ${serverUrl.u}${serverUrl.partialPay}${e.generatedHex}`);
            navigator.clipboard.writeText(`${serverUrl.u}${serverUrl.partialPay}${e.generatedHex}`);
            setPaymentLink(`${serverUrl.u}${serverUrl.partialPay}${e.generatedHex}`);
            setReloadLink(true);
            setBtn("Update")
          }
          setokBtn("Copy");
          subHistory("Link Created", e, "Link Created", totalAmount)

        } else {
          notifyFailed(responseRazor[1].data.message)
          setApp("Failed")
        }
      } else if (responseRazor[0] === "catch") {
        setBtn("Failed")
        if (responseRazor[1].response.data.success === false) {
          notifyFailed(responseRazor[1].response.data.message)
        }
      }
    }
  };
  const handlleTotalAppliance = (app) => {
    let diff = e.totalAppliances - e.currentSecurity / coastperSecurityApplinaces
    console.log(diff,"diff");
    let totalApp = diff + app
    setApp(totalApp)
  }
  useEffect(() => {
    if (e.currentSecurity === 0 && zohoPop === true) {
      notifyFailed("Current Security can not be zero contact admin")
    }
    if(zohoPop){
      countApplianceInHouse(e).then((res)=>{
        console.log(res);
        if(res.type===1){
           if(res.res.data.success){
            let sub=e
            let newAppliances = res.res.data.newAppliances
            let newTotalAppliances = res.res.data.newAppliances
            let app  =res.res.data.newAppliances
            let collectSecurity = true
          
            if (sub.partialPayment === false) {
              if (Number(sub.totalAppliances) !== Number(app)) {
                if (sub.oldDrop === true) {
                  if (Number(app) > -1) {
                    newAppliances = Number(app)
                    newTotalAppliances = Number(app) + Number(sub.totalAppliances)
                  } else {
                    newAppliances = Number(app)
                    collectSecurity = false
                  }
                } else if (sub.oldDrop === false) {
                  newAppliances = app
                  console.log(newAppliances, "3");
                }
              } else if (Number(sub.totalAppliances) === Number(app)) {
                notify("All Payment Clear")
                newAppliances = 0
                console.log(newAppliances, "4");
              }
            } else if (sub.partialPayment === true) {
              newAppliances = app
            }
            let newApp = newAppliances
            let oldApp = 0
            if (e.changesInSubscription.length > 0) {
              oldApp = e.changesInSubscription[e.changesInSubscription.length - 1].oldAppliances
            }
          
            let totalApp = newApp + oldApp

              setNewAppliances(totalApp)
              setUnpaidAappliance({...res.res.data,newAppliances:totalApp})
           }else{
            setUnpaidAappliance({})
            setNewAppliances(0)
           }
        }else{
          let msg = res?.res?.response?.data?.message?(res?.res?.response?.data?.message):res.res.message
          notifyFailed(msg)
          setNewAppliances(0)
          setUnpaidAappliance({})
        }
      })
    }
  }, [e, zohoPop])
  console.log(unpaidAappliance,"unpaid");
  useEffect(()=>{
    console.log(coastperSecurityApplinaces);
  },[coastperSecurityApplinaces])
  return (
    <>

      <ConfirmationForPaymentLink e={e} planNameSet={planNameSet} setZohoPop={setZohoPop} btnName={"Complete Payment Zoho"} page={pages.search} setName={setName} />



      {zohoPop === true ? (
        <div className='popUpBackGround'>
          <div className="innerModalPop" style={{maxHeight:'80%',overflowY:'scroll',marginTop:'5vh'}}>
            <form onSubmit={handleSubmit}>
              <div className="modalCross">
                <div className='crossIcon' onClick={() => {
                  setZohoPop(false)
                  setHisabDetail({})
                  setText("")
                  setZohoInvoices([])
                  setAmount(0)
                }}
                >
                  x
                </div>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>Name</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      autoComplete="name"
                      placeholder="Name"
                      aria-label="name"
                      aria-describedby="basic-addon1"
                      value={e.name}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Phone Number</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      autoComplete="current-phone"
                      placeholder="Phone"
                      aria-label="phone"
                      aria-describedby="basic-addon1"
                      value={e.phone}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>City</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      name="city"
                      autoComplete="current-city"
                      placeholder="City"
                      aria-label="city"
                      aria-describedby="basic-addon1"
                      value={e.city}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Address</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      name="address"
                      autoComplete="current-address"
                      placeholder="Address"
                      aria-label="address"
                      aria-describedby="basic-addon1"
                      defaultValue={e.address}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                {/* <Grid item xs={12} md={6}>
                  <label>Plan Name</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="planName"
                      name="planName"
                      autoComplete="current-planName"
                      aria-label="planName"
                      aria-describedby="basic-addon1"
                      value={planName}
                    />
                  </div>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <label>Plan Duration</label>
                  <div class="input-group mb-3">
                    <select
                      type="text"
                      class="form-control"
                      id="planDuration"
                      name="planDuration"
                      autoComplete="current-planDuration"
                      aria-label="planDuration"
                      aria-describedby="basic-addon1"
                      defaultValue={e.planFrequency}
                    >
                      {planDuration.map((ele) => {
                        return <option value={ele.value}>{ele.value}</option>;
                      })}
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Security Cost Per Appliance?. </label>
                  <div class="input-group mb-3">
                    <input
                      type="number"
                      class="form-control"
                      id="securityCost"
                      name="securityCost"
                      autoComplete="current-securityCost"
                      placeholder="securityCost"
                      aria-label="securityCost"
                      min={0}
                      aria-describedby="basic-addon1"
                      value={coastperSecurityApplinaces}
                      onChange={(ee)=>{
                            setCoastperSecurityApplinaces(Number(ee.target.value))
                            // setNewAppliances(e.currentSecurity / Number(ee.target.value))
                            // handleApplianceChange(newAppliances,Number(ee.target.value));
                        // handlleTotalAppliance(e.currentSecurity / Number(ee.target.value))
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>Overdue Collect?</label>
                  <div class="input-group mb-3">

                    <select 
                      class="custom-select height-select" 
                      required 
                      id="inputGroupSelect01" 
                      name="overdueCollect"
                      label="overdueCollect"
                      autoComplete="current-overdueCollect" 
                      style={{zIndex:8}}
                      defaultValue={collectoverdue}
                    onChange={(val) =>{
                      setCollectoverdue(val.target.value==="true"?true:false)
                      if(val.target.value==="false"){
                        setZohoInvoices([])
                      }
                    }}
                      >


                      <option value={true}>{"Yes"}</option>
                      <option value={false}>{"No"}</option>

                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Credit Note</label>
                  <div class="input-group mb-3">

                    <select className="custom-select height-select" required id="inputGroupSelect01"
                      name="creditNote"
                      label="creditNote"
                      defaultValue={"true"}
                      style={{zIndex:8}}
                      autoComplete="current-creditNote"
                    >

                      <option value={true}>{"Yes"}</option>
                      <option value={false}>{"No"}</option>
                    </select>

                  </div>
                </Grid>
              </Grid>
              {
                collectoverdue ===true?
                <Grid container spacing={4} sx={{zIndex:6}}>
                  <Grid item md={12}  sx={{zIndex:7}}>
              <OverdueInvoiceSelect e={e} amount={amount} setAmount={setAmount} setZohoInvoices={setZohoInvoices} pop={collectoverdue}/>
              </Grid>
            </Grid>:null
              }
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>Security</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      id="security"
                      name="security"
                      onWheel={event => event.currentTarget.blur()}
                      autoComplete="current-security"
                      placeholder="Security"
                      aria-label="security"
                      aria-describedby="basic-addon1"
                      value={secIns.security}
                      onChange={(val) =>
                        setSecIns({ ...secIns, security: val.target.value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Installation</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="installion"
                      name="installion"
                      autoComplete="current-installion"
                      placeholder="installion"
                      aria-label="installion"
                      aria-describedby="basic-addon1"
                      value={secIns.installation}
                      onChange={(val) =>
                        setSecIns({ ...secIns, installation: val.target.value })
                      }
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                
                <Grid item xs={12} md={6}>
                  <label>Appliances in House Unpaid</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      disabled
                      class="form-control"
                    
                      value={Object.keys(unpaidAappliance).length>0?(unpaidAappliance?.newAppliances):0}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Appliances</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      id="appliances"
                      name="appliances"
                      onWheel={event => event.currentTarget.blur()}
                      autoComplete="current-appliances"
                      placeholder="Appliances"
                      aria-label="appliances"
                      aria-describedby="basic-addon1"
                      value={newAppliances}
                      min={0}
                      disabled={!["Akhil","Digvijay"].includes(loginName)?true:false}
                      onChange={(val) => {
                        // handleApplianceChange(val.target.value,coastperSecurityApplinaces);
                        // handlleTotalAppliance(Number(val.target.value))
                        setNewAppliances(Number(val.target.value))
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {
               ( moment(e.InstallationDate).format('MM')!==moment(new Date()).format('MM') ) && (
                <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>Delay Month Payment</label>
                  <div class="input-group mb-3">
                    <select class="custom-select height-select" required id="inputGroupSelect01" defaultValue="true"
                      name="postPayment"
                      label="postPayment"
                      autoComplete="current-postPayment" >
                      <option value={true}>{"Yes"}</option>
                      <option value={false}>{"No"}</option>
                    </select>
                  </div>
                </Grid>
                </Grid>
               )
              }
              
              <Grid container spacing={4}>
                <Stack direction="row" spacing={3} mt={3} pl={5}>
                  <Button variant="contained" color="primary" type="submit" disabled={btn==="Processing.."?true:false}>
                    {btn}
                  </Button>
                {text !== "" ? (
                  <>
                    
                      <ReactWhatsapp
                        number={whatappNumber}
                        message={text}
                        style={{
                          backgroundColor: "#25D366",
                          border: "none",
                          borderRadius: "12px",
                          padding: "5px",

                        }}
                      >
                        <WhatsApp
                          sx={{
                            color: "white",
                            verticalAlign: "middle",
                            fontSize: "xx-large",
                          }}
                        />
                      </ReactWhatsapp>
                
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => navigator.clipboard.writeText(paymentLink)}
                      >
                        Copy
                      </Button>
                  </>
                ) : null}
                </Stack>
              </Grid>

            </form>
            <HisabForZohoRazorPay
              detail={hisabDetail}
              sub={e}
            />


          </div>
        </div>
      ) : null}
    </>
  )
}
