import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./ConvertedLead.css";
import "./DispatchData.css";
import moment from "moment/moment";
import Qutoes from "./Qutoes";
import { serverUrl } from "./ApiUrl"
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import {extendMoment } from "moment-range";
import CommentUdate from "./Function/CommentUdate";
import ChangeInstallationDate from "./Function/ChangeInstallationDate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addGrevianceClient } from "./ApiCalls/AddGreviance";
// import MyTable from "./MyTable";

export default function ReadyDispatch({wholeReload}) {
  let [differntTable, setDiffTable] = useState({})
  let [keys, setKeys] = useState()
  let datas = {}
  
  let grivance = useRef(null);
  let grivanceComment = useRef(null);
  let [gerivanceDate,setGrivanceDate] = useState()
  let [grivanceDisplay,setGrivanceDisplay] = useState(false)
  let [GerivanceType,setGrivanceType] = useState("Misc")
  let [details, setDetails] = useState([]);
  let token = useSelector((state) => state.sar.data.token)
  let [update, setUpdateData] = useState([])
  let [commentDis, setCommentDis] = useState("");
  let [updateDeviceDis, setUpdateDeviceDis] = useState(false);
  let [updateCommentDis, setUpdateCommentDis] = useState(false);
  let [status, setStatus] = useState()
  let [q, setq] = useState("");
  let [websiteReload, SetWebsiteReload] = useState(false);
  const [searchParam] = useState(["name"]);
  let [updateInstallation, setUpdateInstallation] = useState(false);
  let userRoles = useSelector((state) => state.sar.data.user.roles)
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
  let [confirmationDis, setConfirmationDis] = useState(false)
  let [readyData, setReadyData] = useState()
  let [Override, setOverride] = useState(true)
  let [dates, setDates] = useState()
  let [dateErrorMessage, setDateErrorMessage] = useState()
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let [deviceObject,setDeviceObject] = useState({})
  const notify = (text) => toast(text);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    let data = async () => {
      await axios
        .post(
          `${serverUrl.u}/api/tele/getAllReadytoDispatch`
        )
        .then(async function (response) {
          let detail = response.data.data;
          console.log(detail);
          detail.sort(function(a,b){
            return new Date(a.InstallationDate) - new Date(b.InstallationDate);
          });
          await detail.map((d) => {
            let ddate = moment(d.InstallationDate).format("dddd -  Do MMMM");
            if (datas[ddate] === undefined) {
              datas[ddate] = [];
            }
            datas[ddate].push(d);
          })
          setDetails(response.data.data);
          setDiffTable(datas)
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    data();
  
  }, [websiteReload,wholeReload]);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
  const updateReadyToPick = async (data) => {
console.log("update  u",data)
console.log("table  bef ");
let filterData= []
 keys.filter((d, i) => {
 filterData[d]=   differntTable[d].filter((e)=>e._id!==data._id)
})
console.log("table  ",differntTable,"filterData ",filterData);
setDiffTable(filterData)

    await axios
      .post(
        `${serverUrl.u}/api/dispatch/updateDispatched`,
        {
          subId: data._id,
        }
      )
      .then(function (response) {
        let detail = response;
        SetWebsiteReload(!websiteReload);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  

  // const createData = async (data) => {
  //   const algoDetails = data?.devicesAlgo?.totalDevices;
  //   const newStatus = {};
  //   await Object.keys(algoDetails).forEach((numSwitches) => {
  //     console.log("numSwitches   ", numSwitches);
  //     if (Number(algoDetails[numSwitches]) === 0) return;
  //     newStatus[numSwitches] = new Array(algoDetails[numSwitches]).fill({ value: '', state: "" });
  //   })
  //   console.log('initial status', newStatus);
  //   setStatus(newStatus)
  //   setUpdateData(data)
  // }
  const checkDeviceId = async (data1, num, index) => {
    console.log(token);
    if (num === "ir") {
      let obj = { value: data1, state: true }
      let newStatus = { ...status };
      newStatus.ir = newStatus.ir.map((v, i) => {
        if (i === index) {
          return obj;
        } else {
          return v;
        }
      })
      setStatus(newStatus)
    } else {
      await axios.get(`${serverUrl.s}/sync/testingStatus/${data1}`, {
        headers: {
          AccessToken: token
        }
      }).then(function (response) {
        let obj = { value: data1, state: response.data.data.testing_passed }
        let newStatus = { ...status };
        newStatus[num] = newStatus[num].map((v, i) => {
          if (i === index) {
            return obj;
          } else {
            return v;
          }
        })
        setStatus(newStatus)
      }).catch(function (error) {
        console.log(error);
        let obj = { value: data1, state: false }
        let newStatus = { ...status };
        newStatus[num] = newStatus[num].map((v, i) => {
          if (i === index) {
            return obj;
          } else {
            return v;
          }
        })
        setStatus(newStatus)
      })
    }
  }

  const updateDevices = async (deviceId) => {
    if (deviceId.length === 0) return;
    await axios
      .post(
        `${serverUrl.u}/api/dispatch/updateDispatchDevices`,
        {
          subId: update._id,
          devices: deviceId,
        }
      )
      .then(function (response) {
        setUpdateData([])
        SetWebsiteReload(!websiteReload);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  let allDevices = {
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
    ir: 0,
    fan: 0
  }
  if (details) {
    details.forEach((data) => {
      let totalDevices = data.devicesAlgo?.totalDevices===undefined?({
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "f": 0,
            "ir": 0
    }):data.devicesAlgo?.totalDevices
      allDevices = {
        one: allDevices.one + totalDevices[1],
        two: allDevices.two + totalDevices['2'],
        three: allDevices.three + totalDevices['3'],
        four: allDevices.four + totalDevices['4'],
        five: allDevices.five + totalDevices['5'],
        fan: allDevices.fan + totalDevices.f,
        ir: allDevices.ir + totalDevices.ir,

      }
    })
  }

  const makeData = async () => {
    let fan = (update.devicesAlgo?.totalDevices.f===undefined?0:update.devicesAlgo.totalDevices.f);
    let arr = Object.keys(status)
    const devicesToSend = [];
    for (let i = 0; i < fan; i++) {
      devicesToSend.push("fan")
    }
    const make = async () => {
      arr.map((e) => {
        status[e].forEach((ele) => {
          if (ele.value !== "" && ele.state === true) {
            devicesToSend.push(ele.value)
          }
        })
      })
    }
    await make();
    updateDevices(devicesToSend);
  }
  const updateDeviceAlgo = async (layoutDecision) => {
    await axios.post(
      `${serverUrl.u}/api/dispatch/updateSwitchboardInfo`,
      {
        subId: layoutDecision._id,
        layout: layoutDecision.houseStructure,
      }
    ).then(function (response) {
      console.log(response);
      SetWebsiteReload(!websiteReload);
    }).catch(function (error) {
      console.log(error);
    })
  }
  
 
  

  useEffect(() => {
    let keya = Object.keys(differntTable)
    setKeys(keya)
  }, [differntTable])
 
  
  const colorDecide = (text)=>{
    if(text==="dispatch"){
      return "rgb(251, 37, 118)"
    }
    if(text==="ready to install"){
       return "green"
    }
    if(text==="installed"){
      return "rgb(51, 47, 208)"
    }
  }
  return (
    <div>
      <div className="input-field-containers">
      <ToastContainer/>
      <div className="input-field1">
        <input
          type="text"
          value={q}
          placeholder="Search here...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
   
      </div>

      {
        keys ?
          keys.map((d, i) => {
            let  deviceTotal = {
              one: 0,
              two: 0,
              three: 0,
              four: 0,
              five: 0,
              ir: 0,
              fan: 0
            };
            differntTable[d]?.map((data) => {
              let dev={};
              if (data.grivience ===true){
                console.log(data,"gre");
             dev= {
              "1": (data.extraDevices.length!==0 && data.extraDevices[0].one)?Number(data.extraDevices[0].one):0,
                "2": (data.extraDevices.length!==0 && data.extraDevices[0].two)?Number(data.extraDevices[0].two):0,
                "3": (data.extraDevices.length!==0 && data.extraDevices[0].three)?Number(data.extraDevices[0].three):0,
                "4":(data.extraDevices.length!==0 && data.extraDevices[0].four)?Number(data.extraDevices[0].four):0,
                "5":(data.extraDevices.length!==0 && data.extraDevices[0].five)?Number(data.extraDevices[0].five):0,
                "f":(data.extraDevices.length!==0 && data.extraDevices[0].fan)?Number(data.extraDevices[0].fan):0,
                "ir":(data.extraDevices.length!==0 && data.extraDevices[0].ir)?Number(data.extraDevices[0].ir):0
             }
              } 
              let totalDevices = data.devicesAlgo?.totalDevices===undefined?( {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "f": 0,
                "ir": 0
        }):(data.grivience ===false?data.devicesAlgo?.totalDevices:dev)
              deviceTotal = {
                one: deviceTotal.one + totalDevices[1],
                two: deviceTotal.two + totalDevices['2'],
                three: deviceTotal.three + totalDevices['3'],
                four: deviceTotal.four + totalDevices['4'],
                five: deviceTotal.five + totalDevices['5'],
                fan: deviceTotal.fan + totalDevices.f,
                ir: deviceTotal.ir + totalDevices.ir,

              }
            })
            
            return (<div className='' style={{ height: "fit-content",marginLeft:"2%" }} >
           <div className="table-date-container">  <div className="table-date" >{d}</div><button style={{fontSize:"10px !important"}} className="btn btn-primary" onClick={()=>{
            setGrivanceDate(differntTable[d][0].InstallationDate)
            setGrivanceDisplay(!grivanceDisplay)}}>Add Grievance Site</button></div>
              <div className="table-container">
              <table className="my-Border" >
                <thead>

                  <tr style={{
                    height: "10px",
                  }}>
                    <th>Name<span style={{ color: "red" }}>*</span></th>
                    <th>City</th>
                    
                    <th>Installation Date<span style={{ color: "red" }}>*</span></th>
                    <th>TeleCaller Name</th>
                    <th>Status</th>
                    <th>Payments</th>
                    <th>Comments<span style={{color:"red"}}>*</span></th>
                    <th>Grievance Comment</th>
                    <th>Appliances</th>
                    <th >1 Switch</th>
                    <th>2 Switch</th>
                    <th>3 Switch</th>
                    <th>4 + 1 Switch</th>
                    <th>5 Switch</th>
                    <th>Fan Switch</th>
                    <th>IR</th>
                    <th style={{width:"166px"}}>Actions</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {
                      differntTable[d]?.sort((a, b) => (b.priorityNumber - a.priorityNumber)).map((e, i) => {

                      return (
                        <tr key={i}>
                          <td style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                          <td>{e.city}</td>
                          <ChangeInstallationDate e={e} reload={websiteReload} setReload={SetWebsiteReload} notify={notify} decision={"td"}/>
                          <td>{e.teleCallerId}</td>
                          <td label="Status" style={{color:colorDecide(e.subscriptionStage)}}>{e.subscriptionStage}{e.grivience===true?"  &  Grivience":""}</td>
                          <td style={{ color: `${e.partialPayment === false ? "green" :(e.partialAmount===0)?"red":"orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount===0)?"Zero":"Partial"}</td>
                          <CommentUdate e={e} websiteReload={websiteReload} SetWebsiteReload={SetWebsiteReload} page={'dispatch'}/>
                          <td>{e.extra2}</td>
                          <td>{e.totalAppliances}</td>
                          <td>{e?.devicesAlgo?.totalDevices[1]===undefined?"0":e?.devicesAlgo?.totalDevices[1]} {e.extraDevices[0]?.one?("("+e.extraDevices[0].one+")"):null}</td>
                          <td>{e?.devicesAlgo?.totalDevices[2]===undefined?"0":e?.devicesAlgo?.totalDevices[2]} {e.extraDevices[0]?.two?("("+e.extraDevices[0].two+")"):null}</td>
                          <td>{e?.devicesAlgo?.totalDevices[3]===undefined?"0":e?.devicesAlgo?.totalDevices[3]} {e.extraDevices[0]?.three?("("+e.extraDevices[0].three+")"):null}</td>
                          <td>{e?.devicesAlgo?.totalDevices[4]===undefined?"0":e?.devicesAlgo?.totalDevices[4]} {e.extraDevices[0]?.four?("("+e.extraDevices[0].four+")"):null}</td>
                          <td>{e?.devicesAlgo?.totalDevices[5]===undefined?"0":e?.devicesAlgo?.totalDevices[5]} {e.extraDevices[0]?.five?("("+e.extraDevices[0].five+")"):null}</td>
                          <td>{e?.devicesAlgo?.totalDevices.f===undefined?"0":e?.devicesAlgo?.totalDevices.f} {e.extraDevices[0]?.fan?("("+e.extraDevices[0].fan+")"):null}</td>
                          <td>{e?.devicesAlgo?.totalDevices.ir===undefined?"0":e?.devicesAlgo?.totalDevices.ir} {e.extraDevices[0]?.ir?("("+e.extraDevices[0].ir+")"):null}</td>
                          <td >
                            <div style={{display:"flex",lineHeight:0,alignItems: "center",justifyContent: "space-evenly"}}>
                            <img data-tip="Refresh" data-place="bottom" onClick={() => updateDeviceAlgo(e)} style={{width:"16px",height:"16px"}} src={process.env.PUBLIC_URL+'/assets/icon/Refresh.png'} alt="icon"/>
                            <Link to="/new" state={{ from: e, path: "Dispatch" }}><img data-tip="SwitchBoard" data-place="bottom" style={{width:"16px",height:"16px"}} src={process.env.PUBLIC_URL+'/assets/icon/SwitchBoard.png'} alt="icon"/></Link>
                            <Link to="/pdf2" state={{ from: e, path: "Dispatch" }}> <img data-tip="Layout" data-place="bottom" style={{width:"16px",height:"16px"}} src={process.env.PUBLIC_URL+'/assets/icon/Layout.png'} alt="icon"/></Link>
                            <img data-tip="Ready" data-place="bottom"  onClick={() => {
                                setReadyData(e)
                                setConfirmationDis(!confirmationDis)
                              }} style={{width:"16px",height:"16px"}} src={process.env.PUBLIC_URL+'/assets/icon/readyPacket.png'} alt="icon"/>
                            </div>
                          </td>
                          
                        </tr>
                      );
                    })
                  }
                    {
                    deviceTotal ?
                   <tr>
                    <th colSpan="9"></th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>Total</th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>{deviceTotal?.one}</th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>{deviceTotal?.two}</th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>{deviceTotal?.three}</th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>{deviceTotal?.four}</th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>{deviceTotal?.five}</th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>{deviceTotal?.fan}</th>
                        <th style={{width:"80px",fontWeight:"700",fontSize:"16px",border:"1px solid  #ebeff3"}}>{deviceTotal?.ir}</th>
                        </tr>:null

                    }
                </tbody>
              </table>
              </div>
              </div>
             )
          }) : null
      }
     
      {
        // (update.length !== 0 && updateDeviceDis === true) ?
        //   <div className="update-Device-cont">
        //     <div style={{
        //       display: "flex",
        //       justifyContent: "space-between"
        //     }}>
        //       <div>Add Device Ids</div>
        //       <div onClick={() => setUpdateData([])} style={{ cursor: "pointer" }}>x</div>
        //     </div>
        //     {
        //       update?.devicesAlgo.totalDevices[1] !== 0 ?
        //         <div>
        //           <h3>One Switch</h3>
        //           {
        //             [...Array(update?.devicesAlgo.totalDevices[1])].map((elementInArray, index) => {
        //               return <div>
        //                 <div class="input-group mb-3">
        //                   <div class="input-group-prepend">
        //                     <span class="input-group-text" id="basic-addon1">S01</span>
        //                   </div>
        //                   <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" onBlur={(e) => {
                    
        //                     checkDeviceId("S01" + e.target.value, 1, index)
        //                   }} />
        //                   {
        //                     status[1][index]?.state === true ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/check.png"} alt="sign" /> : null
        //                   }
        //                   {

        //                     status[1][index]?.state === false ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/cross.png"} alt="sign" /> : null
        //                   }
        //                 </div>
        //               </div>
        //             }
        //             )
        //           }

        //         </div> : null
        //     }
        //     {/* two switch */}
        //     {
        //       update?.devicesAlgo?.totalDevices[2] !== 0 ?
        //         <div>
        //           <h3>Two Switch</h3>
        //           {
        //             [...Array(update?.devicesAlgo?.totalDevices[2])].map((elementInArray, index) => {
        //               return <div>
        //                 <div class="input-group mb-3">
        //                   <div class="input-group-prepend">
        //                     <span class="input-group-text" id="basic-addon1">S02</span>
        //                   </div>
        //                   <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" onBlur={(e) => checkDeviceId("S02" + e.target.value, 2, index)} />
        //                   {
        //                     status[2][index]?.state === true ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/check.png"} alt="sign" /> : null
        //                   }
        //                   {

        //                     status[2][index]?.state === false ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/cross.png"} alt="sign" /> : null
        //                   }
        //                 </div>
        //               </div>
        //             }
        //             )
        //           }

        //         </div> : null
        //     }
        //     {/* three devices */}
        //     {
        //       update?.devicesAlgo.totalDevices[3] !== 0 ?
        //         <div>
        //           <h3>Three Switch</h3>
        //           {
        //             [...Array(update?.devicesAlgo.totalDevices[3])].map((elementInArray, index) => {
        //               return <div>
        //                 <div class="input-group mb-3">
        //                   <div class="input-group-prepend">
        //                     <span class="input-group-text" id="basic-addon1">S03</span>
        //                   </div>
        //                   <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" onBlur={(e) => checkDeviceId("S03" + e.target.value, 3, index)} />
        //                   {
        //                     status[3][index]?.state === true ?
        //                       <img alt="sign" className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/check.png"} /> : null
        //                   }
        //                   {

        //                     status[3][index]?.state === false ?
        //                       <img alt="sign" className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/cross.png"} /> : null
        //                   }
        //                 </div>
        //               </div>
        //             }

        //             )
        //           }

        //         </div> : null
        //     }
        //     {/* Four plus one */}
        //     {
        //       update?.devicesAlgo.totalDevices[4] !== 0 ?
        //         <div>
        //           <h3>Four + One Switch</h3>
        //           {
        //             [...Array(update?.devicesAlgo.totalDevices[4])].map((elementInArray, index) => {
        //               return <div>
        //                 <div class="input-group mb-3">
        //                   <div class="input-group-prepend">
        //                     <span class="input-group-text" id="basic-addon1">S04</span>
        //                   </div>
        //                   <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" onBlur={(e) => checkDeviceId("S04" + e.target.value, 4, index)} />
        //                   {
        //                     console.log("down  ", status[4][index].state)
        //                   }
        //                   {
        //                     status[4][index]?.state === true ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/check.png"} alt="sign" /> : null
        //                   }
        //                   {

        //                     status[4][index]?.state === false ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/cross.png"} alt="sign" /> : null
        //                   }

        //                 </div>
        //               </div>
        //             }
        //             )
        //           }

        //         </div> : null
        //     }
        //     {/* five switch */}
        //     {
        //       update?.devicesAlgo.totalDevices[5] !== 0 ?
        //         <div>
        //           <h3>Five Switch</h3>
        //           {
        //             [...Array(update?.devicesAlgo.totalDevices[5])].map((elementInArray, index) => {
        //               return <div>
        //                 <div class="input-group mb-3">
        //                   <div class="input-group-prepend">
        //                     <span class="input-group-text" id="basic-addon1">S05</span>
        //                   </div>
        //                   <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" onBlur={(e) => checkDeviceId("S05" + e.target.value, 5, index)} />
        //                   {
        //                     status[5][index]?.state === true ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/check.png"} alt="sign" /> : null
        //                   }
        //                   {

        //                     status[5][index]?.state === false ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/cross.png"} alt="sign" /> : null
        //                   }
        //                 </div>
        //               </div>
        //             }
        //             )
        //           }

        //         </div> : null
        //     }
        //     {/* IR */}
        //     {
        //       update?.devicesAlgo.totalDevices.ir !== 0 ?
        //         <div>
        //           <h3>IR Device</h3>
        //           {
        //             [...Array(update?.devicesAlgo.totalDevices.ir)].map((elementInArray, index) => {
        //               return <div>
        //                 <div class="input-group mb-3">

        //                   <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" onBlur={(e) => checkDeviceId("" + e.target.value, "ir", index)} />
        //                   {
        //                     status.ir[index]?.state === true ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/check.png"} alt="sign" /> : null
        //                   }
        //                   {

        //                     status.ir[index]?.state === false ?
        //                       <img className="check-cross-icon" src={process.env.PUBLIC_URL + "assets/icon/cross.png"} alt="sign" /> : null
        //                   }
        //                 </div>
        //               </div>
        //             }
        //             )
        //           }

        //         </div> : null
        //     }
        //     <button className="btn btn-primary" onClick={() => makeData()}>Update</button>
        //   </div> : null
      }
    
      {confirmationDis === true ?
        <div className='confirmation-popup'>
          <div className='confirmation-popup-heading'>Confirmation for Ready {readyData.name}</div>
          <div className='confirmation-popup-Subheading'>Are your sure you want to Ready</div>
          <button className='btn btn-danger' onClick={() => { setConfirmationDis(false) }}>No</button>
          <button className='btn btn-primary ml-5' onClick={() => {
            updateReadyToPick(readyData)
            setConfirmationDis(false)
          }}>Yes</button>
        </div> : null
      }
      
      {
        grivanceDisplay === true ?

          <div className="greivance-container" style={{top:"21% !important"}}>
            <span className="cross" onClick={() => setGrivanceDisplay(!grivanceDisplay)}>x</span>
            <div class="input-group mb-1">
              <input type="text" ref={grivance} placeholder="Enter Hex Code here..." defaultValue={update.generatedHex}/>
            </div>
         
        <div class="input-group mb-1">
        <input type="text" ref={grivanceComment} placeholder="Enter Comment here..."  defaultValue=""/>
        </div>
        <div class="input-group mb-1" >
        <label>Problem Type</label>
          <select onChange={(e)=>setGrivanceType(e.target.value)} defaultValue={GerivanceType} >
            <option value="WiFi">WiFi</option>
            <option value="Hanging">Hanging</option>
            <option value="Power">Power</option>
            <option value="Switch">Switch</option>
            <option value="Misc">Misc</option>
            
          </select>
        </div> 
        <div class="input-group mb-1" >
        <label>If you want to send devices select Yes</label>
          <select onChange={(e)=>setDeviceObject({...deviceObject,decision:e.target.value})} defaultValue="no">
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </select>
        </div> 
        {
          deviceObject?.decision=="yes"?
          <div className="DeviceObjectDetails">
            <div className="DeviceObjectDetails-specific">
            <label >One Switch</label>
            <input type="number"  onWheel={ event => event.currentTarget.blur() } onChange={(e)=>setDeviceObject({...deviceObject,one:e.target.value})}/>
            </div>
            <div className="DeviceObjectDetails-specific">
            <label>Two Switch</label>
            <input type="number"  onWheel={ event => event.currentTarget.blur() } onChange={(e)=>setDeviceObject({...deviceObject,two:e.target.value})}/>
            </div>
            <div className="DeviceObjectDetails-specific">
            <label>Three Switch</label>
            <input type="number"  onWheel={ event => event.currentTarget.blur() } onChange={(e)=>setDeviceObject({...deviceObject,three:e.target.value})}/>
            </div>
            <div className="DeviceObjectDetails-specific">
            <label>4 + 1 Switch</label>
            <input type="number"  onWheel={ event => event.currentTarget.blur() } onChange={(e)=>setDeviceObject({...deviceObject,four:e.target.value})}/>
            </div>
            <div className="DeviceObjectDetails-specific">
            <label>Five Switch</label>
            <input type="number" onWheel={ event => event.currentTarget.blur() } onChange={(e)=>setDeviceObject({...deviceObject,five:e.target.value})}/>
            </div>
            <div className="DeviceObjectDetails-specific">
            <label>IR</label>
            <input type="number" onWheel={ event => event.currentTarget.blur() } onChange={(e)=>setDeviceObject({...deviceObject,ir:e.target.value})}/>
            </div>
            <div className="DeviceObjectDetails-specific">
            <label >Fan</label>
            <input type="number" onWheel={ event => event.currentTarget.blur() } onChange={(e)=>setDeviceObject({...deviceObject,fan:e.target.value})}/>
            </div>
            </div>:null
           }
            <button className="btn btn-primary" onClick={() => addGrevianceClient(grivance.current.value,deviceObject,grivanceComment.current.value,GerivanceType,loginName,gerivanceDate,notify,SetWebsiteReload,websiteReload,setGrivanceDisplay,{})}>Greviane</button>
          </div> : null
      }

      <Qutoes />
    </div>
  );
}
