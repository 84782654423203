import { Button } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { subHistory } from '../Function/SubscriptionHistory'
import { useSelector } from 'react-redux'

export default function FinalUnInstallationBtn({data,notify,setReload,reload}) {
    let [btn,setBtn] = useState("Uninstall Completed")
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    const finalUnInstallation = ()=>{
        setBtn("Processing...")
        axios.post(`${serverUrl.u}/api/tele/uninstallationDone`,{
            generatedHex:data.generatedHex,
        }).then(function (res) {
            console.log(res);
            if(res.status===200){
                notify("Updated Sucessfully")
                setBtn("Uninstall Completed")
                subHistory("UnInstallation Done By Department",data,"UnInstallation Done By Department","UnInstallation Completed")
                setReload(!reload)
            }else{
                notify("Failed")
                setBtn("Retry")
            }
        }).catch(function (err) {
            notify("Failed")
            setBtn("Retry")
        })
    }
  return (
    <>
      <Button 
         variant='contained' 
         size='small' 
         onClick={()=>finalUnInstallation()}
         style={{pointerEvents:btn==="Processing..."?"none":""}}
         >{btn}</Button>
    </>
  )
}
