import React, { useState } from "react";
import {
  fetchSequrityReceipt,
  fetchSequrityReceiptComplete,
  fetchSequrityReceiptCsv,
} from "./Api";
import { notify, notifyFailed } from "../Common/Notification";
import { CSVLink } from "react-csv";
import { Stack } from "@mui/material";
import EditSequrityReceipt from "./component/EditSequrityReceipt";

function SecurityReceipt() {
  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [dates, setDates] = useState({});
  const refresh = async (date) => {
    await fetchSequrityReceiptCsv(date).then((res) => {
      if (res.type === 1) {
        if (res.data.success) {
          if(res.data.data.length>0){
            setData(res.data.data)
             }else{
               notify('Data Not Found')
             }
        } else {
          notifyFailed(res.data.message);
        }
      } else {
        let msg = res?.response?.data?.message
          ? res.response.data.message
          : res.message;
        notifyFailed(msg);
      }
    });
    await fetchSequrityReceiptComplete(date).then((res) => {
      if (res.type === 1) {
        if (res.data.success) {
          setCompleteData(res.data.data);
        } else {
          notifyFailed(res.data.message);
        }
      } else {
        let msg = res?.response?.data?.message
          ? res.response.data.message
          : res.message;
        notifyFailed(msg);
      }
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData(e.currentTarget);

    let date = {
      firstDate: data.get("startDate"),
      lastDate: data.get("endDate"),
    }
    setDates(date)
    await refresh(date)
  };
  return (
    <div style={{ width: "100%", padding: "0 4%", marginTop: "5vh" }}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-2 ">
            <label>Start Date</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="startDate"
                defaultValue={new Date(
                  new Date().setDate(new Date().getDate() - 10)
                )
                  .toISOString()
                  .substr(0, 10)}
                autoComplete="current-startDate"
              />
            </div>
          </div>
          <div className="col-2 ">
            <label>End Date</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="endDate"
                defaultValue={new Date(
                  new Date().setDate(new Date().getDate() + 1)
                )
                  .toISOString()
                  .substr(0, 10)}
                autoComplete="current-endDate"
              />
            </div>
          </div>
          <div className="col-2">
            <label></label>
            <div className="input-group mt-2 ">
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "2%",
                  marginTop: "2%",
                  marginBottom: "5%",
                }}
                type="submit"
              >
                Get Data
              </button>
            </div>
          </div>
          {data.length > 0 && (
            <div className="col-2">
              <label></label>
              <div className="input-group mt-2 ">
                <CSVLink data={data} filename="Security_Receipt.csv">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      marginBottom: "5%",
                    }}
                  >
                    Download
                  </button>
                </CSVLink>
              </div>
            </div>
          )}
        </div>
      </form>

      <div
        className="table-container1"
        style={{
          marginTop: "10px",
          marginBottom: "30px",
          height: "fit-content",
        }}
        key={"imp"}
      >
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Receipt Number</th>
              <th>Name</th>
              <th>Hex</th>
              <th>Amount</th>
              <th scope="col">Zoho Invoice Number</th>
              <th>Created Date</th>
              <th>Comment</th>
              <th>Master Cancel</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, i) => {
              let sequrityData = completeData.find(
                (cr) => cr._id === e.receiptId
              );
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{e.receiptNumber}</td>
                  <td>{e.name}</td>
                  <td>{e.generatedHex}</td>
                  <td>{e?.amount}</td>
                  <td>{e.zohoInvoiceNumber}</td>
                  <td>{e?.createdDate}</td>
                  <td>{e.comments}</td>
                  <td>{e.masterCancel ? "Yes" : "No"}</td>
                  <td>
                    <Stack>
                      {sequrityData !== undefined &&
                        Object.keys(sequrityData).length > 0 && (
                          <EditSequrityReceipt
                            data={sequrityData}
                            date={dates}
                            refresh={refresh}
                          />
                        )}
                    </Stack>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SecurityReceipt;
