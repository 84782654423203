import React from 'react'
import Webcam from "react-webcam";
export default function WebCam() {
    const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [devices, setDevices] = React.useState([]);
  const [activeDeviceId, setActiveDeviceId] = React.useState('');

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);
  React.useEffect(() => {
    const getDevices = async () => {
      try {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput');
        setDevices(videoDevices);
        setActiveDeviceId(videoDevices[0].deviceId); // Set the first camera as the default active camera
      } catch (error) {
        console.error('Error enumerating devices:', error);
      }
    };
    getDevices();
  }, []);
  const toggleCamera = () => {
    const activeIndex = devices.findIndex((device) => device.deviceId === activeDeviceId);
    const nextIndex = (activeIndex + 1) % devices.length; // Circular loop through available cameras
    setActiveDeviceId(devices[nextIndex].deviceId);
  };
  return (
    <div>
        <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ deviceId: activeDeviceId }} // Use the currently active camera
     
           mirrored={activeDeviceId !== devices[0]?.deviceId}
        />
        <button onClick={capture}>Capture photo</button>
        <button onClick={toggleCamera}>Toggle Camera</button>
      {imgSrc && (
        <img
          src={imgSrc}
        />
      )}
    </div>
  )
}
