import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";
import store from "../Redux";

export const subHistory = (comment, additionalInfo, type, updatedValue, info = "",updateDate=new Date()) => {

    axios.post(`${serverUrl.u}/api/history/createSubscriptionHistory`, {
        generatedHex: additionalInfo.generatedHex,
        loggingPerson: store.getState().sar.data.user.first_name,
        additionalInfo: additionalInfo,
        comments: comment,
        type: type,
        dateExecuted: new Date(),
        updatedValue: updatedValue,
        updateDate: updateDate,
        info1: info,
    }).then(function (res) {
        console.log(res);
        if (res.data.success === true) {
            notify(res.data.message)
        } else {
            notifyFailed(res.data.message)
        }
    }).catch(function (err) {
        console.log(err);
        notifyFailed(err.message)
    })
}