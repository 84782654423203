import { Button,Grid } from '@mui/material'
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { enterFeedback } from './Api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius:"12px",
};

function EnterFeedback({sub}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [btn,setBtnText]= useState("Submit")
  const handleSubmit = async(event)=>{
    setBtnText("Processing...")
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let detail ={
        firstName:sub.firstName!==""?sub.firstName:sub.name,
        lastName:sub.lastName, 
        phone:sub.phone, 
        city:sub.city, 
        generatedHex:sub.generatedHex, 
        clientFeedback:data.get('clientFeedback'),
    }
    console.log(detail);
   await enterFeedback(detail)
   setBtnText("Submit")
  }
  return (
    <>
       <Button 
         variant='contained'
         color='primary'
         onClick={handleOpen}
         size='small'
       >Enter FeedBack</Button>
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
           <form onSubmit={handleSubmit}>
           <Grid container spacing={2}>
           <Grid item xs={12} md={12}>
                <label>Name</label>
                <div class="input-group">
                  <input 
                    type="text" 
                    className="form-control" 
                    disabled
                    value={sub.name} />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <label>Custmor Feedback</label>
                <div class="input-group">
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder='feedback'
                    name='clientFeedback'
                    autoComplete='current-clientFeedback'
                 />
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                 <Button
                   type='sunmit'
                   variant='contained'
                   color='primary'
                   disabled={btn==="Processing..."?true:false}
                 >{btn}</Button>
              </Grid>
           </Grid>
           </form>
        </Box>
      </Modal>

    </>
  )
}

export default EnterFeedback