import React,{ useState,useRef } from 'react'
import axios from 'axios'
import { serverUrl } from './ApiUrl'
import Qutoes from './Qutoes'
import './SingleClientSubscription.css'
import { planDuration } from './Data'

export default function SingleClientSubscription() {
    let [data,setData] = useState([])
    let [pop,setPop] = useState(false)
    let [popData,setPopData] = useState([])
    let [choose,setChoose] = useState("")
    let search = useRef(null)
   const subscriptionData = (phone) =>{
      axios.post(`${serverUrl.t}/api/tele/fetchSubBasedOnPhone`,{
        phone:phone
      }).then(
        function (res) {
            console.log(res.data);
            if(res.data.success===true){
                setData(res.data.data)
            }
        }
      ).catch(function (err) {
        console.log(err);
      })
   }
  return (
    <div>
        <div style={{position:"relative",width:"100%"}} className='input-field'>
           <input type="text" style={{float:"right"}} ref={search}  placeholder="Search here by number...."/>
           <button className='btn btn-primary' style={{borderRadius:"12px",height:"15px",position:"absolute",right:"2.5%",padding:"0 4px",top:"10%"}} onClick={()=>subscriptionData(search.current.value)}>Search</button>
        </div> 

        {
            data.length!==0?
            
             data.map((arr,i)=>{
                if(arr.length===0) return
               return(
                <>
                <div className='table-container1' style={{marginTop:"15px",height:"auto"}}>
      
      <table className='table table-sm table-bordered'>
        <thead>
          <tr >
            <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
            <th scope="col">Telecaller Name</th>
            <th scope="col">Second Telecaller Name</th>
            <th>Address<span style={{color:"red"}}>*</span></th>
            <th scope="col">Phone</th>
            <th>Payment</th>
            <th scope="col">Appliances</th>
            <th scope="col">Selected Plan</th>
          </tr>
        </thead>
        <tbody>
          {
            arr !== 0 ?
              arr.map((e, index) => {
                return (
                  <tr key={index}>
                    <td className="pr-15 pl-55" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                    <td>{e.teleCallerId}</td>
                    <td>{e.secondarySalesPerson}</td>

                    <td>{e.address}</td>
                    <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                    <td style={{ color: `${e.partialPayment === false ? "green" :(e.partialAmount===0)?"red":"orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount===0)?"Zero":`Partial`}  </td>
                    <td>{e.totalAppliances}</td>
                    <td>{e.planFrequency}</td>
                    
                    
                  </tr>
                )
              }) : null
          }
        </tbody>
      </table>
      <div style={{width:"inherit",display:"flex",justifyContent:"flex-end",marginBottom:"4px"}}>
      <button className='btn btn-primary'onClick={()=>{setPop(true)
                                                       setPopData(arr)}}>DownGrade</button>
      <button className='btn btn-primary' style={{margin:"0px 5px"}} onClick={()=>{setPop(true)
                                                       setPopData(arr)}}>UpGrade</button>
      </div>
      
    </div>
                </>
               )
             })

            :null
        }
        {
           pop===true?
           <div style={{position:"fixed",top:"40%",left:"40%",background: "lightgray",
           padding: "11px 16px",
           zIndex: 4,
           borderRadius: "14px"}}>
            <div style={{position:"relative",height:"15px"}}><span onClick={()=>setPop(false)}  style={{position:"absolute",cursor:"pointer",right:"5px",top:"-2px"}}>X</span></div>
           <div>
            <input type='radio'name='choose' onChange={()=>setChoose("Appliances")} value={"Appliances"}/>
            <label>Appliances</label>
            <input type='radio' name='choose' onChange={()=>setChoose("Frequency")} value={"Frequency"}/>
            <label>Frequency</label>
            <input type='radio' name='choose' onChange={()=>setChoose("Both")}  value={"Both"}/>
            <label>Both</label>
            </div>
            <div>{choose}</div>
            <div>
                {
                    choose==="Appliances"?
                    <div className='single_client_pop_container'>
                        <div className='single_input'>
                      <label>Appliances</label>
                      <input type='number' className='form-control' onWheel={ event => event.currentTarget.blur() }/>
                      </div>
                    </div>:(choose==="Frequency"?
                    <div  className='single_client_pop_container'>
                        <div className='single_input'>
                        <label>Frequency</label>
                        <select className='form-control'>
                            {
                                planDuration.map((ele)=>{
                                    return(
                                        <option value={ele.value}>{ele.value}</option>
                                    )
                                })
                            }
                
                        </select>
                        </div>
                    </div>:(choose==="Both"?
                    <div  className='single_client_pop_container'>
                      <div  className='single_input'>
                         <label>Appliances</label>
                         <input type="number" className='form-control' onWheel={ event => event.currentTarget.blur() }/>
                      </div>
                      <div  className='single_input'>
                        <label>Frequency</label>
                        <select className='form-control'>
                        {
                                planDuration.map((ele)=>{
                                    return(
                                        <option value={ele.value}>{ele.value}</option>
                                    )
                                })
                            }
                        </select>
                      </div>
                    </div>:null)
                    )
                }
            </div>

           </div>:null
        }
     <Qutoes/>   
    </div>
  )
}
