import { Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { InvoiceGenerate } from '../Function/InvoieDownloadFun';
import { serverUrl } from '../ApiUrl';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import axios from 'axios';
import { notifyFailed } from '../Common/Notification';

const creditNoteGenerate = async (Number) => {
  return await axios
    .post(`${serverUrl.u}/api/tele/createCreditNotes`, {
      creditNoteNumber: Number,
    })
    .then(function (res) {
      return { res: res };
    })
    .catch(function (err) {
      return { err: err };
    });
};
export default function CreditNoteDownload({ creditnoteNumber, name }) {
  const [loading, setloading] = useState(false);
  const creditNote = async () => {
    setloading(true);
    let res = await creditNoteGenerate(creditnoteNumber, setloading);

    if (res.res !== undefined) {
      setloading(false);
      if (res.res.status === 200) {
        for (let url of res.res.data.urlObj) {
          FileSaver.saveAs(
            `${serverUrl.u + url.substring(6)}`,
            `${name.trim()}_CreditNote.pdf`
          );
        }
      } else {
        notifyFailed('Failed');
      }
    } else if (res.err !== undefined) {
      setloading(false);
      notifyFailed(res.err.response.data.message);
    } else {
      setloading(false);
    }
  };
  return (
    <>
      <button
        className='btn btn-primary'
        onClick={() => creditNote()}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Credit Note Download'}
      </button>
    </>
  );
}
