import axios from "axios"
import { serverUrl } from "../ApiUrl"
import { notify, notifyFailed } from "../Common/Notification";

export const leadCreate = async(detail)=>{
   await axios.post(`${serverUrl.amazon}/lead/other`,detail).then(
        function (res) {
            console.log(res);
            if(res.data.success===true){
                notify(res.data.message)
            }else{
                notifyFailed(res.data.message)
            }
        }
    ).catch(function (err) {
        console.log(err);
        notifyFailed(err.response.data.message)
    })
}

export const autoAssignStatus = async()=>{
   return await axios.get(`${serverUrl.amazon}/autoAssignment/status`).then(
        function (res) {
           if(res.data.success){
              if(res.data.data.autoAssignEnabled){
                return res.data.data
              }else{
                return {}
              }
           }else{
            notifyFailed(res.data.message)
            return {}
           }  
        }
    ).catch(function (err) {
        console.log(err);
        return {}
    })
}

export const autoAssignOn = async(detail)=>{
    await axios.post(`${serverUrl.amazon}/autoAssignment/on`,{
        offTime:detail.offTime,
        telecallers:detail.autoAssignTelecallers
    }).then(
        function (res) {
            if(res.data.success){
                notify("Auto Assign Set")
            }else{
                notifyFailed(res.data.message)
            }
        }
    ).catch(function(err){
        notifyFailed(err.response.data.message)
    })
}
export const autoAssignOff = async()=>{
    await axios.post(`${serverUrl.amazon}/autoAssignment/off`).then(
        function (res) {
           
                if(res.data.success){
                    if(res.data.data.autoAssignEnabled===false){
                        notify("Auto Assign Off")
                    }else{
                        notifyFailed("Auto Assign Off Failed")
                    }
            }else{
                notifyFailed("Auto Assign Off Failed")
            }
        }
    ).catch(function(err){
        notifyFailed(err.response.data.message)
    })
}