import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { custmorDontFeedback } from './Api'
import EnterFeedback from './EnterFeedback'
import moment from 'moment'

function NotFeedback() {
  const [feedback, setFeedBack] = useState([])
  useEffect(() => {
    custmorDontFeedback(setFeedBack)
  }, [])
  let skipName = [
    "Housr Homes Private Limited",
    "DTWELVE SPACES PRIVATE LIMITED"
  ]
  return (
    <div style={{ padding: "0 1%", marginTop: "4vh" }}>
      <div className='table-container1' style={{ marginTop: 0 }}>

        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th>Sr. No.</th>
              <th>Name</th>
              <th>Phone</th>
              <th>City</th>
              <th>Address</th>
              <th>Installation Date</th>
              <th>TeleCaller</th>
              <th>Second TeleCaller</th>
              <th>User</th>
              <th>Alex Linked</th>
              <th>Google Home Linked</th>
              <th>Schedule</th>
              <th>Scene</th>
              <th>Stack</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              feedback.length !== 0 ?
                feedback.filter(e=>!skipName.includes(e.name)).map((e, i) => {
            
                
                
                  return (
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td
                        onClick={() => navigator.clipboard.writeText(e.generatedHex)}
                        style={{ cursor: "pointer" }}
                      >{e.name}</td>
                      <td>{e.phone}</td>
                      <td>{e.city}</td>
                      <td>{e.address}</td>
                      <td>{moment(e.installDoneDate).format("DD-MM-YYYY")}</td>
                      <td>{e.teleCallerId}</td>
                      <td>{e.secondarySalesPerson}</td>
                      <td>{e.userCount}</td>
                      <td>{e.alexaLinked?"Linked":"Not Linked"}</td>
                      <td>{e.googleHomeLinked?"Linked":"Not Linked"}</td>
                      <td>{e.schedule}</td>
                      <td>{e.scene}</td>
                      <td>{e.stack}</td>
                      <td><EnterFeedback sub={e} /></td>
                    </tr>
                  )
                  
                }) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default NotFeedback