import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { getSubscriptionData, getSubscriptionDataReturn, handeleDownGradeUpGradeGrivance, handleCash, handleDifferenceAppElectrician, installationDoneByElectrician, removeElectricianFromSite } from './ApplincesDifferenceApi';
import { finalSelectDeviceUpdate, orderTyepes, planDuration } from '../../Data';
import ReactWhatsapp from 'react-whatsapp';
import { WhatsApp } from '@mui/icons-material';
import { Popover } from '@mui/material';
import { notify } from '../../Common/Notification';
import { useSelector } from 'react-redux';
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "max-content",
    maxWidth: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: "12px",
    height: "90vh",
    overflowY: "scroll"
};

export default function ModalUpgradeDownGrade({ Sub, deviceDifference, setReload, reload, setDeviceDifference }) {
    const [open, setOpen] = useState(false);
    const [hisabNotDrop, setHisabNotDrop] = useState({})
    const [hisabDrop, setHisabDrop] = useState({})
    const [applianceDifference, setApplianceDifference] = useState({})
    const [securityCoastApplinace, setSecurityCoastApplinace] = useState(100)
    const [selectPlan, setSelectPlan] = useState({})
    const [paymentLink, setPaymentLink] = useState('')
    const [whatappNumber, setWhatappNumber] = useState('');
    const [text, setText] = useState("")
    const [paymentLinkBtn, setPaymentLinkBtn] = useState('Get Payment Link')
    const [anchorElDrop, setAnchorElDrop] = React.useState(null);
    const [anchorElNotDrop, setAnchorElNotDrop] = React.useState(null);
    const [cashTransaction, setCashTransaction] = useState(false)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    const handleClickDrop = (event) => {
        setAnchorElDrop(event.currentTarget);
    };
    const [sub, setSub] = useState({})
    useEffect(() => {
        getSubscriptionDataReturn(Sub).then(
            function (res) {
                setSub(res)
            }
        ).catch(function (err) {

        })

    }, [Sub])

    const handleCloseDrop = () => {
        setAnchorElDrop(null);
    };

    const openDrop = Boolean(anchorElDrop);
    const idDrop = openDrop ? 'simple-popover' : undefined;
    const handleClickNotDrop = (event) => {
        setAnchorElNotDrop(event.currentTarget);
    };

    const handleCloseNotDrop = () => {
        setAnchorElNotDrop(null);
    };

    const openNotDrop = Boolean(anchorElNotDrop);
    const idNotDrop = openNotDrop ? 'simple-popover' : undefined;
    const handleOpen = () => setOpen(true);
    const handleClose = () => {

        setOpen(false)
        setApplianceDifference({})
        setHisabDrop({})
        setHisabNotDrop({})
        setSelectPlan({})
        setPaymentLink("")
        setDeviceDifference(false)
    };

    useEffect(() => {
        if (deviceDifference === false) return
        setOpen(deviceDifference)

    }, [deviceDifference])

    useEffect(() => { }, [hisabDrop, hisabNotDrop])
    const handleCheckBox = (event, data) => {
        setSelectPlan(data)
    }
    const handlePlanDuration = (date) => {
        const data = date.split(" ")
        let text = date
        if (data.length === 8) {
            data[1] = moment(new Date(data[1])).format("DD-MM-YYYY")
            data[5] = moment(new Date(data[5])).format("DD-MM-YYYY")
            text = `${data[0]}  ${data[1]}  ${data[5]}`
        } else {
            text = `${data[0]}  ${data[1]}  ${data[3]}`
        }
        return text
    }
    useEffect(() => { console.log(hisabDrop,hisabNotDrop, "dde") }, [hisabDrop,hisabNotDrop])

    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/* <Button onClick={()=>setReload(!reload)}>Refresh</Button> */}
                    <div className='modalCross'>
                        <span className="crossIcon" onClick={() => {
                            handleClose()
                        }}
                        >
                            x
                        </span>
                    </div>

                    {
                        (sub.houseId === "") ?
                            <form onSubmit={(event) => handleDifferenceAppElectrician(event, "device", setApplianceDifference, setHisabDrop, setHisabNotDrop, sub, setSelectPlan, loginName,securityCoastApplinace)}>
                                {
                                    orderTyepes[2].value !== sub.orderType && (
                                        <>
                                            <label className="specific-label">Select Device Type</label>
                                            <div class="input-group mb-3">
                                                <select class="custom-select height-select"
                                                    name="deviceType"
                                                    autoComplete='deviceType'
                                                    defaultValue={finalSelectDeviceUpdate[0].value}
                                                >
                                                    {finalSelectDeviceUpdate.map((option) => (
                                                        <option value={option.value}>{option.value}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <label className="specific-label">Enter one Device Id</label>
                                            <div className="input-group mb-3">
                                                <input type="text"
                                                    name="deviceId"
                                                    autoComplete='current-deviceId'
                                                    className="form-control"
                                                    required
                                                    aria-label="referenceId"
                                                    aria-describedby="basic-addon1" />
                                            </div>
                                        </>)
                                }

                                {
                                    orderTyepes[0].value === sub.orderType && (
                                        <>
                                            <label>Plan Duration</label>
                                            <div class="input-group mb-3">
                                                <select className="custom-select height-select" required id="inputGroupSelect01"
                                                    name="planFrequency"
                                                    label="planFrequency"
                                                    autoComplete="current-planFrequency"
                                                    defaultValue={sub.planFrequency}
                                                // onChange={(ev)=>checkUpgradeDownGrade(ev.target.value)}
                                                >
                                                    {planDuration.map((option) => {
                                                        if (option.value !== "Test (Weekly)") {
                                                            return (
                                                                <option value={option.value}>{option.value}</option>
                                                            )
                                                        }
                                                    })}

                                                </select>

                                            </div>
                                        </>
                                    )}
                                <label className="specific-label">Installation</label>
                                <div className="input-group mb-3">
                                    <input type="text"
                                        name="installation"
                                        autoComplete='current-installation'
                                        className="form-control"
                                        aria-label="referenceId"
                                        aria-describedby="basic-addon1"
                                        defaultValue={sub.currentInstallation}
                                    />
                                </div>
                                <label className="specific-label">Security Cost Per Appliance</label>
                                <div className="input-group mb-3">
                                    <input type="text"
                                        name="securityCoastApplinace"
                                        autoComplete='current-securityCoastApplinace'
                                        className="form-control"
                                        aria-label="referenceId"
                                        aria-describedby="basic-addon1"
                                        value={securityCoastApplinace}
                                        onChange={(e) => setSecurityCoastApplinace(Number(e.target.value))}
                                    />
                                </div>
                                {
                                    orderTyepes[0].value === sub.orderType && (
                                        <>
                                            <label className="specific-label">Select Payment Mode</label>
                                            <div class="input-group ">
                                                <select class="custom-select height-select mb-2"
                                                    name="paymentMode"
                                                    autoComplete='current-paymentMode'
                                                    defaultValue={"zoho"}
                                                >
                                                    <option value={"zoho"}>{"Zoho"}</option>
                                                    <option value={"razorpay"}>RazorPay</option>
                                                </select>
                                            </div>
                                        </>
                                    )
                                }
                                <Button variant='contained' color='primary' mt={3} type='submit'>Next</Button>
                            </form> : <div>
                                <form onSubmit={(event) => handleDifferenceAppElectrician(event, "house", setApplianceDifference, setHisabDrop, setHisabNotDrop, sub, setSelectPlan, loginName,securityCoastApplinace)}>
                                    {
                                        orderTyepes[0].value === sub.orderType && (
                                            <>
                                                <label>Plan Duration</label>
                                                <div class="input-group">
                                                    <select className="custom-select height-select" required id="inputGroupSelect01"
                                                        name="planFrequency"
                                                        label="planFrequency"
                                                        autoComplete="current-planFrequency"
                                                        defaultValue={sub.planFrequency}
                                                    // onChange={(ev)=>checkUpgradeDownGrade(ev.target.value)}
                                                    >
                                                        {planDuration.map((option) => {
                                                            if (option.value !== "Test (Weekly)") {
                                                                return (
                                                                    <option value={option.value}>{option.value}</option>
                                                                )
                                                            }
                                                        })}

                                                    </select>

                                                </div>
                                            </>)
                                    }
                                    <label className="specific-label">Installation</label>
                                    <div className="input-group">
                                        <input type="text"
                                            name="installation"
                                            autoComplete='current-installation'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                            defaultValue={sub.currentInstallation}
                                        />
                                    </div>
                                    <label className="specific-label">Security Cost Per Appliance</label>
                                    <div className="input-group mb-3">
                                        <input type="text"
                                            name="securityCoastApplinace"
                                            autoComplete='current-securityCoastApplinace'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                            value={securityCoastApplinace}
                                            onChange={(e) => setSecurityCoastApplinace(Number(e.target.value))}
                                        />
                                    </div>
                                    {
                                        orderTyepes[0].value === sub.orderType && (
                                            <>
                                                <label className="specific-label">Select Payment Mode</label>
                                                <div class="input-group ">
                                                    <select class="custom-select height-select mb-2"
                                                        name="paymentMode"
                                                        autoComplete='current-paymentMode'
                                                        defaultValue={"zoho"}
                                                    >

                                                        <option value={"zoho"}>{"Zoho"}</option>
                                                        <option value={"razorpay"}>RazorPay</option>

                                                    </select>
                                                </div>
                                            </>
                                        )
                                    }

                                    <button className='btn btn-primary mt-3' type='submit'>Next</button>
                                </form>
                            </div>
                    }
                    <div>{
                        (Object.keys(applianceDifference).length !== 0) && (

                            <Stack mt={2} mb={2}>
                                <Stack direction={'row'} spacing={2}>
                                    <div>Un-Paid Appliance :-</div>
                                    <div>{applianceDifference.newAppliances}</div>
                                </Stack>
                                <Stack direction={'row'} spacing={2}>
                                    <div>Total Appliance In House :-</div>
                                    <div>{applianceDifference.totalAppliances}</div>
                                </Stack>
                            </Stack>)
                    }
                        {
                            (sub.partialPayment === false) && (
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={4}>
                                    {
                                        Object.keys(hisabNotDrop).length !== 0 && (
                                            <Stack>
                                                <h5>
                                                    <input type='radio' style={{ marginLeft: 0, margin: "3px" }} name="hisabDrop" onChange={(event) => handleCheckBox(event, hisabNotDrop)} />New Subscription of {hisabNotDrop.currentAppliances} appliances</h5>
                                                <Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Appliances :- </item>
                                                        <item>{hisabNotDrop.currentAppliances}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Subscription Amount :- </item>
                                                        <item>{Number(hisabNotDrop.currentRecursive).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Security :- </item>
                                                        <item>{Number(hisabNotDrop.currentSecurity)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Installation :- </item>
                                                        <item>{Number(hisabNotDrop.currentInstallation)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Sub Total (A) :- </item>
                                                        <item>{Number(hisabNotDrop.currentTotal).toFixed(2)}</item>
                                                    </Stack>

                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>CreditNote Amount (B) :- <Button aria-describedby={idDrop} onClick={handleClickDrop}>info</Button>:- </item>
                                                        <item>- {Number(hisabNotDrop.creditNoteActualAmount).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} spacing={2}>
                                                        <item>Advance Amount (C) :- </item>
                                                        <item></item>
                                                        <item></item>
                                                        <item>-₹ {Number(hisabNotDrop.partialAmount)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Overdue Amount (D) :- </item>
                                                        <item>+ {Number(hisabNotDrop.overdueAmount).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Total Amount to be Paid (A-B-C+D) :- </item>
                                                        <item>{Number(hisabNotDrop.paymentToTake).toFixed(2)}</item>
                                                    </Stack>
                                                </Stack>
                                                <Popover
                                                    id={idDrop}
                                                    open={openDrop}
                                                    anchorEl={anchorElDrop}
                                                    onClose={handleCloseDrop}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}

                                                >
                                                    {
                                                        hisabNotDrop.creditNoteDetails.length !== 0 ?
                                                            <Stack>
                                                                {
                                                                    hisabNotDrop.creditNoteDetails.map((note, i) => (
                                                                        <Stack direction={"column"} spacing={1} sx={{
                                                                            padding: "15px"
                                                                        }}>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Credit Note {i + 1}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Old Appliances</item>
                                                                                <item>{note.appliances}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Plan Duration</item>
                                                                                <item>{handlePlanDuration(note.planDuration)}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Un-Used Days</item>
                                                                                <item>{note.unusedDays}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Used Days</item>
                                                                                <item>{note.usedDays}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Paid Amount</item>
                                                                                <item>{note.paidAmount}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Used Amount</item>
                                                                                <item>{note.amountUtilized.toFixed(2)}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Balance Amount</item>
                                                                                <item>{note.creditAmount}</item>
                                                                            </Stack>

                                                                        </Stack>
                                                                    ))
                                                                }

                                                            </Stack> : null

                                                    }
                                                </Popover>
                                            </Stack>
                                        )
                                    }

                                    {
                                        Object.keys(hisabDrop).length !== 0 && (
                                            <Stack>
                                                <h5>
                                                    <input type='radio' style={{ marginLeft: 0, margin: "3px" }} name="hisabDrop" onChange={(event) => handleCheckBox(event, hisabDrop)} />1 Subscription of {hisabDrop.currentAppliances} appliances</h5>
                                                <Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Appliances :- </item>
                                                        <item>{hisabDrop.currentAppliances}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Subscription Amount :- </item>
                                                        <item>{Number(hisabDrop.currentRecursive).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Security :- </item>
                                                        <item>{Number(hisabDrop.currentSecurity)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Installation :- </item>
                                                        <item>{Number(hisabDrop.currentInstallation)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Sub Total (A) :- </item>
                                                        <item>{Number(hisabDrop.currentTotal).toFixed(2)}</item>
                                                    </Stack>

                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>CreditNote Amount (B) :- <Button aria-describedby={idDrop} onClick={handleClickDrop}>info</Button>:- </item>
                                                        <item>- {Number(hisabDrop.creditNoteActualAmount).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} spacing={2}>
                                                        <item>Advance Amount (C) :- </item>
                                                        <item></item>
                                                        <item></item>
                                                        <item>-₹ {Number(hisabDrop.partialAmount)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Overdue Amount (D) :- </item>
                                                        <item>+ {Number(hisabDrop.overdueAmount).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Total Amount to be Paid (A-B-C+D) :- </item>
                                                        <item>{Number(hisabDrop.paymentToTake).toFixed(2)}</item>
                                                    </Stack>
                                                </Stack>
                                                <Popover
                                                    id={idDrop}
                                                    open={openDrop}
                                                    anchorEl={anchorElDrop}
                                                    onClose={handleCloseDrop}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}

                                                >
                                                    {
                                                        hisabDrop.creditNoteDetails.length !== 0 ?
                                                            <Stack>
                                                                {
                                                                    hisabDrop.creditNoteDetails.map((note, i) => (
                                                                        <Stack direction={"column"} spacing={1} sx={{
                                                                            padding: "15px"
                                                                        }}>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Credit Note {i + 1}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Old Appliances</item>
                                                                                <item>{note.appliances}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Plan Duration</item>
                                                                                <item>{handlePlanDuration(note.planDuration)}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Un-Used Days</item>
                                                                                <item>{note.unusedDays}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Used Days</item>
                                                                                <item>{note.usedDays}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Paid Amount</item>
                                                                                <item>{note.paidAmount}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Used Amount</item>
                                                                                <item>{note.amountUtilized.toFixed(2)}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Balance Amount</item>
                                                                                <item>{note.creditAmount}</item>
                                                                            </Stack>

                                                                        </Stack>
                                                                    ))
                                                                }

                                                            </Stack> : null

                                                    }
                                                </Popover>
                                            </Stack>
                                        )
                                    }
                                </Stack>
                            )
                        }


                        {
                            (sub.partialPayment === true) && (
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={4}>

                                    {
                                        Object.keys(hisabDrop).length !== 0 && (
                                            <Stack>
                                                <h5>
                                                    <input type='radio' style={{ marginLeft: 0, margin: "3px" }} name="hisabDrop" onChange={(event) => handleCheckBox(event, hisabDrop)} />1 Subscription of {hisabDrop.currentAppliances} appliances</h5>
                                                <Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Appliances :- </item>
                                                        <item>{hisabDrop.currentAppliances}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Subscription Amount :- </item>
                                                        <item>{Number(hisabDrop.currentRecursive).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Security :- </item>
                                                        <item>{Number(hisabDrop.currentSecurity)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Installation :- </item>
                                                        <item>{Number(hisabDrop.currentInstallation)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Sub Total (A) :- </item>
                                                        <item>{Number(hisabDrop.currentTotal).toFixed(2)}</item>
                                                    </Stack>

                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>CreditNote Amount (B) :- <Button aria-describedby={idDrop} onClick={handleClickDrop}>info</Button>:- </item>
                                                        <item>- {Number(hisabDrop.creditNoteActualAmount).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} spacing={2}>
                                                        <item>Advance Amount (C)  </item>
                                                        <item></item>
                                                        <item></item>
                                                        <item>-₹ {Number(hisabDrop.partialAmount)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Overdue Amount (D) :- </item>
                                                        <item>+ {Number(hisabDrop.overdueAmount).toFixed(2)}</item>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                                        <item>Total Amount to be taken (A-B-C+D) :- </item>
                                                        <item>{Number(hisabDrop.paymentToTake).toFixed(2)}</item>
                                                    </Stack>
                                                </Stack>
                                                <Popover
                                                    id={idDrop}
                                                    open={openDrop}
                                                    anchorEl={anchorElDrop}
                                                    onClose={handleCloseDrop}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}

                                                >
                                                    {
                                                        hisabDrop.creditNoteDetails.length !== 0 ?
                                                            <Stack>
                                                                {
                                                                    hisabDrop.creditNoteDetails.map((note, i) => (
                                                                        <Stack direction={"column"} spacing={1} sx={{
                                                                            padding: "15px"
                                                                        }}>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Credit Note {i + 1}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Old Appliances</item>
                                                                                <item>{note.appliances}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Plan Duration</item>
                                                                                <item>{handlePlanDuration(note.planDuration)}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Un-Used Days</item>
                                                                                <item>{note.unusedDays}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Used Days</item>
                                                                                <item>{note.usedDays}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Paid Amount</item>
                                                                                <item>{note.paidAmount}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Used Amount</item>
                                                                                <item>{note.amountUtilized.toFixed(2)}</item>
                                                                            </Stack>
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                <item>Balance Amount</item>
                                                                                <item>{note.creditAmount}</item>
                                                                            </Stack>

                                                                        </Stack>
                                                                    ))
                                                                }

                                                            </Stack> : null
                                                    }
                                                </Popover>
                                            </Stack>
                                        )
                                    }


                                </Stack>
                            )
                        }


                        <Stack direction="row" spacing={2} mt={2}>
                            {
                                Object.keys(selectPlan).length !== 0 && (
                                    <>
                                        <Button
                                            variant="contained"
                                            color='primary'
                                            disabled={paymentLinkBtn === "Processing..." ? true : false}
                                            onClick={() => {
                                                if (sub.partialPayment === false) {
                                                    handeleDownGradeUpGradeGrivance(selectPlan, setPaymentLink, setText, sub, setPaymentLinkBtn, loginName)
                                                } else if (sub.partialPayment === true) {
                                                    getSubscriptionData(sub, selectPlan, setText, setWhatappNumber, setPaymentLink, setPaymentLinkBtn, loginName,securityCoastApplinace)
                                                }
                                                setPaymentLink("")
                                                setText('')
                                                setWhatappNumber('')
                                            }}>{paymentLinkBtn}</Button>
                                        {
                                            orderTyepes[0].value !== sub.orderType && (
                                                <Button
                                                    variant="contained"
                                                    color='primary'
                                                    onClick={() => {
                                                        setCashTransaction(true)

                                                    }}>{"Payment in Cash"}</Button>
                                            )
                                        }

                                    </>
                                )
                            }
                            {
                                paymentLink !== "" && (
                                    <>
                                        <ReactWhatsapp
                                            number={sub.phone}
                                            message={text}
                                            style={{
                                                backgroundColor: "#25D366",
                                                border: "none",
                                                borderRadius: "12px",
                                                padding: "5px",

                                            }}
                                            onClick={async () => {
                                                let latestSub = await getSubscriptionDataReturn(sub)
                                                if (latestSub.partialPayment === false) {
                                                    if (selectPlan.oldDrop === false) {
                                                        await installationDoneByElectrician(latestSub)
                                                        await removeElectricianFromSite(latestSub)
                                                    }

                                                } else if (latestSub.partialPayment === true) {
                                                    // await removeElectricianFromSite(latestSub)
                                                }
                                                handleClose()
                                                setReload(!reload)
                                            }}
                                        >
                                            <WhatsApp
                                                sx={{
                                                    color: "white",
                                                    verticalAlign: "middle",
                                                    fontSize: "xx-large",
                                                }}
                                            />
                                        </ReactWhatsapp>


                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={async () => {

                                                navigator['clipboard'].writeText(paymentLink)
                                                let latestSub = await getSubscriptionDataReturn(sub)
                                                if (latestSub.partialPayment === false) {
                                                    if (selectPlan.oldDrop === false) {
                                                        await installationDoneByElectrician(latestSub)
                                                        await removeElectricianFromSite(latestSub)
                                                    }

                                                } else if (latestSub.partialPayment === true) {
                                                    // await removeElectricianFromSite(latestSub)
                                                }
                                                handleClose()
                                                setReload(!reload)
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </>
                                )
                            }
                        </Stack>
                    </div>
                    {
                        cashTransaction && (
                            <Stack>
                                <form onSubmit={(e) => handleCash(e, sub)}>
                                    <label className="specific-label">Select Payment Type</label>
                                    <div class="input-group mb-3">
                                        <select class="custom-select height-select"
                                            name="paymentMode"
                                            autoComplete='current-paymentMode'
                                            defaultValue={"cash"}
                                        >

                                            <option value={"cash"}>Cash</option>
                                            <option value={"cheque"}>Cheque</option>
                                            <option value={"Bank Transfer"}>Bank Transfer</option>

                                        </select>
                                    </div>
                                    <label className="specific-label">Transaction Id</label>
                                    <div className="input-group">
                                        <input type="text"
                                            name="transactionId"
                                            autoComplete='current-transactionId'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                        />
                                    </div>
                                    <label className="specific-label">Transaction Amount</label>
                                    <div className="input-group">
                                        <input type="number"
                                            name="transactionAmount"
                                            autoComplete='current-transactionAmount'
                                            className="form-control"
                                            aria-label="referenceId"
                                            aria-describedby="basic-addon1"
                                        />
                                    </div>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </Stack>
                        )
                    }
                </Box>
            </Modal>
        </div>
    );
}