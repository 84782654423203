import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

function HexGroupOverdue({ data }) {
  const [hexWiseData, setHexWiseData] = useState({});
  const [finalData, setFinalData] = useState([]);
  console.log('[data]', data);
  useEffect(() => {
    const phoneNumberWiseData = {};
    for (const inv of data) {
      if (!phoneNumberWiseData[inv.phone]) {
        phoneNumberWiseData[inv.phone] = {
          invoices: [],
          totalInvoices: 0,
          totalDropped: 0,
          totalActive: 0,
        };
      }

      phoneNumberWiseData[inv.phone].invoices.push(inv);
    }

    for (const phoneNumber of Object.keys(phoneNumberWiseData)) {
      const processedHex = [];
      for (const hex of phoneNumberWiseData[phoneNumber].invoices) {
        // console.log('[hex]', hex);
        if (processedHex.includes(hex.generatedHex)) {
          continue;
        }
        processedHex.push(hex.generatedHex);

        phoneNumberWiseData[phoneNumber].totalInvoices += 1;

        if (hex.dropped) {
          phoneNumberWiseData[phoneNumber].totalDropped += 1;
        }

        if (!hex.dropped) {
          phoneNumberWiseData[phoneNumber].totalActive += 1;
        }
      }
    }

    setHexWiseData(phoneNumberWiseData);
  }, [data]);

  useEffect(() => {
    function generateData() {
      const finalData =
        Object.keys(hexWiseData).length > 0
          ? Object.keys(hexWiseData).map((phoneNumber) => {
              return hexWiseData[phoneNumber].invoices
                .sort((a, b) => b.amount - a.amount)
                .map((e, i) => {
                  return {
                    ...e,
                    totalInvoices: hexWiseData[phoneNumber].totalInvoices,
                    totalDropped: hexWiseData[phoneNumber].totalDropped,
                    totalActive: hexWiseData[phoneNumber].totalActive,
                  };
                });
            })
          : [];

      setFinalData(finalData);
    }

    generateData();
  }, []);
  return (
    <div>
      <CSVLink
        className='ml-1'
        data={finalData}
        filename={`${moment(new Date()).format('DD-MMM-YYYY')}_Hex_Wise.csv`}
      >
        <button className='btn btn-primary'>Download Csv</button>
      </CSVLink>
      {Object.keys(hexWiseData).length > 0 && (
        <table className='table table-sm table-bordered mt-3'>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th scope='col'>Name</th>
              <th>Hex</th>
              <th>Amount</th>
              <th>Total</th>
              <th>Total Dropped</th>
              <th>Total Active</th>
              <th>To Be Picked</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(hexWiseData).map((phoneNumber) => {
              return hexWiseData[phoneNumber].invoices
                .sort((a, b) => b.amount - a.amount)
                .map((e, i) => {
                  return (
                    <tr key={i}>
                      {console.log('[e]', e)}
                      <td>{i + 1}</td>
                      <td
                        className='pr-15 pl-55'
                        style={{ fontWeight: 'bold' }}
                      >
                        {e.name}
                      </td>
                      <td>{e.generatedHex}</td>
                      <td>{e.amount}</td>
                      <td>{hexWiseData[phoneNumber].totalInvoices}</td>
                      <td>{hexWiseData[phoneNumber].totalDropped}</td>
                      <td>{hexWiseData[phoneNumber].totalActive}</td>
                      <td>{e.toBePickedUp}</td>
                    </tr>
                  );
                });
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default HexGroupOverdue;
