import axios from 'axios';
import { pages, serverUrl } from '../../ApiUrl';
import { notify, notifyFailed } from '../../Common/Notification';
import { subHistory } from '../../Function/SubscriptionHistory';
import {
  compareFrequency,
  orderTyepes,
  planCost,
  typeOFClient,
} from '../../Data';
import store from '../../Redux';
import { revokeReInstallation } from '../../SearchClient/RevokeReInstallation';

const handleCalculateRecursiveAmount = async (sub, app, planFrequency) => {
  let createdDate = new Date();
  await axios
    .post(`${serverUrl.u}/api/tele/getFirstRegistrationDate`, {
      phone: sub.phone,
    })
    .then(function (res) {
      console.log(res.data.data);
      createdDate = res.data.data;
    })
    .catch(function (err) {
      console.log(err);
    });
  let fee = 0;
  if (planFrequency === 'Monthly') {
    if (new Date(createdDate) > new Date('2023-07-25T00:00:00')) {
      fee = app * planCost.Monthly * 30;
    } else {
      fee = app * 30;
    }
  } else if (planFrequency === 'Quarterly') {
    fee = app * planCost.Quarterly * 90;
  } else if (planFrequency === 'Semi Annualy') {
    fee = app * planCost.SemiAnnualy * 180;
  } else if (planFrequency === 'Annualy') {
    fee = app * planCost.Annualy * 365;
  } else if (planFrequency === 'Annualy-3') {
    fee = app * planCost.Annualy * 365;
  } else if (planFrequency === 'Test (Weekly)') {
    fee = app * planCost.TestWeekly * 7;
  } else if (planFrequency === 'Two Years') {
    fee = app * planCost.TwoYears * (365 * 2);
  } else if (planFrequency === 'Three Years') {
    fee = app * planCost.ThreeYears * (365 * 3);
  } else if (planFrequency === 'Quarterly2') {
    fee = app * planCost.Quarterly2 * 90;
  } else if (planFrequency === 'Semi Annualy2') {
    fee = app * planCost.SemiAnnualy2 * 180;
  } else if (planFrequency === 'Annualy2') {
    fee = app * planCost.Annualy2 * 365;
  } else if (planFrequency === 'Three Years2') {
    fee = app * planCost.ThreeYears2 * (365 * 3);
  } else if (app * planCost['Five Years']) {
    fee = app * planCost['Five Years'] * (365 * 5);
  }
  return fee;
};

export const installationDoneByElectrician = async (
  sub,
  allDone = false,
  upsell = false,
  page = ''
) => {
  sub = await getSubscriptionDataReturn(sub);
  if (sub.siteDone === true && page === '') {
    subHistory(
      `Site is already Done`,
      sub,
      'Site is already Done',
      'Site is already Done'
    );
    return;
  }
  if (sub.houseId === '' && upsell === false) {
    notifyFailed('House Id Not available update house id first');
    return;
  }
  await axios
    .post(`${serverUrl.u}/api/dispatch/installationDoneByElectrication`, {
      generatedHex: sub.generatedHex,
      houseId: sub.houseId,
      firstTime: sub.finalInstalled === false ? true : false,
      allDone: allDone,
      is_Upsell: upsell,
    })
    .then(function (res) {
      console.log(res);
      if (res.data.success === true) {
        notify('Installation Done By Electrician');
        if (page !== '') {
          revokeReInstallation(sub, pages.installation);
          subHistory(
            'Installation Done On Installation Page',
            sub,
            'Installation Done On Installation Page',
            ''
          );
          if (page === 'true') {
            window.location.reload();
          }
        }
        if (sub.finalInstalled === false) {
          subHistory(
            'First Installation Complete',
            sub,
            'First Installation Complete',
            sub.InstallationDate
          );
        } else if (sub.grivience === true) {
          subHistory(
            `Final Installation Completed by ${
              store.getState().sar.data.user.first_name
            }`,
            sub,
            'Grivience Site Installation Completed by Electrician',
            'Final Installation clicked by Electrician'
          );
        } else {
          subHistory(
            `Final Installation Completed by ${
              store.getState().sar.data.user.first_name
            }`,
            sub,
            'Installation Completed by Electrician',
            'Final Installation clicked by Electrician'
          );
        }
      } else {
        notifyFailed(res.data.message);
      }
    })
    .catch(function (err) {
      console.log(err);
      notifyFailed('Error Occuer in Installation Done By Electrician');
    });
};

export const removeElectricianFromSite = async (sub) => {
  return;
  await axios
    .post(`${serverUrl.u}/api/dispatch/removeElecFromSiteAfterInstallation`, {
      generatedHex: sub.generatedHex,
    })
    .then(function (res) {
      console.log(res);
      if (res.data.success === true) {
        notify(res.data.message);
        subHistory(
          `Electrician remvoe from site`,
          sub,
          'Electrician Remove from site',
          ''
        );
      } else {
        notifyFailed(res.data.message);
      }
    })
    .catch(function (err) {
      console.log(err);
      notifyFailed('Error Occuer in Remove Electrician');
    });
};

export const zohoLink = async (
  sub,
  setText,
  setWhatappNumber,
  setPaymentLink,
  appliance,
  security,
  installation,
  total,
  newDate,
  setPaymentLinkBtn,
  planFrequency,
  arrayInvoice,
  loginName,
  securityCoastApplinace
) => {
  if (planFrequency === undefined) {
    notifyFailed('Plan Not Selected');
    setPaymentLinkBtn('Failed');
    return;
  }
  return await axios
    .post(`${serverUrl.u}/api/partialSubscription/paymentLink`, {
      generatedHex: sub.generatedHex,
      totalAppliances: appliance,
      security: Number(security),
      planFrequency: planFrequency,
      planId: sub.planId,
      installation: Number(installation),
      collectOverdue: true,
      applyCreditNotes: true,
      postPayment: true,
      invoiceArray: arrayInvoice,
    })
    .then(function (response) {
      if (response.data.success === false) {
        notifyFailed(response.data.message);
        setPaymentLinkBtn('Failed');
      }
      console.log(
        response,
        'response1',
        response.data.data.subscription.generatedHex
      );
      subHistory(
        'Zoho Link Created',
        {
          ...sub,
          payload: {
            generatedHex: sub.generatedHex,
            totalAppliances: appliance,
            security: Number(security),
            planFrequency: planFrequency,
            planId: sub.planId,
            installation: Number(installation),
            collectOverdue: true,
            applyCreditNotes: true,
            postPayment: true,
            invoiceArray: arrayInvoice,
          },
        },
        'Complete Zoho Link Created',
        ''
      );
      if (response.data.url !== '') {
        setWhatappNumber(sub.phone);
        console.log(response.data.data.subscription.totalAmount, '1');
        setText(`Hey ${sub.name},
               Book your Aliste Smart Home in a few clicks by carrying out a payment on the following link :  ${serverUrl.u}${serverUrl.partialPay}${response.data.data.subscription.generatedHex}`);

        setPaymentLink(
          `${serverUrl.u}${serverUrl.partialPayZoho}${response.data.data.subscription.generatedHex}`
        );
        setPaymentLinkBtn('Done');
      }
    })
    .catch(function (error) {
      console.log(error);
      notifyFailed(error.message);
      setPaymentLinkBtn('Failed');
      notifyFailed(error.response.data.message);
    });
};

export const razorpayLink = async (
  sub,
  setText,
  setWhatappNumber,
  setPaymentLink,
  appliance,
  security,
  installation,
  total,
  newDate,
  setPaymentLinkBtn,
  planFrequency,
  arrayInvoice,
  loginName
) => {
  if (planFrequency === undefined) {
    notifyFailed('Plan Not Selected');
    setPaymentLinkBtn('Failed');
    return;
  }
  return await axios
    .post(`${serverUrl.u}/api/tele/createRazorFullPayment`, {
      generatedHex: sub.generatedHex,
      totalAppliances:
        orderTyepes[1].value === sub.orderType
          ? Number(sub.totalAppliances)
          : appliance,
      planFrequency: planFrequency,
      security: orderTyepes[0].value === sub.orderType ? security : 0,
      installation: orderTyepes[2].value === sub.orderType ? 0 : installation,
      collectOverdue: orderTyepes[0].value === sub.orderType ? true : false,
      applyCreditNotes: orderTyepes[0].value !== sub.orderType ? false : true,
      postPayment: orderTyepes[0].value === sub.orderType ? false : true,
      invoiceArray: orderTyepes[0].value === sub.orderType ? arrayInvoice : [],
      upsellCapex: orderTyepes[0].value !== sub.orderType ? true : false,
    })
    .then(function (response) {
      if (
        response.data.message === 'payment link create' ||
        response.data.message === 'ok'
      ) {
        if (response.data.url !== '') {
          setWhatappNumber(sub.phone);
          setText(`Hey ${sub.name},
      Book your Aliste Smart Home in a few clicks by carrying out a payment on the following link :  ${serverUrl.u}${serverUrl.partialPay}${sub.generatedHex}`);
          //   navigator.clipboard.writeText(`${serverUrl.u}${serverUrl.partialPay}${sub.generatedHex}`);
          setPaymentLink(
            `${serverUrl.u}${serverUrl.partialPay}${sub.generatedHex}`
          );
        }
        subHistory(
          'RazorPay Link Created',
          {
            ...sub,
            payload: {
              generatedHex: sub.generatedHex,
              totalAppliances:
                orderTyepes[1].value === sub.orderType
                  ? Number(sub.totalAppliances)
                  : appliance,
              planFrequency: planFrequency,
              security: orderTyepes[0].value === sub.orderType ? security : 0,
              installation:
                orderTyepes[2].value === sub.orderType ? 0 : installation,
              collectOverdue:
                orderTyepes[0].value === sub.orderType ? true : false,
              applyCreditNotes: true,
              postPayment:
                orderTyepes[0].value === sub.orderType ? false : true,
              invoiceArray:
                orderTyepes[0].value === sub.orderType ? arrayInvoice : [],
              upsellCapex:
                orderTyepes[0].value !== sub.orderType ? true : false,
            },
          },
          'RazorPay Link Created',
          ''
        );
        setPaymentLinkBtn('Done');
      } else {
        notifyFailed(response.data.message);
        setPaymentLinkBtn('Failed');
      }
    })
    .catch(function (error) {
      console.log(error);
      setPaymentLinkBtn('Failed');
      notifyFailed(error.response.data.message);
    });
};

const handleZohoAndRazorPayLink = async (
  sub,
  selectPlan,
  setText,
  setWhatappNumber,
  setPaymentLink,
  setPaymentLinkBtn,
  arrayInvoice,
  loginName,
  securityCoastApplinace
) => {
  let appliance = sub.totalAppliances;
  let security = sub.currentSecurity;
  let installation = sub.currentInstallation;
  let fee = await handleCalculateRecursiveAmount(
    sub,
    appliance,
    selectPlan.planFrequency
  );
  let total = (Number(fee) + Number(installation)) * 1.18 + Number(security);
  let newDate = new Date();
  console.log(selectPlan, 'mode');

  if (total <= 15000 && selectPlan.paymentMode === 'zoho') {
    zohoLink(
      sub,
      setText,
      setWhatappNumber,
      setPaymentLink,
      appliance,
      security,
      installation,
      total,
      newDate,
      setPaymentLinkBtn,
      selectPlan.planFrequency,
      arrayInvoice,
      loginName,
      securityCoastApplinace
    );
  } else {
    razorpayLink(
      sub,
      setText,
      setWhatappNumber,
      setPaymentLink,
      appliance,
      security,
      installation,
      total,
      newDate,
      setPaymentLinkBtn,
      selectPlan.planFrequency,
      arrayInvoice,
      loginName.securityCoastApplinace
    );
  }
};

export const getSubscriptionData = (
  sub,
  selectPlan,
  setText,
  setWhatappNumber,
  setPaymentLink,
  setPaymentLinkBtn,
  loginName,
  securityCoastApplinace
) => {
  setPaymentLinkBtn('Processing...');
  axios
    .post(`${serverUrl.u}/api/tele/getDataWithHex`, {
      generatedHex: sub.generatedHex,
    })
    .then(async function (res) {
      console.log(res);
      let updateSub = res.data.alisteSubscription;
      let array = [];
      async function getInvoices() {
        await axios
          .post(`${serverUrl.u}/api/tele/getAllOverdueInvoice`, {
            phone: sub.phone,
          })
          .then(function (res) {
            console.log(res);
            if (res.data.success === true) {
              notify(res.data.message);
              for (let i of res.data.finalInvoice) {
                array.push({
                  zohoInvoiceNumber: i.zohoInvoiceNumber,
                  generatedHex: i.generatedHex,
                  amount: i.actualTotalAmount,
                  type: 'overDue',
                  creditNotesNumber: [],
                });
              }
            } else {
              notify(res.data.message);
            }
          })
          .catch(function (err) {
            console.log(err);
            notify(err.response.data.message);
          });
      }
      await getInvoices();
      handleZohoAndRazorPayLink(
        updateSub,
        selectPlan,
        setText,
        setWhatappNumber,
        setPaymentLink,
        setPaymentLinkBtn,
        array,
        loginName,
        securityCoastApplinace
      );
    })
    .catch(function (err) {
      console.log(err);
    });
};

export const getSubscriptionDataReturn = async (sub) => {
  return await axios
    .post(`${serverUrl.u}/api/tele/getDataWithHex`, {
      generatedHex: sub.generatedHex,
    })
    .then(function (res) {
      console.log(res);
      let updateSub = res.data.alisteSubscription;
      return updateSub;
    })
    .catch(function (err) {
      console.log(err);
      return sub;
    });
};

const updateCapexSirt = async (sub, newApp, installation) => {
  if (newApp < 0) {
    newApp = Number(sub.totalAppliances) + Number(newApp);
  }
  let payload = {
    generatedHex: sub.generatedHex,
    totalAppliances: Number(newApp),
    installation: installation,
    perApplianceCost: sub.perApplianceCost,
  };
  await axios
    .post(`${serverUrl.u}/api/capex/updateDevices`, payload)
    .then(function (res) {
      subHistory(
        'Capex Client sirt update by Electrician',
        { ...sub, payload: payload },
        'Capex Sirt Updated',
        ''
      );
      console.log(res);
    })
    .catch(function (err) {
      console.log(err);
    });
};

const updateSirt = async (
  sub,
  newApp,
  installation,
  planFrequency,
  securityCoastApplinace
) => {
  let diff = 0;
  let app = 0;
  let oldApp = 0;
  if (sub.changesInSubscription.length > 0) {
    oldApp =
      sub.changesInSubscription[sub.changesInSubscription.length - 1]
        .oldAppliances;
  }

  let totalApp = newApp + oldApp;
  if (totalApp <= 0) {
    notifyFailed('contact admin');
    window.location.reload();
    return;
  }
  await axios
    .post(`${serverUrl.u}/api/tele/updateSIRT`, {
      generatedHex: sub.generatedHex,
      planId: sub.planId,
      planFrequency: planFrequency,
      totalAppliances: totalApp,
      security:
        Number(newApp) > 0
          ? Number(newApp) * Number(securityCoastApplinace)
          : 0,
      installation: installation,
    })
    .then(function (res) {
      if (res.data.success === true) {
        notify(res.data.message);
        if (
          sub.planFrequency !== planFrequency &&
          totalApp !== sub.totalAppliances
        ) {
          if (
            totalApp < sub.totalAppliances &&
            compareFrequency[planFrequency] <
              compareFrequency[sub.planFrequency]
          ) {
            subHistory(
              `Partial Subscription DowGrade Appliances & Frequency`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          } else if (
            totalApp > sub.totalAppliances &&
            compareFrequency[planFrequency] >
              compareFrequency[sub.planFrequency]
          ) {
            subHistory(
              `Partial Subscription UpGrage Appliances & Frequency`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          } else if (
            totalApp < sub.totalAppliances &&
            compareFrequency[planFrequency] >
              compareFrequency[sub.planFrequency]
          ) {
            subHistory(
              `Partial Subscription DownGrade Appliances & UpGrade Frequency`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          } else if (
            totalApp > sub.totalAppliances &&
            compareFrequency[planFrequency] <
              compareFrequency[sub.planFrequency]
          ) {
            subHistory(
              `Partial Subscription UpGrade Appliances & DownGrade Frequency`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          } else {
            subHistory(
              `Partial Subscription DownGrade/Upgrade Both`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          }
        } else if (totalApp !== sub.totalAppliances) {
          if (totalApp < sub.totalAppliances) {
            subHistory(
              `Partial Subscription DowGrade Appliances`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          } else {
            subHistory(
              `Partial Subscription UpGrage Appliances`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          }
        } else if (planFrequency !== sub.planFrequency) {
          if (
            compareFrequency[planFrequency] <
            compareFrequency[sub.planFrequency]
          ) {
            subHistory(
              `Partial Subscription DownGrade in Frequency`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          } else if (
            compareFrequency[planFrequency] >
            compareFrequency[sub.planFrequency]
          ) {
            subHistory(
              `Partial Subscription Upgrade in Frequency`,
              {
                ...sub,
                payload: {
                  generatedHex: sub.generatedHex,
                  planId: sub.planId,
                  planFrequency: planFrequency,
                  totalAppliances: totalApp,
                  security:
                    Number(newApp) > 0
                      ? Number(newApp) * Number(securityCoastApplinace)
                      : 0,
                  installation: installation,
                },
              },
              'Partial Subscription UpGrade/DownGrade',
              ''
            );
          }
        }
      } else {
        notify(res.data.message);
      }
    })
    .catch(function (err) {
      console.log(err);
      notify(err.response.data.message);
    });
};

const getHisab = async (
  app,
  installation,
  oldDrop,
  setHisab,
  sub,
  paymentMode,
  planFrequency,
  setSelectPlan,
  securityCoastApplinace
) => {
  let newAppliances = app;
  let newTotalAppliances = app;
  let collectSecurity = true;

  if (sub.partialPayment === false) {
    if (Number(sub.totalAppliances) !== Number(app)) {
      if (oldDrop === true) {
        if (Number(app) > -1) {
          newAppliances = Number(app);
          newTotalAppliances = Number(app) + Number(sub.totalAppliances);
        } else {
          newAppliances = Number(app);
          collectSecurity = false;
        }
      } else if (oldDrop === false) {
        newAppliances = app;
        console.log(newAppliances, '3');
      }
    } else if (Number(sub.totalAppliances) === Number(app)) {
      notify('All Payment Clear');
      newAppliances = 0;
      console.log(newAppliances, '4');
    }
  } else if (sub.partialPayment === true) {
    newAppliances = app;
  }

  await axios
    .post(`${serverUrl.u}/api/tele/calculateClientAmount`, {
      generatedHex: sub.generatedHex,
      totalAppliances: newAppliances,
      oldDrop: oldDrop,
      installation: installation,
      newPlanFrequency: planFrequency,
      collectSecurity: collectSecurity,
      orderType: sub.orderType,
      perApplianceCost: securityCoastApplinace,
    })
    .then(async function (res) {
      if (res.data.success === true) {
        notify('Details Fetch');
        setHisab({
          ...res.data.data,
          newAppliances,
          newTotalAppliances,
          oldDrop: oldDrop,
          reasonOfSubChange: oldDrop
            ? Number(sub.totalAppliances) > newTotalAppliances
              ? 'downGrade'
              : 'upgrade'
            : 'upgrade',
          generatedHex: sub.generatedHex,
          planFrequency: planFrequency,
          paymentMode: paymentMode,
        });

        subHistory(
          `client Hisab By Electrician`,
          {
            ...res.data.data,
            ...sub,
            newAppliances,
            newTotalAppliances,
            oldDrop: oldDrop,
            reasonOfSubChange: oldDrop
              ? Number(sub.totalAppliances) > newTotalAppliances
                ? 'downGrade'
                : 'upgrade'
              : 'upgrade',
            generatedHex: sub.generatedHex,
            planFrequency: planFrequency,
            paymentMode: paymentMode,
          },
          sub.orderType,
          ''
        );

        setSelectPlan({
          ...res.data.data,
          newAppliances,
          newTotalAppliances,
          oldDrop: oldDrop,
          reasonOfSubChange: oldDrop
            ? Number(sub.totalAppliances) > newTotalAppliances
              ? 'downGrade'
              : 'upgrade'
            : 'upgrade',
          generatedHex: sub.generatedHex,
          planFrequency: planFrequency,
          paymentMode: paymentMode,
        });

        if (sub.partialPayment === true) {
          if (orderTyepes[0].value === sub.orderType) {
            await updateSirt(
              sub,
              newAppliances,
              installation,
              planFrequency,
              securityCoastApplinace
            );
          }
          await installationDoneByElectrician(sub);
        }
      } else {
        setHisab({});
        notifyFailed('Failed');
      }
    })
    .catch(function (err) {
      setHisab({});
      notifyFailed('Error Occur in hisab');
    });
};
export const handleCash = async (event, sub) => {
  event.preventDefault();

  const data = new FormData(event.currentTarget);
  let detail = {
    payment_mode: data.get('paymentMode'),
    gateway_transaction_id: data.get('transactionId'),
    amount: Number(data.get('transactionAmount')),
    uniqueId: 1,
  };
  console.log(detail, 'ffrrffr');
  if (
    detail.gateway_transaction_id === undefined ||
    detail.gateway_transaction_id.length === 0
  ) {
    notifyFailed('Enter Transaction Id');
    return;
  }
  if (detail.amount === undefined) {
    notifyFailed('Enter Transaction Amount');
    return;
  }
  await collectCashPaymentUpSellCapax(sub.generatedHex, [detail]);
  installationDoneByElectrician(
    sub,
    false,
    sub.orderType === orderTyepes[2].value ? true : false
  );
};

export const handleDifferenceAppElectrician = async (
  event,
  type,
  setApplianceDifference,
  setHisabDrop,
  setHisabNotDrop,
  sub,
  setSelectPlan,
  loginName,
  securityCoastApplinace
) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);
  let deviceInfo = {
    deviceType: data.get('deviceType'),
    deviceId: data.get('deviceId'),
    installation: Number(data.get('installation')),
    paymentMode: data.get('paymentMode'),
    planFrequency: data.get('planFrequency'),
  };
  if (orderTyepes[2].value === sub.orderType) {
    await getHisab(
      1,
      deviceInfo.installation,
      true,
      setHisabDrop,
      sub,
      'razorpay',
      deviceInfo.planFrequency,
      setSelectPlan,
      securityCoastApplinace
    );
    return;
  }
  console.log(deviceInfo, 'deviceInfo');

  const deviceIdDifference = () => {
    axios
      .post(`${serverUrl.u}/api/dispatch/countTotalNumberOfDevice`, {
        generatedHex: sub.generatedHex,
        deviceId: deviceInfo.deviceId,
        type: deviceInfo.deviceType,
      })
      .then(async function (res) {
        console.log(res);

        if (res.data.success === true) {
          subHistory(
            `Appliance Difference subscription appliance ${sub.totalAppliances} and house appliance Diff ${res.data.newAppliances} .`,
            sub,
            'Appliance Difference',
            `${res.data.newAppliances}`
          );
          setApplianceDifference(res.data);
          if (res.data.newAppliances === 0) {
            await installationDoneByElectrician(sub, true);
            await removeElectricianFromSite(sub);
            // subHistory("Grievence Done By Electrician",sub,"Grievence Done",sub.InstallationDate)
            window.location.reload();
            notify('All Payment Clear Plz Refersh');
            return;
          }
          if (
            !['Monthly', 'Quarterly', 'Quarterly2'].includes(
              sub.planFrequency
            ) &&
            res.data.newAppliances <= 0
          ) {
            await installationDoneByElectrician(sub, true);
            await removeElectricianFromSite(sub);
            // subHistory("Grievence Done By Electrician",sub,"Grievence Done",sub.InstallationDate)
            window.location.reload();
            notify('All Payment Clear Plz Refersh');
            return;
          }
          if (
            sub.partialPayment === false &&
            orderTyepes[2].value !== sub.orderType
          ) {
            if (orderTyepes[2].value === sub.orderType) {
              await installationDoneByElectrician(sub, false, true);
              await getHisab(
                res.data.newAppliances,
                deviceInfo.installation,
                true,
                setHisabDrop,
                sub,
                deviceInfo.paymentMode,
                deviceInfo.planFrequency,
                setSelectPlan,
                securityCoastApplinace
              );

              setSelectPlan({
                installation: deviceInfo.installation,
                paymentMode: 'razorpay',
                planFrequency: deviceInfo.planFrequency,
              });
              return;
            }
            if (orderTyepes[0].value === sub.orderType) {
              if (res.data.newAppliances > -1) {
                getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  false,
                  setHisabNotDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              } else {
                getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  true,
                  setHisabDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              }
            } else if (orderTyepes[1].value === sub.orderType) {
              await installationDoneByElectrician(sub, false, false);
              await updateCapexSirt(
                sub,
                res.data.newAppliances,
                Number(deviceInfo.installation)
              );

              if (res.data.newAppliances > -1) {
                await getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  false,
                  setHisabNotDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              } else {
                await getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  true,
                  setHisabDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              }
              setSelectPlan({
                installation: deviceInfo.installation,
                paymentMode: 'zoho',
                planFrequency: deviceInfo.planFrequency,
              });
              return;
            }
          } else if (sub.partialPayment === true) {
            if (orderTyepes[2].value === sub.orderType) {
              await installationDoneByElectrician(sub, false, true);
              await getHisab(
                res.data.newAppliances,
                deviceInfo.installation,
                true,
                setHisabDrop,
                sub,
                deviceInfo.paymentMode,
                deviceInfo.planFrequency,
                setSelectPlan,
                securityCoastApplinace
              );

              setSelectPlan({
                installation: deviceInfo.installation,
                paymentMode: 'zoho',
                planFrequency: deviceInfo.planFrequency,
              });
            } else {
              if (orderTyepes[0].value === sub.orderType) {
                getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  true,
                  setHisabDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              } else if (orderTyepes[1].value === sub.orderType) {
                await installationDoneByElectrician(sub, false, false);
                await updateCapexSirt(
                  sub,
                  res.data.newAppliances,
                  Number(deviceInfo.installation)
                );
                await getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  true,
                  setHisabDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
                setSelectPlan({
                  installation: deviceInfo.installation,
                  paymentMode: 'razorpay',
                  planFrequency: deviceInfo.planFrequency,
                });
                return;
              }
            }
          }
        } else {
          setApplianceDifference({});
          setHisabDrop({});
          setHisabNotDrop({});
          setSelectPlan({});
          notifyFailed('Failed');
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err.response.data.message);
        setApplianceDifference({});
        setHisabDrop({});
        setSelectPlan({});
        setHisabNotDrop({});
      });
  };
  const houseDifference = () => {
    axios
      .post(`${serverUrl.u}/api/tele/countApplianceInHouse`, {
        houseId: sub.houseId,
        generatedHex: sub.generatedHex,
      })
      .then(async function (res) {
        console.log(res, 'appliance difference');

        if (res.data.success === true) {
          console.log(
            sub.totalAppliances,
            res.data.newAppliances,
            sub,
            loginName,
            'test'
          );
          subHistory(
            `Appliance Difference subscription appliance ${sub.totalAppliances} and house appliance Diff ${res.data.newAppliances} .`,
            sub,
            'Appliance Difference',
            `${res.data.newAppliances}`
          );
          setApplianceDifference(res.data);
          if (res.data.newAppliances === 0) {
            await installationDoneByElectrician(sub, true);
            await removeElectricianFromSite(sub);
            // subHistory("Grievence Done By Electrician",sub,"Grievence Done",sub.InstallationDate)
            window.location.reload();
            notify('All Payment Clear Plz Refersh');

            return;
          }
          if (
            !['Monthly', 'Quarterly', 'Quarterly2'].includes(
              sub.planFrequency
            ) &&
            res.data.newAppliances <= 0
          ) {
            await installationDoneByElectrician(sub, true);
            await removeElectricianFromSite(sub);
            // subHistory("Grievence Done By Electrician",sub,"Grievence Done",sub.InstallationDate)
            window.location.reload();
            notify('All Payment Clear Plz Refersh');
            return;
          }
          if (sub.partialPayment === false) {
            if (
              res.data.newAppliances === 0 &&
              orderTyepes[2].value !== sub.orderType
            ) {
              await installationDoneByElectrician(sub, true, false);
              await removeElectricianFromSite(sub);
              window.location.reload();
              // subHistory("Grievence Done By Electrician",sub,"Grievence Done",sub.InstallationDate)
              notify('All Payment Clear Plz Refersh');
              return;
            }
            if (orderTyepes[2].value === sub.orderType) {
              await installationDoneByElectrician(sub, false, true);
              if (res.data.newAppliances > -1) {
                await getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  false,
                  setHisabNotDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              } else {
                await getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  true,
                  setHisabDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              }
              setSelectPlan({
                installation: deviceInfo.installation,
                paymentMode: 'razorpay',
                planFrequency: deviceInfo.planFrequency,
              });
              return;
            }
            if (orderTyepes[0].value === sub.orderType) {
              if (res.data.newAppliances > -1) {
                getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  false,
                  setHisabNotDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              } else {
                getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  true,
                  setHisabDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              }
            } else if (orderTyepes[1].value === sub.orderType) {
              await installationDoneByElectrician(sub, false, false);
              await updateCapexSirt(
                sub,
                res.data.newAppliances,
                Number(deviceInfo.installation)
              );

              await getHisab(
                res.data.newAppliances,
                deviceInfo.installation,
                true,
                setHisabDrop,
                sub,
                deviceInfo.paymentMode,
                deviceInfo.planFrequency,
                setSelectPlan,
                securityCoastApplinace
              );
              if (res.data.newAppliances > -1) {
                await getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  false,
                  setHisabNotDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              }
              setSelectPlan({
                installation: deviceInfo.installation,
                paymentMode: 'razorpay',
                planFrequency: deviceInfo.planFrequency,
              });
              return;
            }
          } else if (sub.partialPayment === true) {
            if (orderTyepes[2].value === sub.orderType) {
              await installationDoneByElectrician(sub, false, true);
              setSelectPlan({
                installation: deviceInfo.installation,
                paymentMode: 'razorpay',
                planFrequency: deviceInfo.planFrequency,
              });
            } else {
              if (orderTyepes[0].value === sub.orderType) {
                getHisab(
                  res.data.newAppliances,
                  deviceInfo.installation,
                  true,
                  setHisabDrop,
                  sub,
                  deviceInfo.paymentMode,
                  deviceInfo.planFrequency,
                  setSelectPlan,
                  securityCoastApplinace
                );
              } else if (orderTyepes[1].value === sub.orderType) {
                await installationDoneByElectrician(sub, false, false);
                await updateCapexSirt(
                  sub,
                  res.data.newAppliances,
                  Number(deviceInfo.installation)
                );

                setSelectPlan({
                  installation: deviceInfo.installation,
                  paymentMode: 'razorpay',
                  planFrequency: deviceInfo.planFrequency,
                });
                return;
              }
            }
          }
        } else {
          notify(res.data.message);
          setApplianceDifference({});
          setSelectPlan({});
        }
      })
      .catch(function (err) {
        console.log(err);
        notify('House Not found');
        setApplianceDifference({});
        setSelectPlan({});
      });
  };
  if (type === 'device') {
    deviceIdDifference();
  } else {
    houseDifference();
  }
};

export const handeleDownGradeUpGradeGrivance = async (
  plan,
  setPaymentLink,
  setText,
  sub,
  setPaymentLinkBtn,
  loginName
) => {
  if (plan.reasonOfSubChange !== 'upgrade') {
    let d = sub.totalAppliances + plan.newAppliances;
    if (d <= 0) {
      notifyFailed(
        'Subscription Not Have Much Appliace to from downgrade Contact Admin'
      );
      return;
    }
  }

  let array = [];
  async function getInvoices() {
    await axios
      .post(`${serverUrl.u}/api/tele/getAllOverdueInvoice`, {
        phone: sub.phone,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.success === true) {
          notify(res.data.message);
          for (let i of res.data.finalInvoice) {
            array.push({
              zohoInvoiceNumber: i.zohoInvoiceNumber,
              generatedHex: i.generatedHex,
              amount: i.actualTotalAmount,
              type: 'overDue',
              creditNotesNumber: [],
            });
          }
        } else {
          notify(res.data.message);
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err.response.data.message);
      });
  }
  await getInvoices();
  setPaymentLinkBtn('Processing...');
  if (plan.newAppliances === undefined || plan.newAppliances === null) {
    notifyFailed('Select Plan First');
    setPaymentLinkBtn('Done');
    return;
  }
  let payload = {
    generatedHex: plan.generatedHex,
    totalAppliances: Math.abs(plan.newAppliances),
    installation: plan.currentInstallation,
    reasonOfSubChange: plan.oldDrop ? plan.reasonOfSubChange : 'upgrade',
    planFrequency: plan.planFrequency,
    oldDrop: plan.oldDrop,
    invoiceArray: array,
  };
  axios
    .post(`${serverUrl.u}/app/tele/upgradeDowngradeOnGrievance`, payload)
    .then((res) => {
      console.log(res);
      if (res.data.success === true) {
        setPaymentLink(
          `${serverUrl.u}${serverUrl.partialPayZoho}${res.data.data.subscription.generatedHex}`
        );
        setText(`Hey ${sub.name},
      Book your Aliste Smart Home in a few clicks by carrying out a payment on the following link :  ${serverUrl.u}${serverUrl.partialPayZoho}${res.data.data.subscription.generatedHex}`);
        subHistory(
          `Paid Subscription DownGrade/UpGrade By ${
            store.getState().sar.data.user.first_name
          }`,
          { ...sub, payload: payload },
          'Subscription UpGrade/DownGrade',
          plan.oldDrop ? plan.reasonOfSubChange : 'upgrade',
          res.data.data.subscription.InstallationDate
        );
      }
      setPaymentLinkBtn('Done');
    })
    .catch((err) => {
      console.log(err);
      setPaymentLinkBtn('Failed');
    });
};

export const completeDone = async (sub, id, decision) => {
  await axios
    .post(`${serverUrl.u}/api/elec/getNewInstalledDevices`, {
      generatedHex: sub.generatedHex,
      id,
      houseId: decision,
    })
    .then(function (res) {
      subHistory(
        `New Devices Installed By Electrician`,
        {
          ...sub,
          generatedHex: sub.generatedHex,
          id,
          houseId: decision,
          res: res.data,
        },
        'new Devices installed by electrician',
        ''
      );
      console.log(res);
    })
    .catch(function (err) {
      console.log(err);
    });
};

export const collectCashPaymentUpSellCapax = async (generatedHex, trnxData) => {
  await axios
    .post(`${serverUrl.u}/api/tele/collectPaymentFromCash`, {
      generatedHex,
      trnxData,
    })
    .then(function (res) {
      console.log(res);
      if (res.data.success === true) {
        notify(res.data.message);
      } else {
        notifyFailed(res.data.message);
      }
      subHistory(
        `offline payment collected by ${
          store.getState().sar.data.user.first_name
        }`,
        { generatedHex, trnxData: trnxData, res: res.data },
        'Offline Payment collected by Electrician',
        res.data.message
      );
    })
    .catch(function (err) {
      console.log(err);
      notifyFailed('Failed Cash Payment');
    });
};
