import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { serverUrl } from '../ApiUrl';
import moment from 'moment';
import Spinner from '../Function/Spinner';
import { notifyFailed, notify } from '../Common/Notification';
import InvoiceCommentUpdate from '../Function/InvoiceCommentUpdate';
import { CSVLink } from 'react-csv';

import { daysDifference } from '../Common/applianceDifference';
import { cleanNumber } from '../Function/AxiosControl';
import { object } from 'zod';
import ClientGroupOverdue from './ClientGroupOverdue';
import HexGroupOverdue from './HexGroupOverdue';
// const days = (target) => {
//   // Define the present date and the target date
//   function parseDate(dateString) {
//     let parts = dateString.split("-");
//     let day = parseInt(parts[0], 10);
//     let month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript Date
//     let year = parseInt(parts[2], 10);
//     return new Date(year, month, day);
//   }
//   let presentDate = new Date();
//   let targetDate = parseDate(target);

//   // Calculate the time difference in milliseconds
//   let timeDifference = presentDate - targetDate;

//   // Convert the time difference from milliseconds to days
//   let daysPassed = timeDifference / (1000 * 3600 * 24);
//   return daysPassed.toFixed(2);
// };

const days = (target) => {
  // Define the present date and the target date
  function parseISOString(isoString) {
    let parts = isoString.split('T')[0].split('-');
    if (parts.length !== 3) {
      throw new Error(
        'Invalid ISO date format. Expected format is YYYY-MM-DD.'
      );
    }
    let year = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript Date
    let day = parseInt(parts[2], 10);

    // Validate the date components
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      throw new Error('Invalid date components.');
    }

    // Create a new Date object and check if it is valid
    let date = new Date(Date.UTC(year, month, day));
    if (
      date.getUTCFullYear() !== year ||
      date.getUTCMonth() !== month ||
      date.getUTCDate() !== day
    ) {
      throw new Error('Invalid date.');
    }

    return date;
  }

  let presentDate = new Date();
  let targetDate;

  try {
    targetDate = parseISOString(target);
  } catch (error) {
    return `Error: ${error.message}`;
  }

  // Calculate the time difference in milliseconds
  let timeDifference = presentDate - targetDate;

  // Convert the time difference from milliseconds to days
  let daysPassed = timeDifference / (1000 * 3600 * 24);
  return daysPassed.toFixed(0);
};

function ActiveOverDueInvoice() {
  let salesUser = useSelector((state) => state.salesUser);
  let [reload, setReload] = useState(true);
  const [spine, setSpine] = useState(false);
  const [overDue, setOverDue] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [invoiceDateFilter, setInvoiceDateFilter] = useState('All');
  const [actualStatusFilter, setActualStatusFilter] = useState([]);
  const [actualStatusFilterValue, setActualStatusFilterValue] = useState('All');
  const [apiData, setApiData] = useState([]);
  const [groupCsvData, setGropupCsvData] = useState([]);
  const [groupCustomerWise, setGroupCustomerWise] = useState([]);
  const [groupHexWise, setGroupHexWise] = useState([]);
  const [tab, setTab] = useState('Client-Wise');

  console.log('[spine]', spine);
  const getAllInvoices = () => {
    axios
      .get(`${serverUrl.u}/api/tele/getAllOverdueInvoices`)
      .then((res) => {
        if (res.data.success === true) {
          setApiData(res.data.finalData);
          if (res.data.finalData.length > 0) {
            let data = {};
            let csvDatas = [];
            let newKeys = [];
            let actualStatusType = ['All'];
            for (let sub of res.data.finalData) {
              // console.log('[SUB]', sub);
              newKeys.push({
                name: sub.name,
                invoiceNumber:
                  sub.invoiceNumber > 7975
                    ? sub.invoiceNumber - 7975
                    : sub.invoiceNumber > 1200
                    ? sub.invoiceNumber - 1200
                    : sub.invoiceNumber,
                zohoInvoiceNumber: sub.zohoInvoiceNumber,
                invoiceDate: moment(sub.invoiceDate).format('DD-MM-YYYY'),
                status: sub.status,
                teleCallerId: sub.teleCallerId,
                totalAppliances: sub.totalAppliances,
                generatedHex: sub.generatedHex,
                amount: sub.amount,
                secondarySalesPerson: sub.secondarySalesPerson,
                dropped: sub.dropped,
                clientDrop: sub.clientDrop,
                actualStatus: sub.actualStatus,
                invoice_ageing: days(sub.invoiceDate),
                comments: sub.comments,
                dateNot: sub.invoiceDate,
                phone: sub.phone,
                handler: sub.teleCallerId,
                dropped: sub.dropped,
                lastInvoiceDate: sub.lastInvoiceDate,
                totalReceivedAmount: sub.receivedAmount,
                totalPendingAmount: sub.pendingAmount,
                toBePickedUp: sub.toBePickedUp,
              });
              if (!actualStatusType.includes(sub.actualStatus)) {
                actualStatusType.push(sub.actualStatus);
              }
            }
            setActualStatusFilter(actualStatusType);
            for (let sub of newKeys) {
              if (data[sub.invoiceDate] === undefined) {
                data = { ...data, [sub.invoiceDate]: [{ ...sub }] };
              } else {
                data[sub.invoiceDate].push({ ...sub });
              }
              csvDatas.push({ ...sub });
            }
            setCsvData(csvDatas);
            setOverDue(data);
            setSpine(false);
          } else {
            notifyFailed('OverDue Invoices Not Found');
            setSpine(false);
          }
        }
        setSpine(false);
      })
      .catch((err) => {
        notifyFailed('OverDue Invoices Not Found');
        setSpine(false);
      });
  };
  const getOverdueList = async () => {
    let data = apiData;

    let groupObject = {};
    let phoneKey = [];

    for (let dt of data) {
      if (dt.actualStatus === 'overdue') {
        let phone = cleanNumber(dt.phone);
        if (groupObject[phone] === undefined) {
          groupObject[phone] = {};
        }
        if (groupObject[phone][dt.generatedHex] === undefined) {
          groupObject[phone][dt.generatedHex] = [];
        }
        groupObject[phone][dt.generatedHex].push(dt);
      }
    }

    let finalData = [];
    for (let phone of Object.keys(groupObject)) {
      for (let hex of Object.keys(groupObject[phone])) {
        let obj = {
          name: groupObject[phone][hex][0].name,
          phone: phone,
          generatedHex: hex,
          amount: 0,
        };
        for (let dd of groupObject[phone][hex]) {
          obj.amount += dd.amount;
        }
        finalData.push(obj);
      }
    }

    setGropupCsvData(finalData);
  };
  const getClientWise = () => {
    let data = apiData;
    let clientgroup = {};
    for (let dt of data) {
      if (dt.actualStatus === 'overdue') {
        let phone = cleanNumber(dt.phone);
        if (clientgroup[phone] === undefined) {
          clientgroup[phone] = [];
        }
        clientgroup[phone].push(dt);
      }
    }
    let dataArr = Object.keys(clientgroup).reduce((p, c) => {
      let obj = { name: clientgroup[c][0].name, phone: `'${c}`, amount: 0 };
      for (let d of clientgroup[c]) {
        obj.amount += d.amount;
      }
      return [...p, { ...obj, amount: obj.amount.toFixed(2) }];
    }, []);
    setGroupCustomerWise(dataArr);
  };
  const getHexWise = () => {
    let data = apiData;
    let hexgroup = {};
    for (let dt of data) {
      if (dt.actualStatus === 'overdue') {
        if (hexgroup[dt.generatedHex] === undefined) {
          hexgroup[dt.generatedHex] = [];
        }
        hexgroup[dt.generatedHex].push(dt);
      }
    }
    let dataArr = Object.keys(hexgroup).reduce((p, c) => {
      let obj = {
        name: hexgroup[c][0].name,
        generatedHex: c,
        amount: 0,
        ...hexgroup[c][0],
      };
      for (let d of hexgroup[c]) {
        obj.amount += d.amount;
      }
      return [...p, { ...obj, amount: obj.amount.toFixed(2) }];
    }, []);
    setGroupHexWise(dataArr);
  };
  useEffect(() => {
    getOverdueList();
    getClientWise();
    getHexWise();
  }, [apiData]);
  useEffect(() => {
    setSpine(true);
    getAllInvoices();
  }, []);

  console.log('[finalData]', overDue);
  return (
    <div style={{ padding: '0 4%', marginTop: '4vh' }}>
      {spine && <Spinner />}
      <div style={{ marginBottom: '20px' }}>
        <button
          className={
            tab === 'Hex-Wise'
              ? 'btn btn-primary ml-2 mt-2'
              : 'btn btn-light ml-2 mt-2'
          }
          onClick={() => setTab('Hex-Wise')}
        >
          Hex-Wise
        </button>
        <button
          className={
            tab === 'Client-Wise'
              ? 'btn btn-primary ml-2 mt-2'
              : 'btn btn-light ml-2 mt-2'
          }
          onClick={() => setTab('Client-Wise')}
        >
          Client-Wise
        </button>
        <button
          className={
            tab === 'Date-Wise'
              ? 'btn btn-primary ml-2 mt-2'
              : 'btn btn-light ml-2 mt-2'
          }
          onClick={() => setTab('Date-Wise')}
        >
          DateWise
        </button>
      </div>
      {tab === 'Date-Wise' && (
        <>
          <div className='row align-items-end'>
            {/* <div class="input-group col-4">
          <div>
            <label>TeleCaller</label>
            <select
              class="custom-select height-select"
              required
              id="inputGroupSelect01"
              onChange={handleChange}
              name="telecaller"
              autoComplete="telecaller"
            >
              <option value={"all"}>All</option>
              {salesUser?.map((option) => (
                <option value={option?.first_name}>{option?.first_name}</option>
              ))}
              <input type="text" />
            </select>
          </div>
       </div> */}
            <div class='input-group col-3'>
              <div className='w-100'>
                <label>Invoice Date</label>
                <select
                  class='custom-select height-select'
                  required
                  id='inputGroupSelect01'
                  onChange={(e) => {
                    setInvoiceDateFilter(e.target.value);
                  }}
                  name='invoiceDate'
                  value={invoiceDateFilter}
                  autoComplete='invoiceDate'
                >
                  <option value={'All'}>All</option>
                  {Object.keys(overDue)?.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                  <input type='text' />
                </select>
              </div>
            </div>
            <div class='input-group col-3'>
              <div className='w-100'>
                <label>Actual Status</label>
                <select
                  class='custom-select height-select'
                  required
                  id='inputGroupSelect01'
                  onChange={(e) => {
                    setActualStatusFilterValue(e.target.value);
                  }}
                  name='actualStatusFilter'
                  value={actualStatusFilterValue}
                  autoComplete='actualStatusFilter'
                >
                  {actualStatusFilter?.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                  <input type='text' />
                </select>
              </div>
            </div>
            {csvData.length > 0 && (
              <div class='input-group col-2'>
                <CSVLink
                  data={csvData}
                  filename={`All_${moment(new Date()).format(
                    'DD-MMM-YYYY'
                  )}.csv`}
                >
                  <button className='btn btn-primary'>Download Csv</button>
                </CSVLink>
              </div>
            )}
            {groupCsvData.length > 0 && (
              <div class='input-group col-3'>
                <CSVLink
                  className='ml-1'
                  data={groupCsvData}
                  filename={`${moment(new Date()).format('DD-MMM-YYYY')}.csv`}
                >
                  <button className='btn btn-primary'>
                    Download Group Csv
                  </button>
                </CSVLink>
              </div>
            )}
          </div>
          {Object.keys(overDue)?.map((option) => {
            if (invoiceDateFilter === 'All' || invoiceDateFilter === option) {
              return (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div className='table-date' style={{ marginTop: '2%' }}>
                      {option}
                    </div>
                    <CSVLink
                      data={overDue[option]}
                      filename={`${option}_${moment(new Date()).format(
                        'DD-MMM-YYYY'
                      )}.csv`}
                    >
                      <button className='btn btn-primary'>Download Csv</button>
                    </CSVLink>
                  </div>
                  {Object.keys(overDue).length !== 0 && (
                    <div
                      className='table-container1'
                      style={{ marginTop: '10px', height: 'max-content' }}
                    >
                      {console.log('[overDue]', overDue)}
                      <table className='table table-sm table-bordered'>
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th scope='col'>Name</th>
                            <th scope='col'>Invoice No</th>
                            <th scope='col'>Zooho Invoice No</th>
                            <th>Date of Invoice</th>
                            <th>Status</th>
                            <th>Phone</th>
                            <th>Total Invoices</th>
                            <th>TeleCaller</th>
                            <th>Total Appliances</th>
                            <th>Hex</th>
                            <th>Amount</th>
                            <th>Secondary TeleCaller</th>
                            <th>Dropped</th>
                            <th>Client Dropped</th>
                            <th>Actual Status</th>
                            <th>
                              Comment<span style={{ color: 'red' }}>*</span>
                            </th>
                            <th>Invoice Ageing</th>
                            <th style={{ width: '130px' }}>Payment Link</th>
                          </tr>
                        </thead>
                        <tbody>
                          {overDue[option]
                            .sort((a, b) => {
                              if (a.name.toLowerCase() < b.name.toLowerCase())
                                return -1;
                            })
                            .map((e, i) => {
                              if (
                                actualStatusFilterValue === 'All' ||
                                actualStatusFilterValue === e.actualStatus
                              ) {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td
                                      className='pr-15 pl-55'
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      {e.name} {e.B2BRoom_No}
                                    </td>
                                    <td>
                                      <div>{e.invoiceNumber}</div>
                                    </td>
                                    <td>{e.zohoInvoiceNumber}</td>
                                    <td>{e.invoiceDate}</td>
                                    <td>{e.status}</td>
                                    <td>{e.phone}</td>
                                    <td>
                                      {
                                        apiData.filter(
                                          (doc) => doc.phone === e.phone
                                        ).length
                                      }
                                    </td>
                                    <td>{e.teleCallerId}</td>
                                    <td>{e.totalAppliances}</td>
                                    <td>{e.generatedHex}</td>
                                    <td>{e.amount.toFixed(2)}</td>
                                    <td>{e.secondarySalesPerson}</td>
                                    <td>{e.dropped ? 'True' : 'False'}</td>
                                    <td>{e.clientDrop ? 'True' : 'False'}</td>
                                    <td>{e.actualStatus}</td>
                                    <InvoiceCommentUpdate
                                      e={e}
                                      reload={reload}
                                      setReload={setReload}
                                      notify={notify}
                                    />
                                    <td>{days(e.dateNot)}</td>
                                    <td>
                                      <button
                                        className='btn btn-primary'
                                        onClick={() =>
                                          navigator.clipboard.writeText(
                                            e.invoiceUrl === undefined
                                              ? e.url
                                              : e.invoiceUrl
                                          )
                                        }
                                      >
                                        Copy
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </>
      )}
      {groupCsvData.length > 0 && tab === 'Date-Wise' && (
        <table className='table table-sm table-bordered'>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th scope='col'>Name</th>
              <th>Phone</th>
              <th>Hex</th>
              <th>Amount</th>
              {/* <th>Handler</th> */}
            </tr>
          </thead>
          <tbody>
            {groupCsvData.map((e, i) => {
              console.log('[e]', e);
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td className='pr-15 pl-55' style={{ fontWeight: 'bold' }}>
                    {e.name}
                  </td>
                  <td>{e.phone}</td>
                  <td>{e.generatedHex}</td>
                  <td>{e.amount.toFixed(2)}</td>
                  <td>{e.telecallerId}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {tab === 'Client-Wise' && <ClientGroupOverdue data={groupCustomerWise} />}
      {tab === 'Hex-Wise' && <HexGroupOverdue data={groupHexWise} />}
    </div>
  );
}

export default ActiveOverDueInvoice;
