import { Document, Page, Text, Image, Link, StyleSheet, View, Font } from '@react-pdf/renderer';
import React from 'react'
import font from '../../Manrope-VariableFont_wght.ttf'
// import logo from '../../../public/assets/pdfQuto/logoPdf.png'
Font.register({ family: 'Manrope', src: font });

const styles = StyleSheet.create({

    page: {
        margin: 0,
        padding: 0,

        backgroundColor: "#E5EAF5"
    },
    pdfHeader: {

        height: "400px",
        backgroundColor: "white"
    },
    logoPng: {
        height: "44.70624923706055px",
        width: "50.005859375px",
        marginLeft: "16px",
        borderRadius: "0px",
        marginTop: "63px",
    },
    welcome: {
        marginTop: "5px",
        marginLeft: "16px",
        fontFamily: 'Manrope',
        fontStyle: "normal",
        fontSize: "28px",
        fontWeight:'400',
        color: "#0E112A",
        //  lineHeight:"40px",
    },
    aliste: {
        marginTop: "5px",
        marginLeft: "16px",
        fontFamily: 'Manrope',
        fontStyle: "normal",
        fontSize: "28px",
        fontWeight:800,
        color: "#0E112A",
        //  lineHeight:"40px",
    }
})



export default function Qutoo() {
    const Data = {
        name: 'Shreyansh',
        planName: '1BHK',
        ratePerDay: 4,
        totalAplliances: 4,
        total: 100,
        tax: 18,
        discount: 50,
        planDuration: 'Monthly',
        securityDeposite: 100,
        installionCharges: 100,
        planRate: 4,
        paymentLink: "https://github.com"
    }
    const today = new Date();
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dis = Data.discount == 0 ? "none" : "";
    let offer = ""
    switch (Data.planDuration) {
        case "Monthly":
            offer = "";
            break;
        case "Quarterly":
            offer = "+ 10 Days Free";
            break;
        case "Semi Annualy":
            offer = "+ 1 Month Free";
            break;
        case "Annualy":
            offer = "+ 3 Months Free";
            break;
        default:
            break;
    }


    const d = new Date();
    let nameOfMonth = month[d.getMonth()];

    return (
        <Document>
            <Page style={styles.page} size={[360, 950]}>
                <View>
                    <View style={styles.pdfHeader}>
                        <View>
                            <Image style={styles.logoPng} src={process.env.PUBLIC_URL + '/assets/pdfQuto/logoPdf.png'} />
                            <Text style={styles.welcome}>Welcome to</Text>
                            <Text style={styles.aliste}>Aliste</Text>
                        </View>
                        <View>

                        </View>
                    </View>
                </View>
            </Page>
        </Document>

    )
}