import axios from "axios";
export const salesUserData = async(dispatch,token) => {      
    return await axios.get("https://int.alistetechnologies.com/users/salesUsers", {
         headers: {
           AccessToken: token
         }
       }).then(function (response) {
           dispatch(salesUserAction([...response.data.data.users,{first_name:"website"}]))
       }).catch(function (error) {
           dispatch(salesUserAction([]))
       })
     }

     const salesUserAction = (data)=>{
        return {
            type:"SalesUser",
            payload:data
        }
     }