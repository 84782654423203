import React, { useEffect, useState } from "react";
import axios from "axios";
import "../ConvertedLead.css";
import "../DispatchData.css";
import moment from "moment/moment";
import '../Installation.css'
import Qutoes from "../Qutoes";
import {serverUrl} from '../ApiUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { subHistory } from "../Function/SubscriptionHistory";
import FinalUnInstallationBtn from "./FinalUnInstallationBtn";
export default function UnInstallationDepartment() {
  let [details, setDetails] = useState([]);
  let [displayData,setDisplayData] = useState([])
//   let [page,setPage] = useState(1)
//   let [totalPage,setTotalPage] = useState(1)
  let [reload,SetReload] = useState(false);
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let userRole =useSelector((state)=>state.sar.data.user.roles)
 
  const notify = (text) => toast(text);
  useEffect(() => {
    let data = async () => {
      await axios
        .get(
          `${serverUrl.u}/api/tele/getUninstallationSite`
        )
        .then(function (response) {
          let detail = response.data.alisteDropSubscription;
          console.log(detail,"uninstall");
          setDetails(detail);
          setDisplayData(detail)
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    data();
    
  }, [reload]);





  
 
 
 
 

  return (
    <div style={{padding:"0 4%",marginTop:"4vh"}}>
        <ToastContainer />
        {/* <nav aria-label="..." className='pagination-container'>
      <ul class="pagination">
        {
          [...Array(totalPage)].map((e,i)=>{
              return(
                
                <li className={page===i+1?"page-item active":"page-item"} onClick={()=>setPage(i+1)}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
        </nav> */}
      <div className='table-container1' style={{marginTop:0}}>
      <table className="table table-sm  ">
        <thead>
          <tr>
          {/* <th>Sr. No.</th> */}
            <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
            <th>TeleCaller Name</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col" style={{width:"120px"}}>Phone</th>
            <th>Un-Installation Date</th> 
            <th>Action</th>           
          </tr>
        </thead>
        <tbody>
          {displayData !== 0
            ? displayData.map((e, i) => {
                return (
                  <tr key={i} style={{marginBottom:5}}>
                    {/* <td>{(i+1)+((page-1)*30)}</td> */}
                    <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                    <td>{e.teleCallerId}</td>
                    <td label="Address"><div className="hover-wide">{e.address}</div></td>
                    <td label="City">{e.city}</td>
                    <td label="Phone"><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                    <td label="Date">{moment(e.InstallationDate).format("MMM Do LT")}</td>
                    <td><FinalUnInstallationBtn data={e} notify={notify} setReload={SetReload} reload={reload}/></td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
    <Qutoes/>
    </div>
  );
}
