import { Button } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'
import { notify, notifyFailed } from '../Common/Notification'

function SendOverDueLinkWhatapp({sub}) {
  const senddOverDueLinkWhatapp = () => {
    axios.post(`${serverUrl.u}/app/tele/sendOverdueLinkToClient`,{
        "generatedHex":sub.generatedHex,
        "phone":sub.phone,
        "name":sub.name
    }).then((res)=>{
       console.log(res);
       if(res.data.success===true){
        notify(res.data.message)
       }else{
        notifyFailed(res.data.message)
       }
    }).catch((err)=>{
       notifyFailed("Error Occured")
    })
  }
  return (
    <>
      <button className='btn btn-primary' onClick={senddOverDueLinkWhatapp}>Send Over Due Link Whatapp</button>
    </>
  )
}

export default SendOverDueLinkWhatapp