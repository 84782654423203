import React, { useEffect, useState, useRef } from "react";
import { notify, notifyFailed } from "../Common/Notification";
import { serverUrl } from "../ApiUrl";
// import NotificationModal from "./modal";
import { Button } from "@mui/material";
import {
  getAllReferrals,
  getAllReferralsByEmployee,
  getAllReferralsByUser,
  getPaginatedResponse,
  markPaid,
} from "./referralAPIs";
import { toast } from "react-toastify";
import moment from "moment";
import { InstallationUser } from "../Function/AxiosControl";
import { useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { notifyFailed } from '../Common/Notification';

export default function Referral() {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [edit, setEdit] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState({});

  // INPUTS
  const [userIndentifier, setUserIndentifier] = useState("");
  const [userReferrals, setUserReferrals] = useState([]);
  const [installationUser, setInstallationUser] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employeeReferrals, setEmployeeReferrals] = useState([]);

  const [paginatedResponse, setPaginatedResponse] = useState([]);
  // const [totalReferrals, setTotalReferrals] = useState(1)

  let token = useSelector((state) => state.sar.data?.token);

  // Pagination
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  let startDate = useRef(new Date());

  useEffect(() => {
    // async function fetchData() {
    //   setLoading(true);
    //   const resp = await getAllReferrals();

    //   if (!resp.success) {
    //     setLoading(false);
    //     return toast('Request failed');
    //   }

    //   setResponse(resp.data);
    //   setLoading(false);
    // }

    // fetchData();

    async function fetchData() {
      setLoading(true);

      const resp = await InstallationUser(token);

      setInstallationUser(resp);

      setLoading(false);
    }

    async function fetchReferrals() {
      setLoading(true);
      const resp = await getPaginatedResponse({ page: 1 });

      if (!resp.success) {
        return notify(resp.message || String(resp?.data?.error));
      }

      setTotalPage(Math.ceil(resp?.data?.totalReferrals / 30));
      // console.log("Resp - ", resp.data.referrals)
      setPaginatedResponse(resp.data.referral);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchReferrals() {
      setLoading(true);
      const resp = await getPaginatedResponse({ page });

      if (!resp.success) {
        return notify(resp.message || String(resp?.data?.error));
      }

      setTotalPage(Math.ceil(resp?.data?.totalReferrals / 10));
      // console.log("Resp - ", resp.data.referrals)
      setPaginatedResponse(resp.data.referral);
    }

    fetchReferrals();
  }, [page]);

  async function markReferralPaid(referredTo, id) {
    const resp = await markPaid(referredTo);
    if (!resp.success) {
      notifyFailed("Operation failed");
    }

    notify("Lead marked as paid");
    setResponse((data) => {
      return data.map((p) => (String(p._id) === String(id) ? resp.data : p));
    });
  }

  async function getReferralsByUser() {
    if (userIndentifier === "") {
      notify("Please enter the User Identifier");
      return;
    }

    const resp = await getAllReferralsByUser({ referredBy: userIndentifier });

    if (!resp.success) {
      notify(resp?.data?.error);
      return;
    }

    setUserReferrals(resp?.data?.referrals);
  }

  const showModal = (n) => {
    setData(n);
    setEdit(true);
  };

  async function getReferralsByEmployee(data) {
    console.log("START DATE - ", startDate);
    const resp = await getAllReferralsByEmployee({
      employeeCode: data.employeeId,
      start: moment(startDate.current.value).format("YYYY-MM-DD"),
      end: moment(startDate.current.value).add(1, "days").format("YYYY-MM-DD"),
    });

    if (!resp.success) {
      notify(resp.data.error);
    }
    setSelectedEmployee(data.first_name);
    setEmployeeReferrals(resp.data.referrals);
  }

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="table-container1" style={{ height: "90vh" }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>referredBy</th>
              <th>referredByHex</th>
              <th>referredTo</th>
              <th>referralDate</th>
              <th>paymentStatus</th>
              <th>amountPaid</th>
              <th>Mark Paid</th>
            </tr>
          </thead>
          <tbody>
            {response.length > 0 &&
              response.map((n) => {
                return (
                  <tr key={n._id}>
                    <td>{n.referredBy}</td>
                    <td>{n.referredByHex}</td>
                    <td>{n.referredTo}</td>
                    <td>{moment(n.referralDate).format("MMM Do YYYY")}</td>

                    <td>{n.paymentStatus}</td>
                    <td>{n.amountPaid}</td>
                    <td>
                      <Button
                        variant="contained"
                        onClick={() => markReferralPaid(n.referredTo, n._id)}
                      >
                        Mark Paid
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {/* Get all the referrals */}
      {/* Pagination */}
      <nav className="pagination-container">
        <ul className="pagination">
          {[...Array(totalPage)].map((e, i) => {
            return (
              <li
                className={page === i + 1 ? "page-item active" : "page-item"}
                onClick={(e) => {
                  e.preventDefault();
                  setPage(i + 1);
                }}
              >
                <a className="page-link" href="#">
                  {i + 1}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>

      {/* Table */}
      <div className="table-container1" style={{ marginTop: 0 }}>
        {/* <table className="table table-sm table-bordered">
          <thead>
            <tr >
              <td style={{ width: "max-content" }}>S.No</td>
              <td>name</td>
              <td>Contact Details</td>
              <td>Referred By</td>
              <td>Referred By Hex</td>
              <td>Referral Status</td>
              <td>Referral Date</td>
              <td>Last Updated</td>
            </tr>
          </thead>

          <tbody>
            {paginatedResponse.length > 0 && paginatedResponse.map((r, i) => (
              <tr key={r._id}>
                <td>{i + 1}</td>
                <td>{r?.name}</td>
                <td>{r?.referredTo}</td>
                <td>{r?.referredBy}</td>
                <td>{r?.referredByHex}</td>
                <td>{r?.referralStatus}</td>
                <td>{moment(r?.createdAt).format("MMM Do YYYY")}</td>
                <td>{moment(r?.updatedAt).format("MMM Do YYYY")}</td>
              </tr>
            ))}
          </tbody>
        </table> */}

        {}
      </div>

      {/* Get Referrals by user */}
      <div>
        <div style={{ display: "flex", gap: 20 }}>
          <label style={{ fontSize: "1.5rem" }}>User Identifier:</label>
          <input
            type="text"
            value={userIndentifier}
            onChange={(e) => setUserIndentifier(e.target.value)}
          />
          <Button onClick={getReferralsByUser} >Get Data</Button>
        </div>
        {userReferrals.length === 0 ? (
          <div
            style={{
              height: "30vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              border: "1px solid #C6D0F0",
              boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
              borderRadius: "10px",
            }}
          >
            <image src="/assets/img/referralTablePlaceholder.webp" />
            <p>Enter user details to fetch data -_-</p>
          </div>
        ) : (
          <>
            <div className="table-container1" style={{ maxHeight: "90vh" }}>
              <DataGrid
                sx={{ minHeight: "30vh" }}
                columns={
                  userReferrals.length > 0
                    ? Object.keys(userReferrals[0]).map((h) => {
                        if (h === "_id") {
                          return { field: "id", headerName: "id", width: 150 };
                        } else {
                          return { field: h, headerName: h, width: 150 };
                        }
                      })
                    : []
                }
                rows={userReferrals.map((r, i) => ({ id: i, ...r }))}
                slots={{
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { fileName: `${userIndentifier}_referrals` },
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                // components={{
                //   toolbar: GridToolbar,
                // }}

                pageSizeOptions={[5, 10]}
                // checkboxSelection
                // disableRowSelectionOnClick
              />

              {/* <table className="table table-bordered">
                <thead>
                  <th>Referred By</th>
                  <th>Referred By Hex</th>
                  <th>Referred To</th>
                  <th>Referral Date</th>
                  <th>Referral Status</th>
                  <th>Payment Status</th>
                </thead>
                <tbody>
                  {userReferrals.length > 0 && userReferrals.map(r => (
                    <tr key={r._id}>
                      <td>{r.referredBy}</td>
                      <td>{r.referredByHex}</td>
                      <td>{r.referredTo}</td>
                      <td>{r.referralDate}</td>
                      <td>{r.referralStatus}</td>
                      <td>{r.paymentStatus}</td>
                    </tr>
                  ))}

                </tbody>
              </table> */}
            </div>
          </>
        )}
      </div>

      {/* Get Referrals By Employee */}

      <div style={{marginTop: "20px"}}>
        <div style={{display: "flex", gap: 20, alignItems: "center", marginBottom: "20px"}}>
          <div style={{display: "flex", gap: 10, alignItems: "center"}}>
          <label className="create-label1"><b>Employee</b></label>
          <div class="input-group ">
            <select
              class="custom-select height-select"
              onChange={(e) => {
                const obj = JSON.parse(e.target.value);
                getReferralsByEmployee({ employeeId: obj.employeeId });
              }}
              value={selectedEmployee}
            >
              <option value="All">All</option>
              {installationUser?.map((option) => {
                return (
                  // console.log("OPTION - ", option)
                  <option value={JSON.stringify(option)}>
                    {option.first_name}
                  </option>
                );
              })}
            </select>
          </div>
          </div>
          <div class="input-group ">
            <input
              type="date"
              value={moment().format("YYYY-MM-DD")}
              ref={startDate}
            />
          </div>
        </div>

        <div>
          {employeeReferrals.length === 0 ? (
            <div
              style={{
                height: "30vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                border: "1px solid #C6D0F0",
                boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
                borderRadius: "10px",
              }}
            >
              <image src="/assets/img/referralTablePlaceholder.webp" />
              <p>Enter user details to fetch data -_-</p>
            </div>
          ) : (
            <div className="table-container1" style={{ maxHeight: "90vh" }}>
              <DataGrid
                columns={
                  employeeReferrals.length > 0
                    ? Object.keys(employeeReferrals[0]).map((h) => {
                        if (h === "_id") {
                          return { field: "id", headerName: "id", width: 150 };
                        } else {
                          return { field: h, headerName: h, width: 150 };
                        }
                      })
                    : []
                }
                rows={employeeReferrals.map((r, i) => ({ id: i, ...r }))}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                // components={{
                //   toolbar: GridToolbar,
                // }}

                pageSizeOptions={[5, 10]}
                // checkboxSelection
                // disableRowSelectionOnClick
              />
              {/* <table className="table table-bordered">
                <thead>
                  <th>Referred By</th>
                  <th>Referred By Hex</th>
                  <th>Referred To</th>
                  <th>Referral Date</th>
                  <th>Referral Status</th>
                  <th>Payment Status</th>
                </thead>
                <tbody>
                  {employeeReferrals.length > 0 && employeeReferrals.map(r => (
                    <tr key={r._id}>
                      <td>{r.referredBy}</td>
                      <td>{r.referredByHex}</td>
                      <td>{r.referredTo}</td>
                      <td>{r.referralDate}</td>
                      <td>{r.referralStatus}</td>
                      <td>{r.paymentStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
