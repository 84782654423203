import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { notifyFailed } from '../Common/Notification'
import Spinner from '../Function/Spinner'

function Retention() {
  const [displayData,setDisplayData]  = useState([])
  const [spin,setSpin] = useState(false)
  const data = async()=>{
      await axios.get(`${serverUrl.u}/api/tele/getSubscriptionDetailsWithHouse`).then(

        function (res) {
            if(res.data.success===true){
                setDisplayData(res.data.data)
            }else{
                notifyFailed("Data not found")
            }
        }
      ).catch(function (err) {
         notifyFailed("Data Load Failed")
      })
      setSpin(false)
  }

   useEffect(()=>{
     setSpin(true)
     data()
   },[])
  return (
    <div style={{width:"100%",padding:"0 4%",marginTop:"5vh"}}>
        {
            spin && <Spinner/>
        }
      <div className='table-container1' style={{ margin: 0 }}>
                <table className='table table-sm table-bordered'>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
                            <th>Phone</th>
                            <th>TeleCaller</th>
                            <th>Second TeleCaller</th>
                            <th>Alex Linked</th>
                            <th>Google Home Linked</th>
                            <th>Total User in House</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            displayData.length !== 0 ?
                                displayData.map((e, i) => {
                                    return (
                                        <tr key={i} >
                                            <td>{i+1}</td>
                                            <td className="pr-15 pl-55" style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(e.generatedHex) }}>{e.name}</td>
                                            <td>{e.phone}</td>
                                            <td>{e.teleCallerId}</td>
                                            <td>{e.secondarySalesPerson}</td>
                                            <td>{e.alexaLinked?"Linked":"Not Linked"}</td>
                                            <td>{e.googleHomeLinked?"Linked":"Not Linked"}</td>
                                            <td>{e.masters.length}</td>
                                          </tr>
                                    )
                                }) : null
                        }
                    </tbody>
                </table>
                
            </div>
    </div>
  )
}

export default Retention