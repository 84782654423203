import axios from "axios";
import React from "react";
import { useState } from "react";
import { serverUrl } from "../ApiUrl";
import { GrievanceHandler, grevianceCategrory } from "../Data";
import { subHistory } from "../Function/SubscriptionHistory";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { useRef } from "react";
import moment from "moment";
import { Dialog } from "@mui/material";
export default function GreivanceResolveDep({
  dataObject,
  reloadW,
  setReload,
  notify,
}) {
  let [pop, setPop] = useState(false);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let [btnText, setBtnText] = useState("Done");
  let Select = useRef();
  let [onDecision, setOnDecision] = useState("");
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let [reslove, setReslove] = useState("false");
  const handleSubmit = (event) => {
    setBtnText("Processing....");
    event.preventDefault();
    const datas = new FormData(event.currentTarget);
    let detail = {
      Comment: datas.get("comment"),
      decision: datas.get("decision"),
      installationDate: datas.get("installationDate"),
      categeory: datas.get("categeory"),
      priority: datas.get("priority"),
    };
    if (
      onDecision === "Vikas" ||
      onDecision === "Heena" ||
      onDecision === "Sidharth"
    ) {
      assign(detail);
    } else {
      resloveGrivance(detail);
    }
  };
  const assign = async (detail) => {
    await axios
      .post(`${serverUrl.u}/api/grv/changeHandler`, {
        generatedHex: dataObject.generatedHex,
        handler: detail.decision,
        commentByGrivienceDepartment: `${detail.Comment} - ${moment(
          new Date()
        ).format("DD-MM-YYYY")}`,
        grievancePriority: detail.priority === "false" ? false : true,
        InstallationDate: detail.installationDate,
        grivienceCategory: Select.current.state.selectedValues,
      })
      .then(function (response) {
        if (response.status === 200) {
          setPop(false);
          setReload(!reloadW);
          setBtnText("Done");
          subHistory(
            `Grivience Handler change by ${loginName}`,
            dataObject,
            "Grivience Handler change",
            detail.decision,
            detail.Comment
          );
          notify("Handler is updated plz wait to update Data");
        } else {
          notify("Error Occured");
          setBtnText("Failed Retry");
        }
      })
      .catch(function (error) {
        console.log(error);
        notify("Error Occured");
        setBtnText("Failed Retry");
      });
  };

  const resloveGrivance = (detail) => {
    if (
      dataObject.zone === "" ||
      dataObject.zone === null ||
      dataObject.zone === undefined ||
      dataObject.zone === "empty"
    ) {
      notify("Plz Select Subscription zone Bofore Sending");
      setBtnText("Failed Retry");
      return;
    }
    axios
      .post(`${serverUrl.u}/api/grv/actionOnGrivience`, {
        generatedHex: dataObject.generatedHex,
        isResolved: detail.decision === "onCall" ? true : false,
        subscriptionStage:
          dataObject.partialPayment === true
            ? "ready to install"
            : "installed & complete",
        InstallationDate: detail.installationDate,
        category: Select.current.state.selectedValues,
        comments: detail.Comment,
      })
      .then(function (res) {
        if (res.data.success === true) {
          notify(res.data.message);
          setPop(false);
          for (let i = 0; i < Select.current.state.selectedValues.length; i++) {
            subHistory(
              `Grivance resolved via ${detail.decision} `,
              dataObject,
              `${"Grivance Department"}`,
              `${Select.current.state.selectedValues[i].value}`,
              detail.Comment,
              detail.installationDate
            );
          }
          setBtnText("Done");
          setReload(!reloadW);
        } else {
          notify("Failed");
          setBtnText("Failed Retry");
        }
      })
      .catch(function (err) {
        console.log(err);
        notify("Failed");
        setBtnText("Failed Retry");
      });
  };

  const styles = {};
  return (
    <>
      <button className="btn btn-primary" onClick={() => setPop(true)}>
        Action
      </button>
      <Dialog open={pop} onClose={()=>setPop(false)}>
        <div
          style={{
            position: "fixed",
            top: "15vh",
            left: "35vw",
            width: "45vw",
            padding: "15px",
            zIndex: "5000",
            backgroundColor: "whitesmoke",
            borderRadius: "12px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            <span
              style={{ cursor: "pointer", position: "absolute", right: "5px" }}
              onClick={() => {
                setPop(false);
                setOnDecision("");
              }}
            >
              X
            </span>
          </div>

          <form onSubmit={handleSubmit}>
            <label className="specific-label">Client Name</label>

            <div className="input-group">
              <input
                type="text"
                name="name"
                autoComplete="current-name"
                className="form-control"
                required
                disabled
                value={dataObject.name}
                aria-label="name"
                aria-describedby="basic-addon1"
              />
            </div>

            <label className="specific-label">Comment</label>

            <div className="input-group">
              <input
                type="text"
                name="comment"
                autoComplete="current-comment"
                className="form-control"
                required
                aria-label="comment"
                aria-describedby="basic-addon1"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div>
                <input
                  type="radio"
                  name="decision"
                  value="onCall"
                  onChange={() => setOnDecision("onCall")}
                  style={{ marginLeft: 0 }}
                />
                <label>On Call Resolved</label>
              </div>
              {/* {
                                userRole?.includes("Super Admin")? */}
              {GrievanceHandler.map((handler) => {
                if (loginName !== handler) {
                  return (
                    <div>
                      <input
                        type="radio"
                        name="decision"
                        value={handler}
                        onChange={() => setOnDecision(handler)}
                        style={{ marginLeft: 0 }}
                      />
                      <label>Send To {handler}</label>
                    </div>
                  );
                }
              })}
              {/* <>
                                <div><input type='radio' name="decision" value={"Heena"} onChange={()=>setOnDecision("Heena")} style={{marginLeft:0}}/><label>Send To Heena</label></div>
                                <div><input type='radio' name="decision" value={"Vikas"} onChange={()=>setOnDecision("Vikas")} style={{marginLeft:0}}/><label>Send To Vikas</label></div>
                                <div><input type='radio' name="decision" value={"Sidharth"} onChange={()=>setOnDecision("Sidharth")} style={{marginLeft:0}}/><label>Send To Sidharth</label></div>
                                </> */}
              {/* // :
                                // <div><input type='radio' name="decision" value={loginName==="Vikas"?"Heena":"Vikas"} onChange={()=>setOnDecision(loginName==="Vikas"?"Heena":"Vikas")} style={{marginLeft:0}}/><label>{loginName==="Vikas"?"Send To Heena":"Send To Grivance Department"}</label></div>
                                // } */}
              <div>
                <input
                  type="radio"
                  name="decision"
                  value="sendDispatch"
                  onChange={() => setOnDecision("sendDispatch")}
                  style={{ marginLeft: 0 }}
                />
                <label>Send Dispatch</label>
              </div>
            </div>
            {onDecision === "Vikas" ||
            onDecision === "Heena" ||
            onDecision === "Sidharth" ? (
              <div>
                <label>Priorty</label>
                <div class="input-group mb-3">
                  <select
                    class="custom-select"
                    id="inputGroupSelect01"
                    name="priority"
                    autoComplete="priority"
                    defaultValue="false"
                  >
                    <option value={"false"}>No</option>
                    <option value={"true"}>Yes</option>
                  </select>
                </div>
              </div>
            ) : null}

            <div className="input-group">
              <input
                required
                type="datetime-local"
                className="form-control"
                name="installationDate"
                autoComplete="current-installationDate"
              />
            </div>
            <div className="input-group">
              <Multiselect
                displayValue="value"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                options={grevianceCategrory}
                ref={Select}
                style={{
                  width: "100px !important",
                  multiselectContainer: {
                    marginTop: "15px",
                    backgroundColor: "#ffffff",
                  },
                }}
              />
            </div>

            <div>
              <button
                type="submit"
                className="btn2 btn-primary mt-2"
                style={{
                  pointerEvents: btnText === "Processing...." ? "none" : "",
                }}
              >
                {btnText}
              </button>
            </div>
          </form>
        </div>
        </Dialog>

    </>
  );
}
