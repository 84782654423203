import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../ApiUrl';
import Multiselect from 'multiselect-react-dropdown';
import './UpdateUpsellProduct.css'
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { Label } from '@mui/icons-material';
import { updateUpsellItem } from './Api';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    maxHeight: "95vh",
    overflowY: "scroll",
};
function UpdateUpsellProduct({ sub, reload, setReload }) {
    let [products, setProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [open, setOpen] = React.useState(false);
    const [totalCost,setTotalCoast] = useState({})
    const [installationCharges,setInstallationCharges] = useState(Number(sub.currentInstallation))
    const handleClose = () => {
        setOpen(false)
    };
    useEffect(() => {
        axios.post(`${serverUrl.u}/v3/affiliateProducts/listAll`).then(
            function (res) {
                console.log(res);
                setProducts(res.data.data.products)
                let dataofSelectedProduct = []
                let totalWithOutTax = 0
                for (let item of sub.upsellItems) {
                    for (let pro of res.data.data.products) {
                        if (item.P_Id === pro.product_code) {
                            dataofSelectedProduct.push({ ...pro, selling_price: item.sellingPricePerPic, mrp: item.mrpPricePerPic, hsn_code: item.hsn_code, qty: item.qty })
                            totalWithOutTax +=(item.sellingPricePerPic*item.qty)
                        }
                    }
                }
                console.log(dataofSelectedProduct, "ggggggg");
                setTotalCoast({
                    
                    total:totalWithOutTax
                })
                setSelectedProducts(dataofSelectedProduct)
            }
        ).catch(function (err) {
            console.log(err);
        })
    }, [])
    const onChange = (value, id, key) => {
        let newData = []
        for (let p of selectedProducts) {
            if (p._id === id) {
                if (key === "hsn_code" || key === "name") {
                    newData.push({ ...p, [key]: value })
                } else {
                    newData.push({ ...p, [key]: Number(value) })
                }
            } else {
                newData.push(p)
            }
        }
        let totalWithOutTax = 0
        for (let item of newData) {
            totalWithOutTax +=(item.selling_price*item.qty)
        }
        setTotalCoast({
            total:totalWithOutTax
        })
        setSelectedProducts(newData)
    }

    const onProductSelect = (data) => {
        let newdata = []
        for (let p of data) {

            newdata.push({ ...p, qty: p.qty === undefined ? 1 : p.qty })

        }
        console.log(newdata, "after filter");
        let totalWithOutTax = 0
        for (let item of newdata) {
            totalWithOutTax +=(item.selling_price*item.qty)
        }
        setTotalCoast({
            total:totalWithOutTax
        })
        setSelectedProducts(newdata)
    }
    const handleSubmit = async(data,installationCharge)=>{
        let itemArray = []
        for (let item of data) {
            let obj = {
                P_Id: item.product_code,
                sellingPricePerPic: item.selling_price,
                mrpPricePerPic: item.mrp,
                qty: item.qty,
                hsn_code: item.hsn_code,
                productName: item.name,
            }
            itemArray.push(obj)
        }
        console.log(itemArray,installationCharge,"submit");
       await updateUpsellItem(sub,itemArray,installationCharge,handleClose)
       
       setReload(!reload)
    }
    return (
        <div>
            <Button
                variant='contained'
                size='small'
                color='primary'
                onClick={() => {
                    setOpen(true)
                    console.log(sub, "sub");
                }}
            >
                {"Update Upsell Product"}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                     <Typography>Update Upsell Product</Typography>
                    <Box component='div' className='make_quto'>
                        <label>Select Product</label>
                        <Multiselect
                            displayValue="name"
                            onRemove={setSelectedProducts}
                            selectedValues={selectedProducts}
                            onSelect={onProductSelect}
                            options={products}

                            style={{
                                width: "100px !important",
                                multiselectContainer: {
                                    marginTop: "5px",
                                    backgroundColor: "#ffffff"
                                },
                            }}
                        />
                    </Box>
                    {
                        selectedProducts.length !== 0 ?
                            <Box component='div' className='make_quto ' style={{ overflowX: "scroll" }}>

                                <div className='row ml-2 mb-2'>
                                    <table className='table'>
                                        <thead>
                                            <tr>

                                                <th>Product Name</th>
                                                <th>M.R.P</th>
                                                <th>Selling Price</th>
                                                <th>Quantity</th>
                                                <th>HSN Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                selectedProducts.map((e) => {
                                                    return (
                                                        <tr>
                                                            <td><input type="text" value={e.name} onChange={(ele) => onChange(ele.target.value, e._id, "name")} style={{ width: "60px" }} /></td>
                                                            <td><input type="number" value={e.mrp} onChange={(ele) => onChange(Number(ele.target.value), e._id, "mrp")} min={1} style={{ width: "60px" }} /></td>
                                                            <td><input type="number" value={e.selling_price} onChange={(ele) => onChange(Number(ele.target.value), e._id, "selling_price")} min={1} style={{ width: "60px" }} /></td>
                                                            <td><input type="number" value={e.qty} onChange={(ele) => onChange(Number(ele.target.value), e._id, "qty")} min={1} style={{ width: "60px" }} /></td>
                                                            <td><input type="text" value={e.hsn_code} onChange={(ele) => onChange(ele.target.value, e._id, "hsn_code")} style={{ width: "60px" }} /></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>

                                </div>

                            </Box> : null
                    }
                    <Box component='div' className='make_quto updateFieldUpsell'>
                        <TextField
                            id="outlined-textarea"
                            type='number'
                            label="Installation Charges"
                            placeholder="Installation Charges"
                            name="installationCharges"
                            onChange={(e)=>setInstallationCharges(Number(e.target.value))}
                            value={installationCharges}
                        />
                        <TextField
                            id="outlined-textarea"
                            type='number'
                            label="Tax"
                            placeholder="Tax"
                            name="tax"
                            className='ml-4'
                            value={Math.ceil((totalCost.total+installationCharges)*0.18)}                        
                        />
                        <TextField
                            id="outlined-textarea"
                            type='number'
                            label="Total"
                            placeholder="Total"
                            name="total"
                            className='ml-4'
                            value={Math.ceil((totalCost.total+installationCharges)*1.18)}
                        />
                    </Box>
                    <Button
                      variant='contained'
                      onClick={()=>{
                        handleSubmit(selectedProducts,installationCharges);
                      }}
                    >Submit</Button>
                </Box>
            </Modal>


        </div>
    )
}

export default UpdateUpsellProduct