import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'
import { subHistory } from '../Function/SubscriptionHistory'
import { useSelector } from 'react-redux'

export default function RefundDeleteBtn({data,notify, SetReload,reload}) {
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    const deleteRefundRecord=()=>{
        axios.post(`${serverUrl.u}/app/fnd/deleteMultipleRefundRequest`,{
            subId:data._id,
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                notify(res.data.message)
                subHistory("Delete Refund Record",data,"Refund Record Delete","")
                SetReload(!reload)
            }else{
                notify(res.data.message)
            }
        }).catch(function (err) {
            console.log(err);
            notify("Error Occur")
        })
    }
  return (
    <>
      <button className='btn btn-danger' onClick={()=>deleteRefundRecord()}>Delete Refund</button>
    </>
  )
}
