import axios from 'axios'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { Button } from '@mui/material'
import { notify, notifyFailed } from '../Common/Notification'
import moment from 'moment'

export default function LastTenActionOnGrievence({data}) {
    let [datas,setDatas] = useState([])
    let [pop,setPop] = useState(false)
    const greivanceHistory = ()=>{
        axios.post(`${serverUrl.u}/api/grv/showActionOnSite`,{
            generatedHex:data.generatedHex
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                setDatas(res.data.data)
                if(res.data.data.length===0){
                  notify("No History Found")
                }else{
                setPop(true)
                }
            }else{
                notifyFailed("Failed")
            }
        }).catch(function (error) {
            console.log(error);
            notifyFailed("Failed")
        })
    }
  return (
    <>
       <button className='btn btn-primary'  onClick={()=>greivanceHistory()}>Action On Subscription </button>

       {
        (pop===true)?
        <div style={{width:"100vw",height:"100vh",position:"fixed",top:0,left:0,overflow:"scroll",display:"flex",alignItems:"center",justifyContent:"center",zIndex:400}}>
           {
            datas.length!==0?
            <div style={{width:"80%",height:"90vh",backgroundColor:"whitesmoke",padding:"2%",borderRadius:"12px",overflowY:"scroll"}} className='scrollbar'>
              <div style={{textAlign:"right"}}>
                <span style={{cursor:"pointer",fontSize:"18px"}} onClick={()=>{
                  setPop(false)
                  setDatas([])
                  }}>X</span>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Action Executed By</th>
                    <th>Type</th>
                    <th>Action Take</th>
                    <th>Comment</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    datas.map((ele,i)=>{
                      return(
                        <tr>
                          <td>{ele.enteringPerson}</td>
                          <td>{ele.actionType}</td>
                          <td>{ele.action}</td>
                          <td>{ele.Comments}</td>
                          <td>{moment(ele.date).format('DD-MM-YYYY')}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
            </table>
            </div>:null

           }
        </div>:null
       }
    </>
  )
}
