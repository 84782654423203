import axios from 'axios'
import React,{useState, useEffect} from 'react'
import { serverUrl } from '../ApiUrl'
import { notify, notifyFailed } from './Notification'

function OverdueInvoiceSelect({e,amount,setAmount,setZohoInvoices,pop}) {

    let [btn,setBtn] = useState("Search")
    let [text,setText] = useState("Loading...")
    let [btn1,setBtn1] = useState("Submit")
    let [invoice, setInvoice] = useState([])
    let [selectedInvoice, setSelectedInvoice] = useState([])
    
    
    
    
    function getInvoices(){
        axios.post(`${serverUrl.u}/api/tele/getAllOverdueInvoice`, {
          phone: e.phone
        }).then(function (res) {
          console.log(res);
          if (res.data.success === true) {
            notify(res.data.message)
            setInvoice(res.data.finalInvoice) 
          }else{
            notify(res.data.message)
            setText("Invoice not found")
          }
        }).catch(function (err) {
          console.log(err);
          notify(err.response.data.message)
          if(err.response.data.message==="Invoice not found"){
            setText("Invoice not found")
          }
        })
      }
    
      const getSelectedInput = (checked,data)=>{
         if(checked===true){
            setSelectedInvoice([...selectedInvoice,data])
         }else{
          let newFilterInvoice = [...selectedInvoice]
          newFilterInvoice = newFilterInvoice.filter((inv)=>{
            if(data.zohoInvoiceNumber!==inv.zohoInvoiceNumber){
              return inv
            }
          })
          setSelectedInvoice(newFilterInvoice)
         }
      }
      useEffect(()=>{
        console.log(selectedInvoice,"ii");
        let value = selectedInvoice.reduce((intialvalue,currentValue)=>{
                    return intialvalue+currentValue.actualTotalAmount
        },0) 
        setAmount(Math.round(value))
        let array=[]
        for(let i of selectedInvoice){
             array.push({zohoInvoiceNumber:i.zohoInvoiceNumber,generatedHex:i.generatedHex,amount:i.actualTotalAmount,type:"overDue",creditNotesNumber:[]})
        }
        setZohoInvoices(array)
    
      },[selectedInvoice])
      useEffect(()=>{
        if(pop===false) return
        getInvoices()
        setSelectedInvoice([])
      },[pop])
  return (
    <div>
            <div style={{ width:"fit-content",}}>
              
             
              {
                invoice.length !== 0 ?
                  <table className="table" style={{width:"35vw"}}>
                    <thead className="thead-light">
                      <tr>
                        <th>Select</th>
                        <th scope="col">Hex</th>
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Status</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                     {
                      invoice.map((inv,index)=>{
                        
                            return(
                              <tr>
                                <td><input type='checkbox' name="chk" onChange={(event)=>getSelectedInput(event.target.checked,inv)}/></td>
                                <td>{inv.generatedHex}</td>
                                <td>{inv.zohoInvoiceNumber}</td>
                                <td>{inv.status}</td>
                                <td>{inv.actualTotalAmount}</td>

                              </tr>
                            )
                          })
                       
                     }

                    </tbody>
                  </table> : <div>{text}</div>
              }
          </div>
          </div>
  )
}

export default OverdueInvoiceSelect