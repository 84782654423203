import { Box, Button, Modal,Stack } from '@mui/material'
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "max-content",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    overflowY:"scroll",
    maxHeight:"85vh",
}
const dateLeave = {
    width:"170px"
}
function TotalLevaves({leave}) {
    const [open, setOpen] = useState(false);
    const handleClose = ()=>{
     
        setOpen(false)
    }

    
  return (
    <div>
    <Button
      variant='contained'
      size='small'
      disabled={leave.approvedLeave.length===0?true:false}
      onClick={()=>setOpen(true)}
    >
        Approved Leave Details
    </Button>
   <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="ModelBox">
                   
                <Stack>
                        <Stack direction={"row"} spacing={1}>
                                            <item style={dateLeave}>Date</item>
                                            <item>Reason</item>
                                        </Stack>
                                       
                            {
                                leave.approvedLeave.map(le=>{
                                    return(
                                        <Stack direction={"row"} spacing={1} >
                                            <item style={dateLeave}>{moment(le.date).format("DD-MM-YYYY")}</item>
                                            <item>{le.description}</item>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                        <Button
                          variant='contained'
                          color='primary'
                          sx={{mt:2}}
                          onClick={async()=>{
                           handleClose()
                        }}
                        >
                            Close
                        </Button>
                </Box>
            </Modal>
    </div>
  )
}

export default TotalLevaves