import React,{useEffect,useRef,useState} from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { pages, serverUrl } from './ApiUrl';
import './MyRequest.css'
import { getAllRequestsParticular,getAllRequests,deletesubscription,getAllDepartmentRequestsParticular } from './Function/AxiosControl';
import Deleteanddrop from './Function/Deleteanddrop';
import CalculatePartialAmount from './Function/CalculatePartialAmount'
import DeleteAndDropMettingNotConverted from './Function/DeleteAndDropMettingNotConverted';
import TicketResolve from './Function/TicketResolve';
import ChangeDepartment from './Function/ChangeDepartment';
import CancelZohoSubscription from './Function/CancelZohoSubscription';
import SubscriptionPause from './Function/SubscriptionPause';
import Spinner from './Function/Spinner';
import RefundDetails from './Function/RefundDetails';
import moment from 'moment';

export default function DepartmentRequests({dep}) {
    let [reloadW, setReload] = useState(false);
    let [allRequest,setAllRequest] = useState([])
    let [tikResolveBtnText,setTikResolveBtnText] =useState("Ticket Resolve")
    let [requestData,setRequestData] = useState()
    let [spinnerActive,setSpinnerActive] = useState(false)
    let userRole = useSelector((state) => state.sar.data?.user?.roles)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let [reqUpdate,setReqUpdate] = useState(false)
    let [page,setPage] = useState(1)
  let [totalPage,setTotalPage] = useState(1)
    let adminComment = useRef("");
    let resolveStatu = useRef('');
    let comment = useRef("")
    const notify = (text) => toast(text);
  
    useEffect(()=>{
      let data=[]
     
      let fun1 = async()=>{
        data= await getAllDepartmentRequestsParticular(dep,page)
          console.log("d",data)
         if(data.slotData.length!==0){
           notify("Data is loaded")
          setAllRequest(data.slotData)
          setTotalPage(data.totalPage)
          setTikResolveBtnText("Ticket Resolve")
          }else if(data.slotData.length===0){
            setAllRequest([])
            notify("No Request Found")
          }else(
           notify("error is ocured")
          )
           }
           fun1();
     
     
       },[reloadW,page])

    const requestDelete= async(data)=>{
          await axios.post(`${serverUrl.u}/api/zoho/deletelogs`,{
            subId:data._id
          }).then(function (res) {
            console.log(res.data.success);
            if(res.data.success===true){
              notify(res.data.message)
            setReload(!reloadW)
            }else{
              notify(res.data.message)
            }
          }).catch(function (er) {
            console.log(er);
            notify(er.message)
          })
    }

    const requestEdit= async(adminC,resolveS,comm,data)=>{
      await axios.post(`${serverUrl.u}/api/zoho/editlogs`,{
        subId:data._id,
        enteringPerson:data.enteringPerson,
        adminComments:adminC, 
        resolveStatus:resolveS,
        generatedHex:data.generatedHex,
        comments:`${comm} - ${moment(new Date()).format("DD-MM-YYYY")}`,
        type:data.type,
      }).then(function (res) {
        console.log(res.data.success);
        if(res.data.success===true){
          notify(res.data.message)
          setReqUpdate(false)
        setReload(!reloadW)
        }else{
          notify(res.data.message)
        }
      }).catch(function (er) {
        console.log(er);
        notify(er.message)
      })
}


  return (
    <div style={{padding:"0 4%",marginTop:"4vh"}}>
    <div>My Ticket</div> 
    <nav aria-label="..." style={{marginLeft:"1vw",marginTop:"2vh"}} className="pagination-container">
      <ul class="pagination">
        {
          [...Array(totalPage)].map((e,i)=>{
              return(
                
                <li className={page===i+1?"page-item active":"page-item"} onClick={()=>setPage(i+1)}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
</nav>
              <div className='request-container'>
                
                {
                  allRequest.length!==0?
                  allRequest.map((e)=>{
                   return(
                    <div className='request-single-container'>
                    <div className='request-detail'>
                       <span>Tiket created by :-</span>
                       <span>{e.enteringPerson}</span>
                    </div>
                    <div className='request-detail'>
                       <span>Tiket created on :-</span>
                       <span>{moment(e.createdAt).format('DD-MMM-YYYY')}</span>
                    </div>
                    <div className='request-detail'>
                       <span>Telecaller Name :-</span>
                       <span>{e.teleCallerId}</span>
                    </div>
                    <div className='request-detail'>
                       <span>department Name :-</span>
                       <span>{e.department}</span>
                    </div>
                    <div className='request-detail'>
                       <span>Client Name :-</span>
                       <span>{e.name}</span>
                    </div>
                    <div className='request-detail'>
                        <span>Admin Comments :-</span>
                        <span>{e.adminComments}</span>
                    </div>
                    <div className='request-detail'>
                         <span>Ticket Status :-</span>
                         <span>{e.resolveStatus}</span>
                    </div>
                    <div className='request-detail'>
                         <span>Appliances :-</span>
                         <span>{e.totalAppliances}</span>
                    </div>
                    <div className='request-detail'>
                         <span>Partial Status :-</span>
                         <span style={{ color: `${e.partialPayment === false ? "green" :(e.partialAmount===0)?"red":"orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount===0)?"Zero":`Partial`}</span>
                    </div>
                    <div className='request-detail'>
                         <span>HexCode</span>
                         <span>{e.generatedHex}</span>
                    </div>
                    <div className='request-detail'>
                        <span>Comment</span>
                        <span>{e.comments}</span>
                   </div>
                   <div className='request-detail'>
                        <span>Plan Frequency</span>
                        <span>{e.planFrequency}</span>
                   </div>
                 
                  
                
                {/* <div className='request-detail'>
                   <span>Zoho :- </span>
                   <span >s-{e.zohoSecurity},i-{e.zohoInstallation},r-{e.zohoRecursiveAmount},t-{e.zohoTotalAmount}</span>
                </div> */}
                   <div className='request-detail'>
                        <span>Type</span>
                        <span>{e.type}</span>
                   </div>
                   <CalculatePartialAmount e={e} notify={notify} />   
                   <div className='btn-container-myRequest mt-3 '>
                   <button className="btn btn-primary width mb-2"  onClick={()=>{setReqUpdate(true) 
                                                                     setRequestData(e)}}>Edit</button>
                    <button className="btn btn-danger width mb-2"  onClick={()=>requestDelete(e)}>
                     Ticket Delete
                    </button> 
                    {
                    userRole?.includes('Super Admin')?
                    <TicketResolve e={e} reload={reloadW} setReload={setReload}  notify={notify} spinnerActive={spinnerActive} setSpinnerActive={setSpinnerActive} tikResolveBtnText={tikResolveBtnText} setTikResolveBtnText={setTikResolveBtnText}/>:null
                  }  
                    <ChangeDepartment e={e} reload={reloadW} setReload={setReload}  notify={notify}/>
                   {
                        userRole?.includes('Super Admin')?
                    <SubscriptionPause e={e} reload={reloadW} setReload={setReload}  notify={notify}/>:null
                  }  
                 
                  
                    {
                        ((loginName === "Udit" || loginName === "BHAVYA" || loginName ==="Konark" || loginName === "Akhil") && (e.partialPayment===true))?
                    <Deleteanddrop e={e.subId} data={e} reloadW={reloadW} setReload={setReload} notify={notify} page={pages.department} />:null
                  } 
                  {
                    (dep === "Refund" &&  userRole?.includes('Super Admin'))?
                      <RefundDetails e={e} notify={notify}/>:null
                  }
                  
                                      
                </div>
                    </div>  
                    

                   )
                  }):null
                }
                <ToastContainer/>
              {
                reqUpdate===true?
                <div className="greivance-container" style={{top:"21% !important"}}>
                <span className="cross" onClick={() => setReqUpdate(!reqUpdate)}>x</span>
                
                 <div style={{display:loginName=="Udit" || loginName==="Akhil"?"":"none"}}>
                <label className='create-label1'>Admin Comment</label>
                <div class="input-group mb-1">
                  <input type="text" ref={adminComment}  defaultValue={requestData.adminComments}/>
                </div>
                <label className='create-label1'>Resolve Status</label>
                <div class="input-group mb-1">
                  <input type="text" ref={resolveStatu}  defaultValue={requestData.resolveStatus} />
                </div>
                </div>
                
                <label className='create-label1'>Comment</label>
                <div class="input-group mb-1">
                  <input type="text" ref={comment}  defaultValue={requestData.comments}/>
                </div>
                <button className='btn btn-primary' onClick={()=>requestEdit(adminComment.current.value,resolveStatu.current.value,comment.current.value,requestData)}>Update Request</button>
            </div>:null
              }
              </div>

            </div>
  )
}
