import { AppBar, Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import DeviceTable from "./components/DeviceTable";

export default function House() {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <div className="house">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <AppBar
          position="static"
          color="transparent"
          sx={{ bgcolor: "#f1f0f0", color: "white" }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Device Table" />
          </Tabs>
        </AppBar>

        {tab === 0 && <DeviceTable />}
      </Box>
    </div>
  );
}
