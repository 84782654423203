import { upgradeRequestAction } from "./actionIndex"

export const UpgradeRequestReducer = (state = {}, action) => {
    
    switch (action.type) {
        case upgradeRequestAction.All_Request_Upgrade:
            return action.payload
        case upgradeRequestAction.All_Request_Empty_Upgrade:
            return {...state,upgradeRecord:action.payload}
        case upgradeRequestAction.All_Request_Empty_Upgrade_All:
            
            return {}
        default:
            return state
    }
}
export const UpgradePendingRequestReducer = (state = {}, action) => {
    
    switch (action.type) {
      
        case upgradeRequestAction.All_Pending_Upgrade:
            return action.payload
        case upgradeRequestAction.All_Pending_Empty_Upgrade:
            return {...state,upgradeRecord:action.payload}
        case upgradeRequestAction.All_Pending_Empty_Upgrade_All:
            
            return {}
        default:
            return state
    }
}