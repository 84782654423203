
 import React, { useEffect, useState } from 'react';
 import { useFormik } from 'formik';
 import TextField from '@mui/material/TextField';
 import './Login.css'
 import {  useNavigate } from "react-router-dom";
 import axios from 'axios';
 import { useDispatch } from "react-redux";
 import {saveData} from './Redux/action'
 import Button from '@mui/material/Button';
 import {serverUrl} from './ApiUrl'
 import { salesUserData } from './Redux/UserData';

 const validate = values => {
   const errors = {};
   if (!values.password) {
    errors.password = 'Required';
    }
   if (!values.email) {
     errors.email = 'Required';
   } 
   return errors;
 };

export default function Login() {
   
   let [detail,setDetails] = useState({})
   let [errorsApi,setErrorApi] = useState(false)
   let dispatch = useDispatch();
   const navigate = useNavigate();
   const formik = useFormik({
     initialValues: {
       password: '',
       email: '',
     },
     validate,
     onSubmit: async(values) => {
     setDetails(values, null,5);
      

       if(detail!==undefined){
      localStorage.setItem("user", JSON.stringify(values));
     
       }
     },
   });
  useEffect(()=>{
    if(detail.email===undefined)return;
    let updateDetails=async()=>{
    
        await axios.post(`${serverUrl.s}/users/login`,{
            employeeId:detail.email,
            password:detail.password
        }).then(async function (response) {
        
        if(response.data.success===true){
           dispatch(saveData(response.data.data));
           salesUserData(dispatch,response.data.data.token)
           localStorage.setItem('userProfile', JSON.stringify(response.data.data));
              setErrorApi(false)
               navigate('/main')
        }
        
      })
      .catch(function (error) {
        console.log(error);
        setErrorApi(true)
      })
    }
      updateDetails();
  },[detail])

   return (
    <div  className='login-container'>
        <div className='login-form'>
            <div className='login-heading'>Welcome</div>
            <img src={process.env.PUBLIC_URL+"Aliste Logo.png"} className="logo-aliste" alt="logo"></img>
     <form onSubmit={formik.handleSubmit}>
       
 
 
    
       <TextField
         variant="filled"
         id="email"
         name="email"
         type="text"
         label="Employee Id"
         onChange={formik.handleChange}
         value={formik.values.email}
         InputProps={{ style: { fontSize: "20px" } }}
        InputLabelProps={{ style: { fontSize: "15px" } }}
         sx={{
            width:"100%",
            textAlign:"left"
         }}
       />
       {formik.errors.email ? <div className='error-login'>{formik.errors.email}</div> : null}
       <TextField
         variant="filled"
         id="password"
         name="password"
         type="password"
         label="Password"
         InputProps={{ style: { fontSize: "20px" } }}
        InputLabelProps={{ style: { fontSize: "15px" } }}
         onChange={formik.handleChange}
         value={formik.values.password}
         sx={{
            width:"100%",
            marginTop:"15px",
            fontSize:"14px",
            textAlign:"left"
         }}
       />
       {formik.errors.password ? <div className='error-login'>{formik.errors.password}</div> : null}
       <Button variant="contained" type="submit" sx={{marginTop: "6%",
    fontSize: "15px",
    borderRadius: "12px"}}>Login</Button>

     </form>
     
     {
      errorsApi===true?
      <div className='failed'>User does not exists</div>:null
     }
     </div>
     </div>
   );
 };