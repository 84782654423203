import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { updateRefundReferenceId } from '../Function/RefundFunction'
import { Grid } from '@mui/material';
export default function RefundReference({e,notify,SetReload,reload}) {
   let [forms,setForm] = useState(false)
   let salesData = useSelector(state=>state.salesUser)
   let loginName = useSelector((state) => state.sar.data.user.first_name)
   const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userData = {
      refundBy: data.get("refundBy"),
      refferenceId: data.get("refundReferenceId"),
      refundAmount:data.get('refundAmount'),
      };
      
       let res =   await updateRefundReferenceId(userData,e,notify);
       if(res.data.success===true){
        setForm(false)
        SetReload(!reload)
       }
       console.log(res,"id update");
  };
     return (
    <>
    <button className='btn btn-primary' onClick={()=>setForm(true)}>Update Reference id</button>

    {forms=== true ? (
        <div className="updatePlan">
          <form onSubmit={handleSubmit}>
            <div className="cross1" onClick={() => setForm(false)}>
              x
            </div>
            <Grid container spacing={4}>
             
              <Grid item xs={12} md={6}>
                <label>Refund By<span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <select class="custom-select height-select" 
                                  required 
                                  id="refundBy" 
                                  name="refundBy"
                                  aria-label="refundBy"
                                  aria-describedby="basic-addon1"
                                  autoComplete="refundBy" 
                                  defaultValue={loginName}
                                  >
                                     {salesData?.map((option) => (
                                         <option value={option?.first_name}>{option?.first_name}</option>
                                      ))}
                                       
                          </select>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Refference Id <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="refundReferenceId"
                    required
                    name="refundReferenceId"
                    autoComplete="current-refundReferenceId"
                    placeholder=""
                    aria-label="refundReferenceId"
                    aria-describedby="basic-addon1"
                    defaultValue={e.referenceId}
                  />
                </div>
              </Grid>
            </Grid>
          
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
              <label>Refund Amount <span style={{color:"red"}}>*</span></label>
                <div class="input-group mb-3">
                  <input
                    type="number"
                    class="form-control"
                    id="refundAmount"
                    required
                    name="refundAmount"
                    autoComplete="current-refundAmount"
                    placeholder=""
                    aria-label="refundAmount"
                    aria-describedby="basic-addon1"
                    defaultValue={e.refundAmount}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={2}>
                <button className="btn btn-primary" type="submit">
                  Update Refund Id
                </button>
              </Grid>
              
            </Grid>
          </form>
             </div>
      ) : null}
    </>
  )
}
