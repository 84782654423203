import React, { useEffect, useState } from 'react'
import { clientData } from './data';
import { Alert, Box, Button } from '@mui/material';
import './MakeQuto.css'
import { dummy, planDuration } from '../Data';
import { useActionData } from 'react-router-dom';
import { leadCreatedOrNot, newClientRegister } from './Api';
import { recursiveAmountCalculate } from '../Common/applianceDifference';
import { useSelector } from 'react-redux';
import { notifyFailed } from '../Common/Notification';
const TermCondition = ({term,setTotalTerm,totalterm})=>{
  
  const [Value,setValue] = useState(term)
  useEffect(()=>{
    setValue(term)
  },[term])
  return(
    <div className='row mt-2'>
      <div className='col-8'>
      <input
         className='form-control'
         value={Value.value}
         onChange={(e)=>{
          setValue({...Value,value:e.target.value})
          let data = totalterm.map(i=>{
            if(i.id===Value.id){
              return {
                value:e.target.value,
                id:Value.id
              }
            }else{
              return i
            }
          })
          console.log(data,"edit");
          setTotalTerm(data)
        }}
      />
      </div>
      {/* <div className='col-4'>
        <button
         className='btn btn-primary'
         type='button'
         onClick={()=>{
          let t = totalterm.filter(e=>{
            if(e.id!==value.id){
              return e
            }})
            console.log(t,"remove");
          setTotalTerm(t)
         }}
        >
          Remove
        </button>
      </div> */}
    </div>
  )
}
function MakeQuto({Leadpage}) {
  let oneTime = "One-Time"
  let userRole = useSelector((state) => state.sar.data?.user?.roles)
  let teleCallers = useSelector((state) => state.sar.data.user.first_name)
  const [activeTitle, setActiveTitle] = useState(clientData.title[0])
  const [phones,setPhone] = useState("")
  const [activePlan, setActivePlan] = useState("1 Room")
  let [getData, setGetData] = useState([]);

  const [totalterm,setTotalTerm] = useState(clientData.termCondition.subscription.split('\n').map((e,i)=>{
    return {
      value:e,
      id:i
    }
  }))
  const [activeFrequency, setActiveFrequency] = useState(planDuration[0].value)
  const [installation, setInstallation] = useState(1000)

  const [securityRate,setSecurityRate] = useState(100)
  const [chargeType, setChargeType] = useState(clientData.typeOfCharges[0])
  const [costAppliances, setCostAppliances] = useState(0)
  const [appliance, setAppliances] = useState(4)
  const [havvyAppliance, setHavvyAppliances] = useState(0)
  const [securityCharges,setSecurityCharges] = useState(400)
  const [discount,setDiscount] = useState(0)
  const [clientName, setClientName] = useState("")
  let [qutoBtnText, setQutoBtnText] = useState("Download")
  let [numberWhatapp, setNumberWhatapp] = useState("");
  const [open, setOpen] = useState(false)
  let [paymentLink, setPaymentLink] = useState("")
   console.log(clientData.termCondition.subscription.split('\n'),"to");
  const [text, setText] = useState("");
  const [leadNotFound,setLeadNotFound] = useState("")
  const [planName, setPlanName] = useState([clientData.planName[4], clientData.planName[0]])
  useEffect(()=>{
    console.log("term")
   },[totalterm])
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (clientName === "" ) {
      notifyFailed("Name is Mandatory")
      setQutoBtnText("Download")
      return
    }
    if(discount>(installation+securityCharges)){
      notifyFailed("Discount can't be Greater than Installation + Sequrity")
      setQutoBtnText("Download")
      return
    }
    let termsAndCons = ""
    for(let t of totalterm){
      termsAndCons+=`${t.value}\n`
    }
    const data = new FormData(event.currentTarget);
    let userData2 = {
      first_name: `${activeTitle} ${clientName.trim()}`,
      last_name: "",
      phone: phones,
      city: getData.city,
      address: "no",
      planType: activeFrequency,
      plan: "",
      origin: "",
      leadQuality: 0,
      email: "demo@gmail.com",
      zip: "000000",
      comments: data.get("comments"),
      security: activeFrequency === oneTime ? 0 : securityCharges,
      installion: installation,
      teleCallerId: teleCallers,
      refferal: "empty",
      teleCallerSecond: "empty",
      quantityAppliances: appliance,
      havvyAppliance:havvyAppliance,
      partialAmount: 100,
      costAppliances: costAppliances,
      installationApply:chargeType===clientData.typeOfCharges[0]?true:false,
      termsAndCons:termsAndCons,
      page:"quto",
      securityRate:securityRate,
      discount:discount
    }

    let seq = activeFrequency === oneTime ? 0 : Number(userData2.security);
    let ins = Number(userData2.installion);
    let qu = Number(userData2.quantityAppliances)
    let planDurations = userData2.planType;
    let fee = 0
    let lightFee=0
    let havvyFee=0
    let lightAppliance = appliance-havvyAppliance

    if (activeFrequency === oneTime) {
      fee = appliance * costAppliances
      lightFee=fee

    } else {
      let havvyLihgt = (havvyAppliance*2)+lightAppliance
      console.log(havvyLihgt,"havvylihh");
      fee = await recursiveAmountCalculate(planDurations, havvyLihgt);
      lightFee = await recursiveAmountCalculate(planDurations, lightAppliance);
      havvyFee = await recursiveAmountCalculate(planDurations, havvyAppliance*2);
    }

    let total = ((fee + ins) * 1.18 + seq).toFixed(2)
    // setCheck(!check);
    let tax = ((fee + ins) * 0.18).toFixed(2)
    userData2 = { ...userData2, totalAppliances: qu, orignalTotalAmount: total, totalAmount: total, orignalRecursiveAmount: fee, recursiveAmount: fee, tax: tax,lightFee:lightFee,heavyFee:havvyFee,lightAppliances:lightAppliance,heavyAppliances:havvyAppliance }
    newClientRegister(userData2, setQutoBtnText, 0, "Any for Now", userRole, teleCallers, dummy, setText, setPaymentLink, { planName: activePlan }, setOpen)
  };
  const handleAppliances = (event) => {
    let app = Number(event.target.value)
    setAppliances(app)
    let plan = clientData.planName[0]
    let Installion;
    if (app > 10) {
      let cal = app - 10;
      Installion = 1000 + (cal * 50);
    } else {
      Installion = 1000;
    }
    setInstallation(Installion)
    setSecurityCharges(app*securityRate)
    switch (app) {
      case 4:
        plan = clientData.planName[app]
        break;
      case 8:
        plan = clientData.planName[app]
        break;
      case 12:
        plan = clientData.planName[app]
        break;
      case 18:
        plan = clientData.planName[app]
        break;
      case 22:
        plan = clientData.planName[app]
        break;
      case 26:
        plan = clientData.planName[app]
        break;
      case 30:
        plan = clientData.planName[app]
        break;
      default:
        plan = clientData.planName[0]
        break;
    }
    if (plan === clientData.planName[0]) {
      setPlanName([plan])

    } else {
      setPlanName([plan, clientData.planName[0]])
    }
    setActivePlan(plan)

  }
  const callFilled = async (number) => {
    
    if (number.length < 10) return;
    let Lead = await leadCreatedOrNot(number)
    console.log(Lead,"found lead");
    setGetData(Lead)
    setPhone(number)
    setClientName(Lead.first_name)
    if(Object.keys(Lead).length===0){
        notifyFailed("First Register Lead")
        setLeadNotFound("notFound")
        setPhone("")
        setClientName("")
    }
    
}

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="make_quto">
                    <div className="row">
                        <div className="col-6">
                            <label className="create-label1">Phone Number <span className="required_star">*</span></label>
                            <div class="input-group mb-1 ">
                                <input type="text"
                                    class="form-control"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    autoComplete="current-phoneNumber"
                                    defaultValue={getData?.phone}
                                    required
                                    onChange={(ele) => callFilled(ele.target.value)}
                                    placeholder="Phone Number"
                                    aria-label="phoneNumber"
                                    aria-describedby="basic-addon1" />
                            </div>
                        </div>
                       
                
                    </div>
                </div>
                {
                Object.keys(getData).length!==0?
                <>
      <Box component="form" className='make_quto newQuto' validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
        <div>
          <label>Title</label>
          <div className='title-Container'>
            {
              clientData.title.map(title => (
                <div
                  className='title'
                  style={{
                    backgroundColor: activeTitle === title ? clientData.activeColor : "white",
                    color: activeTitle === title ? "white" : "black"
                  }}
                  onClick={() => setActiveTitle(title)}
                >{title}</div>
              ))
            }
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <label>Name</label>
            <input
              className='form-control'
              placeholder='Client Name'
              name='name'
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              autoComplete='current-name'
            />
          </div>

        </div>
        <div className='row'>
          <div className='col-6'>
            <label>Total Appliances</label>
            <input
              type='number'
              className='form-control'
              placeholder='Appliances'
              name='appliances'
              value={appliance}
              autoComplete='current-appliances'
              onChange={handleAppliances}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <label>Heavy Appliances (One-Time Not Applicable)</label>
            <input
              type='number'
              className='form-control'
              placeholder='Havvy Appliances'
              name='havvy_appliances'
              value={havvyAppliance}
              autoComplete='current-havvy_appliances'
              onChange={(e)=>setHavvyAppliances(Number(e.target.value))}
            />
          </div>
        </div>
        <div className='col p-0'>
          <label>Plan Name</label>
          <div className='title-Container'>
            {
              planName.map((title) => (
                <div
                  className='title'
                  style={{
                    backgroundColor: activePlan === title ? clientData.activeColor : "white",
                    color: activePlan === title ? "white" : "black"
                  }}
                  onClick={() => setActivePlan(title)}
                >{title}</div>
              ))
            }
          </div>
          <div className='col-6 p-0'>
                <label>Custom Plan Name </label>

                <input
                  type='text'
                  className='form-control'
                  placeholder='Plan Name'
                  name='planName'
                  value={activePlan}
                  onChange={(e) => setActivePlan((e.target.value))}
                  autoComplete='current-planName'
                />
              </div>
        </div>
        <div className='col p-0'>
          <label>Plan Duration</label>
          <div className='title-Container' style={{flexWrap:'wrap'}}>
            {
              planDuration.map((title) => {
                if (![0, 7].includes(title.id)) {
                  return (
                    <div
                      className='title'
                      style={{
                        backgroundColor: activeFrequency === title.value ? clientData.activeColor : "white",
                        color: activeFrequency === title.value ? "white" : "black"
                      }}
                      onClick={() => {
                        setTotalTerm(clientData.termCondition.subscription.split('\n').map((e,i)=>{
                          return {
                            value:e,
                            id:i
                          }
                        }))
                        setActiveFrequency(title.value)
                      }}
                    >{title.value}</div>
                  )
                }
              })
            }
            <div
              className='title'
              style={{
                backgroundColor: activeFrequency === oneTime ? clientData.activeColor : "white",
                color: activeFrequency === oneTime ? "white" : "black"
              }}
              onClick={() => {
                setTotalTerm(clientData.termCondition.onTime.split('\n').map((e,i)=>{
                  console.log(e,"eeeeee");
                  return {
                    value:e,
                    id:i
                  }
                }))
                setActiveFrequency(oneTime)
                setHavvyAppliances(0)
              }}
            >{oneTime}</div>
          </div>
        </div>
        {
          activeFrequency === oneTime && (
            <div className='row'>
              <div className='col-6'>
                <label>Cost Per Appliance </label>

                <input
                  type='number'
                  className='form-control'
                  placeholder='Appliance Cost'
                  name='costAppliances'
                  value={costAppliances}
                  onChange={(e) => setCostAppliances(Number(e.target.value))}
                  autoComplete='current-costAppliances'
                />
              </div>
            </div>
          )
        }
        <div className='col p-0'>
          <label>Plan Duration</label>
          <div className='title-Container'>
            {
              clientData.typeOfCharges.map((title) => {
             
                  return (
                    <div
                      className='title'
                      style={{
                        backgroundColor: chargeType === title ? clientData.activeColor : "white",
                        color: chargeType === title ? "white" : "black"
                      }}
                      onClick={() => setChargeType(title)}
                    >{title}</div>
                  )
                
              })
            }
          </div>
        </div>
        
        <div className='row'>
          <div className='col-6'>
            <label>{chargeType}</label>

            <input
              type='number'
              className='form-control'
              placeholder='Installation Charges'
              name='installation'
              value={installation}
              onChange={(e) => setInstallation(Number(e.target.value))}
              autoComplete='current-installation'
            />
          </div>
        </div>
        {
          activeFrequency !== oneTime && (
            <>
            <div className='row'>
            <div className='col-6'>
              <label>Per Appliance Security Rate</label>
  
              <input
                type='number'
                className='form-control'
                placeholder='Security Rate'
                name='SecurityRate'
                value={securityRate}
                onChange={(e) => {
                  setSecurityRate(Number(e.target.value))
                  setSecurityCharges(Number(e.target.value)*appliance)
                }}
                autoComplete='current-securityRate'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <label>Security</label>
  
              <input
                type='number'
                className='form-control'
                placeholder='Security Charges'
                name='SecurityCharges'
                value={securityCharges}
                onChange={(e) => setSecurityCharges(Number(e.target.value))}
                autoComplete='current-SecurityCharges'
              />
            </div>
          </div>
          </>
          )
        }
        <div className='row'>
            <div className='col-6'>
              <label>Discount</label>
  
              <input
                type='number'
                className='form-control'
                placeholder='Discount'
                name='discount'
                value={discount}
                onChange={(e) => {

                  console.log(installation+securityCharges,Number(e.target.value));
                  if(Number(e.target.value)<=(installation+securityCharges))
                  setDiscount(Number(e.target.value))
                }}
                autoComplete='current-SecurityCharges'
              />
            </div>
          </div>
        <div className='col'>
        <label>Term Conditions</label>
        </div>
        
        {
          totalterm.map((t,i)=>(
            <TermCondition term={t} totalterm={totalterm} setTotalTerm={setTotalTerm}/>
          ))
        }
        <div className='row mt-2'>
          <div className='col'>
          <button 
            className='btn btn-primary'
            type='button'
            onClick={()=>{
              let newData = totalterm.map((tt,i)=>{
                return {
                  value:tt.id,
                  id:i
                }
              })
               setTotalTerm([
                ...totalterm,
                {
                  value:"",
                  id:newData.length
                }
               ])
              setTotalTerm([...totalterm,""])
              }}>
            Add Terms
          </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-2">
            <button
              className="btn btn-primary"
              type="submit"
              variant="contained"
              sx={{ pointerEvents: `${qutoBtnText === "Processing..." ? "none" : ""}`, backgroundColor: `${qutoBtnText === "Processing..." ? "#cccccc" : "#2552B2"}` }}
              onClick={() => {

                setQutoBtnText("Processing...")
              }}
            >
              {qutoBtnText}
            </button>
          </div>

        </div>
      </Box>
      </>:null}
      {
                leadNotFound!=="" ?

                    <Alert variant="filled" severity="error"
                        sx={{ fontSize: "15px", marginTop: "5%",marginBottom:'3%', }}>
                         First Register Lead  
                         <Button onClick={()=>{
                            Leadpage()
                            }}>Click here</Button>
                    </Alert> : null
            }
      <div className="make_quto">
        <div className="row">
          <div className="col-4">
            <div class="input-group">
              <input type="text" style={{ height: "41px !important" }} class="form-control" id="phoneNumbers" name="phoneNumbers" required placeholder="Phone Number" aria-label="phoneNumbers" aria-describedby="basic-addon1" onChange={(e) => {
                setNumberWhatapp(e.target.value)
              }} />
            </div>
          </div>
          <div className="col-2">
            <a href={`http://wa.me/${numberWhatapp}?text=Hey there, 
This is ${teleCallers} from Aliste Home Automation. 
As discussed, I am sharing our brochure, pricing and videos below. 
Would request you to go through them and let me know if you have any queries in regards to the same. 
For further details please feel free to reach out to me or visit alistetechnologies.com`} target="_blank">
              <img src={process.env.PUBLIC_URL + '/assets/icon/WhatsApp_logo.svg'} style={{ height: "43px" }} />
            </a>
          </div>

        </div>
      </div>

    </div>
  )
}

export default MakeQuto