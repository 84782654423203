import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Multiselect from 'multiselect-react-dropdown';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { notify, notifyFailed } from '../Common/Notification';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: '85vh',
  overflowY: 'scroll',
};

function EditInvoice({ invoice, sub, setInvoice }) {
  let [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [installationCharges, setInstallationCharges] = useState(
    Number(sub.currentInstallation)
  );
  const [totalCost, setTotalCoast] = useState({});
  const [gstName, setGstName] = useState(invoice.BillTo);
  const [gstNumber, setGstNumber] = useState(invoice.BusinessGst);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .post(`${serverUrl.u}/v3/affiliateProducts/listAll`)
      .then(function (res) {
        setProducts(res.data.data.products);
        let dataofSelectedProduct = [];
        let totalWithOutTax = 0;
        for (let item of invoice.product) {
          for (let pro of res.data.data.products) {
            if (item.P_Id === pro.product_code) {
              dataofSelectedProduct.push({
                ...pro,
                selling_price: item.sellingPricePerPic,
                mrp: item.mrpPricePerPic,
                hsn_code: item.hsn_code,
                qty: item.qty,
              });
              totalWithOutTax += item.sellingPricePerPic * item.qty;
            }
          }
        }
        let ins = invoice.additionalInfo[0].item_total - totalWithOutTax;
        setTotalCoast({
          total: totalWithOutTax,
        });
        setInstallationCharges(ins);
        setSelectedProducts(dataofSelectedProduct);
      })
      .catch(function (err) {});
  }, []);
  const onChange = (value, id, key) => {
    let newData = [];
    for (let p of selectedProducts) {
      if (p._id === id) {
        if (key === 'hsn_code' || key === 'name') {
          newData.push({ ...p, [key]: value });
        } else {
          newData.push({ ...p, [key]: Number(value) });
        }
      } else {
        newData.push(p);
      }
    }
    let totalWithOutTax = 0;
    for (let item of newData) {
      totalWithOutTax += item.selling_price * item.qty;
    }
    setTotalCoast({
      total: totalWithOutTax,
    });
    setSelectedProducts(newData);
  };

  const onProductSelect = (data) => {
    let newdata = [];
    for (let p of data) {
      newdata.push({ ...p, qty: p.qty === undefined ? 1 : p.qty });
    }

    let totalWithOutTax = 0;
    for (let item of newdata) {
      totalWithOutTax += item.selling_price * item.qty;
    }
    setTotalCoast({
      total: totalWithOutTax,
    });
    setSelectedProducts(newdata);
  };
  const invoiceDetails = async () => {
    await axios
      .post(`${serverUrl.u}/api/tele/getAllInvoicesDetails`, {
        generatedHex: sub.generatedHex,
      })
      .then(function (res) {
        if (res.data.success === true) {
          setInvoice(res.data.data);
          if (res.data.data.length > 0) {
          } else {
          }
        } else {
        }
      })
      .catch(function (err) {
        notify('Failed');
      });
  };
  const handleSubmit = (products, insCharges) => {
    setLoading(true);
    let itemArray = [];
    for (let item of products) {
      let obj = {
        P_Id: item.product_code,
        sellingPricePerPic: item.selling_price,
        mrpPricePerPic: item.mrp,
        qty: item.qty,
        hsn_code: item.hsn_code,
        productName: item.name,
      };
      itemArray.push(obj);
    }

    let payload = {
      invoiceNumber: invoice.invoiceNumber,
      gstName: gstName,
      gstNumber: gstNumber,
      generatedHex: invoice.generatedHex,
      product: itemArray,
      additionalInfo: [
        { item_total: Math.ceil(totalCost.total + installationCharges) },
      ],
      amount: Math.ceil((totalCost.total + installationCharges) * 1.18),
    };
    axios
      .post(`${serverUrl.u}/app/tele/updateGSTDetailsInInvoice`, payload)
      .then((res) => {
        if (res.data.success) {
          invoiceDetails();
          notify(res.data.message);
          setLoading(false);
        } else {
          notifyFailed(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed(err.response.data.message);
      });
  };
  return (
    <>
      <button className='btn btn-primary' onClick={() => setOpen(true)}>
        Edit Invoice
      </button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{ ...style, width: '80%' }}>
          <Stack direction={'row'} spacing={5} mb={4} mt={4}>
            <Typography mb={3}>{invoice.name}</Typography>
            <Typography mb={3}>
              Current Invoice Amount :-{' '}
              {invoice.hasOwnProperty('additionalInfo') &&
                invoice?.additionalInfo[0]?.item_total}
            </Typography>
            <Typography mb={3}>
              Current Invoice Amount + GST :- {invoice.actualTotalAmount}
            </Typography>
          </Stack>
          <Box component='div' className='make_quto'>
            <TextField
              id='outlined-textarea'
              type='text'
              label='GST Name'
              placeholder='GST Name'
              name='GST Name'
              onChange={(e) => setGstName(e.target.value)}
              value={gstName}
            />
            <TextField
              id='outlined-textarea'
              type='text'
              label='GST Number'
              placeholder='GST Number'
              name='GST Number'
              className='ml-4'
              value={gstNumber}
              onChange={(e) => setGstNumber(e.target.value)}
            />
          </Box>
          <Box component='div' className='make_quto'>
            <label>Select Product</label>
            <Multiselect
              displayValue='name'
              onRemove={onProductSelect}
              selectedValues={selectedProducts}
              onSelect={onProductSelect}
              options={products}
              style={{
                width: '100px !important',
                multiselectContainer: {
                  marginTop: '5px',
                  backgroundColor: '#ffffff',
                },
              }}
            />
          </Box>
          {selectedProducts.length !== 0 ? (
            <Box
              component='div'
              className='make_quto '
              style={{ overflowX: 'scroll' }}
            >
              <div className='row ml-2 mb-2'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>M.R.P</th>
                      <th>Selling Price</th>
                      <th>Quantity</th>
                      <th>HSN Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProducts.map((e) => {
                      return (
                        <tr>
                          <td>
                            <input
                              type='text'
                              value={e.name}
                              onChange={(ele) =>
                                onChange(ele.target.value, e._id, 'name')
                              }
                              style={{ width: '60px' }}
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              value={e.mrp}
                              onChange={(ele) =>
                                onChange(Number(ele.target.value), e._id, 'mrp')
                              }
                              min={1}
                              style={{ width: '60px' }}
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              value={e.selling_price}
                              onChange={(ele) =>
                                onChange(
                                  Number(ele.target.value),
                                  e._id,
                                  'selling_price'
                                )
                              }
                              min={1}
                              style={{ width: '60px' }}
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              value={e.qty}
                              onChange={(ele) =>
                                onChange(Number(ele.target.value), e._id, 'qty')
                              }
                              min={1}
                              style={{ width: '60px' }}
                            />
                          </td>
                          <td>
                            <input
                              type='text'
                              value={e.hsn_code}
                              onChange={(ele) =>
                                onChange(ele.target.value, e._id, 'hsn_code')
                              }
                              style={{ width: '60px' }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Box>
          ) : null}
          <Box component='div' className='make_quto updateFieldUpsell'>
            <TextField
              id='outlined-textarea'
              type='number'
              label='Installation Charges'
              placeholder='Installation Charges'
              name='installationCharges'
              onChange={(e) => setInstallationCharges(Number(e.target.value))}
              value={installationCharges}
            />
            <TextField
              id='outlined-textarea'
              type='number'
              label='Tax'
              placeholder='Tax'
              name='tax'
              className='ml-4'
              value={Math.ceil((totalCost.total + installationCharges) * 0.18)}
            />
            <TextField
              id='outlined-textarea'
              type='number'
              label='Total'
              placeholder='Total'
              name='total'
              className='ml-4'
              value={Math.ceil((totalCost.total + installationCharges) * 1.18)}
            />
          </Box>
          <Button
            variant='contained'
            disabled={loading}
            onClick={() => {
              handleSubmit(selectedProducts, installationCharges);
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default EditInvoice;
