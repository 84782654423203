import React from 'react'

export default function LastGrivanceElectrician({data}) {
  console.log(data);
    const findLastGrivanceElectrician =()=>{
        let length = data.AssignedElectritianList.length
        for(let i=length-1;i>=0;i--){
            if(data.AssignedElectritianList[i].siteType==="Grivience"){
                return data.AssignedElectritianList[i].electricianName
            }
        }
        return ''
    }
  return (
    <span>
     {findLastGrivanceElectrician()}
    </span>
  )
}
