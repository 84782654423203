import axios from "axios"
import { serverUrl } from "../ApiUrl"
import { notify, notifyFailed } from "../Common/Notification"

export const lastLocationLogElectrician = async()=>{
   return await axios.get(`${serverUrl.u}/api/electricians/latestLog`).then((res)=>{
        const {message,success,data} = res.data
       if(success){
           notify(message)
           return data
       }else{
        notifyFailed(message)
        return []
       }
    }).catch(err=>{
        let msg = err?.response?.data?.message ? err?.response?.data?.message :  err?.message
        notifyFailed(msg)
        return []
    })
}
export const locationOfClientSites = async()=>{
    return await axios.post(`${serverUrl.u}/api/tele/getAllNotInstalledForMarker`,{date:new Date()}).then((res)=>{
         const {message,success,data} = res.data
        if(success){
            notify(message)
            return data
        }else{
         notifyFailed(message)
         return []
        }
     }).catch(err=>{
         let msg = err?.response?.data?.message ? err?.response?.data?.message :  err?.message
         notifyFailed(msg)
         return []
     })
 }

 export const kmReportDaily = async(data)=>{
    return await axios.post(`${serverUrl.u}/api/electricians/Km/report`,data).then((res)=>{
         const {message,success,data} = res.data
        if(success){
            notify(message)
            return data
        }else{
         notifyFailed(message)
         return []
        }
     }).catch(err=>{
         let msg = err?.response?.data?.message ? err?.response?.data?.message :  err?.message
         notifyFailed(msg)
         return []
     })
 }

 export const kmsReportTableAccess = [
    '1058'
 ]

 