import axios from 'axios'
import moment from 'moment'
import React from 'react'
import { useEffect,useState } from 'react'
import {serverUrl} from './ApiUrl'
import { WhatsApp } from "@mui/icons-material";
import { useSelector } from 'react-redux'
export default function CrmQuto() {
  const [searchParam] = useState(["name"]);
  let [details, setDetails] = useState([]);
  let [q, setq] = useState("");
  let [display,setDisplay] = useState(false)
  let [data,setData] = useState()
  let token = useSelector((state)=>state.sar.data.token)
  let teleCallers = useSelector((state)=>state.sar.data.user.first_name)
    useEffect(()=>{
        axios.get(`${serverUrl.u}/v3/quotation/list`).then(function (res) {
        console.log(res.data.data.quotations);
        setDetails(res.data.data.quotations)
       }).catch(function (error) {
        console.log(error);
       })
    },[])
   
    const downloadFile = (blob, fileName) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.append(link);
        link.click();
        link.remove();
      };
    const quotationApi = async (data) => {
      await axios.post(`${serverUrl.s}/misc/getQuotation`,
        {
            quoteTo: data.quoteTo.name,
            quoteNo: data.quoteNum,
            itemsQuantity: { appliances: data.appliances },
            packagePlanDetails: {
              packageName: data.plan,
              planName: data.package,
              security: data.security,
              installation: data.installation,
              fee: data.fee,
              total: data.total,
    
            },
            amounts: {
              subtotal: data.subtotal,
              tax: data.tax,
              total: data.total
            }
          }, {
          responseType: 'blob',
          headers: {
            AccessToken: token
          }
        }).then(function (response) {
          
          downloadFile(new Blob([response.data]), `Quote - ${data.quoteTo.name}.pdf`)
        }).catch(function (error) {
          console.log("quto   ", error);
        })
    
      }
    function search(items) {
        return items.filter((item) => {
          return searchParam.some((newItem) => {
            console.log(newItem);
            return (
               item.quoteTo[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            );
          });
        });
      }
      
  return (
    <div>
        
            <div className="input-field">
        <input
          type="text"
          value={q}
          placeholder="Search here...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
      <div className='table-container1'>
      <table className="table table-bordered table-sm ">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">City</th>
            <th  scope="col" style={{width:"130px"}}>Phone</th>
            <th style={{width:"200px"}}>Email</th>
            <th scope="col">Create Date</th>
            <th>Duration</th>
            <th>Plan Name</th>
            
            <th>Fee</th>
            <th>Installation</th>
            <th>Appliances</th>
            <th>Scurity</th>
           <th colSpan="3" style={{width:"200px"}}>Action</th>
            
          </tr>
        </thead>
        <tbody>
          {details !== 0
            ? search(details).map((e, i) => {
                return (
                  <tr key={i}>
                    <td label="Name" >{e.quoteTo.name}</td>
                    
                    <td label="City">{e.quoteTo.city}</td>
                    <td label="Phone"><a href={`tel:${e.quoteTo.phone}`}>{e.quoteTo.phone}</a></td>
                    <td label="Email">{e.quoteTo.email}</td>
                    <td label="Date" >{moment(e.createdAt).format("MMM Do")}</td>
                    <td>{e.plan}</td>
                    <td>{e.package}</td>
                    <td>{e.fee}</td>
                    <td>{e.installation}</td>
                    <td>{e.appliances}</td>
                    <td>{e.security}</td>
                 <td><button className='btn btn-primary' onClick={()=>{quotationApi(e)}}>Quto</button></td>
                 <td><a href={`http://wa.me/${e.quoteTo.phone}?text=Hey there, 
This is ${teleCallers} from Aliste Home Automation. 
As discussed, I am sharing our brochure, pricing and videos below. 
Would request you to go through them and let me know if you have any queries in regards to the same. 
For further details please feel free to reach out to me or visit alistetechnologies.com`} style={{backgroundColor:"#25D366",border:"none",borderRadius:"12px",padding:"5px"}} target="_blank"><WhatsApp sx={{color:"white",verticalAlign: "middle",fontSize: "xx-large",width: "41px",
paddingBottom: "4px",}}/></a></td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
      

    </div>
  )
}
