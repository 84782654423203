import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../ConvertedLead.css'
import '../DispatchData.css'
import '../PaymentLinkGen.css'
import Qutoes from '../Qutoes'
import { serverUrl } from '../ApiUrl'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GrivanceResolved from './GrivanceResolved'
import moment from 'moment'

export default function Grivance() {
  let [displayData,setDisplayData] = useState([])
  let [page,setPage] = useState(1)
  let [totalPage,setTotalPage] = useState()
  let [q, setq] = useState("");
  let [reload,setReload] = useState(false)
  const [searchParam] = useState(["phone"]);
  const notify = (text) => toast(text);
  
  useEffect(()=>{
     axios.get(`${serverUrl.u}/api/grv/getAllGrievance/?p=${page-1}`).then(
      function (res) {
        console.log(res);
        if(res.data.success===true){
            setTotalPage(res.data.data.totalPage)
            setDisplayData(res.data.data.allGrievance)
        }
      }
  ).catch(function (err) {
    console.log(err);
  })
  },[page,reload])

 
  

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }

  return (
    <div className='container-fluid'>

      <div className='input-field mb-3 mt-4'>
        <input type="text" value={q} placeholder="Search here phone number...." onChange={(e) => setq(e.target.value)} /></div>
        <nav aria-label="..." className='pagination-container'>
      <ul class="pagination">
        {
          [...Array(totalPage)].map((e,i)=>{
              return(
                
                <li className={page===i+1?"page-item active":"page-item"} onClick={()=>setPage(i+1)}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
</nav>
    
      <div className='table-container1' style={{marginTop:0}}>
      
        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th>Client Name</th>
              <th>Phone</th>
              <th>Created Date</th>
              <th>Resolved By</th>
              <th>Grievance Type</th>
              <th>Grievance Description</th>
              <th>Resolved Status</th>
              <th>General Grievance</th>
            </tr>
          </thead>
          <tbody>
            {
              displayData !== 0 ?
                search(displayData).map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.name}</td>
                      <td>{e.phone}</td>
                      <td>{moment(e.createdAt).format("DD MMM YYYY")}</td>
                      <td>{e.resolvedBy}</td>
                      <td>{e.grievanceType}</td>
                      <td>{e.grievanceDescription}</td>
                      <td>{e.isResolved===true?"Resolved":<GrivanceResolved grivance={e} notify={notify} reload={reload} setReload={setReload}/>}</td>
                      <td>{e.generalGrievance===true?"Yes":"No"}</td>
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
<ToastContainer/>
     
      
     
      <Qutoes />
    </div>
  )
}
