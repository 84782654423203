import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import UninstallDoneBtn from './UninstallDoneBtn';
import { useSelector } from 'react-redux';
import { electicianLocationLog, typeOfLocaionLogs } from './api';
import { notifyFailed } from '../Common/Notification';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:"12px"
};

export default function ConfirmatinModel({data,notify,setReload,reload}) {
  const [open, setOpen] = React.useState(false);
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)
  const handleOpen = async() => {
    let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.leave,data)
    if(!res.success){
      notifyFailed("First Click on Day Start Button")
      return
    }
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button variant='contained' sx={{width:"100%"}} onClick={handleOpen}>Uninstall Done</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you completely Uninstall appliance on {data.name} site?
          </Typography>
          <Typography id="modal-modal-footer" sx={{ mt: 2 }}>
              <Button variant='contained' sx={{mr:2}} onClick={handleClose}>No</Button>
              <UninstallDoneBtn data={data} notify={notify} setReload={setReload} reload={reload}/>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}