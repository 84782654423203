import React from 'react'
import jsPDF from 'jspdf'
import './InvoicePdf.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { ToWords } from 'to-words'
import moment from 'moment';
export default function InvoicePdf() {
  const location = useLocation()
  const navigate = useNavigate();
  let [path, setPath] = useState();
  let [gst,setGst] = useState("")
  let [layout, setLayout] = useState();
  useEffect(() => {
    const { from, path } = location.state;
    setLayout(from)
    console.log(from);
    setPath(path)
  }, [])
  
  const date = new Date(layout?.InstalledDate);
 
  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: { // can be used to override defaults for the selected locale
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: '',
        },
      }
    }
  });
  
  if(layout?.planFrequency==="Monthly"){
  date.setDate(date.getDate() + 30)
  }else if(layout?.planFrequency==="Quarterly"){
    date.setDate(date.getDate() + 100)
  }else if(layout?.planFrequency==="Semi Annualy"){
    date.setDate(date.getDate() + 210)
  }else if(layout?.planFrequency==="Annualy"){
    date.setDate(date.getDate() + 455)
  }else if(layout?.planFrequency==="Test (Weekly)"){
    date.setDate(date.getDate() + 7)
  }
  // const state = [{ value: "Delhi" }, { value: "Noida" }, { value: "Gurgaon" }, { value: "Faridabad" }, { value: "Greater Noida" }, { value: "Ghaziabad" }];
  let taxType;
if(layout?.city==="Delhi" || layout?.city==="Gurgaon" || layout?.city==="Faridabad"){
  taxType="IGST"
}else {
  taxType="CGST"
}
let [clientName,setClientName] =useState(layout?.name)
  const generatePdf = () => {
    var doc = new jsPDF("p", "pt", "a4");
    
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        console.log("call");
        pdf.save("doc.pdf");
      }
    })
  }

  useEffect(() => {
    console.log(layout);
    setClientName(layout?.name)
  }, [layout])
  return (
    <>
      <button className='btn btn-primary' onClick={generatePdf}>Generate Pdf</button>
      <button className='btn btn-primary' style={{ marginLeft: "5%" }} onClick={() => navigate(`/${path}`)}>Back</button>
      <div style={{marginTop: "15px"}}>
      <label>Gst Number</label>
      <input type="text"  value={gst} onChange={(e)=>setGst(e.target.value)}/>
      </div>
      <div style={{marginTop: "15px"}}>
      <label>Clients Name</label>
      <input type="text"   value={clientName} onChange={(e)=>setClientName(e.target.value)}/>
      </div>
      <div id="content" style={{ border: "1px solid black", width: "535px",height:"772px", margin: "30px" }}>
        <div className='Invoice-top-header'>
          <div className='Invoice-top-header-left'>
            <img src={process.env.PUBLIC_URL + "/assets/icon/logoWithName.png"} alt='logo'/>
          
          </div>
          <div>
            <div className='Invoice-top-header-right'>
              <div className='Invoice-top-header-right-name'>ALISTE &nbsp; TECHNOLOGIES  &nbsp; &nbsp; PRIVATE &nbsp; &nbsp;  LIMITED<br /></div>
              <div style={{ fontSize: "9px" }}>B-56,Sector-64, Noida,Gautam Buddha Nagar,Uttar Pradesh, 201301<br />India<br />GSTIN 09AAVCA8533A1ZH</div>
            </div>
          </div>
          <div style={{
            position: "absolute",
            right: "3px",
            bottom: "3px",
            fontSize: "21px"
          }}>TAX &nbsp;&nbsp;INVOICE</div>
        </div>
        <div className='Invoice-number-detail'>
          <div className='Invoice-number-detail-left'>
            <table>

              <tr>
                <td>Invoice Number</td>
                <th>:{layout?.invoiceNumber}</th>
              </tr>
              <tr>
                <td>Invoice Date</td>
                <th>:{moment(layout?.InstalledDate).format('L')}</th>
              </tr>
              
              
            </table>
          </div>
          <div className='Invoice-number-detail-right'>
            <table>

              
              <tr>
                <td>Reference #</td>
                <th>:{layout?.generatedHex}</th>
              </tr>
            </table>
          </div>
        </div>
        <div className='Invoice-number-BILL-SHIP'>
          <div className='Invoice-number-BILL'>Bill To</div>
          <div className="Invoice-number-SHIP">Ship To</div>
        </div>
        <div className='Invoice-number-BILL-SHIP-cus'>
          <div className='Invoice-number-BILL-to'>
            <div>{clientName}</div>
            <div>{layout?.address}</div>
            <div>{gst}</div>
            <div>India</div>
          </div>
          <div className="Invoice-number-SHIP-to">
            <div>{layout?.address}</div>
            <div>India</div>
          </div>
        </div>
        <div className='Invoice-payment-Detail'>
          <table>
            <tr>
              <th rowSpan="2" style={{textAlign:"center"}}>#</th>
              <th rowSpan="2" style={{textAlign:"left"}}>Item & Description</th>
              <th rowSpan="2" style={{textAlign:"left"}}>HSN/SAC</th>
              <th rowSpan="2">Qty</th>
              <th rowSpan="2">Rate</th>
              <th colSpan="2" style={{borderBottom:"1px solid black"}}>{taxType}</th>
              <th rowSpan="2">Amount</th>
            </tr>
            <tr >
              <th>%</th>
              <th>Amt </th>
            </tr>
            <tr>
              <td className='Invoice-detail-cell' style={{textAlign:"center"}}>1</td>
              <td className='Invoice-detail-cell' style={{textAlign:"left"}}>Automation-{layout?.planFrequency}<span style={{fontWeight:"lighter"}}><br/>Charges for this duration (from {moment(layout?.InstalledDate).format('L')} to {moment(date).format('L')} )</span></td>
              <td className='Invoice-detail-cell' style={{textAlign:"left"}}>85365090</td>
              <td className='Invoice-detail-cell'>{layout?.totalAppliances} pcs</td>
              <td className='Invoice-detail-cell'>{layout?.finalCalculatedRecursiveAmount}</td>
              <td className='Invoice-detail-cell'>18%</td>
              <td className='Invoice-detail-cell'>{(layout?.finalCalculatedRecursiveAmount*0.18).toFixed(2)}</td>
              <td className='Invoice-detail-cell'>{Number(layout?.finalCalculatedRecursiveAmount*1.18).toFixed(2)}</td>
            </tr>
            <tr>
              <td className='Invoice-detail-cell' style={{textAlign:"center"}}>2</td>
              <td className='Invoice-detail-cell' style={{textAlign:"left"}}>Installation</td>
              <td className='Invoice-detail-cell' style={{textAlign:"left"}}></td>
              <td className='Invoice-detail-cell'>1 pcs</td>
              <td className='Invoice-detail-cell'>{layout?.finalCalculatedInstallation}</td>
              <td className='Invoice-detail-cell'>18%</td>
              <td className='Invoice-detail-cell'>{(layout?.finalCalculatedInstallation*0.18).toFixed(2)}</td>
              <td className='Invoice-detail-cell'>{Number(layout?.finalCalculatedInstallation*1.18).toFixed(2)}</td>
            </tr>
            <tr>
              <td className='Invoice-detail-cell' style={{textAlign:"center"}}>3</td>
              <td className='Invoice-detail-cell' style={{textAlign:"left"}}>Security</td>
              <td className='Invoice-detail-cell' style={{textAlign:"left"}}></td>
              <td className='Invoice-detail-cell'>{layout?.totalAppliances} pcs</td>
              <td className='Invoice-detail-cell'>{layout?.finalCalculatedSecurity}</td>
              <td className='Invoice-detail-cell'></td>
              <td className='Invoice-detail-cell'></td>
              <td className='Invoice-detail-cell'>{layout?.finalCalculatedSecurity.toFixed(2)}</td>
            </tr>
          </table>
        </div>
        <div className='Invoice-payment-detail-total'>
          <div className='Invoice-payment-detail-total-left'>
            <div style={{fontWeight:"lighter",marginTop:"8px"}}>Total In Words</div>
            {layout!==undefined?
         <div>{toWords.convert(layout?.orignalTotalAmount.toFixed(2), { currency: true })}</div> :null
            }
          <div style={{fontWeight:"lighter",marginTop:"8px"}}>Thanks for your business</div>
          </div>
          <div className='Invoice-payment-detail-total-right'>
            <table>
                <tr>
                  <td>Sub Total</td>
                  <td>{(layout?.finalCalculatedRecursiveAmount+layout?.finalCalculatedSecurity+layout?.finalCalculatedInstallation).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>IGST18(18%)</td>
                  <td>{((layout?.finalCalculatedRecursiveAmount+layout?.finalCalculatedInstallation)*0.18).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Rs.{layout?.orignalTotalAmount.toFixed(2)}</th>
                </tr>
                {/* <tr>
                  <td>Payment Made</td>
                  <td style={{color:"red"}}>(-) {layout?.orignalTotalAmount.toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Balance Due</th>
                  <th>Rs. {(layout?.orignalTotalAmount-layout?.orignalTotalAmount).toFixed(2)}</th>
                </tr> */}
            </table>
          </div>

        </div>
        <div className='Invoice-payment-detail-total'>
          <div className='Invoice-payment-detail-total-left-signatur'>
           
             <div style={{marginTop:"12px"}}>Term & Conditions :-</div>
             <pre>Visit:- www.alistetechnologies.com/subscriptionterms</pre>
            
            
          </div>
          <div className='Invoice-payment-detail-total-right-signatur'>
            <img src={process.env.PUBLIC_URL+"/assets/icon/signature.png"} style={{width: "82px",
    position: "absolute",
    left: "394px",
    marginTop:"2px"}} alt='sign'/>
            <div className='Invoice-payment-detail-singnature'>Authorized Signature</div>
          </div>

        </div>
      </div>
    </>
  )
}
