import axios from "axios"
import { serverUrl } from "../ApiUrl"

export const saveData = (data) => {
 
    return {
             type:"save",
             payload:data,
         }
 }
 export const saveSwitchBoardData = (data) => {
 
    return {
             type:"saveSwitch",
             payload:data,
         }
 }

 export const switchUpdate = (data) => {
 
    return {
             type:"switchUpdate",
             payload:data,
         }
 }
 

 // new 

 export const defaultData = (data) => {
    return {
        type:"defaultLayout",
        payload:data
    }
 } 

 export const switchBoardEmpty = () => {
 
    return {
             type:"switchBoardEmpty",
         }
 }
 export const switchBoardAddNew = (data) =>{
    return {
        type:"addNewRoom",
        payload:data
    }
 }
 export const addSwitchBoardInRoom = (data) =>{
    return {
        type: "addNewSwitchinRoom",
        payload:data
    }
 }

 export const deleteRoom = (data) =>{
    return {
        type:"deleteRoom",
        payload:data
    }
 }
 const refundRecordAction = (refundRecord,totalPage) =>{
    return {
        type:"refundRecord",
        payload:{
            refundRecord,
            totalPage
        }
    }
 }

 const refundPendingRecordAction = (refundPandingRecord,totalPage) =>{
    return {
        type:"refundPendingRecord",
        payload:{
            refundPandingRecord,
            totalPage
        }
    }
 }
 const refundCompleteRecordAction = (refundCompleteRecord,totalPage) =>{
    return {
        type:"refundCompleteRecord",
        payload:{
            refundCompleteRecord,
            totalPage
        }
    }
 }

 export const refundRecords = (page,notify,dispatch) =>{
    
       axios.get(`${serverUrl.u}/app/fnd/getFuncRecord/?p=${page-1}`).then(
        function (res) {
            console.log(res);
            const {message,refundRecord,status,totalPage} = res.data;
            if(status===true){
            dispatch(refundRecordAction(refundRecord,totalPage))
            notify(message)
           }else{
            notify(message)
           }
        }
       ).catch(function (err) {
         console.log(err);
         notify("Error Ocurred")
       })
 }

 export const refundPendingRecords = (page,notify,dispatch) =>{
    
    axios.get(`${serverUrl.u}/app/fnd/getPendingRefund/?p=${page-1}`).then(
     function (res) {
         console.log(res.data);
         const {message,data,success} = res.data;
         if(success===true){
            console.log(data.refundRecord,"if");
         dispatch(refundPendingRecordAction(data.refundRecord,data.totalPage))
         notify(message)
        }else{
         notify(message)
        }
     }
    ).catch(function (err) {
      console.log(err);
      notify("Error Ocurred")
    })
}
export const refundCompleteRecords = (page,notify,dispatch) =>{
    
    axios.post(`${serverUrl.u}/app/fnd/getCompleteRefund/?p=${page-1}`).then(
     function (res) {
         console.log(res.data);
         const {message,data,success} = res.data;
         if(success===true){
            console.log(data,"if");
         dispatch(refundCompleteRecordAction(data.refundRecord,data.totalPage))
         notify(message)
        }else{
         notify(message)
        }
     }
    ).catch(function (err) {
      console.log(err);
      notify("Error Ocurred")
    })
}

// installation api
export const installationNotCompleteData = async(dispatch)=>{
    await axios.post(
          `${serverUrl.u}/api/tele/getAllNotInstalled`
        )
        .then(async function (response) {
          let detail = response.data.data;
          dispatch(installationPendingAction(detail))
        }).catch(function (err) {
            console.log(err);
        })
}

// installationData Action
const installationPendingAction = (data) =>{
    return {
        type:"intallationPending",
        payload:data
    }
 }