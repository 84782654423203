import { handleBreakpoints } from '@mui/system'
import React, { useEffect, useState } from 'react'
import './Estimate.css'
import { planCost } from './Data'
export default function Estimate() {
    let [appliances,setAppliances] = useState(0)
    let [havvyAppliance,setHeavyAppliance] = useState(0)
    let [installations,setInstallation] = useState(1000)
    let [securitys,setSecqurity] = useState(appliances*100)
   
    const handleAppliances = (appli)=>{
      console.log(appli,"app");
      let t= appli+havvyAppliance
      setAppliances(appli)
      setSecqurity(t*100)
      if(t<=10 && this>=1){
         setInstallation(1000)
        }else if(t>10){
         let app = t-10;
           setInstallation(1000+(app*50))
        }else{
         return;
        }
    }
    const handleHavvyAppliances = (appli)=>{
      let t= appli+appliances
      setHeavyAppliance(appli)
      setSecqurity(t*100)
      if(t<=10 && t>=1){
         setInstallation(1000)
        }else if(t>10){
         let app = t-10;
           setInstallation(1000+(app*50))
        }else{
         return;
        }
    }


  return (
    <div>
        <div className='estimate-topHeading'>
        
            <div class="input-group input-appliances">
            <div className='estimate-heading'>Enter Number of Light appliances</div>

              <input type="number" defaultValue={appliances} className="form-control" id="appliances" name="appliances" autoComplete="current-appliances" placeholder="Appliances" aria-label="appliances" aria-describedby="basic-addon1" onChange={(e)=>handleAppliances(Number(e.target.value))}  onWheel={ event => event.currentTarget.blur() }/>
            </div>
            
            <div class="input-group input-appliances">
            <div className='estimate-heading'>Enter Number of Heavy appliances</div>

              <input type="number" defaultValue={havvyAppliance} className="form-control" id="appliances" name="appliances" autoComplete="current-appliances" placeholder="Appliances" aria-label="appliances" aria-describedby="basic-addon1" onChange={(e)=>handleHavvyAppliances(Number(e.target.value))}  onWheel={ event => event.currentTarget.blur() }/>
            </div>
        <div class="input-group input-appliances">
        <div className='estimate-heading'>Enter Installation</div>
          <input type="number" value={installations} class="form-control" id="installation" name="installation" autoComplete="current-installation" placeholder="Installation" aria-label="appliances" aria-describedby="basic-addon1" onChange={(e)=>setInstallation(Number(e.target.value))}  onWheel={ event => event.currentTarget.blur() }/>
        </div>
        <div class="input-group input-appliances">
        <div className='estimate-heading'>Enter Security</div>
          <input type="number" value={securitys} class="form-control" id="installation" name="installation" autoComplete="current-security" placeholder="security" aria-label="security" aria-describedby="basic-addon1" onChange={(e)=>setSecqurity(Number(e.target.value))}  onWheel={ event => event.currentTarget.blur() }/>
        </div>
            </div>
            
         <div className='estimate-sec-ins'>
            <div className='containerEstimate'>
               <div>Security</div>
               <div className='price-container'>{securitys}</div>
            </div>
            <div className='containerEstimate'>
               <div>Installation</div>
               <div className='price-container'>{installations}</div>
            </div>
         </div>
         <div className='estimate-sec-ins'>
            <div className='containerEstimate'>
               <div>Fees Monthly WithOut Security&Tax</div>
               <div className='price-container '>{(((havvyAppliance*2)+appliances)*30).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Fees Quarterly WithOut Security&Tax</div>
               <div className='price-container'>{(((havvyAppliance*2)+appliances)*planCost.Quarterly*90).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Fees Semi Annualy WithOut Security&Tax</div>
               <div className='price-container'>{(((havvyAppliance*2)+appliances)*planCost.SemiAnnualy*180).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Fees Annualy WithOut Security&Tax</div>
               <div className='price-container'>{(((havvyAppliance*2)+appliances)*planCost.Annualy*365).toFixed(2)}</div>
            </div>
          
            <div className='containerEstimate'>
               <div>Fees 3 Years WithOut Security&Tax</div>
               <div className='price-container'>{(((havvyAppliance*2)+appliances)*planCost.Annualy*(365*3)).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Fees 5 Years WithOut Security&Tax</div>
               <div className='price-container'>{(((havvyAppliance*2)+appliances)*planCost.Annualy*(365*5)).toFixed(2)}</div>
            </div>
         </div>
         <div className='estimate-sec-ins'>
            <div className='containerEstimate'>
               <div>1st Time Tax Monthly</div>
               <div className='price-container '>{(((((havvyAppliance*2)+appliances)*30)+installations)*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>1st Time Tax Quarterly</div>
               <div className='price-container'>{(((((havvyAppliance*2+appliances))*planCost.Quarterly*90)+installations)*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>1st Time Tax Semi Annualy</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.SemiAnnualy*180)+installations)*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>1st Time Tax Annualy</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.Annualy*365)+installations)*0.18).toFixed(2)}</div>
            </div>
          
            <div className='containerEstimate'>
               <div>1st Time Tax 3 Years</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.Annualy*365*3)+installations)*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>1st Time Tax 5 Years</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.Annualy*365*5)+installations)*0.18).toFixed(2)}</div>
            </div>
         </div>
         <div className='estimate-sec-ins'>
            <div className='containerEstimate'>
               <div>Tax Monthly</div>
               <div className='price-container '>{(((((havvyAppliance*2)+appliances)*30))*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Tax Quarterly</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.Quarterly*90))*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Tax Semi Annualy</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.SemiAnnualy*180))*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Tax Annualy</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.Annualy*365))*0.18).toFixed(2)}</div>
            </div>
          
            <div className='containerEstimate'>
               <div>Tax 3 Years</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.Annualy*365*3))*0.18).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Tax 5 Years</div>
               <div className='price-container'>{(((((havvyAppliance*2)+appliances)*planCost.Annualy*365*5))*0.18).toFixed(2)}</div>
            </div>
         </div>
         <div className='estimate-sec-ins'>
            <div className='containerEstimate'>
               <div>Total Monthly</div>
               <div className='price-container'>{((((((havvyAppliance*2)+appliances)*30)+installations)*1.18)+(securitys)).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div >Total Quarterly</div>
               <div className='price-container'>{((((((havvyAppliance*2)+appliances)*planCost.Quarterly*90)+installations)*1.18)+(securitys)).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Total Semi Annualy</div>
               <div className='price-container'>{((((((havvyAppliance*2)+appliances)*planCost.SemiAnnualy*180)+installations)*1.18)+(securitys)).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Total Annualy</div>
               <div className='price-container'>{((((((havvyAppliance*2)+appliances)*planCost.Annualy*365)+installations)*1.18)+(securitys)).toFixed(2)}</div>
            </div>
           
            <div className='containerEstimate'>
               <div>Total 3 Years</div>
               <div className='price-container'>{((((((havvyAppliance*2)+appliances)*planCost.Annualy*365*3)+installations)*1.18)+(securitys)).toFixed(2)}</div>
            </div>
            <div className='containerEstimate'>
               <div>Total 5 Years</div>
               <div className='price-container'>{((((((havvyAppliance*2)+appliances)*planCost['Five Years']*365*5)+installations)*1.18)+(securitys)).toFixed(2)}</div>
            </div>
         </div>
         <div style={{height:"100px"}}></div>
    </div>
  )
}
