import React, { useState } from 'react'
import { getHisab } from './Api'
import { notify, notifyFailed } from '../Common/Notification'
import { Stack, Divider, Popover, Button } from '@mui/material'
import { useEffect } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'

function HisabForZohoRazorPay({ detail, sub }) {
  let [hisab, setHisab] = useState({})
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleHisab = async () => {
    // let res = await getHisab(detail.qu, Number(detail.installion), true, sub, detail.planType)
    let oldApp=0
    if (sub.changesInSubscription.length > 0  && sub.changesInSubscription[sub.changesInSubscription.length-1].changeInSubscription!=="reactivate") {
      oldApp = sub.changesInSubscription[sub.changesInSubscription.length - 1].oldAppliances
    }
    console.log(detail,"fff");
     await axios.post(`${serverUrl.u}/api/tele/calculateClientAmount`, {
      "generatedHex": sub.generatedHex,
      "totalAppliances": detail.qu-oldApp,
      "oldDrop": true,
      "installation": Number(detail.installion),
      "newPlanFrequency": detail.planType,
      "collectSecurity": detail.security>0?true:false,
      "orderType":sub.orderType,
      perApplianceCost:detail.coastperSecurityApplinaces!==undefined?detail.coastperSecurityApplinaces:100
  }).then(async function (res) {
      if (res.data.success === true) {
          notify("Details Fetch")
          setHisab(res.data.data)
      } else {
          notifyFailed(res.data.message)
           {}
      }
  }).catch(function (err) {
      notifyFailed("Error Occur")
       {}
  })

  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    if (Object.keys(detail).length === 0) return
    handleHisab()
  }, [detail])
  return (
    <>

      {
        Object.keys(hisab).length !== 0 && (
          <Stack direction={"column"} spacing={2} mt={3}>
            <Stack direction={'row'} spacing={2}>
              <div>Particulars</div>
              <div>Appliances</div>
              <div>Rate</div>
              <div>Total</div>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <div>New Recursive Amount</div>
              <div>{detail.qu}</div>
              <div>{Number(hisab.currentRecursive) / Number(detail.qu)}/{detail.planType}</div>
              <div>₹ {hisab.currentRecursive}</div>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <div>New Security Amount</div>
              <div>{detail.qu}</div>
              <div>{Number(hisab.currentSecurity)}</div>
              <div>₹ {Number(hisab.currentSecurity)}</div>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <div>New Installation Amount</div>
              <div>{detail.qu}</div>
              <div>{Number(hisab.currentInstallation)}</div>
              <div>₹ {Number(hisab.currentInstallation)}</div>
            </Stack>
            <Divider></Divider>
            <Stack direction={'row'} spacing={2}>
              <div>Sub Total(A)</div>
              <div></div>
              <div></div>
              <div>₹ {Number(hisab.currentTotal).toFixed(2)}</div>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <div>Overdue (B)</div>
              <div></div>
              <div></div>
              <div>+₹ {Number(hisab.overdueAmount).toFixed(2)}</div>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <div>Credit Note (C)  <Button aria-describedby={id} onClick={handleClick}>info</Button></div>
              <div></div>
              <div></div>
              <div>-₹ { Number(hisab.creditNoteActualAmount).toFixed(2) }</div>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <div>Advance Amount (D) :-</div>
              <div></div>
              <div></div>
              <div>-₹ {Number(hisab.partialAmount)}</div>
            </Stack>
            <Divider></Divider>
            <Stack direction={'row'} spacing={2}>
              <div>Total (A+B-C-D)</div>
              <div></div>
              <div></div>
              <div>₹ {Number(hisab.paymentToTake).toFixed(2)}</div>
            </Stack>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}

            >
               {
                                hisab.creditNoteDetails.length !== 0 && (
                                    hisab.creditNoteDetails.map((e, i) => {
                                        return (
                                            <Stack direction={"column"} spacing={4} sx={{
                                                padding: "10px"
                                            }}>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Credit Note {i + 1}</item>
                                                </Stack>
                                               
                                                <Stack direction={'row'} spacing={2}>
                                                    <item>Plan Duration</item>
                                                    <item>{e.planDuration}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Un-Used Days</item>
                                                    <item>{e.unusedDays}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={2}>
                                                    <item>Used Days</item>
                                                    <item>{e.usedDays}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Paid Amount</item>
                                                    <item>{e.paidAmount}</item>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <item>Used Amount</item>
                                                    <item>{e.amountUtilized.toFixed(2)}</item>
                                                </Stack>
                                               
                                               
                                                <Stack direction={'row'} spacing={2}>
                                                    <item>Balance Amount</item>
                                                    <item>{e.creditAmount}</item>
                                                </Stack>
                                            </Stack>
                                        )
                                    })

                                )
                            }
            </Popover>
          </Stack>
        )
      }
    </>
  )
}

export default HisabForZohoRazorPay