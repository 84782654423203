import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../ApiUrl';

export default function PreviousFrequency({e}) {
    let [frequency,setFrequency] = useState('')
    useEffect(()=>{
        axios.post(`${serverUrl.u}/api/tele/getSubscription`,{
            generatedHex:e.generatedHex
        }).then(function (res) {
            console.log(res.data.data,"check2");
            setFrequency(res.data.data.planFrequency)
        }).catch(function (error) {
            console.log(error);
        })
    },[])
  return (
    <>{frequency}</>
  )
}
