import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { notify, notifyFailed } from '../Common/Notification'
import { Button, Grid, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import LeadCreate from './LeadCreate'
import { salesUserData } from '../Redux/UserData'
import { useDispatch } from 'react-redux'
import Multiselect from 'multiselect-react-dropdown'
import { user_roles } from '../Data'
import './AssignLead.css'
import { autoAssignOff, autoAssignOn, autoAssignStatus } from './Api'
import moment from 'moment'
import ChangeLeadType from './ChangeLeadType'
const firsttimes = () => {

    // Get the current date and time
    let now = new Date();

    // Format the date as "YYYY-MM-DD" (required by datetime-local input)
    let year = now.getFullYear();
    let month = (now.getMonth() + 1).toString().padStart(2, '0');
    let day = now.getDate().toString().padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;

    // Format the time as "HH:MM" (required by datetime-local input)
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let formattedTime = `${"19"}:${"00"}`;

    // Set the default value of the input field
    
    return `${formattedDate}T${formattedTime}`;

}
const TableRow = ({ e, i, handle, cityArray, setAutoAssignTelecallers, autoAssignTelecallers, activeAutoAssignCallers, sources }) => {
    const [telecallerData, setTelecallerData] = useState({ excludedCity: [], excludedSources: [] })
    const [excludedCity, setExcludedCity] = useState([])
    const [excludedSources, setExcludedSoures] = useState([])
    const [check, setChecked] = useState(false)
    let num = e.phoneNumbers[0].split(',')
    //console.log(sources, "table row");
    useEffect(() => {
        if (activeAutoAssignCallers.length === 0) return
        let tele = []
        //console.log(num, "num");
        tele = activeAutoAssignCallers.filter(t => t.number === num[0])
        //console.log(tele, "telc");
        //console.log(tele, num, "excludedCity");
        if (tele.length !== 0) {
            for (let t of tele) {
                setTelecallerData(t)
                setChecked(true)
                if(t.excludedCity!==undefined){
                if (t.excludedCity.length === 0) {
                    setExcludedCity([])
                } else {
                    let d = []
                    console.log(cityArray,"array");
                    for (let c of cityArray) {
                        console.log(c,"city in row");
                        if (t.excludedCity.includes(c.value)) {
                            d.push(c)
                        }
                    }
                    console.log(d, "excludedCity")
                    setExcludedCity(d)
                }}

                // excluded soures excludedSource
                if(t.excludedSources!==undefined){
                if (t.excludedSources.length === 0) {
                    setExcludedSoures([])

                } else {
                    let excludeS = []
                    for (let s of sources) {
                        console.log(s,"eeed");
                        if (t.excludedSources.includes(s.value)) {
                            excludeS.push(s)
                        }
                    }
                    console.log(excludeS, "excludedSoures")
                    setExcludedSoures(excludeS)
                }}

            }
        } else {
            setChecked(false)
        }
    }, [activeAutoAssignCallers, sources, cityArray])

    const handleCheck = (event) => {
        setChecked(event.target.checked)
        if (event.target.checked) {
            setTelecallerData({
                ...telecallerData,
                "number": num[0],
                "name": e.first_name,
            })
            let filterYourSelf = []
            filterYourSelf = autoAssignTelecallers.filter(t => t.number !== num[0])

            setAutoAssignTelecallers([
                ...filterYourSelf,
                {
                    ...telecallerData,
                    "number": num[0],
                    "name": e.first_name,
                }
            ])
        } else {
            setTelecallerData({})
            let filter = autoAssignTelecallers.filter(t => t.name !== e.first_name)
            setAutoAssignTelecallers(filter)
        }
    }
    const handleCity = (event) => {
        let cities = []
        cities = event.reduce((p, c) => {
            console.log(c);
            return p = [...p, c.value]
        }, [])
        //console.log(cities, "fgggg");
        setTelecallerData({
            ...telecallerData,
            excludedCity: cities
        })
        let filterYourSelf = []
        filterYourSelf = autoAssignTelecallers.filter(t => t.number !== num[0])
        setAutoAssignTelecallers([
            ...filterYourSelf,
            {
                ...telecallerData,
                name: e.first_name,
                number: num[0],
                excludedCity: cities
            }
        ])
        setChecked(true)
    }
    const handleSoures = (event) => {
        let s = event.map(e => {
            return e.value
        })
        //console.log(s, "sssss");
        setTelecallerData({
            ...telecallerData,
            "number": num[0],
            "name": e.first_name,
            "excludedSources": s
        })
        let filterYourSelf = []
        filterYourSelf = autoAssignTelecallers.filter(t => t.number !== num[0])
        setAutoAssignTelecallers([
            ...filterYourSelf,
            {
                ...telecallerData,
                name: e.first_name,
                number: num[0],
                excludedSources: s
            }
        ])
        setChecked(true)

    }
    return (
        <tr key={i} >
            <td>
                <input
                    type="checkbox"
                    checked={check}
                    onClick={(event) => handleCheck(event)}
                />
            </td>
            <td>{e.first_name}</td>
            <td

            >{num[0]}</td>
            <td>
                <input
                    type='number'
                    style={{ width: "50px" }}
                    defaultValue={0}
                    onBlur={(val) => handle(val, e)}
                />
            </td>
            <td>
                <Multiselect
                    displayValue="label"
                    selectedValues={excludedCity}
                    onRemove={handleCity}
                    onSelect={handleCity}

                    options={cityArray}
                    style={{

                        multiselectContainer: {
                            marginTop: "3px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />
            </td>
            <td>
                <Multiselect
                    displayValue="label"
                    selectedValues={excludedSources}
                    onRemove={handleSoures}
                    onSelect={handleSoures}

                    options={sources}
                    style={{

                        multiselectContainer: {
                            marginTop: "3px",
                            backgroundColor: "#ffffff"
                        },
                    }}
                />
            </td>
        </tr>
    )
}
function AssignLeadV2() {
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    const [leadCount, setLeadCont] = useState(0)
    const [leads, setLeads] = useState([])
    const [leadTypes, setLeadTypes] = useState({})
    const [selectedLeadType, setSelectedLeadType] = useState({})
    const [cityes, setCityes] = useState([])
    const [unassignedLead, setUnassignedLead] = useState([])
    const [selectedCitys, setSelectedCity] = useState([])
    
    const [sources, setSources] = useState([])
    const [selectedSources,setSelectedSources] = useState([])
    const [advertisersType, setAdvertisersType] = useState([])
    const [selectedContent, setSelectedContent] = useState([])
    const [contents, setContents] = useState([])
    let [totalNumber,setTotalNumber] = useState({
        days:0,
        sources:0,
        contents:0,
        city:0
    })
    const [activeAutoAssignCallers, setActiveAutoAssignCallers] = useState([])
    const [allTeleCallers, setAllTeleCallers] = useState([])
    const [time, setTime] = useState(firsttimes())
    const [autoAssignTelecallers, setAutoAssignTelecallers] = useState([])
    const salesUser = useSelector(state => state.salesUser)
    let token = useSelector((state) => state.sar.data.token)
    let [totalDate, setTotalDate] = useState([])
    let [selectedDate, setSelectedDate] = useState([])
    let [btn, setBtn] = useState("Assign Lead")
    const [reload, setReload] = useState(false)
    const dispatch = useDispatch()
    //   new assignLeads state
    const [totalWithTypeLead, setTotalWithTypeLead] = useState({})

    const totalFilter = (totalLeads, type, dates, contentType, sourceType,cityType,d=" ") => {
        console.log(totalLeads, type, dates, contentType, sourceType,cityType, "'rrrr");
        let TypofLeades = {
            "D2C": []
        }
        for (let l of totalLeads) {

            if (l.leadType === undefined) {
                TypofLeades.D2C.push(l)
            } else if (TypofLeades[l.leadType] === undefined) {
                TypofLeades[l.leadType] = [l]
            } else {
                TypofLeades[l.leadType].push(l)
            }
        }
        console.log(TypofLeades,"dddd");
        setTotalWithTypeLead(TypofLeades)

        // Date
        let date = []
        let data = {}
        let selecdedDate = []
        let dateLength = 0
        for (let i of TypofLeades[type]) {
            if (dates.length===0) {
                if (!date.includes(moment(i.createdDay).format('DD-MMM'))) {
                    date.push(moment(i.createdDay).format('DD-MMM'))
                    if (data[moment(i.createdDay).format('DD-MMM')] === undefined) {
                        data[moment(i.createdDay).format('DD-MMM')] = []
                    }
                }
                data[moment(i.createdDay).format('DD-MMM')].push(i)
            } else {
                if (dates.includes(moment(i.createdDay).format('DD-MMM'))) {
                    if (!date.includes(moment(i.createdDay).format('DD-MMM'))) {
                        date.push(moment(i.createdDay).format('DD-MMM'))
                        if (data[moment(i.createdDay).format('DD-MMM')] === undefined) {
                            data[moment(i.createdDay).format('DD-MMM')] = []
                        }
                    }
                    data[moment(i.createdDay).format('DD-MMM')].push(i)
                }
            }

        }
        for (let d of date) {
            selecdedDate.push({
                value: d,
                length: data[d].length,
                label: `${d} ${data[d].length}`,
                originalDate: data[d][0].createdDay
            })
            dateLength +=data[d].length
        }
        //console.log(date, dates, data, "total");
        setSelectedDate(selecdedDate)
        /// sources
        let sourceData = {}
        for (let d of date) {
            for (let l of data[d]) {
                let obj = JSON.parse(l.first_source)
                if (sourceType.length===0) {
                    if (sourceData[obj.source] === undefined) {
                        sourceData[obj.source] = []
                    }
                    sourceData[obj.source].push(l)
                } else {
                    if (sourceType.includes(obj.source)) {
                        if (sourceData[obj.source] === undefined) {
                            sourceData[obj.source] = []
                        }
                        sourceData[obj.source].push(l)
                    }
                }
            }
        }
        let selectedSource = []
        let sourceLength = 0
        for(let s of Object.keys(sourceData)){
            selectedSource.push({
                value:s ,
                label:`${s} ${sourceData[s].length}`,
                number:sourceData[s].length,
            })
            sourceLength +=sourceData[s].length
        }
        setSelectedSources(selectedSource)
        //content 
        let contentData={}
        //console.log(sourceData,"source");
        for (let s of Object.keys(sourceData)){
            //console.log(s,"ffff");
            for(let i of sourceData[s]){
            
                let obj = JSON.parse(i.first_source)
                if(contentType.length===0){
                  if(obj.content===undefined || obj.content===""){
                    if(contentData['Empty_Content']===undefined){
                    contentData['Empty_Content'] = []
                    }
                    contentData['Empty_Content'].push(i)
                  }else{
                  if(contentData[obj.content]===undefined){
                    contentData[obj.content]=[]
                  }
                  contentData[obj.content].push(i)
                }
                }else{
                    if(contentType.includes(obj.content)){ 
                        if(obj.content===undefined || obj.content===""){
                            if(contentData['Empty_Content']===undefined){
                            contentData['Empty_Content'] = []
                            }
                            contentData['Empty_Content'].push(i)
                          }     
                        if(contentData[obj.content]===undefined){
                            contentData[obj.content]=[]
                          }
                          contentData[obj.content].push(i)
                    }else if(contentType.includes('Empty_Content')){
                        if(obj.content===undefined || obj.content===""){
                            if(contentData['Empty_Content']===undefined){
                            contentData['Empty_Content'] = []
                            }
                            contentData['Empty_Content'].push(i)
                          }  
                    }
                    
                }
            }
        }
        //console.log(contentData,"contentData");
        let selectedContent = []
        let contentLength = 0
        for(let c of Object.keys(contentData)){
            selectedContent.push({
                value:c ,
                label:`${c} ${contentData[c].length}`,
                number:contentData[c].length,
            })
            contentLength +=contentData[c].length
        }
        setSelectedContent(selectedContent)
        // city 
        console.log(contentData,"content Data");
        let citiesData = {
        
        }
        for (let s of Object.keys(contentData)){
            for(let i of contentData[s]){
               if(cityType.length===0){
                if(i.city===undefined || i.city===""){
                    if(citiesData["Empty_City"]===undefined){
                        citiesData["Empty_City"]=[] 
                    }
                    citiesData["Empty_City"].push(i)
                }else{
                    if(citiesData[i.city]===undefined){
                        citiesData[i.city]=[]
                    }
                    citiesData[i.city].push(i)
                }
               }else{
               
                if(cityType.includes(i.city) ){
                    if(i.city===undefined || i.city===""){
                        if(citiesData["Empty_City"]===undefined){
                            citiesData["Empty_City"]=[] 
                        }
                        citiesData["Empty_City"].push(i)
                    }
                        if(citiesData[i.city]===undefined){
                            citiesData[i.city]=[]
                        }
                        citiesData[i.city].push(i)
                    
                }else if(cityType.includes('Empty_City')){
                    if(i.city===undefined || i.city===""){
                        if(citiesData["Empty_City"]===undefined){
                            citiesData["Empty_City"]=[] 
                        }
                        citiesData["Empty_City"].push(i)
                    }
                }
               }
            }
        }
        let selectedCity = []
        let cityLength = 0
        for(let c of Object.keys(citiesData)){
            selectedCity.push({
                value:c ,
                label:`${c} ${citiesData[c].length}`,
                number:citiesData[c].length,
            })
            cityLength +=citiesData[c].length
        }
        setSelectedCity(selectedCity)
        setTotalNumber({
            days:dateLength,
            sources:sourceLength,
            contents:contentLength,
            city:cityLength
        })
        if (dates.length===0) {
         
            setTotalDate(selecdedDate)
            setSources(selectedSource)
            setContents(selectedContent)
            setCityes(selectedCity)
        }
        if(d==='main'){
            setSelectedLeadType({
                type: "D2C", total: Number(TypofLeades.D2C.length)
            })
        }
    }
    const getAllUnassignLead = async () => {
        await axios.get(`${serverUrl.amazon}/leads/unassigned`).then(
            function (res) {
                if (res.data.success === true) {

                    setUnassignedLead(res.data.data.leads)
                    // let soures = []
                    // let uniqueSoures = []
                    // for (let i of res.data.data.leads) {
                    //     let obj = JSON.parse(i.first_source)
                    //     //console.log(obj.source);
                    //     // soures.push({value:obj.source})
                    //     if (!uniqueSoures.includes(obj.source)) {
                    //         uniqueSoures.push(obj.source)

                    //     }
                    // }
                    // for (let s of uniqueSoures) {
                    //     soures.push({ "value": s })
                    // }
                    // //console.log(soures, "sources");
                    // setSources(soures)
                    setLeadCont(res.data.data.count)
                    let content = []
                    let source = []
                    let date = []
                    let city  = []
                    totalFilter(res.data.data.leads, "D2C", date, content, source,city,'main')
                   

                } else {
                    notify("There is no lead")
                }
            }
        ).catch(function (err) {
            notifyFailed("Failed")

        })
    }

    const handle = (count, tele) => {
        let number = tele.phoneNumbers[0]

        if (number.includes("+")) {
            number = number.substring(3)

        }
        number = number.substring(0, 10)

        let filter = leads.filter((e) => e.number !== number)
        if (Number(count.target.value) === 0) {
            setLeads(filter)
            return
        }


        let obj = {
            "number": number,
            "count": Number(count.target.value)
        }
        setLeads([...filter, obj])
    }

    const assignLeads = () => {
        if (selectedLeadType.type === "default") {
            notifyFailed("Plese Select Lead Type")
            setBtn("Assign Lead")
            return
        }
        setBtn("Processing...")
        let total = 0
        for (let le of leads) {
            total += le.count
        }
        if (total > selectedLeadType.total) {
            notifyFailed("Total lead you want to assign is more than available leads")
            setBtn("Assign Lead")
            return
        }
        let cityTotal = 0
        for (let le of selectedCitys) {
            cityTotal += le.value
        }
        if (cityTotal < total) {
            notifyFailed("Total lead you want to assign is more than available leads in Selected city")
            setBtn("Assign Lead")
            return
        }
        let cit = []
        for (let i of selectedCitys) {
            if (i.value === "Empty_City") {
                cit.push('')
            } else {
                cit.push(i.value)
            }
        }
        let createdDays = []
        for (let i of selectedDate) {
            createdDays.push(i.originalDate)
        }
        let content = []
        for(let o of selectedContent.map(e=>e.value)){
            if(o==="Empty_Content"){
                for( let s of selectedSources){
                    content.push(s.value)
                }
            }else{
                content.push(o)
            }
        }
        //console.log(createdDays, "days");
        console.log(selectedLeadType,"type");
        axios.post(`${serverUrl.amazon}/leads/assign`, {
            assignments: leads,
            leadType: selectedLeadType.type,
            cities: cit,
            sources:content,
            createdDays: createdDays,
            employId:employeeId
        }).then(
            res => {
                if (res.data.success === true) {
                    notify(res.data.message)
                    setReload(!reload)
                    setBtn("Assign Lead")
                } else {
                    notifyFailed(res.data.message)
                    setBtn("Assign Lead")
                }
            }
        ).catch(function (err) {
            notifyFailed("Failed")
            setBtn("Assign Lead")
        })
    }

    useEffect(() => {

        getAllUnassignLead()
        let allTeleCaller = []
        for (let user of salesUser) {

            if (user.roles !== undefined && user.roles.includes(user_roles.LeadHandler)) {
                let num = user.phoneNumbers[0].split(',')
                allTeleCaller.push({ number: num[0], name: user.first_name })
            }
        }

        setAllTeleCallers(allTeleCaller)

    }, [reload])
    useEffect(() => {
        salesUserData(dispatch, token)

        autoAssignStatus().then(
            function (res) {
                if (Object.keys(res).length !== 0) {
                    setActiveAutoAssignCallers(res.teleCallerList)
                    setAutoAssignTelecallers(res.teleCallerList)
                } else {
                    notify("Auto assign not active")
                    setActiveAutoAssignCallers([])
                    setAutoAssignTelecallers([])
                }
            }
        ).catch(function (err) {

        })
    }, [])




    const handleAdvertiser = (add) => {

        let content = []
        for (let i of add) {
            content.push(i.value)
        }
       
        let city = selectedCitys.map(d => {
            return d.value
        })
        let source = selectedSources.map(d => {
            return d.value
        })
        let date = selectedDate.map(d => {
            return d.value
        })
        totalFilter(unassignedLead, selectedLeadType.type, date, content,source,city)
        // setAdvertisersType(data)
        // setAdvertisers(add)
        // citiesfilter(selectedLeadType.type, data, unassignedLead)

    }
    const handleDate = (dataD) => {
        let dates = dataD.map(d => {
            return d.value
        })
        let source = selectedSources.map(d => {
            return d.value
        })
        let city = selectedCitys.map(d => {
            return d.value
        })
        let content = selectedContent.map(d => {
            return d.value
        })
    
        //console.log(selectedLeadType.type, "type");
        totalFilter(unassignedLead, selectedLeadType.type, dates, content, source,city)

    }
    const handleSoures = (dataS) => {
        let source = dataS.map(d => {
            return d.value
        })
        let date = selectedDate.map(d => {
            return d.value
        })
        let city = selectedCitys.map(d => {
            return d.value
        })
        let content = selectedContent.map(d => {
            return d.value
        })
      
        //console.log(source, "new");
        totalFilter(unassignedLead, selectedLeadType.type, date, content, source,city)

    }
    const handleCity =(dataC)=>{
        let city = dataC.map(d => {
            return d.value
        })
        let source = selectedSources.map(d => {
            return d.value
        })
        let date = selectedDate.map(d => {
            return d.value
        })
        let content = selectedContent.map(d => {
            return d.value
        })
        totalFilter(unassignedLead, selectedLeadType.type, date, content, source,city)

    }
    const handleAutoAssignOn = () => {
        let telecallers = []
        for (let u of activeAutoAssignCallers) {
            telecallers.push(u.number)
        }

        let offTime = new Date(time).getTime()
        console.log(autoAssignTelecallers, offTime,telecallers, "autp assign cALL");
         autoAssignOn({ autoAssignTelecallers, offTime })
    }

    const times = (date) => {

        // Get the current date and time
        let now = new Date(date);

        // Format the date as "YYYY-MM-DD" (required by datetime-local input)
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0');
        let day = now.getDate().toString().padStart(2, '0');
        let formattedDate = `${year}-${month}-${day}`;

        // Format the time as "HH:MM" (required by datetime-local input)
        let hours = now.getHours().toString().padStart(2, '0');
        let minutes = now.getMinutes().toString().padStart(2, '0');
        let formattedTime = `${hours}:${minutes}`;

        // Set the default value of the input field
        //console.log(`${formattedDate}T${formattedTime}`, "rrrrrrrrrrrrrr");
        return `${formattedDate}T${formattedTime}`;

    }
    useEffect(() => { times(time) }, [time])
    
    return (
        <div style={{ padding: "0 4%", marginTop: "4vh" }}>
            {
                Object.keys(totalWithTypeLead).length && (
                    
                    <Grid container spacing={2}>

                        <Grid item md={4} xs={12}>
                            <Button
                                variant='contained'
                                color='primary'
                                size="small"
                                disabled={btn === "Assign Lead" ? false : true}
                                onClick={() => assignLeads()}
                            >
                                {btn}
                            </Button>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <ChangeLeadType
                               leads={leads}
                               selectedLeadType={selectedLeadType}
                               selectedCitys={selectedCitys}
                               selectedDate={selectedDate}
                               selectedContent={selectedContent}
                               selectedSources={selectedSources}
                               setReload={setReload}
                               reload={reload}
                            />
                        </Grid>
                    </Grid>
                )
            }




            {
                leadCount !== 0 && (
                    <Stack className='lead'>
                        <div className='mt-2'>Total UnAssign Leads :-  {leadCount}</div>
                        <div className='input-group mt-2' style={{ width: "300px", maxWidth: "90%", display: "flex", flexDirection: "column" }}>
                            <label>Type of Lead</label>
                            <select
                                className="custom-select height-select"
                                style={{ width: "100%" }}
                                value={`${selectedLeadType.type}`}
                                onChange={(e) => {
                                    if (e.target.value !== "default") {
                                        let data = e.target.value
                                       
                                        setSelectedLeadType({
                                            type: data, total: totalWithTypeLead[data]
                                        })
                                        totalFilter(unassignedLead, data, [], [], [],[])
                                        // citiesfilter(data[0], advertisersType, unassignedLead)
                                    }
                                }}
                            >

                                {
                                    Object.keys(totalWithTypeLead).map(lead => (
                                        <option value={lead}>Total {lead} are {totalWithTypeLead[lead].length}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                        <Multiselect
                            displayValue="label"
                            selectedValues={selectedDate}
                            onRemove={handleDate}
                            onSelect={handleDate}

                            options={totalDate}
                            style={{

                                multiselectContainer: {
                                    marginTop: "3px",
                                    backgroundColor: "#ffffff"
                                },
                            }}
                        />
                        </div>
                        <div className='col-2'>
                            Total :- {totalNumber.days}
                        </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                        <Multiselect
                            displayValue="label"
                             selectedValues={selectedSources}
                            onRemove={handleSoures}
                            onSelect={handleSoures}

                            options={sources}
                            style={{

                                multiselectContainer: {
                                    marginTop: "3px",
                                    backgroundColor: "#ffffff"
                                },
                            }}
                        />
                        </div>
                        <div className='col-2'>
                            Total :- {totalNumber.sources}
                        </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                        <Multiselect
                            displayValue="label"
                            selectedValues={selectedContent}
                            onRemove={handleAdvertiser}
                            onSelect={handleAdvertiser}

                            options={contents}
                            style={{

                                multiselectContainer: {
                                    marginTop: "3px",
                                    backgroundColor: "#ffffff"
                                },
                            }}
                        />
                        </div>
                        <div className='col-2'>
                            Total :- {totalNumber.contents}
                        </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                        <Multiselect
                            displayValue="label"
                            selectedValues={selectedCitys}
                            onRemove={handleCity}
                            onSelect={handleCity}
                            options={cityes}
                            style={{

                                multiselectContainer: {
                                    marginTop: "3px",
                                    backgroundColor: "#ffffff"
                                },
                            }}
                        />
                        </div>
                        <div className='col-2'>
                            Total :- {totalNumber.city}
                        </div>
                        </div>
                    </Stack>
                )
            }
            {
                salesUser.length !== 0 ?
                    <div className='table-container1' style={{ marginTop: "10px", marginBottom: "30px", height: "fit-content", maxHeight: "450px" }} key={"imp"}>
                        <table className='table table-sm table-bordered'>
                            <thead>
                                <tr >
                                    <th>Select</th>
                                    <th>TeleCaller</th>
                                    <th>Phone</th>
                                    <th>Lead Count</th>
                                    <th>Excluded City</th>
                                    <th>Excluded Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    salesUser.map((e, i) => {


                                        if (e.roles !== undefined && e.roles.includes(user_roles.LeadHandler)) {


                                            return (
                                                <TableRow
                                                    e={e}
                                                    handle={handle}
                                                    i={i}
                                                    cityArray={cityes}
                                                    setAutoAssignTelecallers={setAutoAssignTelecallers}
                                                    autoAssignTelecallers={autoAssignTelecallers}
                                                    activeAutoAssignCallers={activeAutoAssignCallers}
                                                    sources={sources}
                                                />
                                            )
                                        }

                                    })
                                }
                            </tbody>
                        </table>
                    </div> : null
            }
            {/* <LeadCreate/> */}
            {/* <Multiselect
        displayValue="name"
        selectedValues={activeAutoAssignCallers}
        onRemove={setActiveAutoAssignCallers}
        onSelect={setActiveAutoAssignCallers}

        options={allTeleCallers}
        style={{

          multiselectContainer: {
            marginTop: "3px",
            backgroundColor: "#ffffff"
          },
        }}
      /> */}
            <div style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px"
            }}>
                <label>Turn Off time </label>

                <div style={{ display: "flex", gap: "10px" }}>
                    <input
                        type="datetime-local"
                        className='form-control'
                        style={{ width: "20%" }} onChange={(e) => setTime(e.target.value)}
                        value={times(time)} />
                    <Button
                        variant='contained'
                        color='primary'
                        size="small"
                        onClick={() => handleAutoAssignOn()}
                    >
                        Auto Assign On
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        size="small"
                        onClick={() => autoAssignOff()}
                    >
                        Auto Assign Off
                    </Button>
                </div>
            </div>
            <div style={{ marginBottom: "30px" }}></div>
        </div>
    )
}

export default AssignLeadV2