import React from 'react'
import { notifyFailed } from '../Common/Notification';
import { pages } from '../ApiUrl';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';

import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "12px"
};

function ConfirmationForPaymentLinkRzp({ e, setRazPop, btnName }) {
  // console.log(page===pages.search,"value",page,pages.search);
  const [open, setOpen] = React.useState(false);
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setRazPop(true)
  };

  return (
    <div>
      {
        console.log(loginName,"name")
      }
      <Button
        variant='contained'
        type="button"
        className={"btn btn-primary"}

        onClick={() => {
          if (e.houseId === "" && (!["Akhil","Digvijay"].includes(loginName)) ) {
            notifyFailed('House Id Not Mapped')
            return
          }
          if (e.InstallationDate === "" || e.InstallationDate === null) {
            notifyFailed("Set Installation Date First Then Create Payment Link")
            return
          }
          setOpen(true)
        }}
      >
        {btnName}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
            Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to create payment link for {e?.name}?.
            Because Previouse subscription will be cancelled.
          </Typography>
          <Stack direction={'row'} spacing={3} justifyContent={"flex-end"} mt={5}>
            <Button variant="contained" sx={{ backgroundColor: "#9FA6B2" }} onClick={() => setOpen(false)}>No</Button>

            {
              e.InstallationDate !== "" && e.InstallationDate !== null ?
                <Button variant="contained" color='primary'
                  onClick={() => {
                    setRazPop(true)
                    setOpen(false)
                  }}
                >
                  Yes</Button>
                : null
            }
          </Stack>
        </Box>
      </Modal>


    </div>
  )
}

export default ConfirmationForPaymentLinkRzp