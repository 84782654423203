import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import '../AssignElectrician.css'
import { subHistory } from '../Function/SubscriptionHistory';
import moment from 'moment';

export default function ChangeGrivanceHandler({e,notify,reload,setReload}) {
    let userRole = useSelector((state) => state.sar.data?.user?.roles)
    let loginName = useSelector((state) => state.sar.data.user.first_name)

    let [displayDropDown,setDisplayDropDown] = useState(false) 
    let [confirmationDis, setConfirmationDis] = useState(false) 
    
      const assign = async(detail)=>{
        await axios.post(`${serverUrl.u}/api/grv/changeHandler`,{
          generatedHex:e.generatedHex,
          handler :detail.handlerName,
          commentByGrivienceDepartment:`${detail.comment} - ${moment(new Date()).format("DD-MM-YYYY")}`, 
          grievancePriority:detail.priority==="false"?false:true,
          InstallationDate:e.InstallationDate,
          grivienceCategory:e.grivienceCategory
        }).then(function (response) {
        let detail=response;
        console.log(detail);
        if(response.status===200){
          setDisplayDropDown(false)
          setReload(!reload)
          
          subHistory(`Grivience Handler change by ${loginName}`,{...e,payload:{
            generatedHex:e.generatedHex,
          handler :detail.handlerName,
          commentByGrivienceDepartment:`${detail.comment} - ${moment(new Date()).format("DD-MM-YYYY")}`, 
          grievancePriority:detail.priority==="false"?false:true,
          InstallationDate:e.InstallationDate,
          grivienceCategory:e.grivienceCategory
          }},"Grivience Handler change",detail.handlerName)
          notify("Handler is updated plz wait to update Data")
        }else{
          notify("Error Occured")
        }
        
      }).catch(function (error) {
        console.log(error);
        notify("Error Occured")
      })}
      const handleElecteicain = (event,dataHex) => {
        assign(event.target.value,dataHex)
      }
      const handleSubmit =(event)=>{
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let detail = {
          handlerName:data.get('handlerName'),
          priority:data.get('priority'),
          comment:data.get('comment')
        }
        assign(detail)
      }
    return (
      <>
       {confirmationDis === true ?
        <div className='confirmation-popup'>
          <div className='confirmation-popup-heading'>Confirmation to change {e.name}</div>
          <div className='confirmation-popup-Subheading'>Are your sure you want to Ready</div>
          <button className='btn btn-danger wid' onClick={() => { setConfirmationDis(false) }}>No</button>
          <button className='btn btn-primary wid ml-5' onClick={() => {
          
            setConfirmationDis(false)
            setDisplayDropDown(true)
          }}>Yes</button>
        </div> : null
      }
      <td onClick={() => {setConfirmationDis(true)}} label="Assigned Electritian *">{e.grivienceHandler}</td>
    
            {
                displayDropDown === false ? null : 
                <div style={{ position: "fixed", height: "100vh", width: "100vw", display: "flex", overflow: "scroll", justifyContent: "center", alignItems: "center", top: 0, left: 0,zIndex:4}}>
               <div style={{ width:"fit-content", backgroundColor: "lightGray", padding: "10px 15px", borderRadius: "12px" ,}}>
               <div style={{width:"100%",position:"relative",height:"35px"}}>
                <span 
                  style={{
                    position:"absolute",
                    right:"10px",
                    cursor:"pointer"
                  }}
                  onClick={()=>{
                       setDisplayDropDown(false)
                      }}>X</span>
              </div>
                <form onSubmit={handleSubmit}>
                <div class="input-group mb-1" >
                    <select class="custom-select" 
                            id="inputGroupSelect01"
                            name="handlerName"
                            autoComplete='current-handlerName'
                            defaultValue="empty"
                    >
                        <option value="empty">choose Handler</option>
                            <option value={"Vikas"}>Vikas</option>
                            <option value={"Heena"}>Heena</option>
                            <option value={'Sidharth'}>Sidharth</option>
                    
                    </select>
                </div>
                <label>Priority</label>
                <div class="input-group mb-3" >
                    <select class="custom-select" 
                        id="inputGroupSelect01"
                        name='priority'
                        autoComplete='priority'
                        defaultValue="false"
                    >
                            <option value={"false"}>No</option>
                            <option value={"true"}>Yes</option>
                    </select>
                </div>
                <label>Comment</label>
                <div class="input-group mb-3" >
                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Comment" autoComplete='current-comment' name='comment' />
                </div>
                <div class="input-group mb-3" >
                  <button type='submit' className='btn btn-primary'>Submit</button>
                </div>
                </form>
                </div>
                </div>
            }
        
        </>
    )
}
