import React from 'react'
import { useEffect } from 'react'
import { allLeave, electricianOutOfCity, pendingLeave } from './Api'
import { useState } from 'react'
import { Button, Stack } from '@mui/material'
import ApproveLeave from './ApproveLeave'
import DisapproveLeave from './DisapproveLeave'
import TotalLevaves from './TotalLevaves'

const dateLeave = {
    width:"170px"
}
function ElectricianLeave() {
    const [leave,setLeave] = useState([])
    
    useEffect(()=>{
      allLeave(setLeave)
    },[])
    
  return (
    <div style={{ padding: "0 1%", marginTop: "4vh" }}>
<div className='table-container1' style={{marginTop:0}}>
      
      <table className='table table-sm table-bordered'>
        <thead>
          <tr >
            <th>Sr. No</th>
            <th>Employee Id</th>
            <th>Name</th>
            <th>Approved Leave</th>
            <th>UpComing Leave</th>
            <th>Pending For Approve</th>
            <th>Out Of City</th>
            <th style={{width:"550px"}}>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            leave.length !== 0 ?
              leave.map((e, i) => {
                return (
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{e.employeeId}</td>
                    <td>{e.firstName}</td>
                    <td>{e.approvedLeave.length}</td>
                    <td>{e.currentLeave.length}</td>
                    <td>
                       {e.leaveForApproval.length}
                    </td>
                    <td>{e.outOfCity?"Yes":"No"}</td>
                    <td>
                        <Stack direction={"row"} spacing={2}>
                        <ApproveLeave leave={e} setLeave={setLeave}/>
                        <DisapproveLeave leave={e} setLeave={setLeave}/>
                        <Button 
                          variant='contained'
                          color='primary'
                          size='small'
                          onClick={()=>electricianOutOfCity(e.employeeId,setLeave)}
                        >
                          Electrician Out Of City
                        </Button>
                        <TotalLevaves leave={e}/>
                        </Stack>
                    </td>
                  </tr>
                )
              }) : null
          }
        </tbody>
      </table>
    </div>
    </div>
  )
}

export default ElectricianLeave