import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './ConvertedLead.css'
import './DispatchData.css'
import './PaymentLinkGen.css'
import './PartialPayment.css'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs';
import Qutoes from './Qutoes'
import { serverUrl } from './ApiUrl'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import AddressUpdate from './Function/AddressUpdate'
import ChangeCalculationProblem from './Function/ChangeCalculationProblem'
import ChangeMultipleProblem from './Function/ChangeMultipleProblem'
import OtherProblemChange from './Function/OtherProblemChange'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { planCost } from './Data'

export default function MandatePaidOrderStatus({wholeReload}) {
  let [details, setDetails] = useState([]);
  let [update, setUpdateData] = useState({})
  let [q, setq] = useState("");
  let [reloadW, setReload] = useState(false);
  const [searchParam] = useState(["phone","name"]);
  let [resetData, setResetData] = useState()
  let [partialD, setPartialD] = useState(false);
  let [multipleProblemCount,setMutipleProblemCount] = useState(0)
  let [calculationProblemCount,setCalculationProblemCount] = useState(0)
  const notify = (text) => toast(text);
  let [filter, setFilter] = useState({
    partialPayment: false,
    dispatched: false,
    installation: false,
    nonPartialPayent:false
  })
  let userRoles = useSelector((state) => state.sar.data.user)
  let teleCallers = useSelector((state)=>state.sar.data.user.first_name)
  let [secIns, setSecIns] = useState({
    security: 0,
    installation: 0
  });
 
  useEffect(() => {
    let data = async () => {
      await axios.post(`${serverUrl.u}/api/tele/getAllSubscriptionsParticular`, {
        teleCallerId: teleCallers
      })
        .then(function (response) {
          let detail = response.data.data;
          detail.sort(function(a,b){
            return new Date(b.mandateDate) - new Date(a.mandateDate);
          });
          console.log(detail);
          setDetails(detail);
          
        })
        .catch(function (error) {
          console.log(error);
        })
    }
    let dataAll = async () => {
      await axios.post(`${serverUrl.u}/api/tele/getAllSubscriptions`).then(function (response) {
        let detail = response.data.data;
        detail.sort(function(a,b){
            return new Date(b.mandateDate) - new Date(a.mandateDate);
          });
        console.log(detail);
        if(filter.partialPayment===false && filter.dispatched===false && filter.installation===false && filter.nonPartialPayent===false){
            setDetails(detail)
        }else if(filter.partialPayment===true && filter.dispatched===true && filter.installation===true){
        let partialData=detail.filter(e=>(e.partialPayment===true))
        let dispatchData=partialData.filter(e=>(e.dispatched===true))
        let installData=dispatchData.filter(e=>(e.installed===true))
         setDetails(installData);
         } else if(filter.partialPayment===true && filter.dispatched===false && filter.installation===false && filter.nonPartialPayent===false){
           detail=detail.filter(e=>(e.partialPayment===true))
           setDetails(detail)
         }else if(filter.partialPayment===false && filter.dispatched===true && filter.installation===false && filter.nonPartialPayent===false){
          detail=detail.filter(e=>(e.dispatched===true))
          setDetails(detail)
        }else if(filter.partialPayment===false && filter.dispatched===false && filter.installation===true && filter.nonPartialPayent===false){
          detail=detail.filter(e=>(e.installed===true))
          setDetails(detail)
        }else if(filter.partialPayment===false && filter.dispatched===false && filter.installation===false && filter.nonPartialPayent===true){
          detail=detail.filter(e=>(e.partialPayment===false))
          setDetails(detail)
        }
        let MultipleProCount=0;
      
          for(let i of detail){
           console.log(i,"i");
            if(i.MultipleProblem===true){
              MultipleProCount +=1;
            }
          }
           setMutipleProblemCount(MultipleProCount)
           let calProCount=0;
      
          for(let i of detail){
           console.log(i,"i");
            if(i.calculationProblem===true){
              calProCount +=1;
            }
          }
           setCalculationProblemCount(calProCount)
           
      }).catch(function (error) {
          console.log(error);
        })
    }
    userRoles?.roles.includes("Super Admin") ? dataAll() : data()
  }, [reloadW,filter,wholeReload])
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }


  const planNameSet = (data) => {
    setSecIns({
      security: data.security,
      installation: data.installation
    })
  }
  const handleApplianceChange = (val) => {
    val = Number(val);
    let secu = val * 100;
    let inst;
    if (val > 10) {
      let cal = val - 10;
      inst = 1000 + (cal * 50);
    } else {
      inst = 1000;
    }
    setSecIns(
      {
        security: secu,
        installation: inst
      }
    )
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userData2 = {
      name: data.get("name"),
      phone: data.get("phone"),
      city: data.get("city"),
      address: data.get("address"),
      planType: data.get("planDuration"),
      plan: data.get("planName"),
      security: data.get("security"),
      installion: data.get("installion"),
      quantityAppliances: data.get("appliances"),
      partialAmount: data.get("partial"),
      partialStatus: data.get("Partial")
    }
    console.log(userData2);

    let seq = Number(userData2.security);
    let ins = Number(userData2.installion);
    let qu = Number(userData2.quantityAppliances)
    let planDurations = update.planFrequency;
    let fee = 0;



    if (planDurations === "Monthly") {
      fee = (qu*planCost.Monthly) * 30
    } else if (planDurations === "Quarterly") {
      fee = (qu*planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
      fee = (qu*planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
      fee = (qu*planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
      fee = (qu*planCost.TwoYears) * (365*2);
    } else if (planDurations === "Three Years") {
      fee = (qu*planCost.ThreeYears) * (365*3);
    }
    let total = ((fee + ins) * 1.18 + seq).toFixed(2)

    let layout = [{
      roomName: "Custom",
      roomId: 0,
      switchboards: [
        {
          switchboardNumber: "1",
          appliances: {
            fans: 0,
            light: 0,
            ac: 0,
            geyser: 0,
            tv: 0,
            socket: 0,
            other: 0,
            ir: 0
          }
        }
      ]
    }]
    layout[0].switchboards[0].appliances.other = Number(userData2.quantityAppliances)
    userData2 = { ...userData2, totalAppliances: qu, orignalTotalAmount: total, totalAmount: total, orignalRecursiveAmount: fee, recursiveAmount: fee, layout: layout }
    updatePlanComp(userData2)
  }

  const updatePlanComp = async (userData) => {

    await axios.post(`${serverUrl.u}/api/zoho/registerLeadAndink`,
      {
        planId: 0,
        planFrequency: update.planFrequency,
        refferal: update.refferal,
        email: update.email,
        first_name: update.first_name,
        last_name: update.last_name,
        phone: update.phone,
        city: update.city,
        zip: update.zip,
        address: update.address,
        teleCallerId: update.teleCallerId,
        comments: update.comments,
        leadQuality: update.leadQuality,
        origin: update.origin,
        layout: userData.layout,
        security: userData.security,
        installation: userData.installion,
        partialPayment: userData.partialStatus === "true" ? true : false,
        secondarySalesPerson: update.teleCallerSecond,
        discount: 0,
        orignalRecursiveAmount: userData.orignalRecursiveAmount,
        orignalTotalAmount: Number(userData.orignalTotalAmount),
        recursiveAmount: userData.recursiveAmount,
        totalAmount: Number(userData.orignalTotalAmount),
        addOnAmount: 0,
        totalAppliances: userData.quantityAppliances,
        addOnSubscriptionTo: "empty",
        partialAmount: Number(userData.partialAmount),
        generatedHex: update.generatedHex,


      }).then(function (response) {
        console.log(response);

      }).catch(function (error) {
        console.log(error);
      })

  }

  return (
    <div className='container-fluid'>
       <ToastContainer/>
      <div className='input-field'>
        <div style={{marginRight:"15px"}}><Link to="/CscOrderPaidStatus" state={{ from: {} ,path:"Mandate_Paid_Order_Status"}}><img src={process.env.PUBLIC_URL+'/assets/icon/csv.png'} style={{height:"35px"}}/></Link></div>
        <input type="text" value={q} placeholder="Search here name or phone number...." onChange={(e) => setq(e.target.value)} /></div>
      
      <div>
        <input type="checkbox" checked={filter.partialPayment} onClick={(e) => {
          console.log(e.target.checked)
          setFilter({ ...filter, partialPayment: e.target.checked })
        }} />
        <label style={{marginRight: "30px"}}>Partial Payment</label>
        <input type="checkbox" checked={filter.dispatched} onClick={(e) => setFilter({ ...filter, dispatched: e.target.checked })} />
        <label style={{marginRight: "30px"}}>Dispatch</label>
        <input type="checkbox" checked={filter.installation} onClick={(e) => setFilter({ ...filter, installation: e.target.checked })} />
        <label style={{marginRight: "30px"}}>Installed</label>
        <input type="checkbox" checked={filter.nonPartialPayent} onClick={(e) => setFilter({ ...filter, nonPartialPayent: e.target.checked })} />
        <label style={{marginRight: "30px"}}>Non Partial Payment</label>
      </div>
      <div>
        <span>Multiple Problem Count :- {multipleProblemCount}</span>
        <span style={{marginLeft:"20px"}}>Calculation Problem Count :- {calculationProblemCount}</span>
      </div>
      <div className='table-container1' >
        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th scope="col" style={{fontWeight:"bold"}}>Name</th>
              <th>Hex Code</th>
              
              <th scope="col">Appliances</th>
              <th>Payment Status</th>
              <th style={{color:"#FB2576"}}>Security</th>
              <th style={{color:"#332FD0"}}>Zoho Security</th>
              <th style={{color:"#332FD0"}}>Zoho Installation</th>
              <th style={{color:"#332FD0"}}>Zoho Recursive Amount</th>
             
              <th style={{color:"#FB2576"}}>Installation</th>
              <th style={{color:"#FB2576"}}>RecursiveAmount</th>
              
              
              <th style={{color:"#0002A1"}}>Total Amount</th>
              <th style={{color:"#332FD0"}}>Extra Payment</th>
              
              <th colspan="2" style={{width:"200px"}}>Action</th>
              <th style={{width:"250px"}}>Calculation Problem</th>
              <th style={{width:"250px"}}>Multiple Problem</th>
              <th style={{width:"250px"}}>Other Problem</th>
              <th style={{width:"250px"}}>Other Problem Description</th>
              <th scope="col">Selected Plan</th>
              <th scope="col">City</th>
              <th scope="col" style={{width:"110px"}}>Phone</th>
              <th scope="col">Created At</th>
              <th>Mandate Date</th>
              <th scope="col">Comments</th>
              <th>Address<span style={{color:"red"}}>*</span></th>
              <th>Online Payment</th>
              <th>Payment Carrier</th>
              <th>Payment Status</th>
              <th>Partial Payment</th>
              <th>Installation Status</th>
              <th scope="col">Telecaller Name</th>
              <th>Second TeleCaller</th>
              
              <th>Dispatch Status</th>
              <th>Dispatched</th>
              <th>Out of Delivery Status</th>
              <th style={{width:"110px"}}>Devices</th>
              <th style={{width:"110px"}}>subscriptionStage</th>
            </tr>
          </thead>
          <tbody>
            {

              details !== 0 ?

                search(details).map((e, i) => {
                  return (

                    <tr key={i} style={{backgroundColor:e.totalAppliances*100!==e.security?"#ff00005e":''}}>
                      <td className="pr-15 pl-55" style={{ color: `${e.installed === true ? "green" : "black"}`,fontWeight:"bold" }}>{e.name}</td>
                      <td style={{ color: `${e.partialPayment === true ? "orange" : "black"}` }}>{e.generatedHex}</td>
                    
                      <td>{e.totalAppliances}</td>
                      <td style={{ color: `${e.partialPayment === false ? "green" :(e.partialAmount===0)?"red":"orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount===0)?"Zero":`Partial`}</td>
                      <td style={{color:"#FB2576"}}>{e.security}</td>

                      <td style={{color:"#332FD0"}}>{e.zohoSecurity}</td>
                      <td style={{color:"#332FD0"}}>{e.zohoInstallation}</td>
                      <td style={{color:"#332FD0"}}>{e.zohoRecursiveAmount}</td>
                      <td style={{color:"#FB2576"}}>{e.installation}</td>
                      <td style={{color:"#FB2576"}}>{e.recursiveAmount}</td>
                      
                    
                      <td style={{color:"#0002A1"}}>{e.totalAmount.toFixed(2)}</td>
                      <td style={{color:"#332FD0"}}>{e.extra1}</td>
                 
                      <td><Link to="/pdf2" state={{ from: e, path: "Order Paid Status" }}><button className='btn btn-primary' type="button" >Layout</button></Link></td>
                      
                      <td ><button className='btn btn-primary' onClick={() => {
                        planNameSet(e)
                        setUpdateData(e)
                      }}>Add-On</button></td>
                      <td><ChangeCalculationProblem e={e} notify={notify} reload={reloadW} setReload={setReload}/></td>
                      <td><ChangeMultipleProblem e={e} notify={notify} reload={reloadW} setReload={setReload}/></td>
                      <td><OtherProblemChange e={e} notify={notify} reload={reloadW} setReload={setReload}/></td>
                      <td>{e.OtherProblemDesc}</td>
                      <td>{e.planFrequency}</td>
                      <td>{e.city}</td>
                      <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <td>{moment(e.mandateDate).format("MMM Do")}</td>
                      <td>{e.comments}</td>
                      <AddressUpdate e={e} setReload={setReload} reloadW={reloadW} page={"mandatePage"}/>
                      <td>{e.onlinePayment  === false ? "No" : "Yes"}</td>
                      <td>{e.paymentCarrier}</td>
                      <td>{e.paymentStatus}</td>
                      <td style={{ color: `${e.partialPayment === false ? "green" : "red"}` }}>{e.partialPayment === false ? "No" : "Yes"}</td>

                      <td style={{ color: `${e.installed === false ? "green" : "red"}` }}>{e.installed === false ? "NO" : "YES"}</td>
                      <td>{e.teleCallerId}</td>
                      <td>{e.secondarySalesPerson === "empty" ? "" : e.secondarySalesPerson}</td>
                      <td>{e.dispatchStatus}</td>
                      <td style={{ color: `${e.dispatched === false ? "green" : "red"}` }}>{e.dispatched === false ? "NO" : "YES"}</td>
                      <td style={{ color: `${e.pickedForInstallation === false ? "green" : "red"}` }}>{e.pickedForInstallation === false ? "NO" : "YES"}</td>
                      <td><ul>{e.devices.map((ele) => {
                        return <li>{ele}</li>
                      })}</ul></td>
                      <td>{e.subscriptionStage}</td>
                    </tr>

                  )
                }) : null
            }
          </tbody>
        </table>
      </div>


      
      {
        (Object.keys(update).length !== 0) ?
          <form className='form-update' onSubmit={handleSubmit}>
            <div className='cross1' onClick={() => setUpdateData({})}>x</div>
            <Grid container spacing={4} >
              <Grid item xs={12} md={6}>
                <label>Name</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="name" name="name" autoComplete="name" placeholder="Name" aria-label="name" aria-describedby="basic-addon1" value={update.name} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Phone Number</label>
                <div class="input-group mb-3">
                  <input type="number" class="form-control" id="phone" name="phone" autoComplete="current-phone" placeholder="Phone" aria-label="phone" aria-describedby="basic-addon1" value={update.phone}  onWheel={ event => event.currentTarget.blur() }/>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={4} >

              <Grid item xs={12} md={6}>
                <label>Appliances</label>
                <div class="input-group mb-3">
                  <input type="Number" class="form-control" id="appliances"  onWheel={ event => event.currentTarget.blur() } name="appliances" autoComplete="current-appliances" placeholder="Appliances" aria-label="appliances" aria-describedby="basic-addon1" defaultValue={0} onChange={(val) => {
                    handleApplianceChange(val.target.value)
                  }} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Partial Payment select</label>
                <div class="input-group mb-3">
                  <select class="custom-select" required id="inputGroupSelect01" defaultValue="choose one" onChange={(ele) => setPartialD(ele.target.value)}
                    name="Partial"
                    label="Partial"
                    autoComplete="current-Partial" >
                    <option value="false">choose Partial Payment</option>
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={4} >
              <Grid item xs={12} md={6}>
                <label>Security</label>
                <div class="input-group mb-3">
                  <input type="Number" class="form-control"  onWheel={ event => event.currentTarget.blur() } id="security" name="security" autoComplete="current-security" placeholder="Security" aria-label="security" aria-describedby="basic-addon1" value={secIns.security} onChange={(val) => setSecIns({ ...secIns, security: val.target.value })} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Installation</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="installion" name="installion" autoComplete="current-installion" placeholder="installion" aria-label="installion" aria-describedby="basic-addon1" value={secIns.installation} onChange={(val) => setSecIns({ ...secIns, installation: val.target.value })} />
                </div>
              </Grid>
            </Grid>
            {
              partialD === "true" ?
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <label>Partial Payment</label>
                    <div class="input-group mb-3">
                      <input type="Number" class="form-control"  onWheel={ event => event.currentTarget.blur() } id="partial" name="partial" autoComplete="current-partial" placeholder="Partial" aria-label="partial" aria-describedby="basic-addon1" value={update.partialAmount} />
                    </div>
                  </Grid>
                </Grid> : null
            }
            <Grid container spacing={4}>
              <Grid item xs={12} md={2}>
                <button className='btn btn-primary' type="submit">Update</button>
              </Grid>
            </Grid>
          </form> : null
      }
      <Qutoes />
    </div>
  )
}
