import axios from 'axios';
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl';
import { useDispatch } from 'react-redux';
import { removeInstallationData } from '../Redux/installationActions';

export default function OutOfNCR({dataSub,notify}) {
    let dispatch = useDispatch()
    let [pop,setPop] = useState(false)
    const handleSubmit = (event) =>{
           event.preventDefault();
           const data = new FormData(event.currentTarget);
           const device = data.get('id').split(',')
           console.log(device);
           outSideNcr(device)
    }
    const outSideNcr= (device)=>{
        axios.post(`${serverUrl.u}/api/sub/updateSubscriptionOfOutsideNCR`,{
            generatedHex:dataSub.generatedHex,
            devices:device
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
               notify(res.data.message)
               dispatch(removeInstallationData(dataSub))
               setPop(false)
            }else{
                notify(res.data.message)
            }
        }).catch(function (err) {
            console.log(err);
            notify("Failed")
        })
    }
  return (
    <>
      <button className='btn btn-primary' onClick={()=>setPop(true)}>Outside NCR</button>

      {
        pop===true?
        <div style={{position:"fixed",top:"35%",zIndex:31,left:"30%",width:"25%",backgroundColor:"whitesmoke",borderRadius:"12px",padding:"15px"}}>
            <div style={{float:"right",cursor:"pointer"}} onClick={()=>setPop(false)}>X</div>
            <form onSubmit={handleSubmit}>
            <label>Device Id (Separate with ",")</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="id"
                    name="id"
                    autoComplete="current-id"
                    placeholder="Device-id"
                    aria-label="id"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <button type='submit' className='btn btn-primary'>Submit</button>
            </form>
        </div>:null
      }
    </>
  )
}
