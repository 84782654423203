import {
  useForm,
  SubmitHandler,
  useFieldArray,
  Controller,
} from "react-hook-form";
import React from "react";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import { registerNewClient } from "./Api";

export default function CustomerRegister() {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      legalName: "",
      phoneNumbers: [],
      gstNumber: "",
      emails: [],
      type: "B2C",
      addresses: [
        {
          address: "",
          city: "",
          state: "",
          gstNumber: "",
          pinCode: "",
          country: "",
        },
      ],
    },
  });

  // Address Field Array
  const {
    fields: addressFields,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: "addresses",
  });

  // Phone Numbers Field Array
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: "phoneNumbers",
  });

  // Emails Field Array
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "emails",
  });

  const onSubmit = async (data) => {
    // if (data)
    if (data.phoneNumbers.length < 1) {
      toast("PhoneNumber is required");
      return;
    }

    if (data.addresses[0].address === "") {
      toast("Address is required");
      return;
    }

    if (data.addresses[0].city === "") {
      toast("City is required");
      return;
    }

    if (data.addresses[0].state === "") {
      toast("State is required");
      return;
    }

    if (data.addresses[0].pincode === "") {
      toast("Pincode is required");
      return;
    }

    if (data.addresses[0].country === "") {
      toast("Country is required");
      return;
    }
    await registerNewClient(data);
  };
  return (
    <div>
      <h2 className="text-2xl text-black/70 font-bold">Customer Register</h2>

      <form
        className="p-4 border border-[#C6D0F0] rounded-lg shadow-lg mb-4"
        style={{ border: "1px solid #C5D0F0", borderRadius: "0.5rem" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-6">
            <label>
              First Name <span className="text-red-500">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                {...register("first_name", { required: true })}
                type="text"
                className="form-control"
                name="first_name"
                aria-label="first_name"
                placeholder="First Name"
                // style={{borderColor: error && "border-red-500"}}
              />
            </div>
          </div>
          <div className="col-6">
            <label>
              Last Name<span className="text-red-500">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                {...register("last_name", { required: true })}
                type="text"
                className="form-control"
                name="last_name"
                aria-label="last_name"
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label>
              Legal Name<span className="text-red-500">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                {...register("legalName", { required: true })}
                type="text"
                className="form-control"
                name="legalName"
                aria-label="legal name"
                placeholder="GST Name"
              />
            </div>
          </div>
          <div className="col-6">
            <label>GST Number</label>
            <div className="input-group mb-3">
              <input
                {...register("gstNumber")}
                type="text"
                className="form-control"
                name="gstNumber"
                aria-label="gst number"
                placeholder="GST Number"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <label>
              Type<span className="text-red-500">*</span>
            </label>
            <Controller
              name="type"
              control={control}
              defaultValue="" // Set a default value if needed
              render={({ field }) => (
                <select {...field} className="form-control">
                  <option value="B2C">B2C</option>
                  <option value="B2B">B2B</option>
                </select>
              )}
            />
          </div>
        </div>

        {/* PhoneNumbers */}
        <div>
          <div className="w-full border-b-2 my-4 p-1">
            <div className="text-xl text-bold">Phone Numbers</div>
          </div>
          <div className="row">
            {phoneFields.map((item, index, dataArray) => {
              return (
                <div className="col-6" key={index}>
                  <label>PhoneNumber</label>
                  <div className="input-group mb-3 relative">
                    {dataArray.length > 1 && (
                      <DeleteForeverIcon
                        onClick={() => removePhone(index)}
                        className="absolute"
                        style={{ right: 0, zIndex: 1, color: "#d32f2f" }}
                      />
                    )}
                    <input
                      type="number"
                      {...register(`phoneNumbers.${index}`)}
                      placeholder="Phone Number"
                      className="form-control"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row">
            <Button
              variant="contained"
              type="button"
              onClick={() => appendPhone(" ")}
              className="bg-blue-500 p-2 text-white rounded-md ml-4 mb-4"
            >
              Add Number
            </Button>
          </div>
        </div>

        {/* Emails */}
        <div>
          <div className="w-full border-b-2 my-4 p-1">
            <div className="text-xl text-bold">Emails</div>
          </div>
          <div className="row">
            {emailFields.map((item, index, dataArray) => {
              return (
                <div className="col-6" key={index}>
                  <label>Email</label>
                  <div className="input-group mb-3 relative">
                    {dataArray.length > 1 && (
                      <DeleteForeverIcon
                        className="absolute"
                        onClick={() => removeEmail(index)}
                        style={{ right: 0, zIndex: 1, color: "#d32f2f" }}
                      />
                    )}
                    <input
                      type="text"
                      {...register(`emails.${index}`)}
                      placeholder="Email"
                      className="form-control"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row">
            <Button
              variant="contained"
              type="button"
              onClick={() => appendEmail("")}
              className="bg-blue-500 p-2 rounded-md ml-4 mb-4"
            >
              Add Email
            </Button>
          </div>
        </div>

        {/* Addresses */}
        <div>
          <div className="w-full border-b-2 my-4 p-1">
            <div className="text-xl text-bold">Address</div>
          </div>

          {addressFields.map((item, index, dataArray) => (
            <React.Fragment key={index}>
              <div className="row">
                <div className="col-6">
                  <label>
                    Address<span className="text-red-500">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      {...register(`addresses.${index}.address`)}
                      placeholder="Address"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label>
                    City <span className="text-red-500">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      {...register(`addresses.${index}.city`)}
                      placeholder="City"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label>
                    State<span className="text-red-500">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      {...register(`addresses.${index}.state`)}
                      placeholder="State"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6 ">
                  <label>GST Number</label>
                  <input
                    type="text"
                    {...register(`addresses.${index}.gstNumber`)}
                    placeholder="GST Number"
                    className="form-control"
                  />
                </div>
                <div className="col-6">
                  <label>Pincode</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      {...register(`addresses.${index}.pinCode`)}
                      placeholder="Pincode"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label>Country</label>
                  <input
                    type="text"
                    {...register(`addresses.${index}.country`)}
                    placeholder="Country"
                    className="form-control"
                  />
                </div>
                {dataArray.length > 1 && (
                  <div className="row">
                    <div className="col-12">
                      <Button
                        variant="outlined"
                        type="button"
                        color="error"
                        onClick={() => removeAddress(index)}
                        className="bg-[#] mb-2 p-1 rounded-md ml-4"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}

          <div className="row">
            <Button
              variant="contained"
              type="button"
              onClick={() =>
                appendAddress({
                  address: "",
                  city: "",
                  state: "",
                  gstNumber: "",
                  pinCode: "",
                  country: "",
                })
              }
              className="bg-blue-500 p-2 text-white rounded-md ml-4 mb-4"
            >
              Add Address
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              type="submit"
              variant="contained"
              className="bg-blue-700 p-2 rounded-md w-full"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
