import React from 'react'
import SelectCityInput from '../../city/SelectCityInput'
import { indianStates } from '../../Data'
import { notifyFailed } from '../../Common/Notification'
import { editIsp, getIsp } from '../RegisterIsp/ispApi'
import { useEffect } from 'react'

function EditIsp() {
    const [city, setCity] = React.useState("")
    const [btn, setBtn] = React.useState("Submit")
    const [isp, setIsp] = React.useState({})
    const [walletBtn,setWalletBtn] = React.useState("Submit")

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setBtn("Processing...")
        console.log(data);
        if (city === "") {
            notifyFailed("City Not Selected")
            return
        }
        if (data.get('state') === "") {
            notifyFailed("State Not Selected")
            return
        }
        let details = {
            "name": data.get("name"),
            "address": data.get('address'),
            "city": city,
            "phone": Number(data.get('phone')),
            "ispAppliances": Number(data.get('ispAppliances')),
            "walletBalance": Number(data.get('walletBalance')),
            "email": data.get('email'),
            "gstDetails": {
                getName: data.get('gstName'),
                gstNumber: data.get('gstNumber'),
                billToAddress: data.get('billToAddress'),
                shipToAddress: data.get('shipToAddress'),
            },
            "state": data.get('state'),
            "paymentType": ""
        }
        console.log(details, "details");
        await editIsp(details, setBtn)
    }
    useEffect(() => {
        console.log(isp, "isp");
        setCity(isp?.city)
    }, [isp])
    return (
        <div style={{ padding: "0 4%", marginTop: "4vh" }} >
            <form onSubmit={handleSubmit} className='make_quto'>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Phone Number</label>
                            <input
                                type="number"
                                required
                                className='form-control'
                                name='phone'
                                autoComplete='current-phone'
                                onChange={(e) => getIsp(e.target.value, setIsp)}
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Email</label>
                            <input
                                type="email"
                                required
                                className='form-control'
                                name='email'
                                defaultValue={isp?.email}
                                autoComplete='current-email'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>

                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Name</label>
                            <input
                                type="text"
                                required
                                className='form-control'
                                name='name'
                                defaultValue={isp?.name}
                                autoComplete='current-name'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Free Appliances</label>
                            <input
                                type="number"
                                required
                                className='form-control'
                                name='ispAppliances'
                                defaultValue={isp?.ispAppliances}
                                autoComplete='current-ispAppliances'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Address</label>
                            <input
                                type="text"
                                className='form-control'
                                name='address'
                                required
                                defaultValue={isp?.address}
                                autoComplete='current-address'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>City</label>
                            <SelectCityInput value={city} setValue={setCity} />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>GST Name</label>
                            <input
                                type="text"
                                className='form-control'
                                name='gstName'
                                required
                                defaultValue={isp?.gstDetails?.getName}
                                autoComplete='current-gstName'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>GST Number</label>
                            <input
                                type="text"
                                className='form-control'
                                name='gstNumber'
                                required
                                defaultValue={isp?.gstDetails?.gstNumber}
                                autoComplete='current-gstNumber'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Bill To Address</label>
                            <input
                                type="text"
                                className='form-control'
                                name='billToAddress'
                                required
                                defaultValue={isp?.gstDetails?.billToAddress}
                                autoCapitalize='current-billToAddress'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Ship To Address</label>
                            <input
                                type="text"
                                className='form-control'
                                name='shipToAddress'
                                required
                                defaultValue={isp?.gstDetails?.shipToAddress}
                                autoCapitalize='current-shipToAddress'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>State</label>
                            <select
                                className='form-control'
                                name='state'
                                autoComplete='current-state'
                                value={isp?.state}
                                required
                            >
                                <option value={""} key={"state"}>Select State</option>
                                {
                                    indianStates && indianStates.map((item, index) => {
                                        return (
                                            <option key={index} value={item.value}>{item.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-3'>
                        <button className='btn btn-success h-4' disabled={btn === "Processing..." ? true : false} type="submit" style={{ width: "100%" }}>{btn}</button>
                    </div>
                </div>
            </form>

            
        </div>
    )
}

export default EditIsp