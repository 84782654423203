import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { serverUrl } from './ApiUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductSellingLink from './SmallComponent/ProductSellingLink';
import ProductSellingWithOutLink from './SmallComponent/ProductSellingWithOutLink';
import ProductEdit from './SmallComponent/ProductEdit'
import { useSelector } from 'react-redux';
import { subHistory } from './Function/SubscriptionHistory';
import DeleteProduct from './Product/DeleteProduct';

export default function ProductSelling() {
  let userRole = useSelector((state) => state.sar.data?.user?.roles)
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let [displayData, setDisplayData] = useState([])
  let [productSellingPop, setProductSellingPop] = useState(false)
  let [productDataItem,setProductDataItem] = useState([])
  let [apiCall,setApiCall] = useState(false)
  let [productSellingLinkPop, setProductSellingLinkPop] = useState(false)
  let [productData, setProductData] = useState({})
  let [isBusinesss, setIsBusinesss] = useState(false)
  let [isUP, setIsUP] = useState(true)
  const notify = (text) => toast(text);
  let [btn, setBtn] = useState("Sell Product")
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let Data = {
      client_name: data.get("client_name"),
      address: data.get("address"),
      bill_to: data.get("bill_to"),
      gst_number: data.get("gst_number"),
      product_qty: data.get("product_qty"),
      product_code: data.get("product_code"),
      product_mrp: data.get("product_mrp"),
      product_name: data.get("product_name"),
      product_selling_price: data.get("product_selling_price"),
      hsn_code: data.get("hsn")
    }

    console.log("data", Data, isBusinesss, isUP);
    sellProduct(Data)
  };

  useEffect(() => {
    axios.post(`${serverUrl.u}/v3/affiliateProducts/listAll`).then(
      function (res) {
        console.log(res);
        setDisplayData(res.data.data.products)
      }
    ).catch(function (err) {
      console.log(err);
    })
  }, [apiCall])

  const sellProduct = (Data) => {
    notify("Request Send")
    setBtn("Processing...")
    let detail = {
      generatedHex:"hex",
      name: Data.client_name,
      address: Data.address,
      amount: (Number(Data.product_selling_price) * Number(Data.product_qty)) * 1.18,
      BillTo: Data.bill_to,
      additionalInfo: [{
        item_total: Number(Data.product_selling_price) * Number(Data.product_qty),
      }],
      Is_Business: isBusinesss,
      BusinessGst: Data.gst_number,
      In_Up: isUP,
      productName: Data.product_name,
      product: [{
        P_Id: Data.product_code,
        sellingPricePerPic: Number(Data.product_selling_price),
        mrpPricePerPic: Number(Data.product_mrp),
        qty: Data.product_qty,
        hsn_code: Data.hsn_code,
        productName: Data.product_name
      }],
      hsn_code: Data.hsn_code,
      is_UpSell: true,
    }
    axios.post(`${serverUrl.u}/api/tele/createInvoice`, detail).then(
      function (res) {
        console.log(res);
        if (res.status === 200) {
          notify("Data Saved Sucessfully")
          setProductSellingPop(false)
          setProductData({})
          subHistory("Single Product Upsell Created Without Link",detail,"Upsell Created Without Link","Single WithOut Link")

        } else {
          notify("Error occured")
          setBtn("Failed")
        }
      }
    ).catch(function (err) {
      console.log(err);
      notify("Error occured")
      setBtn("Failed")
    })
  }
 
  const productDataFilterAdd = (value,checked)=>{
    console.log(value);
    if(checked===true){
       setProductDataItem([...productDataItem,value])
    }else if(checked===false){
      let newData = productDataItem.filter((e)=>{
        if(e._id!==value._id){
          return e
        }
      })
      setProductDataItem(newData)
    }
  
  }

  useEffect(()=>{
    console.log(productDataItem,"item");
  },[productDataItem])
  return (
    <div style={{padding:"0 4%",marginTop:"4vh"}}>
      <div className='table-container1'>
        <table className="table table-bordered">
          <thead>
            <tr style={{ zIndex: 5 }}>
            {/* <th>Select Product</th> */}
              <th style={{ width: "180px" }}>Product Code</th>
              <th>Product Name</th>
              <th>Product Display Name</th>
              <th>Short Name</th>
             
              <th>Stock</th>
              <th>Description</th>
              <th>E-Commerce</th>
              <th>M.R.P</th>
              <th>Selling Price</th>
              <th>Installation Charges</th>
              <th>Tax Id</th>
              <th>HSN Code</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {

              displayData !== 0 ?

                displayData.map((e, i) => {
                  return (

                    <tr key={i}>
                      {/* <td><input type='checkbox' name='product'  onChange={(event)=>productDataFilterAdd(e,event.target.checked)} value={e}/></td> */}
                      <td><div style={{overflow:"auto"}}>{e.product_code}</div></td>
                      <td>{e.name}</td>
                      <td>{e.display_name}</td>
                      <td>{e.short_name}</td>
                      
                      <td>{e.stock}</td>
                      <td>
                      <div className="hover-wide">
                        {e.description}
                        </div>
                      </td>
                      <td>{e.ecommerceProduct ? "Yes" : "No"}</td>
                      <td>{e.mrp}</td>
                      <td>{e.selling_price}</td>
                      <td>{e.installationCharge}</td>
                      <td>{e.taxId}</td>
                      <td>{e.hsn_code}</td>
                      <td><div style={{ display: "flex", alignItems: "center", justifyContent: "space-between",flexDirection:"column",gap:10 }}>
                      
                        {
                         (!e.ecommerceProduct || userRole?.includes("Super Admin")) ?<ProductEdit e={e} setApiCall={setApiCall} apiCall={apiCall}/>:"Ecommerce Product"
                        }
                
                      </div></td>
                      <td><DeleteProduct data={e}  setApiCall={setApiCall} apiCall={apiCall}/></td>
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
      <ToastContainer />
      {
        productSellingPop === true ?
          <div className="make_quto" style={{ position: "fixed", top: "2%", left: "25%", width: "50%", backgroundColor: "whitesmoke", zIndex: 120 }}>
            <span style={{ position: "absolute", top: "-5px", cursor: "pointer", right: "15px", fontSize: "25px" }} onClick={() => setProductSellingPop(false)}>X</span>
            <form validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
              <div className="row">
                <div className="col-6">
                  <label>Client Name <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="text" required class="form-control" id="client_name" name="client_name" autoComplete="client_name" placeholder="client_name" aria-label="client_name" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="col-6">
                  <label>Address <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="text" required
                      class="form-control" id="address" name="address" autoComplete="current-address" placeholder="address" aria-label="address" aria-describedby="basic-addon1" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label>Bill To <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" id="bill_to" name="bill_to" autoComplete="current-bill_to" placeholder="bill_to" required aria-label="bill_to" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="col-6">
                  <label>Is Business</label>
                  <div class="input-group mb-3">
                    <select type="text" class="form-control" defaultValue="no" onChange={(ele) => { setIsBusinesss(ele.target.value == "yes" ? true : false) }} id="isBusiness" name="isBusiness" autoComplete="current-isBusiness" aria-label="isBusiness" aria-describedby="basic-addon1" >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
              {
                isBusinesss === true ?
                  <div className="row">
                    <div className="col-12">
                      <label>Gst Nunber</label>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control" id="gst_number" name="gst_number" autoComplete="current-gst_number" placeholder="gst_number" aria-label="gst_number" aria-describedby="basic-addon1" />
                      </div>
                    </div>
                  </div> : null
              }
              <div className="row">
                <div className="col-6">
                  <label>Product Name <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" id="product_name" name="product_name" value={productData.name} autoComplete="current-product_name" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="col-6">
                  <label>Product Code <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" value={productData.product_code} id="product_code" name="product_code" autoComplete="current-product_code" aria-label="product_code" aria-describedby="basic-addon1" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label>Product MRP <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="number" onWheel={event => event.currentTarget.blur()} class="form-control" id="product_mrp" name="product_mrp" autoComplete="current-product_mrp" value={productData.mrp} aria-label="product_mrp" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="col-6">
                  <label>Product Selling Price <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="number" onWheel={event => event.currentTarget.blur()} class="form-control" id="product_selling_price" name="product_selling_price" autoComplete="current-product_selling_price" defaultValue={productData.selling_price} aria-label="product_selling_price" aria-describedby="basic-addon1" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label>Quantity <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="number" onWheel={event => event.currentTarget.blur()} class="form-control" id="product_qty" name="product_qty" autoComplete="current-product_qty" defaultValue={1} aria-label="product_qty" aria-describedby="basic-addon1" />
                  </div>
                </div>
                <div className="col-6">
                  <label>Is U.P. Sale</label>
                  <div class="input-group mb-3">
                    <select type="text" class="form-control" defaultValue="yes" onChange={(ele) => { setIsUP(ele.target.value == "yes" ? true : false) }} id="isBusiness" name="isBusiness" autoComplete="current-isBusiness" aria-label="isBusiness" aria-describedby="basic-addon1" >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label>Product HSN <span className="required_star">*</span></label>
                  <div class="input-group mb-3">
                    <input type="number" onWheel={event => event.currentTarget.blur()} class="form-control" id="hsn" name="hsn" defaultValue={productData.hsn_code} autoComplete="current-hsn" placeholder="product_HSN" aria-label="hsn" aria-describedby="basic-addon1" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button className='btn btn-primary' style={{ pointerEvents: `${btn === "Processing..." ? "none" : ""}`, color: `${btn === "Processing..." ? "grey" : ""}` }}>{btn}</button>
                </div>
              </div>
            </form>
          </div> : null
      }

    </div>
  )
}
