import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import './UpdateHouseIdDevices.css'
import { useSelector } from 'react-redux'
import { subHistory } from '../Function/SubscriptionHistory'
import { Button } from '@mui/material'
export default function UpdateHouseIdDevices({ e, notify, reload, setReload }) {
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let [forms, setForm] = useState(false)
  let [selectDeciceId,setSelectDeciceId] = useState(true)
  let [btn,setBtn] = useState("Update")
  const handleSubmit = async (event) => {
    setBtn("Processing...")
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let deviceData = {
      deviceId: data.get('deviceId'),

    }
    console.log(deviceData, "deviceData");
    await axios.post(`${serverUrl.u}/api/tele/updateHouseIdAndDevices`, {
      generatedHex: e.generatedHex,
      deviceId: selectDeciceId,
      id: deviceData.deviceId,
    }).then(function (res) {
      console.log(res)
      if (res.data.success === true) {
        notify("Data Saved Successfully");
        setForm(false)
        subHistory('House Id Updated', {...e,payload:{
          deviceId: selectDeciceId,
          id: deviceData.deviceId,
        }}, "House Id Updated", "")
        setReload(!reload);
        setBtn("Update")
      } else {
        notify(res.data.message);
        setBtn("Failed")
      }
    }).catch(function (error) {
      console.log(error);
      notify("Failed");
      setBtn("Failed")
    })
  }
  return (
    <>
      <button className='btn btn-primary' onClick={() => setForm(true)}>Update HouseId & DeviceId</button>
      {
        forms === true ?
          <div className="Installed-final-popup update-id" style={{ top: "20%",zIndex:40 }}>
            <span style={{ width: 0, position: "absolute", right: "10%", top: "5px", fontSize: "16px", cursor: "pointer" }} onClick={() => setForm(false)}>X</span>
            <form onSubmit={handleSubmit}>
              <label>Update By DeviceId/House id?</label>
              <div className="input-group ">
                <select className="custom-select height-select mb-2"
                  id="deviceType"
                  name="deviceType"
                  autoComplete="deviceType"
                  defaultValue={selectDeciceId}
                  onChange={(e)=>setSelectDeciceId(e.target.value==="true"?true:false)}
                >
                  <option value={true}>Decive Id</option>
                  <option value={false}>House Id</option>
                </select>
              </div>
              <label>{selectDeciceId===true?"Device Id":"House id"}</label>
              <div className="input-group mb-3">
                <input type="text"
                  className="form-control"
                  id='deviceId'
                  name="deviceId"
                  autoComplete="current-deviceId"
                  placeholder={selectDeciceId===true?"Device Id":"House Id"}
                  aria-label="deviceId"
                  defaultValue={selectDeciceId===true?"":(e.houseId!==""?e.houseId:"Enter House Id")}
                  required
                  aria-describedby="basic-addon1" />
              </div>

              <Button varient="contained" className="btn btn-primary" type='submit'>{btn}</Button>
            </form>
          </div>
          : null
      }
    </>
  )
}
