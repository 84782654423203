import React, { useState } from 'react'
import './PaymentHistory.css'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { cleanNumber } from '../Function/AxiosControl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainCard from './MainCard'
import Spinner from '../Function/Spinner'
const PaymentHistory = () => {

  const [phone, setPhone] = useState({ phone: '' })
  const [data,setData] = useState([]);
  const [spinner, setSpinner] = useState(false)
  const handleChange = (e) => {
    setPhone({ ...phone, [e.target.name]: e.target.value })
  }
  const getPaymentHistory = async () => {
    setSpinner(true)
    let phone1 = cleanNumber(phone.phone);
    console.log('phone', phone1);
    await axios.post(`${serverUrl.u}/api/subscription/paymentHistoryByPhone`, { phone: phone1 })
      .then((response) => { setSpinner(false);setData(response.data.finalData); console.log('res',response.data.finalData) })
      .catch((error) => { notify(error.response.data.message); console.log('error', error.response.data.message) })
  }
  const notify = (text) => toast(text);
  return (
    <div>

      <div class="input-group mb-3  textInput">
        <input type="text" class="form-control textInput" placeholder="Enter Phone Number" aria-label="Phone" name='phone' aria-describedby="basic-addon1" onChange={handleChange} />
        <button type="button" class="btn btn-primary" onClick={() => getPaymentHistory()}>Search</button>
      </div>
      <div>
        {
          console.log('data',data)
        }
        {
          data.length >0?<MainCard data={data}/>:null
        }
        {
          spinner?<Spinner/>:null
        }
        
      </div>
      <ToastContainer />
    </div>
  )
}

export default PaymentHistory