import React from 'react'
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { useSelector } from 'react-redux';
import { subHistory } from '../Function/SubscriptionHistory';
export default function HouseDeviceActivateDeactivate({e,reload,SetReload,notify}) {
  let loginName = useSelector((state) => state.sar.data.user.first_name)
    const ActivateHouse = async(id)=>{
        await axios.post(`${serverUrl.u}/v2/house/activate`,{
          houseId:id,
        }).then(function (res) {
          console.log(res);
          notify(res.data.message)
          subHistory("House Activated",e,"House Active/Deactive","House Activated")
          SetReload(!reload)
        }).catch(function (err) {
          console.log(err);
          notify("Error")
        })
     }

     const DectivateHouse = async(id)=>{
        await axios.post(`${serverUrl.u}/v2/house/deactivate`,{
          houseId:id,
        }).then(function (res) {
          console.log(res);
          notify(res.data.message)
          subHistory("House Deactivated",e,"House Active/Deactive","House Deactivated")
          SetReload(!reload);
        }).catch(function (err) {
          console.log(err);
          notify("Error")
        })
      }
  return (
    <>
      {
                      e.houseId!==""?
                      <>
                      { 
                      e.active===false ?
                      <button className="btn btn-primary" onClick={()=>ActivateHouse(e.houseId)} style={{backgroundColor:"#28a745"}}>Devices Activate</button>:
                      <button className="btn btn-primary" onClick={()=>DectivateHouse(e.houseId)} style={{backgroundColor:"#ec0909c7"}}>Devices Deactivate</button>
                      }
                      </>:null
                    }
    </>
  )
}
