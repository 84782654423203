import axios from 'axios'
import React from 'react'
import { pages, serverUrl } from '../ApiUrl'
import { Button } from '@mui/material'
import { notify, notifyFailed } from '../Common/Notification'
import { subHistory } from '../Function/SubscriptionHistory'
import { updateInstallationData } from '../Redux/installationActions'
import { useDispatch } from 'react-redux'
import store from '../Redux'

export const revokeReInstallation = (sub,page,reload,setReload)=>{
    axios.post(`${serverUrl.u}/api/subscription/revokeReeInstallation`,{
        generatedHex:sub.generatedHex
    }).then((res)=>{
        if(res.data.success){
            subHistory("Remove Site From ReInstallation",sub,"Revoke Installation",page)
            if(pages.installation===page){
               store.dispatch(updateInstallationData(res.data.data.subscription))
            }else if(pages.search===page){
                setReload(!reload)
            }
            notify(res.data.message)
        }else{
            notifyFailed(res.data.message)
        }
    }).catch((err)=>{
        notifyFailed(err.response.data.message)
    })
}
function RevokeReInstallation({sub,reload,setReload,page}) {
 

    
  return (
     <div>
        <Button 
          variant='contained'
          onClick={()=>revokeReInstallation(sub,page,reload,setReload)}
          size='small'
        >Re-Voke Installation</Button>
     </div>
  )
}

export default RevokeReInstallation