
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { useSelector } from 'react-redux'
import { subHistory } from '../Function/SubscriptionHistory'
import { Button } from '@mui/material'

export default function AssignAccordingTimeZone({data,notify,name,reload,setReload}) {
    let [pop,setPop] = useState(false)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    let [electric,setElectrician] = useState([])
    const handleSubmit = (event)=>{
        event.preventDefault();
        const datas = new FormData(event.currentTarget);

        let employeeIds = datas.get('electician')
        console.log(employeeIds,"id");
        let ele = electric.filter(e=>employeeIds===e.employeeId)
        console.log(ele,"ele");
        const assignedElectritian = ()=>{
            axios.post(`${serverUrl.u}/api/dispatch/assignElectrician`,{
                "generatedHex":data.generatedHex,
                "electrician":[{"name":ele[0].firstName,"Id":ele[0].employeeId}]
            }).then(function (res) {
                 console.log(res);
                 if(res.data.success===true){
                    notify(res.data.message)
                    setReload(!reload)
                    subHistory(`Add more Electrician in site by ${loginName}`,data,"Add Electrician by Electrician in site",`New Electrician Name :- ${ele[0].firstName} and Id :- ${ele[0].employeeId}`)
                    setPop(false)
                 }else{
                    notify(res.data.message)
                 }
            }).catch(function (error) {
                 console.log(error);
                 notify("Failed")
            })
        }
        assignedElectritian()
    }
    useEffect(()=>{
        axios.get(`${serverUrl.u}/api/tele/getAllElectrician`).then(function (res) {
            console.log(res);
            if(res.data.success===true){
               console.log(res.data.data,"dd");
             let electrician = res.data.data.filter((e)=>{
                     if(e.zone.includes(data.city.toUpperCase()) && employeeId!==e.employeeId){
                        return e
                     }
               })
               setElectrician(electrician)
            }
         }).catch(function (err) {
             console.log(err);
         })
    },[])
  return (
    <>
      <Button onClick={()=>setPop(true)} variant="contained" className='w-100' color="success">Add Electrician</Button>

      {
        pop===true?
        <div style={{position:"fixed",top:0,left:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100vw",height:"100vh"}}>
            <div style={{width:"90%",padding:"10px",backgroundColor:"whitesmoke",borderRadius:"12px",zIndex:4,height:"fit-content"}}>
                <div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
                    <span style={{cursor:"pointer"}} onClick={()=>setPop(false)}>X</span>
                </div>
                <form onSubmit={handleSubmit}>
                <div style={{display:"flex",flexDirection:"column"}} className='mb-2'>
               <label for="inputState" class="form-label">Name</label>
               <select id="inputState" class="form-select" name='electician' autoComplete='current-electician'>
                    <option selected>Choose...</option>
                    {
                        electric && electric.map((e)=>{
                            return (
                                <option value={e.employeeId}>{e.firstName}</option>
                            )
                        })
                    }
               </select>
            </div>
            <Button type='submit' variant="contained">Submit</Button>
            </form>
            </div>
        </div>:null
      }
    </>
  )
}
