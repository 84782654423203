import React, { useState } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainCard from '../PaymentHistory/MainCard'
import Spinner from '../Function/Spinner'
const ClientJoureny = ({ e }) => {

    const [data, setData] = useState([]);
    const [spinner, setSpinner] = useState(false)

    const getPaymentHistory = async () => {
        setSpinner(true)

        await axios.post(`${serverUrl.u}/api/subscription/paymentHistoryByPhone`, { phone: e.phone })
            .then((response) => { setSpinner(false); setData(response.data.finalData); console.log('res', response.data.finalData) })
            .catch((error) => { notify(error.response.data.message); console.log('error', error.response.data.message) })
    }
    const notify = (text) => toast(text);
    return (
        <div>
            <div className="input-group mb-3">

                <button className="btn btn-primary" onClick={() => getPaymentHistory()}>Client Payment History</button>
            </div>
            <div>
                {
                    data.length > 0 ?    <div style={{position:"fixed",top:"0",left:0,overflowY:"scroll",height:"100vh",width:"100vw",zIndex:4}}>
                    <div style={{
                        padding:"10px",
                        backgroundColor:"whitesmoke",
                        borderRadius:"12px",
                        width:"86%",
                        position:"relative",
                        left:"6%",
                        top:"12%",
                    }}>    
                    <div style={{width:"100%",textAlign:"right"}}>
                        <span style=
                               {{cursor:"pointer",
                                 marginRight:"30px"}}
                              onClick={()=>setData([])}
                        >X</span>
                    </div>
                    <MainCard data={data} />
                    </div>
                    </div> : null
                }
                {
                    spinner ? <Spinner /> : null
                }

            </div>
            <ToastContainer />
        </div>
    )
}

export default ClientJoureny