import axios from "axios"
import { serverUrl } from "../ApiUrl"
import { upgradeRequestAction } from "./actionIndex";
const upgradeRequest=(upgradeRecord,totalPage)=>{
    console.log(upgradeRecord,"action","coming");
    return{
        type:upgradeRequestAction.All_Request_Upgrade,
        payload:{
            upgradeRecord,totalPage
        }
    }
}
export const upgradeRequestEmpty=(upgradeRecord)=>{
    
    return{
        type:upgradeRequestAction.All_Request_Empty_Upgrade,
        payload:upgradeRecord
    }
}
export const upgradeRequestEmptyAll=()=>{
    console.log("ALL ALL");
    return{
        type:upgradeRequestAction.All_Request_Empty_Upgrade_All,
        payload:{}
    }
}
const upgradePendingRequest=(upgradeRecord,totalPage)=>{
    return{
        type:upgradeRequestAction.All_Pending_Upgrade,
        payload:{
            upgradeRecord,totalPage
        }
    }
}
export const upgradePendingEmptyRequest=(upgradeRecord)=>{
    return{
        type:upgradeRequestAction.All_Pending_Empty_Upgrade,
        payload:upgradeRecord
    }
}
export const upgradePendingEmptyRequestAll=()=>{
    console.log("Ac Ac");

    return{
        type:upgradeRequestAction.All_Pending_Empty_Upgrade_All,
        payload:{}
    }
}

export const upgradeRequestApi = (page,notify,dispatch) =>{
    
    axios.get(`${serverUrl.u}/app/req/getAllRequest/?p=${page-1}`).then(
     function (res) {
         console.log(res);
         const {message,success,data} = res.data;
         if(success===true){
         dispatch(upgradeRequest(data.allRequest,data.totalPage))
         notify(message)
        }else{
         notify(message)
        }
     }
    ).catch(function (err) {
      console.log(err);
      notify("Error Ocurred")
    })
}

export const upgradePendingRecords = (page,notify,dispatch) =>{
 
 axios.get(`${serverUrl.u}/app/req/getPendingRequest/?p=${page-1}`).then(
  function (res) {
      console.log(res.data);
      const {message,data,success} = res.data;
      if(success===true){
         console.log(data.refundRecord,"if");
      dispatch(upgradePendingRequest(data.allRequest,data.totalPage))
      notify(message)
     }else{
      notify(message)
     }
  }
 ).catch(function (err) {
   console.log(err);
   notify("Error Ocurred")
 })
}