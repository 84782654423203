import axios from 'axios'
import moment from 'moment'
import React, { useRef } from 'react'
import { serverUrl } from '../ApiUrl'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirstandLastDate } from '../Common/applianceDifference'
import { notifyFailed } from '../Common/Notification'

export default function TeleCallerIncentive() {
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    let startDate = useRef(new Date())
    const notify = (text) => toast(text);
    let endDate = useRef(new Date())
    let [data, setData] = useState([])

    const getIncentive = () => {
        let gg = new Date(startDate.current.value)
        let data = getFirstandLastDate(startDate.current.value)
        setData([])

        axios.post(`${serverUrl.u}/api/tele/calculateIncentive`, {
            "teleCallerId": "All",
            "firstDate": gg,
            "lastDate": data.lastDay
        }).then(function (res) {

            if (res.data.success === true) {
                notify(res.data.message)
                setData(res.data.data.incentiveArray)
            } else {
                notifyFailed(res.data.message)
                setData([])

            }
        }).catch(function (err) {
            console.log(err);
        })
    }
    return (
        <div style={{ padding: "0 1%", marginTop: "4vh" }}>
            <ToastContainer />
            <div className='row'>

                <div className="col-md-2 col-sm-5">
                    <label className="create-label1">Month</label>
                    <div class="input-group ">
                        <input type="month" ref={startDate} style={{ width: "100%" }} />
                    </div>
                </div>

                <div className="col-md-1 col-sm-5">
                    <button className='btn btn-primary mt-3' onClick={() => getIncentive()}>Get Data</button>
                </div>


            </div>
            {data.length !== 0 && (data.map((main, i) => {
                let name = Object.keys(main)[0]
                if(name==="website" || name==="WhatsApp") {
                    return
                }
                return (<div className='table-container1' style={{ marginTop: "20px" }}>
                    <div className='ml-4'>{name} <br/> Incentive Amount:-{main["incentiveAmount"]} <br/>  TotalAppliances:-{main["totalAppliances"]}</div>
                    <table className="table table-sm table-bordered ">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th scope="col">Client Name</th>
                                <th>Phone</th>
                                <th>Site Type</th>
                                <th>Total Appliances</th>
                                <th>Sales Person</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length !== 0
                                ? main[name].map((e, i) => {
                                   
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{e.name}</td>
                                            <td>{e.phone}</td>
                                            <td>{e["site Type"]}</td>  
                                            <td>{e.totalAppliances}</td>          
                                            <td>{e.salesExecutive}</td>                           
                                        </tr>
                                    );
                                })
                                : null}
                        </tbody>
                    </table>

                </div>)
            }))
            }
            <div style={{ marginBottom: "60px", height: "60px" }}></div>

        </div>
    )
}
