import React, {  useState,useEffect } from 'react'
import { useSelector ,useDispatch} from "react-redux";
import RoomHome from './RoomHome';
import axios from 'axios';
import { useNavigate,useLocation} from 'react-router-dom';
import { switchBoardAddNew,switchBoardEmpty,deleteRoom ,defaultData } from "../Redux/action";
import {serverUrl} from "../ApiUrl"

export default function RoomDisplay() {
  let [open, setOpen] = useState(false);
  let [name,setName] =useState("Add");
  
  let [changeData,setChangeData] = useState()
  
  let [layoutDecision,setLauoutDecision]= useState([]);
  const location = useLocation()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [quantitys,setQuantity] = useState()
  let [paths,setPath] = useState("")
  const navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(()=>{
    const { from,path} = location.state
    console.log("from   ",from);
    setLauoutDecision(from)
    setPath(path)
  },[])
    
    let dataState = useSelector((state) => {
        return state.switchBoardsSave; 
      });    
      
   useEffect(()=>{
    if(layoutDecision.length===0)return
      dispatch(defaultData(layoutDecision.houseStructure))
   },[layoutDecision])
     console.log(dataState);
     console.log("layout  =  ",layoutDecision.houseStructure);
      
    const dataUpdate = ()=>{
      console.log("call update");
      if(dataState.length===0) return
      console.log("call");
      
      let data2 = async () => {
      
        await axios.post(
          `${serverUrl.u}/api/dispatch/updateSwitchboardInfo`,
          {
            subId :layoutDecision._id,
            layout:dataState,
          }
        ).then(function (response) {
          console.log(response);
    
          dispatch(switchBoardEmpty())
          navigate(`/${paths}`)
        }).catch(function (error) {
          console.log(error);
        })
    }
  
    if(quantitys<=layoutDecision.totalAppliances){
      console.log("Quan   =  ",quantitys,"  Desc  =  ",layoutDecision.totalAppliances);
      console.log("dada");
      data2()

    }else if(quantitys!==layoutDecision.totalAppliances){
      alert("Enter more than allowed devices")
    }
    }
   
    useEffect(()=>{
      const countAppliances=async(items)=>{
        var quantity = 0 
    
        console.log("inner ",items);
        for (var roomLevel in items) {
          var roomName = items[roomLevel]
          console.log("RoomName  ",roomName);
          var switchLevel = roomName.switchboards
          for (var switchh in switchLevel) {
            var switchboard = switchLevel[switchh]
            console.log("Switchboard     =  ",switchboard);
            var { fans, light, ac, socket, geyser, tv , other,ir} = switchboard.appliances;
            quantity = quantity + fans + light + ac + socket + geyser + tv + other + ir
          }
        }
        console.log(quantity);
        setQuantity(quantity)
        if(quantity===layoutDecision.totalAppliances){
          console.log("Quan   =  ",quantitys,"  Desc  =  ",layoutDecision.totalAppliances);
          
          console.log("dada11");
          

        }else if(quantity>layoutDecision.totalAppliances){
          alert("Enter more than allowed devices")
        }
      }
      countAppliances(dataState)
      console.log("final Data  = ",dataState);
    },[dataState])
   
  return (
    <>
    <div className="addRoom-title-cont">
        <div className="addRoom-title-inner" >
          <span
            style={{
              fontWeight: "700",
              fontSize: "36px",
              LineHeight: "49.18px",
            }}
          >
            Add the rooms
          </span>
          <br />
          <span
            style={{
              fontWeight: "400",
              fontSize: "36px",
              LineHeight: "49.18px",
            }}
          >
            in your home
          </span>
        </div>
      </div>

      <div className="add-room-details-container" style={{minHeight:"75vh"}}>
     {dataState?.length !== 0
          ?dataState.map((e, i) => {
            
              return (
                <div key={i}
                  className="addRoom-room-detail"
                  rid={e.roomId}
                >
                  <img
                    className="addroom-lamp-icon"
                    src={process.env.PUBLIC_URL + "/assets/icon/Group.png"}
                    alt="lamp"
                  ></img>
                  <span className="addroom-room-name">{e.roomName}</span>
                  <button className='btn btn-primary room-edit' onClick={()=>{
                    setChangeData(i)
                    setName("Edit");
                    handleOpen();
                  }}>Edit</button>
                  <button className='btn btn-danger room-delete' onClick={()=>dispatch(deleteRoom(e))}>Delete</button>
                </div>
              );
            })
          : null}
          <div className="addRoom-room-cont2">
          <div
            className="addRoom-room-cont1"
            onClick={() => {
              dispatch(switchBoardAddNew([]))
              setName("Add")
              handleOpen();
            }}
          >
            <img
              className="addroom-plus-icon"
              src={process.env.PUBLIC_URL + "/assets/icon/Frame.png"}
              alt="lamp"
            ></img>
            <span className="addroom-add-room-name">ADD ROOM</span>
          </div>
        </div>
          </div>
          {/* model */}

          <RoomHome open={open}  handleClose={handleClose}  name={name} changeData={changeData} layoutDecision={layoutDecision}/>
          {dataState?.length > 0 ? (
            <div className='go-back'>
        <div className="addroom-bottom-container" onClick={()=>dataUpdate()}>
          <span className="add-room-detailes">Done</span>
        </div>
        </div>
      ) : null}

    </>
  )
}
