import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import axios from 'axios'
import Qutoes from '../Qutoes'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ProductEdit({e, setApiCall, apiCall}) {
    let [pop,setPop] = useState(false)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    const notify = (text) => toast(text);
    let [files,setFiles] = useState([])
    const handleSubmit = async(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append("deleteMedia", e.media?e.media:[]);
        data.append("productId",e._id);
        data.append("category","general");
        data.append("createdBy",loginName);
        // data.append("ecommerceProduct",data.get("ecommerceProduct")==="true"?true:false);
        let formData = new FormData();
        // console.log(files,"file");
        // for(let i=0;i<files.length;i++){
        //     console.log(files[i], i);
        //     data.append(`photos`,files[i])
        // };
       
        // console.log(formData.forEach(k => {
        //     console.log('form entry', k, formData.getAll('photos'))
        // }));
        let Data = {
            product_code: data.get("product_code"),
            name: data.get("name"),
            display_name: data.get("display_name"),
            short_name: data.get("short_name"),
            description: data.get("description"),
            stock: Number(data.get("stock")),
            mrp: Number(data.get("mrp")),
            selling_price: Number(data.get("selling_price")),
            hsn_code:data.get("hsn_code"),
            ecommerceProduct:data.get("ecommerceProduct")==="true"?true:false,
            createdBy:loginName,
            productId:e._id,
            deleteMedia: [],
            category:'general',
            installationCharge:data.get('installationCharge')
          }
          createProduct(data)
      };
      const handleFile = (e)=>{
           setFiles(e.target.files)
      }
      
    const createProduct = async(data)=>{
        await axios.post(`${serverUrl.u}/v3/affiliateProducts/editProduct`,data
        ).then(function (res) {
            console.log(res);
            if(res.data.success===true){
              if(res.data.data.updateProduct.success){
                setApiCall(!apiCall)
                notify("Product Updated")
                setPop(false)
              }else{
                notify("Failed")
              }
            }else{
                notify("Failed") 
            }
        }).catch(function (err) {
            console.log(err);
            notify(err.response.data.message)
        })

    }
  return (
    <>
      <button className='btn btn-primary' onClick={()=>setPop(true)}>Producr Edit</button>
{
    pop===true?
     <div style={{ position: "fixed", height: "100vh", width: "100vw", display: "flex", overflow: "scroll", justifyContent: "center", alignItems: "center", top: 0, left: 0,zIndex:4,overflow:"auto"}}>
      <ToastContainer/>
      <div  style={{position:"relative",top:"20px", width:"60vw", backgroundColor: "lightGray", padding: "10px 15px", borderRadius: "12px" ,margin:"50px 0px",marginTop:"70px"}}>
      <div style={{width:"100%",position:"relative",height:"35px"}}>
                <span 
                  style={{
                    position:"absolute",
                    right:"10px",
                    cursor:"pointer",
                    width:"fit-content",
                    fontSize:"22px"
                  }}
                  onClick={()=>{
                          setPop(false)
                      }}>X</span>
              </div>
      <form  validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
          <div className="row">
             <div className="col-6">
             <label>Product Code <span className="required_star">*</span></label>
                 <div class="input-group mb-3">
                    <input type="text" class="form-control" id="product_code" name="product_code" value={e.product_code} autoComplete="product_code"  required placeholder="product_code" aria-label="product_code" aria-describedby="basic-addon1" />
                 </div>
              </div>
              <div className="col-6">
              <label>Product Name</label>
                <div class="input-group mb-3">
                   <input type="text" class="form-control" id="name" name="name" defaultValue={e.name} autoComplete="current-name" placeholder="product_name" aria-label="name" aria-describedby="basic-addon1" />
                </div> 
              </div>
          </div>  

          <div className="row">
             <div className="col-6">
                <label>Product Display Name <span className="required_star">*</span></label>
                <div class="input-group mb-3">
                   <input type="text" class="form-control" defaultValue={e.display_name} id="display_name" name="display_name" autoComplete="current-display_name" placeholder="display_name" aria-label="product_display_name" aria-describedby="basic-addon1" />
                </div>
             </div>
            <div className="col-6">
              <label>Product Short Name <span className="required_star">*</span></label>
              <div class="input-group mb-3">
                   <input type="text" class="form-control" defaultValue={e.short_name} id="short_name" name="short_name" autoComplete="current-short_name" placeholder="product_short_name" aria-label="product_short_name" aria-describedby="basic-addon1" />
                </div>
            </div>    
          </div>

          <div className="row">
                <div className="col-6">
                    <label>Product Discription <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                         <input type="text" class="form-control" defaultValue={e.description}  id="description" name="description" autoComplete="current-description"  placeholder="product_description" aria-label="product_description" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
                    <label>Stock <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                        <input type="number" defaultValue={e.stock}  onWheel={ event => event.currentTarget.blur() } class="form-control"  id="stock" name="stock" autoComplete="current-stock"   placeholder="0" aria-label="stock" aria-describedby="basic-addon1" />
                    </div>
                </div>
             </div>  

             <div className="row">
                <div className="col-6">
                    <label>Product MRP <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                         <input type="number" defaultValue={e.mrp}  onWheel={ event => event.currentTarget.blur() } class="form-control"  id="mrp" name="mrp" autoComplete="current-mrp"  placeholder="product_mrp" aria-label="product_mrp" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
                    <label>Product Selling Price <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                        <input type="number"  onWheel={ event => event.currentTarget.blur() } class="form-control"  id="selling_price" defaultValue={e.selling_price} name="selling_price" autoComplete="current-selling_price"   placeholder="product_selling_price" aria-label="product_selling_price" aria-describedby="basic-addon1" />
                    </div>
                </div>
             </div> 
             <div className="row">
                <div className="col-6">
                    <label>Product HSN <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                         <input type="number"  onWheel={ event => event.currentTarget.blur() } class="form-control" defaultValue={e.hsn_code}  id="hsn_code" name="hsn_code" autoComplete="current-hsn_code"  placeholder="product_HSN" aria-label="hsn" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
              <label>Is Ecommerce</label>
              <div class="input-group mb-3">
                   <select type="text" class="form-control"  defaultValue={e.ecommerceProduct+""} id="ecommerceProduct" name="ecommerceProduct" autoComplete="current-ecommerceProduct"  aria-label="ecommerceProduct" aria-describedby="basic-addon1" >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                </div>
            </div> 
             </div> 
             <div className="row">
             <div className="col-6">
             <label>Product CreateBy<span className="required_star">*</span></label>
                    <div class="input-group mb-3">
             <input type="text"   class="form-control"  id="createdBy" name="createdBy" autoComplete="current-createdBy"  placeholder="createdBy" disabled defaultValue={loginName} aria-label="hsn" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
                <label>Image Upload (max 5)</label>
              <div class="input-group mb-3">
                   <input type="file" multiple onChange={handleFile} class="form-control" id="ecommerceProductImg" name="files" autoComplete="current-files"  aria-label="files" aria-describedby="basic-addon1" />
                      
                
                </div>
                </div> 
             </div>    
             <div className='row'>
             <div className="col-6">
             <label>Installation Charges<span className="required_star">*</span></label>
                    <div class="input-group mb-3">
             <input type="number"   class="form-control"  id="installationCharge" name="installationCharge" autoComplete="current-installationCharge"  placeholder="Installation Charges" defaultValue={0} min={0} aria-label="installationCharge" aria-describedby="basic-addon1" />
                    </div>
                </div>
             </div> 
              <div style={{display:"flex",flexWrap:"wrap",gap:10,marginBottom:"10px"}}>
                {
                   e.media && e.media.map(img=>{
                    return(
                        <div style={{height:"100px",width:"100px"}}>

                            <img style={{height:"100px",width:"100px"}} src={`${serverUrl.aws}${img.substring(6)}`}/>
                        </div>
                    )
                   })
                }
               </div>  
             <div className="row">
                <div className="col-6">
                    <button className='btn btn-primary'>Update Product</button>
                 </div>
             </div>                       
        </form>  
      </div>
      </div>:null
}
    </>
  )
}
