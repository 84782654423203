import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid } from '@mui/material';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Qutoes from "./Qutoes";
import { serverUrl } from './ApiUrl'
import { WhatsApp } from "@mui/icons-material";
import { dummy, SeurityInstallion, plan, planDuration, PartialPaymentModes, planCost, ElectricianIncentiveZone } from './Data'
import { useSelector } from "react-redux";
import { getFilledDetail } from "./Function/AxiosControl";
import { ToastContainer, toast } from "react-toastify";
import { createAndDownloadPdf } from "./Function/DownloadQuto";
import { useRef } from "react";
import { subHistory } from "./Function/SubscriptionHistory";
import 'react-toastify/dist/ReactToastify.css';
import { ci } from "./city/Cities";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SelectCityInput from "./city/SelectCityInput";

export default function CreateLead({ dataEdit, wholeReload }) {
  let userRole = useSelector((state) => state.sar.data?.user?.roles)
  let [reloadLink, setReloadLink] = useState(false)
  let [getData, setGetData] = useState([]);
  let [stateN, setStateN] = useState(dataEdit?.city);
  let [planD, setPlanD] = useState(dataEdit?.planFrequency);
  let [okBtn, setOkBtn] = useState(false)
  let [partialD, setPartialD] = useState("true");
  let [qutoBtnText, setQutoBtnText] = useState("Download")
  let [alreadyRegister, setAlreadyRegister] = useState(false)
  let to;
  const notify = (text) => toast(text);
  let stopScroll = useRef(null);
  let [paymentMode, setPaymentMode] = useState("razorpay")
  let [userName, setUserName] = useState()
  let [planN, setPlanN] = useState(1);
  let [planNa, setPlanNa] = useState((dataEdit?.houseStructure) ? dataEdit?.houseStructure[0]?.roomName : "1 Room");
  let [name, setName] = useState(dataEdit?.name);
  let [userData, setUserData] = useState({});
  let [check, setCheck] = useState(false);
  let [ok, setok] = useState(false)
  let [paymentLink, setPaymentLink] = useState("")
  let [errorsApi, setErrorApi] = useState(false);
  const [text, setText] = useState("");
  let [whatappNumber, setWhatappNumber] = useState()
  let [teleCallerSecondName, setTeleCallerSecondName] = useState("")
  let [teleCallerFirstName, setTeleCallerFirstName] = useState("")
  let token = useSelector((state) => state.sar.data?.token)
  let teleCallers = useSelector((state) => state.sar.data.user?.first_name)
  let [install, setInsall] = useState({
    security: 400,
    Installion: 1000,
    quantity: 4
  })



  useEffect(() => {
    if (planNa === "Custom" || (getData?.plan === 0)) {
      setPlanN(0)
      setPlanNa("Custom")

    } else if (planNa === "1 Room" || (getData?.plan === 1)) {
      setPlanN(1)
      setPlanNa("1 Room")
    } else if (planNa === "1 BHK" || (getData?.plan === 2)) {
      setPlanN(2)
      setPlanNa("1 BHK")
    } else if (planNa === "2 BHK" || (getData?.plan === 3)) {
      setPlanN(3)
      setPlanNa("2 BHK")
    } else if (planNa === "3 BHK" || (getData?.plan === 4)) {
      setPlanN(4)
      setPlanNa("3 BHK")
    } else if (planNa === "4 BHK" || (getData?.plan === 5)) {
      setPlanN(5)
      setPlanNa("4 BHK")
    }
    else if (planNa === "5 BHK" || (getData?.plan === 6)) {
      setPlanN(6)
      setPlanNa("5 BHK")
    } else if (planNa === "6 BHK" || (getData?.plan === 7)) {
      setPlanN(7)
      setPlanNa("6 BHK")
    }
    if (getData.length === 0) return
    let Installion;
    if (getData.quantity > 10) {
      let cal = getData.quantity - 10;
      Installion = 1000 + (cal * 50);
    } else {
      Installion = 1000;
    }
    setInsall({
      security: getData.quantity === 0 ? 400 : getData.quantity * 100,
      Installion: Installion,
      quantity: getData.quantity === 0 ? 4 : getData.quantity
    })
  }, [getData])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let userData2 = {
      first_name: data.get("fname"),
      last_name: data.get("lname"),
      phone: data.get("phoneNumber"),
      city: data.get("city"),
      address: data.get("address"),
      planType: data.get("planDuration"),
      plan: data.get("plan"),
      origin: data.get("source"),
      leadQuality: data.get("lead_quality"),
      email: data.get("email"),
      zip: data.get("zipcode"),
      comments: data.get("comments"),
      security: data.get("security"),
      installion: data.get("install"),
      teleCallerId: data.get("teleCallerFirst"),
      refferal: data.get("refferal"),
      partialPayment: data.get("Partial"),
      teleCallerSecond: data.get("teleCallerSecond"),
      quantityAppliances: data.get("appliance"),
      partialAmount: data.get("partial"),
      paymentMode: data.get("paymentMode"),
      reference_id: data.get("referenceId"),
      heavyAppliances: Number(data.get('heavy')),
      incentiveZone:data.get("incentiveZone")
    }

    let seq = Number(userData2.security);
    let ins = Number(userData2.installion);
    let qu = Number(userData2.quantityAppliances)
    let planDurations = userData2.planType;
    let fee = 0;
    let addon = 0

    if (planN === 0) {
      qu = Number(userData2.quantityAppliances)
    } else {
      qu = install.quantity;
    }
    if (planDurations === "Monthly") {
      fee = (qu * planCost.Monthly) * 30
    } else if (planDurations === "Quarterly") {
      fee = (qu * planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
      fee = (qu * planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
      fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
      fee = (qu * planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
      fee = (qu * planCost.TwoYears) * (365 * 2);
    } else if (planDurations === "Three Years") {
      fee = (qu * planCost.ThreeYears) * (365 * 3);
    }

    let total = ((fee + ins + addon) * 1.18 + seq).toFixed(2)
    setCheck(!check);
    let tax = ((fee + ins + addon) * 0.18).toFixed(2)
    setUserData({ ...userData2, totalAppliances: qu })
    userData2 = { ...userData2, totalAppliances: qu, orignalTotalAmount: userData2.paymentMode === "RazorPayFullPayment" ? Math.round(total) : total, totalAmount: userData2.paymentMode === "RazorPayFullPayment" ? Math.round(total) : total, orignalRecursiveAmount: fee, recursiveAmount: fee, tax: userData.paymentMode === "RazorPayFullPayment" ? Math.round(tax) : tax }
    paymentLinkGen(userData2, addon)
  };

  useEffect(() => {
    if (planN === 0) return;
    setInsall(SeurityInstallion[planN - 1])
  }, [planN])


  const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    setQutoBtnText("Download")
    // in case the Blob uses a lot of memory
    // setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };
  const quotationApi = async (data, userData, addon) => {
    let name = `${userData.first_name} ${userData.last_name}`
    console.log(data, 'quote num');
    let seq = Number(userData.security);
    let ins = Number(userData.installion);
    let qu = Number(install.quantity);
    let planNamess = install.planName
    let planDurations = userData.planType;
    let fee;
    if (planDurations === "Monthly") {
      fee = (qu * planCost.Monthly) * 30
    } else if (planDurations === "Quarterly") {
      fee = (qu * planCost.Quarterly) * 90
    } else if (planDurations === "Semi Annualy") {
      fee = (qu * planCost.SemiAnnualy) * 180
    } else if (planDurations === "Annualy") {
      fee = (qu * planCost.Annualy) * 365
    } else if (planDurations === "Annualy-3") {
      fee = (qu*planCost.Annualy) * 365
    } else if (planDurations === "Test (Weekly)") {
      fee = (qu * planCost.TestWeekly) * 7
    } else if (planDurations === "Two Years") {
      fee = (qu * planCost.TwoYears) * (365 * 2);
    } else if (planDurations === "Three Years") {
      fee = (qu * planCost.ThreeYears) * (365 * 3);
    }
    let total = fee + seq + ins
    let tax = userData.paymentMode === "RazorPayFullPayment" ? Math.round((fee + ins) * 0.18) : (fee + ins) * 0.18;
    console.log("Tax   ", tax);
    let totalTax = total + tax;
    console.log("bill", userData);
    to = totalTax;
    await axios.post(`${serverUrl.s}/misc/getQuotation`,
      {
        quoteTo: name,
        quoteNo: data,
        itemsQuantity: { appliances: qu },
        packagePlanDetails: {
          packageName: planNamess === undefined ? "1 Room" : planNamess,
          planName: planDurations,
          security: seq,
          installation: ins,
          fee: fee,
          total: total,

        },
        amounts: {
          subtotal: total,
          tax: tax,
          total: totalTax
        },
        heavyAppliance: {
          count: Number(userData.heavyAppliances),
          total: addon,
        }
      }, {
      responseType: 'blob',
      headers: {
        AccessToken: token
      }
    }).then(function (response) {
      downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`)
    }).catch(function (error) {
      console.log("quto   ", error);
    })

  }
  useEffect(() => {
    setTeleCallerFirstName(teleCallers)
    axios.get("https://int.alistetechnologies.com/users/salesUsers", {
      headers: {
        AccessToken: token
      }
    }).then(function (response) {


      if (response.data.data.success === false) {

        setUserName()
      } else {

        setUserName(response.data.data.users)
      }
    }).then(function (error) {
      console.log("list error   ", error);
    })
  }, [])



  const paymentLinkGen = (userData, addon) => {
    setQutoBtnText("Processing...")
    let data1 = async () => {


      await axios.post(
        `${serverUrl.u}/api/zoho/registerLeadAndLink`,
        {
          planId: planN,
          planFrequency: userData.planType,
          refferal: userData.refferal !== "" ? userData.refferal : "empty",
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone: userData.phone,
          city: stateN.toUpperCase(),
          zip: userData.zip,
          address: userData.address,
          teleCallerId: userRole.includes("External Telecaller") ? teleCallers : userData.teleCallerId,
          comments: userData.comments,
          leadQuality: userData.leadQuality,
          origin: userData.origin,
          layout: dummy[planN],
          security: userData.security,
          installation: userData.installion,
          partialPayment:  true ,
          secondarySalesPerson: userData.teleCallerSecond,
          discount: 0,
          orignalRecursiveAmount: userData.orignalRecursiveAmount,
          orignalTotalAmount: Number(userData.orignalTotalAmount),
          recursiveAmount: userData.recursiveAmount,
          totalAmount: Number(userData.orignalTotalAmount),
          addOnAmount: 0,
          totalAppliances: userData.totalAppliances,
          addOnSubscriptionTo: "empty",
          partialAmount: Number(userData.partialAmount),
          paymentMode: userData.paymentMode,
          reference_id: userData.reference_id,
          zone:userData.incentiveZone
        }).then(async function (resp) {
          console.log(resp)
          if (resp.data.success === true) {
            
            subHistory("Client Register", resp.data.data, "Client Register" , "New Client")
            await quotationApi(resp.data.data.quotationNumber, userData, addon);
            
            if (userData.partialPayment === "false" && userData.paymentMode === "zoho") {
              setText(`Hey ${userData.first_name},
                         Book your Aliste Smart Home in a few clicks by carrying out a payment of INR ${to} on the following link :  ${serverUrl.pay + resp.data.data.generatedHex}`)
              navigator.clipboard.writeText(serverUrl.pay + resp.data.data.generatedHex);
              setPaymentLink(serverUrl.pay + resp.data.data.generatedHex)
            } else if (userData.partialPayment === "false" && userData.paymentMode === "RazorPayFullPayment") {
              setText(`Hey ${userData.first_name},
                Book your Aliste Smart Home in a few clicks by carrying out a payment of INR ${resp.data.data.completeRZPPaymentInOneTime[0].amount} on the following link :  ${resp.data.data.completeRZPPaymentInOneTime[0].short_url}`)
              navigator.clipboard.writeText(resp.data.data.completeRZPPaymentInOneTime[0].short_url);
              setPaymentLink(resp.data.data.completeRZPPaymentInOneTime[0].short_url)
            }
            else {
              setText(`Hey ${userData.first_name},
                         Book your Aliste Smart Home in a few clicks by carrying out a payment of INR ${userData.partialAmount} on the following link :  ${resp.data.data.partialPaymentDetails.short_url}`)
              navigator.clipboard.writeText(resp.data.data.partialPaymentDetails.short_url);
              setPaymentLink(resp.data.data.partialPaymentDetails.short_url)
            }
            setOkBtn(true)
            setok(true)
            setWhatappNumber(userData.phone)
            setReloadLink(true)
          } else {
            console.log(resp.data.message);
            setQutoBtnText("Download")
            setReloadLink(true)
            setErrorApi(true)
          }
        }).catch(function (error) {
          console.log(error);
          setErrorApi(true)
        })
    };
    if (planN === 0) {
      dummy[planN][0].switchboards[0].appliances.fans = Number(install.quantity);
      data1();
    } else {
      data1();
    }
  }
  const handleChange = (event) => {
    console.log(event,"ev");
    setStateN(event.target.value);

  };
  const handleChangepaymentMode = (event) => {
    setPaymentMode(event.target.value);
  }
  const handlePlanDuration = (event) => {
    setPlanD(event.target.value);
  };

  const handleTeleCallerSecond = (event) => {
    setTeleCallerSecondName(event.target.value);
  };
  const handleTeleCallerFirst = (event) => {
    setTeleCallerFirstName(event.target.value);
  };
  const handlePlan = (event) => {

    setPlanNa(event.target.value);
    if (event.target.value === "Custom") {
      setPlanN(0)

    } else if (event.target.value === "1 Room") {
      setPlanN(1)
    } else if (event.target.value === "1 BHK") {
      setPlanN(2)
    } else if (event.target.value === "2 BHK") {
      setPlanN(3)
    } else if (event.target.value === "3 BHK") {
      setPlanN(4)
    } else if (event.target.value === "4 BHK") {
      setPlanN(5)
    }
    else if (event.target.value === "5 BHK") {
      setPlanN(6)
    } else if (event.target.value === "6 BHK") {
      setPlanN(7)
    }
    console.log(event.target.value);
  };

  const state = [{ value: "Delhi" }, { value: "Noida" }, { value: "Gurgaon" }, { value: "Faridabad" }, { value: "Greater Noida" }, { value: "Ghaziabad" }];


  const handleChangeCustom = (event) => {
    let quantitys = event
    let sequrity = quantitys * 100;
    let Installion;
    if (quantitys > 10) {
      let cal = quantitys - 10;
      Installion = 1000 + (cal * 50);
    } else {
      Installion = 1000;
    }
    setInsall({
      planName: "Custom",
      security: sequrity,
      Installion: Installion,
      quantity: quantitys
    })


  }
  useEffect(() => {
    if (dataEdit?.totalAppliances) {
      handleChangeCustom(dataEdit.totalAppliances)
    }
  }, [])

  useEffect(() => {
    if (ok === false) return
    setTimeout(() => {
      console.log("Delayed for 1 second.");
      setok(false)
    }, "4000")
  }, [ok])
  useEffect(() => {
    if (errorsApi === false) return
    setTimeout(() => {
      console.log("Delayed for 1 second.");
      setErrorApi(false)
    }, "4000")
  }, [errorsApi])
  const callFilled = async (number) => {
    if (number.length < 10) return;
    let res = await getFilledDetail(number);
    console.log(res, "res");
    if (res[1] === "Client Already Register") {

      setAlreadyRegister(true)
      notify("Client Already Register")
    } else if (res[1] === "New Client" && res[0].data.success === true) {
      setGetData(res[0].data.data.user)
    }
    console.log("res  ", res);
  }

  return (
    <div>
      <ToastContainer />
      <Box component="form" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Phone Number <span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  autoComplete="current-phoneNumber"
                  defaultValue={dataEdit?.phone}
                  required
                  onChange={(ele) => callFilled(ele.target.value)}
                  placeholder="Phone Number"
                  aria-label="phoneNumber"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
            <div className="col-6">
                    <label className="create-label1">Subscription Zone <span className="required_star">*</span></label>
                    <div class="input-group ">
                      <select class="custom-select height-select" required id="inputGroupSelect01"
                        name="incentiveZone"
                        label="incentiveZone"
                        autoComplete="current-incentiveZone"
                        >
                        {ElectricianIncentiveZone.map((option) => (
                          <option value={option.value}>{option.value}</option>
                        ))}
                      </select>
                    </div>
                  </div>
          </div>
        </div>
     
              <div className="make_quto">
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">First Name <span className="required_star">*</span></label>
                    <div class="input-group mb-1">
                      <input type="text"
                        class="form-control"
                        id="fname"
                        name="fname"
                        autoComplete="fname"
                        defaultValue={name || getData?.name}
                        required
                        placeholder="First Name"
                        aria-label="fname"
                        aria-describedby="basic-addon1"
                        onFocus={(e) => { console.log(e.target.value) }} />
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="create-label1">Last Name</label>
                    <div class="input-group mb-1">
                      <input type="text"
                        class="form-control"
                        id="lname"
                        name="lname"
                        autoComplete="current-lname"
                        placeholder="Last Name"
                        aria-label="lname"
                        aria-describedby="basic-addon1" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">Email <span className="required_star">*</span></label>
                    <div class="input-group ">
                      <input type="email" class="form-control" required defaultValue={dataEdit?.email || getData?.email} id="email" name="email" autoComplete="current-email" placeholder="Email" aria-label="email" aria-describedby="basic-addon1" />
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="create-label1">City <span className="required_star">*</span></label>
                    <div class="input-group ">
                    <SelectCityInput value={stateN} setValue={setStateN}/>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div className="make_quto">
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">Plan Name <span className="required_star">*</span></label>
                    <div class="input-group ">
                      <select class="custom-select height-select" required id="inputGroupSelect01" onChange={handlePlan} defaultValue="choose one"
                        name="plan"
                        label="plan"
                        autoComplete="current-plan" value={planNa}>
                        {plan.map((option) => (
                          <option value={option.text}>{option.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="create-label1">Plan Duration <span className="required_star">*</span></label>
                    <div className="input-group ">
                      <select className="custom-select height-select" required id="inputGroupSelect01" onChange={handlePlanDuration}
                        name="planDuration"
                        label="planDuration"
                        autoComplete="current-planDuration"
                        defaultValue={(getData?.frequency) || (planD)}>
                        {planDuration.map((option) => (
                          <option value={option.value}>{option.value}</option>
                        ))}

                      </select>
                    </div>
                  </div>
                </div>
                {
                  planN === 0 ?
                    <div className="row">
                      <div className="col-12">
                        <label className="create-label1">Total Appliances</label>
                        <div class="input-group ">
                          <input type="text" defaultValue={getData.quantity == 0 ? 4 : getData.quantity} class="form-control" id="appliance" name="appliance" autoComplete="current-appliance" placeholder="Total appliances" aria-label="appliance" aria-describedby="basic-addon1" onChange={(e) => { handleChangeCustom(e.target.value) }} />
                        </div>
                      </div>
                    </div> : null
                }
              
              </div>

              <div className="make_quto">
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">Security</label>
                    <div class="input-group mb-1">
                      <input type="number" class="form-control" value={install.security} id="security" name="security" autoComplete="current-security" onChange={(e) => { setInsall({ ...install, security: e.target.value }) }} placeholder="Security" aria-label="security" aria-describedby="basic-addon1" onWheel={event => event.currentTarget.blur()} />
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="create-label1">Installation</label>
                    <div class="input-group mb-1">
                      <input type="number" class="form-control" value={install.Installion} id="install" name="install" autoComplete="current-install" onChange={(e) => { setInsall({ ...install, Installion: e.target.value }) }} placeholder="Installation" aria-label="install" aria-describedby="basic-addon1" onWheel={event => event.currentTarget.blur()} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-6">
                    <label className="create-label1">Choose Partial Payment</label>
                    <div class="input-group ">
                      <select class="custom-select height-select" id="inputGroupSelect01" defaultValue="true" onChange={(ele) => setPartialD(ele.target.value)}
                        name="Partial"
                        label="Partial"
                        autoComplete="current-Partial" >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div> */}
                  
                      <div className="col-6">
                        <label className="create-label1">Partial Amount</label>
                        <div class="input-group ">
                          <input type="text"
                            class="form-control"
                            id="partial"
                            name="partial"
                            autoComplete="current-partial"
                            placeholder="Partial Amount"
                            aria-label="partial"
                            aria-describedby="basic-addon1"
                            defaultValue={100} />
                        </div>
                      
                      </div>
                </div>
                {/* {
                  partialD === "true" ?
                    <div className="row">
                      <div className="col-6">
                        <label className="create-label1">Payment Mode</label>
                        <div class="input-group ">
                          <select class="custom-select height-select"
                            required
                            id="inputGroupSelect01"
                            onChange={handleChangepaymentMode}
                            defaultValue="choose one"
                            name="paymentMode"
                            label="City"
                            autoComplete="current-paymentMode"
                            value={paymentMode}>
                            {PartialPaymentModes.map((option) => (
                              <option value={option.value}>{option.value}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {
                        paymentMode !== "razorpay" ?
                          <div className="col-6">
                            <label className="create-label1">Reference Id</label>
                            <div class="input-group ">
                              <input type="text"
                                class="form-control"
                                id="referenceId"
                                name="referenceId"
                                required
                                autoComplete="current-referenceId"
                                placeholder="reference Id"
                                aria-label="referenceId"
                                aria-describedby="basic-addon1" />
                            </div>
                          </div> : null
                      }
                    </div> : null
                } */}
              </div>
              {
                userRole.includes("External Telecaller") ?
                  null : <div className="make_quto">
                    <div className="row">
                      <div className="col-6">
                        <label className="create-label1">First Telecaller Name</label>
                        <div class="input-group">
                          <select class="custom-select height-select" id="inputGroupSelect01"
                            onChange={handleTeleCallerFirst}
                            defaultValue="choose one"
                            name="teleCallerFirst"
                            label="teleCallerFirst"
                            autoComplete="current-teleCallerFirst"
                            value={teleCallerFirstName}>
                            {userName?.map((option) => (
                              <option value={option.first_name}>{option.first_name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <label className="create-label1">Second Telecaller Name</label>
                        <div class="input-group">
                          <select class="custom-select height-select" id="inputGroupSelect01"
                            onChange={handleTeleCallerSecond}
                            defaultValue="choose one"
                            name="teleCallerSecond"
                            label="teleCallerSecond"
                            autoComplete="current-teleCallerSecond"
                            value={teleCallerSecondName}>
                            <option value="empty">choose if Second Telecaller</option>
                            {userName?.map((option) => (
                              <option value={option.first_name}>{option.first_name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
              }


              <div style={{ marginLeft: "1%" }}>
                <div className="btn-create-container">
                  <div className="btn-create">
                    {
                      (stateN !== undefined && stateN !== "" && userName !== undefined && stateN !== "") ?
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            pointerEvents: `${qutoBtnText === "Processing..." ? "none" : ""}`,
                            backgroundColor: `${qutoBtnText === "Processing..." ? "#cccccc" : "#2552B2"}`
                          }}
                          onClick={() => {
                            setReloadLink(false)
                          }}>
                          {qutoBtnText}
                        </Button> : null
                    }
                  </div>
                  {/* <div className="btn-create">
                        <Button
                               type="submit"
                               variant="contained"
                               sx={{ mt: "2%", fontSize: "15px" }}
                               onClick={() => cancelCourse()}>
                                  Reset
                        </Button>
                   </div> */}
                  {
                    (okBtn === true && reloadLink === true) ?
                      <>
                        <div className="btn-create">

                          <Button

                            variant="contained"
                            onClick={() => navigator.clipboard.writeText(paymentLink)}
                          >
                            Copy
                          </Button>
                        </div>
                        <div className="btn-create">
                          <a href={`http://wa.me/${whatappNumber}?text=${text}`} target="_blank">
                            <img src={process.env.PUBLIC_URL + '/assets/icon/WhatsApp_logo.svg'} style={{ height: "47px" }} />
                          </a>
                        </div>

                      </> : null
                  }
                </div>
              </div>
            
        

      </Box>
      {
        userName == undefined ?

          <Alert variant="filled" severity="error"
            sx={{ fontSize: "15px", marginTop: "5%", }}>

            LogOut your account from all devices
          </Alert> : null
      }
      {
        reloadLink === true ?
          <Stack sx={{ width: '100%' }} spacing={2}>
            {
              errorsApi === true ?
                <Alert variant="filled" severity="error"
                  sx={{ fontSize: "15px", marginTop: "5%", }}>
                  Error occur Partial should less than Security amount
                </Alert> : null
            }


            {
              ok === true ?
                <Alert variant="filled" severity="success"
                  sx={{ fontSize: "15px", marginTop: "5%", }} >
                  Data is saved sucessfully
                </Alert> : null
            }
          </Stack> : null
      }
      <Qutoes />
    </div>
  );
}

