import React,{useState} from 'react'
import axios from 'axios'
import { pages, serverUrl } from '../ApiUrl'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { updateAddressLayout } from '../Redux/Action/adderess&layout'

export default function ChangeSecondTeleCaller({e,SetWebsiteReload,websiteReload,userName,filter}) {
  
    let [displayDropDown,setDisplayDropDown] = useState(false)
    let [showDropDown,setShowDropDown] = useState(false)
    let userRole = useSelector((state) => state.sar.data?.user?.roles)
    let teleCallers = useSelector((state)=>state.sar.data.user.first_name)
    const dispatch = useDispatch()
    const assign = async(name,hex)=>{
        await axios.post(`${serverUrl.u}/api/dispatch/updateSecondaryTeleCaller`,{
          generatedHex:hex,
          teleCallerId:name 
        }).then(function (response) {
        let detail=response;
        console.log(detail,"updatescond");
        if(response.data.success===true){
          setDisplayDropDown(false)
          setShowDropDown(false)
          
          if(filter===pages.addressLayout){
             dispatch(updateAddressLayout(response.data.data.cart))
             console.log("callerssssss");
          }else{
            SetWebsiteReload(!websiteReload);
          }
          
        }
        
      }).catch(function (error) {
        console.log(error);
      })}
      const handleElecteicain = (event,dataHex) => {
        assign(event.target.value,dataHex)
      }
      const Desigion = ()=>{
        if((filter==="website" && (userRole.includes("Super Admin"))) || (filter==="website" && teleCallers==="Heena")){
          setShowDropDown(true)
          setDisplayDropDown(true)
        }else if(filter===pages.addressLayout){
          setShowDropDown(true)
          setDisplayDropDown(true)
    }
      }
      
  return (
    <td label="Assigned Electritian *" onClick={() => {
                                                         Desigion()}}>
    {
        (displayDropDown === false && showDropDown===false) ? e.secondarySalesPerson : 
        <div class="input-group mb-3" >
            <select class="custom-select" id="inputGroupSelect01"
                onChange={(ele) => handleElecteicain(ele, e.generatedHex)}
                defaultValue="choose one"
            >
                <option value="empty">
                    Choose TeleCaller</option>
                    <option value="empty">
                    empty</option>
                {userName?.map((option) => (
                    <option value={option.first_name}>{option.first_name}</option>
                ))}
            </select>
        </div>
    }
</td>  )
}
