import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { interestOrNot } from "./Api";
import { interstedorNotClient, readyConstrcuction } from "../Data";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
};
export const InterestedOrNot = ({ sub }) => {
    const [open,setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [Interested,setInerested] = useState(sub.InterestedClient)
    const [site,setSite]= useState(sub.categoryOfInterestedClient==="ready"?readyConstrcuction[1].value:sub.categoryOfInterestedClient)
    return (
        <>
            <Button
                variant="contained"
                onClick={() => setOpen(true)}
                disabled={loading}
            >{"Change Site Type"}
            </Button>
            <Modal
                open={open}
                onClose={()=>setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h4">
                        Confirmation
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  
                        <label className="specific-label">Site Type </label>
                        <div className="input-group ">
                            <select type="text"
                                className="form-control"
                                required
                                aria-label="referenceId"
                                name="comment"
                                value={site}
                                onChange={(event) => setSite(event.target.value)}
                                autoComplete="current-comment"
                                aria-describedby="basic-addon1" >

                                {
                                    readyConstrcuction.map((e) => {
                                    if(e.name!=="All"){
                                    return(
                                        <option value={e.value}>{e.name}</option>
                                    )}})
                                }
                            </select>
                        </div>

                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={"flex-end"} mt={5}>
                        <Button variant="contained" sx={{ backgroundColor: "#9FA6B2" }} onClick={() => {
                           
                            setOpen(false)
                        }}>No</Button>

                        <Button variant="contained" color='primary'
                            onClick={() => {
                                interestOrNot(sub, setLoading, loading,site,Interested)

                                setOpen(false)
                            }}
                        >
                            Yes</Button>

                    </Stack>
                </Box>
            </Modal>
        </>

    )
}