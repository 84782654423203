import axios from 'axios'
import React,{useState,useEffect} from 'react'
import { serverUrl } from '../ApiUrl'
import ZoneAssign from './ZoneAssign'
import ZoneRemove from './ZoneRemove'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignGrivence from './AssignGrivence'

export default function ElectricianZone() {
   const [electrician,setElectrician] = useState([])
   const [reload,setReload] = useState(false)
   const notify = (text)=>toast(text)

   useEffect(()=>{
      axios.get(`${serverUrl.u}/api/tele/getAllElectrician`).then(function (res) {
         console.log(res);
         if(res.data.success===true){
            console.log(res.data.data,"dd");
            setElectrician(res.data.data)
         }
      }).catch(function (err) {
          console.log(err);
      })
   },[reload])

  return (
    <div  style={{padding:"0 4%",marginTop:"4vh"}}>
        <ToastContainer/>
        <div className='table-container1' style={{marginTop:"10px",marginBottom:"30px",height:"fit-content"}} key={"imp"}>
      
      <table className='table table-sm table-bordered'>
        <thead>
          <tr >
            <th>Sr. No.</th>
            <th>Employee Id</th>
            <th>Name</th>
            <th>Type</th>
            <th>Phone</th>
            <th style={{width:"150px"}}>Zone</th>
            <th>Assign Zone</th>
            <th>Remove Zone</th>
            <th>Greivance</th>
          </tr>
        </thead>
        <tbody>
          {
            electrician && electrician.map((e,i)=>(
                <tr key={e._id}>
                    <td>{i+1}</td>
                    <td>{e.employeeId}</td>
                    <td>{e.firstName}</td>
                    <td>{e.grievanceElectrician===true?"Griviance Election and Normal":"Normal"}</td>
                    <td>{e.phoneNumber}</td>
                    <td><ul>
                        {
                            e.zone.map((ele)=>(
                                <li key={`${e._id}${ele}`}>{ele}</li>
                            ))
                        }
                        </ul>
                    </td>
                    <ZoneAssign e={e} reload={reload} setReload={setReload} notify={notify}/>
                    {
                      e.zone.length!==0?
                      <ZoneRemove e={e} reload={reload} setReload={setReload} notify={notify}/>
                      :<td>No Zone Assign</td>
                    }
                    <td><AssignGrivence data={e} setReload={setReload} reload={reload}/></td>
                    {/* <ZoneRemove e={e} reload={reload} setReload={setReload} notify={notify}/> */}
                </tr>
            ))
          }
                  <tr  >
                    
                    
                  </tr>
        
        </tbody>
      </table>
    </div>
    </div>
  )
}
