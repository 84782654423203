import React, { useState, useEffect, useRef } from 'react'
import ReactWhatsapp from "react-whatsapp";
import { WhatsApp } from "@mui/icons-material";
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import { Grid } from "@mui/material";
import './CollectExtraPartialPayment.css'
import { calculatePartialAmount } from './AxiosControl';
export default function CollectExtraPartialPayment({ e, notify }) {
  let [extraPartialPop, setExtraPartialPop] = useState(false)
  let [partialUpdate, setPartialUpdate] = useState({});
  let [whatappNumber, setWhatappNumber] = useState();
  let [paymentLink, setPaymentLink] = useState("");
  let [partialAmoun, setPartialAmoun] = useState(-1)
  const [text, setText] = useState("");
  let [okBtn, setokBtn] = useState(false);
  let [partialSecurity, setPartialSecurity] = useState(0)
  const handleApplianceChangeExtraPartial = (val) => {
    val = Number(val);
    let secu = val * 100;
    setPartialSecurity(secu)
  };

  useEffect(() => {
    setPartialSecurity(partialUpdate.security)
    console.log(partialUpdate, "update");
  }, [partialUpdate])
  const handleExtraPartialPayment = async (event) => {
    event.preventDefault();
    setPaymentLink("")
    setText("")
    setWhatappNumber("")
    setokBtn(false)
    const data = new FormData(event.currentTarget);
    let userData = {
      name: data.get("name"),
      security: data.get("security"),
      quantityAppliances: data.get("appliances"),
      additionalPartialAmount: data.get("newpartial"),
      isSecond: data.get("isSecond"),
    }

    await axios.post(`${serverUrl.u}/v2/subscription/additionalPartialPaymen`, {
      generatedHex: partialUpdate.generatedHex,
      totalAppliances: userData.quantityAppliances,
      additionalPartialAmount: userData.additionalPartialAmount,
      teleCallerId: partialUpdate.teleCallerId,
      secondTeleCaller: userData.isSecond == "true" ? true : false,
    }).then(function (response) {
      console.log(response);
      notify(response.data.message)
      setWhatappNumber(partialUpdate.phone);
      setText(`Hey ${partialUpdate.name},
           Book your Aliste Smart Home in a few clicks by carrying out a payment of INR ${Number(userData.additionalPartialAmount)
        } on the following link :  ${response.data.data.paymentDetails.short_url}`);
      setPaymentLink(
        response.data.data.paymentDetails.short_url
      );
      setokBtn(true);
    }).catch(function (err) {
      console.log(err);
      notify(err.message)
    })

  }
  return (
    <>
      <button className="btn btn-primary" onClick={() => {
        setPartialUpdate(e)
        setPaymentLink("")
        setText("")
        setWhatappNumber("")
        setExtraPartialPop(true)
        setokBtn(false)
      }}>
        Collect Partial Payments
      </button>

      {
        extraPartialPop === true ? (
          <div className="updatePlan">
            <form onSubmit={handleExtraPartialPayment}>
              <div className="cross1" onClick={() => setExtraPartialPop(false)}>
                x
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>Name</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      autoComplete="name"
                      placeholder="Name"
                      aria-label="name"
                      aria-describedby="basic-addon1"
                      value={partialUpdate.name}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Collected Partial Payment</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      id="partial"
                      name="partial"
                      onWheel={event => event.currentTarget.blur()}
                      autoComplete="current-partial"
                      placeholder="Partial"
                      aria-label="partial"
                      aria-describedby="basic-addon1"
                      value={partialAmoun}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <label>New Partial Payment</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      id="newpartial"
                      name="newpartial"
                      onWheel={event => event.currentTarget.blur()}
                      autoComplete="current-newpartial"
                      placeholder="New Partial"
                      aria-label="newpartial"
                      aria-describedby="basic-addon1"
                      defaultValue={0}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Security</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      id="security"
                      name="security"
                      onWheel={event => event.currentTarget.blur()}
                      autoComplete="current-security"
                      placeholder="Security"
                      aria-label="security"
                      aria-describedby="basic-addon1"
                      value={partialSecurity}
                    />
                  </div>
                </Grid>

              </Grid>
              <Grid container spacing={4}>

                <Grid item xs={12} md={6}>
                  <label>Appliances</label>
                  <div class="input-group mb-3">
                    <input
                      type="Number"
                      class="form-control"
                      id="appliances"
                      name="appliances"
                      onWheel={event => event.currentTarget.blur()}
                      autoComplete="current-appliances"
                      placeholder="Appliances"
                      aria-label="appliances"
                      aria-describedby="basic-addon1"
                      defaultValue={partialUpdate.totalAppliances}
                      onChange={(val) => {
                        handleApplianceChangeExtraPartial(val.target.value);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>If Site Visited By Second TeleCaller</label>
                  <div class="input-group mb-3">
                    <select

                      class="form-control"
                      id="isSecond"
                      name="isSecond"
                      autoComplete="current-isSecond"
                      aria-label="isSecond"
                      aria-describedby="basic-addon1"
                      defaultValue={true}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </Grid>
              </Grid>
              {
                partialAmoun !== -1 ?
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={2}>
                      <button className="btn btn-primary" type="submit">
                        Update
                      </button>
                    </Grid>
                  </Grid> : null
              }
            </form>
            {
              partialAmoun === -1 ?
                <button className='btn btn-primary' onClick={async () => {
                  let amountP = await calculatePartialAmount(e)
                  setPartialAmoun(amountP)
                }}>Partial Amount</button> : null
            }
            {okBtn === true ? (
              <div className='extrapayment'>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={2}>
                    <ReactWhatsapp
                      className="collectExtraWhatapp"
                      number={whatappNumber}
                      message={text}
                      style={{

                      }}
                    >
                      <WhatsApp
                        sx={{
                          color: "white",
                          verticalAlign: "middle",
                          fontSize: "xx-large",

                        }}
                      />
                    </ReactWhatsapp>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <button
                      className="btn btn-primary width"
                      onClick={() => navigator.clipboard.writeText(paymentLink)}
                    >
                      Copy
                    </button>
                  </Grid>
                </Grid>
              </div>
            ) : null}
          </div>
        ) : null
      }
      {/* <ToastContainer/> */}
    </>
  )
}
