import React,{useState} from 'react'
import { ispPlanDuration, planDuration } from '../Data'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { useSelector } from 'react-redux'
import { subHistory } from '../Function/SubscriptionHistory'
import { useEffect } from 'react'
import { notifyFailed } from '../Common/Notification'


export default function ({setPopPartial,popPartial,data,notify,setReload,reload}) {
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let [security,setSecurity] = useState(data.currentSecurity)
    let [install,setInstall] = useState(data.currentInstallation)
    let [apps,setApp]= useState(data.totalAppliances)
    const handleApplianceChange =(app)=>{
             setSecurity(app*100)
             if(app>10){
                let m = app-10;
                setInstall(1000+(m*50))
             }else{
                setInstall(1000)
             }
       }
    const handlePartail =(event)=>{
        event.preventDefault();
        const datav = new FormData(event.currentTarget);
        let sendData = {
            app:datav.get('app'),
            fre:datav.get('fre'),
            security:security,
            install:install,
        
        }
        let oldAppliance= Number(data.totalAppliances)-(data.currentSecurity/100)
        let newApp=0
   
        if(data.changesInSubscription.length!==0){
            newApp = Number(sendData.app)+oldAppliance;
       }else{
        newApp = Number(sendData.app)
       }
        
        axios.post(`${serverUrl.u}/api/tele/updateSIRT`,{
            generatedHex:data.generatedHex,
            planId:data.planId,
            planFrequency:sendData.fre,
            totalAppliances:newApp,
            security:Number(sendData.security),
            installation:Number(sendData.install),
        }).then(function (res) {
            
            if(res.data.success===true){
                notify(res.data.message)
                setReload(!reload)
                setPopPartial(false)
                subHistory(`Partial Subscription UpGrade/DownGrade By telecallers`,data,"Partial Subscription UpGrade/DownGrade","")
            }else{
                notify(res.data.message)
            }
           
        }).catch(function (err) {
            console.log(err);
            notify(err.response.data.message)
        })
       }
       const handlleTotalAppliance = (app)=>{
          
          let diff = data.totalAppliances-data.currentSecurity/100
          let totalApp = diff+app
          setApp(totalApp)
       }
       useEffect(()=>{
        if(data.currentSecurity===0 && popPartial===true && data.ispSubscription===false){
            notifyFailed("Current Security can not be zero contact admin")  
        }
        if(data.ispSubscription){
            setApp(data.totalAppliances)
        }
       },[data,popPartial])
  return (
    <div>
        {
          popPartial===true?
          <div style={{position:"fixed",top:"22%",left:"40%",background: "lightgray",
           padding: "11px 16px",
           zIndex: 4,
           borderRadius: "14px"}}>
             <div style={{position:"relative",height:"15px"}}><span onClick={()=>setPopPartial(false)}  style={{position:"absolute",cursor:"pointer",right:"5px",top:"-2px"}}>X</span></div>
             <form onSubmit={handlePartail}>
             <div  className='single_input'>
                         <label>Client Name</label>
                         <input type="text"  value={data.name}  className='form-control' />
                      </div>
            
                      <div  className='single_input'>
                         <label>Total Appliances</label>
                         <input type="number" name="app" className='form-control' value={apps}  disabled/>
                      </div>
              <div  className='single_input'>
                         <label>New Appliances</label>
                         <input 
                            type="number" 
                            name="app" 
                            defaultValue={data.currentSecurity/100} 
                            autoComplete="current-app" 
                            className='form-control' 
                            onWheel={ event => event.currentTarget.blur() } 
                            onChange={(eleww)=>{
                                handleApplianceChange(Number(eleww.target.value))
                                handlleTotalAppliance(Number(eleww.target.value))
                        }}/>
                      </div>
                      <div  className='single_input'>
                        <label>Frequency</label>
                        <select className='form-control' defaultValue={data.planFrequency} name="fre" autoComplete="current-fre">
                        {
                                (data.ispSubscription?ispPlanDuration:planDuration).map((ele)=>{
                                    return(
                                        <option value={ele.value}>{ele.value}</option>
                                    )
                                })
                            }
                        </select>
                      </div>
                      <div  className='single_input'>
                         <label>Security</label>
                         <input type="number" name="security" value={security} autoComplete="current-security" className='form-control' onWheel={ event => event.currentTarget.blur() } onChange={(elew)=>setSecurity(elew.target.value)}/>
                      </div>
                      <div  className='single_input'>
                         <label>Installation</label>
                         <input type="number" name="install" value={install} autoComplete="current-install" className='form-control' onWheel={ event => event.currentTarget.blur() } onChange={(elew)=>setInstall(elew.target.value)}/>
                      </div>

             
                      <button className='btn btn-primary mt-2' type='submit'>Submit</button>
                      </form>
                
           </div>
             :null
        }
    </div>
  )
}
