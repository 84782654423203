import React ,{useState}from 'react'
import { serverUrl } from '../ApiUrl';
import axios from 'axios';
export default function CalculatePartialAmount({e,notify}) {
  let [partialAmounts ,setPartialAmounts] = useState([])
    const calculatePartialAmount = async()=>{
        await axios.post(`${serverUrl.u}/api/tele/calPartialAmount`,{
          generatedHex:e.generatedHex
        }).then(function (res) {
          console.log(res);
          if(res.status===200){
          setPartialAmounts([res.data])
          notify("Amount Calculated Succssfully")
          }else{
             notify(res.data.message)
          }
        }).catch(function (err) {
          console.log(err);
        })
  }
  return (
    <>
    {
      partialAmounts.length!==0?
      <div className='request-detail'>
      <span>Calculate Partial Amount :- </span>
      <span >{partialAmounts[0].totalamount}</span>
   </div>:
    
    <button className="btn btn-primary info mt-2 w-24" onClick={()=>{
        calculatePartialAmount();
      }}><span>Calculate Partial Amont</span>
    </button>
    }
    </>
  )
}

