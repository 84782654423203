import React, { useState } from "react";
import { payUTrannsaction } from "./Api";
import { notify, notifyFailed } from "../Common/Notification";
import { CSVLink } from "react-csv";
import moment from "moment";
import Spinner from "../Function/Spinner";

function PayTransaction() {
  const [data,setData]  = useState([])
  const [completeData,setCompleteData]  = useState([])
  const  [loading,setLoading] = useState(false)
  const [dates,setDates] = useState({})
  const refresh = async(date)=>{
    setLoading(true)
    await payUTrannsaction(date).then(res=>{
      
      if(res.type===1){
          if(res.data.success){
            if(res.data.data.tranactions.length>0){
           setData(res.data.data.tranactions)
            }else{
              notify('Data Not Found')
            }
           
          }else{
              notifyFailed(res.data.message)
          }
          setLoading(false)
      }else{
          let msg = res?.response?.data?.message?(res.response.data.message):res.message
          notifyFailed(msg)
          setLoading(false)
      }
      
  })

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData(e.currentTarget);

    let date = {
      firstDate: data.get("startDate"),
      lastDate: data.get("endDate"),
    };
    setDates(date)
    await refresh(date)
  };
  return (
    <div style={{ width: "100%", padding: "0 4%", marginTop: "5vh" }}>
      {
        loading && <Spinner/>
      }
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-2 ">
            <label>Start Date</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="startDate"
                defaultValue={new Date(
                  new Date().setDate(new Date().getDate() - 4)
                )
                  .toISOString()
                  .substr(0, 10)}
                autoComplete="current-startDate"
              />
            </div>
          </div>
          <div className="col-2 ">
            <label>End Date</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="endDate"
                defaultValue={new Date(
                  new Date().setDate(new Date().getDate() + 1)
                )
                  .toISOString()
                  .substr(0, 10)}
                autoComplete="current-endDate"
              />
            </div>
          </div>
          <div className="col-2">
            <label></label>
            <div className="input-group mt-2 ">
              <button
                className="btn btn-primary"
                disabled={loading}
                style={{
                  marginLeft: "2%",
                  marginTop: "2%",
                  marginBottom: "5%",
                }}
                type="submit"
              >
                Get Data
              </button>
            
            </div>
          </div>
          {
            data.length>0 && (
                <div className="col-2">
                <label></label>
                <div className="input-group mt-2 ">
                <CSVLink data={data} filename={`payuTransaction_${dates?.firstDate} - ${dates?.lastDate}.csv`}>
                  <button 
                    type="button" 
                    className="btn btn-primary"
                    style={{
                        marginLeft: "2%",
                        marginTop: "2%",
                        marginBottom: "5%",
                      }}
                  >Download</button>
                </CSVLink>
                </div>
                </div>
            )
          }
        
        </div>
      </form>

      <div className='table-container1' style={{ marginTop: "10px", marginBottom: "30px", height: "fit-content" }} key={"imp"}>
              <table className='table table-sm table-bordered'>
                <thead>
                  <tr >
                    <th>Sr. No.</th>
                    <th style={{width:'220px'}}>Transaction Id</th>
                    <th>Name</th>
                    <th style={{width:'160px'}}>Phone</th>
                    <th style={{width:'250px'}}>Email</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                   <th>Mode</th>
                   <th style={{width:'220px'}}>Client Id</th>
                   <th>Client Name</th>
                   <th style={{width:'220px'}}>Property Id</th>
                   <th style={{width:'220px'}}>Room Id</th>
                   <th style={{width:'220px'}}>User Id</th>
                  </tr>
                </thead>
                <tbody>
                  {

                     data.map((e, i) => {
                     
                      return (
                        <tr key={i} >
                          <td>{(i + 1)}</td>
                         <td>{e.txnid}</td>
                         <td>{e.firstname}</td>
                         <td>{e.phone}</td>
                         <td style={{overflowY:'scroll'}}>{e.email}</td>
                         <td>{e.amount}</td>
                         <td>{moment(e.addedon).format('DD-MMM-YYYY, LT')}</td>
                         <td>{e.status}</td>
                         <td>{e.mode}</td>
                         <td>{e?.udf1}</td>
                         <td>{e["Client Name"]}</td>
                         <td>{e?.udf2}</td>
                         <td>{e?.udf3}</td>
                         <td>{e?.udf4}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
    </div>
  );
}

export default PayTransaction;
