import React, { useEffect, useState } from "react";
import "../ConvertedLead.css";
import "../DispatchData.css";
import moment from "moment/moment";
import '../Installation.css'
import Qutoes from "../Qutoes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { upgradeRequestApi,upgradePendingRecords, upgradePendingEmptyRequest, upgradeRequestEmpty, upgradeRequestEmptyAll, upgradePendingEmptyRequestAll } from "../Redux/UpgradeRequestAction";
import RequestComplete from "./RequestComplete";
import PreviousFrequency from "./PreviousFrequency";

export default function ClientUpgrade() {
  let [displayData,setDisplayData] = useState([])
  let [tab,setTab] = useState("All_Record");
  let [page,setPage] = useState(1)
  let [pendingPage,setPendingPage] = useState(1)
  let [totalPage,setTotalPage] = useState(1)
  let [pendingTotalPage,setPendingTotalPage] = useState(1)
  let [q, setq] = useState("");
  let [reload,SetReload] = useState(false);
  let userRole =useSelector((state)=>state.sar.data.user.roles)
  let UpgradeDetail = useSelector((state)=>state.upgradeRequest)
  let UpgradePendingRecord = useSelector((state)=>state.pendingUpgrade)
  const [searchParam] = useState(["name"]);
  const notify = (text) => toast(text);
  let dispatch = useDispatch();
  console.log(UpgradePendingRecord,"pending Record",UpgradeDetail);

 
  useEffect(()=>{

    upgradeRequestApi(1,notify,dispatch);
    upgradePendingRecords(1,notify,dispatch)
  },[reload])
 
  useEffect(()=>{
       setTotalPage(UpgradeDetail.totalPage)
       setPendingTotalPage(UpgradePendingRecord.totalPage)
  },[UpgradeDetail,UpgradePendingRecord])
 
  return (
    <div style={{padding:"0 4%",marginTop:"4vh"}}>
      
      <div className="input-field">
      {/* <SearchDataWithStage setDisplayData={setDisplayData} stage={"installed"}/> */}
      </div>
      
        <ToastContainer />
        <div style={{marginBottom: "20px"}}>
            <button className={tab==="All_Record"?"btn btn-primary mt-2":'btn btn-light mt-2'} onClick={()=>{setTab("All_Record")
                                                                                                   setPage(1)
                                                                                                         upgradeRequestApi(1,notify,dispatch)}}>Complete Record</button>
            
            <button className={tab==="Pending_Record"?"btn btn-primary ml-2 mt-2":'btn btn-light ml-2 mt-2'} onClick={()=>{setTab("Pending_Record")
                                                                                                setPendingPage(1)
                                                                                                 upgradePendingRecords(1,notify,dispatch)}}>Pending Record</button>
        </div>
        { tab==="All_Record"?
        <>
        <nav aria-label="..." className='pagination-container'>
      <ul class="pagination">
        {
          [...Array(totalPage)].map((e,i)=>{
              return(
                
                <li className={page===i+1?"page-item active":"page-item"} onClick={()=>{setPage(i+1)
                  dispatch(upgradeRequestEmpty([]))
                  upgradeRequestApi(i+1,notify,dispatch)}}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
        </nav>

      <div className='table-container1' style={{marginTop:0,height:"60vh"}}>
      <table className="table table-sm table-bordered ">
        <thead>
          <tr>
          <th>Sr. No.</th>
            <th scope="col">Hex Code<span style={{ color: "red" }}>*</span></th>
           <th>Client Name</th>
           <th>Telecaller Name</th>
            <th>Phone</th>
            <th>Request Date</th>
            <th>Current Frequency</th>
            {/* <th style={{width:"180px"}}>Request Frequency</th> */}
            <th>Status</th>
            {/* <th>Action</th> */}
         
          </tr>
        </thead>
        <tbody>
          {(Object.keys(UpgradeDetail).length>0 && UpgradeDetail.hasOwnProperty('upgradeRecord'))
            ? UpgradeDetail.upgradeRecord.filter(s=>s.request_Resolved===true).map((e, i) => {
                return (
                  <tr key={i} style={{marginBottom:5}}>
                    <td>{(i+1)+((page-1)*50)}</td>
                    <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.generatedHex}</td>
                    <td>{e.name}</td>
                    <td>{e.teleCallerId}</td>
                    <td>{e.phone}</td> 
                    <td>{moment(e.createdAt).format('DD-MM-YYYY')}</td>
                    <td><PreviousFrequency e={e}/></td>
                    {/* <td>{e.planfrequency}</td> */}
                    <td>{e.request_Resolved===true?"Completed":"Pending"}</td>
                    {/* <td><RequestComplete data={e} notify={notify} reload={reload} SetReload={SetReload}/></td> */}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
      </>:
      <>
     <nav aria-label="..." className='pagination-container'>
      <ul class="pagination">
        {
          [...Array(pendingTotalPage)].map((e,i)=>{
              return(
                
                <li className={pendingPage===i+1?"page-item active":"page-item"} onClick={()=>{setPendingPage(i+1)
                                                                 dispatch(upgradePendingEmptyRequest([]))
                                                                 upgradePendingRecords(i+1,notify,dispatch)}}>
                <a className="page-link" href="#" >{i+1} </a>
              </li>
              )
          })
        }
      </ul>
     </nav>

     <div className='table-container1' style={{marginTop:0,height:"60vh"}}>
      <table className="table table-sm table-bordered ">
        <thead>
          <tr>
          <th>Sr. No. Pending</th>
            <th scope="col">Hex Code<span style={{ color: "red" }}>*</span></th>
            <th>Name</th>
            <th>TeleCaller</th>
            <th>Phone</th>
            <th>Request Date</th>
            <th>Current Frequency</th>
            <th style={{width:"180px"}}>Request Frequency</th>
            <th>Status</th>
            <th style={{width:"180px"}}>Action</th>
          </tr>
        </thead>
        <tbody>
          {(Object.keys(UpgradePendingRecord).length>0 && UpgradePendingRecord.hasOwnProperty('upgradeRecord'))
            ? UpgradePendingRecord.upgradeRecord.map((e, i) => {
                return (
                  <tr key={i} style={{marginBottom:5}}>
                    <td>{(i+1)+((page-1)*50)}</td>
                    <td label="Name" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.generatedHex}</td>
                    <td>{e.name}</td> 
                    <td>{e.teleCallerId}</td>
                    <td>{e.phone}</td> 
                    <td>{moment(e.createdAt).format('DD-MM-YYYY')}</td>
                    <td><PreviousFrequency e={e}/></td>
                    <td>{e.planfrequency}</td> 
                    <td>{e.request_Resolved===true?"Completed":"Pending"}</td>  
                    <td><RequestComplete data={e} notify={notify} reload={reload} SetReload={SetReload}/></td>

                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>
      </>
      }
    <Qutoes/>
    </div>
  );
}
