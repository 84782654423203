import React from 'react'
import { notifyFailed } from '../Common/Notification';
import { pages } from '../ApiUrl';
import { useEffect } from 'react';
import { useState } from 'react';
import { Box, Button, Modal, Stack, Typography, setRef } from '@mui/material';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
  };

function ConfirmationForPaymentLink({ e, setZohoPop, btnName, page, setBasicPop }) {
    const [refesh,setRefresh] = useState(false)
    const [open, setOpen] = React.useState(false);
    let loginName = useSelector((state) => state.sar.data.user.first_name)

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setBasicPop(true)
  };
    useEffect(() => { 
       setRefresh(!refesh)
    }, [e.generatedHex])

    return (
        <div>
            <Button
                variant='contained'
                className={page === pages.elctrician ? "btn1 btn-primary" : "btn btn-primary"}
                style={{ width: page.elctrician ? "100% !important" : "" }}
              
                onClick={() => {
                     if(e.houseId==="" && !["Akhil","Digvijay"].includes(loginName)){
                      notifyFailed('House Id Not Mapped')
                      return
                     }
                    if (e.InstallationDate === "" || e.InstallationDate === null) {
                        notifyFailed("Set Installation Date First Then Create Payment Link")
                        return
                    }
                    setOpen(true)
                }}
            >
                {btnName}
            </Button>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
          Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to create payment link for {e?.name}?.
          Because Previouse subscription will be cancelled.
          </Typography>
          <Stack direction={'row'} spacing={3} justifyContent={"flex-end"} mt={5}>
            <Button variant="contained" sx={{backgroundColor:"#9FA6B2"}} onClick={()=>setOpen(false)}>No</Button>
            
            {
                                e.InstallationDate !== "" && e.InstallationDate !== null ?
                                    <Button variant="contained" color='primary' 
                                        onClick={() => {
                                            if (page === pages.search) {                                        
                                                    setZohoPop(true)
                                                    setOpen(false)
                                                
                                            }
                                            if (page == pages.elctrician) {
                                                setBasicPop(true)
                                                setOpen(false)
                                            }
                                        }}
                                    >
                                        Yes</Button>
                                    : null
                            }
          </Stack>
        </Box>
      </Modal>

         
        </div>
    )
}

export default ConfirmationForPaymentLink