import { Button, Stack } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import ShowAllFeedback from './ShowAllFeedback'
import NotFeedback from './NotFeedback'

function Feedback() {
  const [tab,setTab] = useState("Not have feedback")
  return (
    <div style={{ padding: "0 1%", marginTop: "4vh" }}>
        <Stack direction={'row'} spacing={2}>
            <Button
              variant='contained'
              color={tab==="Not have feedback"?"primary":"grey"}
              onClick={()=>setTab("Not have feedback")}
              size='small'
            >Customer Don't Have FeedBack</Button>
            <Button
              variant='contained'
              color={tab==="All feedback"?"primary":"grey"}
              onClick={()=>setTab("All feedback")}
              size='small'
            >All FeedBack</Button>
        </Stack>
        {
            tab==="All feedback"?
            <ShowAllFeedback/>:
            <NotFeedback/>
        }
    </div>
  )
}

export default Feedback