import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Qutoes from "../Qutoes";
import { dummy, SeurityInstallion, plan, planDuration, PaymentModes, planCost } from '../Data'
import { useSelector } from "react-redux";
import { raiseRequestToRegisterOfflineClient } from "./Api";
import { recursiveAmountCalculate } from "../Common/applianceDifference";

export default function PartialOfflineClientRegister({ dataEdit }) {
  let userName = useSelector(state => state.salesUser)


  let [planD, setPlanD] = useState("");
  let [qutoBtnText, setQutoBtnText] = useState("Download")
  let to;
  let [paymentMode, setPaymentMode] = useState("BankTransfer")
  let [planN, setPlanN] = useState(1);
  let [planNa, setPlanNa] = useState("1 Room");
  let [check, setCheck] = useState(false);
  let [paymentLink, setPaymentLink] = useState("")
  const [text, setText] = useState("");
  let [teleCallerSecondName, setTeleCallerSecondName] = useState("")
  let [teleCallerFirstName, setTeleCallerFirstName] = useState("")
  let [install, setInsall] = useState({
    security: 400,
    Installion: 100,
    quantity: 4
  })

  useEffect(() => {
    if (planNa === "Custom") {
      setPlanN(0)
      setPlanNa("Custom")

    } else if (planNa === "1 Room") {
      setPlanN(1)
      setPlanNa("1 Room")
    } else if (planNa === "1 BHK") {
      setPlanN(2)
      setPlanNa("1 BHK")
    } else if (planNa === "2 BHK") {
      setPlanN(3)
      setPlanNa("2 BHK")
    } else if (planNa === "3 BHK") {
      setPlanN(4)
      setPlanNa("3 BHK")
    } else if (planNa === "4 BHK") {
      setPlanN(5)
      setPlanNa("4 BHK")
    }
    else if (planNa === "5 BHK") {
      setPlanN(6)
      setPlanNa("5 BHK")
    } else if (planNa === "6 BHK") {
      setPlanN(7)
      setPlanNa("6 BHK")
    }

  }, [planNa])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let userData2 = {
      first_name: data.get("fname"),
      last_name: data.get("lname"),
      phone: data.get("phoneNumber"),
      city: data.get("city"),
      address: data.get("address"),
      planType: data.get("planDuration"),
      plan: data.get("plan"),
      origin: data.get("source"),
      leadQuality: data.get("lead_quality"),
      email: data.get("email"),
      zip: data.get("zipcode"),
      comments: data.get("comments"),
      security: data.get("security"),
      installion: data.get("install"),
      teleCallerId: data.get("teleCallerFirst"),
      refferal: data.get("refferal"),
      partialPayment: false,
      teleCallerSecond: data.get("teleCallerSecond"),
      quantityAppliances: data.get("appliance"),
      partialAmount: 0,
      paymentMode: data.get("paymentMode"),
      reference_id: data.get("referenceId"),
      createdAt: data.get("createdAt"),
      startDate: data.get("startDate"),
      mandateDate: data.get("mandateDate"),
      oldHex: data.get('oldHex')
    }

    let seq = Number(userData2.security);
    let ins = Number(userData2.installion);
    let qu = Number(userData2.quantityAppliances)
    let planDurations = userData2.planType;



    if (planN === 0) {
      qu = Number(userData2.quantityAppliances)
    } else {
      qu = install.quantity;
    }
    let fee = await recursiveAmountCalculate(planDurations, qu)
    let total = ((fee + ins) * 1.18 + seq).toFixed(2)
    setCheck(!check);
    userData2 = { ...userData2, totalAppliances: qu, orignalTotalAmount: total, totalAmount: total, orignalRecursiveAmount: fee, recursiveAmount: fee }

    raiseRequestToRegisterOfflineClient(userData2, setQutoBtnText, planN, dummy, setText, setPaymentLink)
  };

  useEffect(() => {
    if (planN === 0) return;
    setInsall(SeurityInstallion[planN - 1])
  }, [planN])

  const handleChangepaymentMode = (event) => {
    setPaymentMode(event.target.value);
  }
  const handlePlanDuration = (event) => {
    setPlanD(event.target.value);
  };

  const handleTeleCallerSecond = (event) => {
    setTeleCallerSecondName(event.target.value);
  };
  const handleTeleCallerFirst = (event) => {
    setTeleCallerFirstName(event.target.value);
  };
  const handlePlan = (event) => {

    setPlanNa(event.target.value);
    if (event.target.value === "Custom") {
      setPlanN(0)

    } else if (event.target.value === "1 Room") {
      setPlanN(1)
    } else if (event.target.value === "1 BHK") {
      setPlanN(2)
    } else if (event.target.value === "2 BHK") {
      setPlanN(3)
    } else if (event.target.value === "3 BHK") {
      setPlanN(4)
    } else if (event.target.value === "4 BHK") {
      setPlanN(5)
    }
    else if (event.target.value === "5 BHK") {
      setPlanN(6)
    } else if (event.target.value === "6 BHK") {
      setPlanN(7)
    }
    console.log(event.target.value);
  };



  const handleChangeCustom = (event) => {
    let quantitys = event
    let sequrity = quantitys * 100;
    let Installion;
    if (quantitys > 10) {
      let cal = quantitys - 10;
      Installion = 1000 + (cal * 50);
    } else {
      Installion = 1000;
    }
    setInsall({
      planName: "Custom",
      security: sequrity,
      Installion: Installion,
      quantity: quantitys
    })


  }


  return (
    <div className="container-fluid">

      <Box component="form" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
        <div className="make_quto">
          <div className="row">

            <div className="col-6">
              <label className="create-label1">Old Hex <span className="required_star">*</span></label>
              <div class="input-group mb-1 ">
                <input type="text"
                  class="form-control"
                  id="oldHex"
                  name="oldHex"
                  autoComplete="current-oldHex"
                  requiredd
                  placeholder="oldHex"
                  aria-label="oldHex"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
        </div>
        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Plan Name <span className="required_star">*</span></label>
              <div class="input-group ">
                <select class="custom-select height-select" required id="inputGroupSelect01" onChange={handlePlan} defaultValue="choose one"
                  name="plan"
                  label="plan"
                  autoComplete="current-plan" value={planNa}>
                  {plan.map((option) => (
                    <option value={option.text}>{option.text}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Plan Duration <span className="required_star">*</span></label>
              <div className="input-group ">
                <select className="custom-select height-select" required id="inputGroupSelect01" onChange={handlePlanDuration}
                  name="planDuration"
                  label="planDuration"
                  autoComplete="current-planDuration"
                  defaultValue={ (planD)}>
                  {planDuration.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}

                </select>
              </div>
            </div>
          </div>
          {
            planN === 0 ?
              <div className="row">
                <div className="col-12">
                  <label className="create-label1">Total Appliances</label>
                  <div class="input-group ">
                    <input type="text" defaultValue={ 4} class="form-control" id="appliance" name="appliance" autoComplete="current-appliance" placeholder="Total appliances" aria-label="appliance" aria-describedby="basic-addon1" onChange={(e) => { handleChangeCustom(e.target.value) }} />
                  </div>
                </div>
              </div> : null
          }
        </div>

        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Security</label>
              <div class="input-group mb-1">
                <input type="number" onWheel={event => event.currentTarget.blur()} class="form-control" value={install.security} id="security" name="security" autoComplete="current-security" onChange={(e) => { setInsall({ ...install, security: e.target.value }) }} placeholder="Security" aria-label="security" aria-describedby="basic-addon1" />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Installation</label>
              <div class="input-group mb-1">
                <input type="number" onWheel={event => event.currentTarget.blur()} class="form-control" value={install.Installion} id="install" name="install" autoComplete="current-install" onChange={(e) => { setInsall({ ...install, Installion: e.target.value }) }} placeholder="Installation" aria-label="install" aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-6">
              <label className="create-label1">Payment Mode</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  required
                  id="inputGroupSelect01"
                  onChange={handleChangepaymentMode}
                  defaultValue="choose one"
                  name="paymentMode"
                  label="City"
                  autoComplete="current-paymentMode"
                  value={paymentMode}>
                  {PaymentModes.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
            {
              paymentMode !== "razorpay" ?
                <div className="col-6">
                  <label className="create-label1">Reference Id</label>
                  <div class="input-group ">
                    <input type="text"
                      class="form-control"
                      id="referenceId"
                      name="referenceId"
                      required
                      autoComplete="current-referenceId"
                      placeholder="reference Id"
                      aria-label="referenceId"
                      aria-describedby="basic-addon1" />
                  </div>
                </div> : null
            }
          </div>
        </div>

        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">First Telecaller Name</label>
              <div class="input-group">
                <select class="custom-select height-select" id="inputGroupSelect01"
                  onChange={handleTeleCallerFirst}
                  defaultValue="choose one"
                  name="teleCallerFirst"
                  label="teleCallerFirst"
                  autoComplete="current-teleCallerFirst"
                  value={teleCallerFirstName}>
                  {userName?.map((option) => (
                    <option value={option.first_name}>{option.first_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Second Telecaller Name</label>
              <div class="input-group">
                <select class="custom-select height-select" id="inputGroupSelect01"
                  onChange={handleTeleCallerSecond}
                  defaultValue="choose one"
                  name="teleCallerSecond"
                  label="teleCallerSecond"
                  autoComplete="current-teleCallerSecond"
                  value={teleCallerSecondName}>
                  <option value="empty">choose if Second Telecaller</option>
                  {userName?.map((option) => (
                    <option value={option.first_name}>{option.first_name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Created At <span className="required_star">*</span></label>
              <div class="input-group mb-1">
                <input type="date"
                  class="form-control"
                  id="createdAt"
                  name="createdAt"
                  autoComplete="current-createdAt"
                  required
                  aria-label="createdAt"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">Start Date <span className="required_star">*</span></label>
              <div class="input-group mb-1">
                <input type="date"
                  class="form-control"
                  id="startDate"
                  name="startDate"
                  autoComplete="current-startDate"
                  aria-label="startDate"
                  aria-describedby="basic-addon1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label className="create-label1">Mandate Date <span className="required_star">*</span></label>
              <div class="input-group ">
                <input type="date" class="form-control" required id="mandateDate" name="mandateDate" autoComplete="current-mandateDate" aria-label="mandateDate" aria-describedby="basic-addon1" />
              </div>
            </div>

          </div>
        </div>

        <div style={{ marginLeft: "1%" }}>
          <div className="btn-create-container">
            <div className="btn-create">

              <Button
                type="submit"
                variant="contained"
                style={{
                  pointerEvents: `${qutoBtnText === "Processing..." ? "none" : ""}`,
                  backgroundColor: `${qutoBtnText === "Processing..." ? "#cccccc" : "#2552B2"}`
                }}
              >
                {qutoBtnText}
              </Button>

            </div>


          </div>
        </div>


      </Box>
      {
        userName == undefined ?

          <Alert variant="filled" severity="error"
            sx={{ fontSize: "15px", marginTop: "5%", }}>

            LogOut your account from all devices
          </Alert> : null
      }

      <Qutoes />
    </div>
  );
}

