import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import './ConvertedLead.css'
import './DispatchData.css'
import './PaymentLinkGen.css'
import './PartialPayment.css'
import Qutoes from './Qutoes'
import { serverUrl } from './ApiUrl'
import moment from 'moment'
import { useSelector } from 'react-redux'
import DonwloadInvoiceCsv from './Invoice/DonwloadInvoiceCsv'
import InvoiceCommentUpdate from './Function/InvoiceCommentUpdate'
import { getInvoiceDateWise } from './Function/AxiosControl'
import { state, InvoiceStatus } from './Data'
import { InvoiceGenerate } from './Function/InvoieDownloadFun'
import SubscriptionPause from './Function/SubscriptionPause'
import MaterAuditCancel from './SmallComponent/MaterAuditCancel'
import ChangeInstallationDate from './Function/ChangeInstallationDate'
import Spinner from './Function/Spinner'
import UpdateSingleInvoice from './Invoice/UpdateSingleInvoice'
import InvoiceDelete from './Function/InvoiceDelete'
import { Button } from '@mui/material'
import { subHistory } from './Function/SubscriptionHistory'
import InvoiceSend from './Invoice/InvoiceSend'
import { notify } from './Common/Notification'
import DownloadInvoiceCurrentMonth from './Invoice/DownloadInvoiceCurrentMonth'
import NewInvoiceCsv from './Invoice/NewInvoiceCsv'
import JSZip from 'jszip';
import FileSaver from 'file-saver'

export default function InvoiceGenerated() {
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let salesUser = useSelector((state) => state.salesUser)
  let [details, setDetails] = useState([]);
  let [page, setPage] = useState(1)
  let [totalPage, setTotalPage] = useState(1)
  let recordPerPage=50
  let [dates, setDates] = useState({
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
  })
  let [errorMessage, setErrorMeassage] = useState('')
  let [displayData, setDisplayData] = useState([])
  const searchValue = useRef(null)
  let [filterOption, setFilterOption] = useState(false)
  let [spinning, setSpining] = useState(false)
  let [reloadW, setReload] = useState(false);
  let [paymentStatusFilter, setPaymentStatusFilter] = useState("All")
  let [filters, setFilters] = useState({ teleCaller: "All", status: "", city: "" })
  let [filterTelecaller, seFfilterTelecaller] = useState("All")
  const handleSubmitDate = async (e) => {
    setFilterOption(true)
    e.preventDefault();
    let data = new FormData(e.currentTarget);

    let date = {
      startDate: data.get('startDate'),
      endDate: new Date(new Date(data.get('endDate')).setDate(new Date(data.get('endDate')).getDate() + 1))
    }
    setDates(date)
    invoiceDateData(date.endDate, date.startDate, page, recordPerPage)
  }
  useEffect(() => {
    setErrorMeassage('')
    setFilterOption(true)
    let endDate = new Date(new Date().setDate(new Date().getDate() + 1))
    let startDate = new Date(new Date().setDate(new Date().getDate() - 30))

    invoiceDateData(endDate, startDate, 1, 50)

  }, [])
  const invoiceDateData = (endDate, startDate, page, recordPerPage) => {
    setSpining(true)
    setFilters({ teleCaller: "All", status: "", city: "" })
    getInvoiceDateWise(startDate, endDate, page, recordPerPage).then((bill) => {
      if (bill[2] === "then") {
        setSpining(false)
        setDetails(bill[0])
        setDisplayData(bill[0])
        setTotalPage(bill[1])
      } else if (bill[1] === "catch") {
        notify("Error Occur")
        setSpining(false)
      } else {
        setSpining(true)
      }
    }).catch(function (err) {
      console.log(err);
    })
  }
  useEffect(() => {
    filterOnInvoiceStatus(paymentStatusFilter)
  }, [details])
  const filterOnInvoiceStatus = (name) => {
    setPaymentStatusFilter(name)
    if (name === "All") {
      setDisplayData(details)
    } else if (name === "Any for Now") {
      let data = details.filter((e) => (e.status !== "paid" && e.status !== "sent" && e.status !== "overdue"))
      setDisplayData(data);
    } else if (filterTelecaller != "All") {
      let data = details.filter((e) => (e.status == name && (e.teleCallerId === filterTelecaller || e.secondarySalesPerson === filterTelecaller)))
      setDisplayData(data);
    }
    else {
      let data = details.filter((e) => e.status == name)
      setDisplayData(data);
    }
  }
  
  const InvoiceGenerated = async (e) => {
    let res = await InvoiceGenerate(e, e, notify)
 
    if (res[0] === "then") {
      if (res[1].status === 200) {
        if (!e.is_UpSell) {
          const zip = new JSZip();
          const zipContent = await zip.loadAsync(res[1].data);
          for (let file in zipContent?.files) {
            // Extract the PDF file
            const pdfFile = zipContent?.files[file];
            if (pdfFile) {
              const pdfBlob = await pdfFile.async("blob");
              FileSaver.saveAs(pdfBlob, `${e.name} - invoice.pdf`);
            } else {
              console.error("PDF file not found in the ZIP archive.");
            }
          }
        } else {
          // Create a Blob from the response data (PDF)
          const blob = new Blob([res[1].data], { type: "application/pdf" });

          // Use file-saver to download the file
          FileSaver.saveAs(blob, `${e.name} - invoice.pdf`);
        }
      } else {
        notify("Failed");
      }
    } else {
      notify("Failed")
    }


  }
  const ActivateHouse = async (id, e) => {
    await axios.post(`${serverUrl.u}/v2/house/activate`, {
      houseId: id,
    }).then(function (res) {
      console.log(res);
      notify(res.data.message)
      subHistory("House Activated From Invoice Page", e, "House Active/Deactive", "House Activated Invoice Page")
      setReload(!reloadW)
    }).catch(function (err) {
      console.log(err);
      notify("Error")
    })
  }

  const DectivateHouse = async (id, e) => {
    await axios.post(`${serverUrl.u}/v2/house/deactivate`, {
      houseId: id,
    }).then(function (res) {
      console.log("Deactate", res);
      notify(res.data.message)
      let newDate = new Date()
      subHistory("House Deactivated From  Invoice Page", e, "House Active/Deactive", "House Deactivated Invoice Page")
      setReload(!reloadW)
    }).catch(function (err) {
      console.log(err);
      notify("Error")
    })
  }

 
  const InvoucdcolorDecide = (text) => {
    if (text === "overdue") {
      return "rgb(251, 37, 118)"
    }
    if (text === "paid") {
      return "green"
    }
    if (text === "sent") {
      return "rgb(51, 47, 208)"
    }
  }

  const searchInvoice = async () => {
    setErrorMeassage('')
    setFilters({ teleCaller: "All", status: "", city: "" })
    const digits_only = string => [...string].every(c => '0123456789'.includes(c));
    let val = await digits_only(searchValue.current.value)
    axios.post(`${serverUrl.u}/api/tele/searchOnInvoicePage`, {
      value: val === true ? Number(searchValue.current.value) : searchValue.current.value,
      firstDate: dates.startDate,
      lastDate: dates.endDate
    }).then(
      function (res) {
        console.log(res);
        if (res.data.inv.length !== 0) {
          setDetails(res.data.inv)
          setDisplayData(res.data.inv)
          setTotalPage(1)
          setPage(1)
        } else {
          setErrorMeassage("Invoice Not Found")
          setDetails(res.data.inv)
          setDisplayData(res.data.inv)
        }
      }
    ).catch(function (err) {
      console.log(err);
      setErrorMeassage("Failed to Fetch")
    })
  }
  const filtersPara = (key, value, page) => {
    setErrorMeassage('')
    axios.post(`${serverUrl.u}/app/inv/filterInvoices?p=${page - 1}`, {
      teleCallerId: key === "teleCaller" ? value : filters.teleCaller,
      city: key === "city" ? value : filters.city,
      status: key === "status" ? value : filters.status,
      firstDate: dates.startDate,
      lastDate: dates.endDate,
      is_UpSell: false
    }).then(
      function (res) {
        console.log(res);
        setTotalPage(res.data.totalPage)

        if (res.data.inv.length !== 0) {
          setDetails(res.data.inv)
          setDisplayData(res.data.inv)
          setSpining(false)
        } else {
          setErrorMeassage("Invoice Not Found")
          setDetails(res.data.inv)
          setDisplayData(res.data.inv)
          setSpining(false)
        }

      }
    ).catch(function (err) {
      console.log(err);
      setErrorMeassage("Failed to Fetch")
      setSpining(false)
    })
  }
  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      {
        spinning === true ?
          <Spinner /> : null
      }
      <div style={{
        display: "flex",
        justifyContent: "space-between",
      }}>
        <div
          style={{
            display: "flex",
            width: "48%",
          }}>
          <DonwloadInvoiceCsv />
          <DownloadInvoiceCurrentMonth/>
          <NewInvoiceCsv/>

        </div>
        <div style={{ display: "flex" }}><input className='invoiceInputSearch' type="text" placeholder="Search here by Name or Invoice Number...." ref={searchValue} />
          <Button variant='contained' onClick={() => searchInvoice()}>Search</Button>
        </div>
      </div>
      <form onSubmit={handleSubmitDate} >
        <div className='row' style={{ marginLeft: "0.7vw", marginBottom: "5px" }}>
          <div className='col-2 '>
            <label>Start Date</label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                name="startDate"
                defaultValue={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substr(0, 10)}
                autoComplete='current-startDate'
              />
            </div>
          </div>
          <div className='col-2 '>
            <label>End Date</label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                name="endDate"
                defaultValue={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10)}
                autoComplete='current-endDate'
              />
            </div>
          </div>
          <div className='col-2'>
            <label></label>
            <div className='input-group mt-2 '>
              <button className='btn1 btn-success' style={{ fontSize: "26px !important" }} type='submit'>Search</button>
            </div>
          </div>
        </div>
      </form>
      {
        filterOption === true ?
          <div className='row' style={{ marginLeft: "0.7vw" }}>
            <div className="col-2">
              <label className="create-label1">TeleCaller</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  onChange={(e) => {
                    setFilters({ ...filters, teleCaller: e.target.value })
                    setPage(1)
                    setSpining(true)

                    filtersPara("teleCaller", e.target.value, 1)
                  }}
                  value={filters.teleCaller}
                >
                  <option value="All">All</option>
                  {salesUser?.map((option) => (
                    <option value={option.first_name}>{option.first_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <label className="create-label1">City</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  onChange={(e) => {
                    setFilters({ ...filters, city: e.target.value })
                    setPage(1)
                    setSpining(true)
                    filtersPara("city", e.target.value, 1)
                  }}
                  value={filters.city}
                >
                  <option value="">All</option>
                  <option value="Any for Now">Any for Now</option>
                  {state?.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <label className="create-label1">InvoiceStatus</label>
              <div class="input-group ">
                <select className="custom-select height-select"
                  onChange={(e) => {
                    setFilters({ ...filters, status: e.target.value })
                    setPage(1)
                    setSpining(false)
                    filtersPara("status", e.target.value, 1)
                  }}
                  value={filters.status}
                >
                  <option value="">All</option>
                  {InvoiceStatus?.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>

          </div> : null
      }
      {
        errorMessage !== '' ?
          <div
            style={{
              width: "-webkit-fill-available",
              textAlign: "center",
              color: "red",
              fontWeight: "700",
              fontSize: "27px",
              marginTop: "10px",
            }}
          >{errorMessage}</div> : null
      }
      <nav aria-label="..." className='pagination-container'>
        <ul class="pagination">
          {
            [...Array(totalPage)].map((e, i) => {
              return (

                <li className={page === i + 1 ? "page-item active" : "page-item"}
                  onClick={() => {
                    setPage(i + 1)
                    if (filters.teleCaller === "All" && filters.city === "" && filters.status === "") {
                      invoiceDateData(dates.endDate, dates.startDate, i + 1, recordPerPage)
                    } else {
                      filtersPara("", "", i + 1)
                    }
                  }}
                >
                  <a className="page-link" href="#" >{i + 1} </a>
                </li>
              )
            })
          }
        </ul>
      </nav>
      <div className='table-container1' style={{ marginTop: "4px" }}>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th>Sr. No.</th>
              <th scope="col">Invoice No</th>
              <th scope="col" >Name</th>
              <th>Phone</th>
              <th>TeleCaller</th>
              <th>City</th>
              <th>Status</th>
              <th style={{ width: "210px" }}>Payment Status Update</th>
              <th>Comments<span style={{ color: "red" }}>*</span></th>
              <th>Download</th>
              {/* <th style={{ width: "140px" }}>Invoice</th> */}
              <th style={{ width: "130px" }}>Invoice Url</th>
              {
                (loginName === "Udit" || loginName === "BHAVYA" || loginName === "Akhil" || loginName === "Digvijay"  || loginName==="Lalat") ?
                  <>
                    <th style={{ width: "180px" }}>Subscription Pause</th>
                    <th style={{ width: "160px" }}>Master Audting Status</th>
                    <th>Master Status</th>
                    <th style={{ width: "160px" }}>Invoice Delete</th>
                    <th style={{ width: "150px" }}>Activate/Deactivate</th>
                    <th style={{ width: "160px" }}>Activate/Deactivate Status</th>
                  </> : null
              }
              <th>Is First Invoice</th>
              <th>UpSell</th>
              <th>Amount</th>
              <th>Zoho Invoice No</th>
              <th>Create Time</th>
              <th>Mandate Date</th>
              <th>Billing Date</th>
              <th>CreatedAt</th>
              <th style={{ width: "120px" }}>Installation Date<span style={{ color: "red" }}>*</span></th>
              <th>Hex Code</th>
              <th style={{ width: "180px" }}>houseId</th>
          
              x
              <th style={{ width: "160px" }}>Subscription Id</th>
            </tr>
          </thead>
          <tbody>
            {
              displayData !== 0 ?
                displayData.map((e, i) => {
                  return (
                    <tr key={i} style={{ backgroundColor: e.problem ? "rgb(235 85 82 / 30%)" : "" }}>
                      <td>{(i + 1) + ((page - 1) * recordPerPage)}</td>
                      <td><div>{e.invoiceNumber>7975?e.invoiceNumber-7975:(e.invoiceNumber>1200?e.invoiceNumber-1200:e.invoiceNumber)}</div></td>
                      <td className="pr-15 pl-55" style={{ fontWeight: "bold" }}>{e.name} {e.B2BRoom_No}</td>
                      <td>{e.phone}</td>
                      <td>{e.teleCallerId}</td>
                      <td>{e.city}</td>
                      <td style={{ color: InvoucdcolorDecide(e.status) }}>{e.status}</td>
                      <td><UpdateSingleInvoice data={e} reload={reloadW} setReload={setReload} /></td>
                      <InvoiceCommentUpdate e={e} reload={reloadW} setReload={setReload} notify={notify} />
                      <td><button className='btn btn-primary' onClick={() => InvoiceGenerated(e)}>Invoice</button></td>
                      {/* <td>{(e.hasOwnProperty("email") === true && e.email !== "") ? <InvoiceSend inv={e} sub={e} notify={notify} /> : "E-Mail Not Found/Register"}</td> */}
                      <td><button className='btn btn-primary' onClick={() => navigator.clipboard.writeText(e.invoiceUrl)}>Copy</button></td>
                      {
                        (loginName === "Udit" || loginName === "BHAVYA" || loginName === "Akhil" || loginName === "Digvijay" || loginName==="Lalat") ?
                          <>
                            <td><SubscriptionPause e={e} reload={reloadW} setReload={setReload} notify={notify} /></td>

                            <td><MaterAuditCancel e={e} notify={notify} reload={reloadW} setReload={setReload} /></td>
                            <td>{e.masterCancel === true ? "Yes" : "No"}</td>
                            <td><InvoiceDelete e={e} notify={notify} reload={reloadW} setReload={setReload} /></td>
                            {
                        e.houseId !== "" ?
                          <>
                            {
                              e.active === false ?
                                <td><button className="btn btn-primary" onClick={() => ActivateHouse(e.houseId, e)}>Activate</button></td> :
                                <td><button className="btn btn-primary" onClick={() => DectivateHouse(e.houseId, e)}>Deactivate</button></td>
                            }
                          </> : <td></td>
                      }
                          <td>{e.active ? "Yes" : "No"}</td>
                          </> : null
                      }
                      <td>{e.isFirstInvoice === true ? "Yes" : "No"}</td>
                      <td>{e.is_UpSell === true ? "Yes UP Sell" : "NO"}</td>
                      <td>{e.amount}</td>
                      <td>{e.zohoInvoiceNumber}</td>
                      <td>{moment(e.createdTime).format('MMM Do YYYY')}</td>
                      <td>{moment(e.mandateDate).format('MMM Do YYYY')}</td>
                      <td>{moment(e.BillingDate).format('MMM Do YYYY')}</td>
                      <td>{moment(e.createdAt).format("MMM Do YYYY")}</td>
                      <ChangeInstallationDate e={e} reload={reloadW} setReload={setReload} notify={notify} decision={"td"} />
                      <td>{e.generatedHex}</td>
                      <td>{e.houseId}</td>
                  
                    
                      <td>{e.subscriptionId}</td>
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
    

      <Qutoes />
    </div>
  )
}
