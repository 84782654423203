import React, { useState } from 'react'
import axios from 'axios'
import { serverUrl } from '../ApiUrl'
import { CSVLink } from "react-csv";

export default function TempDataCsv() {
    let [data,setData] = useState([])
    let [loading,setLoading]  = useState(false)
    const temporaryCartData = (date) =>{
        setLoading(true)
          axios.post(`${serverUrl.u}/api/ecom/tempCsv`,{
            datee:date
          }).then(function (res) {
            console.log(res);
            setData(res.data.Cartdata)
            
          }).catch(function (err) {
            console.log(err);
          })
    }
  return (
    <>
       {
        data.length===0?
        <>
        {
        loading===false ?
        <input type="date" className="height-select" style={{    backgroundSize: "8px 10px",
        border: "1px solid #ced4da",
        borderRadius: "0.25rem"}} onBlur={(e)=>temporaryCartData(e.target.value)}/>:
        <div>Loading...</div>
        }
        </>:
         <CSVLink className='btn btn-primary' data={data}>Download CSV</CSVLink>
       }
       
    </>
       
    
  )
}
