import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import Qutoes from './Qutoes'
import { serverUrl } from './ApiUrl'
import CommentUdate from './Function/CommentUdate'
import ChangeSecondTeleCaller from './Function/ChangeSecondTeleCaller'
import { useSelector } from 'react-redux'

export default function YesterDaySales() {
    
    let teleCallers = useSelector((state)=>state.sar.data.user?.first_name)
    let [displayData,setDisplayData] = useState([])
    useEffect(()=>{
        let decisied;
        if(teleCallers=="Deepanker"){
            decisied=true
        }else{
            decisied=false 
        }
         axios.post(`${serverUrl.u}/api/tele/showYesterdaySales`,{
            teleCallerId:teleCallers, 
            isSecondTeleCaller:decisied
          }).then(function (res) {
              console.log(res);
              setDisplayData(res.data.alisteSubscription)
            //   notify(res.data.message)
          }).catch(function (err) {
              console.log(err)
            //   notify(err.data.message)
          })
    },[])
  return (
    <div>
        <div className='table-container1'>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th>Sr. No.</th>
              <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
              <th scope="col">Telecaller Name</th>
              <th scope="col">Second Telecaller Name</th>
              <th scope="col">City</th>
              <th>Address</th>
              
              <th scope="col">Phone</th>
              <th>Payment</th>
              <th scope="col">Created At</th>
              <th scope="col">Appliances</th>
              <th scope="col">Selected Plan</th>
              <th scope="col">Comments </th>
             
            </tr>
          </thead>
          <tbody>
            {
              displayData !== 0 ?
                displayData.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td style={{textAlign:"center"}}>{i+1}</td>
                      <td className="pr-15 pl-55" style={{fontWeight:"bold",cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText(e.generatedHex)}}>{e.name}</td>
                      <td>{e.teleCallerId}</td>
                      <td>{e.secondarySalesPerson}</td>
                      {/* <ChangeSecondTeleCaller e={e} SetWebsiteReload={setReload} websiteReload={reloadW} userName={salesUserDatas}/> */}
                      <td>{e.city}</td>
                      <td label="Address">{e.address}</td>
                   
                      <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                      <td style={{ color: `${e.partialPayment === false ? "green" :(e.partialAmount===0)?"red":"orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount===0)?"Zero":`Partial`}</td>
                  
                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <td>{e.totalAppliances}</td>
                      <td>{e.planFrequency}</td>
                      <td>{e.comment}</td>
                      {/* <CommentUdate e={e} websiteReload={reloadW} SetWebsiteReload={setReload}/> */}
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
      <Qutoes/>
    </div>
  )
}
