import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import SuccessTick from '../../component/animation/SuccessTick.json'
import Lottie from "lottie-react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px"
};

function AlertSuccessModel({open,setOpen}) {
    const handleClose = ()=>{
        setOpen(false)
    }
  return (
    <div>
          <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h5" style={{textAlign:'center'}}>
                        Registered Successfully
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Lottie animationData={SuccessTick} loop={true} />
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={"center"} mt={5}>
                        <Button variant="contained" color={"primary"} onClick={() => setOpen(false)}>Close</Button>
                    </Stack>
                </Box>
            </Modal>
    </div>
  )
}

export default AlertSuccessModel