import axios from 'axios'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateAddressLayout } from '../Redux/Action/adderess&layout'
import { notify, notifyFailed } from '../Common/Notification'
import { subHistory } from '../Function/SubscriptionHistory'

function CallNotPickByClient({hex}) {
    const [loading,setLoading] = useState("Call Not Picked")
    const dispatch = useDispatch()
    const callNotPick = ()=>{
        setLoading("Loading...")
        axios.post(`${serverUrl.u}/api/tele/callPickedOrNot`,{
            generatedHex:hex
        }).then(function (res) {
            if(res.data.success===true){
            dispatch(updateAddressLayout(res.data.data))
            subHistory("Call Not Picked",res.data.data,"Call Not Picked","")
            notify("Sucessfully Set")
            }else{
                notifyFailed("Call Not Pick Failed")
            }
            setLoading("Call Not Picked")
        }).catch(function (err) {
            notifyFailed("Call Not Pick Error")
            setLoading("Call Not Picked")
        })
    }
  return (
    <Button
      variant='contained'
      color='primary'
      size='small'
      onClick={()=>callNotPick()}
      disabled={loading!=="Call Not Picked"?true:false}
    >{loading}</Button>
  )
}

export default CallNotPickByClient