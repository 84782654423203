import React from 'react';
import ConfirmationModalMui from '../../Model/ConfirmationModalMui';
import { useState } from 'react';
import { pages } from '../../ApiUrl';
import ModalUpgradeDownGrade from './ModalUpgradeDownGrade';
import { installationDoneByElectrician } from './ApplincesDifferenceApi';
import { Button } from '@mui/material';

function UpgradeDownGradeV2({ sub, setReload, reload }) {
  const [deviceDifference, setDeviceDifference] = useState(false);

  return (
    <div>
      {sub.orderType === 'smartMeter-Installation' ? (
        <Button
          variant='contained'
          sx={{ width: 1 }}
          onClick={() =>
            installationDoneByElectrician(sub, true, false, 'true')
          }
        >
          {sub.reeInstallation
            ? 'ReInstallation Done'
            : sub.grivience
            ? 'Grievance Done'
            : 'Installation Done'}
        </Button>
      ) : (
        <ConfirmationModalMui
          btnName={
            sub.reeInstallation
              ? 'ReInstallation Done'
              : sub.grivience
              ? 'Grievance Done'
              : 'Installation Done'
          }
          Sub={sub}
          setBasicPop={setDeviceDifference}
          page={pages.elctrician}
        />
      )}
      <ModalUpgradeDownGrade
        Sub={sub}
        deviceDifference={deviceDifference}
        setDeviceDifference={setDeviceDifference}
        setReload={setReload}
        reload={reload}
      />
    </div>
  );
}

export default UpgradeDownGradeV2;
