import React from 'react'
import { useState } from 'react';
import { fetchWalletBalance } from './walletApi';
import { cleanNumber } from '../../Function/AxiosControl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { state } from '../../Data';

function WalletBalance() {
    const [btn,setBtn] = useState("Submit")
    const [walletBalance,setWalletBalance] = useState({})
    let loginPersonNumber = useSelector((state)=>state.sar.data.user.phoneNumbers[0])
    
    console.log(loginPersonNumber,"dfrv");
    const handleSubmit =async(event)=>{
        setBtn("Processing...")
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let phoneNumber = data.get('phoneNumber');
        console.log(phoneNumber,"phoneNumber");
        const cleanNumber = (num)=> {
            try {
              let cn = String(num).replace(/\D/g, ''); // replace non digits
              if (cn.startsWith('0')) {
                // the number is starting with 0, remove it
                cn = cn.slice(1, cn.length);
              }
              if (cn.length > 10) {
                cn = cn.slice(cn.length - 10, cn.length);
              }
              return `+91${cn}`;
            } catch (_) {
              return num;
            }
          };
          let phone = await cleanNumber(phoneNumber)
          console.log(phone,"cleanNumber");
        fetchWalletBalance(phone,setWalletBalance,setBtn)
    }
    
    useEffect(()=>{
      loginPersonNumber =  cleanNumber(loginPersonNumber)
      fetchWalletBalance(loginPersonNumber,setWalletBalance,setBtn)
    },[])
    return (
        <div style={{ padding: "0 4%", marginTop: "4vh" }}>
            {/* <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-4'>
                    <label className="create-label1">Phone Number <span className="required_star">*</span></label>
                    <div class="input-group mb-1 ">
                        <input type="text"
                            class="form-control"
                            id="phoneNumber"
                            name="phoneNumber"
                            autoComplete="current-phoneNumber"
                            required
                            placeholder="Phone Number"
                            aria-label="phoneNumber"
                            aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className='col-3'>
                    <button type="submit" className="btn btn-success mt-4" disabled={btn === "Processing..." ? true : false} >{btn}</button>
                </div>
            </div>
            </form> */}
            {
                Object.keys(walletBalance).length > 0 ?
                <div style={{display:"flex",flexDirection:"row",gap:"15px"}} className='mt-4'>
                    <div className='h3'>Balance :-</div>
                    <div className='h3'>{walletBalance.remainingBalance}</div>
                </div>:null
            }
            {
                Object.keys(walletBalance).length> 0 && walletBalance.trnx.length!==0?
                <div className='table-container1'>
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th scope="col" >Transaction Type</th>
            <th scope="col" style={{width:"150px"}}>Amount</th>
            <th scope="col">Client Hex</th>
            
        
          </tr>
        </thead>
        <tbody>
          {walletBalance.trnx.length  !== 0
            ? walletBalance.trnx.map((e, i) => {
                return (
                  <tr key={i}>
                    <td>{e.type.toUpperCase()}</td>
                    <td>{e.amount.toFixed(2)}</td>
                    <td>{e.generatedHex}</td>
                </tr>
                );
              })
            : null}
        </tbody>
      </table>
      </div>:null
            }
        </div>
    )
}

export default WalletBalance