import moment from 'moment'
import React, { useEffect, useState, memo } from 'react'

function InstallationSlot({dates, setDates,filterDataofDates}) {
    
    let [data,setData] = useState({})
 
    useEffect(()=>{
    if(filterDataofDates.length===0) return
      let M = filterDataofDates.filter((d)=>{
        let d1 = new Date(d.InstallationDate)
        return 0<=Number(d1.getHours()) && 12>=Number(d1.getHours())
      })
      let AN = filterDataofDates.filter((d)=>{
        let d2 = new Date(d.InstallationDate)
        return 13<=d2.getHours() && 15>=d2.getHours()
      })
      let Pre_Evening = filterDataofDates.filter((d)=>{
        let d3 = new Date(d.InstallationDate)
        return 16<= Number(d3.getHours()) && 19>=Number(d3.getHours())
      })
      let Post_Evening = filterDataofDates.filter((d)=>{
        let dd = new Date(d.InstallationDate)
        return 20<= Number(dd.getHours()) && 24>=Number(dd.getHours())
      })
      setData({
        M,
        AN,
        Pre_Evening,
        Post_Evening
      })
    },[filterDataofDates])
    let noida = [
        'GREATER NOIDA',
        'GHAZIABAD',
        'NOIDA'
    ]
    let bengaluru = [
        'BENGALURU'
    ]
    const count = (date,datas,include)=>{
        // BENGALURU
        let val =0
        for(let i of datas){
            if(moment.utc(date).format('DD-MM-YYYY')===moment.utc(i.InstallationDate).format('DD-MM-YYYY') && !include.includes(i.city)){
                val+=1
            }
        }
        return val
    }
    const countBENGALURU = (date,datas,include)=>{
        // BENGALURU
        let val =0
        for(let i of datas){
            if(moment.utc(date).format('DD-MM-YYYY')===moment.utc(i.InstallationDate).format('DD-MM-YYYY') && include.includes(i.city)){
                val+=1
            }
        }
        return val
    }
  return (
    <>
    {
        Object.keys(data).length !==0 && <>
        <div style={{marginTop:'5%',marginBottom:'1%',fontWeight:600}}>Other than BENGALURU, NOIDA, GREATER NOIDA, GHAZIABAD</div>
    <div style={{display:"flex",width:"90%",flexWrap:'wrap',gap:'5%'}}>
    
        {
         dates.length!==0 && dates.map((e)=>(
            <div style={{display:"flex",marginBottom:'15px',flexDirection:'column'}}>
            <div>{moment(e).format("DD-MM-YYYY")}</div>
            <div style={{display:'flex',gap:"10px"}}>
            {
                Object.keys(data).length !==0 && Object.keys(data).map((ee)=>{
                    return (
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <div>{ee}</div>
                        <div>{
                            count(e,data[ee],[...noida,...bengaluru])
                        }</div>
                        </div>
                    )
                    
                })
            }
            </div>
            </div>
         ))   
        }
    </div>

    <div style={{marginTop:'3%',marginBottom:'1%',fontWeight:600}}>NOIDA, GREATER NOIDA, GHAZIABAD</div>
    <div style={{display:"flex",width:"90%",flexWrap:'wrap',gap:'5%',}}>
       
        {
         dates.length!==0 && dates.map((e)=>(
            <div style={{display:"flex",marginBottom:'15px',flexDirection:'column'}}>
            <div>{moment(e).format("DD-MM-YYYY")}</div>
            <div style={{display:'flex',gap:"10px"}}>
            {
                Object.keys(data).length !==0 && Object.keys(data).map((ee)=>{
                    return (
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <div>{ee}</div>
                        <div>{
                            countBENGALURU(e,data[ee],noida)
                        }</div>
                        </div>
                    )
                    
                })
            }
            </div>
            </div>
         ))   
        }
    </div>

    <div style={{marginTop:'3%',marginBottom:'1%',fontWeight:600}}>BENGALURU</div>
    <div style={{display:"flex",width:"90%",flexWrap:'wrap',gap:'5%',}}>
       
        {
         dates.length!==0 && dates.map((e)=>(
            <div style={{display:"flex",marginBottom:'15px',flexDirection:'column'}}>
            <div>{moment(e).format("DD-MM-YYYY")}</div>
            <div style={{display:'flex',gap:"10px"}}>
            {
                Object.keys(data).length !==0 && Object.keys(data).map((ee)=>{
                    return (
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <div>{ee}</div>
                        <div>{
                            countBENGALURU(e,data[ee],bengaluru)
                        }</div>
                        </div>
                    )
                    
                })
            }
            </div>
            </div>
         ))   
        }
    </div>
        </>
    }
    
    </>
  )
}

export default memo(InstallationSlot)


