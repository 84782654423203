import React, { useEffect, useState } from 'react'
import CityUpdate from '../Function/CityUpdate'
import ChangeSecondTeleCaller from '../Function/ChangeSecondTeleCaller'
import CommentUdate from '../Function/CommentUdate'
import axios from 'axios'
import { pages, serverUrl } from '../ApiUrl'
import moment from 'moment'
import { orderTyepes, subscriptionStages } from '../Data'
import AddressUpdate from '../Function/AddressUpdate'
import { useSelector } from 'react-redux'
import { notifyFailed } from '../Common/Notification'
import { clientData } from '../Client registation/data'

function FreeClient() {
    let saleData = useSelector(state => state.salesUser);
    const [activeStage,setActiveStage] = useState(subscriptionStages[0])
    const [reloadW,setReload] = useState(false)
    const [data,setData] = useState([])
  const freeClientApi = () =>{
    setData([])
    axios.get(`${serverUrl.u}/api/tele/getFreeClient`).then(function (res) {
        console.log(res);
        if(res.data.success){
            console.log(activeStage,"stage");
            if(activeStage.value===subscriptionStages[0].value){
            setData(res.data.subscription)
            }
           else if(activeStage.value===subscriptionStages[2].value){
                let d = res.data.subscription.filter(p=>{
                    if(p.subscriptionStage!==subscriptionStages[1].value && p.subscriptionStage!==subscriptionStages[3].value){
                        return p
                    }
                })
                setData(d)
            }else{
                let d = res.data.subscription.filter(p=>{
                    if(activeStage.value===p.subscriptionStage){
                        return p
                    }
                })
                setData(d)
            }
            
        }else{
            notifyFailed(res.data.message)
        }
    }).catch(function (err) {
        console.log(err);
        notifyFailed(err.response.data.message)
    })
 
  }
  
  useEffect(()=>{
    freeClientApi()
  },[])
  useEffect(()=>{
    freeClientApi()
  },[reloadW,activeStage])
  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
        

        <div className='title-Container mt-2 mb-5' style={{textAlign:'center'}}>
            {
              subscriptionStages.map(o=>(
                <div
                className='title'
                style={{
                  backgroundColor: activeStage.name === o.name ? clientData.activeColor : "white",
                  color: activeStage.name === o.name ? "white" : "black",
                  minWidth:"121px",
                  height: "min-content",
                }}
                onClick={() => setActiveStage(o)}
              >{o.name}</div>
              ))
            }
          </div>

            <div className='table-container1' style={{ marginTop: "10px", marginBottom: "30px", height: "fit-content" }} key={"imp"}>
              <table className='table table-sm table-bordered'>
                <thead>
                  <tr >
                    <th>Sr. No.</th>
                    <th>planType</th>
                    <th>Subscription Stage</th>
                    <th scope="col">Name<span style={{ color: "red" }}>*</span></th>
                    <th scope="col">Telecaller Name</th>
                    <th scope="col">Second Telecaller Name</th>
                    <th scope="col">City</th>
                    <th scope="col">Appliances</th>
                    <th scope="col">Comments <span style={{ color: "red" }}>*</span></th>
                    <th scope="col">Selected Plan</th>
                    <th>Address<span style={{ color: "red" }}>*</span></th>
                    <th scope="col">Phone</th>
                    <th>Payment</th>
            
                    <th scope="col">Created At</th>
                    <th>Installation Date</th>
                    
                    <th>Electrician Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {

                     data.sort(function(a,b){return new Date(a.installDoneDate).getTime() - new Date(b.installDoneDate).getTime()}).map((e, i) => {
                      return (
                        <tr key={i} >
                          <td>{(i + 1)}</td>
                          <td>{e.orderType}</td>
                          <td>{e.subscriptionStage}</td>
                          <td className="pr-15 pl-55" style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(e.generatedHex) }}>{e.name}</td>
                          <td>{e.teleCallerId}</td>
                          <ChangeSecondTeleCaller
                            e={e}
                            userName={saleData}
                            filter={pages.addressLayout}
                          />
                          <CityUpdate
                            e={e}
                            page={pages.addressLayout}
                          />
                          <td><div className="hover-wide">{e.orderType !== orderTyepes[2].value ? e.totalAppliances : (
                            <ul>
                              {
                                e.upsellItems.map((product, i) => {
                                  return (
                                    <li>
                                      Product Name - {product.productName},
                                      Selling Per Pic - {product.sellingPricePerPic},
                                      Qty - {product.qty}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          )}</div></td>
                          <CommentUdate
                            e={e}
                            websiteReload={reloadW}
                            SetWebsiteReload={setReload}
                            page={pages.addressLayout}
                          />
                          <td>{e.planFrequency}</td>
                          <AddressUpdate
                            e={e}
                            page={pages.addressLayout}
                          />
                       
                          <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>

                          <td style={{ color: `${e.partialPayment === false ? "green" : (e.partialAmount === 0) ? "red" : "orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount === 0) ? "Zero" : `Partial`}  </td>
                        

                          <td>{moment(e.createdAt).format("DD-MM-YYYY")}</td>
                        

                         <td>{moment(e.installDoneDate).format('DD-MM-YYYY')}</td>

                          <td><div className="hover-wide">{e.installerComments}</div></td>


                         
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
    
    </div>
  )
}

export default FreeClient