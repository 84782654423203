import React, { useState } from "react";

import "./users.scss";
import UserLoginOtp from "./components/UserLoginOtp";

export default function Users() {
  const [tab, setTab] = useState("user_otp");

  return (
    <div className="users">
      <div className="tab-group">
        <button
          className={
            tab === "user_otp" ? "btn btn-primary mt-2" : "btn btn-light mt-2"
          }
          onClick={() => setTab("user_otp")}
        >
          User Login OTP
        </button>
      </div>
      {tab === "user_otp" ? <UserLoginOtp /> : null}
    </div>
  );
}
