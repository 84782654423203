import React from 'react'
import SelectCityInput from '../../city/SelectCityInput'
import { indianStates, user_roles } from '../../Data'
import { notifyFailed } from '../../Common/Notification'
import { registerIsp } from './ispApi'
import { useSelector } from 'react-redux'
import { cleanNumber } from '../../Function/AxiosControl'

function RegisterIsp() {
    const [city, setCity] = React.useState("")
    const [btn, setBtn] = React.useState("Submit")
    let salesUser = useSelector((state)=>state.salesUser)

    const handleSubmit = async(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setBtn("Processing...")
        console.log(data);
        if(city===""){
            notifyFailed("City Not Selected")
            return
        }
        if(data.get('state')===""){
            notifyFailed("State Not Selected")
            return
        }
        if(data.get('teleCallerId')===""){
            notifyFailed("Isp Not Selected")
            return
        }
        let phone =await cleanNumber(Number(data.get('phone')))
        let details = {
            "firstName":data.get("firstName"),
            "lastName":data.get("lastName"),
            "companyName":data.get("companyName"),
            "address":data.get('address'),
            "city":city,
            "phone":phone,
            "ispAppliances":Number(data.get('ispAppliances')),
            "walletBalance":Number(data.get('walletBalance')),
            "email":data.get('email'),
            "gstDetails":{
                getName:data.get('gstName'),
                gstNumber:data.get('gstNumber'),
                billToAddress:data.get('billToAddress'),
                shipToAddress:data.get('shipToAddress'),
            },
            "state":data.get('state'),
            "paymentType":"",
            "teleCallerId":data.get('teleCallerId')
        }
        console.log(details,"details");
       await registerIsp(details,setBtn)
    }
    return (
        <div style={{ padding: "0 4%", marginTop: "4vh" }} >
            <form onSubmit={handleSubmit} className='make_quto'>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>First Name</label>
                            <input
                                type="text"
                                required
                                className='form-control'
                                name='firstName'
                                autoComplete='current-firstName'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Last Name</label>
                            <input
                                type="text"
                                required
                                className='form-control'
                                name='lastName'
                                autoComplete='current-lastName'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Company Name</label>
                            <input
                                type="text"
                                required
                                className='form-control'
                                name='companyName'
                                autoComplete='current-companyName'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Email</label>
                            <input
                                type="email"
                                required
                                className='form-control'
                                name='email'
                                autoComplete='current-email'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Phone Number</label>
                            <input
                                type="number"
                                required
                                className='form-control'
                                name='phone'
                                autoComplete='current-phone'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Free Appliances</label>
                            <input
                                type="number"
                                required
                                className='form-control'
                                name='ispAppliances'
                                autoComplete='current-ispAppliances'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Address</label>
                            <input
                                type="text"
                                className='form-control'
                                name='address'
                                required
                                autoComplete='current-address'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>City</label>
                            <SelectCityInput value={city} setValue={setCity} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>State</label>
                            <select
                                className='form-control'
                                name='state'
                                autoComplete='current-state'
                                defaultValue={""}
                                required
                            >
                                <option value={""} key={"state"}>Select State</option>
                                {
                                    indianStates && indianStates.map((item, index) => {
                                        return (
                                            <option key={index} value={item.value}>{item.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Wallet Balance</label>
                            <input
                                type="number"
                                className='form-control'
                                name='walletBalance'
                                required
                                autoComplete='current-walletBalance'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>GST Name</label>
                            <input
                                type="text"
                                className='form-control'
                                name='gstName'
                                required
                                autoComplete='current-gstName'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>GST Number</label>
                            <input
                                type="text"
                                className='form-control'
                                name='gstNumber'
                                required
                                autoComplete='current-gstNumber'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Bill To Address</label>
                            <input
                                type="text"
                                className='form-control'
                                name='billToAddress'
                                required
                                autoCapitalize='current-billToAddress'
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>Ship To Address</label>
                            <input
                                type="text"
                                className='form-control'
                                name='shipToAddress'
                                required
                                autoCapitalize='current-shipToAddress'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='form-group'>
                            <label>ISP ID</label>
                            <select
                                className='form-control'
                                name='teleCallerId'
                                autoComplete='current-teleCallerId'
                                defaultValue={""}
                                required
                            >
                                <option value="">Select Isp</option>
                                <option value={"NetWay"}>NetWay</option>
                                <option value={"Megatel"}>Megatel</option>
                                     {/* {salesUser?.map((option) =>{ 
                                        if(option.roles.includes(user_roles.Isp))(
                                         <option value={option.first_name}>{option.first_name}</option>
                                      )
                                      })} */}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'>
                        <button className='btn btn-success h-4' disabled={btn === "Processing..." ? true : false} type="submit" style={{ width: "100%" }}>{btn}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default RegisterIsp