import React, { useState } from 'react'
import { serverUrl } from './ApiUrl'
import axios from 'axios'
import Qutoes from './Qutoes'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ProductCreation() {
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    const notify = (text) => toast(text);
    let [files,setFiles] = useState([])
    const handleSubmit = async(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let formData = new FormData();
        console.log(files,"file");
        // for(let i=0;i<files.length;i++){
        //     console.log(files[i], i);
        //     data.append(`photos`,files[i])
        // };
       
        console.log(formData.forEach(k => {
            console.log('form entry', k, formData.getAll('photos'))
        }));
        let Data = {
            product_code: data.get("product_code"),
            product_name: data.get("product_name"),
            product_display_name: data.get("product_display_name"),
            product_short_name: data.get("product_short_name"),
            product_description: data.get("product_description"),
            stock: data.get("stock"),
            product_mrp: data.get("product_mrp"),
            product_selling_price: data.get("product_selling_price"),
            hsn_code:data.get("hsn"),
            ecommerceProduct:data.get("ecommerceProduct"),
            installationCharge:data.get('installationCharge')
          }
         createProduct(data,formData)
      };
      const handleFile = (e)=>{
           setFiles(e.target.files)
      }
      
    const createProduct = async(data,formData)=>{
        await axios.post(`${serverUrl.u}/v3/affiliateProducts/create`,
            data,
        ).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                notify(res.data.message)
            }else{
                notify(res.data.message) 
            }
        }).catch(function (err) {
            console.log(err);
            notify(err.response.data.message)
        })

    }
  return (
    <div style={{padding:"0 4%",marginTop:"4vh"}}>
         <ToastContainer/>
      
          <form className="make_quto" validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
          <div className="row">
             <div className="col-6">
             <label>Product Code <span className="required_star">*</span></label>
                 <div class="input-group mb-3">
                    <input type="text" class="form-control" id="product_code" name="product_code" autoComplete="product_code"  required placeholder="product_code" aria-label="product_code" aria-describedby="basic-addon1" />
                 </div>
              </div>
              <div className="col-6">
              <label>Product Name <span className="required_star">*</span></label>
                <div class="input-group mb-3">
                   <input type="text" class="form-control" required id="name" name="name" autoComplete="current-name" placeholder="product_name" aria-label="product_name" aria-describedby="basic-addon1" />
                </div> 
              </div>
          </div>  

          

          <div className="row">
                <div className="col-6">
                    <label>Product Discription <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                         <input type="text" class="form-control" required id="description" name="description" autoComplete="current-description"  placeholder="product_description" aria-label="product_description" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
                    <label>Stock</label>
                    <div class="input-group mb-3">
                        <input type="number"  onWheel={ event => event.currentTarget.blur() } class="form-control"  id="stock" name="stock" autoComplete="current-stock"   placeholder="0" aria-label="stock" aria-describedby="basic-addon1" />
                    </div>
                </div>
             </div>  

             <div className="row">
                <div className="col-6">
                    <label>Product MRP <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                         <input type="number" required onWheel={ event => event.currentTarget.blur() } class="form-control"  id="mrp" name="mrp" autoComplete="current-mrp"  placeholder="product_mrp" aria-label="product_mrp" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
                    <label>Product Selling Price <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                        <input type="number" required onWheel={ event => event.currentTarget.blur() } class="form-control"  id="selling_price" name="selling_price" autoComplete="current-selling_price"   placeholder="product_selling_price" aria-label="product_selling_price" aria-describedby="basic-addon1" />
                    </div>
                </div>
             </div> 
             <div className="row">
                <div className="col-6">
                    <label>Product HSN <span className="required_star">*</span></label>
                    <div class="input-group mb-3">
                         <input type="number" required onWheel={ event => event.currentTarget.blur() } class="form-control"  id="hsn_code" name="hsn_code" autoComplete="current-hsn_code"  placeholder="product_HSN" aria-label="hsn" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
              <label>Is Ecommerce</label>
              <div class="input-group mb-3">
                   <select type="text" class="form-control" defaultValue="no" id="ecommerceProduct" name="ecommerceProduct" autoComplete="current-ecommerceProduct"  aria-label="ecommerceProduct" aria-describedby="basic-addon1" >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                </div>
            </div> 
             </div> 
             <div className="row">
             <div className="col-6">
                <label>Product Display Name</label>
                <div class="input-group mb-3">
                   <input type="text" class="form-control"  id="display_name" name="display_name" autoComplete="current-display_name" placeholder="product_display_name" aria-label="product_display_name" aria-describedby="basic-addon1" />
                </div>
             </div>
            <div className="col-6">
              <label>Product Short Name </label>
              <div class="input-group mb-3">
                   <input type="text" class="form-control" id="short_name" name="short_name" autoComplete="current-short_name" placeholder="product_short_name" aria-label="product_short_name" aria-describedby="basic-addon1" />
                </div>
            </div>    
          </div>
             <div className="row">
             <div className="col-6">
             <label>Product CreateBy<span className="required_star">*</span></label>
                    <div class="input-group mb-3">
             <input type="text"   class="form-control"  id="createdBy" name="createdBy" autoComplete="current-createdBy"  placeholder="product_HSN" defaultValue={loginName} aria-label="hsn" aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-6">
                <label>Image Upload (max 5)</label>
              <div class="input-group mb-3">
                   <input type="file" multiple onChange={handleFile} class="form-control" id="ecommerceProductImg" name="photos" autoComplete="current-photos"  aria-label="ecommerceProduct" aria-describedby="basic-addon1" />
                      
                
                </div>
                </div> 
             </div>   
             <div className='row'>
             <div className="col-6">
             <label>Installation Charges<span className="required_star">*</span></label>
                    <div class="input-group mb-3">
             <input type="number"   class="form-control"  id="installationCharge" name="installationCharge" autoComplete="current-installationCharge"  placeholder="Installation Charges" defaultValue={0} min={0} aria-label="installationCharge" aria-describedby="basic-addon1" />
                    </div>
                </div>
             </div> 
             <div className="row">
                <div className="col-6">
                    <button className='btn btn-primary'>Create Product</button>
                 </div>
             </div>                       
        </form>  
      
   
    <Qutoes/>
    </div>
  )
}
