import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React from 'react'
import { reInstallation } from './Api';
import { notify, notifyFailed } from '../Common/Notification';
import { subHistory } from '../Function/SubscriptionHistory';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    maxHeight: "95vh",
};
function ReInstallation({ sub,reload,setReload }) {
    const [open, setOpen] = React.useState(false);
    const [timeDecision, setTimeDecision] = React.useState("")
    const handleClose = () => {
        setOpen(false)
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let detail = {
            type: data.get("type"),
            comment: data.get('comment'),
            InstallationDate:data.get('InstallationDate')
        }
        console.log(detail);
      let result = await reInstallation(sub,detail)
      if(result.res!==undefined){
        if(result.res.data.success){
            notify(result.res.data.message)
            subHistory(detail.comment,{...sub,payload:detail},"ReInstallation Set",detail.InstallationDate,detail.type)
            handleClose()
            setReload(!reload)
        }else{
            notifyFailed(result.res.data.message)
        }
      }else if(result.err!==undefined){
          notifyFailed(result.err.response.data.message)
      }
    }
    return (
        <div>


            <Button
                variant='contained'
                size='small'
                color='primary'
                onClick={() => {
                    setOpen(true)

                }}
            >
                {"Set Re-Installation"}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>Re Installation</Typography>

                    <form onSubmit={handleSubmit}>
                        <Box component='div' className='make_quto updateFieldUpsell d-flex flex-column'>
                            <FormControl sx={{ mb: 2, minWidth: 220 }} size="small">
                                <InputLabel id="demo-select-small-label">Type Of Reinstallation</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="Type Of Reinstallation"
                                    name='type'
                                    autoComplete='current-type'
                                    required
                                >
                                    <MenuItem value={"UpGrade"}>UpGrade</MenuItem>
                                    <MenuItem value={"DownGrade"}>DownGrade</MenuItem>
                                    <MenuItem value={"UpSell"}>UpSell</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mb: 2, minWidth: 320 }} size="small">
                                <InputLabel id="demo-select-small-label">Installation time avilable ?</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label="Type Of Reinstallation"
                                    name='time'
                                    autoComplete='current-time'
                                    required
                                    onChange={(e) => setTimeDecision(e.target.value)}
                                >
                                    <MenuItem value={"yes"}>Yes</MenuItem>
                                    <MenuItem value={"no"}>No</MenuItem>
                                </Select>
                            </FormControl>
                            {
                                timeDecision === "yes" && (
                                    <TextField
                                        id="outlined-textarea"
                                        type='datetime-local'
                                        sx={{mb:2}}
                                        placeholder="Installation Date"
                                        name="InstallationDate"
                                        required
                                        autoComplete='current-InstallationDate'
                                    />
                                )
                            }
                            {
                                timeDecision === "no" && (
                                    <TextField
                                        id="outlined-textarea"
                                        type='date'
                                        sx={{mb:2}}
                                        placeholder="Installation Date"
                                        name="InstallationDate"
                                        required
                                        autoComplete='current-InstallationDate'
                                    />
                                )
                            }
                            <TextField
                                id="outlined-textarea"
                                type='text'
                                label="comment"
                                placeholder="comment"
                                name="comment"
                                required
                                autoComplete='current-comment'
                            />
                        </Box>
                        <Button
                            variant='contained'
                            type='submit'
                        >Submit</Button>
                    </form>
                </Box>
            </Modal>


        </div>
    )
}

export default ReInstallation