import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { revertMandate } from '../Function/AxiosControl';
import { Label } from '@mui/icons-material';
import {useState} from 'react'
import { orderTyepes } from '../Data';
import { notifyFailed } from '../Common/Notification';
import { completeDone } from '../SpecificElectricianUpGradeDownGrade.js/UpgradeDownGrade/ApplincesDifferenceApi';
import { electicianLocationLog, typeOfLocaionLogs } from '../SpecificElectricianUpGradeDownGrade.js/api';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "12px"
};

export default function ConfirmationModalMui({Sub,btnName,setBasicPop,page}) {
  const [open, setOpen] = React.useState(false);
  const [deviceId,setDeviceId] = useState("")
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)

  const handleOpen = async() => {
    let res = await electicianLocationLog(employeeId,typeOfLocaionLogs.leave,Sub)
    if(!res.success){
      notifyFailed("First Click on Day Start Button")
      return
    }
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
    setBasicPop(true)
  };
   console.log(Sub,"sub");
  return (
    <div>
      <Button onClick={handleOpen} variant="contained" sx={{width:1}}>{btnName}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
          Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to create payment link for {Sub?.name}?.
          Because Previouse subscription will be cancelled.
          </Typography>
          {
            (Sub.houseId==="" && orderTyepes[2].value!==Sub.orderType)&& (
              <>
              <label style={{fontWeight:"bolder",fontSize:"18px"}}>Device Id</label>
              <input type='text' className="form-control" onChange={(e)=>setDeviceId(e.target.value)}/>
              </>
            )
          }

          <Stack direction={'row'} spacing={3} justifyContent={"flex-end"} mt={5}>
            <Button variant="contained" sx={{backgroundColor:"#9FA6B2"}} onClick={()=>setOpen(false)}>No</Button>
            <Button variant="contained" color='primary' onClick={async()=>{
                
                
                if(Sub.reatachMandate===true){
                 await revertMandate(Sub.generatedHex)
                 window.location.reload()
                }
                if(Sub.houseId==="" && orderTyepes[2].value!==Sub.orderType){
                  if(deviceId===""){
                    notifyFailed("Enter Device Id")
                    return
                  }
                 await completeDone(Sub,deviceId,false)
                }
                if(Sub.houseId!=="" && orderTyepes[2].value!==Sub.orderType){
                  await completeDone(Sub,Sub.houseId,true)
                }
                handleClose()
            }}>Yes</Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}