import axios from "axios"
import { serverUrl } from "../../ApiUrl"
import { notify, notifyFailed } from "../../Common/Notification"
import { getAllDates } from "../../Common/applianceDifference"

export const addLeave = async(employeeId,leaveData)=>{
    const leaveArray = getAllDates(leaveData)
   await axios.post(`${serverUrl.u}/app/electrician/addLeave`,{
        employeeId ,
        leaveArray
    }).then(res =>{
        if(res.data.success === true){
            notify(res.data.message)
        }else{
            notifyFailed(res.data.message)
        }
    }).catch(err =>{
       notifyFailed("Failed")
    })
}