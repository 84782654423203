import axios from "axios";
import { notify } from "../Common/Notification";
import { serverUrl } from "../ApiUrl";

// const downloadFile = async(links,fileName) => {
//     const link = document.createElement('a');
//     // create a blobURI pointing to our Blob
//     link.href =  serverUrl.u + links;
    
//       link.download = fileName;
//     // some browser needs the anchor to be in the doc
//    document.body.append(link);
//     link.click();
//     link.remove();
//   };
export const InvoiceGenerate = async (e,ele)=>{
    notify("Invoice is generating plz")
  try {
    const response = await axios.post(`${serverUrl.u}/invoicePdf/create`, {
      generatedHex: e.generatedHex,
      invoiceNumber: ele.invoiceNumber,
    }, {
      responseType: 'arraybuffer', // Ensure we're getting binary data
    });


    // Check if the response contains the expected binary data
    if (response.data) {
      // Log data size
      console.log(`Received data size: ${response.data.byteLength} bytes`);
    } else {
      console.error("No data received");
    }

    return ["then", response]; // Handle response properly

  } catch (err) {
    console.error(err, "error invoice");
    return ["catch", err];
  }
  }