import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { serverUrl } from './ApiUrl'
import { notifyFailed } from './Common/Notification'
import { InstallationUser, salesUserData } from './Function/AxiosControl'
import { useSelector } from 'react-redux'
import { ci } from './city/Cities'
import { StagesSubscription } from './Data'
import CommentUdate from './Function/CommentUdate'
import AddressUpdate from './Function/AddressUpdate'
import moment from 'moment'
import Spinner from './Function/Spinner'
import { CSVLink } from 'react-csv'
import { Button } from '@mui/material'

function AllPaidClients() {
  let [salesUserDatas, setSalesUserDatas] = useState()
  let [loading,setLoading]=useState(false)
  let [reloadW, setReload] = useState(false);
  let [displayData,setDisplayData]=useState([])
  let token = useSelector((state) => state.sar.data.token)
  let [filterParameter,setFilterParameter] = useState({
    teleCallerId:"All",
    city:"All",
    subscriptionStage:"All"
  })
  const getAllData=async()=>{
    setLoading(true)
     await axios.post(`${serverUrl.u}/api/tele/getAllInstalled`).then(res=>{
      console.log(res);
      if(res.data.success){
        setDisplayData(res.data.data)
      }
      setLoading(false)
     }).catch(err=>{
      let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
       notifyFailed(msg)
       setLoading(false)
     })
  }
  useEffect(()=>{
getAllData()
const sale = async () => {
  let salesUser = await salesUserData(token);
  setSalesUserDatas(salesUser);
}
sale();
  },[])
  const colorDecide = (text) => {
    if (text === "dispatch") {
      return "rgb(251, 37, 118)"
    }
    if (text === "ready to install") {
      return "green"
    }
    if (text === "installed" || text === "installed & complete") {
      return "rgb(51, 47, 208)"
    }
  }
  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      {loading && <Spinner/>}
      <div className='row align-items-end'>
        <div className="col-2">
              <label className="create-label1">TeleCaller</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  onChange={(e) => setFilterParameter({...filterParameter,teleCallerId:e.target.value})}
                  value={filterParameter.teleCallerId}
                >
                  <option value="All">All</option>
                  {salesUserDatas?.map((option) => (
                    <option value={option.first_name}>{option.first_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <label className="create-label1">City</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  onChange={(e) => setFilterParameter({...filterParameter,city:e.target.value})}
                  value={filterParameter.city}
                >
                  <option value="All">All</option>
                  <option value="Any for Now">Any for Now</option>
                  {ci.sort((a,b)=> a.value.localeCompare(b.value)).map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
            {
              displayData.filter(c=>{
                if(filterParameter.city!=="All"){
                 if(filterParameter.city.includes(c.city.toUpperCase())){
                  return c
                 }
                }else{
                  return c
                }
              }).filter(t=>{
                if(filterParameter.teleCallerId!=="All"){
                  if(filterParameter.teleCallerId.includes(t.teleCallerId)){
                    return t
                  }
                }else{
                  return t
                }
              }).length!==0 && (
                <div className='col-2'>
                <CSVLink data={displayData.filter(c=>{
                if(filterParameter.city!=="All"){
                 if(filterParameter.city.includes(c.city.toUpperCase())){
                  return c
                 }
                }else{
                  return c
                }
              }).filter(t=>{
                if(filterParameter.teleCallerId!=="All"){
                  if(filterParameter.teleCallerId.includes(t.teleCallerId)){
                    return t
                  }
                }else{
                  return t
                }
              }).map(e=>{
                let obj={
                  Name:e.name,
                  "Client Status":e.dropped === true ? "Dropped" : "Active",
                  "Hex Code":e.generatedHex,
                  "Address":e.address,
                  "City":e.city,
                  "Phone":e.phone,
                  "Comments":e.comments,
                  "Telecaller Name":e.teleCallerId,
                  "Second TeleCaller":e.secondarySalesPerson === "empty" ? "" : e.secondarySalesPerson,
                  "Selected Plan":e.planFrequency,
                  "Appliances":e.totalAppliances,
                  "Payment Status":e.partialPayment === false ? "Paid" : (e.partialAmount === 0) ? "Zero" : `Partial`,
                  "Security":e.security,
                  "Installation":e.installation,
                  "RecursiveAmount":e.recursiveAmount,
                  "Total Amount":(e.totalAmount).toFixed(2),
                  "Gst Name":e.gstName,
                  "Gst Number":e.gstNumber,
                  "Created At":moment(e.createdAt).format("MMM Do LT"),
                  "Mandate Date":moment(e.mandateDate).format("MMM Do"),
                  "Installation Date":moment(e.InstallationDate).format("MMM Do") !== "Invalid date" ? moment(e.InstallationDate).format("MMM Do YYYY") : "",
                  "Installation Done Date":e.hasOwnProperty('installDoneDate')?(moment(e.installDoneDate).format("MMM Do") !== "Invalid date" ? moment(e.installDoneDate).format("MMM Do YYYY") : ""):"",
                  "subscriptionStage":`${e.subscriptionStage}${e.grivience === true ? "  &  Grivience" : ""}`
                }
                return obj
              })} filename='paidClient.csv'>
                  <Button variant='contained'>Download</Button>
                </CSVLink>
              </div>
              )
            }
           
            {/* <div className="col-2">
              <label className="create-label1">Stage</label>
              <div class="input-group ">
                <select class="custom-select height-select"
                  value={filterParameter.subscriptionStage}
                  onChange={(e) => setFilterParameter({...filterParameter,subscriptionStage:e.target.value})}
            
                >
                  <option value={"All"}>All</option>
                  {StagesSubscription?.map((option) => (
                    <option value={option.value}>{option.name}</option>
                  ))}
                </select>
              </div>
            </div> */}
            </div>
      
            <div className='table-container1' style={{ marginTop: '20px' ,height:'600px'}}>
        <table className="table table-sm table-bordered">
          <thead>
            <tr >
              <th>Sr. No.</th>
              <th scope="col" style={{ fontWeight: "bold" }}>Name</th>
              <th>Client Status</th>
              <th>Hex Code</th>
              <th>Address<span style={{ color: "red" }}>*</span></th>
              <th scope="col">City</th>
              <th scope="col" style={{ width: "110px" }}>Phone</th>
              <th scope="col">Comments</th>
              <th scope="col">Telecaller Name</th>
              <th>Second TeleCaller</th>
              <th scope="col">Selected Plan</th>
              <th scope="col">Appliances</th>
              <th>Payment Status</th>
              <th style={{ color: "#FB2576" }}>Security</th>
              <th style={{ color: "#FB2576" }}>Installation</th>
              <th style={{ color: "#FB2576" }}>RecursiveAmount</th>
              <th style={{ color: "#0002A1" }}>Total Amount</th>
              <th>Gst Detail<span style={{ color: "red" }}>*</span></th>
              <th>Gst Name</th>
              <th>Gst Number</th>
              <th>Created At</th>
              <th>Mandate Date</th>
              <th>Installation Date</th>
              <th>Installation Done Date</th>
              <th style={{ width: "140px" }}>subscriptionStage</th>
            </tr>
          </thead>
          <tbody>

            {
              (displayData !== 0) ?
                displayData.filter(c=>{
                  if(filterParameter.city!=="All"){
                   if(filterParameter.city.includes(c.city.toUpperCase())){
                    return c
                   }
                  }else{
                    return c
                  }
                }).filter(t=>{
                  if(filterParameter.teleCallerId!=="All"){
                    if(filterParameter.teleCallerId.includes(t.teleCallerId)){
                      return t
                    }
                  }else{
                    return t
                  }
                }).map((e, i) => {

                  return (
                    <tr key={i}>
                      <td>{(i + 1)}</td>
                      <td className="pr-15 pl-55" style={{ color: `${e.installed === true ? "green" : "black"}`, fontWeight: "bold" }}>{e.name}</td>
                      <td style={{ color: e.dropped === false ? "#008000" : "#FF0000" }}>{e.dropped === true ? "Dropped" : "Active"}</td>
                      <td style={{ color: `${e.partialPayment === true ? "orange" : "black"}` }}>{e.generatedHex}</td>
                      <AddressUpdate e={e} setReload={setReload} reloadW={reloadW} page={"paidOrder"} />
                      <td>{e.city}</td>
                      <td><a href={`tel:${e.phone}`}>{e.phone}</a></td>
                      <CommentUdate e={e} setReload={setReload} reloadW={reloadW} page={"paidOrder"} />
                      <td>{e.teleCallerId}</td>
                      <td>{e.secondarySalesPerson === "empty" ? "" : e.secondarySalesPerson}</td>
                      <td>{e.planFrequency}</td>
                      <td>{e.totalAppliances}</td>
                      <td style={{ color: `${e.partialPayment === false ? "green" : (e.partialAmount === 0) ? "red" : "orange"}` }}>{e.partialPayment === false ? "Paid" : (e.partialAmount === 0) ? "Zero" : `Partial`}</td>
                      <td style={{ color: "#FB2576" }}>{e.security}</td>
                      <td style={{ color: "#FB2576" }}>{e.installation}</td>
                      <td style={{ color: "#FB2576" }}>{e.recursiveAmount}</td>
                      <td style={{ color: "#0002A1" }}>{(e.totalAmount).toFixed(2)}</td>
                      <td>{e.isGstClient === true ? (`${e.gstName}  ${e.gstNumber}`) : "No"}</td>
                      <td >{e.gstName}</td>
                      <td >{e.gstNumber}</td>
                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <td>{moment(e.mandateDate).format("MMM Do")}</td>
                      <td>{moment(e.InstallationDate).format("MMM Do") !== "Invalid date" ? moment(e.InstallationDate).format("MMM Do YYYY") : ""}</td>
                      <td>{e.hasOwnProperty('installDoneDate')?(moment(e.installDoneDate).format("MMM Do") !== "Invalid date" ? moment(e.installDoneDate).format("MMM Do YYYY") : ""):""}</td>
                      <td style={{ color: colorDecide(e.subscriptionStage) }}>{e.subscriptionStage}{e.grivience === true ? "  &  Grivience" : ""}</td>
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AllPaidClients