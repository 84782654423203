import axios from 'axios';
import React from 'react'
import { serverUrl } from '../ApiUrl';
import { subHistory } from '../Function/SubscriptionHistory';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import ReactWhatsapp from 'react-whatsapp';
import { notify, notifyFailed } from '../Common/Notification';

export default function ReteriveClient({e,reload, setReload}) {
    let [pop, setPop] = useState(false)
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let [whatappNumber, setWhatappNumber] = useState();
    let [paymentLink, setPaymentLink] = useState("");
    const [text, setText] = useState("");

    const clientRetirvel = async () => {
        axios.post(`${serverUrl.u}/api/subscription/reteriveDropClient`, {
            generatedHex: e.generatedHex,
        }).then(function (res) {
            console.log(res);
            if (res.data.success === true) {
                notify(res.data.message)
                subHistory("Client Reterive", e, "Client Reterive", "Client Reterive")
                setReload(!reload)
            } else {
                notifyFailed(res.data.message)
            }
        }).catch(function (error) {
            console.log(error);
            notifyFailed("Failed")
        })
    }
    // const handleSubmit = (event)=>{
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     let detail = {
    //         mandate:data.get('mandate'),
    //     }
    //     console.log(detail,"ff");
    //     clientRetirvel(detail)
    // }
  
    return (
        <>
            <button className='btn btn-primary' onClick={() => clientRetirvel()}>Reterive Client</button>

            {/* {
                pop === true ?
                    <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "30vw", backgroundColor: "whitesmoke", padding: "10px", borderRadius: "12px" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <span style={{ cursor: "pointer" }} onClick={() => setPop(false)}>X</span>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">Mandate Attatch</label>
                                    <select class="form-select" aria-label="Default select example" name="mandate" autoComplete='current-mandate'>
                                        <option selected value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                                
                                <button className='btn btn-primary' type='submit'>Submit</button>
                            </form>

                            {text!=="" ? (
                 <div className='razorpayPaymentLink'>
                   <div>Payment Link</div>
                     <ReactWhatsapp
                       number={whatappNumber}
                       message={text}
                       style={{
                         backgroundColor: "#25D366",
                         border: "none",
                         borderRadius: "12px",
                         padding: "5px",
                     
                       }}
                     >
                       <WhatsApp
                         sx={{
                           color: "white",
                           verticalAlign: "middle",
                           fontSize: "xx-large",
                         }}
                       />
                     </ReactWhatsapp>
                   
                  
                     <button
                       className="btn btn-primary btn-width "
                       style={{marginLeft:'15px'}}
                       onClick={() => navigator.clipboard.writeText(paymentLink)}
                     >
                       Copy
                     </button>
                   
                 </div>
               ) : null}
                        </div>
                    </div> : null
            } */}
        </>
    )
}
