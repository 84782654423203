import axios from "axios"
import { serverUrl } from "../ApiUrl"
import { notify, notifyFailed } from "../Common/Notification"

export const fetchSequrityReceiptCsv = async(data)=>{
  return await axios.post(`${serverUrl.u}/securityReceipt/csvData`,data).then((res)=>{
    return {...res,type:1}
   }).catch((err)=>{
     return {...err,type:0}
   })
}

export const fetchSequrityReceiptComplete =  async(data)=>{
  return await axios.post(`${serverUrl.u}/Securityreceipt/fetch`,data).then((res)=>{
    return {...res,type:1}
   }).catch((err)=>{
     return {...err,type:0}
   })
}
export const editSequrityReceipt = async(data)=>{
  return await axios.post(`${serverUrl.u}/securityReceipt/edit`,data).then((res)=>{
    if(res.data.success){
      notify(res.data.message)
    }else{
      notifyFailed(res.data.message)
    }
  
   }).catch((err)=>{
    let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
    notifyFailed(msg)
    
   }).finally((g)=>{
    return false
   })
}

export const fetchCreditNoteCsv = async(data)=>{
    return await axios.post(`${serverUrl.u}/creditNote/csvData`,data).then((res)=>{
      return {...res,type:1}
     }).catch((err)=>{
       return {...err,type:0}
     })
  }

export const fetchCreditNoteComplete =  async(data)=>{
  return await axios.post(`${serverUrl.u}/creditNote/fetch`,data).then((res)=>{
    return {...res,type:1}
   }).catch((err)=>{
     return {...err,type:0}
   })
}
export const editCreditNote = async(data)=>{
  return await axios.post(`${serverUrl.u}/creditNote/edit`,data).then((res)=>{
    if(res.data.success){
      notify(res.data.message)
    }else{
      notifyFailed(res.data.message)
    }
  
   }).catch((err)=>{
    let msg = err?.response?.data?.message?(err?.response?.data?.message):err?.message
    notifyFailed(msg)
    
   }).finally((g)=>{
    return false
   })
}

export const payUTrannsaction = async(data)=>{
  return await axios.post(`${serverUrl.smartMeter}/payment/payuTransactions`,data).then((res)=>{
    return {...res,type:1}
  }).catch((err)=>{
    return {...err,type:0}
  })
}